import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { PaginationService } from 'src/app/services/pagination.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Handle document evidences component functions
 */
export class DocumentEvidencesService {
  private pagination: any;
  private documentId: number;
  private evidences: Array<any> = [];
  private evidencesSub = new Subject<any>();

  constructor(
    private apiService: ApiService,
    private paginationService: PaginationService
  ) {}

  /**
   * Trigger evidences subscription
   */
  public triggerEvidences() {
    this.evidencesSub.next({ evidences: this.evidences });
  }

  /**
   * Return evidences observable
   */
  public getEvidences(): Observable<any> {
    return this.evidencesSub.asObservable();
  }

  /**
   * Get related documents from database
   */
  public getEvidencesFromDB(pagination?, documentId?) {
    if (pagination) {
      this.pagination = pagination;
    } else {
      pagination = this.paginationService.getPagination('evidences');
    }

    if (documentId) {
      this.documentId = documentId;
    } else {
      documentId = this.documentId;
    }

    if (!pagination || !documentId) {
      return;
    }

    this.apiService
      .get(
        'evidences/',
        { ...pagination, documentid: documentId, page_size: 5 },
        ''
      )
      .subscribe((data: any) => {
        this.evidences = data;
        this.triggerEvidences();
      });
  }
}
