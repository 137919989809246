import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Router } from '@angular/router';
import { PaginationService } from 'src/app/services/pagination.service';
import { PaginationModel } from 'src/app/models/pagination';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit, OnDestroy {
  user: any;
  backgroundLoading: boolean = false;
  settingsTable = {};
  rowsTable = {};
  columnsTable = {};
  public roleList: any = [];
  public permissionsList: any = [];
  private pagination: PaginationModel;
  public loadingRoles: boolean = false;
  private subscriptionRoles: Subscription;

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private globalService: GlobalService,
    private alertService: AlertService,
    private apiService: ApiService
  ) {
    this.user = authService.getLoggedInUser();
    this.pagination =
      this.paginationService.getDefaultPagination('clientmanagement');
    this.getClientManagementData();
    this.getColumnsTable();
    this.getSettingTable();
    this.getRolesPermissions();
  }

  ngOnInit() {}

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loadingRoles = false;
  }

  /**
   * Return loaded columnsProjectList with the information of each column of the table we want to show
   */
  public getColumnsTable() {
    this.columnsTable = [
      {
        header: 'clientManagement.tenantName',
        name: 'tenantdisplayname',
        orderBy: 'tenantdisplayname',
        type: 'link',
        clickLink: this.confirmImpersonateClient.bind(this)
      },
      {
        header: 'clientManagement.toolName',
        name: 'toolName',
        orderBy: 'toolName'
      },
      {
        header: 'projectUsers.role',
        title: 'projectUsers.role',
        name: 'role',
        second: 'rolename',
        tags: this.getTagsIcon.bind(this),
        type: 'roles',
        class: ''
      }
    ];
  }

  /**
   * Retrieve all roles and permissions
   */
  private getRolesPermissions() {
    this.roleList = this.globalService.getRoles();
    if (this.roleList.length === 0 && !this.globalService.passedWatcherUtils) {
      this.loadingRoles = true;
      this.subscriptionRoles = this.globalService.watchUtils().subscribe(() => {
        this.roleList = this.globalService.getRoles();
        this.permissionsList = this.globalService.getPermissions();
        this.loadingRoles = false;
      });
    } else {
      this.loadingRoles = false;
    }
  }

  public getTagsIcon(tags: any) {
    const dinamycClass = 'fa fa-info';
    return dinamycClass;
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingsTable = {
      dataId: 'tenantid',
      getDataFromDB: this.getClientManagementData.bind(this),
      responsiveTitle: {
        label: 'tenantdisplayname'
      },
      paginationClass: 'relative-pag'
    };
  }

  // TODO: unify static table data management in a service
  public getClientManagementData() {
    let data = JSON.parse(JSON.stringify(this.user.tenants));
    const originalData = JSON.parse(JSON.stringify(data));
    const dataToShowIni =
      this.pagination.page * this.pagination.page_size -
      this.pagination.page_size;
    const dataToShowFin = this.pagination.page * this.pagination.page_size;
    data = data.slice(dataToShowIni, dataToShowFin);
    this.rowsTable['total_elements'] = originalData.length;
    this.rowsTable['page'] = this.pagination.page;
    this.rowsTable['page_size'] = this.pagination.page_size;
    if (originalData.length <= data.length) {
      this.rowsTable['num_pages'] = Math.floor(
        originalData.length / this.pagination.page_size
      );
      if (originalData.length % this.pagination.page_size > 0) {
        this.rowsTable['num_pages'] = this.rowsTable['num_pages'] + 1;
      }
    } else {
      this.rowsTable['num_pages'] = 1;
    }
    this.rowsTable['order_by'] = this.pagination.order_by;
    this.rowsTable['desc'] = this.pagination.desc;
    this.rowsTable['data'] = data;
    this.paginationService.setPage('clientmanagement', data);
    this.pagination = this.paginationService.setPagination('clientmanagement', {
      total_elements: data.length,
      page: this.rowsTable['page'],
      page_size: this.rowsTable['page_size'],
      num_pages: this.rowsTable['num_pages'],
      order_by: this.rowsTable['order_by'],
      desc: this.rowsTable['desc'],
      query: this.pagination.query
    });
    this.pagination = this.paginationService.getPagination('clientmanagement');
  }

  /**
   * call modal to confirm impersonation
   */
  public confirmImpersonateClient(row) {
    let message = this.translate.transform(
      'components.modal.clientManagement.impersonate'
    );
    const tenantName = row.tenantdisplayname ? row.tenantdisplayname : 'SIRE';
    message = message + tenantName + ' ?';
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'clientManagement',
      message: message
    };
    modalWindowRef.result.then(result => {
      if (result === 1) {
        this.backgroundLoading = true;
        this.authService.setIdTenantHeader(row.tenantid.toString());
        this.loginAPI(row, tenantName);
      }
    });
  }

  /**
   * Sends a login request to the API with the new tenant
   * @param row
   * @param tenantName
   */
  public loginAPI(row: any, tenantName: string): void {
    const urlTenant = this.authService.getTenantNameFromUrl();
    const subscription = this.apiService
      .get('login/', null, '', true, {}, urlTenant)
      .subscribe(
        user => {
          this.onApiLoginSuccess(user, row, tenantName);
          subscription?.unsubscribe();
        },
        error => {
          this.onApiLoginError(error, tenantName);
          subscription?.unsubscribe();
        }
      );
  }

  /**
   * On Login Success from the API
   * @param user User recieved
   * @param row Row of the table
   * @param tenantName Tenant Name
   */
  private onApiLoginSuccess(user: any, row: any, tenantName: string) {
    const expirationDate = this.authService.getTokenExpirationDate();
    this.authService.setLoggedInUser(user);
    this.authService.setTokenExpirationDate(expirationDate);
    this.user = this.authService.getLoggedInUser();
    const tenant = this.user.tenants.find(
      tenant => tenant.tenantid === row.tenantid
    );
    this.authService.setTenantName(tenant.tenantname);
    this.globalService.resetData(tenant);
    if (this.user.privacyNoteAccepted == false) {
      this.authService.navigateToPrivacyNoteConfirmation();
    }
    else {
      this.globalService.resetData(tenant);
      this.globalService.redirectUserHomeURL(tenant);
      this.backgroundLoading = false;
      this.alertService.success(
        this.translate.transform('clientManagement.successfulChanged'),
        true,
        `${tenantName}`
      );
    }
  }

  /**
   *
   * @param error
   * @param tenantName
   */
  private onApiLoginError(error: any, tenantName: string) {
    this.backgroundLoading = false;
    this.alertService.error(
      this.translate.transform('clientManagement.unsuccessfulChanged'),
      true,
      `${tenantName}`
    );
  }

  ngOnDestroy(): void {
    this.subscriptionRoles?.unsubscribe();
  }
}
