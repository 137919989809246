import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DatamodelSearchService {
  public filtersExtractionBR: [];
  public filtersPreliminaryCatalog: [];
  public filtersChecklist: [];

  private hasBeenSearchedEBR: boolean = false;
  private hasBeenSearchedPC: boolean = false;
  private hasBeenSearchedC: boolean = false;

  constructor(private modalService: NgbModal) {}

  public getPreviousSearch(paginationType) {
    switch (paginationType) {
      case 'validations':
        return this.filtersExtractionBR;
      case 'validationsMulti':
        return this.filtersPreliminaryCatalog;
      case 'checklist':
        return this.filtersChecklist;
    }
  }

  public setPreviousSearch(newSearchFilter, paginationType) {
    switch (paginationType) {
      case 'validations':
        return (this.filtersExtractionBR = newSearchFilter);
      case 'validationsMulti':
        return (this.filtersPreliminaryCatalog = newSearchFilter);
      case 'checklist':
        return (this.filtersChecklist = newSearchFilter);
    }
  }

  public getHasBeenSearched(paginationType) {
    switch (paginationType) {
      case 'validations':
        return this.hasBeenSearchedEBR;
      case 'validationsMulti':
        return this.hasBeenSearchedPC;
      case 'checklist':
        return this.hasBeenSearchedC;
    }
  }

  public setHasBeenSearched(value, paginationType) {
    switch (paginationType) {
      case 'validations':
        return (this.hasBeenSearchedEBR = value);
      case 'validationsMulti':
        return (this.hasBeenSearchedPC = value);
      case 'checklist':
        return (this.hasBeenSearchedC = value);
    }
  }

  public cleanFilters() {
    this.filtersExtractionBR = [];
    this.filtersPreliminaryCatalog = [];
    this.filtersChecklist = [];

    this.hasBeenSearchedEBR = false;
    this.hasBeenSearchedPC = false;
    this.hasBeenSearchedC = false;
  }
}
