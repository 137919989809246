import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CognitiveSearchService } from '../../../cognitive-search.service';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent implements OnInit {
  @Input() public form: FormGroup;

  public textFilter: any;

  constructor(private cognitiveService: CognitiveSearchService) {}

  ngOnInit(): void {
    this.form.markAllAsTouched();
  }
}
