import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api.service';
import { DatamodelModel } from 'src/app/models/datamodel.model';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription, concat } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ValidationListModel } from 'src/app/models/validation-list.model';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ValidationService } from './common-components/validation.service';
import { ValidationTypeModel } from 'src/app/models/validation-type-mstr.model';
import { ExtractionService } from './extraction-components/extraction.service';
import { BusinessService } from './business-components/business.service';
import { PreliminaryService } from './preliminary-components/preliminary.service';
import { CatalogService } from './catalog-components/catalog.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-new-validation',
  templateUrl: './new-validation.component.html',
  styleUrls: ['./new-validation.component.scss']
})
export class NewValidationComponent implements OnInit, OnDestroy {
  formTest: FormGroup = new FormGroup({
    description: new FormControl(''),
    type: new FormControl({ value: '', disabled: true }),
    autoexecute: new FormControl(true),
    cases: new FormArray([])
  });
  public user: any;
  public tenantName: any;
  public expandedCases = [];
  public datamodels: DatamodelModel[] = [];
  public validationTypes: ValidationTypeModel[] = [];
  public fieldsOfInterest = [];
  public datamodelId: number;
  public validationTypeId: number;
  public validationGlobal: ValidationListModel;
  private routeSub: Subscription;
  public subscriptionDataModels: Subscription;
  public subscriptionUtils: Subscription;
  public afterPreliminaryUpdate: Subscription;
  public loadingSave: boolean = false;
  public loadingDatamodels: boolean = true;
  public isNew: boolean = true;
  public loadingEditNext = true;
  public loaded: boolean = false;
  private isSaveButtonActive: boolean = false;

  constructor(
    private apiService: ApiService,
    public global: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();
    this.tenantName = this.authService.getTenantName();
  }

  ngOnInit() {
    const datamodelMap = this.global.getDataModelMap();
    const errorsmapping = this.global.getErrorsMapping();

    if (
      datamodelMap.size === 0 &&
      !errorsmapping &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionDataModels = concat(
        this.global.watchDataModels(),
        this.global.watchUtils()
      ).subscribe((data: string) => {
        this.initNewValidation();
      });
    } else if (
      datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.initNewValidation();
        });
    } else if (!errorsmapping && !this.global.passedWatcherUtils) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.initNewValidation();
        });
    } else {
      this.initNewValidation();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionDataModels) {
      this.subscriptionDataModels.unsubscribe();
    }
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
    if (this.afterPreliminaryUpdate) {
      this.afterPreliminaryUpdate.unsubscribe();
    }
  }

  public changePreConditionCheckbox(casesIndex: number, flag: boolean) {
    flag
      ? this.addCasePreCondition(casesIndex)
      : this.removeCasePreConditions(casesIndex);
  }

  private initNewValidation() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      const datamodelidParams = params.get('datamodelid');
      const validationtypeParams = params.get('validationtype');
      const validationidParams = params.get('id');

      this.validationTypes = this.global.getValidationTypes();
      this.validationService.setValidationTypes(this.validationTypes);

      if (datamodelidParams && validationtypeParams) {
        this.createOrEditExtractionBusiness(
          datamodelidParams,
          validationtypeParams
        );
      } else if (validationidParams && validationtypeParams) {
        // edit catalog or preliminary
        this.editValidation(validationidParams, validationtypeParams);
      } else if (validationtypeParams) {
        this.createPreliminary(validationtypeParams);
      } else if (validationidParams) {
        // create catalog
        this.nextStepValidationCatalog(validationidParams);
      }
    });

    this.touchRequiredFields();

    setTimeout(() => {
      // TODO: Handle this without timeout
      this.formTest.valueChanges.subscribe((changedObj: any) => {
        this.isSaveButtonActive = this.formTest.valid;
      });
    }, 10);
  }

  private createOrEditExtractionBusiness(datamodelid, validationtypeid) {
    // get datamodels and validations
    const datamodelValidation = this.global.getDataModelById(+datamodelid);
    this.datamodels = [
      {
        datamodelid: datamodelValidation.datamodelid,
        datamodeldisplayname: datamodelValidation.datamodeldisplayname
      }
    ];
    this.validationService.setGroupOfFields(datamodelValidation.groupoffields);
    this.fieldsOfInterest = this.validationService.getGroupOfFields();

    // set validationname
    const validationTypeObject = this.validationTypes.find(
      validationType => validationType.validationtypeid === +validationtypeid
    );
    const validationTypeName = validationTypeObject.validationtypename;
    this.validationService.setValidationTypeName(validationTypeName);

    // if its create or edit
    if (
      validationTypeName === 'Extraction' &&
      (!datamodelValidation.validations.extraction ||
        !datamodelValidation.validations.extraction.validationid)
    ) {
      this.formTest = this.extractionService.newValidationExtraction(
        this.formTest,
        datamodelValidation
      );
      this.validationService.getFunctions();
    } else if (
      validationTypeName === 'Business Rules' &&
      (!datamodelValidation.validations.business_rules ||
        !datamodelValidation.validations.business_rules.validationid)
    ) {
      this.formTest = this.businessService.nextStepValidationBusiness(
        datamodelValidation,
        this.formTest
      );
    } else if (
      validationTypeName === 'Extraction' &&
      datamodelValidation.validations.extraction
    ) {
      this.isNew = false;
      this.formTest = this.extractionService.editValidationExtraction(
        datamodelValidation,
        this.formTest
      );
      this.validationService.getFunctions();
    } else if (
      validationTypeName === 'Business Rules' &&
      datamodelValidation.validations.extraction
    ) {
      this.isNew = false;
      this.formTest = this.businessService.editValidationBusiness(
        datamodelValidation,
        this.formTest
      );
    }
    this.loaded = true;
  }

  private createPreliminary(validationtypeid) {
    const validationTypeObject = this.validationTypes.find(
      validationType => validationType.validationtypeid === +validationtypeid
    );
    const validationTypeName = validationTypeObject.validationtypename;
    this.validationService.setValidationTypeName(validationTypeName);
    this.formTest.addControl('trackstatus', new FormControl(true));
    this.formTest.addControl('precondition', new FormControl(false));
    this.formTest.addControl('preconditions', new FormArray([]));
    this.datamodels = this.global.getDataModelsFormatedBusiness();
    this.formTest = this.preliminaryService.newValidationPreliminary(
      this.formTest
    );
    this.addCase();
    this.loaded = true;
  }

  /**
   * Get last validation to populate
   * @param validation validation data to populate
   */
  private nextStepValidationCatalog(validationidParams) {
    this.validationService.setValidationTypeName('Catalog');
    const validation = this.global.getPreliminaryValidationById(
      +validationidParams
    );
    this.datamodels = this.global.getDataModelsFormatedPreliminary();
    this.formTest = this.catalogService.nextStepValidationCatalog(
      validation,
      this.formTest,
      this.datamodels
    );
    this.addCase();
    this.loaded = true;
  }

  /**
   * Edit Validation routing wrapper
   * @param validation validation json to edit
   * @param validationtypeid validation type
   */
  private editValidation(validationid, validationtypeid) {
    this.isNew = false;

    const validationTypeObject = this.validationTypes.find(
      validationType => validationType.validationtypeid === +validationtypeid
    );
    const validationTypeName = validationTypeObject.validationtypename;
    this.validationService.setValidationTypeName(validationTypeName);

    if (validationTypeName === 'Preliminary') {
      const validation = this.global.getPreliminaryValidationById(
        +validationid
      );
      this.datamodels = this.global.getDataModelsFormatedBusiness();
      this.formTest = this.preliminaryService.editPreliminary(
        validation,
        this.formTest,
        this.datamodels
      );
    } else if (validationTypeName === 'Catalog') {
      const validation = this.global.getCatalogValidationById(+validationid);
      this.datamodels = this.global.getDataModelsFormatedPreliminary();
      this.formTest = this.catalogService.editCatalog(
        validation,
        this.formTest,
        this.datamodels,
        +validationid
      );
    }
    this.loaded = true;
  }

  /**
   * Touch required fields, to show them as invalid if are empty
   */
  private touchRequiredFields() {
    const cases = this.formTest.get('cases') as FormArray;

    for (let i = 0; i < cases.length; i++) {
      // Touch case type and name
      this.validationService.touchControl(cases['controls'][i], 'case_name');
      this.validationService.touchControl(
        cases['controls'][i],
        'condition_type'
      );

      const conditionLists = cases['controls'][i].get(
        'condition_list'
      ) as FormArray;
      Object.values(conditionLists['controls']).forEach(conditionList => {
        this.validationService.touchControl(conditionList, 'error');
      });
    }
  }

  /**
   * Add new empty case to Form
   */
  private addCase() {
    const cases = this.formTest.get('cases') as FormArray;
    let case_condition_type = '';
    let condition_list_condition_type = '';
    let simple_multiple = false;
    let disabledSelect = false;
    let condition = new FormGroup({
      condition_type: new FormControl(
        { value: condition_list_condition_type, disabled: disabledSelect },
        Validators.required
      ),
      simple_multiple: new FormControl(
        { value: simple_multiple, disabled: disabledSelect },
        Validators.required
      ),
      description: new FormControl(''),
      conditions: new FormArray([], Validators.required)
    });
    if (this.validationService.isType(['Business Rules', 'Catalog'])) {
      condition.addControl('precondition', new FormControl(false));
      condition.addControl('preconditions', new FormArray([]));
    }
    if (this.validationService.isType(['Preliminary'])) {
      case_condition_type = 'must';
      condition_list_condition_type = 'should';
      simple_multiple = false;
      disabledSelect = true;
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        conditions: new FormArray([], Validators.required),
        datamodel_order_document: new FormControl(''),
        field_order_document: new FormControl(''),
        order_document: new FormControl('')
      });
    }
    if (this.validationService.isType(['Business Rules', 'Catalog'])) {
      cases.push(
        new FormGroup({
          case_name: new FormControl('', Validators.required),
          case_description: new FormControl(''),
          condition_type: new FormControl(
            { value: case_condition_type, disabled: disabledSelect },
            Validators.required
          ),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          precondition: new FormControl(false),
          preconditions: new FormArray([]),
          condition_list: new FormArray([condition])
        })
      );
    } else {
      cases.push(
        new FormGroup({
          case_name: new FormControl('', Validators.required),
          case_description: new FormControl(''),
          condition_type: new FormControl(
            { value: case_condition_type, disabled: disabledSelect },
            Validators.required
          ),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          condition_list: new FormArray([condition])
        })
      );
    }
  }

  /**
   * Description: Adds new Condition List to Case i
   * @param i index of Case
   * @returns void
   */
  public addConditionCase(i: number) {
    const cases = this.formTest.get('cases') as FormArray;
    const case_to_add = cases.controls[i] as FormGroup;
    const case_conditions = case_to_add.controls.condition_list as FormArray;
    let condition_list_condition_type = '';
    let simple_multiple = false;
    let disabledSelect = false;
    let condition = new FormGroup({});
    if (!this.validationService.isType(['Preliminary'])) {
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        precondition: new FormControl(false),
        preconditions: new FormArray([]),
        conditions: new FormArray([], Validators.required)
      });
    } else {
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        conditions: new FormArray([], Validators.required)
      });
    }
    if (this.validationService.isType(['Preliminary'])) {
      condition_list_condition_type = 'should';
      simple_multiple = false;
      disabledSelect = true;
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        conditions: new FormArray([], Validators.required),
        datamodel_order_document: new FormControl(''),
        field_order_document: new FormControl(''),
        order_document: new FormControl('')
      });
    }
    case_conditions.push(condition);
  }

  /**
   * Description: Adds new Pre Condition to Case i
   * @param caseIndex index of Case
   * @returns void
   */
  public addCasePreCondition(caseIndex: number) {
    const cases = this.formTest.get('cases') as FormArray;
    const case_to_add = cases.controls[caseIndex] as FormGroup;
    const case_conditions = case_to_add.controls.preconditions as FormArray;
    const precondition = this.getPreCondition();
    case_conditions.push(precondition);
  }

  public getPreCondition() {
    if (this.validationService.getValidationTypeName() === 'Business Rules') {
      return this.businessService.getPreCondition();
    } else {
      return this.catalogService.getPreCondition();
    }
  }

  public removeCasePreConditions(caseIndex: number) {
    const cases = this.formTest.get('cases') as FormArray;
    const conditions = cases.controls[caseIndex] as FormGroup;
    const case_conditions = conditions.controls.preconditions as FormArray;
    case_conditions.clear();
  }

  /**
   * Save current validation
   */
  private saveValidation() {
    const postMessage = this.isNew
      ? this.translate.transform('validation.validationCreated')
      : this.translate.transform('validation.validationUpdated');
    this.loadingSave = true;
    this.apiService
      .post(
        'validations',
        this.formTest.getRawValue(),
        postMessage,
        true,
        {},
        true
      )
      .subscribe(
        newData => {
          if (this.validationService.isType(['Extraction', 'Business Rules'])) {
            this.global.updateDatamodel(newData);
            this.loadingSave = false;
            this.router.navigate([
              `/${this.tenantName}`,
              'datamodels-validations'
            ]);
          } else if (this.validationService.isType(['Preliminary'])) {
            this.global.getAllDataModels(1);
            this.afterPreliminaryUpdate = this.global
              .watchDataModels()
              .subscribe((data: string) => {
                this.loadingSave = false;
                this.router.navigate([
                  `/${this.tenantName}`,
                  'datamodels-validations'
                ]);
              });
            // this.global.updatePreliminary(this.formTest.getRawValue().validationid, newData);
          } else if (this.validationService.isType(['Catalog'])) {
            this.global.updateCatalog(
              this.formTest.getRawValue().validationid,
              newData
            );
            this.loadingSave = false;
            this.router.navigate([
              `/${this.tenantName}`,
              'datamodels-validations'
            ]);
          }
        },
        () => {
          this.loadingSave = false;
        }
      );
  }

  /**
   * Get Case name
   * @param caseName
   */
  private getCaseName(caseName: string) {
    return caseName
      ? this.translate.transform('validation.' + caseName + 'Condition')
      : this.translate.transform('validation.newCase');
  }

  /**
   * Toggle case expanded status
   * @param index case index number
   */
  private toggleExpandedCase(index: number) {
    const index_element = this.expandedCases.indexOf(index);
    if (index_element >= 0) {
      this.expandedCases.splice(index_element, 1);
    } else {
      this.expandedCases.push(index);
    }
  }

  /**
   * Check if case is expanded
   * @param index case index number
   */
  private isExpandedCase(index: number) {
    return this.expandedCases.indexOf(index) !== -1;
  }

  /**
   * Get errors for general box
   */
  private getGeneralErrors() {
    const generalFields = [
      'name',
      'general_confidence',
      'docnameoverride',
      'uniquedoc',
      'latestdoc'
    ];
    for (let i = 0; i < generalFields.length; i++) {
      if (
        this.validationService.controlHasError(this.formTest, generalFields[i])
      ) {
        return this.validationService.getControlError(generalFields[i]);
      }
    }
    return false;
  }

  /**
   * Get errors for datamodel list box
   * @param formGroup datamodelist form group
   */
  private getDatamodelListErrors(formGroup) {
    if (
      Object.keys(formGroup['controls']).some(
        key => formGroup.get(key).get('id').invalid
      )
    ) {
      // Some datamodel input is invalid
      return this.validationService.getControlError('datamodelsAreEmpty');
    }
    if (
      !Object.keys(formGroup['controls']).some(
        key => formGroup.get(key).get('datamodeltrigger').value === true
      )
    ) {
      // No trigger is marked
      return this.validationService.getControlError('triggerRequired');
    }
    return false;
  }

  /**
   * Get errors for each case
   * @param casesCtrl case form group
   */
  public getCaseErrors(casesCtrl) {
    if (this.validationService.controlHasError(casesCtrl, 'condition_type')) {
      return this.validationService.getControlError('condition_type');
    }
    if (this.validationService.controlHasError(casesCtrl, 'case_name')) {
      return this.validationService.getControlError('case_name');
    }

    const conditionLists = casesCtrl.get('condition_list') as FormArray;
    if (
      Object.values(conditionLists['controls']).some(
        (conditionList, i) => conditionList.invalid
      )
    ) {
      // Some condition list is invalid
      return this.validationService.getControlError('someConditionIsInvalid');
    }
    return false;
  }
}
