// Status codes
export const ID_IN_PROGRESS = 1;
export const ID_SUCCESS = 2;
export const ID_PARTIAL = 3;
export const ID_FAILURE = 4;
export const ID_PENDING = 5;
export const ID_WARNING = 6;
export const ID_EXTRACTION_PENDING = 7;
export const ID_EXTRACTION_IN_PROGRESS = 8;
export const ID_EXTRACTION_FAILURE = 9;
export const ID_BR_PENDING = 10;
export const ID_BR_IN_PROGRESS = 11;
export const ID_BR_FAILURE = 12;
export const ID_PRELIMINARY_PENDING = 13;
export const ID_PRELIMINARY_IN_PROGRESS = 14;
export const ID_PRELIMINARY_FAILURE = 15;
export const ID_CATALOG_PENDING = 16;
export const ID_CATALOG_IN_PROGRESS = 17;
export const ID_CATALOG_FAILURE = 18;

// Status names
export const IN_PROGRESS = 'in-progress';
export const SUCCESS = 'success';
export const MANUAL_SUCCESS = 'manual-success';
export const PARTIAL = 'partial-success';
export const FAILURE = 'failure';
export const PENDING = 'pending';
export const SKIPPED = 'skipped';
export const WARNING = 'warning';
export const EXTRACTION_PENDING = 'extraction-pending';
export const EXTRACTION_IN_PROGRESS = 'extraction-in-progress';
export const EXTRACTION_FAILURE = 'extraction-failure';
export const BR_PENDING = 'br-pending';
export const BR_IN_PROGRESS = 'br-in-progress';
export const BR_FAILURE = 'br-failure';
export const PRELIMINARY_PENDING = 'preliminary-pending';
export const PRELIMINARY_IN_PROGRESS = 'preliminary-in-progress';
export const PRELIMINARY_FAILURE = 'preliminary-failure';
export const CATALOG_PENDING = 'catalog-pending';
export const CATALOG_IN_PROGRESS = 'catalog-in-progress';
export const CATALOG_FAILURE = 'catalog-failure';
