import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../../../common-components/validation.service';
import { PreliminaryService } from '../../../../preliminary-components/preliminary.service';

@Component({
  selector: 'app-preliminary-type',
  templateUrl: './preliminary-type.component.html',
  styleUrls: ['./preliminary-type.component.scss']
})
export class PreliminaryTypeComponent implements OnInit {
  @Input('control') control: FormGroup;
  public fieldsOfInterest: any = {};

  constructor(
    public validationService: ValidationService,
    public preliminaryService: PreliminaryService
  ) {}

  ngOnInit() {
    ['0', '2'].forEach(position => {
      const formGroup = this.control.get('operations').get(position);

      if (formGroup.get('datamodelid')) {
        formGroup.get('datamodelid').valueChanges.subscribe(value => {
          if (value) {
            this.getFieldsOfInterest(+value, position);
          }
        });
        if (formGroup.get('datamodelid').value) {
          this.getFieldsOfInterestPopulation(
            formGroup.get('datamodelid').value,
            position
          );
        }
      }
      if (formGroup.get('fieldofinterestid')) {
        formGroup.get('fieldofinterestid').valueChanges.subscribe(value => {
          if (value) {
            this.getGroupOfFieldName(+value, position);
          }
        });
      }
    });
  }

  /**
   * Description: set fields of interest of datamodel
   * @param datamodelid datamodel ID
   * @param position position of array variable to store fields of interest
   */
  private getFieldsOfInterest(datamodelid: number, position: string) {
    this.control
      .get('operations')
      .get(position)
      .get('fieldofinterestid')
      .setValue('');
    this.control
      .get('operations')
      .get(position)
      .get('groupoffieldsid')
      .setValue('');
    this.fieldsOfInterest[position] =
      this.validationService.getGroupOfFieldsOfDatamodel(+datamodelid);
  }

  /**
   * Description: Populate function when edit validation
   * @param datamodelid datamodel ID setted
   * @param position position to of datamodel array
   */
  getFieldsOfInterestPopulation(datamodelid: number, position: string) {
    this.fieldsOfInterest[position] =
      this.validationService.getGroupOfFieldsOfDatamodel(+datamodelid);
    let fieldOfInterestId = this.control
      .get('operations')
      .get(position)
      .get('fieldofinterestid').value;
    this.getGroupOfFieldName(+fieldOfInterestId, position);
  }

  /**
   * Retrieve group of field name of a field of interest
   * to print in HTML
   * @param fieldOfInterestId field of interest ID
   * @param position position to of datamodel array
   */
  getGroupOfFieldName(fieldOfInterestId: number, position: string) {
    const groupOfField =
      this.validationService.getGroupOfFieldFromFieldOfInterest(
        fieldOfInterestId,
        this.fieldsOfInterest[position]
      );
    if (groupOfField) {
      this.control
        .get('operations')
        .get(position)
        .get('groupoffieldsid')
        .setValue(groupOfField.groupoffieldsid);
    }
  }

  /**
   * Get non-empty datamodels from service
   */
  filteredDatamodels() {
    const datamodels = this.preliminaryService.getPreliminaryDatamodels();
    return [...datamodels].filter(
      datamodel => Object.entries(datamodel).length
    );
  }
}
