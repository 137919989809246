import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {
  DisabilityDiagnosisInformation,
  DisabilityDisabilityInformation,
  DisabilityGeneralInformation,
  DisabilityHospitalSurgeryInformation,
  DisabilityInformation,
  DisabilityMedicationInformation,
  DisabilityOverviewDiagnosisInformationItem,
  DisabilityOverviewInformation,
  DisabilityPersonalInformation,
  DisabilityPhysicianInformation,
  DisabilityTreatmentInformation,
  RestrictionsLimitationsInformation,
} from 'src/app/models/document-dashboard.model';
import { DisabilityPanelComponent } from './disability-panel/disability-panel.component'

@Component({
  selector: 'app-disability-dashboard',
  templateUrl: './disability-dashboard.component.html',
  styleUrls: ['./disability-dashboard.component.scss'],
})
export class DisabilityDashboardComponent
  implements OnInit {

  @Input() dashboardData: DisabilityInformation;

  isInfoPanelVisible: any = {};

  claimNumber: string;
  generalInfo: DisabilityGeneralInformation;
  personalInfo: DisabilityPersonalInformation;
  disabilityInfo: DisabilityDisabilityInformation;
  restrictionsLimitationsInfo: RestrictionsLimitationsInformation;
  disabilityOverviewInfo: DisabilityOverviewInformation;
  overviewDiagnosisInfo: DisabilityOverviewDiagnosisInformationItem[];
  overviewHospitalSurgeryInfo: DisabilityHospitalSurgeryInformation;
  overviewMedicationInfo: DisabilityMedicationInformation;
  overviewTreatmentInfo: DisabilityTreatmentInformation;
  physicianInfo: DisabilityPhysicianInformation;
  diagnosisInfo: DisabilityDiagnosisInformation;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.setDashboardData();
  }

  /**
   * Extract parts of dashboards data.
   */
  private setDashboardData() {
    this.claimNumber =
      this.dashboardData?.claimnumber;

    this.generalInfo =
      this.dashboardData?.generalinformation;

    this.personalInfo =
      this.generalInfo?.personalinformation;

    this.disabilityInfo =
      this.generalInfo?.disabilityinformation;

    this.restrictionsLimitationsInfo =
      this.dashboardData?.restrictionslimitations;

    this.disabilityOverviewInfo =
      this.dashboardData?.disabilityoverview;

    this.overviewDiagnosisInfo =
      this.disabilityOverviewInfo?.diagnosis;

    this.overviewHospitalSurgeryInfo =
      this.disabilityOverviewInfo?.hospitalsurgeryinformation;

    this.overviewMedicationInfo =
      this.disabilityOverviewInfo?.medication;

    this.overviewTreatmentInfo =
      this.disabilityOverviewInfo?.treatment;

    this.physicianInfo =
      this.dashboardData?.physicianinformation;

    this.diagnosisInfo =
      this.dashboardData?.diagnosisinformation;
  }

  /**
   * Show panel.
   */
  public showPanel(
    contentType: string,
    contentTab?: string | number
  ): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'md'
    };
    const modalWindowRef = this.modalService.open(
      DisabilityPanelComponent,
      modalOptions
    );
    const panelComponent: DisabilityPanelComponent =
      modalWindowRef.componentInstance;

    panelComponent.dashboardData = this.dashboardData;
    panelComponent.contentType = contentType;
    panelComponent.contentTab = contentTab;
  }

  /**
   * Toggle info panel visibility.
   */
  public toggleInfoPanelVisibility(panelName: string) {
    this.isInfoPanelVisible[panelName] = !this.isInfoPanelVisible[panelName];
  }

}
