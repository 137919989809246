import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bulk-edit-fields',
  templateUrl: './bulk-edit-fields.component.html',
  styleUrls: ['./bulk-edit-fields.component.scss']
})
export class BulkEditDocumentFieldsComponent implements OnInit {
  @Input() options;
  @Output() savedEvent = new EventEmitter<any>();
  public reason = Reason;
  public form: FormGroup;
  public fieldsList: Array<any> = [];

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit() {
    this.fieldsList = this.options.fieldsGroup.fieldslist[0].map(field => {
      return {
        id: field.fieldid,
        name: field.fieldname
      };
    });
    this.form = this.fb.group({
      field: ['', Validators.required],
      value: [''],
      override: ['overrideAll', Validators.required]
    });
  }

  public onSubmit() {
    this.savedEvent.emit({
      extractedfieldids: this.options.fieldsGroup.fieldslist.map(row => {
        return row.find(field => field.fieldid === this.form.get('field').value)
          .extractedfieldid;
      }),
      extractedvaluemodified: this.form.get('value').value,
      override: this.form.get('override').value
    });
    this.activeModal.close(this.reason.submitBtn);
  }
}
