import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Alert, AlertType } from '../models/alert.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AlertService {
  public static readonly ALERT_TIMEOUT = 9000;

  private keepAfterRouteChange = false;
  private alertInfo: BehaviorSubject<Alert>;

  constructor(private router: Router) {
    this.alertInfo = new BehaviorSubject<Alert>(new Alert());
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
        }
      }
    });
  }

  success(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('success', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, AlertService.ALERT_TIMEOUT);
  }

  error(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('danger', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, AlertService.ALERT_TIMEOUT);
}

  info(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('info', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, AlertService.ALERT_TIMEOUT);
}

  warn(message: string, keepAfterRouteChange = false, optional = '') {
    this.alert('warning', message, keepAfterRouteChange, optional);
    const that = this;
    setTimeout(function () {
      that.clear();
    }, AlertService.ALERT_TIMEOUT);
}

  alert(
    type: AlertType,
    message: string,
    keepAfterRouteChange = false,
    optional = ''
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.setValue(<Alert>{ type: type, message: message, optional: optional });
  }

  clear() {
    this.setValue(null);
  }

  getValue(): Observable<Alert> {
    return this.alertInfo.asObservable();
  }

  setValue(newValue): void {
    this.alertInfo.next(newValue);
  }
}
