import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { StyleManagerService } from 'src/app/services/stylemanager.service';

@Component({
  selector: 'app-analysis-overview-view',
  templateUrl: './analysis-overview-view.component.html',
  styleUrls: ['./analysis-overview-view.component.scss']
})
export class AnalysisOverviewViewComponent implements OnInit {
  public qualityData;
  public timeData;
  public usersData;
  public statusData;
  public qualityDataConfig;
  public timeDataConfig;
  public usersDataConfig;
  public statusDataConfig;
  public loading: boolean;

  @Input() analysisId: number | string;

  constructor(
    private global: GlobalService,
    private styleManager: StyleManagerService
  ) { }

  ngOnInit() {
    const style = this.styleManager.styleSettings;
    const statusChartStyle = style.charts.status;

    this.timeDataConfig = {
      key: 'total',
      label: 'month',
      height: 340,
      colorScheme:  style.charts.time.colorScheme,
      yAxis: 'Documents'
    };
    this.usersDataConfig = {
      key: 'total',
      label: 'name',
      height: 340,
      labelRotation: 15,
      yAxis: 'Documents'
    };
    this.qualityDataConfig = {
      keys: ['Blur', 'Dimness', 'Rotation', 'Size', 'Tilt'],
      label: 'name',
      height: 340,
      maxValue: 5,
      minValue: 1
    };
    this.statusDataConfig = {
      key: 'name',
      value: 'value',
      height: 340,
      colorScheme: statusChartStyle.colorScheme,
      colorKeys: {
        Pending: statusChartStyle.colorKeys.pending,
        Failure: statusChartStyle.colorKeys.failure,
        Success: statusChartStyle.colorKeys.success,
        'In Progress': statusChartStyle.colorKeys.inProgress,
        Warning: statusChartStyle.colorKeys.warning,
        'Partial Success': statusChartStyle.colorKeys.partialSuccess
      }
    };
    this.getChartData();
  }

  /**
   * Get Charts data from storage or database
   */
  private getChartData() {
    this.loading = true;
    this.global.getCharts('analysis', this.analysisId).then(data => {
      this.global.setCharts('analysis', this.analysisId, data);
      this.qualityData = data[0];
      this.statusData = data[1];
      this.timeData = data[2];
      this.usersData = data[3];
      this.loading = false;
    });
  }
}
