import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../security/authentication.service';
import {ApiService} from '../../services/api.service';
import {UserModalComponent} from '../../components/modals/user-modal/user-modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services/global.service';
import {UsersTableComponent} from '../../components/users-table/users-table.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild(UsersTableComponent)
  private usersTable: UsersTableComponent;
  public url: string;
  public currentTab: string;

  // Const
  public _userPermissions: any;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private global: GlobalService
  ) {
    this.currentTab = 'users';
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  ngOnInit() {}

  /**
   * Load modal to edit user
   */
  public onCreateUser() {
    this.usersTable.onCreateUser();
  }

  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }
}
