import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { DashboardsModel, DashboardsModelDashboards } from 'src/app/models/document-dashboard.model';
import { DocumentDashboardService } from './document-dashboard.service';


@Component({
  selector: 'app-document-dashboard',
  templateUrl: './document-dashboard.component.html',
  styleUrls: ['./document-dashboard.component.scss'],
})
export class DocumentDashboardComponent
  implements OnInit, OnDestroy {

  @Input() documentId: string;
  @Input() dashboardModel: string;

  dashboardModelName: string;
  dashboards: DashboardsModelDashboards;
  loadingDashboards: boolean;
  dashboardsSubscription: Subscription;

  constructor(
    private documentDashboardService: DocumentDashboardService
  ) { }

  ngOnInit(): void {
    this.setDashboardName();
    this.loadDashboards();
  }

  ngOnDestroy(): void {
    this.dashboardsSubscription?.unsubscribe();
  }

  /**
   * Set which dashboard is displayed.
   */
  private setDashboardName(): void {
    switch (this.dashboardModel) {
      case 'lifeunderwriting':
        this.dashboardModelName = 'lifeunderwriting';
        break;

      case 'disability':
        this.dashboardModelName = 'disability';
        break;
    }
  }

  /**
   * Load dashboards.
   */
  private loadDashboards(): void {
    this.dashboardsSubscription?.unsubscribe();

    this.loadingDashboards = true;

    this.dashboardsSubscription =
      this.documentDashboardService
        .getDashboards(this.documentId)
        .subscribe((dashboards: DashboardsModel) => {
          this.dashboards = dashboards.dashboards;
          this.loadingDashboards = false;
        });
  }

}
