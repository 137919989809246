// Validation status codes
export const ID_IN_PROGRESS = 1;
export const ID_SUCCESS = 2;
export const ID_MANUAL_SUCCESS = 3;
export const ID_FAILURE = 4;
export const ID_PENDING = 5;
export const ID_MANUAL_FAILURE = 6;
export const ID_FAILURE_NOT_APPLICABLE = 7;
export const ID_SKIPPED = 8;

// Validation status names
export const IN_PROGRESS = 'in-progress';
export const SUCCESS = 'success';
export const MANUAL_SUCCESS = 'manual-success';
export const FAILURE = 'failure';
export const PENDING = 'pending';
export const MANUAL_FAILURE = 'manual-failure';
export const FAILURE_NOT_APPLICABLE = 'failure-not-applicable';
export const FAILURE_NA = 'failure-n/a';
export const SKIPPED = 'skipped';

// Display names
export const IN_PROGRESS_DISPLAYNAME = 'In Progress';
export const SUCCESS_DISPLAYNAME = 'Success';
export const MANUAL_SUCCESS_DISPLAYNAME = 'Manual Success';
export const FAILURE_DISPLAYNAME = 'Failure';
export const PENDING_DISPLAYNAME = 'Pending';
export const MANUAL_FAILURE_DISPLAYNAME = 'Manual Failure';
export const FAILURE_NOT_APPLICABLE_DISPLAYNAME = 'Failure N/A';
export const SKIPPED_DISPLAYNAME = 'Skipped';
