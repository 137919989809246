import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TitleCasePipe } from '@angular/common';

import { LifeUnderWritingInformation } from 'src/app/models/document-dashboard.model';
import { StyleManagerService } from 'src/app/services/stylemanager.service';
import { Reason } from 'src/app/models/close-reason.model';
import {
  BodyHistoryData,
  ImpairmentsData,
  MedicationsHistoryData,
  MenuItem,
  PanelData,
  VitalsHistoryData
} from './lifeunderwriting-panel-data';

@Component({
  selector: 'app-lifeunderwriting-panel',
  templateUrl: './lifeunderwriting-panel.component.html',
  styleUrls: ['./lifeunderwriting-panel.component.scss'],
})
export class LifeUnderWritingPanelComponent
  implements OnInit, AfterViewInit {

  @Input() dashboardData: LifeUnderWritingInformation;
  @Input() contentType: string;
  @Input() contentTab: string;

  closeReason = Reason;
  modalTitle: string;
  data: PanelData;
  menu: MenuItem[];
  selectedMenuItem: MenuItem;
  contentTitle: string;
  showGraphs: boolean;
  itemsPerPage: number = 5;
  currentPage = {};

  constructor(
    public activeModal: NgbActiveModal,
    private styleManager: StyleManagerService,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.showGraphs = false;
    this.initContent();
  }

  ngAfterViewInit(): void {
    this.showGraphs = true;
  }

  /**
   * Initialize content.
   */
  private initContent(): void {
    const style = this.styleManager.styleSettings;

    switch (this.contentType) {
      case 'body':
        this.modalTitle = 'History - Body';
        this.data = new BodyHistoryData(this.dashboardData, style);
        break;

      case 'vitals':
        this.modalTitle = 'History - Vitals';
        this.data = new VitalsHistoryData(this.dashboardData, style)
        break;

      case 'medications':
        this.modalTitle = 'All Medication';
        this.data = new MedicationsHistoryData(this.dashboardData);
        break;

      case 'impairments':
        this.modalTitle = 'Medical Conditions';
        this.data = new ImpairmentsData(this.dashboardData, this.titleCasePipe)
        break;
    }

    this.menu = this.data?.menu;

    if (this.menu?.length) {
      const initialMenuItem =
        this.contentTab ?
          this.menu.find((menuItem: MenuItem) =>
            menuItem.contentTab == this.contentTab)
          : this.menu[0];

      this.selectMenuItem(initialMenuItem);
    }
  }

  /**
   * Select menu item.
   */
  public selectMenuItem(menuItem: MenuItem): void {
    this.selectedMenuItem = menuItem;
    this.contentTab = menuItem.contentTab;
    this.contentTitle = menuItem.contentTitleParts.join(' - ');
  }

  /**
   * Called when a page of a paginated item list is selected.
   */
  public onPageChange(id: string, page: number): void {
    this.currentPage[id] = page;
  }

}
