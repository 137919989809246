import { Injectable } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../../../../components/modals/delete/delete.component';
import { ErrorModalComponent } from '../../../../../../components/modals/error/error.component';
import { ApiService } from 'src/app/services/api.service';
import { ValidationFunctionModel } from '../../../../../../models/validation-function-mstr.model';
import { ValidationListModel } from '../../../../../../models/validation-list.model';
import { ValidationTypeModel } from '../../../../../../models/validation-type-mstr.model';
import { ValidationMultipleDocsListModel } from '../../../../../../models/validation-multiple-docs-list.model';
import { DatamodelModel } from 'src/app/models/datamodel.model';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  public validationFunctions: ValidationFunctionModel[];
  public validationTypeName;
  public validationList: ValidationListModel;
  public validationTypes: ValidationTypeModel[];
  public fieldsofinterest;
  public groupoffields;
  public validationMultipleDocsList: ValidationMultipleDocsListModel;
  public validationTypeId: number;
  public businessNumberValueTypes = ['days', 'months', 'years', 'number'];

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private global: GlobalService,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) {}

  /**
   * Check if a Form or a part of it has a control
   * @param casesCtrl Form
   * @param key       control key name
   * @returns boolean depends it has key or not
   *
   */
  public hasKey(formCtrl, key) {
    return formCtrl ? key in formCtrl.getRawValue() : false;
  }

  /**
   * Delete condition from list
   * @param condition condition to delete to acces to its parent
   * @param index index of condition to delete
   * @returns void
   */
  public deleteCondition(condition, index) {
    this.deleteModalValidation(condition.parent, index, 'condition');
  }

  /**
   * Delete Case or Condtion List only if has more than one child
   * @param parent where we gonna delete a case or a condition list
   * @param index index to delete
   * @param type to know if its case  or condition list for the message
   * @returns void
   */
  public deleteCaseOrConditionList(parent, index, type) {
    if (parent.length <= 1) {
      this.errorModalValidation('validation.cannotDelete');
    } else {
      this.deleteModalValidation(parent, index, type);
    }
  }

  /**
   * Check if case or condition can be deleted
   * @param parent control's parent formgroup
   */
  public canDeleteCaseOrConditionList(parent) {
    return parent.length > 1 && !this.isType(['Extraction']);
  }

  /**
   * Delete Modal for delete case, condition list or conditions
   * @param parent  where we gonna delete a case or a condition list
   * @param index index to delete
   * @param message variable that what we are gonna delete
   * @returns Form without case, condition list or condition
   */
  private deleteModalValidation(parent, index, message) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: message,
      notAllowed: false
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          parent.removeAt(index);
        }
      },
      reason => {}
    );
  }

  /**
   * Modal Error when just exists one child
   * @param message Message to show in Error Modal
   * @returns Error Modal Message
   */
  private errorModalValidation(message) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      body: message
    };
    const modalWindowRef = this.modalService.open(
      ErrorModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {},
      reason => {}
    );
  }

  /**
   * Retrieve childs functions
   * @param id id of the current function
   * @returns List of children functions
   */
  public findChildFunctions(id) {
    return this.validationFunctions.filter(vf => vf.validationparent === id);
  }

  /**
   * Retrieve a list of list that contains all possible options for each level
   * e.g  [ [·Number, Text, Date], [Float, Integer, ·Decimal], [ '·,', '.']
   * Used for population
   * @param childFunction List of child functions
   * @param parentList  List of parent functions
   * @return List of parent functions
   */
  public getParentList(childFunction, parentList) {
    this.validationFunctions.forEach(validationFunction => {
      if (
        +validationFunction.validationfunctionid ===
        +childFunction.validationparent
      ) {
        parentList.push(validationFunction);
        this.getParentList(validationFunction, parentList);
      }
    });
    return parentList;
  }

  /**
   * Gets entire object of function by ID
   * @param funcId ID of the function
   * @returns Function object
   */
  public getFunctionById(funcId: number) {
    return this.validationFunctions.find(
      vf => vf.validationfunctionid === +funcId
    );
  }

  /**
   * Retrieve validation functions if setted or call to API to rerieve them
   * @returns Promise of Functions
   */
  public getFunctions() {
    this.validationFunctions = this.global.getFunctions();
  }

  /**
   * Retrieve validation types if setted or call to API to rerieve and set them
   * @returns Promise of Validation Types
   */
  public getValidationTypes() {
    return this.validationTypes;
  }

  /**
   * Set global validation types
   * @param validationTypes validation types to set
   * @returns void
   */
  public setValidationTypes(validationTypes) {
    this.validationTypes = validationTypes;
  }

  /**
   * Set validation type name
   * @param validationTypeName validation type name
   * @returns void
   */
  public setValidationTypeName(validationTypeName) {
    this.validationTypeName = validationTypeName;
  }

  /**
   * Set group of fields variable
   * @param groupoffields group of fields of datamodel
   */
  public setGroupOfFields(groupoffields) {
    this.groupoffields = groupoffields;
  }

  /**
   * Get group of fields variable
   */
  public getGroupOfFields() {
    return this.groupoffields ? [...this.groupoffields] : [];
  }

  /**
   * Set fields of interest
   * @param fieldsofinterest fields of interest to set
   * @returns void
   */
  public setFieldsOfInterest(fieldsofinterest) {
    this.fieldsofinterest = fieldsofinterest;
  }

  /**
   * Get Group of field of a field of interest
   * @param fieldOfInterestId field of interest id
   * @param groupOfFields group of fields
   * @returns group of fields ID
   */
  public getGroupOfFieldFromFieldOfInterest(fieldOfInterestId, groupOfFields) {
    let searchGroupoffield;
    groupOfFields.forEach(groupoffield => {
      groupoffield.fieldsofinterest.forEach(fieldofinterest => {
        if (fieldofinterest.fieldofinterestid === fieldOfInterestId)
          searchGroupoffield = groupoffield;
      });
    });
    return searchGroupoffield;
  }

  /**
   * Get group of fields from datamodel
   * @param datamodelid id of data model
   * @returns group of fields
   */
  public getGroupOfFieldsOfDatamodel(datamodelid: number) {
    const datamodel = this.global.getDataModelById(+datamodelid);
    return datamodel ? [...datamodel.groupoffields] : [];
  }

  /**
   * Get group of fields ID from field of interest ID without knowing the datamodel
   * (Datamodels are retrieved from service)
   * @param fieldOfInterestId field of Interest ID
   * @param groupsOfFields Groups Of Fields
   * @returns Group of fields ID
   */
  public searchGroupOfFieldFromFieldOfInterest(
    fieldOfInterestId: number,
    groupsOfFields
  ) {
    const gofFound = groupsOfFields.find(
      gof =>
        gof.fieldsofinterest
          .map(foi => foi.fieldofinterestid)
          .indexOf(fieldOfInterestId) > -1
    );
    return gofFound ? gofFound.groupoffieldsid : null;
  }

  /**
   * Get fields of interest and set it into global variable
   * @param value id of data model
   * @returns field of interest
   */
  public async getFieldsOfInterest(value) {
    return this.getFieldsOfInterestApi(value).then(fieldsOfInterest => {
      this.setFieldsOfInterest(fieldsOfInterest);
      return Promise.resolve(this.fieldsofinterest);
    });
  }

  /**
   * API call to retrieve fields of interest of a datamodel
   * @param datamodelid datamodel id
   * @returns Promise of GET of field of interest
   */
  private getFieldsOfInterestApi(datamodelid) {
    return this.apiService
      .get(
        'groupoffields/fieldofinterest-by-groupoffields-list/',
        { datamodelid },
        ''
      )
      .toPromise();
  }

  /**
   * Return empty template for multiple condition
   */
  public getMultipleCondition() {
    return new FormGroup({
      condition_type: new FormControl('', Validators.required),
      precondition: new FormControl(false),
      preconditions: new FormArray([]),
      conditions: new FormArray([], Validators.required),
      description: new FormControl(''),
      simple_multiple: new FormControl(true, Validators.required)
    });
  }

  /**
   * Populate Condition Lists and call functions to populate simple and multiple conditions
   * Recursive function
   * @param condition_lists conditions lists to populate data
   * @returns condition list filled
   */
  public fillConditionListGeneral(condition_lists, fillConditionVariable) {
    const result_condition_list = new FormArray([]);

    condition_lists
      .filter(condition_list => condition_list.conditions.length > 0)
      .forEach(condition_list => {
        // TODO: Remove string comparison once all
        // stored validations has simple_multiple field as boolean
        if (
          condition_list.simple_multiple === false ||
          condition_list.simple_multiple === 'single'
        ) {
          // is simple
          const conditions = fillConditionVariable(
            condition_list.conditions,
            this.existOnDataModel,
            this.global
          );
          const single_condition_structure = new FormGroup({
            condition_type: new FormControl(condition_list.condition_type),
            description: new FormControl(condition_list.description),
            conditions: conditions,
            simple_multiple: new FormControl(false)
          });
          if (this.isType(['Preliminary'])) {
            single_condition_structure.addControl(
              'datamodel_order_document',
              new FormControl(condition_list.datamodel_order_document)
            );
            single_condition_structure.addControl(
              'field_order_document',
              new FormControl(condition_list.field_order_document)
            );
            single_condition_structure.addControl(
              'order_document',
              new FormControl(condition_list.order_document)
            );
          }
          result_condition_list.push(single_condition_structure);
        } else {
          // is multiple
          const conditions = this.fillConditionListGeneral(
            condition_list.conditions,
            fillConditionVariable
          );
          result_condition_list.push(
            new FormGroup({
              condition_type: new FormControl(condition_list.condition_type),
              conditions: conditions,
              description: new FormControl(condition_list.description),
              simple_multiple: new FormControl(true)
            })
          );
        }
      });
    return result_condition_list;
  }

  /**
   * Genaral function for fill simple condition, used in business, catalog and preliminary
   * @param cases cases to iterate
   * @param formTest form to populate data
   * @param fillSimpleCondition function to fill simple condition
   */
  public fillCasesGeneral(cases, formTest, fillSimpleCondition) {
    const formCases = formTest.get('cases') as FormArray;
    const disabledSelect = this.isType(['Preliminary', 'Extraction']);
    cases.forEach(validation_case => {
      formCases.push(
        new FormGroup({
          case_name: new FormControl(
            validation_case.case_name,
            Validators.required
          ),
          case_description: new FormControl(validation_case.case_description),
          condition_type: new FormControl({
            value: validation_case.condition_type,
            disabled: disabledSelect
          }),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          condition_list: this.fillConditionListGeneral(
            validation_case.condition_list,
            fillSimpleCondition
          )
        })
      );
      if ('not_editable' in validation_case) {
        const formCase: any = formCases['controls'][formCases.length - 1];
        formCase.addControl(
          'not_editable',
          new FormControl(validation_case.not_editable)
        );
      }
    });
  }

  /**
   * Retrieve group of field name of a field of interest
   * to print in HTML
   * @returns group of field name
   */
  public getGroupOfFieldName(fieldOfInterestId, fieldsofinterest) {
    const groupOfField = this.getGroupOfFieldFromFieldOfInterest(
      fieldOfInterestId,
      fieldsofinterest
    );
    return groupOfField ? groupOfField.groupoffieldsname : '';
  }

  /**
   * Add or remove control dependending pf the value type slected
   * @param control form control to add or remove value type control
   */
  public selectValueType(control) {
    control.get('value').setValue('');
    control.get('value').setValidators(Validators.required);
  }

  /**
   * Return values of type number
   */
  public getBusinessNumberTypes() {
    return this.businessNumberValueTypes;
  }

  /**
   * Check if fieldofinterest exists on datamodel yet
   * @param fieldofinterestid field of interest id to check
   * @param groupoffieldid froup of field id to check
   * @param datamodelid datamodel id to compare its fields
   */
  public existOnDataModel(
    fieldofinterestid,
    groupoffieldid,
    datamodelid,
    global
  ) {
    const datamodelObj = global.getDataModelById(+datamodelid);
    if (!datamodelObj) {
      return false;
    }
    const groupoffieldObj = datamodelObj.groupoffields.find(
      gof => gof.groupoffieldsid === +groupoffieldid
    );
    return (
      groupoffieldObj &&
      groupoffieldObj.fieldsofinterest.find(
        foi => foi.fieldofinterestid === +fieldofinterestid
      )
    );
  }

  public fieldOfInterestExistsOnDatamodel(fieldofinterestid, datamodelid) {
    const datamodel = this.global.getDataModelById(+datamodelid);
    const fieldsOnDatamodel = datamodel.groupoffields.reduce((acc, gof) => {
      return acc.concat(gof.fieldsofinterest.map(foi => foi.fieldofinterestid));
    }, []);
    return fieldsOnDatamodel.some(fieldid => fieldid === +fieldofinterestid);
  }

  /**
   * Get validation type name
   * @returns validationTypeName
   */
  public getValidationTypeName() {
    return this.validationTypeName;
  }

  /**
   * Check if validation is a certain type
   * @param types: validation types: ['Extraction', 'Business Rules', ...]
   * @returns boolean
   */
  public isType(types: Array<string>) {
    return types.indexOf(this.validationTypeName) !== -1;
  }

  /**
   * Check if form group has an erroneus control by its name
   * @param formGroup Parent FormGroup
   * @param controlName FormControl name
   */
  public controlHasError(formGroup, controlName: string) {
    return (
      this.hasKey(formGroup, controlName) && formGroup.get(controlName).invalid
    );
  }

  /**
   * Get control error translated
   * @param controlName FormControl name
   */
  public getControlError(controlName: string) {
    return this.translate.transform(`validation.required.${controlName}`);
  }

  /**
   * Get control error translated
   * @param controlName FormControl name
   */
  public touchControl(control, controlName: string) {
    if (this.hasKey(control, controlName)) {
      control.get(controlName).markAsTouched();
    }
  }
}
