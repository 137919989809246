import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { NotHasPermissionDirective } from './not-has-permission.directive';
import { EventTrackerDirective } from './event-tracker.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HasPermissionDirective,
    NotHasPermissionDirective,
    EventTrackerDirective
  ],
  exports: [
    HasPermissionDirective,
    NotHasPermissionDirective,
    EventTrackerDirective
  ]
})
export class DirectivesModule {}
