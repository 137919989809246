import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';

import { AuthenticationParameters } from 'msal';
import { environment } from 'src/environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function msalInstanceConfig(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azure_application_id,
      authority: 'https://login.microsoftonline.com/'+ environment.azure_tenant_id,
      redirectUri: window.location.origin + '/',
      postLogoutRedirectUri: window.location.origin + '/',
      knownAuthorities: []
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function msalInterceptorConfig(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']),
  protectedResourceMap.set('http://localhost:8000', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function msalGuardConfig(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    //loginFailedRoute: '/login-failed'
  };
}

export function msalDefaultSilentRequest(forceRefresh?: boolean): SilentRequest {
  const configAuthRequest = msalGuardConfig().authRequest as any;
    const request: SilentRequest = {
      scopes: configAuthRequest.scopes,
      forceRefresh
    }
  return request;
}

export function msalDefaultRedirectRequest(): RedirectRequest {
  const configAuthRequest = msalGuardConfig().authRequest as any;
  const request: RedirectRequest = {
    scopes: configAuthRequest.scopes,
  }
  return request;
}

export function msalAuthenticationParameters(forceRefresh?: boolean): AuthenticationParameters{
  return {
    scopes:[
      'user.read',
      'openid',
      'profile',
    ],
    forceRefresh
  }
}

