import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import * as userPermissionsConst from '../../constants/userPermissions';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor() {}
}
