import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-selection-viewer',
  templateUrl: './selection-viewer.component.html',
  styleUrls: ['./selection-viewer.component.scss']
})
export class SelectionViewerComponent {

  //responsiveTableData: any;
  
  @Input() items = []
  @Input() displayAttribute;
  @Input() data;
  @Output() removeItem = new EventEmitter();
  @Input() removeAllItems = new EventEmitter();

  constructor(
  ) {
  }

  remove(item): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
    this.removeItem.emit({items: this.items, index: item, data: this.data});
  }
}
