import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { ProjectModel } from '../models/project-mstr.model';
import { AnalysisModel } from '../models/analysis.model';
import { DocumentModel } from '../models/document.model';
import { ValidationMultipleDocsListModel } from '../models/validation-multiple-docs-list.model';
import { DocumentFieldDataModel } from '../models/document-field-data.model';
import { ValidationService } from '../pages/datamodels/datamodel-validation-list/validation/new-validation/common-components/validation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../security/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  public _userPermissions: any;
  public isExternal: boolean = false;
  constructor(
    private router: Router,
    private global: GlobalService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    this._userPermissions = this.global.getUserPermissionsConst();
  }
  /**
   * Go to generic route
   */
  public goToRoute(routes: Array<string>) {
    this.router.navigate(routes);
  }

  /**
   * Go to project
   */
  public goToProject(project: ProjectModel, flag: boolean = false) {
    this.global.setProject(project);
    const slug = this.getSlugFromEntity(project.projectid, project.url);
    if (flag) {
      const url = this.router.createUrlTree(
        this.getTenantRoute().concat(['projects', slug])
      );
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigate(this.getTenantRoute().concat(['projects', slug]));
    }
  }

  /**
   * Go to analysis
   */
  public goToAnalysis(analysis: AnalysisModel, flag: boolean = false) {
    this.global.setAnalysis(analysis);
    const projectslug = this.getSlugFromEntity(
      analysis.project.projectid,
      analysis.project.url
    );
    const analysisslug = this.getSlugFromEntity(
      analysis.analysisid,
      analysis.url
    );
    if (flag) {
      const url = this.router.createUrlTree(
        this.getTenantRoute().concat([
          'projects',
          projectslug,
          'analysis',
          analysisslug
        ])
      );
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigate(
        this.getTenantRoute().concat([
          'projects',
          projectslug,
          'analysis',
          analysisslug
        ])
      );
    }
  }

  /**
   * Go to document
   */
  public goToDocument(
    document: DocumentModel | DocumentFieldDataModel,
    flag: boolean = false
  ) {
    if (document instanceof DocumentModel) {
      this.global.setDocument(document);
    }
    if (document.project && document.analysis) {
      const projectslug = this.getSlugFromEntity(
        document.project.projectid,
        document.project.url
      );
      const analysisslug = this.getSlugFromEntity(
        document.analysis.analysisid,
        document.analysis.url
      );
      const documentslug = this.getSlugFromEntity(
        document.documentid,
        document.url
      );
      if (flag) {
        const url = this.router.createUrlTree(
          this.getTenantRoute().concat([
            'projects',
            projectslug,
            'analysis',
            analysisslug,
            'documents',
            documentslug
          ])
        );
        window.open(url.toString(), '_blank');
      } else {
        this.router.navigate(
          this.getTenantRoute().concat([
            'projects',
            projectslug,
            'analysis',
            analysisslug,
            'documents',
            documentslug
          ])
        );
      }
    } else {
      this.global
        .getAnalysis(document.analysis.analysisid)
        .then((analysis: AnalysisModel) => {
          const projectslug = this.getSlugFromEntity(
            analysis.project.projectid,
            analysis.project.url
          );
          const analysisslug = this.getSlugFromEntity(
            analysis.analysisid,
            analysis.url
          );
          const documentslug = this.getSlugFromEntity(
            document.documentid,
            document.url
          );
          if (flag) {
            const url = this.router.createUrlTree(
              this.getTenantRoute().concat([
                'projects',
                projectslug,
                'analysis',
                analysisslug,
                'documents',
                documentslug
              ])
            );
            window.open(url.toString(), '_blank');
          } else {
            this.router.navigate(
              this.getTenantRoute().concat([
                'projects',
                projectslug,
                'analysis',
                analysisslug,
                'documents',
                documentslug
              ])
            );
          }
        });
    }
  }

  /**
   * Check if is externalViewer section
   */
  public controlExternalAccess() {
    const url = this.router.parseUrl(this.router.url);
    if (url.root.children.primary) {
      const segments = url.root.children.primary.segments;
      const parentRouting = segments[1].path;
      return parentRouting === 'externalViewer' ? true : false;
    }
  }

  /**
   * Go to child document
   */
  public goToChildDocument(document: DocumentModel, flag: boolean = false) {
    this.controlExternalAccess();
    if (this.isExternal) {
      const documentslug = this.getSlugFromEntity(
        document.documentid,
        document.url
      );
      this.global
        .getDocument(document.parentdocumentid)
        .then((parentdocument: DocumentModel) => {
          const parentdocumentslug = this.getSlugFromEntity(
            parentdocument.documentid,
            parentdocument.url
          );
          if (flag) {
            const url = this.router.createUrlTree(
              this.getTenantRoute().concat(
                'externalViewer',
                'documents',
                parentdocumentslug,
                'documents',
                documentslug
              )
            );
            window.open(url.toString(), '_blank');
          } else {
            this.router.navigate(
              this.getTenantRoute().concat(
                'externalViewer',
                'documents',
                parentdocumentslug,
                'documents',
                documentslug
              )
            );
          }
        });
    } else {
      this.global
        .getAnalysis(document.analysis.analysisid)
        .then((analysis: AnalysisModel) => {
          const projectslug = this.getSlugFromEntity(
            analysis.project.projectid,
            analysis.project.url
          );
          const analysisslug = this.getSlugFromEntity(
            analysis.analysisid,
            analysis.url
          );
          const documentslug = this.getSlugFromEntity(
            document.documentid,
            document.url
          );
          let routes = ['projects', projectslug, 'analysis', analysisslug];

          if (!document.isparentdocument) {
            this.global
              .getDocument(document.parentdocumentid)
              .then((parentdocument: DocumentModel) => {
                const parentdocumentslug = this.getSlugFromEntity(
                  parentdocument.documentid,
                  parentdocument.url
                );
                if (flag) {
                  const url = this.router.createUrlTree(
                    this.getTenantRoute().concat(
                      routes.concat([
                        'documents',
                        parentdocumentslug,
                        'documents',
                        documentslug
                      ])
                    )
                  );
                  window.open(url.toString(), '_blank');
                } else {
                  this.router.navigate(
                    this.getTenantRoute().concat(
                      routes.concat([
                        'documents',
                        parentdocumentslug,
                        'documents',
                        documentslug
                      ])
                    )
                  );
                }
              });
          } else {
            this.router.navigate(
              this.getTenantRoute().concat(
                routes.concat(['documents', documentslug])
              )
            );
          }
        });
    }
  }

  public goToExternalDocument(
    document: DocumentModel | DocumentFieldDataModel
  ) {
    const documentslug = this.getSlugFromEntity(
      document.documentid,
      document.url
    );
    this.router.navigate(
      this.getTenantRoute().concat([
        'externalViewer',
        'documents',
        documentslug
      ])
    );
  }

  /**
   * Go to querysheet
   */
  public goToQuerysheet(querysheet) {
    this.global.setQuerysheet(querysheet);
    this.router.navigate(
      [`${this.getTenantRoute()}/querysheet-management/querysheet-details`],
      {
        queryParams: { querysheetId: querysheet.querySheetId }
      }
    );
  }

  /**
   * Go to query sheets management/list 
   */
  public goToQuerySheetsManagement(tab="querysheetList") {
    this.global.setCurrentTab(tab)
    this.router.navigate(
      this.getTenantRoute().concat(['querysheet-management'])
    );
  }

  /**
   * Go to exploration
   */
  public gotToExploration(exploration) {
    this.router.navigate(
      [`${this.getTenantRoute()}/querysheet-management/exploration-details`],
      {
        queryParams: { explorationId: exploration.explorationId, querysheetId: exploration.querySheetId }
      }
    );
  }

  /**
   * Check that project slug is correct
   * If not, redirect to correct one
   */
  public checkProjectSlug(slug: string, project: ProjectModel) {
    if (!this.checkEntitySlug(slug, project.projectid, project.url)) {
      this.goToProject(project);
    }
  }

  /**
   * Check that analysis slug is correct
   * If not, redirect to correct one
   */
  public checkAnalysisSlug(slug: string, analysis: AnalysisModel) {
    if (!this.checkEntitySlug(slug, analysis.analysisid, analysis.url)) {
      this.goToAnalysis(analysis);
    }
  }

  /**
   * Check that document slug is correct
   * If not, redirect to correct one
   */
  public checkDocumentSlug(
    slug: string,
    document: DocumentModel | DocumentFieldDataModel
  ) {
    if (!this.checkEntitySlug(slug, document.documentid, document.url)) {
      this.goToDocument(document);
    }
  }

  /**
   * Check that slug is correct
   */
  public checkEntitySlug(slug: string, id: number, name: string) {
    return slug === this.getSlugFromEntity(id, name);
  }

  /**
   * Construct slug structure (12-lorem-ipsum-dolor)
   */
  public getSlugFromEntity(id: number, name: string) {
    return `${id}-${name}`;
  }

  /**
   * Get id from slug (12-lorem-ipsum returns 12)
   */
  public getIdFromSlug(slug) {
    return slug.split('-')[0];
  }

  /**
   * get tenant route
   *
   */
  getTenantRoute() {
    const tenantName = this.authService.getTenantName();
    return [tenantName];
  }

  /**
   * Go to environment
   */
  public goToEnvironment(env) {
    this.router.navigate(this.getTenantRoute().concat(['queues', env]));
  }
}
