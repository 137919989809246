/**
 * Encapsulates changes to document checklist.
 */
export class DocumentChecklistSelection {
  /**
   * Map of checklist item ids to selected status.
   */
  private map_itemId_status: Map<string, string>;

  /**
   * Number of selected items.
   */
  public numSelectedItems: number;

  public constructor() {
    this.map_itemId_status = new Map<string, string>();
  }

  /**
   * Get item status.
   */
  public getItemStatus(itemId: string): string {
    return this.map_itemId_status.get(itemId);
  }

  /**
   * Toggle item status.
   *
   * Set status of specified item.
   *
   * If item already has this status, the status is cleared.
   *
   * @returns the final status of the item.
   */
  public toggleItemStatus(itemId: string, status: string): string {
    if (this.map_itemId_status.get(itemId) == status) {
      this.map_itemId_status.delete(itemId);
      status = undefined;
    } else {
      this.map_itemId_status.set(itemId, status);
    }

    this.numSelectedItems = this.map_itemId_status.size;

    return status;
  }

  /**
   * Set item status.
   */
  public setItemStatus(itemId: string, status: string): void {
    this.map_itemId_status.set(itemId, status);
    this.numSelectedItems = this.map_itemId_status.size;
  }

  /**
   * Convert selected items to array.
   */
  public toArray(): any[] {
    const array = [];

    const iterator = this.map_itemId_status.entries();
    iterate: while (true) {
      const nextItem = iterator.next();
      if (nextItem.done) {
        break iterate;
      }
      const [itemId, status] = nextItem.value;

      const itemData = DocumentChecklistSelection.createItemData(
        itemId,
        status
      );

      array.push(itemData);
    }

    return array;
  }

  /**
   * Create document selection item data.
   */
  private static createItemData(itemId: string, status: string) {
    return {
      checklistitemexecutionid: itemId,
      status: status
    };
  }
}
