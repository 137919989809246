import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { FileDownloadService } from '../../services/file-download.service';
import { NoOperationAllowedModalComponent } from '../../components/modals/no-operation-allowed/no-operation-allowed.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() showFilePreview: boolean = false;
  @Input() explorationQueryData: any = null;
  @Input() selectedResult: any = null;
  @Input() results: any = null;
  @Output() setShowFilePreviewFlag = new EventEmitter();
  @Output() fetchResultsForSelectedQuery = new EventEmitter();
  @Input() selectedQuery:any = null;
  @Input() usecaseLevels: any = null;
  @ViewChild('searchinputfield', { static: false }) refSearchInputField: ElementRef;
  public scale = 0.75;
  public isFullPreview = false;
  public showThumbnails = false;
  public manualFeedbackSelections: any = [];
  public disableDoneButtonOnPreview: boolean = false;
  public pdfContolButtonsDisabled: boolean = false;
  public zoomLevelsDDOptions = ['50', '75', '100', '200', '400']
  public selectedZoomLevel = '100'
  public showSearchInputField = false;
  public searchKeyword: string = '';
  public totalKeywordSearchResult: number = 0;
  public currentKeywordSearchResult: number = 0;
  public disableKeywordSearchInput: boolean = false;
  constructor(
    private apiService: ApiService,
    private fileDownloadService: FileDownloadService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {}

  fullPreview(flag) {
    if (this.pdfContolButtonsDisabled) {
      return
    }
    this.isFullPreview = flag;
    if (flag) {
      document.body.style.overflow = 'hidden'
      this.showThumbnails = true;
    } else {
      this.showThumbnails = false;
    }
  }

  downloadFile() {
    this.fileDownloadService.downloadFile(
      this.selectedResult.documentId,
      '',
      'download/explorationDocument'
    )
  }

  closeFilePreview() {
    this.setShowFilePreviewFlag.emit(false);
    this.manualFeedbackSelections = [];
    this.scale = 0.75;
    this.selectedZoomLevel = '100';
    this.selectedResult = null;
    this.isFullPreview = false;
    this.pdfContolButtonsDisabled = false;
    document.body.style.overflow=''
    this.showThumbnails = false;
    this.searchKeyword = "";
    this.showSearchInputField = false;
  }

  handleClickOutsideFilePreview(e) {
    if (!document.getElementById('filePreviewModal').contains(e.target) && e.target.parentElement.id != 'full-screen' && e.target.parentElement.id != 'full-screen-exit') {
      this.closeFilePreview();
    }
  }

  setManualFeedbackSelections(feedbacks) {
    this.manualFeedbackSelections = [...feedbacks]
  }

  getModalOption() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      message: this.translate.transform('components.modal.noOperationAllowed.message'),
      title: this.translate.transform('components.modal.noOperationAllowed.title'),
      primaryButtonText: this.translate.transform('components.modal.noOperationAllowed.goBack')
    };
    return {
      modalOptions,
      options
    }
  }

  saveManualFeedback() {
    let selectedFeedbacks =  [...this.manualFeedbackSelections];
    if (selectedFeedbacks.length > 0) {
      const payload = {
        explorationId: this.explorationQueryData.explorationId,
        queryIds: this.explorationQueryData.queries.map(query => query.queryId),
        chunkId: this.selectedResult.chunkId,
        semanticResultId: this.selectedResult.semanticResultId,
        selections: selectedFeedbacks
      }
    
      // TO DO: Save manual feedbacks to DB and close the preview
      this.apiService.post('feedback/modify', payload, '', true, {}, false, true)
      .subscribe(
        event => {
          // re-fetch the search result and manual feedbacks 
          this.fetchResultsForSelectedQuery.emit();
          this.closeFilePreview();
        },
        error => {
          if (error.status === 405 && error?.error === 'exploration is modified') {
            const { modalOptions, options } = this.getModalOption()
            const modalWindowRef = this.modalService.open(
              NoOperationAllowedModalComponent,
              modalOptions
            );
            modalWindowRef.componentInstance.options = options;
          } else {
            this.alertService.error(this.translate.transform('explorationDetails.feedbackSaveError'));
          }
        }
      );
    }
  }

  setDisableDoneButtonOnFilePreview(flagVal: boolean) {
    this.disableDoneButtonOnPreview = flagVal;
  }

  disablePDFPreviewControlButtons(flagVal: boolean) {
    this.pdfContolButtonsDisabled = flagVal;
  }

  public setScale(scale) {
    this.scale = scale;
  }

  public setSelectedZoomLevel(zoomLevel){
    this.selectedZoomLevel = zoomLevel
  }

  public zoomLevelChanged(zoomlevel) {
    if (this.pdfContolButtonsDisabled) {
      return
    }
    this.setSelectedZoomLevel(zoomlevel)
    if (zoomlevel == '50') {
      this.setScale(0.37)
    } else {
      this.setScale(0.75 * (zoomlevel / 100))
    }
  }

  public changeScale(type){
    if (this.pdfContolButtonsDisabled) {
      return
    }
    /*
        Note:- below is a zoom mapping table (% to decimal value)
        100% => 0.75
        75% => 0.56
        50% => 0.38
        25% => 0.19
        */
    let changeFactor = this.scale >= 0.75 ? 0.25 : 0.19;
    let zoomVal;
    if (type === 'incr' && this.scale < 3){
      if ((Math.round((this.scale + Number.EPSILON) * 100) / 100) === 0.38){
        //when increasing from 50% to 75%
        changeFactor = 0.185;
      }
      zoomVal = Math.ceil((+this.scale.toFixed(2) + changeFactor) * 133.33);
      this.setSelectedZoomLevel(zoomVal.toString())
      this.setScale(this.scale + changeFactor)
    } else if (type === 'decr' && (Math.round((this.scale + Number.EPSILON) * 100) / 100) > 0.37){
      if (this.scale === 0.75){
        // this block is to achieve 50% and 75% zoom level
        changeFactor = 0.19;
      } else if ((Math.round((this.scale + Number.EPSILON) * 100) / 100) === 0.37) {
        // this block is to achieve 25% zoom level
        changeFactor = 0.185;
      }
      zoomVal = Math.ceil((+this.scale.toFixed(2) - changeFactor) * 133.33);
      this.setSelectedZoomLevel(zoomVal.toString())
      this.setScale(this.scale - changeFactor)
    }
    if (zoomVal && this.zoomLevelsDDOptions.indexOf(zoomVal.toString()) == -1) {
      this.zoomLevelsDDOptions.push(zoomVal.toString())
      this.zoomLevelsDDOptions = this.zoomLevelsDDOptions.sort((a:any, b:any) => a - b);
    }
  }

  public toggleThumbnails(flag) {
    this.showThumbnails = flag;
  }

  public showSearchInput(flag) {
    setTimeout(() => {
      if(!flag && !this.searchKeyword){
        this.showSearchInputField = flag;
      } else if (flag) {
        this.showSearchInputField = flag;
      }
    }, 100)
    if (flag) {
      setTimeout(() => {
        this.refSearchInputField.nativeElement.focus();
      }, 500)
    }
  }

  public clearSearchKeyword() {
    setTimeout(() => {
      this.searchKeyword = ''
      this.refSearchInputField.nativeElement.focus();
    }, 100)
  }

  public navigateSearchKeywordInDocument(searchDirection) {
    let newValue = this.currentKeywordSearchResult;
    if (searchDirection == 'up') {
      newValue--;
      if(newValue === 0 ){
        newValue = this.totalKeywordSearchResult;
      }
    } else if (searchDirection == 'down') {
      newValue++;
      if(newValue > this.totalKeywordSearchResult){
        newValue = 1
      }
    }
    this.currentKeywordSearchResult = newValue;
  }

  public setKeywordSearchResultsData(matchesCount) {
    this.currentKeywordSearchResult = matchesCount?.current || 0;
    this.totalKeywordSearchResult = matchesCount?.total || 0;
  }

  public setDisableKeywordSearchInput(flag:boolean) {
    this.disableKeywordSearchInput = flag;
  }
}
