/** Usecase contants start here */
export const usecases = {
    SEMANTIC_WITH_QNA: "Semantic with QnA",
    ONLY_SEMANTIC: "Semantic"
}
/** Usecase contants end here */

/** Feedback related constants start here */
export const feedbackTypes = {
    SEMANTIC: "Semantic",
    QNA: "QnA"
}

export const feedbackValues = {
    ACCEPT: "accept",
    REJECT: "reject",
    MODIFY: "modify"
};
/** Feedback related constants end here */