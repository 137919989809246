import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from '../../security/authentication.service';
import { AddCommentValidationComponent } from '../modals/add-comment-validation/add-comment-validation.component';
import { DocumentFieldDataModel } from 'src/app/models/document-field-data.model';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-document-comments-table',
  templateUrl: './document-comments-table.component.html',
  styleUrls: ['./document-comments-table.component.scss']
})
export class DocumentCommentsTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() documentId: number;
  @Input() documentDetails: any;
  @Input() readonly: boolean;

  // Responsive table fixed variables
  public columns: any[];
  public loading: boolean = true;
  public backgroundLoading: boolean = false;
  public settingTable: any;
  rows = {};
  public pagination: PaginationModel;
  private user: any = {};
  public _validationTypes: any;
  private showComments: boolean;
  private update: boolean;
  public errorsmappingSelect: any;
  private approval: any = {};
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  public actionDisabled: boolean;
  public _userPermissions: any;

  constructor(
    private apiService: ApiService,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private globalService: GlobalService,
    public link: LinkService,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.settingTable = this.getSettingTable();
    this.pagination = this.paginationService.getDefaultPagination('comments');
    this.user = this.authService.getLoggedInUser();
    this.showComments = this.authService.userCanViewModule(
      this.user,
      'ManualCommentsFunctionality'
    );
    this._validationTypes = this.globalService.getValidationTypesConst();
    this.errorsmappingSelect = this.globalService.getErrorsMapping();
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  ngOnDestroy() {}

  ngOnInit() {
    this.getReviewersTypes();
    this.update = false;
    this.loading = true;
    this.actionDisabled = false;
  }

  ngOnChanges() {
    this.getDocumentManualComments();
  }

  /**
   * Get manual document comments from storage or database
   */
  private getDocumentManualComments() {
    const documentPage = this.paginationService.getPage('comments');
    const defaultPage = this.paginationService.getDefaultPagination('comments');
    if (
      documentPage &&
      documentPage.data &&
      documentPage.data.length &&
      documentPage.page === 1 &&
      documentPage.order_by === defaultPage.order_by &&
      documentPage.desc === defaultPage.desc
    ) {
      // If document are loaded, show them and hide spinner
      this.rows = documentPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If document aren't loaded, show spinner
      this.loading = false;
    }

    // Always request document from db
    this.getDocumentFromDB(this.pagination, this.documentId, this.update);
  }

  /**
   * Return loaded SettingTable with the general settings for the component responsive table
   */
  public getTypeError(id) {
    let errorname;
    this.errorsmappingSelect.forEach(element => {
      if (id === element.errorid) {
        errorname = element.errorname;
      }
    });
    return errorname;
  }

  public getComments(result) {
    let data = JSON.parse(JSON.stringify(result));
    const originalData = JSON.parse(JSON.stringify(data));

    const dataToShowIni =
      this.pagination.page * this.pagination.page_size -
      this.pagination.page_size;
    const dataToShowFin = this.pagination.page * this.pagination.page_size;

    data = data.slice(dataToShowIni, dataToShowFin);

    this.rows['total_elements'] = originalData.length;
    this.rows['page'] = this.pagination.page;
    this.rows['page_size'] = this.pagination.page_size;
    if (originalData.length <= data.length) {
      this.rows['num_pages'] = Math.floor(
        originalData.length / this.pagination.page_size
      );
      if (originalData.length % this.pagination.page_size > 0) {
        this.rows['num_pages'] = this.rows['num_pages'] + 1;
      }
    } else {
      this.rows['num_pages'] = 1;
    }

    this.rows['order_by'] = this.pagination.order_by;
    this.rows['desc'] = this.pagination.desc;
    this.rows['data'] = data;

    this.rows['data'].forEach(element => {
      element['modifiedby'] = element['modifiedby']['username'];
      element['errorname'] = this.getTypeError(element['errorid']);
    });

    this.paginationService.setPage('comments', data);
    this.pagination = this.paginationService.setPagination('comments', {
      total_elements: data.length,
      page: this.rows['page'],
      page_size: this.rows['page_size'],
      num_pages: this.rows['num_pages'],
      order_by: this.rows['order_by'],
      desc: this.rows['desc'],
      query: this.pagination.query
    });
    this.loading = false;
    this.backgroundLoading = false;
  }

  /**
   * Get document details (document and fields) from global service
   */
  public getDocumentFromDB(pagination, documentId, update) {
    if (pagination) {
      this.pagination = pagination;
    } else {
      pagination = this.paginationService.getPagination('comments');
    }

    if (documentId) {
      this.documentId = documentId;
    } else {
      documentId = this.documentId;
    }

    if (!pagination || !documentId) {
      return;
    }
    this.apiService
      .get(
        'manualvalidationexecutionerrors/',
        { documentid: this.documentId },
        ''
      )
      .subscribe(data => {
        this.getComments(data);
        this.loading = false;
        this.backgroundLoading = false;
      });
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'validation.documentValidation.comment',
        name: 'errormessages',
        title: 'errormessages',
        class: 'ellipsis-text big-size-column'
      },
      {
        header: 'validation.errorType',
        name: ['errorname', 'modifiedby', 'modifieddate'],

        headerNames: {
          errorname: 'validation.errorType',
          modifiedby: 'validation.documentValidation.lastModified',
          modifieddate: 'validation.documentValidation.modifiedDate'
        },
        type: 'datamodel-data',
        headerClass: 'ellipsis-text big-size-column',
        format: 'll'
      },
      {
        header: 'components.modal.consolidate.amount',
        name: 'amount',
        title: 'amount'
      }
    ];

    if (!this.readonly) {
      this.columns = this.setAdditionalColumns();
    }

    return this.columns;
  }

  private setAdditionalColumns() {
    let validationsModule: any[] = [
      {
        header: ' ',
        type: 'link-fa-icon',
        clickLink: this.onEditComment.bind(this),
        classIcon: this.isDisable.bind(this, 'fa fa-edit '),
        title: this.isDisableHover.bind(this, true)
      },
      {
        header: ' ',
        type: 'link-fa-icon',
        clickLink: this.onDeleteComment.bind(this),
        classIcon: this.isDisable.bind(this, 'fa fa-trash '),
        title: this.isDisableHover.bind(this, false)
      }
    ];
    this.columns.splice(3, 0, ...validationsModule);
    return this.columns;
  }

  public isDisable(cssClass, row) {
    if (
      (this.approvalIsAssignedToUser() && this.user.isadmin) ||
      (this.approvalIsAssignedToUser() &&
        row.createdby.userid == this.user.userid)
    ) {
      this.actionDisabled = false;
      return cssClass;
    } else {
      this.actionDisabled = true;
      return cssClass + 'is-disabled';
    }
  }

  public isDisableHover(action, row) {
    if (
      (this.approvalIsAssignedToUser() && this.user.isadmin) ||
      (this.approvalIsAssignedToUser() &&
        row.createdby.userid == this.user.userid)
    ) {
      return action
        ? 'validation.documentValidation.editComment'
        : 'validation.documentValidation.deleteComment';
    } else {
      return 'validation.documentValidation.disableActionComment';
    }
  }

  /**
   * Return loaded SettingTable with the general settings
   * for the component responsive table
   */
  public getSettingTable() {
    return {
      dataId: 'idcomment',
      getDataFromDB: this.getDocumentFromDB.bind(this.documentId, this.update),
      responsiveTitle: {
        label: 'commentdisplayname'
      },
      paginationClass: 'relative-pag',
      classIcon: this.isDisable.bind(this, 'fa fa-trash ')
    };
  }

  /**
   * Functions to construct the form data to pass
   * as param in the post request
   * @param form html form data
   */
  public getFormData(form) {
    const params = new FormData();
    params.append('documentid', this.documentId.toString());
    params.append('amount', form.amount);
    params.append('errorid', form.errorid);
    params.append('message', form.message);
    return params;
  }

  /**
   * Load modal to create new comment
   */
  public onAddComment() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options: any = {};
    options.type = 'new';
    options.action = 'create';
    options.data = {
      textComment: '',
      select: null,
      amount: null
    };

    const modalWindowRef = this.modalService.open(
      AddCommentValidationComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(comment => {
      if (comment !== 0) {
        const formComment = comment;
        const params = this.getFormData(formComment);
        this.update = true;
        this.apiService
          .post('manualvalidationexecutionerrors', params, '', false)
          .subscribe(
            data => {
              this.getDocumentFromDB(
                this.pagination,
                this.documentId,
                this.update
              );
              this.loading = false;
              this.alertService.success(
                this.translate.transform(
                  'validation.documentValidation.commentCreated'
                ),
                true
              );
            },
            () => {
              this.alertService.error(
                this.translate.transform(
                  'validation.documentValidation.commentNotCreated'
                ),
                true
              );
              this.loading = false;
              this.update = false;
            }
          );
      }
    });
  }

  /**
   * Load modal to create new comment
   */
  public onEditComment(row) {
    if (!this.actionDisabled) {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'sm'
      };

      const options: any = {};
      options.type = 'new';
      options.action = 'create';
      options.data = {
        textComment: row.errormessages,
        select: row.errorid,
        amount: row.amount,
        documentId: this.documentId
      };

      const modalWindowRef = this.modalService.open(
        AddCommentValidationComponent,
        modalOptions
      );
      modalWindowRef.componentInstance.options = options;
      modalWindowRef.result.then(comment => {
        if (comment !== 0) {
          const formComment = comment;
          const params = this.getFormData(formComment);
          this.update = true;
          this.apiService
            .put(
              'manualvalidationexecutionerrors/',
              row.validationerrorid,
              comment,
              ''
            )
            .subscribe(
              data => {
                this.getDocumentFromDB(
                  this.pagination,
                  this.documentId,
                  this.update
                );
                this.loading = false;
                this.alertService.success(
                  this.translate.transform(
                    'validation.documentValidation.commentUpdated'
                  ),
                  true
                );
              },
              () => {
                this.alertService.error(
                  this.translate.transform(
                    'validation.documentValidation.commentNotUpdating'
                  ),
                  true
                );
                this.loading = false;
                this.update = false;
              }
            );
        }
      });
    }
  }

  /**
   * Delete comment
   */
  public onDeleteComment(row) {
    if (!this.actionDisabled) {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'sm'
      };
      const options = {
        type: 'comments'
      };
      const modalWindowRef = this.modalService.open(
        DeleteModalComponent,
        modalOptions
      );
      modalWindowRef.componentInstance.options = options;
      modalWindowRef.result.then(
        result => {
          if (result === 1) {
            this.loading = true;
            this.apiService
              .delete(
                'manualvalidationexecutionerrors/',
                row.validationerrorid,
                ''
              )
              .subscribe(
                _ => {
                  this.getDocumentFromDB(
                    this.pagination,
                    this.documentId,
                    this.update
                  );
                  this.loading = false;
                  this.alertService.success(
                    this.translate.transform(
                      'validation.documentValidation.commentDeleted'
                    ),
                    true
                  );
                },
                () => {
                  this.alertService.error(
                    this.translate.transform(
                      'validation.documentValidation.commentNotDeleted'
                    ),
                    true
                  );
                  this.loading = false;
                  this.update = false;
                }
              );
          }
        },
        reason => {
          console.log('Reason ' + reason);
        }
      );
    }
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
        });
    } else {
      this.setApproval();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer or admin
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
    this.columns = this.getColumns();
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid == this.user.userid
    );
  }
}
