import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { AuthenticationService } from '../security/authentication.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input('appHasPermission') hasPermission: string[];
  public user: any = {};

  constructor(
    private element: ElementRef,
    private authService: AuthenticationService
  ) {
    this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    if (
      !this.user.isadmin &&
      !this.authService.hasPermission(this.hasPermission)
    ) {
      this.element.nativeElement.style.display = 'none';
    }
  }
}
