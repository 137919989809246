import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss']
})
export class DocumentSummaryComponent implements OnInit {
  @Input() documentDetails: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
