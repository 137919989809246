import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { RouterModule } from '@angular/router';
import { CreateAnalysisPages } from '../../components/modals/create-analysis/create-analysis.pages';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '../../translate/translate.module';
import { ComponentWsModule } from '../../components/component-ws.module';
import { DirectivesModule } from '../../directives/directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ComponentWsModule,
    DirectivesModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    UserMenuComponent,
    CreateAnalysisPages
  ],
  exports: [HeaderComponent, SidebarComponent, UserMenuComponent],
  entryComponents: [CreateAnalysisPages]
})
export class ThemeComponentModule {}
