import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, Subscription } from 'rxjs';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentValidationService {
  public collapsibleExtractionState: any;
  public collapsibleCatalogState: any;
  public collapsibleBusinessState: any;
  public collapsiblePrelimaniryState: any;
  public _validationTypes: any;

  constructor(private global: GlobalService) {
    this._validationTypes = this.global.getValidationTypesConst();
  }

  /**
   * Retrieve collapsible state of Catalog
   */
  public getCollapsibleCatalogState() {
    return this.collapsibleCatalogState;
  }

  /**
   * Cahnage collapsible state of Catalog
   */
  public setPreviousSearchCatalog(newCollapsibleStateCatalog) {
    this.collapsibleCatalogState = newCollapsibleStateCatalog;
  }

  /**
   * Retrieve collapsible state of Business Rules
   */
  public getCollapsibleBusinessState() {
    return this.collapsibleBusinessState;
  }

  /**
   * Change collapsible state of Business Rules
   */
  public setPreviousSearchBusiness(newCollapsibleStateBusiness) {
    this.collapsibleBusinessState = newCollapsibleStateBusiness;
  }

  /**
   * Retrieve collapsible state of Preliminary
   */
  public getCollapsiblePreliminaryState() {
    return this.collapsiblePrelimaniryState;
  }

  /**
   * Change collapsible state of Preliminary
   */
  public setPreviousSearchPreliminary(newCollapsibleStatePreliminary) {
    this.collapsiblePrelimaniryState = newCollapsibleStatePreliminary;
  }

  /**
   * Retrieve collapsible state of Extraction
   */
  public getCollapsibleExtractionState() {
    return this.collapsibleExtractionState;
  }

  /**
   * Change collapsible state of Extraction
   */
  public setPreviousSearchExtraction(newCollapsibleStateExtraction) {
    this.collapsibleExtractionState = newCollapsibleStateExtraction;
  }

  /**
   * Set the comment and attached file variables to show
   * them properly in the list
   * @param item
   */
  public setCommentAndFilesAttached(item, tests) {
    item.comment = this.getCommentConditions(item, tests);
    if (item.comment) {
      item.fileAttached = item.comment.comment_attachments[0];
    }
  }

  /**
   * Return the comment for the conditions
   * @param item
   */
  public getCommentConditions(item, point) {
    return point.comments.find(c =>
      c.conditions_comment.find(
        conditionId =>
          conditionId.validationexecutionconditionsid ===
          item.validationexecutionconditionsid
      )
    );
  }

  /**
   * Serialize the data for showing the conditions
   * in the collapsible list. This function is for the cases
   * that the data has three levels (validation --> execution --> conditions)
   * This kind of data is in Preliminary and catalog validations.
   * @param data
   */
  public serializeConditions(data, documentDetails, validationsStatus) {
    let tests = { ...data };
    tests = data.conditions.map(condition => {
      const parsedCondition = { ...condition };
      const messageLines = parsedCondition.message.split(' #-#');
      if (messageLines[messageLines.length - 1].trim() === '') {
        messageLines.pop();
      }
      const messageLinesMapped = messageLines.map(function (messageLine) {
        let newMessageLine = null;
        const messageLineSplit = messageLine.split('~');
        if (messageLineSplit.length > 2) {
          newMessageLine = {
            message: messageLineSplit[0] + messageLineSplit[2],
            row: messageLineSplit[1]
          };
        } else {
          newMessageLine = {
            message: messageLine,
            row: null
          };
        }
        return newMessageLine !== null ? newMessageLine : messageLine;
      });
      parsedCondition.messageLines = messageLinesMapped;
      validationsStatus.forEach(status => {
        if (parsedCondition.validationstatusid === status.validationstatusid) {
          parsedCondition.status = status.validationstatusname;
          parsedCondition.statusName = status.validationstatusdisplayname;
        }
      });
      parsedCondition.validationtypename =
        tests.validationtypeid === this._validationTypes.ID_PRELIMINARY
          ? this._validationTypes.PRELIMINARY
          : '';
      parsedCondition.documentId = documentDetails.documentid;

      // Parse preconditions 
      if (parsedCondition.preconditions) {
        parsedCondition.preconditions = parsedCondition.preconditions.map(precondition => {
            return this.global.preconditionToString(documentDetails.datamodel.datamodelid,precondition);
        });
      }

      if (tests.comments) {
        this.setCommentAndFilesAttached(parsedCondition, tests);
      }
      return parsedCondition;
    });
    return tests;
  }

  public cleanCollapsibleState() {
    this.collapsibleCatalogState = {};
    this.collapsibleBusinessState = {};
    this.collapsiblePrelimaniryState = {};
    this.collapsibleExtractionState = {};
  }
}
