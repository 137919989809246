import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAiResult'
})
export class SortAiResultPipe implements PipeTransform {

  transform(value: any, args: any): any[] {
    return value.sort((n1, n2) => {
      if(n1.implicit){
        return 0;
      } else {
        return -1
      }
    });
  }
}
