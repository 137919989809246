import {
  DisabilityDiagnosisInformation,
  DisabilityInformation,
  RestrictionsLimitationsInformation
} from "src/app/models/document-dashboard.model";

/**
 * Panel data.
 */
export class PanelData {
  claimNumber: string;

  public constructor(
    dashboardData: DisabilityInformation
  ) {
    this.claimNumber = dashboardData.claimnumber;
  }
}

/**
 * Restrictions and limitations data.
 */
export class RestrictionsLimitationsData extends PanelData {

  claimNumber: string;
  restrictionsLimitationsInfo: RestrictionsLimitationsInformation;

  public constructor(
    dashboardData: DisabilityInformation
  ) {
    super(dashboardData);
    this.initContent(dashboardData);
  }

  /**
   * Initialize content.
   */
  private initContent(dashboardData: DisabilityInformation) {
    this.restrictionsLimitationsInfo =
      dashboardData.restrictionslimitations;
  }
}

/**
 * Diagnosis data.
 */
export class DiagnosisData extends PanelData {
  diagnosisInfo: DisabilityDiagnosisInformation;

  public constructor(
    dashboardData: DisabilityInformation
  ) {
    super(dashboardData);
    this.initContent(dashboardData);
  }

  /**
   * Initialize content.
   */
  private initContent(dashboardData: DisabilityInformation) {
    this.diagnosisInfo =
      dashboardData.diagnosisinformation;
  }
}
