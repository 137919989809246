import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateQuerysheetComponent } from '../../../components/modals/create-querysheet/create-querysheet.component';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { LinkService } from '../../../services/link.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { ValidationSearchService } from '../../../components/validations-filter/validation-search.service';
import { QuerysheetManagementComponent } from '../querysheet-management.component';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
@Component({
  selector: 'app-querysheet-list-view',
  templateUrl: './querysheet-list-view.component.html',
  styleUrls: ['./querysheet-list-view.component.scss']
})
export class QuerysheetListViewComponent implements OnInit {
  @Input() data;
  @Input() settings;
  @Input() loading;
  @Input() user;
  @Input() qsAllowedRoles;
  @Output() loadingChange = new EventEmitter();
  @Output() getQuerySheetsFromDB = new EventEmitter();
  @Input() totalQuerysheets: number = 0;
  public columns: Array<any> = [];
  public tableSettings: any;
  public extraColumns: string[] = [];
  // Filter variables
  public filters: any = {};
  public labelFilters: any[];  
  public showFilters: boolean = false;
  public backgroundLoading: boolean = false;
  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();
  public statusList : any[] ;
  public columnFilters: any = [];
  public _userPermissions: any;
  public statusListFilter: any = [
    {
      "statusid": 1,
      "statusdisplayname": "Success",
      "statusname": "success"
    },
    {
      "statusid": 2,
      "statusdisplayname": "Failed",
      "statusname": "failed"
    },
    {
      "statusid": 3,
      "statusdisplayname": "Pending",
      "statusname": "pending"
    },
    {
      "statusid": 4,
      "statusdisplayname": "In Progress",
      "statusname": "in-progress"
    }
  ];
  public stateListFilter: any = [
    {
      "stateid": "0",
      "statedisplayname": "Unpublished",
      "statename": "unpublished"
    },
    {
      "stateid": "1",
      "statedisplayname": "Published",
      "statename": "published"
    }
  ]
  // public disableExplorerMember : boolean = false;
  // public disableFldsForQSA_Plus_EM : boolean = false; 

  constructor(
    private globalService: GlobalService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private alertService: AlertService,
    private link: LinkService,
    private translate: TranslatePipe,
    public validationSearchService: ValidationSearchService,
    private querysheetManagementComponent: QuerysheetManagementComponent,
    private authService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.getTableSettings();
    this.getColumns();
    this.filters = {};
    
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'querysheetList.querysheetNameTableHeader',
        name: 'querySheetName',
        orderBy: 'querySheetName',
        type: 'link',
        clickLink: this.goToQuerysheet.bind(this),
        title: 'querySheetName'
      },
      //  commenting this below line of code Temporary hidding
      //{
      //   header: 'querysheetList.projectTableHeader',
      //   name: 'project'
      // },
      {
        header: 'querysheetList.createdByTableHeader',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'querysheetList.dateTableHeader',
        name: 'modifiedDate',
        orderBy: 'modifiedDate',
        type: 'date',
        format: 'lll'
      },
      {
        header: 'querysheetList.queriesTableHeader',
        name: 'querycount'
      },
      {
        header: 'querysheetList.state',
        name: 'isPublishedStatus',
        title: 'isPublishedStatus'
      },
      {
        header: 'querysheetList.processingStatus',
        name: 'status_data',
        type: 'one-span-status',
        orderBy: 'querySheetStatus',
        title: 'status_data',
        second: 'value'
      },
    ];
    this.setConditionalColumns();
  }
   /**
   * Function to add additional columns
   * to the table depending on which
   * user roles
   */
   private setConditionalColumns() {
    if(((this.authService.hasPermission([this._userPermissions.VIEW_QUERYSHEET]) && this.authService.hasPermission([this._userPermissions.EDIT_QUERYSHEET]))  || this.user.isadmin)){
      const editOption = [
        {
          type: 'link-fa-icon',
          header: ' ',
          link: 'external',
          title: 'responsiveTable.edit',
          clickLink: this.editQuerysheet.bind(this),
          namefunc: rowData => {
            return '<i class="fa fa-pencil-square-o"></i>';
          }
        }
      ]
      this.columns.splice(this.columns.length,0,...editOption);
    }
  }


  public getTableSettings(): void {
    this.tableSettings = {
      dataId: 'querySheetId',
      hasSelect: 'true',
      hasEditPermissions: this.authService.hasPermission([this._userPermissions.DELETE_QUERYSHEET]) || this.user.isadmin,
      getDataFromDB: this.settings['getDataFromDB'],
      hasDownloadOptions: false,
      extraColumns: this.extraColumns,
      actionsOnSelected: [
        {
          click: this.deleteSelectedQuerysheets.bind(this),
          class: 'fa fa-trash',
          title: 'querysheetList.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteQuerysheet.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'querySheetName',
        value: 'createdDate',
        formatDate: 'lll'
      }
    };
  }

    /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
      this.backgroundLoading = bool;
  }
  public cleanIndividualFilter(filter: string) {
    this.filterEvent.next(filter);
    if (filter === 'querySheetName') {
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'querysheetdisplayname_logic');
    }
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    const filteredItem = {}
    Object.keys(this.filters).forEach(f => {
      const filtersArr = [];
      filtersArr.push(filter);
      if (filter === 'querySheetName') {
        filtersArr.push("querysheetdisplayname_logic");
      } else if (filter == 'status_name') {
        filtersArr.push("statusId");
      }

      if (!filtersArr.includes(f)) {
        filteredItem[f] = this.filters[f]
      }
    })
    this.filters = { ...filteredItem }
    this.validationSearchService.setPreviousSearch(
      this.filters,
      'querysheets'
    );
    this.onBackgroundLoadingChange(true);
    this.querysheetManagementComponent.getQuerySheetsFromDB(this.filters, () => this.onBackgroundLoadingChange(false));
  }

  public onSearch(filters) {
    //Clear the keys which is not required
    Object.keys(this.filters).forEach(key=>{
      if(!filters[key])
        delete this.filters[key]
    })
    if (Object.keys(filters).length == 1 && filters['analysisids'].length == 0) {
      this.filters = {}
    } else 
    {
      Object.keys(filters).forEach(key => {
        if (key == 'start_date' || key == 'end_date' || key == 'querySheetName' || key == 'analysisids' || key == 'status_name' || key == 'statusId' || key == 'querysheetdisplayname_logic' ||key=='stateId') {
          if (key == 'start_date' || key == 'end_date') {
            const dateObj = new Date(filters[key]);
            const filterDate = dateObj.getFullYear() + ' ' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + ' ' + ('0' + (dateObj.getDate())).slice(-2)
            let dateToCompare = new Date();
            if (key == 'start_date') {
              dateToCompare.setFullYear(dateToCompare.getFullYear() - 10);
            }
            let dateToCompareString = dateToCompare.getFullYear() + ' ' + ('0' + (dateToCompare.getMonth() + 1)).slice(-2) + ' ' + ('0' + (dateToCompare.getDate())).slice(-2)
            if (dateToCompareString == filterDate) {
              delete this.filters[key];
            } else {
              this.filters[key] = filterDate;
            }
          } 
          else {
            if (filters[key] && (key !== 'analysisids' || (key == 'analysisids' && filters[key].length > 0))) {
              // In case of analysisids, if it has length > 0 then only update otherwise delete the filter key
              this.filters[key] = filters[key]
            } else {
              delete this.filters[key]
            }
          }
        }
      });
    }
    let labelFilters = [];
    Object.keys(this.filters).forEach(key => {
      if (key == 'start_date' || key == 'end_date') {
        let dateObj = new Date(this.filters[key])
        labelFilters.push({
          'name': key,
          'value': `${dateObj.getFullYear()}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${('0' + (dateObj.getDate())).slice(-2)}`
        })
      }else if(this.filters[key] && key == "stateId"){
        labelFilters.push({
          'name': key,
          'value': this.translate.transform('querysheetListFilter.'+this.stateListFilter[this.filters[key]].statename)
        })
      }else  if (this.filters[key] && key == "querysheetdisplayname_logic") {
        labelFilters.push({
          'name': key,
          'value':this.translate.transform('querysheetListFilter.'+this.filters[key]) 
        })
      }
      else  if (this.filters[key] && key != "statusId") {
        labelFilters.push({
          'name': key,
          'value': this.filters[key]
        })
      }
    })
    this.labelFilters = [...labelFilters];
    if (!(this.labelFilters?.length == 1 && this.labelFilters[0].name == 'querysheetdisplayname_logic')) {
      this.onBackgroundLoadingChange(true);
      this.querysheetManagementComponent.getQuerySheetsFromDB(this.filters, () => this.onBackgroundLoadingChange(false));
    }
  }
  /**
   * Edit querysheet, open up the modal for editing querysheet
   */
  public editQuerysheet(querysheet): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      CreateQuerysheetComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      querysheet,
      title: this.translate.transform('editQuerysheet.title'),
      action: 'edit'
    };

    modalWindowRef.result.then(result => {
      if (result) {
        this.getQuerySheetsFromDB.emit();
      }
    });
  }

  /**
   * Delete multiple querysheet
   */
  public deleteSelectedQuerysheets(selectedQuerysheets): void {
    this.deleteQuerysheetModal(selectedQuerysheets);
  }

  /**
   * Delete single querysheet
   */
  public deleteQuerysheet(selectedQuerysheet: any): void {
    this.deleteQuerysheetModal([selectedQuerysheet.querySheetId]);
  }

  /**
   * Load modal to confirm project(s) removal
   */
  private deleteQuerysheetModal(querysheetids: number[]) {
    if (querysheetids.length < 1) {
      // Break if array is empty
      return;
    }

    const url = 'querysheets/delete';
    const params = { 'ids': querysheetids};

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: querysheetids.length > 1 ?  'querySheets' : 'querySheet',
      customMessage: querysheetids.length > 1 ?
                        "querysheetManagement.multiQuerySheetDeleteConfirmation"
                        : "querysheetManagement.querySheetDeleteConfirmation",
      notAllowed: querysheetids.length < 1
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.onLoadingChange(true);
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              // Querysheet/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.querysheetDeletedSuccessfully'
                )
              );

              this.settings['getDataFromDB']();
            }
          );
        }
      }
    );
  }

  /**
   * Go to querysheet
   */
  public goToQuerysheet(querysheet): void {
    if (this.isAccessQuerySheet(querysheet)){
      this.filters = {};
      this.validationSearchService.setPreviousSearch(
      this.filters,
      'querysheets'
      );
      this.link.goToQuerysheet(querysheet);
    }
  }


  /**
   * Verify QS Access based on status and roles
   */
  public isAccessQuerySheet(querysheet) {
    return (querysheet.querySheetStatus === 'success' || this.qsAllowedRoles.includes(this.user.role.rolename))
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loadingChange.emit(bool);
  }

  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public getLabelFiltersForChips() {
    return this.labelFilters.filter(f => f.name !== 'querysheetdisplayname_logic');
  }
}
