import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-querysheet-users',
  templateUrl: './querysheet-users.component.html',
  styleUrls: ['./querysheet-users.component.scss']
})
export class QuerysheetUsersComponent implements OnInit {
  @Input() options;
  public option: string;
  public params: any = {};
  public paginationType: string = 'explorers';
  public isSubmitClicked: boolean = false;
  private querySheetId = null;
  private loading = false;
  private assignedUsersList: Array<number> = [];
  private toAssignUsersList: Array<number> = [];
  private toUnassignUsersList: Array<number> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslatePipe,
    private apiService: ApiService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.querySheetId = this.options.data['querySheetId']
    this.getAssignedUsers()
  }

  /**
    * API to get assigned users as list
  */
  public getAssignedUsers() {
    const params = {
      querySheetId : this.querySheetId
    };
    const url = 'querysheets/users/'
    this.apiService.get(url, params, '').subscribe
    (data => {
      this.assignedUsersList = data
    },
    (error => {
      this.alertService.error(
        this.translate.transform(
          'querysheetDetails.usersfetchFailedMsg'
        )
      );
    })
    );
  }

  public handleUsersMngtList(userId: number) {
    if (this.assignedUsersList.includes(userId)) {
      if (this.toUnassignUsersList.includes(userId)) {
        const index = this.toUnassignUsersList.indexOf(userId)
        this.toUnassignUsersList.splice(index, 1)
      } else {
        this.toUnassignUsersList.push(userId)
      }
    }
    else if (!this.assignedUsersList.includes(userId)) {
      if (this.toAssignUsersList.includes(userId)) {
        const index = this.toAssignUsersList.indexOf(userId)
        this.toAssignUsersList.splice(index, 1)
      } else {
        this.toAssignUsersList.push(userId)
      }
    }
  }

  /**
   * Handle CheckBox click to modify usersList
  */
  public handleSubmit(users: any) {
    if (users['userid']) {
      /* Select user individually to assign / unassign Users list */
      this.handleUsersMngtList(users['userid'])
    }
    else if (users['data']) {
      /* Select all user individually to assign / unassign Users list */
      const usersList = users['data']
      //Iterate list of userObjects to fetch userId
      usersList.forEach(user => {
        this.handleUsersMngtList(user['userid'])
      })
      // For Select all include existing users also
      this.toAssignUsersList = [...this.toAssignUsersList, ...this.assignedUsersList]
      // For Select all no need to unassign existing users
      this.toUnassignUsersList = [];
    }
    else {
      return
    }
  }

  /**
   * Remove duplicates from given array
  */
  public removeDuplicatesInArray(usersList: any[]) {
    if (usersList.length > 0) {
      const uniqueArr =
      usersList.filter((item:any,
        index:any) => usersList.indexOf(item) === index)
      return [...uniqueArr]
    }
    return [...usersList]
  }

  /**
   * Handle Submit button status
  */
  public disableSubmit() {
    if (this.toAssignUsersList.length > 0 ||
      this.toUnassignUsersList.length > 0 ) {
      return false;
    }
    return true;
  }

  /**
   * API to assign / unassign bulk users
  */
  public assignUsers() {
    //Integrate assignUsers API
    const url = 'querysheets/bulk-add-remove-users'
    this.toAssignUsersList = this.removeDuplicatesInArray(this.toAssignUsersList);
    const params = {
      querySheetId : Number(this.querySheetId),
      selectedUserIds: this.toAssignUsersList,
      unSelectedUserIds: this.toUnassignUsersList,
    };
    this.isSubmitClicked = true
    this.loading = true
    this.apiService.post(url, params, '').subscribe(data => {
      this.alertService.success(
        this.translate.transform(
          'querysheetDetails.usersSuccessMsg'
        )
      );
      this.loading = false;
      this.closeModal({ action: 'close' })
    },
    (error => {
      this.loading = false;
      this.alertService.error(
        this.translate.transform(
          'querysheetDetails.usersfailedMsg'
        )
      );
    })
    );

  }

  /**
   * Method to close modal
  */
  public closeModal(action) {
    this.activeModal.close(action)
    this.isSubmitClicked = false;
  }
}
