import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public constructor(
    private router: Router
  ) { }

  /**
   * Reload current route.
   */
  public reloadRoute(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([currentUrl]);
      });
  }

  /**
   * Returns true if the arrays are equal.
   */
  public areArraysEqual<Item>(
    array_1: Item[],
    array_2: Item[]
  ): boolean {
    if (array_1) {
      if (array_2) {
        if (array_1.length != array_2.length) {
          // different array lengths
          return false;
        }

        for (let i = 0; i < array_1.length; ++i) {
          if (array_1[i] != array_2[i]) {
            // different array item
            return false;
          }
        }

        // identical arrays
        return true;

      } else {
        // no array 2
        return false;
      }

    } else {
      if (array_2) {
        // no array 1
        return false;

      } else {
        // no arrays 1 & 2
        return true;
      }
    }
  }
}
