import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MockService {
  showThumbImage = false;

  constructor(private http: HttpClient) {}

  public get(urlServer, paramsServer) {
    return this.mappingFunction(urlServer, paramsServer);
  }

  public post(message) {
    const jsonResponse = { status: 201, message: message };
    return of(jsonResponse);
  }

  public put(message) {
    const jsonResponse = { status: 201, message: message };
    return of(jsonResponse);
  }

  private mappingFunction(urlServer, paramsServer) {
    urlServer = urlServer.split('/');
    switch (urlServer[0]) {
      case 'login': {
        return this.getLogin();
      }
      case 'projects': {
        return this.getProjects();
      }
      case 'analysis': {
        return this.getAnalysis();
      }
      case 'documents': {
        return this.getProjectDocuments(paramsServer);
      }
      case 'languages': {
        return this.getLanguages();
      }
      case 'datamodels': {
        return this.getDatamodels();
      }
      case 'documentDataFields': {
        return this.getDocumentFieldData();
      }
      case 'docpictures': {
        return this.getDocumentPictures();
      }
      case 'picturechunks': {
        return this.getPictureChunks();
      }
      case 'docdetails': {
        return this.getDocumentDetails(paramsServer);
      }
      case 'kpis': {
        return this.getProjectKpi(paramsServer);
      }
      case 'overview': {
        return this.getOverview();
      }
      case 'querylist': {
        return this.getQueryList();
      }
      case 'userroles': {
        return this.getUserRoles();
      }
      case 'users': {
        return this.getUsers();
      }
      case 'userlist': {
        return this.getUserList();
      }
      case 'query-result': {
        return this.getQuerySearchResult();
      }
    }
  }

  public getLogin(): Observable<any> {
    return this.http.get('./assets/fixtures/loginData.json');
  }

  public getProjects(): Observable<any> {
    return this.http.get('./assets/fixtures/projectList.json');
  }

  public getAnalysis(): Observable<any> {
    return this.http.get('./assets/fixtures/projectAnalysis.json');
  }

  public getAnalysisDropDownSources(): Observable<any> {
    return this.http.get('./assets/fixtures/analysisDropdownSources.json');
  }

  public getChartDataSources(): Observable<any> {
    return this.http.get('./assets/fixtures/chartData.json');
  }

  public getProjectAnalysis(id: number | string) {
    return this.http.get('./assets/fixtures/projectAnalysis.json?id=' + +id);
  }

  public getUsers(): Observable<any> {
    return this.http.get('./assets/fixtures/users.json');
  }

  public getUserList(): Observable<any> {
    return this.http.get('./assets/fixtures/userList.json');
  }

  public getLanguages(): Observable<any> {
    return this.http.get('./assets/fixtures/languageList.json');
  }

  public getDatamodels(): Observable<any> {
    return this.http.get('./assets/fixtures/datamodelList.json');
  }

  public getProjectDocuments(id: number | string) {
    return this.http.get('./assets/fixtures/projectDocuments.json?id=' + +id);
  }

  public getDocumentPictures() {
    return this.http.get('./assets/fixtures/docpicture.json');
  }

  public getPictureChunks() {
    return this.http.get('./assets/fixtures/pagechunks.json');
  }

  public getDocumentFieldData() {
    return this.http.get('./assets/fixtures/documentFieldData.json');
  }

  public getOverview() {
    return this.http.get('./assets/fixtures/overview.json');
  }

  public getProjectKpi(params: any): Observable<any> {
    if (!params || params.projectid) {
      return this.http.get('./assets/fixtures/projectKpi.json');
    } else if (params.documentid) {
      return this.http.get('./assets/fixtures/documentKpi.json');
    } else {
      return this.http.get('./assets/fixtures/analysisKpi.json');
    }
  }

  public getAnalysisKpi(): Observable<any> {
    return this.http.get('./assets/fixtures/analysisKpi.json');
  }

  public getDocumentDetails(docId: number | string) {
    return this.http.get('./assets/fixtures/documentDetails.json');
  }

  public getQueryList() {
    return this.http.get('./assets/fixtures/queryList.json');
  }

  private getUserRoles(): Observable<any> {
    return this.http.get('./assets/fixtures/userRoles.json');
  }

  public getQuerySearchResult() {
    return this.http.get('./assets/fixtures/queryResults.json');
  }
}
