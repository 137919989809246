import { usecases, feedbackTypes } from './constants';
/* 
--- Semantic with QnA ---
modelLevelsObject: {
  level1_1: {
    model: 'USE',
    idName: 'semanticResultId',
    feedbackType: 'Semantic',
    level2_1: {
      model: 'roberta',
      idName: 'answerId',
      feedbackType: 'QnA'
    }
  }
}

--- Semantic ---
modelLevelsObject: {
  level1_1: {
    model: 'USE',
    idName: 'semanticResultId',
    feedbackType: 'Semantic'
  }
}

--- QNA ---
modelLevelsObject: {
  level1_1: {
    model: 'roberta',
    idName: 'answerId',
    feedbackType: 'QnA'
  }
}
*/
export const getUsecaseLevelsMetaData = (usecase = usecases.SEMANTIC_WITH_QNA, selectedQuery = null) => {

  const usecaseLevels = { level1_1: <any>{} };
  if (usecase == usecases.SEMANTIC_WITH_QNA) {

    // TO DO: Add level2_1 and ai box based on the query contents, 
    // so this case of Sematic with QnA if we have only query not the question the 
    usecaseLevels.level1_1 = {
                                model: 'USE',
                                modelKey: 'USEModel',
                                idName: 'semanticResultId',
                                feedbackType: feedbackTypes.SEMANTIC,
                                highlightType: feedbackTypes.SEMANTIC,
                                resultScoreKey: 'semanticScore',
                                buttonTitle: 'filePreview.semantic',
                                highlightColor: '#008000'
                              };

    if (selectedQuery == null || selectedQuery?.qna?.text) {

      // Thich check will only happen if we have level2 feedback
      usecaseLevels.level1_1.validateLevel1NotIntoLevel2 = true;

      usecaseLevels.level1_1.level2_1 = {
        model: 'roberta',
        modelKey: 'robertaModel',
        idName: 'answerId',
        feedbackType: feedbackTypes.QNA,
        highlightType: feedbackTypes.QNA,
        buttonTitle: 'filePreview.answer',
        highlightColor: '#006400',
      };
      usecaseLevels.level1_1.aiBox = {
        modelKey: 'robertaModel',
        highlightColor: '#B57BBA',
        buttonTitle: 'filePreview.answer',
        highlightType: 'aiAnswerHighlight',
        showLevel1_1Btn: false,
        showLevel2_1Btn: true
      }
    }
  } else if (usecase == usecases.ONLY_SEMANTIC) {
    usecaseLevels.level1_1 = {
                                model: 'USE',
                                modelKey: 'USEModel',
                                idName: 'semanticResultId',
                                feedbackType: feedbackTypes.SEMANTIC,
                                highlightType: feedbackTypes.SEMANTIC,
                                buttonTitle: 'filePreview.semantic',
                                resultScoreKey: 'semanticScore',
                                highlightColor: '#008000'
                              }
  }

  return usecaseLevels;
}

export const sortResultsBasedOnScore = (results: any, modelKey: string, scoreKey: string) => {
  // Handling the results sorting based on feedbacks given, considering manual feedbacks and modified dates
  results.sort((a:any, b:any) => {
    const aScore = a[modelKey].feedback ? a[modelKey].feedback.updatedScore : a[modelKey][scoreKey];
    const bScore = b[modelKey].feedback ? b[modelKey].feedback.updatedScore : b[modelKey][scoreKey];

    if (a[modelKey].feedback?.selections.length && b[modelKey].feedback?.selections.length) {
      // Case to handle scenario where both records have manual feedback
      return new Date(b[modelKey].feedback.modifiedDate).getTime() - new Date(a[modelKey].feedback.modifiedDate).getTime();
    } else if (a[modelKey].feedback?.selections.length || b[modelKey].feedback?.selections.length) {
      // Case to handle scenario where any one of the records have manual feedback
      return (a[modelKey].feedback?.selections.length ? -1 : 1);
    } else if (aScore == bScore && a[modelKey].feedback && b[modelKey].feedback) {
      // Case to handle scenario where both records have feedbacks (accept/reject) 
      return new Date(b[modelKey].feedback.modifiedDate).getTime() - new Date(a[modelKey].feedback.modifiedDate).getTime();
    } else if (aScore == bScore && (a[modelKey].feedback || b[modelKey].feedback)) {
      // Case to handle scenario where any one of the records have feedbacks (accept/reject) 
      return (a[modelKey].feedback ? -1 : 1);
    }

    return bScore - aScore;
  });
}