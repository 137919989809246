import { Component, OnInit, Input } from '@angular/core';
import { ProjectModel } from '../../models/project-mstr.model';
import { LinkService } from '../../services/link.service';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
  @Input('project') projectItem: ProjectModel;
  private command = [];

  constructor(private link: LinkService) {}

  ngOnInit() {}

  /**
   * Navigate to current project
   */
  public goToProject() {
    this.link.goToProject(this.projectItem);
  }
}
