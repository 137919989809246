import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { DocumentFieldDataModel } from 'src/app/models/document-field-data.model';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/services/api.service';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { Subscription } from 'rxjs';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { NgForm } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { DocumentRenderComponent } from 'src/app/components/document-render/document-render.component';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.pages.html',
  styleUrls: ['./document-view.pages.scss']
})
export class DocumentViewPages implements OnInit, OnDestroy {
  @Input() documentDetails: DocumentFieldDataModel;
  @Input() validationExecution: any;
  @Input() isExternal: boolean;
  @Output() updateTimeline = new EventEmitter();
  @ViewChild(DocumentRenderComponent)
  private documentRender: DocumentRenderComponent;
  public user: any = {};
  public showValidations: boolean = false;
  public _userPermissions: any;
  public filesList: Array<File> = [];
  public windowScrollTop: number = 0;
  public distanceDocumentRenderToWindowTop: number = 112;
  public isFullScreen: boolean = false;
  public getFullScreenSub: Subscription;
  public allTags: string[] = ['Transferencia', 'Otros'];
  public modulePrivacy: boolean;
  public loadingdocs: boolean = false;
  public finishedprocess: boolean = false;
  public includeFiles: boolean = true;
  public subscriptionEnrichmentTypes: Subscription;
  public loadingExcel: boolean;
  FILE_FORMATS =
    '.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr';
  constructor(
    private authService: AuthenticationService,
    private global: GlobalService,
    private documentRenderService: DocumentRenderService,
    public fileDownloadService: FileDownloadService,
    public apiService: ApiService,
    public alertService: AlertService,
    public translate: TranslatePipe
  ) {
    // Check user permission to use this component
    this.loadingExcel = false;
    this.user = authService.getLoggedInUser();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this._userPermissions = this.global.getUserPermissionsConst();

    this.getFullScreenSub = this.documentRenderService
      .getFullScreen()
      .subscribe(stateObj => {
        this.isFullScreen = stateObj.state;
      });
  }

  ngOnInit() {
    this.modulePrivacy = this.authService.userCanViewModule(
      this.authService.getLoggedInUser(),
      'PrivacyNoteFunctionality'
    );
  }

  ngOnDestroy() {
    // Remove subscriptions
    if (this.getFullScreenSub) this.getFullScreenSub.unsubscribe();
  }
  /**
   * handle file upload input change
   * @param event uploadevent
   */
  public onChange(event: any) {
    this.filesList = <Array<File>>event.target.files;
    this.showDocumentList();
  }
  /**
   * Listen for window scroll event
   */
  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    this.windowScrollTop = window.pageYOffset;
  }

  /**
   * Check when the document render must be changed
   * to fixed position
   */
  public isDocumentRenderFixed() {
    return (
      !this.documentDetails.isparentdocument &&
      this.windowScrollTop > this.distanceDocumentRenderToWindowTop
    );
  }
  /**
   * Checks if the user can submit depending on the case
   */
  public isSubmitDisabled(): boolean {
    if (this.loadingdocs && !this.finishedprocess) {
      return true;
    }
    return this.filesList.length === 0;
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }
  public showDocumentList() {
    let element = document.getElementById('panel');

    if (element.style.display == 'none') element.style.display = 'block';
    else element.style.display = 'none';
  }
  public deleteFile(index: number) {
    const files = Array.from(this.filesList);
    files.splice(index, 1);
    this.filesList = <Array<File>>files;
  }

  public uploadModifiedExcel() {
    this.loadingExcel = true;
    let formData = new FormData();
    formData.append('file', this.filesList[0], this.filesList[0].name);
    formData.append('documentid', this.documentDetails.documentid.toString());
    formData.append(
      'datamodelname',
      this.documentDetails.datamodel.datamodeldisplayname
    );
    this.apiService
      .post('extractedfields/modifyexcel', formData, '', false, {
        reportProgress: true,
        observe: 'events'
      })
      .subscribe(
        event => {
          if (event.status == 200 && event.type == HttpEventType.Response) {
            this.loadingExcel = false;
            this.alertService.success(
              this.translate.transform('projectDetails.successfulUpload'),
              false
            );
            this.filesList = [];
            this.documentRenderService.emptyImages();
            this.documentRenderService.getDocumentImagesFromDB();
            this.documentRenderService.getImages().subscribe(obj => {
              this.documentRender.reloadExcel(obj.images);
            });
          }
        },
        () => {
          this.loadingExcel = false;
          this.alertService.error(
            this.translate.transform('projectDetails.unsuccessfulUpload'),
            false
          );
        }
      );
  }
}
