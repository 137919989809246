import { Directive, Input, HostListener } from '@angular/core';
import { EventTrackerService } from '../services/event-tracker.service';
import { Router } from '@angular/router';

@Directive({
  selector: '[appEventTracker]'
})
export class EventTrackerDirective {
  @Input('appEventTracker') eventTracker;

  @HostListener('click', ['$event']) onClick($event) {
    this.sendInfo();
  }

  constructor(
    private trackerService: EventTrackerService,
    private router: Router
  ) {}

  public sendInfo() {
    this.trackerService.sendEventTracked(this.router.url, 4, this.eventTracker);
  }
}
