import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Directive({ selector: '[acl]' })
export class AclDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input()
  set acl(permission: Object) {
    if (
      typeof permission['tenantRequired'] == 'undefined' ||
      typeof permission['permissionRequired'] == 'undefined'
    ) {
      this._clearView();
    } else {
      // let access = this.authService.getPermission(permission['tenantRequired'], permission['entityRequired'], permission['permissionRequired']);
      // if (access && !this.hasView) {
      //   this.viewContainer.createEmbeddedView(this.templateRef);
      //   this.hasView = true;
      // }
      // else if (!access && this.hasView) {
      //   this._clearView();
      // }
    }
  }

  _clearView() {
    this.viewContainer.clear();
    this.hasView = false;
  }
}
