import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

import { Reason } from '../../../models/close-reason.model';
import * as querysheetConstants from '../../../constants/querysheetConstants';
import { FileDownloadService } from '../../../services/file-download.service';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-create-querysheet',
  templateUrl: './create-querysheet.component.html',
  styleUrls: ['./create-querysheet.component.scss']
})
export class CreateQuerysheetComponent implements OnInit {
  @Input() options;
  public FILE_FORMATS = querysheetConstants.QUERYSHEET_TEMPLATE_FILE_FORMATS;
  public reason = Reason;
  public querySheetForm: FormGroup;
  public selectedFile: File;
  public fileUploadProgress: number;
  public fileValidations: Array<String> = [];
  public fileValidationInstructions: Array<String> = [];
  private errorFileData: Blob;
  public apiInProgress: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public qsFormBuilder: FormBuilder,
    private fileDownloadService: FileDownloadService,
    private translate: TranslatePipe,
    private apiService: ApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.querySheetForm = this.qsFormBuilder.group({
      querySheetName: [
        this.options?.querysheet
          ? this.options?.querysheet?.querySheetName
          : '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(250)
        ]
      ],
      project: this.options?.querysheet
        ? this.options?.querysheet?.project
        : '',
      querysheetDescription: [
        this.options?.querysheet ? this.options?.querysheet?.description : '',
        [Validators.minLength(3), Validators.maxLength(250)]
      ]
    });

    this.querySheetForm.controls['project'].disable();
    // TO DO: For now initializing it to 100 later on need to change it based on the promise
    this.fileUploadProgress = 0;
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() {
    return this.querySheetForm.controls;
  }

  downloadTemplate(): void {
    const url = `download/querysheettemplate/`;
    const file = {
      mediatype:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };

    this.fileDownloadService.downloadExternalFile(
      url,
      file,
      querysheetConstants.QUERYSHEET_TEMPLATE_FILE_NAME
    );
  }

  closeModal(): void {
    this.activeModal.close(this.reason.closeBtn);
  }

  onQueryNameChanged(): void {
    this.fileValidations = [];
    this.fileUploadProgress = 0;
  }

  searchProjects(): void {
    const projectSearchStr = this.querySheetForm.get('project').value;
    // TO DO: fetch projects for searched string
  }

  onSelectFiles(event: any): void {
    this.selectedFile = event.target.files[0];
    this.validateSelectedFile();
  }

  validateSelectedFile(): void {
    // Clearing the validation messages and error file data as well
    this.fileValidations = [];
    this.fileValidationInstructions = [];
    this.errorFileData = null;
    this.fileUploadProgress = 0;
    if (this.selectedFile.name.split('.').pop() !== 'xlsx') {
      this.fileValidations.push(
        this.translate.transform('createQuerysheet.sizeFormatError')
      );
    }
  }

  deleteFile(): void {
    this.selectedFile = null;
    // Clearing the validation messages and instructions along with it
    this.fileValidations = [];
    this.fileValidationInstructions = [];
  }

  validateAndUpload(): void {
    // Upload the template file and receive errors in response if any otherwise a new querysheet gets created.
    const headers = {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    };
    if (this.options?.action == 'create') {
      const url = `querysheets`;
      const params = new FormData();
      params.append(
        'querysheetname',
        this.querySheetForm.get('querySheetName').value
      );
      params.append('project', this.querySheetForm.get('project').value);
      params.append(
        'description',
        this.querySheetForm.get('querysheetDescription').value
      );
      params.append('template', this.selectedFile);

      // Setting the apiInProgress flag to true
      this.apiInProgress = true;

      this.apiService
        .post(url, params, '', false, headers, false, true)
        .subscribe(
          event => {
            this.handleValidateAndUploadRes(event, 'createQuerysheet.querysheetCreatedSuccessfully');
          },
          async error => {
            this.handleValidateAndUploadErr(error);
          }
        );
    } else if (this.options?.action == 'import') {
      const url = `querysheets/`;
      const params = new FormData();
      params.append('template', this.selectedFile);

      // Setting the apiInProgress flag to true
      this.apiInProgress = true;

      this.apiService
        .put(
          url,
          this.options?.querysheet?.querySheetId,
          params,
          '',
          false,
          headers,
          false,
          true
        )
        .subscribe(
          event => {
            this.handleValidateAndUploadRes(event, 'createQuerysheet.querysheetUpdatedSuccessfully');
          },
          async error => {
            this.handleValidateAndUploadErr(error);
          }
        );
    } else if (this.options?.action == 'edit') {
        this.editQuerysheet();
    }
  }

  handleValidateAndUploadRes(event, msg) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.fileUploadProgress = Math.round(
          (65 * event.loaded) / event.total
        );
        break;
      case HttpEventType.Response:
         // Making the progress to 100% with 200ms interval
         setTimeout(() => {
          const progress = setInterval(() => {
            if (this.fileUploadProgress < 100) {
              this.fileUploadProgress += 5;
            } else {
                clearInterval(progress);
                if (event.status == 201) {
                  // Querysheet has been created successfully from uploaded template
                  this.alertService.success(
                    this.translate.transform(msg),
                    true
                  );
                  this.activeModal.close(this.reason.submitBtn);
                } else {
                  // If we receive a Blob in response, give user an option to download the file
                  // And show an error message with instructions
                  this.errorFileData = event.body;
                  this.fileValidations.push(
                    this.translate.transform(
                      'createQuerysheet.validationErrorsInUploadedFile'
                    )
                  );
                  this.fileValidationInstructions.push(
                    this.translate.transform(
                      'createQuerysheet.validationErrorsInstruction1'
                    )
                  );
                  this.fileValidationInstructions.push(
                    this.translate.transform(
                      'createQuerysheet.validationErrorsInstruction2'
                    )
                  );
                }
                // Setting the apiInProgress flag to false
                this.apiInProgress = false;
            }
          }, 200);
        }, 500);
        break;
    }
  }

  async handleValidateAndUploadErr(error) {
    this.fileUploadProgress = 100;
    const errorMsg = JSON.parse(await error?.error?.text());
    if ([400, 409].includes(error.status)) {
      // 400 - Invalid template
      // 409 - Querysheet name already exists
      this.fileValidations.push(errorMsg);
    } else {
      this.alertService.error(errorMsg);
    }
    // Setting the apiInProgress flag to false
    this.apiInProgress = false;
  }

  editQuerysheet(): void {
    // TO DO: Integrating api for querysheet editing
    const url = `querysheets/${this.options?.querysheet?.querySheetId}/update`;
    const params = { 'querysheetname': this.querySheetForm.get('querySheetName').value,
                      'description': this.querySheetForm.get('querysheetDescription').value };
    const headers = {
      observe: 'response'
    };

    // Setting the apiInProgress flag to true
    this.apiInProgress = true;

    this.apiService
    .put(
      url,
      '',
      JSON.stringify(params),
      '',
      true,
      headers,
      false,
      true
    )
    .subscribe(
      event => {
        // Querysheet has been created successfully from uploaded template
        if (event.status == 201) {

          this.alertService.success(
            this.translate.transform(
              'createQuerysheet.querysheetUpdatedSuccessfully'
            )
          );
          this.activeModal.close(this.reason.submitBtn);
          // Setting the apiInProgress flag to false
          this.apiInProgress = false;
        }
      },
      error => {
        const errorMsg = error.error;
        if ([304, 400, 409].includes(error.status)) {
          // 304 - No new name or new description provided for Query sheet
          // 400 - Query sheet name or description is required to update
          // 409 - Query Sheet name already exists
          this.fileValidations.push(errorMsg);
        } else {
          this.alertService.error(errorMsg);
        }
        // Setting the apiInProgress flag to false
        this.apiInProgress = false;
      }
    );
  }

  downloadErrorFile(): void {
    this.fileDownloadService.openExternalFile(
      this.errorFileData,
      querysheetConstants.QUERYSHEET_TEMPLATE_FILE_NAME
    );
  }
}
