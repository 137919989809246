import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from '../authentication.service';
import { ICustomGuard } from './guard.base';
import { Location } from '@angular/common';
import { AccountInfo, EventMessage } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MsalCustomGuard
  extends MsalGuard
  implements ICustomGuard, CanActivate, CanActivateChild, CanLoad, OnDestroy
{
  public guardRouter: Router;
  public guardRoute: ActivatedRouteSnapshot;
  public guardRouteState: RouterStateSnapshot;
  public result$: Observable<boolean | UrlTree>;
  public observer: Subscriber<boolean | UrlTree>;
  public subscriptions: Subscription[];
  /**
   * Current account logged in
   */
  protected activeAccount: AccountInfo;

  constructor(
    public authenticationService: AuthenticationService,
    public msalService: MsalService,
    private apiService: ApiService,
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    msalBroadcastService: MsalBroadcastService,
    authService: MsalService,
    location: Location,
    router: Router
  ) {
    super(msalGuardConfig, msalBroadcastService, authService, location, router);
    this.subscriptions = [];
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.setRouteInformation(route, state);
    const msalResult = super.canActivate(route, state);
    this.createResult(() => {
      const loginSubscription = this.authenticationService.msalAuthService
        .loginFailure()
        .subscribe(this.onLoginFailure.bind(this));
      const subscription = msalResult.subscribe(
        this.manageResultFromMsal.bind(this)
      );
      this.subscriptions.push(subscription, loginSubscription);
    });
    return this.result$;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return super.canActivateChild(childRoute, state);
  }

  canLoad(): Observable<boolean> {
    return super.canLoad();
  }

  public setRouteInformation(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void {
    this.guardRoute = route;
    this.guardRouteState = state;
  }

  public createResult(onObservableCreated: () => void): void {
    this.result$ = new Observable<boolean | UrlTree>(observer => {
      this.observer = observer;
      onObservableCreated();
    });
  }

  /**
   * Manages the result from the MsalGuard.
   * In case of result false, the result is passed on directly.
   * Else the user is requested to the API.
   @param msalResult The result from the parent msal guard
   */
  private manageResultFromMsal(msalResult: boolean | UrlTree): void {
    if (!this.observer || !this.result$) {
      console.error('Result not created yet');
    }
    if (msalResult instanceof UrlTree) {
      this.notifyResult(msalResult);
    } else if (!msalResult) {
      this.notifyResult(false);
    } else {
      const subscription = this.authenticationService.msalAuthService
        .acquireTokenSilent(false)
        .subscribe(this.onTokenAcquired.bind(this));
      this.subscriptions.push(subscription);
    }
  }

  private onTokenAcquired(token: string) {
    if (token == null) {
      console.error('Token could not be acquired');
      this.notifyResult(this.authenticationService.getServerErrorUrlTree());
      return;
    }
    this.authenticationService.setToken(token);
    this.loginAPI(
      this.onAccessGranted.bind(this),
      this.onAccessDenied.bind(this)
    );
  }

  public loginAPI(
    onUserAcquired: (user: any) => void,
    onError: (error: any) => void
  ): void {
    const tenantName = this.getTenant();
    const subscription = this.apiService
      .get('login/', null, '', true, {}, tenantName)
      .subscribe(
        user => {
          onUserAcquired(user);
          subscription?.unsubscribe();
        },
        error => {
          onError(error);
          subscription?.unsubscribe();
        }
      );
  }

  public notifyResult(result: boolean | UrlTree): void {
    this.observer.next(result);
    this.observer.complete();
    this.disposeSubscriptions();
  }

  public getTenant(): string {
    let tenant = this.guardRouteState.url.split('/')[1];
    tenant === 'tenant' ? '' : tenant;
    this.authenticationService.setTenantName(tenant);
    return tenant;
  }

  public onAccessGranted(user: any): void {
    this.authenticationService.setLoggedInUser(user);
    if (this.setTokenExpirationDate) {
      this.setTokenExpirationDate(user);
    }
    if (this.checkPrivacyNote(user) && !this.accessingPrivacyNote()) {
      this.notifyResult(
        this.authenticationService.getPrivacyNoteConfirmationUrlTree()
      );
    } else {
      this.notifyResult(true);
    }
  }

  public onAccessDenied(error: any): void {
    console.log('Access denied by Guard ==> ', error);
    const errorMessage = error?.error?.error
      ? error.error.error
      : 'Something went wrong';
    if (errorMessage === 'Authentication Token Expired') {
      this.notifyResult(this.authenticationService.getDefaultRouteUrlTree());
    } else if (error?.status === 401) {
      this.notifyResult(this.authenticationService.getNotAllowedUrlTree());
    } else {
      this.notifyResult(this.authenticationService.getServerErrorUrlTree());
    }
  }

  public setTokenExpirationDate?(user: any): void {
    const expirationDate =
      this.authenticationService.msalAuthService.getExpirationDate();
    this.authenticationService.setTokenExpirationDate(expirationDate);
  }

  /**
   *
   * @param user
   */
  public checkPrivacyNote(user: any): boolean {
    const modulePrivacy = this.authenticationService.userCanViewModule(
      this.authenticationService.getLoggedInUser(),
      'PrivacyNoteFunctionality'
    );
    return modulePrivacy && user.privacyNoteAccepted == false;
  }

  /**
   * Return true if the user is currently trying to access
   * the privacy note page.
   */
  private accessingPrivacyNote(): boolean {
    return this.guardRouteState.url.split('/')[2] == 'privacy-note';
  }

  /**
   * Callback in case the msal login fails
   * @param message
   */
  private onLoginFailure(message: EventMessage): void {
    console.log('Access Denied by Guard ==> Login error:', message.error);
    this.notifyResult(this.authenticationService.getNotAllowedUrlTree());
  }

  public disposeSubscriptions(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    this.disposeSubscriptions();
  }
}
