import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, Subscription } from 'rxjs';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentChecklistService {
  public itemState: any;
  public validationStatus: any;
  public collapsibleCatalogState: any;

  constructor(private global: GlobalService) {
    this.validationStatus = this.global.getValidationStatusConst();
  }
  /**
   * Retrieve collapsible state of Catalog
   */
  public getCollapsibleCatalogState() {
    return this.collapsibleCatalogState;
  }

  public cleanCollapsibleState() {
    this.collapsibleCatalogState = {};
  }

  /**
   * Cahnage collapsible state of Catalog
   */
  public setPreviousSearchCatalog(newCollapsibleStateCatalog) {
    this.collapsibleCatalogState = newCollapsibleStateCatalog;
  }

  public setState(item) {
    let failureCount = 0;
    let skippedCount = 0;

    item.forEach(validation => {
      failureCount = 0;
      skippedCount = 0;

      if (validation.children) {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            validationexecution.checklistsexecution.forEach(
              checklistexecution => {
                checklistexecution.checklistid.forEach(template => {
                  if (
                    validationexecution.validation.checklistid != template.id
                  ) {
                    template.is_latest_version = false;
                  }
                });

                checklistexecution.groups.forEach(group => {
                  if (
                    group.validationstatusid.validationstatusname ==
                    this.validationStatus.FAILURE
                  ) {
                    failureCount++;
                  }
                  if (
                    group.validationstatusid.validationstatusname ==
                    this.validationStatus.SKIPPED
                  ) {
                    skippedCount++;
                  }
                });
                if (failureCount > 0) {
                  validation['statusChecklistName'] =
                    this.validationStatus.FAILURE_DISPLAYNAME;
                  validation['statusChecklist'] = this.validationStatus.FAILURE;
                } else {
                  if (checklistexecution.groups.length == skippedCount) {
                    validation['statusChecklistName'] =
                      this.validationStatus.SKIPPED_DISPLAYNAME;
                    validation['statusChecklist'] =
                      this.validationStatus.SKIPPED;
                  } else {
                    validation['statusChecklistName'] =
                      this.validationStatus.SUCCESS_DISPLAYNAME;
                    validation['statusChecklist'] =
                      this.validationStatus.SUCCESS;
                  }
                }
              }
            );
          }
        });
      }
    });

    this.itemState = item;
  }

  public cleanState() {
    this.itemState = {};
  }

  public getState() {
    return this.itemState;
  }
}
