import { Themes } from '../themes';
import { getLightThemePamsTheme } from './light/pams-theme';

/**
 * Get dashboard color palette.
 */
export function getDashboardColorPalette(theme: string, name: string): any {
  switch (theme) {
    case Themes.THEME_LIGHT:
      return getLightThemeColorPalette(name);
  }
}

/**
 * Get light theme color palette.
 */
function getLightThemeColorPalette(name: string): any {
  switch (name) {
    case "PAMS theme":
      return getLightThemePamsTheme();
  }
}
