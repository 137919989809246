import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-trackstatus',
  templateUrl: './trackstatus.component.html',
  styleUrls: ['./trackstatus.component.scss']
})
export class TrackstatusComponent implements OnInit {
  @Input('formTest') formTest;

  constructor() {}

  ngOnInit() {}
}
