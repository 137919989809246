import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileDownloadService } from 'src/app/services/file-download.service';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit {
  public renderValue;

  @Input() value;
  @Output() exportEvent = new EventEmitter();

  constructor(public fileDownloadService: FileDownloadService) {}

  ngOnInit() {
    this.renderValue = this.value;
  }
}
