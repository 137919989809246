import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../../common-components/validation.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-single-condition',
  templateUrl: './single-condition.component.html',
  styleUrls: ['./single-condition.component.scss']
})
export class SingleConditionComponent implements OnInit {
  @Input('single_condition') condition: FormGroup;
  @Input('condition_index') condition_index: number;
  @Input('datamodelId') public datamodelId: number;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  public fieldsOfInterest;

  constructor(
    public validationService: ValidationService,
    public global: GlobalService
  ) {}

  ngOnInit() {}
}
