import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss']
})
export class ChipsAutocompleteComponent implements OnInit, OnDestroy {
  public loading: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;

  private user: any = {};
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  private approval: any = {};
  private showValidations: boolean;

  @Input() allTags: any;
  @Input() documentData: any;
  @Input() documentDetails: any;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private globalService: GlobalService,
    private translate: TranslatePipe
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
    this.loading = false;
    this.user = authService.getLoggedInUser();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
  }

  ngOnInit() {
    this.getReviewersTypes();
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      this.loading = true;
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        const obj = {
          tagname: value.trim().charAt(0).toUpperCase() + value.trim().slice(1),
          document: this.documentData.documentid,
          analysis: this.documentData.analysis.analysisid
        };
        this.apiService.post('tags', obj, 'tags.create').subscribe(
          data => {
            this.documentData.tags
              ? this.documentData.tags.push(data)
              : (this.documentData.tags = [data]);
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
      }

      if (input) {
        input.value = '';
      }

      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.loading = true;
    const obj = {
      tagname:
        event.option.viewValue.trim().charAt(0).toUpperCase() +
        event.option.viewValue.trim().slice(1),
      document: this.documentData.documentid,
      analysis: this.documentData.analysisid
    };
    this.apiService.post('tags', obj, 'tags.create').subscribe(
      data => {
        this.documentData.tags
          ? this.documentData.tags.push(data)
          : (this.documentData.tags = [data]);
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  remove(tag: any): void {
    this.loading = true;
    const index = this.documentData.tags.indexOf(tag);

    if (index >= 0) {
      this.apiService.delete('tags/', tag.tagid, 'tags.delete').subscribe(
        data => {
          this.documentData.tags.splice(index, 1);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      tag => tag.toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
          if (!this.userCanInteract()) {
            this.tagCtrl.disable();
          }
        });
    } else {
      this.setApproval();
      if (!this.userCanInteract()) {
        this.tagCtrl.disable();
      }
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }
  /**
   * Check if user is the assigned reviewer and the validation modules exists.
   */
  public userCanInteract() {
    return !this.showValidations ? true : this.approvalIsAssignedToUser();
  }

  /**
   * Check if is a document parent
   */
  public isDocumentParent() {
    return this.documentDetails.isparentdocument;
  }
  /**
   * Gets the title of the Input depending on the user reviewer status.
   */
  public getInputTitle(): string {
    if (!this.userCanInteract() && !this.isDocumentParent())
      return this.translate.transform('documentDetails.onlyAssigned');
    else return '';
  }
}
