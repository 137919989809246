import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-ball',
  templateUrl: './spinner-ball.component.html',
  styleUrls: ['./spinner-ball.component.scss']
})
export class SpinnerBallComponent implements OnInit {
  @Input() spinnerStyles: string = 'la-ball-circus'; // default
  constructor() {}

  ngOnInit() {}
}
