import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaginationModel } from '../../models/pagination';
import { DocumentModel } from '../../models/document.model';
import { ApiService } from 'src/app/services/api.service';
import { LinkService } from 'src/app/services/link.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { CognitiveSearchService } from './cognitive-search.service';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { DocumentsTableComponent } from 'src/app/components/documents-table/documents-table.component';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit, OnDestroy {
  formCognitiveSearch: FormGroup;

  public expandedFilters: any[] = [];

  // Datamodels variables
  public subscriptionDataModels: Subscription;
  public loadingDatamodels: boolean;
  public fieldOfInterestIds: any[];

  // Global variables
  public subscriptionGlobal: Subscription;
  public loadingGlobal: boolean;
  public status: any[];
  public languages: any[];
  public datamodels: any[];

  public user: any;
  public queryString: string;
  public search: boolean;

  // Document table
  public paramDocumentTable: any[] = [];
  @ViewChild(DocumentsTableComponent)
  private documentsTable: DocumentsTableComponent;
  public showTable: boolean = false;

  public loading: boolean = true;
  public rows: {};
  public columns: any[];
  public settingTable: any;
  private pagination: PaginationModel;
  public backgroundLoading: boolean;
  public previousSearch: FormGroup;

  constructor(
    private apiService: ApiService,
    public link: LinkService,
    private paginationService: PaginationService,
    public cognitiveService: CognitiveSearchService,
    private global: GlobalService,
    private authService: AuthenticationService
  ) {
    authService.checkAccess('CognitiveSearchCoreFunctionality');
    this.getSettingTable();
    this.getColumns();
  }

  ngOnInit() {
    this.formCognitiveSearch = this.cognitiveService.getFormCognitiveSearch();
    this.previousSearch = this.cognitiveService.getPreviousSearch();
    if (this.previousSearch != undefined) {
      this.formCognitiveSearch = this.previousSearch;
      const basicArray = this.formCognitiveSearch.get('basicFilters');
      const extractedArray = this.formCognitiveSearch.get('extractedFilters');
      const validatedArray = this.formCognitiveSearch.get('validatedFilters');

      if (extractedArray != null || validatedArray != null) {
        this.getDatamodels();
      }

      if (basicArray || extractedArray || validatedArray) {
        this.onSearch();
      }
    }
    this.search = false;
    this.loading = false;
  }

  /**
   * Trigger document request and restart pagination
   */
  public onSearch() {
    this.search = true;
    this.expandedFilters = [];
    const formSerialized = this.cognitiveService.serializeForRequest(
      this.formCognitiveSearch
    );
    const params = formSerialized.getRawValue();
    if (params['text_filters'] || params['extracted_data_filters']) {
      this.cognitiveService.setPreviousSearch(this.formCognitiveSearch);
    }
    if (this.paramDocumentTable['formSerialized']) {
      this.setDocumentTableParams(params);
      this.documentsTable.responseTableData = {};
      this.documentsTable.initLoadingPage();
      this.search = false;
    } else {
      this.setDocumentTableParams(params);
      this.search = false;
    }
  }

  /**
   * Function to set the param variables
   * for the document table component
   */
  private setDocumentTableParams(params) {
    this.paramDocumentTable['formSerialized'] = params;
  }

  // -------- COGNITIVE SEARCH STARTS

  public addBasicFilter() {
    this.cognitiveService.addBasicFilter(this.formCognitiveSearch);
    this.expandSelected('basic');
  }

  public addExtractedFilter() {
    this.getDatamodels();
    this.cognitiveService.addExtractedFilter(this.formCognitiveSearch);
    this.expandSelected('extracted');
  }

  public addValidatedFilter() {
    this.getDatamodels();
    this.cognitiveService.addValidatedFilter(this.formCognitiveSearch);
    this.expandSelected('validated');
  }

  removeBasic(form, i) {
    this.cognitiveService.removeInputControl(form, i);
  }

  private getDatamodels() {
    const datamodelMap = this.global.getDataModelMap();
    if (datamodelMap.size === 0 && !this.global.passedWatcherDatamodels) {
      this.loadingDatamodels = true;
      this.subscriptionDataModels = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodels = Array.from(datamodelMap.values());
          this.fieldOfInterestIds = this.getFieldOfInterestList(datamodelMap);
          this.loadingDatamodels = false;
        });
    } else {
      this.datamodels = Array.from(datamodelMap.values());
      this.fieldOfInterestIds = this.getFieldOfInterestList(datamodelMap);
    }
  }

  private getCategories() {
    this.languages = Array.from(this.global.getLanguages().values());
    this.status = Array.from(this.global.getGlobalStatus().values());

    if (
      this.languages.length === 0 &&
      this.status.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.loadingGlobal = true;
      this.subscriptionGlobal = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.status = Array.from(this.global.getGlobalStatus().values());
          this.languages = Array.from(this.global.getLanguages().values());
          this.loadingGlobal = false;
        });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionDataModels) {
      this.subscriptionDataModels.unsubscribe();
    }
  }

  public expandSelected(type: string) {
    if (!this.isExpanded(type)) {
      this.toggleExpanded(type);
    }
  }

  /**
   * Toggle expanded status
   * @param index
   */
  public toggleExpanded(index: string) {
    const index_element = this.expandedFilters.indexOf(index);
    if (index_element >= 0) {
      this.expandedFilters.splice(index_element, 1);
    } else {
      this.expandedFilters.push(index);
    }
  }

  /**
   * Check if is expanded
   * @param index
   */
  public isExpanded(index: string) {
    return this.expandedFilters.indexOf(index) !== -1;
  }

  private getFieldOfInterestList(datamodels) {
    let fieldofinterestlist = [];
    datamodels.forEach(dm =>
      dm.groupoffields.forEach(gof =>
        gof.fieldsofinterest.forEach(foi =>
          fieldofinterestlist[foi.fieldofinterestdisplayname]
            ? fieldofinterestlist[foi.fieldofinterestdisplayname].push(
                foi.fieldofinterestid
              )
            : (fieldofinterestlist[foi.fieldofinterestdisplayname] = [
                foi.fieldofinterestid
              ])
        )
      )
    );
    return fieldofinterestlist;
  }

  // -------- COGNITIVE SEARCH FINISHES

  /**
   * Get documents from database
   */
  private getDocuments() {
    if (!this.queryString) return;
    const params = { ...this.pagination, search: this.queryString };
    this.search = true;
    this.loading = true;
    this.apiService.get('search/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: DocumentModel[];
      }) => {
        this.rows = data;
        this.paginationService.setPage('documents', data);
        this.pagination = this.paginationService.setPagination('documents', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Go to the project with the project information pass by params
   */
  public goToDocument(document) {
    this.link.goToDocument(document);
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectDocument.documentdisplayname',
        name: 'documentdisplayname',
        type: 'link',
        clickLink: this.goToDocument.bind(this),
        orderBy: 'documentdisplayname'
      },
      {
        header: 'projectDocument.username',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'projectDocument.status',
        name: 'status',
        type: 'one-span-status'
      },
      {
        header: 'projectDocument.dataModel',
        name: 'datamodel',
        second: 'datamodeldisplayname'
      },
      {
        header: 'projectDocument.date',
        name: 'createddate',
        type: 'date',
        format: 'lll',
        orderBy: 'createddate'
      },
      {
        header: 'projectDocument.analysisName',
        name: 'analysis',
        second: 'analysisname'
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      getDataFromDB: this.getDocuments.bind(this),
      responsiveTitle: {
        label: 'documentdisplayname',
        value: 'createddate',
        formatDate: 'lll'
      }
    };
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  public cleanFilters() {
    this.formCognitiveSearch = this.cognitiveService.getPreviousSearch();
    this.formCognitiveSearch.removeControl('basicFilters');
    this.formCognitiveSearch.removeControl('extractedFilters');
    this.formCognitiveSearch.removeControl('validatedFilters');
    this.cognitiveService.setPreviousSearch(undefined);

    this.onSearch();
    this.search = false;
    this.loading = false;
  }

  CheckCleanStatus() {
    this.previousSearch = this.cognitiveService.getPreviousSearch();
    if (this.previousSearch == undefined) {
      return true;
    } else {
      const basicArray = this.formCognitiveSearch.get('basicFilters');
      const extractedArray = this.formCognitiveSearch.get('extractedFilters');
      const validatedArray = this.formCognitiveSearch.get('validatedFilters');

      if (basicArray || extractedArray || validatedArray) {
        return false;
      } else {
        return true;
      }
    }
  }
}
