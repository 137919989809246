import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  HostListener
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { FormGroup } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';
import { TrainableService } from './trainable.service';

@Component({
  selector: 'app-trainable',
  templateUrl: './trainable.component.html',
  styleUrls: ['./trainable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainableComponent implements OnInit, OnDestroy {
  formTrainableDM: FormGroup;
  public tenantName: any;
  public isNew: boolean = true;
  public loadingSave: boolean = false;
  public loaded: boolean = false;
  public expandedGOF = [];
  public subscriptionUtils: Subscription;
  public routeSub: Subscription;
  public datamodel: any;
  public languageList;
  public windowScrollTop: number = 0;
  public distanceDocumentRenderToWindowTop: number = 92;
  public _userPermissions: any;
  public showReviewFlow: boolean = false;
  private subscriptionLanguages: Subscription;
  private user: any = {};

  constructor(
    private apiService: ApiService,
    public global: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private trainableService: TrainableService,
    private translate: TranslatePipe,
    private globalService: GlobalService
  ) {
    this.tenantName = this.authService.getTenantName();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.showReviewFlow = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
  }

  ngOnInit() {
    const datamodelMap = this.global.getTrainableDatamodelsMap();
    this.languageList = Array.from(this.global.getLanguages().values());
    if (
      this.languageList.length === 0 &&
      datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.languageList = Array.from(this.global.getLanguages().values());
          this.initTrainable();
        });
    } else if (
      this.languageList.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionLanguages = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.languageList = Array.from(this.global.getLanguages().values());
          this.initTrainable();
        });
    } else {
      this.initTrainable();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.subscriptionLanguages) {
      this.subscriptionLanguages.unsubscribe();
    }
  }

  private initTrainable() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      const datamodelidParam = params.get('datamodelid');
      if (datamodelidParam) {
        this.isNew = false;
        this.datamodel = this.global.getDataModelById(+datamodelidParam);
        const languages =
          this.datamodel.languages.length != 0
            ? this.datamodel.languages[0].language.langid
            : null;
        this.formTrainableDM = this.trainableService.getFormTrainable(
          this.datamodel.datamodelcontent,
          this.isNew,
          this.datamodel.datamodeldisplayname,
          languages,
          this.datamodel.maxreviewdays
        );
      } else {
        this.formTrainableDM = this.trainableService.getFormTrainable(
          {},
          this.isNew
        );
      }
      this.formTrainableDM.markAllAsTouched();
      this.toggleExpandedGOF(0);
      this.loaded = true;
    });
  }

  public addGroupOfField() {
    this.trainableService.addGroupOfField(this.formTrainableDM);
  }

  /**
   * Get GOF name
   * @param gofName
   */
  private getGOFName(gofName: string) {
    return gofName
      ? gofName
      : this.translate.transform('trainable.groupOfFields.newGOF');
  }

  /**
   * Toggle gof expanded status
   * @param index gof index number
   */
  private toggleExpandedGOF(index: number) {
    const index_element = this.expandedGOF.indexOf(index);
    if (index_element >= 0) {
      this.expandedGOF.splice(index_element, 1);
    } else {
      this.expandedGOF.push(index);
    }
  }

  /**
   * Check if case is expanded
   * @param index case index number
   */
  private isExpandedGOF(index: number) {
    return this.expandedGOF.indexOf(index) !== -1;
  }

  /**
   * Save current trainable datamodel
   */
  private saveDatamodel() {
    const postMessage = this.isNew
      ? this.translate.transform('trainable.created')
      : this.translate.transform('trainable.updated');
    this.loadingSave = true;
    if (this.isNew) {
      this.apiService
        .post(
          'datamodels',
          this.formTrainableDM.getRawValue(),
          postMessage,
          true,
          {},
          true
        )
        .subscribe(
          newData => {
            this.global.addDatamodel(newData, this.isNew);
            this.loadingSave = false;
            this.router.navigate([`${this.tenantName}/datamodels-validations`]);
          },
          () => {
            this.loadingSave = false;
          }
        );
    } else {
      this.apiService
        .put(
          'datamodels/',
          this.datamodel.datamodelid,
          this.formTrainableDM.getRawValue(),
          postMessage,
          true,
          {},
          true
        )
        .subscribe(
          newDatamodel => {
            this.global.addDatamodel(newDatamodel, this.isNew);
            this.loadingSave = false;
            this.router.navigate([`${this.tenantName}/datamodels-validations`]);
          },
          () => {
            this.loadingSave = false;
          }
        );
    }
  }

  public isDocumentRenderFixed() {
    return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
  }

  /**
   * Listen for window scroll event
   */
  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    this.windowScrollTop = window.pageYOffset;
  }
}
