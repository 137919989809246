import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { ApiService } from '../../../services/api.service';
import { ProjectModel } from '../../../models/project-mstr.model';
import { AuthenticationService } from '../../../security/authentication.service';
import { GlobalService } from '../../../services/global.service';
import { Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit, OnDestroy {
  @Output() savedEvent = new EventEmitter<ProjectModel>();
  public reason = Reason;
  public subscriptionConsolidations: Subscription;
  public consolidations: any;
  public projectForm: FormGroup;
  public user: any;
  public showValidationsCommentsLink: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private global: GlobalService,
    private fb: FormBuilder,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.showValidationsCommentsLink = this.authService.userCanViewModule(
      this.user,
      'ValidationsComments'
    );
    this.projectForm = this.fb.group({
      projectname: ['', [Validators.required, this.checkLengthAndPatternOfProjectName]],
      allowcomments: [false, Validators.required],
      consolidated: this.fb.array([])
    });

    let consolidations = this.global.getConsolidations();
    if (!consolidations && !this.global.passedWatcherUtils) {
      this.subscriptionConsolidations = this.global
        .watchUtils()
        .subscribe((data: string) => {
          consolidations = this.global.getConsolidations();
          this.addConsolidations(consolidations);
        });
    } else {
      this.addConsolidations(consolidations);
    }
  }

  public checkLengthAndPatternOfProjectName(control: FormControl) {
    const projectValue = control.value;
    const projectLen = projectValue?.length;
    const useRegex = projectValue.match(/[^a-zA-Z0-9 -]/g)
    if (projectLen !== 0) {
      if (projectLen < 100 && useRegex) {
        return {
          projectLenPlusPatternValidation: true
        }
      } else if (projectLen >= 100 && !useRegex) {
        return {
          projectLengthValidation: true
        }
      } else if (projectLen >= 100 && useRegex) {
        return {
          projectPatternValidation: true
        }
      }
    }
    return null;
  }

  
  get f() {
    return this.projectForm.controls;
  }

  public onCreateProject() {
    const body = { ...this.projectForm.value };
    const consolidated = [];
    body.consolidated.forEach(cons => {
      if (cons['checked']) {
        consolidated.push(cons['consolidatedtypeid']);
      }
    });
    body.consolidated = consolidated;

    this.apiService
      .post('projects', body, 'projectList.create')
      .subscribe((project: ProjectModel) => {
        this.savedEvent.emit(project);
        this.activeModal.close(this.reason.submitBtn);
      });
  }

  ngOnDestroy(): void {
    if (this.subscriptionConsolidations) {
      this.subscriptionConsolidations.unsubscribe();
    }
  }

  private addConsolidations(consolidations) {
    const control = <FormArray>this.projectForm.controls['consolidated'];

    consolidations.forEach(consolidatedtype => {
      control.push(
        this.fb.group({
          consolidatedtypeid: [
            consolidatedtype['consolidatedtypeid'],
            Validators.required
          ],
          consolidatedtypename: [
            { value: consolidatedtype['consolidatedtypename'], disabled: true },
            Validators.required
          ],
          checked: [false, [Validators.required]]
        })
      );
    });
  }
}
