import { Component, OnInit, Input } from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { PaginationService } from 'src/app/services/pagination.service';
import { ApiService } from 'src/app/services/api.service';
import { DocumentModel } from 'src/app/models/document.model';
import { LinkService } from 'src/app/services/link.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Router } from '@angular/router';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';
import { Subscription, Subject } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-document-parent',
  templateUrl: './document-parent.component.html',
  styleUrls: ['./document-parent.component.scss']
})
export class DocumentParentComponent implements OnInit {
  @Input() documentDetails: any = {};

  public user: any = {};
  public rows: {};
  public columns: any[];
  public settingTable: any;
  public selectedItems: Array<number> = [];
  public loading: boolean = true;
  public backgroundLoading: boolean = false;
  public pagination: PaginationModel;
  public totalDocuments: number = 0;

  // Filter variables
  public filters: any = [];
  public labelFilters: any[];
  public datamodelList: any[];
  public statusList: any[];
  public showFilters: boolean = false;
  public assignedToFilter: boolean = false;
  public projectFilter: boolean = false;

  // Validation variables
  public reviewersGroupsTypes: any[];
  public subscriptionReviewers: Subscription;

  // Const
  public _userPermissions: any;

  //Document to consolidate variables
  public responseTableData = {};
  public countItemsSelected: number;
  public itemsSelected = [];

  // Preview current selection on pills
  public previewSelectionOnAttribute: String;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private paginationService: PaginationService,
    private linkService: LinkService,
    private globalService: GlobalService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  ngOnInit() {
    this.getColumns();
    this.getSettingTable();
    this.pagination = this.paginationService.getDefaultPagination('documents');
    this.statusList = this.globalService.getGlobalStatus();
    this.datamodelList = Array.from(
      this.globalService.getDatamodels().values()
    );
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    this.getDocuments();
  }

  /**
   * Get documents from storage or database
   */
  private getDocuments() {
    const documentPage = this.paginationService.getPage('childDocuments');
    const defaultPage =
      this.paginationService.getDefaultPagination('documents');
    if (
      documentPage &&
      documentPage.data.length &&
      documentPage.page == 1 &&
      documentPage.order_by == defaultPage.order_by &&
      documentPage.desc == defaultPage.desc
    ) {
      // If document are loaded, show them and hide spinner
      this.rows = documentPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If document aren't loaded, show spinner
      this.loading = true;
    }
    // Always request document from db
    this.getDocumentsFromDB();
  }

  /**
   * Get documents from database
   */
  private getDocumentsFromDB() {
    const params = {
      ...this.pagination,
      ...this.filters,
      parentdocumentid: this.documentDetails.documentid
    };

    this.apiService.get('documents/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        newTab: {};
        order_by: string;
        desc: number;
        // data: DocumentModel[];
        data: any[];
      }) => {
        this.serializeData(data); //
        data.newTab = {
          type: 'link-fa-icon',
          header: 'validation.documentValidation.action',
          link: 'external',
          title: 'responsiveTable.newTab',
          class: 'td-icon',
          clickLink: this.goToChildDocumentNewTab.bind(this),
          namefunc: rowData => {
            return '<i class="fa fa-external-link"></i>';
          }
        };
        this.rows = data;
        this.totalDocuments = data.total_elements;
        this.paginationService.setPage('childDocuments', data);
        this.pagination = this.paginationService.setPagination(
          'childDocuments',
          {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          }
        );
        this.loading = false;
        this.backgroundLoading = false;
      },
      () => {
        this.loading = false;
        this.backgroundLoading = false;
      }
    );
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectDocument.documentdisplayname',
        name: 'documentdisplayname',
        type: 'link',
        clickLink: this.goToChildDocument.bind(this),
        orderBy: 'documentdisplayname'
      },
      {
        header: 'projectDocument.dataModel',
        name: 'datamodel',
        second: 'datamodeldisplayname',
        orderBy: 'datamodelname'
      },
      {
        header: 'projectDocument.date',
        name: 'createddate',
        type: 'date',
        format: 'lll',
        orderBy: 'createddate'
      },
      {
        header: 'projectDocument.status',
        name: 'status_data',
        title: 'projectDocument.status',
        type: 'one-span-status',
        orderBy: 'statusid'
      },
      {
        header: ' ',
        name: 'documentid',
        type: 'export-button'
      }
    ];

    if (this.documentDetails.datamodel.isclassifier) {
      let datamodelclassifier = [
        {
          header: 'documentInfo.parentPages',
          title: 'documentInfo.parentPages',
          type: 'custom',
          namefunc: (rowData: any) => {
            const text = PagesSelection.getChildDocumentSelectionString(
              rowData,
              {
                getPagesFromProperty: 'pages'
              }
            );
            return `<span
            class="ellipsis-clamp-1"
            title="${text}">${text}</span>`;
          }
        },
        {
          header: 'documentInfo.pagesnum',
          title: 'documentInfo.pagesnum',
          type: 'custom',
          namefunc: (rowData: any) => rowData.pages.length
        }
      ];
      this.columns.push(...datamodelclassifier);
    }
    let tags = [
      {
        header: 'Tags',
        name: 'tags',
        tags: this.getTagsIcon.bind(this),
        type: 'tags',
        orderBy: 'tagname'
      }
    ];

    this.columns.push(...tags);
    this.setAdditionalColumns();
  }

  private setAdditionalColumns() {
    if (this.user.isadmin) {
      this.assignedToFilter = true;
      const assignedToColumn = {
        header: 'projectDocument.assignedUser',
        name: 'assigned',
        optional: 'true',
        class: 'small-size-column',
        title: 'projectDocument.assignedUser'
      };
      const assignedToGroup = {
        header: 'validation.validationToReview.reviewGroup',
        name: 'groupName',
        optional: 'true',
        class: 'small-size-column',
        orderBy: 'groupid'
      };
      this.columns.push(assignedToGroup);
      this.columns.push(assignedToColumn);
    }
  }

  private serializeData(data) {
    data.data.map(document => {
      if (document.approvals) {
        const currentApproval = document.approvals.find(
          a => a.currentapproval === true
        );
        if (currentApproval) {
          if (currentApproval.assigned) {
            document['assigned'] = currentApproval.assigned.username;
          }
          if (
            this.reviewersGroupsTypes.find(
              g => g.groupid === currentApproval.groupid
            )
          ) {
            document['groupName'] = this.reviewersGroupsTypes.find(
              g => g.groupid === currentApproval.groupid
            ).groupname;
          }
        }
      }
    });
  }

  /**
   * Return loaded SettingTable with the general settings for the component responsive table
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'documentid',
      hasSelect: 'true',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getDocuments.bind(this),
      actionsOnSelected: [
        {
          click: this.deleteSelectedDocuments.bind(this),
          class: 'fa fa-trash cursor-pointer',
          title: 'projectDocument.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteDocument.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'documentdisplayname',
        value: 'createddate',
        formatDate: 'lll'
      },
      paginationClass: 'relative-pag'
    };
    this.setAdditionalOptions();
  }

  public setAdditionalOptions() {
    this.settingTable['hasSelectedFunctions'] = 'true';
    this.settingTable['changeSelectedItems'] =
      this.changeSelectedItems.bind(this);
    this.settingTable['isSelected'] = this.isSelected.bind(this);
    this.settingTable['checkerItems'] = this.getItemsToConsolidate.bind(this);
    this.previewSelectionOnAttribute = 'documentdisplayname';
  }

  /**
   * Handle loading of the table from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Delete single document
   */
  private deleteDocument(item) {
    this.deleteDocumentModal([item.documentid]);
  }

  /**
   * Delete multiple documents
   */
  private deleteSelectedDocuments(selectedItems) {
    let numericIds = [];

    selectedItems.forEach(item => numericIds.push(item.documentid));

    this.deleteDocumentModal(numericIds);
  }

  /**
   * Load modal to confirm document(s) removal
   */
  private deleteDocumentModal(documentsids: number[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'document',
      notAllowed: documentsids.length < 1
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: documentsids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {}
    );
  }
  public cleanAllSelectedItems() {
    this.itemsSelected.forEach(item => {
      this.changeSelectedItems(item, this.rows);
    });
    this.itemsSelected = [];
  }
  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody) {
    this.apiService
      .post('documents/delete', requestbody, 'projectDocument.delete')
      .subscribe(
        () => {
          this.loading = false;
          this.cleanAllSelectedItems();
          this.selectedItems = [];
          this.pagination = this.paginationService.changePagination(
            'childDocuments',
            'page',
            1
          );
          // Remove documents from view while request from db
          this.documentDetails.splitteddocumentlist =
            this.documentDetails.splitteddocumentlist.filter(
              el => requestbody.ids.indexOf(el.subdocumentid) < 0
            );
          this.loading = false;
          this.getDocuments();
        },
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return (
      (this.user && this.user.isadmin) ||
      (this.user &&
        this.authService.hasPermission(this._userPermissions.DELETE_DOCUMENTS))
    );
  }

  /**
   * Go to the information pass by params
   */
  public goToChildDocumentNewTab(document) {
    this.linkService.goToChildDocument(document, true);
  }

  /**
   * Go to the information pass by params
   */
  public goToChildDocument(document) {
    this.linkService.goToChildDocument(document, false);
  }

  /**
   * Return CSS Class for One tag icon or some tags icon
   */
  public getTagsIcon(tags: any) {
    if (tags.length > 0) {
      return tags.length > 1 ? 'fa fa-tags' : 'fa fa-tag';
    }
    return '';
  }

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters) {
    this.onBackgroundLoadingChange(true);

    this.filters = {
      documentdisplayname:
        filters['documentdisplayname'] !== undefined
          ? filters['documentdisplayname']
          : '',
      documentdisplayname_logic:
        filters['documentdisplayname_logic'] !== undefined
          ? filters['documentdisplayname_logic']
          : '',
      datamodelid:
        filters['datamodelId'] !== undefined ? filters['datamodelId'] : '',
      createdbyname:
        filters['createdByName'] !== undefined ? filters['createdByName'] : '',
      tagname: filters['tagName'] !== undefined ? filters['tagName'] : '',
      statusid: filters['statusId'] !== undefined ? filters['statusId'] : '',
      assigned_to_name:
        filters['assignedTo'] !== undefined ? filters['assignedTo'] : '',
      review_group:
        filters['reviewgroup'] !== undefined ? filters['reviewgroup'] : ''
    };

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodelid':
            return { name: 'datamodelname', value: filters['datamodelname'] };
          case 'documentdisplayname_logic':
            return { name: 'documentdisplayname_logic', value: filters['documentdisplayname_logic'] };
          case 'statusid':
            return { name: 'status_name', value: filters['status_name'] };
          case 'review_group':
            return {
              name: 'review_group_name',
              value: filters['review_group_name']
            };
          default:
            return { name: f, value: this.filters[f] };
        }
      });
    this.getDocumentsFromDB();
  }

  /**
   * Function that returns the inner logic text value
   */
   public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  /**
   * Variable to show the filter panel or not
   */
  public onRemoveItemEvent(event) {
    if (event.length > 0) {
      this.itemsSelected = event;
    } else {
      this.itemsSelected = event.items;
      this.changeSelectedItems(event.index, event.data);
    }
  }

  /**
   * stored items selected in table to not lose it when paginate
   * @param index index of element
   * @param data all data array of current page
   */
  public changeSelectedItems(index, data) {
    let newAddition = false;
    if (data) {
      const index_page = data.data.indexOf(index);
      if (!this.responseTableData[data.page]) {
        this.responseTableData[data.page] = {};
      }
      if (this.responseTableData[data.page][index_page]) {
        delete this.responseTableData[data.page][index_page];
      } else {
        if (index_page >= 0) {
          newAddition = true;
          this.responseTableData[data.page][index_page] = data.data[index_page];
        }
      }
    }
    const items = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        if (
          JSON.stringify(this.responseTableData[number_page][number_item]) ==
            JSON.stringify(index) &&
          !newAddition
        ) {
          delete this.responseTableData[number_page][number_item];
        } else {
          items.push(this.responseTableData[number_page][number_item]);
        }
      }
    }
    this.countItemsSelected = items.length;
    this.itemsSelected = items;
    return items;
  }

  public showDocumentsSelected() {
    return (
      !this.loading && this.countItemsSelected && this.countItemsSelected > 0
    );
  }

  public getItemsToConsolidate() {
    const items = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        items.push(this.responseTableData[number_page][number_item]);
      }
    }
    return items;
  }

  /**
   * Check if is selected when paginate to tick checkbox or not
   * @param index index of element
   * @param data all data array of current page
   */
  public isSelected(index, data) {
    const index_page = data.data.indexOf(index);
    let isSelected = false;
    if (this.responseTableData[data.page]) {
      if (this.responseTableData[data.page][index_page]) {
        isSelected = true;
      }
    }
    return isSelected;
  }
  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public cleanIndividualFilter(filter: string){
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    
    if(filter === 'review_group_name'){
      filter = 'review_group'
    }

    if(filter === 'status_name'){
      filter = 'statusid'
    }

    if(filter === 'datamodelname'){
      filter = 'datamodelid'
    }

    this.filterEvent.next(filter)
    this.filters[filter] = ''; 
    
    if(filter === 'documentdisplayname'){
      this.filters['documentdisplayname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'documentdisplayname_logic');
    }
    
    this.onBackgroundLoadingChange(true);
    this.getDocumentsFromDB();
  }
}
