import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { TranslatePipe } from '../pipes/translate.pipe';

@Injectable()
export class PBIService {
  private subPBI = new Subject<any>();
  private tokenPBI: any;
  private tokenMicrosoft: any;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private translate: TranslatePipe
  ) {}

  /**
   * Generate token to connect to Microsoft
   */
  public getPBIConnection(groupid, reportid, datasetid, user) {
    if (
      this.tokenMicrosoft == undefined ||
      (this.tokenMicrosoft != undefined && this.tokenMicrosoft.expires_in < 300)
    ) {
      this.apiService.get('login/pbiauth/', {}, '').subscribe(
        data => {
          this.tokenMicrosoft = data;
          this.generatePBIApiToken(
            this.tokenMicrosoft.access_token,
            groupid,
            reportid,
            datasetid,
            user
          );
        },
        error => {
          this.alertService.error(
            this.translate.transform('powerbi.connection'),
            true,
            ''
          );
        }
      );
    } else {
      this.generatePBIApiToken(
        this.tokenMicrosoft.access_token,
        groupid,
        reportid,
        datasetid,
        user
      );
    }
  }

  /**
   * Generate token to connect to PBI API
   */
  public generatePBIApiToken(token, groupid, reportid, datasetid, user) {
    const url =
      'https://api.powerbi.com/v1.0/myorg/groups/' +
      groupid +
      '/reports/' +
      reportid +
      '/GenerateToken';
    let data = {
      accessLevel: 'View',
      identities: [
        {
          username: user.userid,
          roles: [user.role.rolename],
          datasets: [datasetid]
        }
      ]
    };
    this.http
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        withCredentials: false
      })
      .subscribe(
        token => {
          this.setPBIToken(token);
          this.subPBI.next('');
        },
        () => {
          this.alertService.error(
            this.translate.transform('powerbi.connection'),
            true,
            ''
          );
        }
      );
  }

  /**
   * Observable of PBI Access
   */
  public watchPBIAccess(): Observable<any> {
    return this.subPBI.asObservable();
  }

  /**
   * Return PBI Token
   */
  public getPBIToken() {
    return this.tokenPBI;
  }

  /**
   * Set PBI Token
   */
  public setPBIToken(token) {
    return (this.tokenPBI = token);
  }

  /**
   * Clear PBI Token
   */
  public clearPBIToken() {
    return (this.tokenPBI = null);
  }

  /**
   * Return Microsoft Token
   */
  public gettokenMicrosoft() {
    return this.tokenMicrosoft;
  }

  /**
   * Set Microsoft Token
   */
  public settokenMicrosoft(token) {
    return (this.tokenMicrosoft = token);
  }

  /**
   * Clear Microsoft Token
   */
  public cleartokenMicrosoft() {
    return (this.tokenMicrosoft = null);
  }
}
