import { Directive, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subscriber, Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { AuthenticationService } from "../authentication.service";

export interface ICustomGuard {

  guardRouter: Router;
  guardRoute: ActivatedRouteSnapshot;
  guardRouteState: RouterStateSnapshot;
  /**
   * Final result returned by the canActivate, canActivateChild, canLoadMethods
   */
  result$: Observable<boolean | UrlTree>;
  /**
   * Subscriber  of the result observable to notify results to the subscribers.
   */
  observer: Subscriber<boolean | UrlTree>;
  /**
   * Contains all the subscriptions generated release memory on destroy.
   */
  subscriptions: Subscription[];

  /**
   * Sets the information regarding the route
   */
  setRouteInformation(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void;
  /**
   * Creates a new observable to the result property and
   * also to the observer property via the observale subscriber.
   * @param onObservableCreated Method to execute within the subscribe param
   * of the observable.
   */
  createResult(onObservableCreated: () => void): void;
  /**
   * Logs in in the API through the login endpoint and retrieves the user
   * @param tenantName Name of the tenant
   * @param onUserAcquired Callback when user is acquired
   * @param disposeSubscriptions If true, the subscriptions will be disposed
   * @param onError Callback on error
   */
  loginAPI(
    onUserAcquired: (user: any) => void,
    onError: (error: any) => void,
    tenantName?: string): void;
  /**
   * Notifies the final result of the guard via the observer subscriber.
   * @param result
   */
  notifyResult(result: boolean | UrlTree): void;
  /**
   * Callback to be executed if the user login in the API is successful.
   * The user and the token are set in the authentication service
   * @param user User object recieved from the API
   */
  onAccessGranted(user: any): void;
  /**
   * Callback to be executed if the user login in the API is not successful.
   * Redirects to error pages.
   * @param error
   */
  onAccessDenied(error: any): void;
  /**
   * Gets the tenant from the url to include in the headers
   * of the request.
   * @returns the tenant name
   */
  getTenant(): string;
  /**
   * Method to be overriden to set the token
   * @param user
   */
  setTokenExpirationDate?(user: any): void;
  /**
   * Checks if the user has accepted the privacy note.
   * @param user The logged in user
   * @returns True if the user has not accepted the privacy note yet.
   * Else, or in case the module is not present, returns false
   */
   checkPrivacyNote(user: any): boolean;
    /**
   * Disposes the subscriptions.
   */
     disposeSubscriptions(): void;
} 

@Directive()
export abstract class CustomGuard implements ICustomGuard, OnDestroy {

  public guardRouter: Router;
  public guardRoute: ActivatedRouteSnapshot;
  public guardRouteState: RouterStateSnapshot;
  public result$: Observable<boolean | UrlTree>;
  public observer: Subscriber<boolean | UrlTree>;
  public subscriptions: Subscription[];

  constructor(
    protected authenticationService: AuthenticationService,
    protected apiService: ApiService,
  ) {
    this.subscriptions = [];
  }

  public setRouteInformation(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : void {
      this.guardRoute = route;
      this.guardRouteState = state;
  }

  public createResult(onObservableCreated: () => void): void {
    this.result$ = new Observable<boolean | UrlTree>(
      (observer) => {
        this.observer = observer;
        onObservableCreated();
      }
    )
  }

  public loginAPI(
    onUserAcquired: (user: any) => void,
    onError: (error: any) => void): void {
    const tenantName = this.getTenant();
    const subscription = this.apiService
    .get('login/', null, '', true, {}, tenantName)
    .subscribe(
      (user) => {
        onUserAcquired(user);
        subscription?.unsubscribe();
      },
      (error) => {
        onError(error);
        subscription?.unsubscribe();
      }
    );
  }

  public notifyResult(result: boolean | UrlTree): void {
    this.observer.next(result);
    this.observer.complete();
    this.disposeSubscriptions();
  }

  public onAccessGranted(user: any): void {
    this.authenticationService.setLoggedInUser(user);
    if (this.setTokenExpirationDate) {
      this.setTokenExpirationDate(user);
    }
    this.notifyResult(true);
    this.checkPrivacyNote(user);
  }

  public onAccessDenied(error: any): void {
    console.log('Access denied by Guard ==> ', error);
    const errorMessage = error?.error?.error
      ? error.error.error
      : 'Something went wrong';
    if (errorMessage === 'Authentication Token Expired') {
      this.notifyResult(
        this.authenticationService.getDefaultRouteUrlTree()
      );
    } else if (error?.status === 401) {
      this.notifyResult(
        this.authenticationService.getNotAllowedUrlTree()
      );
    } else {
      this.notifyResult(
        this.authenticationService.getServerErrorUrlTree()
      );
    }
  }

  public getTenant(): string {
    let tenant = this.guardRouteState.url.split('/')[1];
    tenant === 'tenant' ? '' : tenant;
    this.authenticationService.setTenantName(tenant);
    return tenant;
  }
  public setTokenExpirationDate?(user: any): void {};
  
  public checkPrivacyNote(user: any): boolean {
    const modulePrivacy = this.authenticationService.userCanViewModule(
      this.authenticationService.getLoggedInUser(),
      'PrivacyNoteFunctionality'
    );
    return modulePrivacy && user.privacyNoteAccepted == false;
  }

  public disposeSubscriptions(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
  public ngOnDestroy(): void {
    this.disposeSubscriptions();
  }

}
