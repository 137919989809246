import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-add-query-from',
  templateUrl: './add-query-from.component.html',
  styleUrls: ['./add-query-from.component.scss']
})
export class AddQueryFromComponent implements OnInit {
  @Input() nodes: any;
  @Input() selectedQuery: any;
  @Input() actionOnNode: any
  @Output() updateQueryTree = new EventEmitter();
  @Output() editTreeNode = new EventEmitter();
  @Output() deleteTreeNode = new EventEmitter();
  @Output() cancelQueryModalEvent = new EventEmitter();
  public addQueryForm: FormGroup;
  public isDeleteBtnClicked: boolean = false;

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.addQueryForm = new FormGroup({
      queryName: new FormControl(this.selectedQuery?.name, [Validators.required, Validators.minLength(3), Validators.maxLength(250), this.checkDupilcateQueryName.bind(this)]),
      queryText: new FormControl(this.selectedQuery?.text, [Validators.required, Validators.minLength(1), Validators.maxLength(512)]),
      queryQuestion: new FormControl(this.selectedQuery?.qna?.text, [this.checkLengthOfQueryQuestion])
    },
    );
  }

  // Duplicate Query name check in existing Nodes list
  public findDuplicateQuery = (nodes, queryName, isfound) => {
    if (!isfound) {
      const nodeNameLowerCase = nodes.name.toLowerCase();
      const queryNameLowerCase = queryName.toLowerCase();
      if (nodes.isGroup === false && nodeNameLowerCase == queryNameLowerCase && this.selectedQuery?.id !== nodes.id){
        isfound = true
      } else if (nodes.isGroup === true && nodes.children?.length > 0) {
        nodes.children.forEach((child) => {
          isfound = this.findDuplicateQuery(child, queryNameLowerCase, isfound);
        })
      }
    }
    return isfound
  }

  // Remove the empty space in start and end of user input to through error valication
  trimSpaces(event) {
    if(this.addQueryForm.get(event.target.id).value !== event.target.value.trim()){
      this.addQueryForm.get(event.target.id).setValue(event.target.value.trim())
    }    
  }

  //Stop Event Propagation
  onInputEnterPressed(event){
    event.preventDefault();
  }
  
  // Custom validation plus duplicate check
  public checkDupilcateQueryName(control: FormControl) {
    const createdQueryName = control.value
    if (createdQueryName?.length >= 3 && createdQueryName?.length <= 250 && this.nodes?.length > 0 && control.dirty) {
      let isDuplicate = null
      this.nodes.map(node => {
        if (isDuplicate !== true)
          isDuplicate = this.findDuplicateQuery(node, createdQueryName, isDuplicate)
        if (isDuplicate === true) {
          isDuplicate = true;
        }
      })
      if (isDuplicate === true)
        return {
          "duplicateQuery": isDuplicate
        }
      else
        return null;
    }
    return null;
  }


  public checkLengthOfQueryQuestion(control: FormControl) {
    if (control.value) {
      const queryQuestionSplit = control.value?.split(" ");
      if (control.value?.length !== 0 && (queryQuestionSplit.length < 2 || queryQuestionSplit.length > 64)) {
        return {
          questionLengthValidation: true
        }
      }
    }
    return null;
  }

  get f() {
    return this.addQueryForm.controls;
  }
  public addQueryToQuries() {
    const queryobj = {
      "queryName": this.addQueryForm.get('queryName').value,
      "queryText": this.addQueryForm.get('queryText').value,
      "queryQuestion": this.addQueryForm.get('queryQuestion').value,
      "isGroup": false
    }
    this.updateQueryTree.emit(queryobj);
  }

  public editQuery() {
    const queryObj = {
      ...this.selectedQuery,
      "name": this.addQueryForm.get('queryName').value,
      "text": this.addQueryForm.get('queryText').value
    }

    if (this.addQueryForm.get('queryQuestion').value) {
      // If question present on the form
      queryObj.qna = {
        ...this.selectedQuery?.qna,
        text: this.addQueryForm.get('queryQuestion').value
      }
    } else if (queryObj.qna) {
      // If question text is not present and qna object was already present then delete it
      delete queryObj.qna;
    }

    this.editTreeNode.emit(queryObj);
  }

  // Close/Cancel Modal
  closeModal() {
    this.cancelQueryModalEvent.emit({ value: false })
  }
  
  public deleteQuery(flag: boolean) {
    this.isDeleteBtnClicked = flag;
  }

  public deleteSkipped() {
    this.deleteQuery(false);
    this.closeModal();
  }

  public deleteConfirmed() {
    this.deleteQuery(false);
    this.deleteTreeNode.emit();
  }

}

