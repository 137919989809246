import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { StyleManagerService } from 'src/app/services/stylemanager.service';

@Component({
  selector: 'app-project-overview-view',
  templateUrl: './project-overview-view.component.html',
  styleUrls: ['./project-overview-view.component.scss']
})
export class ProjectOverviewViewComponent implements OnInit {
  public qualityData;
  public timeData;
  public usersData;
  public statusData;
  public qualityDataConfig;
  public timeDataConfig;
  public usersDataConfig;
  public statusDataConfig;
  public barchartdata: Array<any>;
  public barchartconfig: any;
  public loading: boolean;

  @Input() projectId: number | string;

  constructor(
    private global: GlobalService,
    private styleManager: StyleManagerService
  ) { }

  ngOnInit() {
    const style = this.styleManager.styleSettings;
    const statusChartStyle = style.charts.status;

    this.timeDataConfig = {
      keys: ['total'],
      labels: ['Documents'],
      dateKey: 'month',
      dateFormat: '%B',
      height: 340,
      colorScheme: style.charts.time.colorScheme,
      yAxis: 'Documents',
      xScaleFormat: '%b',
      curve: 'curveStep'
    };
    this.usersDataConfig = {
      key: 'total',
      label: 'name',
      height: 340,
      labelRotation: 15,
      yAxis: 'Documents'
    };
    this.qualityDataConfig = {
      keys: ['Blur', 'Dimness', 'Rotation', 'Size', 'Tilt'],
      label: 'name',
      height: 340,
      maxValue: 5,
      minValue: 1
    };
    this.statusDataConfig = {
      key: 'name',
      value: 'value',
      height: 340,
      colorScheme: statusChartStyle.colorScheme,
      colorKeys: {
        Pending: statusChartStyle.colorKeys.pending,
        Failure: statusChartStyle.colorKeys.failure,
        Success: statusChartStyle.colorKeys.success,
        'In Progress': statusChartStyle.colorKeys.inProgress,
        Warning: statusChartStyle.colorKeys.warning,
        'Partial Success': statusChartStyle.colorKeys.partialSuccess
      }
    };
    this.getChartData();
  }

  /**
   * Get Charts data from storage or database
   */
  private getChartData() {
    this.loading = true;
    this.global.getCharts('project', this.projectId).then(data => {
      this.global.setCharts('project', this.projectId, data);
      this.qualityData = data[0];
      this.statusData = data[1];
      this.timeData = data[2];
      console.log('timeData', this.timeData);
      this.usersData = data[3];
      this.loading = false;
    });
  }
}
