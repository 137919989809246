import { Component, OnInit, Input } from '@angular/core';
import { ValidationService } from '../../common-components/validation.service';
import { FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-extraction-docname',
  templateUrl: './extraction-docname.component.html',
  styleUrls: ['./extraction-docname.component.scss']
})
export class ExtractionDocnameComponent implements OnInit {
  @Input('formTest') formTest;
  public isDocNameOverrided: boolean = false;

  constructor(public validationService: ValidationService) {}

  ngOnInit() {
    if (
      this.formTest.get('docnameoverride') &&
      this.formTest.get('docnameoverride').value
    ) {
      this.isDocNameOverrided = true;
    }
    this.onDocNameOverrideChecked();
  }

  /**
   * Set docnameoverride form control as required if checkbox is true,
   * otherwise remove it
   */
  onDocNameOverrideChecked() {
    if (this.isDocNameOverrided) {
      this.formTest.addControl(
        'docnameoverride',
        new FormControl('', Validators.required)
      );
      this.validationService.touchControl(this.formTest, 'docnameoverride');
    } else if (this.formTest.get('docnameoverride')) {
      this.formTest.removeControl('docnameoverride');
    }
  }
}
