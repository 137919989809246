import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../../../common-components/validation.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-extraction-type',
  templateUrl: './extraction-type.component.html',
  styleUrls: ['./extraction-type.component.scss']
})
export class ExtractionTypeComponent implements OnInit {
  @Input('control') control: FormGroup;
  public validationFunctionsOptions = [];
  public parametersOptions = [];
  public parentList = [];

  constructor(
    public validationService: ValidationService,
    public global: GlobalService
  ) {}

  ngOnInit() {
    if (!this.control.get('selected_function')) {
      this.control.addControl(
        'selected_function',
        new FormControl('', Validators.required)
      );
    } else {
      this.validationFunctionsOptions = this.validationService
        .findChildFunctions(+this.control.get('selected_function').value)
        .filter(v => v.validationparent);
    }

    this.control.get('selected_function').valueChanges.subscribe(val => {
      this.onSelectFunction(+val);
    });
    this.control.get('validationfunctionid').valueChanges.subscribe(val => {
      this.onSelectValidationFunction(val);
    });

    if (
      this.control.get('validationfunctionid') &&
      this.control.get('validationfunctionid').value
    ) {
      const functionObj = this.validationService.getFunctionById(
        +this.control.get('validationfunctionid').value
      );
      if (functionObj && functionObj['parameters']) {
        this.parametersOptions = functionObj['parameters'].split('#');
      }
    }
  }

  /**
   * Get selected_function options
   */
  getSelectedFunctionsOptions() {
    return this.validationService.validationFunctions.filter(
      v => v.validationparent === null
    );
  }

  /**
   * Description: Handle selected_function change
   * @param id Function ID
   */
  onSelectFunction(id) {
    this.validationFunctionsOptions = this.validationService
      .findChildFunctions(id)
      .filter(v => v.validationparent);

    if (!this.control.get('validationfunctionid')) {
      this.control.addControl(
        'validationfunctionid',
        new FormControl('', Validators.required)
      );
    } else {
      this.control.get('validationfunctionid').setValue('');
      ['parameter', 'input'].forEach(value => {
        if (this.control.get(value)) {
          this.control.removeControl(value);
        }
      });
    }
  }

  /**
   * Description: Handle validationfunctionid change
   * @param id Function ID
   */
  onSelectValidationFunction(id) {
    const functionObj = this.validationService.getFunctionById(id);

    if (functionObj && functionObj['parameters']) {
      this.parametersOptions = functionObj['parameters'].split('#');
      if (!this.control.get('parameter')) {
        this.control.addControl(
          'parameter',
          new FormControl('', Validators.required)
        );
        this.control.get('parameter').valueChanges.subscribe(val => {
          this.onSelectParam(val);
        });
      } else {
        this.control.get('parameter').setValue('');
        if (this.control.get('input')) {
          this.control.removeControl('input');
        }
      }
    } else if (this.control.get('parameter')) {
      ['parameter', 'input'].forEach(value => {
        if (this.control.get(value)) {
          this.control.removeControl(value);
        }
      });
    }
  }

  /**
   * Handle parameter change.
   * @param value select value
   */
  onSelectParam(value) {
    if (value === 'input') {
      this.control.addControl(
        'input',
        new FormControl('', Validators.required)
      );
    } else if (this.control.get('input')) {
      this.control.removeControl('input');
    }
  }

  
}
