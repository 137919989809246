import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from '../../../models/close-reason.model';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutModalComponent implements OnInit {
  public reason: Reason;
  public body: string;
  public option: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.body = 'components.modal.delete.';
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }
}
