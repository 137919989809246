import { Component, OnInit, Input } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateExplorationsComponent } from '../../../components/modals/create-explorations/create-explorations.component';
@Component({
  selector: 'app-exploration-details-card',
  templateUrl: './exploration-details-card.component.html',
  styleUrls: ['./exploration-details-card.component.scss']
})
export class ExplorationDetailsCardComponent implements OnInit {

  @Input() explorationDetails = null;
  @Input() explorationId;
  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  editExploration(): void {
    // Opening up the create exploration modal
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: any = {};
    options.explorationDetails = { ...this.explorationDetails }
    options.explorationId = this.explorationId;
    const modalWindowRef = this.modalService.open(
      CreateExplorationsComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
  }

}
