import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaginationModel } from 'src/app/models/pagination';
import { ActivityModel } from 'src/app/models/activity.model';

import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  selector: 'app-log-tracking',
  templateUrl: './log-tracking.component.html',
  styleUrls: ['./log-tracking.component.scss']
})
export class LogTrackingComponent implements OnInit, OnDestroy {
  @Input() searchBy: any = {};

  public filterForm: FormGroup;
  public data: any = {};
  private filters: any = {};
  public isLoading: boolean;
  private pagination: PaginationModel;
  public _activities: ActivityModel[] = [];
  public subscriptionUtils: Subscription;
  public activityMstr: any;
  public showLogTracking: boolean = false;
  public user: any = {};

  constructor(
    private paginationService: PaginationService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private global: GlobalService,
    private authService: AuthenticationService

  ) {
    this.user = authService.getLoggedInUser();
    this.activityMstr = this.global.getActivityMstrConst();
    this.showLogTracking = this.authService.userCanViewModule(
      this.user,
      'TimeTrackingCoreFunctionality'
    );
  }

  ngOnInit() {
    this._activities = this.global.getActivities().filter(a => !a.isnavigation);
    if (this._activities.length === 0 && !this.global.passedWatcherUtils) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this._activities = this.global
            .getActivities()
            .filter(a => !a.isnavigation);
          this.initLogTracking();
        });
    } else {
      this.initLogTracking();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
  }

  private initLogTracking() {
    this.pagination = this.paginationService.getDefaultPagination(
      'logtracking'
    );
    this.filterForm = this.fb.group({
      username: [''],
      documentid: [''],
      activityid: ['']
    });
    this.getLogs();
  }

  /**
   * Request logs from database
   */
  public getLogs(filters = {}) {
    this.isLoading = true;
    const params = { ...this.pagination, ...filters, ...this.searchBy };
    this.apiService.get(`logs/`, params, '').subscribe(data => {
      this.paginationService.setPage('logtracking', data);
      this.pagination = this.paginationService.setPagination('logtracking', {
        total_elements: data.total_elements,
        page: data.page,
        page_size: data.page_size,
        num_pages: data.num_pages,
        order_by: data.order_by,
        desc: data.desc,
        query: this.pagination.query
      });
      this.data = data;
      this.isLoading = false;
    });
  }

  public onPageChange(page) {
    this.paginationService.changePagination('logtracking', 'page', page);
    this.getLogs();
  }

  /**
   * Get raw filters values and request logs
   */
  public filter() {
    this.filters = {};
    Object.keys(this.filterForm.value).forEach(k => {
      if (this.filterForm.value[k]) {
        this.filters[k] = this.filterForm.value[k];
      }
    });
    this.getLogs(this.filters);
  }

  /**
   * Remove all filters stored values and request logs
   */
  public clearFilters() {
    this.filterForm.get('username').setValue('');
    this.filterForm.get('documentid').setValue('');
    this.filterForm.get('activityid').setValue('');
    this.getLogs();
  }
}
