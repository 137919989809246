import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleLinear, scaleOrdinal, scaleSqrt } from 'd3-scale';
import { arc } from 'd3-shape';
import { hierarchy, partition } from 'd3-hierarchy';
import { interpolate } from 'd3-interpolate';
import { path } from 'd3-path';
import { transition } from 'd3-transition';
const d3 = {
  select,
  selectAll,
  scaleLinear,
  scaleOrdinal,
  scaleSqrt,
  arc,
  hierarchy,
  partition,
  interpolate,
  path,
  transition
};

/**
 * Sunburst chart Component
 */
@Component({
  selector: 'app-d3sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3SunburstComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  private cfg: any = {}; // Default configuration object
  private selection;
  private svg;
  private xScale;
  private yScale;
  private colorScale;
  private arc: any;
  private slice: any;
  private loaded: boolean = false;

  constructor(elementRef: ElementRef) {
    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 300, // Default height
      margin: { top: 20, right: 30, bottom: 10, left: 30 },
      key: 'name',
      value: 'size',
      colorScheme: 'schemeCategory10', // More shemes in https://github.com/d3/d3-scale-chromatic
      colorKeys: {}
    };
  }

  ngOnChanges() {
    if (
      this.data &&
      this.data['children'] &&
      this.data['children'].length > 0
    ) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    let self = this;
    Object.keys(config).forEach(function (key) {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(function (sk) {
          self.cfg[key][sk] = config[key][sk];
        });
      } else self.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    let radius = Math.min(this.cfg.width, this.cfg.height) / 2;

    this.xScale = d3
      .scaleLinear()
      .range([0, 2 * Math.PI])
      .clamp(true);

    this.yScale = d3.scaleSqrt().range([radius * 0.1, radius]);

    if (this.cfg.colorScheme instanceof Array === true) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }

    this.arc = d3
      .arc()
      .startAngle((d: any) => {
        return this.xScale(d.x0);
      })
      .endAngle((d: any) => {
        return this.xScale(d.x1);
      })
      .innerRadius((d: any) => {
        return Math.max(0, this.yScale(d.y0));
      })
      .outerRadius((d: any) => {
        return Math.max(0, this.yScale(d.y1));
      });
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    // Remove previus chart if exists
    if (this.loaded) this.selection.selectAll('svg').remove();

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--sunburst')
      .attr(
        'viewBox',
        '0 0 ' +
        (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
        ' ' +
        (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        'translate(' + this.cfg.margin.left + ',' + this.cfg.margin.top + ')'
      );

    // Helper group to center chart
    let centerg = g
      .append('g')
      .attr(
        'transform',
        'translate(' + this.cfg.width / 2 + ',' + this.cfg.height / 2 + ')'
      );

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--sunburst');

    // Compose data hierarchically
    let partition = d3.partition();

    let root = d3.hierarchy(this.data);
    root.sum(d => d[this.cfg.value]);

    // Slices groups
    let slices = centerg
      .selectAll('.chart__slice-group')
      .data(partition(root).descendants());

    // Remove old slices
    slices.exit().remove();

    // Slices
    this.slice = slices
      .enter()
      .append('g')
      .attr('class', 'chart__slice-group')
      .on('click', (d, i) => {
        window.event.stopPropagation();
        this.focusOn(i);
      });

    this.slice
      .append('path')
      .attr('class', 'chart__slice chart__slice--sunburst')
      .style('fill', d => this.sliceColor(d))
      .attr('d', this.arc)
      .on('mouseover', (d, i) => {
        let valuetext = i.data.value ? `: ${i.data.value}` : '';
        tooltip
          .html(() => {
            return `
            <div>${i.data.name}${valuetext}</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', () => {
        tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      });

    // Append arc division to write labels
    this.slice
      .append('path')
      .attr('class', 'chart__label-guide chart__label-guide--sunburst')
      .attr('pointer-events', 'none')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('id', (d, i) => 'hiddenArc-' + i)
      .attr('d', d => this.middleArcLine(d));

    // Slice's labels
    this.slice
      .append('text')
      .attr('class', 'chart__label chart__label--sunburst')
      .attr('display', d => (this.textFits(d) ? 'unset' : 'none'))
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('xlink:href', (d, i) => '#hiddenArc-' + i)
      .text(d => d.data.name);

    this.loaded = true;
  }

  /**
   * Calcule each area middle arc to write labels in it
   */
  private middleArcLine(d) {
    let angles = [
      this.xScale(d.x0) - Math.PI / 2,
      this.xScale(d.x1) - Math.PI / 2
    ];
    let r = Math.max(0, (this.yScale(d.y0) + this.yScale(d.y1)) / 2);

    let middleAngle = (angles[1] + angles[0]) / 2;
    let invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
    if (invertDirection) {
      angles.reverse();
    }

    let path = d3.path();
    path.arc(0, 0, r, angles[0], angles[1], invertDirection);
    return path.toString();
  }

  /**
   * Check if label fits in slice
   */
  private textFits(d) {
    let charSpace = 6; // Characters space
    let deltaAngle = this.xScale(d.x1) - this.xScale(d.x0);
    let r = Math.max(0, (this.yScale(d.y0) + this.yScale(d.y1)) / 2);
    return d.data.name.length * charSpace < r * deltaAngle;
  }

  /**
   * Transform slice animation
   */
  private focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
    var self = this;
    let transition = this.svg
      .transition()
      .duration(750)
      .tween('scale', () => {
        const xd = d3.interpolate(this.xScale.domain(), [d.x0, d.x1]),
          yd = d3.interpolate(this.yScale.domain(), [d.y0, 1]);
        return t => {
          this.xScale.domain(xd(t));
          this.yScale.domain(yd(t));
        };
      });

    transition
      .selectAll('path.chart__slice')
      .attrTween('d', d => () => this.arc(d));

    transition
      .selectAll('path.chart__label-guide')
      .attrTween('d', d => () => this.middleArcLine(d));

    transition
      .selectAll('text.chart__label')
      .attrTween('display', d => () => (this.textFits(d) ? 'unset' : 'none'));

    this.moveStackToFront(d);
  }

  /**
   * Transform slice recursively
   */
  private moveStackToFront(g) {
    var self = this;
    this.slice
      .filter(d => d === g)
      .each(function (d) {
        this.parentNode.appendChild(this);
        if (d.parent) {
          self.moveStackToFront(d.parent);
        }
      });
  }

  /**
   * Compute slice color
   */
  private sliceColor(d) {
    if (
      this.cfg.colorKeys &&
      this.cfg.colorKeys.hasOwnProperty(d.data[this.cfg.key])
    ) {
      return this.cfg.colorKeys[d.data[this.cfg.key]];
    } else {
      return this.colorScale((d.children ? d : d.parent).data.name);
    }
  }
}
