import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { Router } from '@angular/router';
import { PaginationModel } from 'src/app/models/pagination';
import { ProjectModel } from 'src/app/models/project-mstr.model';
import { AlertService } from 'src/app/services/alert.service';
import { DocumentsTableComponent } from 'src/app/components/documents-table/documents-table.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateConsolidatedComponent } from 'src/app/components/modals/create-consolidated/create-consolidated.component';
import { ResponsiveTableModalComponent } from 'src/app/components/modals/responsive-table-modal/responsive-table-modal.component';
import * as moment from 'moment';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { zip } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-consolidated-documents',
  templateUrl: './consolidated-documents.component.html',
  styleUrls: ['./consolidated-documents.component.scss']
})
export class ConsolidatedDocumentsComponent implements OnInit {
  @Input('consolidation') consolidation;

  public user: any;
  public showFilters: boolean = true;
  public showProjectTable: boolean = true;

  public filters: {} = [];
  public reviewersGroupsTypes: any[];

  public responseTableDataConsolidated = {};
  public responseTableDataProject = {};

  // Responsive table variables
  public loadingConsolidated: boolean;
  public loadingProjects: boolean;

  public backgroundLoadingConsolidated: boolean;
  public backgroundLoadingProjects: boolean;

  private paginationConsolidatedDocuments: PaginationModel;
  private paginationProjects: PaginationModel;

  public projectId = '';

  public columnsConsolidated: any[];
  public columnsProjects: any[];

  public paramDocumentTable: {} = [];

  @ViewChild(DocumentsTableComponent)
  private documentsTable: DocumentsTableComponent;

  public settingTableConsolidated: any;
  public settingTableProjects: any;

  public rowsConsolidated: any;
  public rowsProjects: any;

  public projects = [];
  public requiredinformation = '';

  public selectedProject: any;
  public oneProjectSelected = false;

  private paginationModal: PaginationModel;
  public modalData;
  public rowsModal;
  public _userPermissions: any;
  public showSendConsolidated: boolean = false;

  public invalidStatusNames = [
    'EXTRACTION_FAILURE',
    'EXTRACTION_IN_PROGRESS',
    'EXTRACTION_PENDING',
    'PENDING',
    'FAILURE',
    'PARTIAL',
    'IN_PROGRESS'
  ];

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private link: LinkService,
    private router: Router,
    private alertService: AlertService,
    private fileDownloadService: FileDownloadService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private global: GlobalService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.showSendConsolidated = this.authService.userCanViewModule(
      this.user,
      'SendConsolidated'
    );
    this.getColumnsProject();
    this.getSettingTableProject();

    this.getColumnsConsolidated();
    this.getSettingTableConsolidated();
  }

  ngOnInit() {
    this.paginationConsolidatedDocuments =
      this.paginationService.getDefaultPagination('consolidateddocuments');
    this.paginationProjects =
      this.paginationService.getDefaultPagination('projects');

    this.rowsConsolidated = {
      total_elements: 0,
      page: 1,
      page_size: 10,
      num_pages: 1,
      order_by: 'consolidateddocid',
      desc: 1,
      data: []
    };

    this.getProjectList();
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChangeConsolidated(bool) {
    this.loadingConsolidated = bool;
  }

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return (
      (this.user && this.user.isadmin) ||
      (this.user &&
        this.authService.hasPermission(
          this._userPermissions.DELETE_OUTPUT_FILES
        ))
    );
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Load data related to a project
   * @param projectid project id to get data
   */
  public onSelectProject(projectid) {
    this.responseTableDataConsolidated = {};

    this.loadingConsolidated = true;
    this.backgroundLoadingConsolidated = true;
    this.projectId = projectid;
    this.requiredinformation = '';
    this.oneProjectSelected = true;
    this.projects.map(projectconsolidated => {
      if (projectconsolidated.projectid == this.projectId) {
        projectconsolidated.consolidations.map(pc => {
          if (
            pc.consolidatedtypeid.consolidatedtypeid ==
            this.consolidation.consolidatedtypeid
          ) {
            this.requiredinformation =
              pc.consolidatedtypeid.consolidatedtypename;
          }
          // TODO store required information in requiredinformation to dynamic added into createconsolidation
        });
      }
    });
    this.getConsolidatedDocuments();
    this.getDocumentsToConsolidate();
  }

  /**
   * Update the documents to consolidate table
   */
  public getDocumentsToConsolidate() {
    if (this.paramDocumentTable['projectid'] && this.documentsTable) {
      this.setDocumentTableParams();
      this.documentsTable.responseTableData = {};
      this.documentsTable.initLoadingPage();
    } else {
      this.setDocumentTableParams();
    }
  }

  isDocumentsTableSetted() {
    if (this.documentsTable && !this.documentsTable.loading) {
      return true;
    }
    return false;
  }

  public consolidateAll() {
    if (!this.documentsTable.hasDocumentToSelect) {
      return;
    }
    this.documentsTable.consolidateDocuments(true);
  }

  /**
   * Function to set the param variables
   * for the document table component
   */
  private setDocumentTableParams() {
    this.paramDocumentTable['consolidatedtypeid'] =
      this.consolidation.consolidatedtypeid;
    this.paramDocumentTable['projectid'] = this.projectId;
    this.paramDocumentTable['requiredinformation'] = this.requiredinformation;
  }

  /**
   * Get Consolidated documents of project
   */
  private getConsolidatedDocuments() {
    const params = {
      consolidatedtypeid: this.consolidation.consolidatedtypeid,
      projectid: this.projectId,
      ...this.paginationConsolidatedDocuments
    };
    this.apiService.get('consolidateddocuments/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: any[];
      }) => {
        // this.serializeDataReviews(data);
        this.rowsConsolidated = data;
        this.paginationService.setPage('consolidateddocuments', data);
        this.paginationConsolidatedDocuments =
          this.paginationService.setPagination('consolidateddocuments', {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.paginationConsolidatedDocuments.query
          });
        this.loadingConsolidated = false;
        this.backgroundLoadingConsolidated = false;
        this.showProjectTable = false;
      },
      () => {
        this.loadingConsolidated = false;
        this.backgroundLoadingConsolidated = false;
      }
    );
  }

  /**
   * Go to the document
   */
  public goToDocument(document) {
    this.link.goToDocument(document);
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumnsConsolidated() {
    // TODO change headers
    this.columnsConsolidated = [
      {
        header: 'projectDocument.documentdisplayname',
        name: 'consolidatedname',
        type: 'link',
        clickLink: this.openModalRelatedDocumentsInfo.bind(this)
      },
      {
        header: 'projectDocument.date',
        name: 'createddate',
        type: 'date',
        format: 'lll',
        orderBy: 'createddate'
      },
      {
        header: 'consolidation.warnings',
        type: 'warning-message',
        tooltipPosition: 'top',
        checkCondition: (rowData: any): boolean =>
          rowData.excludeddocscount && rowData.excludeddocscount > 0,
        message: (rowData: any): string =>
          rowData.excludeddocscount === rowData.documents.length
            ? this.translate.transform('consolidation.fileError')
            : `${rowData.excludeddocscount} ${this.translate.transform(
                'consolidation.fileWarning'
              )}`,
        isError: (rowData: any) =>
          rowData.excludeddocscount === rowData.documents.length
      }
    ];
    if (this.showSendConsolidated) {
      this.columnsConsolidated.push({
        header: 'consolidation.sendSelectedLabel',
        type: 'link-fa-icon',
        clickLink: this.sendResultsAPI.bind(this),
        classIcon: this.isDisable.bind(this, 'fa fa-send iconsend'),
        title: this.isDisableHover.bind(this, true),
        class: 'td-icon iconsend'
      });
    }
  }

  public isDisableHover(action, row) {
    if (row.files.length == 0) {
      return 'consolidation.sendSelectedLabelNotAble';
    } else {
      return 'consolidation.sendSelectedLabel';
    }
  }
  public isDisable(cssClass, row) {
    if (row.files.length == 0) {
      return cssClass + '-disable';
    } else {
      return cssClass;
    }
  }
  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTableConsolidated() {
    this.settingTableConsolidated = {
      dataId: 'consolidateddocid',
      hasSelect: 'true',
      changeSelectedItems: this.changeSelectedItemsConsolidated.bind(this),
      isSelected: this.isSelectedConsolidated.bind(this),
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getConsolidatedDocuments.bind(this),
      actionsOnDownload: this.getDownloadsTypes.bind(this),
      hasDownloadOptions: this.hasEditPermissions(),
      responsiveTitle: {
        label: 'consolidatedname',
        value: 'createddate',
        formatDate: 'll'
      },
      paginationClass: 'relative-pag',
      actionsOnSelected: [
        {
          click: this.deleteSelectedConsolidatedDocuments.bind(this),
          class: 'fa fa-trash',
          title: 'consolidation.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteSelectedConsolidatedDocumentsResponsive.bind(this),
          class: 'fa fa-trash'
        }
      ]
    };
  }

  public deleteSelectedConsolidatedDocuments(selectedItems) {
    this.deleteDatamodelModal(selectedItems);
  }

  public deleteSelectedConsolidatedDocumentsResponsive(selectedItem) {
    this.deleteDatamodelModal([selectedItem.consolidateddocid]);
  }

  public sendResultsAPI(selectedItems) {
    let datamodels = [];
    selectedItems.documents.forEach(documentitem => {
      datamodels.push(documentitem['datamodel']['datamodeldisplayname']);
    });
    const paramsModal = {
      consolidateddocid: selectedItems.files[0]['consolidateddocfileid'],
      datamodels: datamodels,
      projectid: this.projectId
    };
    console.log(paramsModal);
    this.apiService
      .post('consolidateddocuments/send', paramsModal, '')
      .subscribe(
        data => {
          this.alertService.success(
            this.translate.transform('consolidation.sendRequestsuccess'),
            true,
            ''
          );
        },
        error => {
          this.translate.transform('consolidation.sendRequestunsuccess');
        }
      );
  }

  /**
   * Load modal to confirm document(s) removal
   */
  private deleteDatamodelModal(documentids: number[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'consolidated',
      notAllowed: documentids.length < 1
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loadingProjects = true;
          this.backgroundLoadingProjects = true;
          this.sendDeleteRequest(documentids);
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(documentIds: any) {
    const requestBody = { projectid: this.projectId };
    const multipleDeleteRequest = [];
    documentIds.forEach(documentId => {
      const deleteRequest = this.apiService.delete(
        `consolidateddocuments/`,
        documentId,
        '',
        true,
        { body: requestBody }
      );
      multipleDeleteRequest.push(deleteRequest);
    });
    const zipDeleteRequests = zip(...multipleDeleteRequest);
    zipDeleteRequests.subscribe(responses => {
      
      responses.forEach((response: string) => {
        var splitted = response.split(' ');
        if (response.includes('successfully')) {
          var successSplit = splitted.slice(1, -2);
          this.alertService.success(
            this.translate.transform('consolidation.sendDeleteRequestsuccess') +
              ' ' +
            successSplit.join(" "),
            true,
            ''
          );
        } else {
          var errorSplit = splitted.slice(3);
          this.alertService.error(
            this.translate.transform(
              'consolidation.sendDeleteRequestunsuccess'
            ) +
              ' ' +
            errorSplit.join(" "),
            true,
            ''
          );
        }
      });
      this.loadingProjects = false;
      this.backgroundLoadingProjects = false;
      this.onSelectProject(this.projectId);
    });
  }

  private downloadFile(file) {
    const url = `download/consolidate/${file.consolidateddocfileid}/`;
    const filename = file.consolidateddocname;
    this.fileDownloadService.downloadExternalFile(url, file, filename);
  }

  /**
   * Download documents ffuntionality
   * @param row row data
   * @param type type of file to download
   */
  public downloadDocuments(row, type) {
    if (this.requiredinformation === 'pams_pdf_report') {
      row.files.map(file => {
        this.downloadFile(file);
      });
    } else {
      const fileToDownload = row.files.find(file => file.extension === type);
      this.downloadFile(fileToDownload);
    }
  }

  private setDownloadColumn(arr: any[], file: any) {
    arr.push({
      click: this.downloadDocuments.bind(this),
      class: 'fa fa-file-' + this.getIconName(file.extension) + '-o',
      title: 'consolidation.downloadFile.' + file.extension,
      type: file.extension
    });
  }

  /**
   * Set types of documents to download for each row
   * @param row
   */
  public getDownloadsTypes(row: any) {
    const downloadTypes = [];
    const files = row.files;
    if (this.requiredinformation === 'pams_pdf_report' && files.length) {
      this.setDownloadColumn(downloadTypes, files[0]);
    } else {
      files.map(file => {
        this.setDownloadColumn(downloadTypes, file);
      });
    }

    return downloadTypes;
  }

  /**
   * Return Font Awesome icon name
   * @param extension
   */
  public getIconName(extension) {
    switch (extension) {
      case 'docx':
        return 'word';
      case 'xlsx':
        return 'excel';
      case 'pptx':
        return 'powerpoint';
      default:
        return extension;
    }
  }

  /**
   * stored items selected in table to not lose it when paginate
   * @param index index of element
   * @param data all data array of current page
   */
  public changeSelectedItemsConsolidated(index, data) {
    const index_page = data.data.indexOf(index);
    if (!this.responseTableDataConsolidated[data.page])
      this.responseTableDataConsolidated[data.page] = {};
    if (this.responseTableDataConsolidated[data.page][index_page]) {
      delete this.responseTableDataConsolidated[data.page][index_page];
    } else {
      this.responseTableDataConsolidated[data.page][index_page] =
        data.data[index_page];
    }
    const items = [];
    for (const number_page of Object.keys(this.responseTableDataConsolidated)) {
      for (const number_item of Object.keys(
        this.responseTableDataConsolidated[number_page]
      )) {
        items.push(
          this.responseTableDataConsolidated[number_page][number_item]
        );
      }
    }
    return items;
  }

  /**
   * Check if is selected when paginate to tick checkbox or not
   * @param index index of element
   * @param data all data array of current page
   */
  public isSelectedConsolidated(index, data) {
    const index_page = data.data.indexOf(index);
    let isSelected = false;
    if (this.responseTableDataConsolidated[data.page]) {
      if (this.responseTableDataConsolidated[data.page][index_page]) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  /////////////////////  # PROJECT /////////////////////////////

  /**
   * Get projects from storage and database
   */
  public getProjectList() {
    const storedProjects = this.paginationService.getPage('projects');
    this.loadingProjects = true;
    if (
      storedProjects &&
      storedProjects.data.length &&
      storedProjects.page === 1 &&
      storedProjects.order_by === 'projectid' &&
      storedProjects.desc === 0
    ) {
      // If projects are loaded, show them and hide spinner
      this.backgroundLoadingProjects = true;
    }

    // Always request projects from db
    this.getProjectsFromDB();
  }

  /**
   * Get projects from database
   */
  public getProjectsFromDB(resetCall = false) {
    const params = {
      consolidatedtypeid: this.consolidation.consolidatedtypeid,
      ...this.paginationProjects
    };
    this.apiService.get('projects/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: ProjectModel[];
      }) => {
        this.projects = data.data;
        this.rowsProjects = data;
        this.paginationService.setPage('projects', data);
        this.paginationProjects = this.paginationService.setPagination(
          'projects',
          {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.paginationProjects.query
          }
        );
        this.loadingProjects = false;
        this.backgroundLoadingProjects = false;
        if (resetCall) {
          this.onSelectProject(this.projectId);
          this.getDocumentsToConsolidate();
        }
      },
      () => {
        this.loadingProjects = false;
        this.backgroundLoadingProjects = false;
      }
    );
  }

  /**
   * Return loaded columnsProjectList with the information of each column of the table we want to show
   */
  public getColumnsProject() {
    this.columnsProjects = [
      {
        header: 'projectList.projectName',
        name: 'projectname',
        orderBy: 'projectname'
      },
      {
        header: 'projectList.date',
        name: 'createddate',
        type: 'date',
        format: 'll',
        orderBy: 'createddate'
      },
      {
        header: 'projectList.analyses',
        name: 'analyses'
      },
      {
        header: 'projectList.documents',
        name: 'documents'
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTableProject() {
    this.settingTableProjects = {
      dataId: 'projectid',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getProjectsFromDB.bind(this),
      hasOnClickRow: 'true',
      actionOnClickRow: this.goToProject.bind(this),
      responsiveTitle: {
        label: 'projectname',
        value: 'createddate',
        formatDate: 'll'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Go to the project with the project information pass by params
   */
  public goToProject(project) {
    // this.link.goToProject(project);
    this.onSelectProject(project.projectid);
    this.setSelectedProject(project);
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChangeProject(bool) {
    this.loadingProjects = bool;
  }

  public getSelectedProject() {
    return this.selectedProject;
  }

  public setSelectedProject(project) {
    return (this.selectedProject = project);
  }

  public toggleShowProjectTable() {
    this.showProjectTable = !this.showProjectTable;
  }

  public getDatamodelNames() {
    let datamodelNames = '';
    this.consolidation.datamodels.map(d => {
      datamodelNames += ' ' + d.datamodeldisplayname + ' ,';
    });
    return datamodelNames.slice(0, -1);
  }

  public openModalRelatedDocumentsInfo(documentConsolidated) {
    this.modalData = documentConsolidated.documents;
    // based on goToProject
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
      windowClass: 'modal--large'
    };
    const options: any = {};
    options.tableSettings = {
      dataType: 'modal',
      loading: false,
      backgroundLoading: false,
      settings: this.getSettingsModal(),
      data: this.modalData,
      columns: this.getColumnsModal()
    };
    options.title = this.translate.transform(
      'components.modal.consolidate.documentsOfConsolidation'
    );
    const modalWindowRef = this.modalService.open(
      ResponsiveTableModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
  }

  getSettingsModal() {
    return {
      dataId: 'documentid',
      responsiveTitle: {
        label: 'documentdisplayname'
      },
      paginationClass: 'relative-pag'
    };
  }

  getColumnsModal() {
    return [
      {
        header: 'projectAnalysis.documentName',
        name: 'documentdisplayname',
        orderBy: 'documentdisplayname',
        class: 'ellipsis-text big-size-column',
        title: 'documentdisplayname'
      },
      {
        header: 'validation.datamodel',
        name: 'datamodel',
        second: 'datamodeldisplayname',
        orderBy: 'datamodelname',
        class: 'ellipsis-text medium-size-column',
        title: 'datamodel'
      },
      {
        header: 'projectList.createdBy',
        name: 'createdbyuser',
        second: 'username',
        orderBy: 'createdbyname',
        class: 'ellipsis-text medium-size-column',
        title: 'createdbyuser'
      },
      {
        header: 'projectDocument.status',
        name: 'status_data',
        type: 'one-span-status',
        orderBy: 'statusid',
        title: 'status_data',
        second: 'value'
      },
      {
        header: 'consolidation.warnings',
        type: 'warning-message',
        tooltipPosition: 'top',
        checkCondition: (rowData: any): boolean => rowData.isexcluded,
        message: (rowData: any): string =>
          this.translate.transform('consolidation.docWarning'),
        isError: () => false
      },
      {
        type: 'custom',
        header: 'validation.validationToReview.aging',
        orderBy: 'createddate',
        class: 'medium-size-column',
        title: 'custom',
        namefunc: rowData => {
          return moment(rowData.createddate).fromNow();
        }
      }
    ];
  }
}
