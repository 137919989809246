import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-original-text-filter',
  templateUrl: './original-text-filter.component.html',
  styleUrls: ['./original-text-filter.component.scss']
})
export class OriginalTextFilterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
