import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import * as moment from 'moment';
import { AlertService } from '../services/alert.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpirationDateService implements OnDestroy{

  private _expirationNumber: string;
  private _expirationText: string;
  public get expirationInfo(): any {
    return {
      number: this._expirationNumber,
      text: this._expirationText
    }
  }

  private previousExpirationAlert: boolean = false;
  private previousExpirationAlertSeconds: boolean = false;
  private interval: NodeJS.Timeout;
  private subscriptions: Subscription[] = [];

  private tokenExpiredSubject = new Subject<any>();
  public tokenExpired = this.tokenExpiredSubject.asObservable();
  private tokenAboutToExpireSubject = new Subject();
  public tokenAboutToExpire = this.tokenAboutToExpireSubject.asObservable();

  constructor(
    private authService: AuthenticationService,
    public alertService: AlertService,
    private translate: TranslatePipe,
  ) {

    if (this.authService.getLoggedInUser()) {
      this.createInterval();
    } else {
      this.subscriptions.push(
        this.authService.userLoggedIn
        .subscribe(this.createInterval.bind(this))
      );
    }
    this.subscriptions.push(
      this.authService.tokenManuallyRenewed
      .subscribe(this.onTokenManuallyRenewed.bind(this))
    )
    }
  /**
   * Updates the remaining time for expiration every second
   */
  private updateExpiration() {
    const initialDate = moment(this.authService.getTokenExpirationDate());
    const nowDate = moment();

    const diff = initialDate.diff(nowDate, 'minutes');
    if (diff > 0) {
      this.setExpirationInfo('min', diff + '');
      if (!this.previousExpirationAlert && diff === 5) {
        this.tokenAboutToExpireSubject.next();
        this.onTokenAboutToExpire();
        this.publishAlert(diff, false, "minutes");
        this.previousExpirationAlert = true;
      }
    } else {
      const diff = initialDate.diff(nowDate, 'seconds');
      if (diff > 0) {
        this.setExpirationInfo('s', diff + '');
        if (!this.previousExpirationAlertSeconds && diff === 59) {
          this.previousExpirationAlertSeconds = true;
          this.publishAlert(diff, false);
        } else if (
          !this.previousExpirationAlertSeconds &&
          (diff === 30 || diff === 15)
        ) {
          this.previousExpirationAlertSeconds = true;
          this.publishAlert(diff, true);
        } else {
          this.previousExpirationAlertSeconds = false;
        }
      } else {
        if(this.interval){
          clearInterval(this.interval);
          this.interval = null;
        }
        this.tokenExpiredSubject.next();
        /** Removing this logic when session expires automatically refreshing the token expiration
         * this.onTokenExpired(); */

        /** If session expires redirecting user to log out screen */
        this.authService.logout();
      }
    }

    return {
      text: this._expirationText,
      number: this._expirationNumber
    };
  }


  /**
   * Creates the interval for the update of the expiration info.
   */
   private createInterval(): void {
    if(!this.interval){
      this.interval = setInterval(() => this.updateExpiration(), 1000);
    }
  }

  /**
   * Creates an alert using the alert system
   * @param diff Remaining time until expiration date
   * @param isError Set to true if a red alert wants to be displayer
   * @param message Message to show on the alert
   */
  private publishAlert(diff: number, isError: boolean, unit = 'seconds', message: string = 'common.aboutToExpire') {
    if (isError) {
      this.alertService.error(
        this.translate.transform(message),
        false,
        diff + ' ' + unit
      );
    }
    else {
      this.alertService.warn(
        this.translate.transform(message),
        false,
        diff + ' ' + unit
      );
    }
  }

    /**
   * Sets the expiration information
   * @param expText Expiration unit (eg: min, s)
   * @param expNumber Remaining time
   */
  private setExpirationInfo(expText: string, expNumber: string) {
    this._expirationText = expText;
    this._expirationNumber = expNumber;
  }

    /**
   * Opens the modal to renew the token.
   */
  private onTokenAboutToExpire(): void {
    this.authService.renewToken({
      useModal: true,
      isAboutToExpire: true
    })
  }

    /**
   * Handles the token expiration.
   */
  private onTokenExpired(): void {
    this.authService.onTokenExpired();
  }

  /**
   * Clears the previous alerts when the token
   * is renewed manually by the user before its
   * expiration
   */
   private onTokenManuallyRenewed(): void {
    this.previousExpirationAlert = false;
    this.previousExpirationAlertSeconds = false;
  }
  ngOnDestroy(): void {
    for(const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

}
