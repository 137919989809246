import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { LinkService } from './link.service';
import { AuthenticationService } from '../security/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EventTrackerService {
  private _currentPage: string;

  constructor(
    private apiService: ApiService,
    private route: Router,
    private link: LinkService,
    private authService: AuthenticationService
  ) {}

  public sendEventTracked(url, activityId: number, destinyPage?: string) {
    if (this.hasModule()) {
      const params = this.setParams(url, activityId, destinyPage);
      this.apiService.post('logs', params, '').subscribe(
        data => {
          this.setCurrentPage(url);
        },
        error => {
          this.setCurrentPage(url);
        }
      );
    }
  }

  private hasModule() {
    return this.authService.userCanViewModule(
      this.authService.getLoggedInUser(),
      'TimeTrackingCoreFunctionality'
    );
  }

  private setParams(url, activityId, destiny) {
    let current = this.getCurrentPage();
    const destinyPage = url.split('/').pop();
    if (current) {
      const arrURL = current.split('/');
      arrURL.splice(0, 2);
      current = arrURL.join('/');
    }
    const params = {
      activityid: activityId,
      url: url,
      pagename: current,
      destinypage: destiny || destinyPage
    };
    const extraParams = this.setExtraParams(url);
    return { ...params, ...extraParams };
  }

  public getCurrentPage() {
    return this._currentPage;
  }
  public setCurrentPage(value) {
    this._currentPage = value;
  }

  private setEntityId(segments, entity, length) {
    const pathId = segments.findIndex(p => p.path === entity);
    if (pathId != -1 && segments.length > length) {
      return this.link.getIdFromSlug(segments[pathId + 1].path);
    }
    return null;
  }

  private setExtraParams(url) {
    let documentid, analysisid, projectid;
    const urlSegments = this.route.parseUrl(url).root.children.primary.segments;
    projectid = this.setEntityId(urlSegments, 'projects', 2);
    if (projectid) {
      analysisid = this.setEntityId(urlSegments, 'analysis', 4);
      if (analysisid) {
        const documentPath = urlSegments.filter(p => p.path === 'documents');
        if (documentPath.length === 1) {
          documentid = this.setEntityId(urlSegments, 'documents', 6);
        } else if (documentPath.length === 2) {
          const documentPathId = urlSegments.indexOf(documentPath[1]);
          documentid = this.link.getIdFromSlug(
            urlSegments[documentPathId + 1].path
          );
        }
      }
    }

    return {
      projectid: projectid,
      analysisid: analysisid,
      documentid: documentid
    };
  }
}
