import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-add-group-form',
  templateUrl: './add-group-form.component.html',
  styleUrls: ['./add-group-form.component.scss']
})
export class AddGroupFormComponent implements OnInit {
  public addQueryGroupForm: FormGroup;

  @Output() createGroupObjectEvent = new EventEmitter();
  @Output() cancelGroupModalEvent = new EventEmitter();
  @Output() editTreeNode = new EventEmitter();
  @Output() deleteTreeNode = new EventEmitter();
  @Input() nodes;
  @Input() selectedGroup: any;
  @Input() actionOnNode: any
  public isDeleteBtnClicked: boolean = false;

  constructor(
    public qsFormBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    this.addQueryGroupForm = this.qsFormBuilder.group({
      groupName: [
        this.selectedGroup?.name,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(250),
          this.checkDupilcateGroupName.bind(this)
        ]
      ]
    });
  }

  // Duplicate Group name check in existing Nodes list
  public findDuplicateGroup = (nodes, groupName, isfound) => {
    if (!isfound) {
      const nodeNameLowerCase = nodes.name.toLowerCase();
      const gpNameLowerCase = groupName.toLowerCase();
      if (nodes.isGroup === true && nodeNameLowerCase == gpNameLowerCase && this.selectedGroup.id !== nodes.id)
        isfound = true
      else if (nodes.isGroup === true && nodes.children?.length > 0) {
        nodes.children.forEach((child) => {
          isfound = this.findDuplicateGroup(child, gpNameLowerCase, isfound);
        })
      }
    }
    return isfound
  }

  // Remove the empty space in start and end of user input to through error valication
  trimSpaces(event) {
    if(this.addQueryGroupForm.get(event.target.id).value !== event.target.value.trim()){
      this.addQueryGroupForm.get(event.target.id).setValue(event.target.value.trim())
    }
  }

  //Stop Event Propagation
  onInputEnterPressed(event){
    event.preventDefault();
  }
  
  // Custom validation plus duplicate check
  public checkDupilcateGroupName(control: FormControl) {
    const groupName = control.value
    if (groupName?.length >= 3 && groupName?.length <= 250 && this.nodes?.length > 0 && control.dirty) {
      let isDuplicate = null
      this.nodes.map(node => {
        if (isDuplicate !== true)
          isDuplicate = this.findDuplicateGroup(node, groupName, isDuplicate)
        if (isDuplicate === true) {
          isDuplicate = true;
        }
      })
      if (isDuplicate === true)
        return {
          "duplicateGroup": isDuplicate
        }
      else
        return null;
    }
    return null;
  }

  // Common form field validation 
  addQueryAndGroupFormError = (controlName: string, errorName: string) => {
    return this.addQueryGroupForm.controls[controlName].hasError(errorName);
  }

  // On Click Add Group Emitting data to parent component 
  public addGroupToQuery() {
    const createGroupObject = {
      "isGroup": true,
      "id": Math.ceil(Math.random() * 1000000),
      "name": this.addQueryGroupForm.controls['groupName'].value,
      "text": null,
      "type": null,
      "parentId": this.actionOnNode?.isGroup ? this.actionOnNode.id : null,
      "displayOrder": this.nodes.length + 1,
      "hasChildren": true,
      "children": []
    }

    this.createGroupObjectEvent.emit({ value: createGroupObject });
  }

  public editGroup() {
    const groupObj = {
      ...this.selectedGroup,
      "name": this.addQueryGroupForm.controls['groupName'].value
    }

    this.editTreeNode.emit(groupObj);
  }

  // Close/Cancel Modal
  closeModal() {
    this.cancelGroupModalEvent.emit({ value: false })
  }

  public deleteGroup(flag: boolean) {
    this.isDeleteBtnClicked = flag;
  }

  public deleteSkipped() {
    this.deleteGroup(false);
    this.closeModal();
  }

  public deleteConfirmed() {
    this.deleteGroup(false);
    this.deleteTreeNode.emit();
  }

}

