import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../common-components/validation.service';

@Component({
  selector: 'app-unique-doc',
  templateUrl: './unique-doc.component.html',
  styleUrls: ['./unique-doc.component.scss']
})
export class UniqueDocComponent implements OnInit {
  @Input('formTest') formTest;
  public isDeletingUniqueFields: boolean = false;
  public isDeletingLatestFields: boolean = false;

  constructor(public validationService: ValidationService) {}

  ngOnInit() {
    if (this.formTest.controls.uniquedoc.value) {
      this.isDeletingUniqueFields = true;
    }
    if (this.formTest.controls.latestdoc.value) {
      this.isDeletingLatestFields = true;
    }
    this.onDeleteUniqueFields();
    this.onDeleteLatestFields();
  }

  /**
   * Set uniquedoc form control as required if checkbox is true,
   * otherwise clean it
   */
  onDeleteUniqueFields() {
    if (this.isDeletingUniqueFields) {
      this.formTest.addControl(
        'uniquedoc',
        new FormControl('', Validators.required)
      );
      this.validationService.touchControl(this.formTest, 'uniquedoc');
    } else if (this.formTest.get('uniquedoc')) {
      this.formTest.removeControl('uniquedoc');
    }
  }

  /**
   * Set latestdoc form control as required if checkbox is true,
   * otherwise clean it
   */
  onDeleteLatestFields() {
    if (this.isDeletingLatestFields) {
      this.formTest.addControl(
        'latestdoc',
        new FormControl('', Validators.required)
      );
      this.validationService.touchControl(this.formTest, 'latestdoc');
    } else if (this.formTest.get('latestdoc')) {
      this.formTest.removeControl('latestdoc');
    }
  }
}
