import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ProjectModel } from '../../models/project-mstr.model';
import { AnalysisModel } from '../../models/analysis.model';
import { KPIModel } from 'src/app/models/kpi.model';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { LinkService } from 'src/app/services/link.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateAnalysisPages } from '../../components/modals/create-analysis/create-analysis.pages';
import { UpdateDocsComponent } from '../../components/modals/update-docs/update-docs.component';
import { RenameModalComponent } from '../../components/modals/rename-modal/rename-modal.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { EventTrackerService } from 'src/app/services/event-tracker.service';


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.pages.html',
  styleUrls: ['./project-details.pages.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  public user: any;
  public projectInfo: ProjectModel;
  private projectId: number;
  public kpiInfo: KPIModel;
  private dataSub: Subscription;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };
  public currentTab: string;
  private onItemAdded = new Subject<AnalysisModel>();
  public hasEnrichmentModule: boolean;

  // Const
  public _userPermissions: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public apiService: ApiService,
    public global: GlobalService,
    public link: LinkService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private trackerService: EventTrackerService,

  ) {
    this.currentTab = 'analysis';
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.hasEnrichmentModule = this.authService.userCanViewModule(
      this.user,
      'EnrichmentCoreFunctionality'
    );
  }

  ngOnInit() {
    this.dataSub = this.route.params.subscribe((params: Params) => {
      this.projectId = this.link.getIdFromSlug(params.projectidname);
      this.global.getProject(this.projectId).then((project: ProjectModel) => {
        this.link.checkProjectSlug(params.projectidname, project);
        this.global.setProject(project);
        this.projectInfo = project;
      });
      // this.getKPIInfo();
    });
  }

  /**
   * Get KPI info from storage or database
   */
  private getKPIInfo() {
    this.global.getKPIs('project', this.projectId).then((data: KPIModel) => {
      this.global.setKPIs('project', this.projectId, data);
      this.kpiInfo = new KPIModel(data);
    });
  }

  public onCreateAnalysis() {
    const options = {
      level: 'project',
      projectid: this.projectInfo.projectid,
      projectname: this.projectInfo.projectname,
      analysisid: '',
      analysisname: ''
    };
    const modalWindowRef = this.modalService.open(
      CreateAnalysisPages,
      this.modalOptions
    );
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValue => {
      if (formValue && formValue.reason == undefined) {
        const analysisParams = {
          projectid: this.projectInfo.projectid,
          analysisname: formValue.analysisName
        };
        this.apiService
          .post(
            'analysis',
            analysisParams,
            'createAnalysis.analysisCreated',
            false
          )
          .subscribe((analysis: AnalysisModel) => {
            this.onItemAdded.next();
            this.paginationService.changePagination('analysis', 'page', 1);
            if (formValue.files?.length > 0) {
              this.getDocumentCreateRequests(formValue, analysis);
            }
          });
      }
      if (
        formValue &&
        formValue.reason != undefined &&
        formValue.process == true
      ) {
        this.onItemAdded.next();
        this.paginationService.changePagination('analysis', 'page', 1);
      }
    });
  }

  private getDocumentCreateRequests(formValue: any, analysis: AnalysisModel) {
    let promises = [];
    for (const file of formValue.files) {
      const formData = this.getForm(
        this.projectInfo.projectid.toString(),
        analysis.analysisid,
        formValue,
        file
      );
      promises.push(this.apiService.post('documents', formData, '', false));
    }
    this.apiService.forkJoin(
      promises,
      this.translate.transform('projectDetails.successfulUpload'),
      this.translate.transform('projectDetails.unsuccessfulUpload')
    );
  }

  public onUpdateDocs() {
    const options = {
      level: 'project',
      projectId: this.projectInfo.projectid,
      projectName: this.projectInfo.projectname
    };
    const modalWindowRef = this.modalService.open(
      UpdateDocsComponent,
      this.modalOptions
    );
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValues => { });
  }

  getForm(projectId, analysisId, formValues, file) {
    let formData = new FormData();
    formData.append('projectid', projectId);
    formData.append('analysisid', analysisId);
    formData.append('analysisname', formValues.analysisName);
    formData.append('datamodelid', formValues.datamodelId);
    formData.append('language', formValues.language);
    formData.append('files', file, file.name);
    return formData;
  }

  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }

  /**
   * Destroy event handler
   */
  ngOnDestroy() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  /**
  * 
  *Rename project handler
 */
  public onRenameProject() {
    // Change the name of project
    const headTag = document.getElementById('editable__project__title');
    const iconBtn = document.getElementById('editable__project__icon');
    const popModal = this.modalService.open(
      RenameModalComponent,
      this.modalOptions
    );
    const data = {
      projectid: this.projectId,
      title: "Project",
      endpoint: `projects/${this.projectId}`,
      iconBtn: iconBtn,
      headTag: headTag,
      currentName: headTag.innerText,
      previousName: this.projectInfo.projectname
    }
    popModal.componentInstance.data = data;
  }
}
