import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../../../common-components/validation.service';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-business-rule-type',
  templateUrl: './business-rule-type.component.html',
  styleUrls: ['./business-rule-type.component.scss']
})
export class BusinessRuleTypeComponent implements OnInit, OnDestroy {
  @Input('control') control: FormGroup;
  @Input('index') index: number;
  public ter: boolean = false;
  public routeSub: Subscription;
  public hasEnrichments: boolean;
  private datamodelId: number;
  public validationEnrichments: any[];
  public datamodel: any;
  private disableWhenTer: any[] = ['gt', 'gte', 'lt', 'lte', 'contains'];

  constructor(
    public validationService: ValidationService,
    public globalService: GlobalService,
    public apiService: ApiService,
    public authService: AuthenticationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.datamodelHasEnrichments();
    if (this.control.get('rounding')) {
      this.control.get('rounding').valueChanges.subscribe(value => {
        this.addOrRemovePrecisionControl(value);
      });
    }
    this.control.get('toleranceerrorrate').valueChanges.subscribe(ter => {
      if (ter) {
        for (const ctrl in this.control.get('operations')['controls']) {
          if (
            this.control.get('operations').get(`${ctrl}`).get('operator') &&
            this.disableWhenTer.includes(
              this.control.get('operations').get(`${ctrl}`).get('operator')
                .value
            )
          ) {
            this.control
              .get('operations')
              .get(`${ctrl}`)
              .get('operator')
              .setValue('');
            this.control
              .get('operations')
              .get(`${ctrl}`)
              .get('operator')
              .setErrors(Validators.required);
          }
        }
      }
    });
    if (this.control.get('operationScope').value === 'enrichment') {
      this.removeRequiredValidators();
    }
    this.control.get('operationScope').valueChanges.subscribe(scope => {
      scope === 'enrichment'
        ? this.removeRequiredValidators()
        : this.addRequiredValidators();
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  /**
   * This function evaluates if the datamodel has enrichments
   */
  public datamodelHasEnrichments() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      this.datamodelId = +params.get('datamodelid');
    });
    this.datamodel = this.globalService.getDataModelById(this.datamodelId);

    this.hasEnrichments =
      this.authService.userCanViewModule(
        this.authService.getLoggedInUser(),
        'EnrichmentCoreFunctionality'
      ) &&
      this.datamodel.enrichmenttypes.length > 0 &&
      this.globalService
        .getEnrichmentTypes()
        .filter(enrichment => enrichment.enrichment_category > 0).length > 0;
    if (this.hasEnrichments) {
      this.validationEnrichments = this.globalService
        .getEnrichmentTypes()
        .filter(enrichment => enrichment.enrichment_category > 0);
    }
  }

  public removeRequiredValidators() {
    this.control.addControl(
      'enrichments_type',
      new FormControl('', Validators.required)
    );
    this.control.get('rounding').clearValidators();
    this.control.get('rounding').updateValueAndValidity();
    this.control.get('operations').get('0').get('type').clearValidators();
    this.control
      .get('operations')
      .get('0')
      .get('type')
      .updateValueAndValidity();
    this.control.get('operations').get('1').get('operator').clearValidators();
    this.control
      .get('operations')
      .get('1')
      .get('operator')
      .updateValueAndValidity();
    this.control.get('operations').get('1').get('operator').setValue('end');
  }

  public addRequiredValidators() {
    this.control.removeControl('enrichments_type');
  }

  /**
   * Get operations FormGroups keys (ex: ['0', '2', '4', ...])
   */
  public getFormGroupKeys() {
    return Object.keys(this.control.get('operations')['controls']).filter(
      key => !(+key % 2)
    );
  }

  /**
   * Add or remove precision form control depending on rounding type
   * @param rounding roundingt type
   */
  public addOrRemovePrecisionControl(rounding: string) {
    if (rounding === 'regular' && !this.control.get('precision')) {
      this.control.addControl(
        'precision',
        new FormControl('', Validators.required)
      );
      this.validationService.touchControl(this.control, 'precision');
    }
    if (rounding !== 'regular' && this.control.get('precision')) {
      this.control.removeControl('precision');
    }
  }

  public changeToleranceErrorRateCheckbox(flag: boolean) {
    if (!flag) {
      this.control.get('toleranceerrorratevalue').setValue(0);
    }
    this.ter = flag;
  }
}
