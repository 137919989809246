import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ibmGetTokenExpirationDate } from '../utils/ibm.config';
import { CustomGuard } from './guard.base';
import AppID from 'ibmcloud-appid-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IbmCustomGuard extends CustomGuard implements CanActivate {

  private appid = new AppID();
  private accesstokenibm: any;

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    this.setRouteInformation(route, state);
    this.createResult(async () => {
      try {
        await this.appid.init({
          clientId: environment.azure_application_id,
          discoveryEndpoint: `https://us-south.appid.cloud.ibm.com/oauth/v4/${environment.azure_tenant_id}/.well-known/openid-configuration`
        });
      //this.authenticationService.setToken('ibm');
    } catch (e) {
      this.onAccessDenied(this);
    }
    const tokens = await this.appid.signin();
    //console.log("ibm tokens", tokens)
    this.accesstokenibm = tokens['accessTokenPayload']
    //console.log("ibm token", this.accesstokenibm)
    this.authenticationService.setToken(tokens['idToken']); 
    this.loginAPI(        
      this.onAccessGranted.bind(this),
      this.onAccessDenied.bind(this));
    })
      return this.result$;
    }
  
  public setTokenExpirationDate(user: any): void {
    const expirationDate = ibmGetTokenExpirationDate()
    this.authenticationService.setTokenExpirationDate(
      expirationDate
    );
  }

}
