import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { siteminderGetTokenExpirationDate } from '../utils/siteminder.config';
import { CustomGuard } from './guard.base';

@Injectable({
  providedIn: 'root'
})
export class SiteminderCustomGuard extends CustomGuard implements CanActivate {

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    this.setRouteInformation(route, state);
    this.createResult(() => {
      this.authenticationService.setToken('siteminder');
      this.loginAPI(
        this.onAccessGranted.bind(this),
        this.onAccessDenied.bind(this)
      )
    });
    return this.result$;
  }

  public setTokenExpirationDate(user: any): void {
    const expirationDate = siteminderGetTokenExpirationDate()
    this.authenticationService.setTokenExpirationDate(
      expirationDate
    );
  }

}
