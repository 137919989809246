import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Router } from '@angular/router';
import { LinkService } from 'src/app/services/link.service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-project-users-view',
  templateUrl: './project-users-view.component.html',
  styleUrls: ['./project-users-view.component.scss']
})
export class ProjectUsersViewComponent implements OnInit {
  public params: any = {};
  public paginationType: string = 'assignedprojectusers';
  public isAssigned: string;
  @Input() projectId: number | string;

  // Const
  public _userPermissions: any;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    public link: LinkService,
    private global: GlobalService
  ) {
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  ngOnInit() {
    this.params = {
      projectId: this.projectId
    };
  }
}
