import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { select, selectAll} from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { drag } from 'd3-drag';
import {
  sankey,
  sankeyCenter,
  sankeyJustify,
  sankeyLeft,
  sankeyRight,
  sankeyLinkHorizontal
} from 'd3-sankey';
import {
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
  schemeSet3
} from 'd3-scale-chromatic';
const d3 = {
  select,
  selectAll,
  scaleOrdinal,
  drag,
  sankey,
  sankeyCenter,
  sankeyJustify,
  sankeyLeft,
  sankeyRight,
  sankeyLinkHorizontal,
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
  schemeSet3
};
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

/**
 * Sankey chart Component
 */
@Component({
  selector: 'app-d3sankey',
  templateUrl: './sankey.component.html',
  styleUrls: ['./sankey.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3SankeyComponent implements OnChanges {
  @Input() private data: any;
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  @Input() public translateNode: any = undefined; // Translate function
  private cfg: any = {}; // Default configuration object
  private selection;
  private svg;
  private sankey;
  private colorScale;
  private loaded: boolean = false;

  constructor(
    elementRef: ElementRef,
  ) {
    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 300, // Default height
      margin: { top: 0, right: 30, bottom: 0, left: 30 },
      colorScheme: 'schemePaired', // https://github.com/d3/d3-scale-chromatic
      colorKeys: {},
      align: 'center', // Sankey alignement
      nodeWidth: 16, // Horizontal node size
      nodePadding: 4, // Vertical padding between nodes
      nodeTextLength: 20, // Node text max length
      key: 'name', // Node field to show in tooltip and compute color
      labelMinNodeHeight: 6, // Min height to show label on node
      labelSize: 10 // Label font size
    };
  }

  ngOnChanges() {
    if (this.data && this.data['nodes'] && this.data['links']) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    if (this.cfg.colorScheme instanceof Array === true) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }

    const _sankey = d3
      .sankey()
      .nodeAlign(
        d3[`sankey${this.cfg.align[0].toUpperCase()}${this.cfg.align.slice(1)}`]
      )
      .nodeWidth(this.cfg.nodeWidth)
      .nodePadding(this.cfg.nodePadding)
      .size([this.cfg.width, this.cfg.height]);

    this.sankey = ({ nodes, links }) =>
      _sankey({
        nodes: nodes.map(d => Object.assign({}, d)),
        links: links.map(d => Object.assign({}, d))
      });
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    let self = this;
    const { nodes, links } = this.sankey(this.data);

    // Remove previus chart if exists
    if (this.loaded) this.selection.selectAll('svg').remove();

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--sankey')
      .attr(
        'viewBox',
        '0 0 ' +
          (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
          ' ' +
          (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        'translate(' + this.cfg.margin.left + ',' + this.cfg.margin.top + ')'
      );

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--sankey');

    // Links
    let link = g
      .append('g')
      .selectAll('.link')
      .data(links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', d3.sankeyLinkHorizontal())
      .style('stroke-width', d => d.width);

    // Nodes
    let node = g
      .append('g')
      .selectAll('.node')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.x0},${d.y0})`)
      .on('mouseover', (d, i) => {
        tooltip
          .html(() => {
            return `
            <div>${this.translateNodeFunc(i)}: ${i.value}</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', () => {
        tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      });
    /*
      .call(d3.drag()
        .subject(d => d)
        .on("start", function() { this.parentNode.appendChild(this); })
        .on("drag", function(d:any){

          d.y0 = Math.max(
            0,
            Math.min(
              self.cfg.height - (d.y0 - d.y1),
              window.event['offsetY']-((d.y0 - d.y1)/2)-self.cfg.margin.top
            )
          )
          d.x0 = window.event['offsetX'] - self.cfg.margin.left - (self.cfg.nodeWidth);
          d3.select(this)
            .attr("transform", `translate(${d.x},${d.y})`);
            self.sankey.relayout();
            link.attr("d", self.sankey.link() );
        })
      );*/

    // Node's rectagles
    node
      .append('rect')
      .attr('height', d => d.y1 - d.y0)
      .attr('width', d => d.x1 - d.x0)
      .attr('fill', d => this.nodeColor(d));

    // Node's titles
    node
      .append('text')
      .attr('class', 'chart__label chart__label--sankey')
      .filter(d => d.y1 - d.y0 > this.cfg.labelMinNodeHeight)
      .attr('x', -6)
      .attr('y', d => (d.y1 - d.y0) / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'end')
      .attr('transform', null)
      .style('font-size', this.cfg.labelSize + 'px')
      .text(d => {
        let text = this.translateNodeFunc(d);
        if (text.length > this.cfg.nodeTextLength) {
          text = `${text.substring(0, this.cfg.nodeTextLength)}...`;
        }
        return text;
      })
      .filter(d => d.x0 < this.cfg.width / 2)
      .attr('x', d => 6 + d.x1 - d.x0)
      .attr('text-anchor', 'start');

    this.loaded = true;
  }

  /**
   * Compute node color
   */
  private nodeColor(d) {
    if (
      this.cfg.colorKeys &&
      this.cfg.colorKeys.hasOwnProperty(d[this.cfg.key])
    ) {
      return this.cfg.colorKeys[d[this.cfg.key]];
    } else {
      return this.colorScale(d[this.cfg.key]);
    }
  }

  /**
   * Translate nodes function
   */
  public translateNodeFunc(d) {
    // If translateNode is passed, use it
    if (this.translateNode) return this.translateNode(d);
    // Return default
    return d[this.cfg.key];
  }
}
