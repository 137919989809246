import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NewCommentComponent } from '../modals/new-comment/new-comment.component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { Subscription } from 'rxjs';
import { DocumentEvidencesService } from './document-evidences-table.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from '../../security/authentication.service';
import { Router } from '@angular/router';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-document-evidences-table',
  templateUrl: './document-evidences-table.component.html',
  styleUrls: ['./document-evidences-table.component.scss']
})
export class DocumentEvidencesTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() documentId: number;
  @Input() documentDetails: any;

  // Responsive table fixed variables
  public columns: any[];
  public loading: boolean = true;
  public backgroundLoading: boolean = false;
  public settingTable: any;
  public rows: any;
  private pagination: PaginationModel;
  public getEvidencesSub: Subscription;
  public file: File;
  private user: any = {};
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  private approval: any = {};
  public _userPermissions: any;
  public showValidations: boolean;

  constructor(
    private apiService: ApiService,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    private downloadService: FileDownloadService,
    private evidencesService: DocumentEvidencesService,
    private alertService: AlertService,
    private globalService: GlobalService,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private router: Router,
    public link: LinkService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.columns = this.getColumns();
    this.settingTable = this.getSettingTable();
    this.pagination = this.paginationService.getDefaultPagination('evidences');
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.getEvidencesSub = this.evidencesService
      .getEvidences()
      .subscribe(obj => {
        this.handleEvidencesChange(obj.evidences);
      });
  }

  ngOnInit() {
    this.getReviewersTypes();
  }

  ngOnChanges() {
    this.getDocumentEvidences();
  }

  ngOnDestroy() {
    // Remove subscriptions
    if (this.getEvidencesSub) this.getEvidencesSub.unsubscribe();
    if (this.subscriptionReviewers) this.subscriptionReviewers.unsubscribe();
  }

  /**
   * Get documents from storage or database
   */
  private getDocumentEvidences() {
    const documentPage = this.paginationService.getPage('evidences');
    const defaultPage =
      this.paginationService.getDefaultPagination('evidences');
    if (
      documentPage &&
      documentPage.data &&
      documentPage.data.length &&
      documentPage.page === 1 &&
      documentPage.order_by === defaultPage.order_by &&
      documentPage.desc === defaultPage.desc
    ) {
      // If document are loaded, show them and hide spinner
      this.rows = documentPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If document aren't loaded, show spinner
      this.loading = true;
    }
    // Always request document from db
    this.evidencesService.getEvidencesFromDB(this.pagination, this.documentId);
  }

  /**
   * Serializer to show properly the data on the table.
   * If there is no validationType, put 'All' string
   * @param data
   */
  public serializeData(data) {
    const elements = { ...data };
    elements.data = elements.data.map(row => ({
      ...row,
      validationtypename: row.validationtypeid
        ? row.validationtypeid.validationtypename
        : 'All'
    }));
    return elements;
  }

  /**
   * Actions chain to handle evidences list change
   * @param evidences evicendes data (with pagination)
   */
  private handleEvidencesChange(evidences) {
    this.rows = this.serializeData(evidences);
    this.paginationService.setPage('evidences', this.rows);
    this.pagination = this.paginationService.setPagination('evidences', {
      total_elements: this.rows.total_elements,
      page: this.rows.page,
      page_size: this.rows.page_size,
      num_pages: this.rows.num_pages,
      order_by: this.rows.order_by,
      desc: this.rows.desc,
      query: this.pagination.query
    });
    this.loading = false;
    this.backgroundLoading = false;
  }

  /**
   * Download file from remote storage
   * @param file CommentAttachment file object
   */
  public downloadFile(file) {
    const url = `download/evidences/${file.commentattachmentid}/`;
    const filename = file.attachmentdisplayname;
    this.downloadService.downloadExternalFile(url, file, filename);
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectDocument.documentName',
        name: 'attachmentdisplayname'
      },
      {
        header: 'validation.documentValidation.comment',
        name: 'comment',
        second: 'commentdescription'
      },
      {
        header: 'validation.validation',
        name: 'validationtypename'
      }
    ];
    if (
      this.authService.hasPermission([
        this._userPermissions.DOWNLOAD_BACKUP_DOCUMENTS
      ]) ||
      this.user.isadmin
    ) {
      let downloadcolumn: any[] = [
        {
          header: ' ',
          type: 'link-fa-icon',
          clickLink: this.downloadFile.bind(this),
          classIcon: this.isDisable.bind(this, 'fa fa-download '),
          title: this.isDisableHover.bind(this)
        }
      ];
      this.columns.splice(3, 0, ...downloadcolumn);
    }
    return this.columns;
  }

  public isDisable(cssClass, row) {
    return cssClass;
  }

  public isDisableHover(row) {
    return 'consolidation.downloadFile.download';
  }

  public getEvidencesFromDB() {
    this.evidencesService.getEvidencesFromDB();
  }

  /**
   * Check if user has edit permissions
   */
  public hasDownloadPermissions() {
    return (
      this.user.isadmin ||
      this.authService.hasPermission(
        this._userPermissions.DOWNLOAD_BACKUP_DOCUMENTS
      )
    );
  }

  /**
   * Return loaded SettingTable with the general settings for the component responsive table
   */
  public getSettingTable() {
    return {
      dataId: 'commentattachmentid',
      getDataFromDB: this.getEvidencesFromDB.bind(this),
      responsiveTitle: {
        label: 'attachmentdisplayname'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Handle loading data of the table from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Functions to construct the form data to pass
   * as param in the post request
   * @param form html form data
   */
  public getFormData(form) {
    const params = new FormData();
    params.append('commentdescription', form.newComment);
    params.append('documentid', this.documentId.toString());
    for (const file of form.files) {
      params.append('files', file, file.name);
    }
    return params;
  }

  /**
   * Open a modal to add a comment with an optional file attached
   */
  public onCreateComment() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'new',
      action: 'create',
      textComment: ''
    };
    const modalWindowRef = this.modalService.open(
      NewCommentComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(comment => {
      if (comment && comment.files && comment.files.length > 0) {
        const formComment = comment;
        this.loading = true;
        const params = this.getFormData(formComment);

        //The last param to the post is needed for the form-data encode params
        this.apiService.post('comments', params, '', false).subscribe(
          data => {
            this.evidencesService.getEvidencesFromDB();
            this.loading = false;
            this.alertService.success(
              this.translate.transform('projectDetails.successfulUpload'),
              true
            );
          },
          () => {
            this.alertService.error(
              this.translate.transform('projectDetails.unsuccessfulUpload'),
              true
            );
            this.loading = false;
          }
        );
      }
    });
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
        });
    } else {
      this.setApproval();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer and the validation modules exists.
   */
  public userCanInteract() {
    return !this.showValidations
      ? true
      : this.approvalIsAssignedToUser() && !this.approval.recommended;
  }
  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Gets the title of the Button depending on the user reviewer status.
   */
  public getButtonTitle(): string {
    return !this.userCanInteract()
      ? this.translate.transform('documentDetails.onlyAssigned')
      : '';
  }
}
