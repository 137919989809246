import { Component, OnInit, Input } from '@angular/core';
import { Reason } from 'src/app/models/close-reason.model';
import { GlobalService } from 'src/app/services/global.service';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  FormArrayName
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-document-datamodel',
  templateUrl: './update-document-datamodel.component.html',
  styleUrls: ['./update-document-datamodel.component.scss']
})
export class UpdateDatamodelModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public document: any;
  public datamodels: Array<any> = [];
  public form: FormGroup;

  public _documentStatus: any;

  constructor(
    public activeModal: NgbActiveModal,
    public global: GlobalService,
    private fb: FormBuilder
  ) {
    this._documentStatus = this.global.getDocumentStatusConst();
  }

  ngOnInit() {
    this.document = this.options.document;
    const datamodels = this.global.getDatamodels();
    this.datamodels = Array.from(datamodels.keys()).map(key => ({
      datamodelid: key,
      datamodeldisplayname: datamodels.get(key)['datamodeldisplayname']
    }));
    this.form = this.fb.group({
      datamodel: [this.document.datamodel.datamodelid, Validators.required]
    });
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }
}
