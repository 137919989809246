import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-privacy-note',
  templateUrl: './privacy-note.component.html',
  styleUrls: ['./privacy-note.component.scss']
})
export class PrivacyNoteComponent implements OnInit {
  public message: string;
  public loggedInUser: any;
  public loadingPrivacy: boolean = false;
  public appTitle: string;

  constructor(
    public authService: AuthenticationService,
    private globalService: GlobalService,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) {}

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.appTitle = this.globalService.getAppTitle();
  }

  public onConfirmPrivacyNote() {
    this.loadingPrivacy = true;

    const userParams = {
      privacyNoteAccepted: true
    };

    this.apiService
      .put(
        `users/${this.loggedInUser.userid}/privacy_note`,
        '',
        JSON.stringify(userParams),
        'components.modal.user.editSuccessMsg'
      )
      .subscribe(() => {
        this.authService.setLoggedInUserPrivacyNote(true);

        const tenant = this.authService.getTenant();
        this.globalService.redirectUserHomeURL(tenant);

        this.alertService.success(
          this.translate.transform('clientManagement.successfulChanged'),
          true,
          `${tenant.tenantdisplayname}`
        );
      });
  }
}
