import { StyleSettings } from "./style-settings";

export function getDefaultStyleSettings(): StyleSettings {
  return {
    charts: {
      defaultColor: 'steelblue',
      defaultColorScheme: ['steelblue'],

      status: {
        colorScheme: ['#E0EFFC', '#C9E4FC', '#6DA8DB', '#369EF9', '#2885D6'],
        colorKeys: {
          pending: '#d2d040',
          failure: '#d65b5b',
          success: '#44b865',
          inProgress: '#0eacb8',
          warning: '#ee9958',
          partialSuccess: '#b6b844'
        }
      },

      time: {
        colorScheme: ['#46a7b4']
      },

      projectColorScheme: ['#4ca1b1'],
      datamodelColorScheme: ['#6d72be'],
      weekColorScheme: ['steelblue'],
      hourColorScheme: ['steelblue'],
      processColorScheme: ['steelblue'],
      flowColorScheme: ['steelblue'],

      medicDashboard: {
        weightColorScheme: ['#46a7b4'],
        heightColorScheme: ['#46a7b4'],
        bmiColorScheme: ['#46a7b4'],
        hemoglobinColorScheme: ['#46a7b4'],
        bloodPressureColorScheme: ['#46a7b4'],
        cholesterolColorScheme: ['#46a7b4']
      }
    }
  };
}
