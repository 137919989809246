import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { DocumentReviewLogModel } from 'src/app/models/document-review-log.model';

@Component({
  selector: 'app-update-review-countdown',
  templateUrl: './update-review-countdown.component.html',
  styleUrls: ['./update-review-countdown.component.scss']
})
export class UpdateReviewCountdownComponent implements OnInit {

  @Input() options: any;
  public reason = Reason;
  public form: FormGroup;
  public isPaused: boolean;
  public currentDate: Date;
  public lastLogDate?: Date;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.isPaused = this.options ? this.options.isPaused : false;
    if (this.isPaused && this.options?.reviewLogs.length > 0) {
      this.setLastReviewLog();
    }
    this.form = this.formBuilder.group({
      modificationReason: new FormControl('', Validators.required),
      alternativeDate: new FormControl('')
    })
  }

  private setLastReviewLog() : void {
    const reviewLogs = (this.options.reviewLogs as DocumentReviewLogModel[])
    this.lastLogDate = new Date(reviewLogs[reviewLogs.length - 1].createddate)
  }

  public onSubmit() {
    const alternativeDateValue = this.form.get('alternativeDate').value
    let response = {
      reason: Reason.submitBtn,
      result: {
        message: this.form.get('modificationReason').value,
        alternativeDate: alternativeDateValue !== '' ? alternativeDateValue : null
      }
    }
    this.activeModal.close(response);
  }

}
