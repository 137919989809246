import {
  Component,
  OnDestroy,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, AuthenticationType } from '../../../security/authentication.service';
import { LogoutModalComponent } from '../../../components/modals/logout/logout.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public user: any;
  @Input() expandedUserMenu: boolean;
  @Input() expandedNavMenu: boolean;
  @Output() toggleUserMenuEvent = new EventEmitter();
  public clientList: any;
  public loading: boolean = false;
  public spinnerStyles = 'la-ball-circus';
  public hideRefreshSession: boolean;

  constructor(
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private layoutService: LayoutService,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.hideRefreshSession = (
      this.authService.getAuthType() === AuthenticationType.siteminder
    )
    this.user = this.authService.getLoggedInUser();
    this.expandedUserMenu = false;
    if (!this.user) {
      this.subscription = this.authService.userLoggedIn.subscribe(user => {
        this.user = user;
      });
    }
  }

  logout() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      LogoutModalComponent,
      modalOptions
    );
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.authService.logout();
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  public getAvailableClients() {
    if (this.user.iseyadmin) {
      this.loading = true;
      this.apiService.get('clients/', {}, '').subscribe(
        data => {
          this.clientList = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log('Error assigning user to project', error);
        }
      );
    }
  }

  public changeToClient(event: any) {
    const tenant = this.clientList.find(
      element => element.tenantid == event.target.value
    );
    this.authService.setTenantInLocalStorage(tenant.tenantid, tenant.tenantname);
    this.layoutService.toggleUserMenu();
    this.router.navigated = false;
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleMenu() {
    this.expandedUserMenu = !this.expandedUserMenu;
    this.toggleUserMenuEvent.emit(this.expandedUserMenu);
  }

  closeMenu() {
    this.expandedUserMenu = false;
    this.toggleUserMenuEvent.emit(false);
  }

  /**
   * Returns sidebar tenant path
   */
  getPath(item: string) {
    const tenantName = this.authService.getTenantName();
    return `/${tenantName}/${item}`;
  }
  
  public renewToken() {
    this.authService.renewToken({
      useModal: true,
      isAboutToExpire: false
    });
  }
}
