import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CognitiveSearchService } from '../../cognitive-search.service';

@Component({
  selector: 'app-basic-filter',
  templateUrl: './basic-filter.component.html',
  styleUrls: ['./basic-filter.component.scss']
})
export class BasicFilterComponent implements OnInit {
  @Input() public basicFilter: FormGroup;

  public groupSelected: any;

  constructor(private cognitiveService: CognitiveSearchService) {}

  ngOnInit(): void {
    this.basicFilter.markAllAsTouched();
  }

  onSelection(event: MatOptionSelectionChange) {
    // The if is needed beacuse an option trigger changes either select or unselect
    if (event.isUserInput) {
      const deleteKey = Object.keys(this.basicFilter.controls).find(
        e => e !== 'basicType'
      );

      this.groupSelected = event.source.group.label;
      if (this.groupSelected === 'Text') {
        if (deleteKey && deleteKey !== 'text_filters') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addTextFilter(
          this.basicFilter,
          event.source.value
        );
      } else if (this.groupSelected === 'Numeric') {
        if (deleteKey && deleteKey !== 'numeric_filters') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addNumericFilter(
          this.basicFilter,
          event.source.value
        );
      } else if (this.groupSelected === 'Date') {
        if (deleteKey && deleteKey !== 'date_filters') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addDateFilter(
          this.basicFilter,
          event.source.value
        );
      } else if (this.groupSelected === 'Modified') {
        if (deleteKey && deleteKey !== 'modify_filter') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addModifiedFilter(this.basicFilter);
      } else if (this.groupSelected === 'User') {
        if (deleteKey && deleteKey !== 'user_filters') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addUserFilter(this.basicFilter);
      } else if (this.groupSelected === 'Other') {
        if (this.basicFilter.get('basicType').value == '') {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
        this.cognitiveService.addCategoryFilter(
          this.basicFilter,
          event.source.value
        );
      } else {
        if (deleteKey) {
          this.cognitiveService.deleteControl(this.basicFilter, deleteKey);
        }
      }
    }
  }
}
