import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectModel } from '../../models/project-mstr.model';
import { PaginationModel } from '../../models/pagination';
import { KPIModel } from '../../models/kpi.model';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { GlobalService } from 'src/app/services/global.service';
import { NewProjectComponent } from './new-project/new-project.component';
import { UpdateDocsComponent } from '../../components/modals/update-docs/update-docs.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

export enum ViewType {
  list = 0,
  grid
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.pages.html',
  styleUrls: ['./project-list.pages.scss']
})
export class ProjectListComponent implements OnInit {
  public viewType = ViewType;
  public currentViewType = ViewType.grid;
  public kpiInfo: KPIModel;
  public loading: boolean;
  public backgroundLoading: boolean;
  public projectNameFC: FormControl;
  public projectname: string;
  private pagination: PaginationModel;
  public settingTable: any;

  public rows: {};

  public selected: boolean;
  public user: any;
  private modalRef: any;
  public hasEnrichmentModule: boolean;

  // Const
  public _userPermissions: any;

  constructor(
    public router: Router,
    private paginationService: PaginationService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private global: GlobalService
  ) {
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.hasEnrichmentModule = this.authService.userCanViewModule(
      this.user,
      'EnrichmentCoreFunctionality'
    );

    this.getSettingTable();
    this.onResize();
  }

  ngOnInit() {
    this.projectNameFC = new FormControl('');
    this.pagination = this.paginationService.getDefaultPagination('projects');
    // this.getKPIInfo();
    this.getProjectList();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event?) {
    if (window.innerWidth < 768) {
      this.currentViewType = ViewType.list;
    }
  }

  public projectNameFilter() {
    console.log(this.pagination);
    const params = { ...this.pagination, projectname: this.projectname };
    this.apiService.get('projects/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: ProjectModel[];
      }) => {
        this.rows = data;
        this.paginationService.setPage('projects', data);
        this.pagination = this.paginationService.setPagination('projects', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loading = false;
        this.backgroundLoading = false;
      },
      () => {
        this.loading = false;
        this.backgroundLoading = false;
      }
    );
  }

  /**
   * Get projects from storage and database
   */
  public getProjectList() {
    const storedProjects = this.paginationService.getPage('projects');
    this.loading = true;
    if (
      storedProjects &&
      storedProjects.data.length &&
      storedProjects.page == 1 &&
      storedProjects.order_by == 'projectid' &&
      storedProjects.desc === 0
    ) {
      // If projects are loaded, show them and hide spinner
      this.backgroundLoading = true;
    }

    // Always request projects from db
    this.getProjectsFromDB();
  }

  /**
   * Get projects from database
   */
  public getProjectsFromDB() {
    this.apiService.get('projects/', this.pagination, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: ProjectModel[];
      }) => {
        this.rows = data;
        this.paginationService.setPage('projects', data);
        this.pagination = this.paginationService.setPagination('projects', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loading = false;
        this.backgroundLoading = false;
      },
      () => {
        this.loading = false;
        this.backgroundLoading = false;
      }
    );
  }

  /**
   * Get KPI info from storage or database
   */
  private getKPIInfo() {
    this.global.getKPIs('projects', 0).then((data: KPIModel) => {
      this.global.setKPIs('projects', 0, data);
      this.kpiInfo = new KPIModel(data);
    });
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      hasSelect: 'true',
      getDataFromDB: this.getProjectsFromDB.bind(this)
    };
  }

  /**
   * Load modal to create new project
   */
  public onAddProject() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalRef = this.modalService.open(NewProjectComponent, modalOptions);
    modalRef.componentInstance.savedEvent.subscribe(
      () => {
        this.paginationService.changePagination('projects', 'page', 1);
        this.getProjectList();
      },
      error => {
        // TODO implement error message
      }
    );
  }

  /**
   * Handle enrichment files upload
   */
  public onUpdateDocs() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      UpdateDocsComponent,
      modalOptions
    );

    const options = {
      level: 'root'
    };
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValues => {});
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }
}
