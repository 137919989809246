import { Component, Input, OnInit } from '@angular/core';
import { Reason } from '../../../models/close-reason.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _rolesConst from '../../../constants/userRoles';
import { GlobalService } from '../../../services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { PaginationService } from 'src/app/services/pagination.service';
import { PaginationModel } from 'src/app/models/pagination';

@Component({
  selector: 'app-edit-view-role',
  templateUrl: './edit-view-role.component.html',
  styleUrls: ['./edit-view-role.component.scss']
})
export class EditRoleModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public option: string;
  public action: string;
  public roleList: any[] = this.globalService.getRoles();
  public _userPermissions: any;
  public columns: any[];
  public loading: boolean;
  public settingTable: any;
  public rows: any;
  public backgroundLoading: boolean;
  public data: any;
  private pagination: PaginationModel;

  constructor(
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private translate: TranslatePipe,
    private paginationService: PaginationService
  ) {
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.backgroundLoading = false;
  }

  ngOnInit() {
    this.pagination = this.paginationService.getDefaultPagination('editrole');
    this.getPermissionsData();
    this.getSettingTable();
    this.getColumns();
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectUsers.actionsHeader',
        title: 'projectUsers.actionsHeader',
        name: 'description'
      },
      {
        header: 'projectUsers.permissionsHeader',
        title: 'projectUsers.permissionsHeader',
        name: 'hasPermission',
        type: 'permissions'
      }
    ];
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */

  public getPermissionsData() {
    this.options.data.permissions.forEach(element => {
      if (
        this.options.data.rolename != _rolesConst.ADMINISTRATOR &&
        element.permissionname == 'assign_users_to_documents'
      ) {
        element.description = this.translate.transform(
          `permissions.assign_users_to_documents_no_admin`
        );
      } else {
        element.description = this.translate.transform(
          `permissions.${element.permissionname}`
        );
      }
    });

    this.data = {
      total_elements: this.options.data.permissions.length,
      page: 1,
      page_size: 10,
      num_pages: 1,
      order_by: 'permissionid',
      desc: 0,
      data: this.options.data.permissions
    };

    let data = JSON.parse(JSON.stringify(this.options.data.permissions));
    const originalData = JSON.parse(JSON.stringify(data));
    const dataToShowIni =
      this.pagination.page * this.pagination.page_size -
      this.pagination.page_size;
    const dataToShowFin = this.pagination.page * this.pagination.page_size;
    data = data.slice(dataToShowIni, dataToShowFin);
    this.data['total_elements'] = originalData.length;
    this.data['page'] = this.pagination.page;
    this.data['page_size'] = this.pagination.page_size;
    if (originalData.length <= data.length) {
      this.data['num_pages'] = Math.floor(
        originalData.length / this.pagination.page_size
      );
      if (originalData.length % this.pagination.page_size > 0) {
        this.data['num_pages'] = this.data['num_pages'] + 1;
      }
    } else {
      this.data['num_pages'] = 1;
    }
    this.data['order_by'] = this.pagination.order_by;
    this.data['desc'] = this.pagination.desc;
    this.data['data'] = data;
    this.paginationService.setPage('editrole', data);
    this.pagination = this.paginationService.setPagination('editrole', {
      total_elements: data.length,
      page: this.data['page'],
      page_size: this.data['page_size'],
      num_pages: this.data['num_pages'],
      order_by: this.data['order_by'],
      desc: this.data['desc'],
      query: this.pagination.query
    });
    this.pagination = this.paginationService.getPagination('editrole');
  }
  /**
   * Return loaded SettingTable with the general settings for the
   * component responsive table
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'permissionid',
      getDataFromDB: this.getPermissionsData.bind(this),

      responsiveTitle: {},
      paginationClass: 'relative-pag'
    };
  }

  public getRoleTranslation(rolename) {
    this.roleList.forEach(role => {
      if (role.rolename == rolename) {
        rolename = this.translate.transform(
          `components.modal.user.roleList.${role.rolename}`
        );
      }
    });
    return rolename;
  }

  public onLoadingChange(bool) {
    this.loading = bool;
  }
}
