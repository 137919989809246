import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  ViewChild
} from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { UpdateChildrenPages } from 'src/app/components/modals/update-children/update-children.pages';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { DocumentFieldDataModel } from 'src/app/models/document-field-data.model';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';

@Component({
  selector: 'document-children-list',
  templateUrl: './document-children-list.component.html',
  styleUrls: ['./document-children-list.component.scss']
})
export class DocumentChildrenListComponent implements OnInit {
  @Input() documentDetails: any;
  @Input() disableEdit: boolean;
  @Input() childDocument: any;
  @Input() pagesSelectionLimit: number[];
  @Input() pagesSelection: PagesSelection;

  @Output() setChildrenNum = new EventEmitter<string>();
  @Output() createdPagesSelection = new EventEmitter<PagesSelection>();

  private user: any = {};
  public _userPermissions: any;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };
  public approval: any = {};
  public parent: any = {};
  public children: any = {};
  private childrenSelectionStrings: string[] = [];
  public loading = false;
  public reloadpage = false;
  public disableEditProc = false;
  @ViewChild('newUpdateChildrenComponent')
  newUpdateChildrenComponent: UpdateChildrenPages;
  @ViewChildren('updateChildrenComponents')
  updateChildrenComponents: QueryList<UpdateChildrenPages>;
  private activeUpdateChildrenComponent: UpdateChildrenPages;

  constructor(
    private modalService: NgbModal,
    private globalService: GlobalService,
    private apiService: ApiService,
    public fileDownloadService: FileDownloadService,
    authService: AuthenticationService,
    public documentRenderService: DocumentRenderService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  ngOnInit() {
    console.log(this.pagesSelection);
    this.setApproval();
    this.parent = this.documentDetails;
    this.checkIfCanEditChildren();
  }

  /**
   * Set approval and check if user is the assigned reviewer or admin
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals?.find(
      (a: any) => a.currentapproval === true
    );
  }

  public getPagesSelection() {
    if (this.pagesSelection == undefined || this.pagesSelection == null) {
      return false;
    } else {
      if (this.pagesSelection.isSaving) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Iterate over splitted documents and check if all pages have parent data.
   * If any check fails, disable editing children.
   */
  private checkIfCanEditChildren(): void {
    const isChildrenMissingData =
      this.documentDetails.splitteddocumentlist.some((children: any) => {
        return PagesSelection.isChildDocumentMissingPagesData(children);
      });

    if (isChildrenMissingData) {
      this.disableEdit = true;
    }
  }

  /**
   * Check if child edition is disabled.
   */
  isChildDisabled(children: any): boolean {
    return (
      this.childDocument &&
      children.subdocumentid != this.childDocument.documentid
    );
  }

  /**
   * Check if user is the assigned reviewer
   *
   * not used
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Get selection string of pages of child document.
   */
  public getChildrenPagesSelectionString(
    children: any,
    childrenIndex: number
  ): string {
    let selectionString = this.childrenSelectionStrings[childrenIndex];
    if (!selectionString) {
      selectionString =
        PagesSelection.getChildDocumentSelectionString(children);
      this.childrenSelectionStrings[childrenIndex] = selectionString;
    }
    return selectionString;
  }

  /**
   * Document upload handler
   */
  public onUpdateChildDocs(event) {
    this.getDocumentFromDB(this.documentDetails.documentid, () => {
      this.showDocumentList(0, {}, true);
      this.reloadpage = true;
    });
  }

  public showDocumentList(childrenIndex, children, newchild) {
    this.children = children;

    let element: HTMLElement;
    if (newchild) {
      element = this.getChildrenDocumentPanel();
    } else {
      element = this.getChildrenDocumentPanel(childrenIndex);
    }

    this.toggleChildrenDocumentPanelActive(
      element,
      newchild ? undefined : childrenIndex
    );
  }

  /**
   * Make all children document panels not active.
   */
  private makeChildrenDocumentPanelsInactive() {
    let childrenDocumentPanel = this.getChildrenDocumentPanel();
    this.setChildrenDocumentPanelActive(
      childrenDocumentPanel,
      undefined,
      false
    );

    this.documentDetails.splitteddocumentlist.forEach(
      (_e: any, childrenIndex: number) => {
        childrenDocumentPanel = this.getChildrenDocumentPanel(childrenIndex);
        this.setChildrenDocumentPanelActive(
          childrenDocumentPanel,
          childrenIndex,
          false
        );
      }
    );

    this.removeChildrenDocumentPagesSelection();
  }

  /**
   * Documents are available to edit or still being processed
   */
  public isChildrenListAvailable() {
    this.disableEditProc = false;
    for (var i = 0; i < this.documentDetails.splitteddocumentlist.length; i++) {
      if (this.documentDetails.splitteddocumentlist[i].subpagesid.length == 0) {
        this.disableEditProc = true;
        break;
      }
    }
    return this.disableEditProc;
  }

  /**
   * Get children document panel.
   * @param childrenIndex children document index.
   * If undefined, return new children document panel.
   */
  private getChildrenDocumentPanel(childrenIndex?: number): HTMLElement {
    if (childrenIndex === undefined) {
      return document.getElementById('panel');
    } else {
      return document.getElementById('panel--' + childrenIndex);
    }
  }

  /**
   * If children document panel is not active, make it not active and hide it.
   * Otherwise, make it not active and hide it.
   */
  private toggleChildrenDocumentPanelActive(
    childrenDocumentPanel: HTMLElement,
    childrenIndex: number
  ): void {
    const wasChildrenDocumentPanelActive = this.isChildrenDocumentPanelActive(
      childrenDocumentPanel
    );

    this.makeChildrenDocumentPanelsInactive();

    if (!wasChildrenDocumentPanelActive) {
      this.setChildrenDocumentPanelActive(
        childrenDocumentPanel,
        childrenIndex,
        true
      );
    }
  }

  /**
   * Set whether a children document panel is active.
   * Hide or show it.
   * Setup or remove pages selection.
   */
  private setChildrenDocumentPanelActive(
    childrenDocumentPanel: HTMLElement,
    childrenIndex: number,
    isActive: boolean
  ): void {
    if (!childrenDocumentPanel) {
      return;
    }

    if (isActive) {
      childrenDocumentPanel.classList.add('active');
      this.setupChildrenDocumentPagesSelection(childrenIndex);
    } else {
      childrenDocumentPanel.classList.remove('active');
    }

    this.updateChildrenDocumentPanelVisibility(childrenDocumentPanel);
  }

  /**
   * If children document panel is active, make it visible.
   * Otherwise, hide it.
   */
  private updateChildrenDocumentPanelVisibility(
    childrenDocumentPanel: HTMLElement
  ): void {
    if (!childrenDocumentPanel) {
      return;
    }

    childrenDocumentPanel.style.display = this.isChildrenDocumentPanelActive(
      childrenDocumentPanel
    )
      ? 'block'
      : 'none';
  }

  /**
   * Get if children document panel is active.
   */
  private isChildrenDocumentPanelActive(
    childrenDocumentPanel: HTMLElement
  ): boolean {
    if (!childrenDocumentPanel) {
      return undefined;
    }

    return childrenDocumentPanel.classList.contains('active');
  }

  /**
   * Setup selection of children document pages.
   */
  private setupChildrenDocumentPagesSelection(childrenIndex: number) {
    this.removeChildrenDocumentPagesSelection();

    this.activeUpdateChildrenComponent =
      childrenIndex === undefined
        ? this.newUpdateChildrenComponent
        : this.updateChildrenComponents.find((_e, i) => i == childrenIndex);

    const pagesSelection =
      this.activeUpdateChildrenComponent.setupPagesSelection();

    this.createdPagesSelection.emit(pagesSelection);
  }

  /**
   * Remove selection of children document pages.
   */
  private removeChildrenDocumentPagesSelection() {
    if (this.activeUpdateChildrenComponent) {
      this.activeUpdateChildrenComponent.removePagesSelection();
      this.createdPagesSelection.emit(undefined);
    }
  }

  /**
   * Get document details (document and fields) from global service
   */
  public getDocumentFromDB(documentId, next?: (ok: boolean) => void) {
    this.loading = true;
    this.apiService
      .get(
        `documents/${documentId}/`,
        { include_ids: 1, include_fields: 1 },
        ''
      )
      .subscribe(
        (documentDetails: DocumentFieldDataModel) => {
          this.documentDetails = documentDetails;
          this.setChildrenNum.emit(this.documentDetails.splitteddocumentlist);
          this.loading = false;
          if (next) {
            next(true);
          }
        },
        error => {
          this.loading = false;
          if (next) {
            next(false);
          }
        }
      );
  }

  /**
   * Load modal to confirm document(s) removal
   */
  private deleteDocumentModal(documents) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'document',
      notAllowed: documents.length < 1
    };
    const documentsids = documents.map(doc => {
      return doc.subdocumentid;
    });
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: documentsids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.apiService
      .post('documents/delete', requestbody, 'projectDocument.delete')
      .subscribe(
        () => {
          // Remove documents from view while request from db
          this.documentDetails.splitteddocumentlist =
            this.documentDetails.splitteddocumentlist.filter(
              el => requestbody.ids.indexOf(el.subdocumentid) < 0
            );
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }
}
