import {
  Component,
  OnChanges,
  OnDestroy,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { Subscription, Observable, concat } from 'rxjs';
import { DocumentValidationService } from '../document-validation.service';
import { element } from 'protractor';
import { ValidationCollapsibleListItemComponent } from './validation-collapsible-list-item/validation-collapsible-list-item.component';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';

@Component({
  selector: 'app-validation-collapsible-list',
  templateUrl: './validation-collapsible-list.component.html',
  styleUrls: ['./validation-collapsible-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidationCollapsibleListComponent
  implements OnChanges, OnDestroy
{
  @Input() tests: any = {};
  @Input() documentDetails: any;
  @Output() updateTimeline = new EventEmitter();
  @Output() updateShowChunkButton = new EventEmitter();

  public loadingCollapsible: boolean = false;
  public lineMarked: boolean = false;
  public conditionMarked: boolean = false;
  public isCollapsed: boolean = false;
  public failures: number;
  public total: number;
  public elements: any[] = [];
  public previusElements: any[] = [];
  public _validationStatus: any;
  public _validationTypes: any;
  public searchSub: Subscription;
  public validationsStatus: ValidationStatusModel[];
  public subscriptionUtils: Subscription;
  public commentPerm: boolean = true;

  private user: any = {};
  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private global: GlobalService,
    private translate: TranslatePipe,
    private documentRenderService: DocumentRenderService,
    private validationService: DocumentValidationService
  ) {
    this.user = authService.getLoggedInUser();
    this._validationStatus = this.global.getValidationStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();
    this.searchSub = this.documentRenderService.watchSearch().subscribe(obj => {
      this.emptyAllChunks();
    });
  }

  ngOnInit() {
    this.validationsStatus = this.global.getValidationStatus();

    if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = concat(
        this.global.watchUtils(),
        this.global.watchDataModels()
      ).subscribe((data: string) => {
        this.validationsStatus = this.global.getValidationStatus();
      });
    } else if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsStatus = this.global.getValidationStatus();
        });
    }
  }

  /**
   * Change permission for override (only allow to override one validation at the same time)
   *
   */
  public onChangePermComment() {
    this.commentPerm = !this.commentPerm;
  }

  ngOnChanges() {
    this.elements = this.tests.validations
      ? this.tests.validations
      : this.tests.conditions;

    this.retrievePreviusState();

    if (this.previusElements) {
      if (this.previusElements.length) {
        this.elements.forEach(item => {
          this.previusElements.forEach(previus => {
            if (this.tests.conditions) {
              if (
                item.validationexecutionconditionsid ==
                previus.validationexecutionconditionsid
              ) {
                item['collapsed'] = previus['collapsed'];
              }
            } else {
              if (item.validationname == previus.validationname) {
                item['collapsed'] = previus['collapsed'];
                if (item.children) {
                  item.children.forEach(childitem => {
                    previus.children.forEach(childprevius => {
                      if (
                        childitem.validationexecutionid ==
                        childprevius.validationexecutionid
                      ) {
                        childitem['collapsed'] = childprevius['collapsed'];
                        this.changeChildPreviusState(childitem, childprevius);
                      }
                    });
                  });
                }
              }
            }
          });
        });
      }
    }

    if (this.tests.conditions) {
      this.failures = this.tests.failureConditions;
      this.total = this.tests.totalConditions;
    } else if (
      this.tests.validationtypename === this._validationTypes.PRELIMINARY
    ) {
      this.setRerunPreliminaries();
    }
  }

  ngOnDestroy() {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  /**
   * Retrieving previus state of the dropdowns
   */
  public retrievePreviusState() {
    if (this.tests.name === this._validationTypes.PRELIMINARY) {
      this.previusElements =
        this.validationService.getCollapsiblePreliminaryState();
    }
    if (this.tests.name === this._validationTypes.CATALOG) {
      this.previusElements =
        this.validationService.getCollapsibleCatalogState();
    }
    if (this.tests.name === this._validationTypes.BUSINESS_RULES) {
      this.previusElements =
        this.validationService.getCollapsibleBusinessState();
    }

    if (this.tests.name === this._validationTypes.EXTRACTION) {
      this.previusElements =
        this.validationService.getCollapsibleExtractionState();
    }
  }

  /**
   * Change to previus state of the child conditions
   */
  public changeChildPreviusState(childitem, childprevius) {
    if (
      !childitem.conditions &&
      childprevius.conditions &&
      childprevius['collapsed'] == false
    ) {
      this.loadingCollapsible = true;
      this.global
        .getValidationExecution(childitem.validationexecutionid, true)
        .then(
          (execution: ValidationExecutionModel) => {
            childitem.conditions = this.validationService.serializeConditions(
              execution,
              this.documentDetails,
              this.validationsStatus
            );
            childitem.conditions.forEach(childitemcondition => {
              childprevius.conditions.forEach(childpreviuscondition => {
                if (
                  childitemcondition.validationexecutionconditionsid ==
                  childpreviuscondition.validationexecutionconditionsid
                ) {
                  childitemcondition['collapsed'] =
                    childpreviuscondition['collapsed'];
                }
              });
            });
            this.loadingCollapsible = false;
          },
          () => {
            this.loadingCollapsible = false;
          }
        );
    }
  }

  /**
   * Open and close the condition div
   */
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Function to pass as an Output to the child item
   */
  public onOverride() {
    if (this.tests.conditions) {
      this.failures--;
    }
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }

  /**
   * Functions to construct the form data to pass
   * as param in the post request
   * @param form html form data
   */
  public getFormData(executionid) {
    const params = new FormData();
    params.append('commentdescription', '');
    params.append('validationexecutionid', executionid);
    params.append('documentid', this.documentDetails.documentid.toString());
    return params;
  }

  /**
   * Function called by a validation execution child
   * to rerun de execution
   * @param executionid
   */
  public onExcludePreliminary(executionid) {
    const params = this.getFormData(executionid);
    this.apiService.post('comments', params, '', false).subscribe(
      data => {
        this.alertService.success(
          this.translate.transform(
            'ValidationCollapsibleList.successfulExclude'
          ),
          true
        );
      },
      () => {}
    );
  }

  /**
   * Function called by a validation execution child
   * to rerun de execution
   * @param executionid
   */
  public onRerunPreliminary(executionid) {
    const params = { validationexecutionids: [executionid] };
    this.apiService
      .put(
        `documentvalidations/${this.documentDetails.documentid}/preliminaries`,
        '',
        params,
        'documentDetails.runValidationMsg'
      )
      .subscribe(() => {});
  }

  /**
   * Function to rerun the preliminary validation
   */
  public setRerunPreliminaries() {
    const array = [...this.elements];
    array.forEach(validation => {
      validation.children.forEach(valExe => {
        valExe.allowRerun = this.allowRerun(valExe);
      });
    });
  }

  /**
   * Returns if this validation execution
   * is allowed to rerun.
   * @param valExe
   */
  public allowRerun(valExe) {
    return (
      this.user.isadmin ||
      valExe.status === this._validationStatus.FAILURE ||
      (valExe.status === this._validationStatus.SUCCESS &&
        valExe.hasFailureCatalog)
    );
  }

  /**
   * emptyAllChunks
   */
  public emptyAllChunks() {
    this.lineMarked = false;
    this.conditionMarked = false;
    if (this.elements) {
      this.elements.forEach(element => {
        this.conditionMessageLinesToFalse(element);
        if (element.children) {
          element.children.forEach(child => {
            if (child.conditions) {
              child.conditions.forEach(condition => {
                this.conditionMessageLinesToFalse(condition);
              });
            }
          });
        }
      });
    }
    this.documentRenderService.emptyHighlightedChunks();
  }

  public cleanDocument() {
    let chunks = this.documentRenderService.getChunks();
    let documentId = this.documentDetails.documentid;
    let item = { chunks: chunks[1], documentId: documentId };
    this.emptyAllChunks();
    this.documentRenderService.goToMainDoc(item, documentId);
  }

  private conditionMessageLinesToFalse(condition) {
    condition.markedChunks = false;
    if (condition.messageLines) {
      condition.messageLines.forEach(line => {
        line.markedChunks = false;
      });
    }
  }
}
