import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Reason } from 'src/app/models/close-reason.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() options: any;

  public reason: Reason;
  public message: string;
  public showCancelButton: boolean;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.message = this.options.message;
    this.showCancelButton = this.options.showCancelButton;
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }

  onDismiss(reason: Reason) {
    this.activeModal.dismiss(reason);
  }
}
