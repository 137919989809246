import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-query-details-accordion',
  templateUrl: './query-details-accordion.component.html',
  styleUrls: ['./query-details-accordion.component.scss']
})
export class QueryDetailsAccordionComponent implements OnInit {

  @Input() selectedQuery = null;
  public queryDetailsExpanded: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  public expandCollapseQueryDetails():void {
    this.queryDetailsExpanded = !this.queryDetailsExpanded;
  }

}
