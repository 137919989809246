import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  ViewChild,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { DocumentModel } from '../../../models/document.model';
import { StatusModel } from '../../../models/status.model';
import { PaginationModel } from '../../../models/pagination';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from '../../../services/api.service';
import { AuthenticationService } from '../../../security/authentication.service';
import { GlobalService } from '../../../services/global.service';
import { PaginationService } from '../../../services/pagination.service';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { LinkService } from 'src/app/services/link.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { CacheListComponent } from './cache-list/cache-list.component';
import { QueueListComponent } from './queue-list/queue-list.component';


@Component({
  selector: 'app-queue-management',
  templateUrl: './queue-management.component.html',
  styleUrls: ['./queue-management.component.scss']
})
export class QueueManagementComponent implements OnInit, OnChanges {
  @Input() paginationType: string;
  public user: any;
  public showFilters: boolean = false;
  public _documentStatus: any;
  public environ: any;
  public tabs = ['Queues', 'Cache'];
  public currentTab;
  public aux = 0;
  private translate: TranslatePipe;
  public filters: any = [];
  public labelFilters: any[];
  public columns: any[];
  public pagination: PaginationModel;
  public backgroundLoading: boolean;
  public filterEvent: Subject<string> = new Subject<string>();
  public nameTenants = [];

  @ViewChild(CacheListComponent) private cachelistchild: CacheListComponent;
  @ViewChild(QueueListComponent) private queuelistchild: QueueListComponent;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private paginationService: PaginationService
  ) {
    this.user = authService.getLoggedInUser();
    this.environ = environment.env.toUpperCase();
    this.currentTab = 'Queues';
  }

  ngOnChanges() {
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
  }

  public toggleTab(tab: string) {
    this.currentTab = tab;
    if (this.currentTab == 'Queues'){
      this.aux = 0;
      this.labelFilters = [];
      this.showFilters =false;
      
    }
    if (this.currentTab == 'Cache') {
      this.aux = 1;
      this.labelFilters = [];
      this.showFilters =false;
    }
  }

  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }
  ngOnInit() {
    if (
      ['assignedprojectusers', 'unassignedprojectusers'].includes(
        this.paginationType
      )
    ) {
      this.showFilters = true;
    }
  }

  /***
   * Reception of the variable columns from the children
   */
  public getColumns(columns: any[]) {
    this.columns = columns;
  }

  public getTenants(nameTenants: any[]) {
    this.nameTenants = nameTenants;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters) {
    this.onBackgroundLoadingChange(true);
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );

    this.filters = {
      client: filters['client'] !== undefined ? filters['client'] : ''
    };

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        let value;
        value = this.filters[f];

        return { name: f, value };
      });

    if (this.currentTab == 'Queues') {
      this.queuelistchild.getQueues(filters, false);
    } else {
      this.cachelistchild.getCacheKeys(filters);
    }
  }

  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }
}
