import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EditRoleModalComponent } from '../modals/edit-view-rol/edit-view-role.component';
import { Router } from '@angular/router';
import * as _rolesConst from '../../constants/userRoles';
import { LinkService } from 'src/app/services/link.service';
import { MomentPipe } from 'src/app/pipes/moment.pipe';
import * as moment from 'moment';
import { Subscription, concat } from 'rxjs';

@Component({
  selector: 'app-column-responsive-table',
  templateUrl: './column-responsive-table.component.html',
  styleUrls: ['./column-responsive-table.component.scss']
})
export class ColumnResponsiveTableComponent implements OnInit, OnDestroy {
  @Input() column: any;
  @Input() rowData: any;
  public datamodels: any = [];
  public _documentStatus: any;
  public subscriptionUtils: Subscription;
  constructor(
    private global: GlobalService,
    private translate: TranslatePipe,
    private modalService: NgbModal,
    private router: Router,
    private link: LinkService
  ) {
    this._documentStatus = this.global.getDocumentStatusConst();
  }

  ngOnInit() {
    if (this.column.type === 'datamodels') {
      this.datamodels = Array.from(this.global.getDatamodels().values());
      if (this.datamodels.length === 0 && !this.global.passedWatcherUtils) {
        this.subscriptionUtils = this.global.watchUtils().subscribe(() => {
          this.datamodels = Array.from(this.global.getDatamodels().values());
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
  }

  /**
   * Return CSS Class for Status Colors
   */
  public getStatusClass(classname, row) {
    const tooltip = row.statusmessage ? 'has-tooltip ' : '';
    if (this.link.controlExternalAccess()) {
      return 'text-status--in-progress';
    }
    if (classname.includes(this._documentStatus.SUCCESS)) {
      return 'text-status--success';
    } else if (classname.includes(this._documentStatus.FAILURE)) {
      return tooltip + 'text-status--failure';
    } else if (classname.includes(this._documentStatus.WARNING)) {
      return tooltip + 'text-status--warning';
    } else if (classname.includes(this._documentStatus.IN_PROGRESS)) {
      return 'text-status--in-progress';
    } else if (classname.includes(this._documentStatus.PENDING)) {
      return 'text-status--pending';
    }
  }

  /**
   * translate will be done if has translation
   */
  public getStatusMessageTranslation(statusmessage) {
    if (
      statusmessage.split('.').length > 1 &&
      this.translate.transform(`errors.${statusmessage}`) ===
        statusmessage.split('.')[0]
    ) {
      return statusmessage;
    }
    return this.translate.transform(`errors.${statusmessage}`);
  }

  /**
   * translate will be done if has translation
   */
  public getStatusName(statusname) {
    return this.link.controlExternalAccess() ? 'In Progress' : statusname;
  }

  /**
   * Last day in red color
   */
  public getStatusDate(rowdate) {
    if (rowdate.reviewduedate) {
      var currentDate = moment().format();
      return currentDate > rowdate.reviewduedate ? 'column__last_date' : '';
    } else {
      return '';
    }
  }
  /**
   * translate rolenames
   */
  public getRoleTranslation(role) {
    return this.translate.transform(`components.modal.user.roleList.${role}`);
  }

  public getPermissionsRoles(rolename) {
    const roleList = this.global.getRoles();
    const permissionsList = this.global.getPermissions();

    roleList.forEach(role => {
      if (role.rolename == rolename) {
        if (role.rolename == _rolesConst.ADMINISTRATOR) {
          permissionsList.forEach(permissionAll => {
            permissionAll['hasPermission'] = true;
          });
        } else {
          permissionsList.forEach(permissionAll => {
            permissionAll['hasPermission'] = false;
            role.permissions.forEach(permission => {
              if (permission['permissionid'] == permissionAll.permissionid) {
                permissionAll['hasPermission'] = true;
              }
            });
          });
        }
      }
    });
    return permissionsList;
  }

  /**
   * Load modal to show permissions
   */
  public onViewPermissions(rolename) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options: any = {};
    options.type = 'permissions';
    options.action = 'edit';
    options.data = {
      permissions: this.getPermissionsRoles(rolename),
      rolename: rolename
    };

    const modalWindowRef = this.modalService.open(
      EditRoleModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(role => {});
  }

  public rowTitle(title) {
    if ([undefined, null].includes(title)) {
      return '';
    } else {
      let tooltips = '';
      title.forEach(id => {
        const datamodel = this.datamodels.filter(
          datamodel => datamodel.datamodelid === id
        )[0];
        tooltips += datamodel ? datamodel.datamodeldisplayname + '\n' : '';
      });
      return tooltips;
    }
  }

  /*
   * This function gets the icon to be displayed for a document status
   */
  public getIcon(status: string) {
    const replacedStatus = status.replace(/\s+/g, '-');
    if (
      [
        this._documentStatus.MANUAL_SUCCESS,
        this._documentStatus.SUCCESS,
        this._documentStatus.PARTIAL
      ].includes(replacedStatus)
    ) {
      return 'check';
    } else if (
      [
        this._documentStatus.FAILURE,
        this._documentStatus.EXTRACTION_FAILURE,
        this._documentStatus.BR_FAILURE,
        this._documentStatus.PRELIMINARY_FAILURE,
        this._documentStatus.CATALOG_FAILURE
      ].includes(replacedStatus)
    ) {
      return 'clear';
    } else if (replacedStatus === this._documentStatus.WARNING) {
      return 'warning';
    } else if (
      [
        this._documentStatus.IN_PROGRESS,
        this._documentStatus.EXTRACTION_IN_PROGRESS,
        this._documentStatus.BR_IN_PROGRESS,
        this._documentStatus.PRELIMINARY_IN_PROGRESS,
        this._documentStatus.CATALOG_IN_PROGRESS
      ].includes(replacedStatus)
    ) {
      return 'sync';
    } else if (
      [
        this._documentStatus.SKIPPED,
        this._documentStatus.PENDING,
        this._documentStatus.EXTRACTION_PENDING,
        this._documentStatus.BR_PENDING,
        this._documentStatus.PRELIMINARY_PENDING,
        this._documentStatus.CATALOG_PENDING
      ].includes(replacedStatus)
    ) {
      return 'adjust';
    }
  }
}
