import { TitleCasePipe } from "@angular/common";

import {
  BloodPressureDataPoint,
  DataPoint,
  ImpairmentsSetInformationItem,
  LifeUnderWritingInformation,
  MedicationsInformationItem
} from "src/app/models/document-dashboard.model";
import { StyleSettings } from "src/app/theme/theme-components/style-settings/style-settings";

const GRAPH_HEIGHT = 160;

/**
 * Menu item.
 */
export class MenuItem {
  contentTab: string;
  labelText: string;
  contentTitleParts: string[];

  data?: any;
  getNumber?: () => string;
  getFlag?: () => string;
}

/**
 * Panel data.
 */
export interface PanelData {
  menu?: MenuItem[];
}

/**
 * Body history data.
 */
export class BodyHistoryData implements PanelData {
  menu: MenuItem[] = [
    {
      contentTab: 'weight',
      labelText: 'Weight',
      contentTitleParts: ['History', 'Weight']
    },
    {
      contentTab: 'bmi',
      labelText: 'BMI',
      contentTitleParts: ['History', 'BMI']
    },
    {
      contentTab: 'height',
      labelText: 'Height',
      contentTitleParts: ['History', 'Height']
    }
  ];

  weightData: DataPoint[];
  weightDataConfig: any;

  heightData: DataPoint[];
  heightDataConfig: any;

  bmiData: DataPoint[];
  bmiDataConfig: any;

  public constructor(
    dashboardData: LifeUnderWritingInformation,
    style: StyleSettings
  ) {
    this.initGraphs(style);
    this.initContent(dashboardData);
  }

  /**
   * Initialize graphs.
   */
  private initGraphs(style: StyleSettings) {
    const medicDashboardStyle = style.charts.medicDashboard;
    const graphHeight = GRAPH_HEIGHT;

    this.weightDataConfig = {
      keys: ['y'],
      labels: ['weight'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.weightColorScheme,
      yAxis: 'Weight (kg)',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };

    this.heightDataConfig = {
      keys: ['y'],
      labels: ['height'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.heightColorScheme,
      yAxis: 'Height (inches)',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };

    this.bmiDataConfig = {
      keys: ['y'],
      labels: ['BMI'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.bmiColorScheme,
      yAxis: 'BMI (kg/m²)',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };
  }

  /**
   * Initialize content.
   */
  private initContent(dashboardData: LifeUnderWritingInformation) {
    const generalInfo = dashboardData.generalinformation;
    const bodyDashboards = generalInfo?.bodydashboards;

    this.weightData = bodyDashboards?.bodyweightdashboard;
    this.heightData = bodyDashboards?.bodyheightdashboard;
    this.bmiData = bodyDashboards?.bodybmidashboard;
  }
}

/**
 * Vitals history data.
 */
export class VitalsHistoryData implements PanelData {
  menu: MenuItem[] = [
    {
      contentTab: 'hemoglobin',
      labelText: 'Hemoglobin A1C',
      contentTitleParts: ['History', 'Hemoglobin A1C']
    },
    {
      contentTab: 'cholesterol',
      labelText: 'Cholesterol Ratio',
      contentTitleParts: ['History', 'Cholesterol Ratio']
    },
    {
      contentTab: 'blood-pressure',
      labelText: 'Blood Pressure',
      contentTitleParts: ['History', 'Blood Pressure']
    }
  ];

  hemoglobinData: DataPoint[];
  hemoglobinDataConfig: any;

  bloodPressureData: BloodPressureDataPoint[];
  bloodPressureDataConfig: any;

  cholesterolData: DataPoint[];
  cholesterolDataConfig: any;

  public constructor(
    dashboardData: LifeUnderWritingInformation,
    style: StyleSettings
  ) {
    this.initGraphs(style);
    this.initContent(dashboardData);
  }

  /**
   * Initialize graphs.
   */
  private initGraphs(style: StyleSettings) {
    const medicDashboardStyle = style.charts.medicDashboard;
    const graphHeight = GRAPH_HEIGHT;

    this.hemoglobinDataConfig = {
      keys: ['y'],
      labels: ['Hemoglobin A1C'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.hemoglobinColorScheme,
      yAxis: 'Hemoglobin A1C (%)',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };

    this.bloodPressureDataConfig = {
      keys: ['sys', 'dia'],
      labels: ['Systolic Pressure', 'Diastolic Pressure'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.bloodPressureColorScheme,
      yAxis: 'Blood Pressure (mm Hg)',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };

    this.cholesterolDataConfig = {
      keys: ['y'],
      labels: ['Cholesterol Ratio'],
      dateKey: 'x',
      dateFormat: '%x',
      height: graphHeight,
      colorScheme: medicDashboardStyle.cholesterolColorScheme,
      yAxis: 'Cholesterol Ratio',
      xScaleFormat: '%b %Y',
      curve: 'curveLinear'
    };
  }

  /**
   * Initialize content.
   */
  private initContent(dashboardData: LifeUnderWritingInformation) {
    const generalInfo = dashboardData.generalinformation;
    const vitalsDashboards = generalInfo?.vitalsdashboards;

    this.hemoglobinData = vitalsDashboards?.vitalshemoglobindashboard;
    this.bloodPressureData = vitalsDashboards?.vitalsbloodpressuredashboard;
    this.cholesterolData = vitalsDashboards?.vitalscholesteroldashboard;
  }
}

/**
 * Medications history data.
 */
export class MedicationsHistoryData implements PanelData {
  menu = undefined;

  medications: MedicationsInformationItem[];

  public constructor(
    dashboardData: LifeUnderWritingInformation
  ) {
    this.initContent(dashboardData);
  }

  /**
   * Initialize content.
   */
  private initContent(dashboardData: LifeUnderWritingInformation) {
    this.medications = dashboardData.medication;
  }
}

/**
 * Impairments data.
 */
export class ImpairmentsData implements PanelData {
  menu: MenuItem[];

  public constructor(
    dashboardData: LifeUnderWritingInformation,
    titleCasePipe: TitleCasePipe
  ) {
    this.initMenu(dashboardData, titleCasePipe);
  }

  /**
   * Initialize menu.
   */
  private initMenu(
    dashboardData: LifeUnderWritingInformation,
    titleCasePipe: TitleCasePipe
  ) {
    const impairments = dashboardData.impairments;
    const impairmentsHistory = impairments?.impairmentshistory;

    this.menu =
      impairmentsHistory?.map(
        (impairmentSet: ImpairmentsSetInformationItem) => {
          const titleCaseImpairmentType =
            titleCasePipe.transform(impairmentSet.impairmenttype);

          return {
            contentTab: impairmentSet.impairmenttype,
            labelText: titleCaseImpairmentType,
            contentTitleParts: ['Medical Conditions', titleCaseImpairmentType],
            data: impairmentSet,
            getNumber: () => String(impairmentSet.impairmentstotalnumber),
            getFlag: () => impairmentSet.impairmentflag
          };
        }
      ) ?? [];
  }
}
