import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { ExtractionService } from '../../../extraction-components/extraction.service';
import { ValidationService } from '../../../common-components/validation.service';
import { BusinessService } from '../../../business-components/business.service';
import { PreliminaryService } from '../../../preliminary-components/preliminary.service';
import { CatalogService } from '../../../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-multiple-condition',
  templateUrl: './multiple-condition.component.html',
  styleUrls: ['./multiple-condition.component.scss']
})
export class MultipleConditionComponent implements OnInit {
  @Input('multiple_condition') multiple_condition: FormGroup;
  @Input('condition_index') condition_index: number;
  @Input('casesIndex') casesIndex: number;

  constructor(
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    this.multiple_condition
      .get('simple_multiple')
      .valueChanges.subscribe(value => {
        this.onChangeSingleMultiple(value);
      });

    this.multiple_condition
      .get('conditions')
      .valueChanges.subscribe((changedObj: any) => {
        this.changeSingleMultipleDisabledStatus();
      });
    this.changeSingleMultipleDisabledStatus();
  }

  /**
   * Description: Add condition to condition list, here index its not neccesary because we are in
   * @param typo to know if its single or multiple (it has different controllers)
   * @returns void
   */
  public addCondition() {
    const conditions = this.multiple_condition.get('conditions') as FormArray;
    const isMultiple = this.multiple_condition.get('simple_multiple').value;
    let new_condition: FormGroup;
    new_condition = this.getCondition(isMultiple);
    conditions.push(new_condition);
  }

  private getCondition(isMultiple: boolean) {
    if (!isMultiple) {
      switch (this.validationService.getValidationTypeName()) {
        case 'Extraction':
          return this.extractionService.getCaseCondition();
        case 'Business Rules':
          return this.businessService.getCaseCondition();
        case 'Preliminary':
          return this.preliminaryService.getCaseCondition();
        case 'Catalog':
          return this.catalogService.getCaseCondition();
        default:
          break;
      }
    } else {
      return this.validationService.getMultipleCondition();
    }
  }

  /**
   * Add control error if type is single
   * @param isMultiple if condition is multiple
   */
  private onChangeSingleMultiple(isMultiple: boolean) {
    if (
      !isMultiple &&
      this.validationService.isType(['Business Rules', 'Catalog'])
    ) {
      this.multiple_condition.addControl(
        'error',
        new FormControl('', Validators.required)
      );
    } else if (this.multiple_condition.get('error')) {
      this.multiple_condition.removeControl('error');
    }
  }

  /**
   * Check if simple-multiple switch must be disabled
   */
  private changeSingleMultipleDisabledStatus() {
    if (this.multiple_condition.get('conditions')['controls'].length > 0) {
      this.multiple_condition.get('simple_multiple').disable();
    } else {
      this.multiple_condition.get('simple_multiple').enable();
    }
  }

  public isErrorControlActive() {
    return (
      this.validationService.isType(['Business Rules', 'Catalog']) &&
      this.validationService.hasKey(this.multiple_condition, 'error')
    );
  }

  public isAddConditionDisabled() {
    return (
      this.multiple_condition.get('condition_type').invalid ||
      (this.validationService.hasKey(this.multiple_condition, 'error') &&
        this.multiple_condition.get('error').invalid)
    );
  }

  public changePreConditionCheckbox(i: number, flag: boolean) {
    flag ? this.addPreCondition(i) : this.removePreConditions(i);
  }

  /**
   * Description: Add condition to condition list
   * @param i condition list index to add condition
   */
  public addPreCondition(i: number) {
    const conditions = this.multiple_condition.get(
      'preconditions'
    ) as FormArray;
    let new_precondition: FormGroup;
    new_precondition = this.businessService.getPreCondition();
    conditions.push(new_precondition);
  }

  public removePreConditions(i: number) {
    let preconditions = this.multiple_condition.get(
      'preconditions'
    ) as FormArray;
    if (preconditions !== undefined) {
      preconditions.clear();
    }
  }
}
