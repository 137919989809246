import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleLinear, scaleBand } from 'd3-scale';
import { max, extent, histogram } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { brushX, brushSelection } from 'd3-brush';
import { transition } from 'd3-transition';
import { StyleManagerService } from 'src/app/services/stylemanager.service';
const d3 = {
  select,
  selectAll,
  scaleLinear,
  scaleBand,
  max,
  extent,
  histogram,
  axisLeft,
  axisBottom,
  brushX,
  brushSelection,
  transition
};

/**
 * Histogram Component
 */
@Component({
  selector: 'app-d3histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3HistogramComponent implements OnChanges {
  @Input() public data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  @Output() onbrush = new EventEmitter();
  private cfg: any = {}; // Default configuration object
  private bins: Array<any>;
  private selection: any;
  private svg;
  private tooltip;
  private barg;
  private bars;
  private brusharea;
  private axisv;
  private axish;
  private xScale: any;
  private yScale: any;
  private xScaleBrush: any;
  private yScaleBrush: any;
  private xAxis: any;
  private yAxis: any;

  public min_value: number;
  public max_value: number;
  public start_value: number;
  public end_value: number;

  constructor(
    elementRef: ElementRef,
    styleManager: StyleManagerService
  ) {
    const style = styleManager.styleSettings;

    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 400, // Default height
      margin: { top: 10, right: 20, bottom: 20, left: 40 },
      brushHeight: 30,
      brushGap: 26,
      key: 'key', // Value to compute
      bins: 10,
      color: style.charts.defaultColor,
      yAxis: '',
      yScaleTicks: 5, // yAxis ticks
      yScaleFormat: '.0f' // yAxis format. See more on https://github.com/d3/d3-format
    };
  }

  ngOnChanges() {
    this.setConfiguration(this.config);
    if (this.data && this.data.length > 0) {
      const data = [...this.data];
      this.computeData(data);
      this.updateScales(data);
      this.drawChart();
      this.bindData();
      this.updateChart();
      this.drawBrush();
    } else {
      this.emptyChart();
      this.emptyBrush();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }
  /**
 * Creates a new d3 transition
 * @returns a new transition with the specified time
 */
   private getTransition(duration?: number): any{
    if(duration){
      return d3.transition('t').duration(duration);
    }
    return d3.transition('t');
  }

  /**
   * Calcule scales, bins, maximum values and other stuff to draw the chart
   */
  private computeData(data) {
    // Define horizontal scale (Bar's width)
    this.xScale = d3.scaleLinear();

    // Define vertical scale (Bar's height)
    this.yScale = d3.scaleLinear();

    // Define horizontal scale (for brush area)
    this.xScaleBrush = d3
      .scaleLinear()
      .domain(d3.extent(data, d => +d[this.cfg.key]))
      .range([0, this.cfg.width])
      .nice();

    // Calcule bins (for brush area)
    let bins = d3
      .histogram()
      .value(d => d[this.cfg.key])
      .domain(this.xScaleBrush.domain())
      .thresholds(this.xScaleBrush.ticks(this.cfg.bins))(data);

    // Define vertical scale (for brush area)
    this.yScaleBrush = d3
      .scaleLinear()
      .domain([0, d3.max(bins, d => d.length)])
      .range([this.cfg.brushHeight, 0]); // Inverse scale

    // Assign max and min values to inputs
    this.min_value = this.xScaleBrush.domain()[0];
    this.max_value = this.xScaleBrush.domain()[1];
  }

  /**
   * Update chart scales based on filtered data
   */
  private updateScales(data, domain = null) {
    // If there aren't data, don't change scales
    if (!data.length) {
      this.bins = [];
      return;
    }

    let limits = domain ? domain : d3.extent(data, d => +d[this.cfg.key]);

    // Calcule horizontal scale (Bar's width)
    this.xScale
      .domain(limits)
      .range([0, this.cfg.width]) // Min and max chart width
      .nice();

    // Calcule bins
    this.bins = d3
      .histogram()
      .value(d => d[this.cfg.key])
      .domain(this.xScale.domain())
      .thresholds(this.xScale.ticks(this.cfg.bins))(data);

    // Calcule vertical scale (Bar's height)
    this.yScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.bins, d => d.length)])
      .range([this.cfg.height, 0]); // Inverse scale

    // Assign values to inputs
    this.start_value = this.xScale.domain()[0];
    this.end_value = this.xScale.domain()[1];
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    this.selection.selectAll('svg.chart').remove();

    const totalHeight =
      this.cfg.height +
      this.cfg.margin.top +
      this.cfg.brushGap +
      this.cfg.brushHeight +
      this.cfg.margin.bottom;
    const totalWidth =
      this.cfg.width + this.cfg.margin.left + this.cfg.margin.right;

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--histogram chart--histogram-brushed')
      .attr('viewBox', `0 0 ${totalWidth} ${totalHeight}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('width', totalWidth)
      .attr('height', totalHeight)
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Axis group
    let axisg = g
      .append('g')
      .attr('class', 'chart__axis chart__axis--histogram');

    // Vertical axis (horizontal grid)
    this.axisv = axisg
      .append('g')
      .attr('class', 'chart__axis-y chart__axis-y--histogram chart__grid');

    // Vertical axis title
    if (this.cfg.yAxis)
      axisg
        .append('text')
        .attr('y', -this.cfg.margin.left + 10)
        .attr('x', -this.cfg.height / 2 - this.cfg.margin.top)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(this.cfg.yAxis);

    // Botom axis
    this.axish = axisg
      .append('g')
      .attr('class', 'chart__axis-x chart__axis-x--histogram')
      .attr('transform', 'translate(0,' + this.cfg.height + ')');

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    this.tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--histogram');

    // Bars (all) group
    this.barg = g
      .append('g')
      .attr('class', 'chart__bars chart__bars--histogram');
  }

  /**
   * Bind data to elements
   */
  private bindData() {
    // Bind data to bars
    this.bars = this.barg.selectAll('rect').data(this.bins, (d, i) => i);
  }

  /**
   * Draw the chart
   */
  private updateChart() {
    // Vertical axis (horizontal grid)
    this.axisv
    .transition(this.getTransition(1000))
      .call(
        d3
          .axisLeft(this.yScale)
          .tickSize(-this.cfg.width)
          .ticks(this.cfg.yScaleTicks, this.cfg.yScaleFormat)
      );

    // Botom axis
    this.axish.transition(this.getTransition(1000)).call(d3.axisBottom(this.xScale));

    // Enter elements
    this.bars
      .enter()
      .append('rect')
      .attr('class', 'chart__bar chart__bar--histogram')
      .attr('fill', this.cfg.color)
      .attr('y', d => this.cfg.height)
      .attr('width', d => this.xScale(d.x1) - this.xScale(d.x0))
      .attr('x', d => this.xScale(d.x0))
      .on('mouseout', () => {
        this.tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        this.tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      });

    // Update elements
    this.barg
      .selectAll('rect')
      .transition(this.getTransition(1000))
      .attr('width', d => this.xScale(d.x1) - this.xScale(d.x0))
      .attr('x', d => this.xScale(d.x0))
      .attr('y', d => this.yScale(+d.length))
      .attr('height', d => this.cfg.height - this.yScale(+d.length));

      this.barg.selectAll('rect').on('mouseover', (d, i) => {
      this.tooltip
        .html(() => {
          return `
          <div>${i.x0}-${i.x1}: ${i.length}</div>
          `;
        })
        .classed('active', true);
    });

    // Exit elements
    this.bars.exit().transition(this.getTransition(1000)).style('opacity', 0).remove();
  }

  private emptyChart() {
    this.selection
      .selectAll('.chart__bar')
      .transition(this.getTransition(1000))
      .attr('y', this.cfg.height)
      .attr('height', 0)
      .remove();
  }

  /**
   * Draw the brush
   */
  private drawBrush() {
    // Brush area
    this.brusharea = this.svg
      .append('g')
      .attr('class', 'chart__brush chart__brush--histogram')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top + this.cfg.height + this.cfg.brushGap
        })`
      );

    // Brush bars
    let brushbars = this.brusharea
      .selectAll('rect')
      .data(this.bins)
      .enter()
      .append('rect')
      .attr('class', 'chart__bar chart__bar--histogram')
      .attr('fill', this.cfg.color)
      .attr('x', d => this.xScaleBrush(d.x0))
      .attr('width', d => this.xScaleBrush(d.x1) - this.xScaleBrush(d.x0))
      .attr('y', d => this.yScaleBrush(+d.length))
      .attr('height', d => this.cfg.brushHeight - this.yScaleBrush(+d.length));

    // Brush event
    this.brusharea.call(
      d3
        .brushX()
        .extent([
          [0, 0],
          [this.cfg.width, this.cfg.brushHeight]
        ])
        .on('end', _ => {
          this.brushEnded();
        })
    );
  }

  /**
   * Empty brush area
   */
  private emptyBrush() {
    this.selection.selectAll('.chart__brush').remove();
  }

  /**
   * onbrush (end) event handler
   */
  private brushEnded() {
    const brushselection = d3.brushSelection(this.brusharea.node());

    if (brushselection) {
      // Brush has changed -> filter data
      this.filterData(
        Math.floor(this.xScaleBrush.invert(brushselection[0])),
        Math.floor(this.xScaleBrush.invert(brushselection[1]))
      );
    } else {
      // Brush is removed -> show all data
      this.resetFilters();
    }
  }

  /**
   * Inputs change event handler
   */
  private updateInputValues() {
    // Both values are required
    if (this.start_value == null || this.end_value == null) return;

    // Values must be different
    if (this.start_value === this.end_value) return;

    // End value must be greater than start value
    if (this.start_value > this.end_value) return;

    // update brush
    d3.brushX().move(this.brusharea, [
      this.xScaleBrush(this.start_value),
      this.xScaleBrush(this.end_value)
    ]);

    // filter data
    this.filterData(this.start_value, this.end_value);
  }

  /**
   * Filter data from within date range
   */
  private filterData(start, end) {
    let data = this.data.filter(d => {
      return d[this.cfg.key] >= start && d[this.cfg.key] <= end;
    });

    this.updateDataChain(data, [start, end]);

    // Emit date changes
    this.onbrush.emit([start, end]);
  }

  /**
   * Show all data (remove filters)
   */
  private resetFilters() {
    this.updateDataChain(this.data);
    // Emit filter reset
    this.onbrush.emit(null);
  }

  /**
   * Methods to call on data filter change
   */
  private updateDataChain(data, domain = null) {
    this.updateScales(data, domain);
    this.bindData();
    this.updateChart();
  }
}
