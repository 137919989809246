/**
 * Duration time in hours of a session
 */
 const siteminderSessionHoursDuration = 1000;
 export function siteminderGetTokenExpirationDate(): Date {
   const expirationDate = new Date();
   expirationDate.setHours(
     expirationDate.getHours() + siteminderSessionHoursDuration
   )
   return expirationDate;
 }
 
 