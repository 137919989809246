import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-document-genaiqna',
  templateUrl: './document-genaiqna.component.html',
  styleUrls: ['./document-genaiqna.component.scss']
})
export class DocumentGenaiqnaComponent implements OnInit {
  @Input() documentDetails: any;
  public pagesSelection: PagesSelection;
  public isChildrenEditDisabled: boolean = false;
  @ViewChild('qnaWrapper', { static: false }) refQnaWrapper: ElementRef;
  @ViewChild('deletePopup', { static: false }) refdeletePopup: ElementRef;
  @ViewChild('searchinputfield', { static: false }) refSearchInputField: ElementRef;

  public searchString: string = '';
  public searchStringDisbale: Boolean = false;
  public QnAData: any = [];
  public isLoading: Boolean = false;
  public QnAisLoading: Boolean = false;
  public flagQnAisLoadingAndSearchDisbale : Boolean = false;
  public isDeleteActive: Boolean = false;
  private previousDeleteActive: number | null = null
  public searchInputElement: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private apiService: ApiService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) { }

  ngOnInit(): void {
    this.fetchListOfQnA();
  }

  /**
  * Set pages selection.
  */
  public setPagesSelection(pagesSelection: PagesSelection) {
    this.pagesSelection = pagesSelection;

    // Pages selection usually comes from a child component
    // so trigger change detection.
    this.changeDetector.detectChanges();
  }

  /**
 * Disable children edition.
 */
  public disableChildrenEdit(): void {
    this.isChildrenEditDisabled = true;
  }


  // fetch the list of qna from DB
  public fetchListOfQnA() {
    this.isLoading = true;
    const documentid = this.documentDetails.documentid;
    this.apiService.get(`singledocsearchopenai/qna/`, { documentid }, '')
      .subscribe(
        response => {
          this.QnAData = response['hits']['hits'].map((res: any) => {
            const data = { 'isDeleteActive': this.isDeleteActive }
            res['_source'] = { ...res['_source'], ...data };
            return res;
          });         
          this.isLoading = false;
          setTimeout(() => {
            this.searchInputElement = this.refSearchInputField.nativeElement
            if (this.searchInputElement) {
              this.searchInputElement.focus();
            }
          }, 500);
        },
        error => {
          console.log('error', error);
          this.isLoading = false;
        });
  }

  // search question and fetch response from DB.
  public searchQuestion(question) {
    if (question === '' || question === undefined) return false;
    const params = {
      documentid: this.documentDetails.documentid,
      search: question
    };

    this.flagQnAisLoadingAndSearchDisbale = true;
    this.emptyInput();

    this.apiService.get(`singledocsearchopenai/`, params, '')
      .subscribe(
        res => {
          const appendNewData = {
            '_id': res.id,
            '_source': {
              'question': res.question,
              'answer': res.answer,
              'isDeleteActive': this.isDeleteActive,
            }
          }

          this.QnAData.push({ ...appendNewData });
          const el: HTMLDivElement = this.refQnaWrapper.nativeElement;

          setTimeout(() => {
            el.scrollTop = el.scrollHeight;
            this.flagQnAisLoadingAndSearchDisbale = false;            
          }, 300);

          setTimeout(() => {
            this.searchInputElement = this.refSearchInputField.nativeElement;
            if (this.searchInputElement) {
              this.searchInputElement.focus();
            }
          }, 600);
        },
        error => {
          console.log('error', error);
          this.flagQnAisLoadingAndSearchDisbale = false;
        });
  }

  // Open popup on click single delete
  public deleteSingleQnA(qnaId: any, index: number) {
    if (this.previousDeleteActive !== null) {
      this.QnAData[this.previousDeleteActive]['_source'].isDeleteActive = false;
    }
    this.QnAData[index]['_source'].isDeleteActive = true;
    this.previousDeleteActive = index;
  }

  // Delete all QnA in chat
  public deleteAllQnA() {
    const url = 'singledocsearchopenai/qna/delete_all_qna';
    const params = { 'documentid': this.documentDetails.documentid };

    if (this.previousDeleteActive !== null) {
      this.QnAData[this.previousDeleteActive]['_source'].isDeleteActive = false;
    }

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: this.translate.transform('documentDetails.documentQnADeleteConversation'),
      multilineText: true,
      customMessage: this.translate.transform('documentDetails.messageDeleteAllText')
    };
    
    this.flagQnAisLoadingAndSearchDisbale = true;
    modalWindowRef.result.then(
      result => {
        if (result) {
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              this.QnAData = [];
              this.alertService.success(this.translate.transform('documentDetails.allTheConversationRemoved'));
              this.flagQnAisLoadingAndSearchDisbale = false;
            },
            (error) => {
              console.log('error', error);
              this.flagQnAisLoadingAndSearchDisbale = false;
            }
          );
        } else {
          this.flagQnAisLoadingAndSearchDisbale = false;
        }
      }
    );
  }

  // On confirmed button click delete the QnA
  public deleteConfirmed(qnaId: any, index: number) {
    const url = `singledocsearchopenai/qna/delete_single_qna`
    const params = { 'id': qnaId };
    this.refdeletePopup.nativeElement.style.display = 'none';

    this.flagQnAisLoadingAndSearchDisbale = true;

    this.apiService.post(url, params, '')
      .subscribe(
        data => {
          this.QnAData.splice(index, 1);
          if (this.previousDeleteActive !== null) {
            if (this.previousDeleteActive === index) {
              this.previousDeleteActive = null;
            }
          }
          this.alertService.success(this.translate.transform('documentDetails.selectedQuestionDeleted'));
          this.flagQnAisLoadingAndSearchDisbale = false;
        },
        error => {
          console.log('error', error);
          this.flagQnAisLoadingAndSearchDisbale = false;
        });
  }

  // On click of Skip button close current popupup.
  public skipDelete(qnaId: any, index: number) {
    this.QnAData[index]['_source'].isDeleteActive = false;
    this.previousDeleteActive = null;
  }

  public emptyInput() {
    this.searchString = '';
  } 

}

