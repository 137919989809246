import { UserModel } from './user-mstr.model';

export class ProjectModel {
  public projectid: number;
  public projectname: string;
  public url: string;
  public tenantid: number;
  public createddate: string;
  public createdby: number;
  public createdbyuser: UserModel;
  public modifieddate: string;
  public modifiedby: number;
  public modifiedbyuser: UserModel;
  public isactive: boolean;
  public allowcomments: boolean;
  public allowconsolidatedar: boolean;
  public allowconsolidatedpo: boolean;
  public analyses: number;
  public documents: number;
  public analysismstr: any[];
  public respontableIsShowed: boolean;
  public respontableIsSelected: boolean;

  constructor(
    projectid: number,
    projectname: string,
    url: string,
    tenantid: number,
    createddate: string,
    createdby: number,
    createdbyuser: UserModel,
    modifieddate: string,
    modifiedby: number,
    modifiedbyuser: UserModel,
    isactive: boolean,
    allowcomments: boolean,
    allowconsolidatedar: boolean,
    allowconsolidatedpo: boolean,
    analyses: number,
    documents: number,
    analysismstr: any[],
    respontableIsShowed: boolean,
    respontableIsSelected: boolean
  ) {
    this.projectid = projectid;
    this.projectname = projectname;
    this.url = url;
    this.tenantid = tenantid;
    this.createddate = createddate;
    this.createdby = createdby;
    this.createdbyuser = createdbyuser;
    this.modifieddate = modifieddate;
    this.modifiedby = modifiedby;
    this.modifiedbyuser = modifiedbyuser;
    this.isactive = isactive;
    this.allowcomments = allowcomments;
    this.allowconsolidatedar = allowconsolidatedar;
    this.allowconsolidatedpo = allowconsolidatedpo;
    this.analyses = analyses;
    this.documents = documents;
    this.analysismstr = analysismstr;
    this.respontableIsShowed = false;
    this.respontableIsSelected = false;
  }
}
