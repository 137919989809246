import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-general-confidence',
  templateUrl: './general-confidence.component.html',
  styleUrls: ['./general-confidence.component.scss']
})
export class GeneralConfidenceComponent implements OnInit {
  @Input('formTest') formTest;

  constructor() {}

  ngOnInit() {}

  /**
   * Description: Populate all confidence fields
   * @param event value that will take all confidence fields
   * @returns void
   */
  public populateConfidence(event) {
    const confidence_value = event.target.value;
    const cases = this.formTest['controls']['cases'];
    const condition_list = cases['controls'][0]['controls']['condition_list'];
    condition_list.controls.map(conditions => {
      conditions.controls.conditions.controls.map(condition => {
        condition.controls.confidence.setValue(confidence_value);
      });
    });
  }
}
