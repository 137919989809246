/**
 * Duration time in hours of a session
 */
 const ibmSessionHoursDuration = 1;
 export function ibmGetTokenExpirationDate(): Date {
   const expirationDate = new Date();
   expirationDate.setHours(
     expirationDate.getHours() + ibmSessionHoursDuration
   )
   return expirationDate;
 }
 
 