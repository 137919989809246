import { UserModel } from './user-mstr.model';
import { DatamodelModel } from './datamodel.model';
import { ExtractedFieldListModel } from './extracted-field-list.model';
import { LanguageModel } from './language.model';
import { DocumentReviewLogModel } from './document-review-log.model';

export class DocumentFieldDataModel {
  public documentid: number;
  public documentname: string;
  public documentdisplayname: string;
  public url: string;
  public rundate: string | null;
  public mediatype: string;
  public imagequalitymetricslist: {
    imagequalitymetricname: string;
    imagequalitymetricvalue: number;
  }[];
  public createdby: number;
  public createddate: string;
  public createdbyuser: UserModel;
  public modifiedby: number;
  public modifieddate: string;
  public modifiedbyuser: UserModel;
  public clientdocumentid: string;
  public isactive: number;
  public isparentdocument: boolean;
  public parentdocumentid: number | null;
  public datamodel: DatamodelModel;
  public status: any;
  public statusmessage: string;
  public splitteddocumentlist: string[];
  public extracteddatalist: ExtractedFieldListModel[];
  public project: {
    projectid: number;
    projectname: string;
    url: string;
  };
  public analysis: {
    analysisid: number;
    analysisname: string;
    clienttransactionid: number | null;
    url: string;
  };
  public classificationconfidencelevel: number | null;
  public confidencelevelaverage: number | null;
  public language: LanguageModel;
  public numpages: number | null;
  public emailcontent: string | null;
  public approvals: Array<any> | null;
  public status_data: any;
  public isreviewpaused?: boolean;
  public maxreviewdays?: number;
  public reviewlogs?: DocumentReviewLogModel[]
  public reviewduedate?: Date;

  constructor(obj: {
    documentid: number;
    documentname: string;
    documentdisplayname: string;
    url: string;
    rundate: string | null;
    mediatype: string;
    imagequalitymetricslist: {
      imagequalitymetricname: string;
      imagequalitymetricvalue: number;
    }[];
    createdby: number;
    createddate: string;
    createdbyuser: UserModel;
    modifiedby: number;
    modifieddate: string;
    modifiedbyuser: UserModel;
    clientdocumentid: string;
    isactive: number;
    isparentdocument: boolean;
    parentdocumentid: number | null;
    datamodel: DatamodelModel;
    status: string;
    statusmessage: string;
    splitteddocumentlist: string[];
    extracteddatalist: ExtractedFieldListModel[];
    project: {
      projectid: number;
      projectname: string;
      url: string;
    };
    analysis: {
      analysisid: number;
      analysisname: string;
      clienttransactionid: number | null;
      url: string;
    };
    classificationconfidencelevel: number | null;
    confidencelevelaverage: number | null;
    language: LanguageModel;
    numpages: number | null;
    emailcontent: string | null;
    approvals: Array<any> | null;
    status_data: any;
  }) {
    this.documentid = obj.documentid;
    this.documentname = obj.documentname;
    this.documentdisplayname = obj.documentdisplayname;
    this.url = obj.url;
    this.rundate = obj.rundate;
    this.mediatype = obj.mediatype;
    this.imagequalitymetricslist = obj.imagequalitymetricslist;
    this.createdby = obj.createdby;
    this.createddate = obj.createddate;
    this.createdbyuser = obj.createdbyuser;
    this.modifiedby = obj.modifiedby;
    this.modifieddate = obj.modifieddate;
    this.modifiedbyuser = obj.modifiedbyuser;
    this.clientdocumentid = obj.clientdocumentid;
    this.isactive = obj.isactive;
    this.isparentdocument = obj.isparentdocument;
    this.parentdocumentid = obj.parentdocumentid;
    this.datamodel = obj.datamodel;
    this.status = obj.status;
    this.statusmessage = obj.statusmessage;
    this.splitteddocumentlist = obj.splitteddocumentlist;
    this.extracteddatalist = obj.extracteddatalist;
    this.project = obj.project;
    this.analysis = obj.analysis;
    this.classificationconfidencelevel = obj.classificationconfidencelevel;
    this.confidencelevelaverage = obj.confidencelevelaverage;
    this.language = obj.language;
    this.numpages = obj.numpages;
    this.emailcontent = obj.emailcontent;
    this.approvals = obj.approvals;
    this.status_data = obj.status_data;
  }
}
