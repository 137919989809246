import {
  Component,
  Input,
  OnInit,
  OnChanges,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';

import { AuthenticationService } from 'src/app/security/authentication.service';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-document-overview',
  templateUrl: './document-overview.pages.html',
  styleUrls: ['./document-overview.pages.scss']
})
export class DocumentOverviewComponent implements OnInit, OnChanges {
  @Input() documentDetails: any;

  public user: any = {};
  public showLogTracking: boolean = false;
  public showValidations: boolean = false;
  public showComments: boolean = false;
  public showReviewFlow: boolean = false;

  public parentDocumentDetails: any;
  public pagesSelectionLimit: number[];
  public isChildrenEditDisabled: boolean = false;
  public pagesSelection: PagesSelection;

  public windowScrollTop: number = 0;
  public distanceDocumentRenderToWindowTop: number = 112;
  public showWordMock: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private utils: UtilsService,
    private changeDetector: ChangeDetectorRef
  ) {
    // Check user permission to use this component
    this.user = authService.getLoggedInUser();
    this.showWordMock = this.authService.userCanViewModule(this.user,'DemoModule');
  }

  ngOnInit() {
    this.setParentDocumentDetailsIfChildDocument();
  }

  ngOnChanges() {
    if (
      this.documentDetails &&
      !['Failure', 'In Progress', 'Pending'].includes(
        this.documentDetails.status_data.value
      )
    ) {
      this.showComments = this.authService.userCanViewModule(
        this.user,
        'ManualCommentsFunctionality'
      );

      this.showLogTracking = this.authService.userCanViewModule(
        this.user,
        'TimeTrackingCoreFunctionality'
      );
      this.showValidations = this.authService.userCanViewModule(
        this.user,
        'ValidationsCoreFunctionality'
      );
      this.showReviewFlow = this.authService.userCanViewModule(
        this.user,
        'DueTimeReviewFlowFunctionality'
      );
    }
  }

  /**
   * If this document is a child document,
   * create a stub parent document to wrap this one
   * and restrict pages selection to pages of child document.
   */
  private setParentDocumentDetailsIfChildDocument(): void {
    if (this.documentDetails.parentdocumentid) {
      const parent: any = {};
      parent.splitteddocumentlist = this.documentDetails.splitteddocumentlist;
      parent.documentdisplayname = this.documentDetails.documentdisplayname;
      parent.documentid = this.documentDetails.parentdocumentid;
      parent.datamodel = {
        isclassifier:
          this.documentDetails.parentdocumentdatamodel.datamodel.isclassifier
      };
      this.parentDocumentDetails = parent;

      this.pagesSelectionLimit = PagesSelection.getChildDocumentPages(
        this.documentDetails,
        { getPagesFromProperty: 'pages' }
      );
    }
  }

  /**
   * Change children num
   */
  public setChildrenNum(children) {
    this.documentDetails.splitteddocumentlist = children;
  }

  /**
   * Disable children edition.
   */
  public disableChildrenEdit(): void {
    this.isChildrenEditDisabled = true;
  }

  /**
   * Set pages selection.
   */
  public setPagesSelection(pagesSelection: PagesSelection) {
    this.pagesSelection = pagesSelection;

    // Pages selection usually comes from a child component
    // so trigger change detection.
    this.changeDetector.detectChanges();
  }

  /**
   * Called when document is changed.
   */
  public onDocumentChanged(): void {
    this.utils.reloadRoute();
  }

  /**
   * Listen for window scroll event.
   */
  @HostListener('window:scroll', ['$event'])
  public onScroll(_event: Event) {
    this.windowScrollTop = window.pageYOffset;
  }

  /**
   * Check when the document render must be changed
   * to fixed position.
   */
  public isDocumentRenderFixed() {
    return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
  }
}
