import { Component, OnInit, Input } from '@angular/core';
import { Reason } from 'src/app/models/close-reason.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'no-operation-allowed',
  templateUrl: './no-operation-allowed.component.html',
  styleUrls: ['./no-operation-allowed.component.scss']
})
export class NoOperationAllowedModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public body: string;
  constructor(
    public activeModal: NgbActiveModal, 
    public translate: TranslatePipe,
    private linkService: LinkService
    ) {}
  ngOnInit() {
    this.body = this.options.body;
  }
  goBack(reason: Reason) {
    this.activeModal.close(reason);
    this.linkService.goToQuerySheetsManagement('explorationList');
  }
}
