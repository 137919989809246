import { Component, OnInit, Input } from '@angular/core';
import { ValidationService } from '../../common-components/validation.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-field-of-interest-condition',
  templateUrl: './field-of-interest-condition.component.html',
  styleUrls: ['./field-of-interest-condition.component.scss']
})
export class FieldOfInterestConditionComponent implements OnInit {
  @Input('casesCtrl') casesCtrl;

  constructor(
    public validationService: ValidationService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {}

  /**
   * Description: Retrieve group of field name of a field of interest
   * to print in HTML
   * @returns group of field name
   */
  getGroupOfFieldName() {
    const fieldOfInterestId = this.casesCtrl.get('fieldofinterestid').value;
    const groupOfFields = this.validationService.getGroupOfFields();
    const groupOfField =
      this.validationService.getGroupOfFieldFromFieldOfInterest(
        fieldOfInterestId,
        groupOfFields
      );
    return groupOfField ? groupOfField.groupoffieldsname : '';
  }
}
