import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Reason } from 'src/app/models/close-reason.model';

@Component({
  selector: 'app-confirm-change-document-modal',
  templateUrl: './confirm-change-document-modal.component.html',
  styleUrls: ['./confirm-change-document-modal.component.scss']
})
export class ConfirmChangeDocumentModalComponent implements OnInit {
  @Input() options: any;

  public splittedDoc: boolean;
  public currentDocNewDatamodel: string;
  public currentDocNewPages: string;
  public splittedDocDatamodel: string;
  public splittedDocPages: string;

  public reason = Reason;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.splittedDoc = this.options.splittedDoc;
    this.currentDocNewDatamodel = this.options.currentDocNewDatamodel;
    this.currentDocNewPages = this.options.currentDocNewPages;
    this.splittedDocDatamodel = this.options.splittedDocDatamodel;
    this.splittedDocPages = this.options.splittedDocPages;
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }

  onDismiss(reason: Reason) {
    this.activeModal.dismiss(reason);
  }
}
