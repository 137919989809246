import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { DocumentsTableComponent } from 'src/app/components/documents-table/documents-table.component';

@Component({
  selector: 'app-validations-document-list',
  templateUrl: './validations-document-list.component.html',
  styleUrls: ['./validations-document-list.component.scss']
})
export class ValidationsDocumentListComponent implements OnInit {
  @Input() assigned: boolean = false;
  @Input() enableMedicFeatures: boolean = false;
  @Input() showFilterOnInit: boolean = false;

  @Output() selfassigned = new EventEmitter();

  @ViewChild(DocumentsTableComponent)
  private documentsTable: DocumentsTableComponent;

  public params: {} = [];

  constructor() {}

  ngOnInit() {
    this.params = { selfassigned: this.selfassigned, assigned: this.assigned };
  }

  public selfAssign() {
    this.documentsTable.getDocumentsFromDB();
    this.documentsTable.onBackgroundLoadingChange(true);
  }

  public ifAssignedTable() {
    return this.assigned
      ? 'documentvalidationsassigned'
      : 'documentvalidations';
  }
}
