import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { DateformatterPipe } from './dateformatter.pipe';
import { MomentPipe } from './moment.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { TrimDecimalPipe } from './decimal.pipe';
import { QueryTextHighlightPipe } from './query-text-highlight.pipe';
import { SortAiResultPipe } from './sort-ai-result.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe,
    QueryTextHighlightPipe,
    SortAiResultPipe
  ],
  exports: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe,
    QueryTextHighlightPipe,
    SortAiResultPipe
  ]
})
export class PipeModule {}
