import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TrainableService {
  constructor(private modalService: NgbModal) {}

  public getFormTrainable(
    form: object,
    newMode: boolean,
    datamodelname?: string,
    lang?: number,
    maxReviewDays?: number
  ): FormGroup {
    const detecttable = true;
    const multipletable = false;
    const multiplelineheaders = false;
    const multiplelinecells = false;
    let datamodeldisplayname = '';
    let langid = null;
    let localMaxReviewDays = null;
    const hlineseparator = null;
    const groupOfFields = new FormArray([]);

    if (!newMode) {
      datamodeldisplayname = datamodelname;
      langid = lang;
      localMaxReviewDays = maxReviewDays;
      console.log(groupOfFields);
      this.getGroupOfFields(form, groupOfFields);
    } else {
      this.addNewGroupOfField(groupOfFields);
    }

    const fromTrainable = new FormGroup({
      datamodeldisplayname: new FormControl(
        datamodeldisplayname,
        Validators.required
      ),
      humanloop: new FormControl(true),
      detecttable: new FormControl(detecttable),
      multipletable: new FormControl(multipletable),
      multiplelineheaders: new FormControl(multiplelineheaders),
      multiplelinecells: new FormControl(multiplelinecells),
      hlineseparator: new FormControl(hlineseparator),
      groupoffields: groupOfFields,
      langid: new FormControl(langid),
      maxreviewdays: new FormControl(localMaxReviewDays, Validators.min(1))
    });
    return fromTrainable;
  }

  public addNewGroupOfField(arr_gof) {
    arr_gof.push(
      new FormGroup({
        groupoffieldsid: new FormControl(''),
        groupoffieldsname: new FormControl('', Validators.required),
        groupoffieldstypeid: new FormControl(null, Validators.required)
      })
    );
  }

  public getGroupOfFields(form, listForm) {
    const arr_gof = form['information_extractor']['group_of_fields'];
    if (arr_gof) {
      for (const gof of arr_gof) {
        listForm.push(
          new FormGroup({
            groupoffieldsid: new FormControl(gof['groupoffieldsid']),
            groupoffieldsname: new FormControl(gof['groupoffieldsname']),
            groupoffieldstypeid: new FormControl(gof['groupoffieldstypeid']),
            createdbyuser: new FormControl(gof['createdbyuser']),
            fieldsofinterest: this.getFieldOfInterest(gof)
          })
        );
      }
    }
  }

  public getStringsArray(form, key) {
    const formArray = form[key];
    const newArray = new FormArray([]);
    Object.keys(formArray).forEach(key => {
      for (const str of formArray[key]) {
        newArray.push(new FormControl(str));
      }
    });
    return newArray;
  }

  public getStringsArrayHeader(form, key) {
    const formArray = form[key];
    const newArray = new FormArray([]);
    for (const str of formArray) {
      newArray.push(new FormControl(str));
    }
    return newArray;
  }

  public removeInputControl(form, i) {
    if (form.length > 1) {
      form.removeAt(i);
    }
  }

  public deleteFromList(form, i, type) {
    if (form.length > 1) {
      this.deleteModal(form, i, type);
    }
  }

  public deleteFromCheckList(form, i, type) {
    this.deleteModal(form, i, type);
  }

  /**
   * Delete Modal for delete case, condition list or conditions
   * @param parent  where we gonna delete a case or a condition list
   * @param index index to delete
   * @param message variable that what we are gonna delete
   * @returns Form without case, condition list or condition
   */
  private deleteModal(parent, index, message) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: message,
      notAllowed: false
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          parent.removeAt(index);
        }
      },
      reason => {}
    );
  }

  /**
   * Add new empty case to Form
   */
  public addGroupOfField(form) {
    const arr_gof = form.get('groupoffields') as FormArray;
    this.addNewGroupOfField(arr_gof);
  }

  public addField(form, type) {
    // TODO: Pending to uses constants inside the app.
    switch (type.toString()) {
      // Individual case
      case '1':
        this.addNewFieldIndividual(form);
        break;
      // Table case
      case '2':
        this.addNewFieldTable(form);
        break;
      // Paragraph case
      case '3':
        this.addNewFieldParagraph(form);
        break;
      // Entity case
      case '4':
        this.addNewFieldEntity(form);
        break;
      // Default: Individual case
      default:
        this.addNewFieldIndividual(form);
        break;
    }
  }

  public getFieldOfInterest(gof) {
    const arr_fields = gof['fieldsofinterest'];
    const formArray = new FormArray([]);
    let fof: any;
    for (const field of arr_fields) {
      //TODO: Use switch options from constants for different group of fields type
      switch (gof.groupoffieldstypeid.toString()) {
        // Individual case
        case '1':
          fof = this.getFieldOfInterestIndividual(field);
          break;
        // Table case
        case '2':
          fof = this.getFieldOfInterestTable(field);
          break;
        // Paragraph case
        case '3':
          fof = this.getFieldOfInterestParagraph(field);
          break;
        // Entity case
        case '4':
          fof = this.getFieldOfInterestEntity(field);
          break;
        // Default: Individual case
        default:
          fof = this.getFieldOfInterestIndividual(field);
          break;
      }
      formArray.push(fof);
    }
    return formArray;
  }

  public getFieldOfInterestTable(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      istrainable: new FormControl(field['istrainable']),
      headers: this.getStringsArrayHeader(field, 'headers'),
      headers_algorithm: this.getStringsArrayHeader(field, 'headers_algorithm'),
      not_headers: this.getStringsArrayHeader(field, 'not_headers'),
      createdbyuser: new FormControl(field['createdbyuser'])
    });
  }

  public getFieldOfInterestParagraph(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser'])
    });
  }

  public getFieldOfInterestEntity(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser'])
    });
  }

  public getFieldOfInterestIndividual(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser'])
    });
  }

  public addNewFieldTable(arr) {
    const headers = new FormArray([]);
    const notHeaders = new FormArray([]);
    const headerAlgorithm = new FormArray([]);
    this.addNewInputNameControl(headers);
    this.addNewInputNameControl(notHeaders);
    this.addNewInputNameControl(headerAlgorithm);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl('', Validators.required),
        headers: headers,
        not_headers: notHeaders,
        headers_algorithm: headerAlgorithm,
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', Validators.required)
      }),
      Validators.required
    );
  }

  public addNewFieldParagraph(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl('', Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ])
      })
    );
  }

  public addNewFieldEntity(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl('', Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ])
      })
    );
  }

  public addNewFieldIndividual(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl('', Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ])
      })
    );
  }

  public addControlKeyTable(form) {
    const fields = new FormArray([]);
    this.addNewFieldTable(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addNewInputNameControl(arr, validator?) {
    if (validator) {
      const control = new FormControl('', Validators.required);
      control.markAsTouched();
      arr.push(control);
    } else {
      arr.push(new FormControl(''));
    }
  }

  public addControlIndividual(form) {
    const fields = new FormArray([]);
    this.addNewFieldIndividual(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addControlParagraph(form) {
    const fields = new FormArray([]);
    this.addNewFieldParagraph(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addControlEntity(form) {
    const fields = new FormArray([]);
    this.addNewFieldEntity(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public deleteControlKey(form, value) {
    if (form.get(value)) {
      form.removeControl(value);
    }
  }
}
