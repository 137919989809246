import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { DocumentEvidencesService } from 'src/app/components/document-evidences-table/document-evidences-table.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-validation-override',
  templateUrl: './validation-override.component.html',
  styleUrls: ['./validation-override.component.scss']
})
export class ValidationOverrideComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() permission: any;
  @Input() documentDetails: any;
  @Output() discountFailure = new EventEmitter();
  @Output() updateTimeline = new EventEmitter();
  @Output() changePermComment = new EventEmitter();

  private user: any = {};
  private approval: any = {};
  public hasPerm: boolean;
  public documentId;
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;

  public showComments: boolean = false;
  public isLoadingComment: boolean = false;
  public notAllowOverride: boolean = false;
  public file: File;
  public _validationStatus: any;
  public _validationTypes: any;

  FILE_FORMATS =
    '.csv,image/gif,image/jpeg,image/jpeg,image/jpeg,image/jpeg,image/jpeg,image/png,image/tiff,image/x-tiff,image/tiff,image/x-tiff,image/png,.pdf,.msg,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr';
  constructor(
    private apiService: ApiService,
    public fileDownloadService: FileDownloadService,
    private authService: AuthenticationService,
    private globalService: GlobalService,
    private evidencesService: DocumentEvidencesService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();

    this._validationStatus = this.globalService.getValidationStatusConst();
    this._validationTypes = this.globalService.getValidationTypesConst();
  }

  ngOnInit() {
    this.isNotAllowOverride();
    this.getReviewersTypes();
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
          this.hasPermission();
        });
    } else {
      this.setApproval();
      this.hasPermission();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Check if user is the assigned reviewer or admin
   */
  private hasPermission() {
    this.hasPerm = this.approvalIsAssignedToUser() || this.user.isadmin;
  }

  /**
   * Switch between open/close comment
   */
  public toggleComments() {
    this.showComments = !this.showComments;
  }

  /**
   * Gets the title for the override button in the template.
   */
  public getOverrideButtonTitle(): string {
    if (!this.approvalIsAssignedToUser() || this.approval.recommended) {
      return this.translate.transform('documentDetails.onlyAssigned');
    } else if (this.notAllowOverride) {
      return this.translate.transform(
        'validation.documentValidation.notAllowOverride'
      );
    } else {
      return this.translate.transform(
        'validation.documentValidation.overrideTest'
      );
    }
  }

  /**
   * Functions to construct the form data to pass
   * as param in the post request
   * @param form html form data
   */
  public getFormData(form) {
    const array = this.item.children
      ? this.item.children.map(c => c.validationexecutionconditionsid)
      : [this.item.validationexecutionconditionsid];
    const params = new FormData();
    params.append('commentdescription', form.textComment);
    params.append('validationexecutionconditionsids', array.join(','));

    //If the validation is extraction, the api needs to pass the documentId
    if (this.item.documentId) {
      params.append('documentid', this.item.documentId);
    }

    //If there is a file attached to this comment
    if (this.file) {
      const file = this.file;
      params.append('files', file, file.name);
    }
    return params;
  }

  /**
   * Fuction for creatre a comment of each validation execution condition
   * related to the item (extracted field)
   * @param form
   * @param item
   */
  public onCreateComment(form) {
    this.isLoadingComment = true;
    this.changePermComment.emit();
    const params = this.getFormData(form);
    //The last param to the post is needed for the form-data encode params
    this.apiService.post('comments', params, '', false).subscribe(
      data => {
        this.discountFailure.emit();
        this.updateTimeline.emit();
        this.isLoadingComment = false;
        this.evidencesService.getEvidencesFromDB();
        this.changePermComment.emit();
      },
      () => {
        this.isLoadingComment = false;
        this.evidencesService.getEvidencesFromDB();
        this.changePermComment.emit();
      }
    );
  }

  /**
   * If the validation is Extraction or Business Rules and they are not
   * executed with the last version of the validation
   * or if the current validation is Business Rules and
   * It is executed with the last version but the extraction validation is not,
   * the user will not be able to do an override.
   */
  public isNotAllowOverride() {
    if (
      ((this.item.validationtypename === this._validationTypes.EXTRACTION ||
        this.item.validationtypename ===
          this._validationTypes.BUSINESS_RULES) &&
        !this.item.islastversion) ||
      (this.item.validationtypename === this._validationTypes.BUSINESS_RULES &&
        !this.item.isExtractionlastVersion)
    ) {
      this.notAllowOverride = true;
    }
  }

  /**
   * Function to save a document for upload
   * when the user press the save button
   * of the comment
   * @param event the input file
   */
  public onChangeFile(event: any) {
    this.file = <File>event.target.files[0];
  }

  /**
   * Function that delete the document
   * of the file input for the extracted field
   */
  public deleteFile() {
    this.file = null;
  }

  public downloadFile(item) {
    const url = `download/evidences/${item.fileAttached.commentattachmentid}/`;
    const filename = item.fileAttached.attachmentdisplayname;
    this.fileDownloadService.downloadExternalFile(
      url,
      item.fileAttached,
      filename
    );
  }
}
