import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-analysis',
  templateUrl: './select-analysis.component.html',
  styleUrls: ['./select-analysis.component.scss']
})
export class SelectAnalysisComponent implements OnInit {

  @Output() setSelectedAnalysis = new EventEmitter();
  @Input() selectedAnalysis = [];
  @Input() data: { data: []} = null;
  @Input() setting;
  public columns: Array<any> = [];
  public tableSettings: any;
  
  constructor() { }

  ngOnInit(): void {
    this.getTableSettings();
    this.getColumns();
    const selectedAnalysisIds = []
    if(this.data && this.data.data && Array.isArray(this.data.data) && this.data.data.length == Number(1)){
      const analysisList = this.data.data;
      selectedAnalysisIds.push(analysisList[analysisList.length-1]['analysisid']);
      this.setSelectedAnalysis.emit(selectedAnalysisIds);
      this.data.data.forEach((obj: any) => {
        obj['isRowSelected'] = true
      })
    } else if( this.selectedAnalysis.length > 0){
      this.data.data.forEach((analyses: any) => {
        if (this.selectedAnalysis.indexOf(analyses.analysisid) > -1) {
          analyses.isRowSelected = true;
        }
      })
      this.setSelectedAnalysis.emit(this.selectedAnalysis);
      this.tableSettings.allRowsSelected = (this.data?.data?.length == this.selectedAnalysis.length);
    }
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'createExploration.tableColumns.analysis',
        name: 'analysisname',
        orderBy: 'analysisname'
      },
      {
        header: 'createExploration.tableColumns.project',
        name: 'project',
        second: 'projectname'
      },
      {
        header: 'createExploration.tableColumns.createdBy',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'createExploration.tableColumns.date',
        name: 'createddate',
        orderBy: 'createddate',
        type: 'date',
        format: 'll'
      },
      {
        header: 'createExploration.tableColumns.documents',
        name: 'numberofdocuments'
      }
    ];
  }

  public getTableSettings(): void {
    const analysisList = this.data?.data;
    const allRowSelectedFlag = Array.isArray(analysisList) && analysisList.length === Number(1) ? true : false;
    this.tableSettings = {
      dataId: 'analysisid',
      hasSelect: 'false',
      hasEditPermissions: false,
      hasDownloadOptions: false,
      getDataFromDB: this.setting['getAnalysisFromDB'],
      hasRowSelectOptions: true,
      onRowSelection: this.selectAnalysis.bind(this),
      hidePagination: true,
      allRowsSelected: (analysisList?.length == this.selectedAnalysis?.length || allRowSelectedFlag),
      responsiveTitle: {
        label: 'analysisname'
      }
    };
  }

  public selectAnalysis(analysis, areAllRowsSelected): void {
    if (areAllRowsSelected) {
      this.tableSettings.allRowsSelected = !this.tableSettings.allRowsSelected;
      const selectedAnalysisIds = analysis.data.map(item => {
        item.isRowSelected = this.tableSettings.allRowsSelected;
        return item.analysisid;
      });
      this.setSelectedAnalysis.emit(this.tableSettings.allRowsSelected ? selectedAnalysisIds : []);
    } else {
      const selectedAnalysisIds = this.selectedAnalysis;
      analysis.isRowSelected = !analysis.isRowSelected;
      if (!analysis.isRowSelected) {
        // If any row gets unselected, make allRowsSelected flag false
        this.tableSettings.allRowsSelected = false;
        const removedAnalysisIndex = selectedAnalysisIds.indexOf(analysis.analysisid);
        selectedAnalysisIds.splice(removedAnalysisIndex, 1);
      } else {
        selectedAnalysisIds.push(analysis.analysisid);
        this.tableSettings.allRowsSelected = (this.data?.data?.length == this.selectedAnalysis.length);
      }
      this.setSelectedAnalysis.emit(selectedAnalysisIds);
    }
  }

}
