import { Component, OnInit, OnDestroy, DoCheck, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import { AlertService } from './services/alert.service';
import { Alert } from './models/alert.model';
import { AuthenticationService } from './security/authentication.service';
import { StyleManagerService } from './services/stylemanager.service';
import { Themes } from './theme/theme-components/themes';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public showLoginLoader: boolean = false;

  private _user: any;
  private get user(): any {
    return this._user;
  }
  private set user(value: any) {
    this._user = value;
    this.showLoginLoader = value == null;
  }

  private themeChangeSubscription: Subscription;
  private currentTheme: string;
  private userActivity;
  private userInactive: Subject<any> = new Subject();
  public debounceTimerValue;
  constructor(
    private router: Router,
    private alertService: AlertService,
    public authService: AuthenticationService,
    private styleManager: StyleManagerService
  ) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.authService.logout();
    });  
  }
  public debounce(callback, delay) {
    return () => {
      clearTimeout(this.debounceTimerValue)
      this.debounceTimerValue = setTimeout(() => {
        callback();
      }, delay)
    }
  }

  public setTimeout(): any {
    this.userActivity = setTimeout(() => {
      if (this.authService.getLoggedInUser()) {
        this.userInactive.next(undefined);
      }
    }, 1800000);
  }
  @HostListener('window:mousemove') refreshUserState() {
    const debouncedFun = this.debounce(()=>{
      clearTimeout(this.userActivity);
      this.setTimeout();
    }, 1000)
    debouncedFun()
  }

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.subscription = this.authService.userLoggedIn.subscribe(user => {
      this.user = user;
    });

    this.themeChangeSubscription = this.authService
      .watchTheme()
      .subscribe(() => {
        this.updateTheme();
      });
    this.updateTheme();
  }

  updateTheme(): void {
    const theme = this.authService.getTheme();
    if (theme !== undefined && this.currentTheme != theme) {
      this.currentTheme = theme;
      this.styleManager.setTheme(this.currentTheme);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.themeChangeSubscription?.unsubscribe();
  }
}
