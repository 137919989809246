import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { AppRouting } from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeComponentModule } from './theme/theme-components/ThemeComponentModule';
import { ComponentWsModule } from './components/component-ws.module';
import { TranslateModule } from './translate/translate.module';
import { TranslateService } from './services/translate.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { AlertService } from './services/alert.service';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './security/authentication.service';
import { AppSettings } from './app.settings';
import { NewUserModel } from './models/new-user.model';
import { AclDirective } from './security/acl.directive';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { LoginComponent } from './pages/login/login.pages';
import { ModulesPermissionsGuard } from './security/modules-permissions-guard.service';
import { PBIService } from './services/pbi.service';
import { MsalModule, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalService, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { msalInterceptorConfig, msalGuardConfig, msalInstanceConfig } from './security/utils/msal.config';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, AclDirective, LoginComponent],
  imports: [
    MsalModule.forRoot(msalInstanceConfig(), msalGuardConfig(), msalInterceptorConfig()),
    CommonModule,
    BrowserAnimationsModule,
    PagesModule,
    NgbModule,
    HttpClientModule,
    ThemeComponentModule,
    ComponentWsModule,
    TranslateModule,
    AppRouting,
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceConfig
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HttpClientModule,
    TranslateService,
    TranslatePipe,
    TitleCasePipe,
    AlertService,
    ApiService,
    AuthenticationService,
    ModulesPermissionsGuard,
    AppSettings,
    NewUserModel,
    PBIService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
