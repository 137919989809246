import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ValidationService } from 'src/app/pages/datamodels/datamodel-validation-list/validation/new-validation/common-components/validation.service';
import { CatalogService } from '../../../../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  selector: 'app-catalog-type',
  templateUrl: './catalog-type.component.html',
  styleUrls: ['./catalog-type.component.scss']
})
export class CatalogTypeComponent implements OnInit {
  @Input('control') control: FormGroup;
  @Input('index') index: FormGroup;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  private fieldsOfInterest;
  private groupOfFieldName;
  public ter: boolean = false;
  public routeSub: Subscription;
  public hasEnrichments: boolean;
  private datamodelId: number;
  public validationEnrichments: any[];
  public datamodel: any;
  private disableWhenTer: any[] = ['gt', 'gte', 'lt', 'lte', 'contains'];

  constructor(
    public validationService: ValidationService,
    public catalogService: CatalogService,
    public global: GlobalService,
    public authService: AuthenticationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.datamodelHasEnrichments();
    let datamodel_populated = this.control
      .get('operations')
      ['controls'][0].get('datamodelid');
    if (datamodel_populated) {
      datamodel_populated = datamodel_populated.value;
      this.populateFieldOfInterest(datamodel_populated);
    }

    this.control.get('operationScope').valueChanges.subscribe(value => {
      this.onChangeScope(value);
    });

    if (this.control.get('rounding')) {
      this.control.get('rounding').valueChanges.subscribe(value => {
        this.addOrRemovePrecisionControl(value);
      });
    }

    this.control.get('toleranceerrorrate').valueChanges.subscribe(ter => {
      if (ter) {
        for (const ctrl in this.control.get('operations')['controls']) {
          if (
            this.control.get('operations').get(`${ctrl}`).get('operator') &&
            this.disableWhenTer.includes(
              this.control.get('operations').get(`${ctrl}`).get('operator')
                .value
            )
          ) {
            this.control
              .get('operations')
              .get(`${ctrl}`)
              .get('operator')
              .setValue('');
            this.control
              .get('operations')
              .get(`${ctrl}`)
              .get('operator')
              .setErrors(Validators.required);
          }
        }
      }
    });
    if (this.control.get('operationScope').value === 'enrichment') {
      this.removeRequiredValidators();
    }
    this.control.get('operationScope').valueChanges.subscribe(scope => {
      scope === 'enrichment'
        ? this.removeRequiredValidators()
        : this.addRequiredValidators();
    });
  }

  /**
   * This function evaluates if the datamodel has enrichments
   */
   public datamodelHasEnrichments() {

    this.datamodelId = this.getDatamodelSelected()
    this.datamodel = this.global.getDataModelById(this.datamodelId);
    this.hasEnrichments =
      this.authService.userCanViewModule(
        this.authService.getLoggedInUser(),
        'EnrichmentCoreFunctionality'
      ) &&
      this.datamodel.enrichmenttypes.length > 0 &&
      this.global
        .getEnrichmentTypes()
        .filter(enrichment => enrichment.enrichment_category > 0).length > 0;
    if (this.hasEnrichments) {
      this.validationEnrichments = this.global
        .getEnrichmentTypes()
        .filter(enrichment => enrichment.enrichment_category > 0);
    }
  }

  public removeRequiredValidators() {
    this.control.addControl(
      'enrichments_type',
      new FormControl('', Validators.required)
    );
    this.control.get('rounding').clearValidators();
    this.control.get('rounding').updateValueAndValidity();
    this.control.get('operations').get('0').get('type').clearValidators();
    this.control
      .get('operations')
      .get('0')
      .get('type')
      .updateValueAndValidity();
    this.control.get('operations').get('1').get('operator').clearValidators();
    this.control
      .get('operations')
      .get('1')
      .get('operator')
      .updateValueAndValidity();
    this.control.get('operations').get('1').get('operator').setValue('end');
  }

  public addRequiredValidators() {
    this.control.removeControl('enrichments_type');
  }

  /**
   * Get operations FormGroups keys (ex: ['0', '2', '4', ...])
   */
  private getFormGroupKeys() {
    return Object.keys(this.control.get('operations')['controls']).filter(
      key => !(+key % 2)
    );
  }

  /**
   * Get Catalog Datamodels
   */
  getDatamodels() {
    return this.catalogService.getCatalogDatamodels();
  }

  /**
   * Gets fields of interest of a datamodel
   * @param datamodelid datamodel id
   */
  getFieldsOfInterest(datamodelid: number) {
    const dataDataModel = this.global.getDataModelById(+datamodelid);
    this.fieldsOfInterest = dataDataModel.groupoffields;
  }

  /**
   * Get the datamodel selected
   * @returns 
   * 
   */

  getDatamodelSelected(){
    let datamodelid = 0;

    this.datamodel_list.forEach( (datamodel) => {
      
      if(datamodel.datamodeltrigger){
        datamodelid = datamodel.id;
      }
      
     });
   
    return datamodelid
  }

  

  /**
   * Check if datamodels controls are filled
   */
  public isDatamodelLinksFilled() {
    return (
      this.control.get('datamodelLinks').value.length ===
        this.getDatamodels().length &&
      this.control.get('datamodelLinks')['controls'][0].valid &&
      this.control.get('datamodelLinks')['controls'][1].valid
    );
  }

  /**
   * Get fields of interest when edit and set group of field name
   * @param datamodelid datamodelid to look for its fields of interest
   */
  populateFieldOfInterest(datamodelid: number) {
    this.getFieldsOfInterest(datamodelid);
    this.getGroupOfFieldName();
  }

  /**
   * Retrieve group of field name of a field of interest to print in HTML
   */
  getGroupOfFieldName() {
    const fieldOfInterestId =
      this.control.get('operations')['controls'][0].value.fieldofinterestid;
    let groupOfFieldName = '';
    if (fieldOfInterestId) {
      let groupOfField =
        this.validationService.getGroupOfFieldFromFieldOfInterest(
          fieldOfInterestId,
          this.fieldsOfInterest
        );
      if (groupOfField) {
        groupOfFieldName = groupOfField.groupoffieldsname;
      }
    }
    this.groupOfFieldName = groupOfFieldName;
  }

  /**
   * Get group of fields of datamodel for scope
   * @param i Datamodel FormGroup index
   */
  getGroupOfFieldsOfDatamodel(i: number) {
    const datamodelid = this.control
      .get('datamodelLinks')
      ['controls'][i].get('id').value;
    const scope = this.control.get('operationScope').value;
    const datamodel = this.global.getDataModelById(+datamodelid);
    return datamodel && datamodel.groupoffields
      ? [...datamodel.groupoffields]
      : [];
  }

  /**
   * When scope change update datamodel and fieldofinteres form controls
   * @param value
   * @param indexDatamodel
   */
  onChangeScope(value: string) {
    const datamodelidAndScope = this.control.get('datamodelLinks') as FormArray;
    const datamodels = this.getDatamodels();

    datamodels.forEach((datamodel, i) => {
      if (!datamodelidAndScope['controls'][i]) {
        // Control doesn't exist yet -> create
        const control = new FormGroup({
          id: new FormControl({
            value: `${datamodel.datamodelid}`,
            disabled: true
          })
        });
        if (this.control.get('operationScope').value === 'line') {
          control.addControl(
            'fieldofinterestid',
            new FormControl('', Validators.required)
          );
          this.validationService.touchControl(control, 'fieldofinterestid');
        }
        datamodelidAndScope.push(control);
      } else {
        // Control already exists -> remove fieldofinterestid if scope isn't line
        const control = this.control.get('datamodelLinks')['controls'][i];

        if (this.control.get('operationScope').value === 'line') {
          if (!control.get('fieldofinterestid')) {
            control.addControl(
              'fieldofinterestid',
              new FormControl('', Validators.required)
            );
            this.validationService.touchControl(control, 'fieldofinterestid');
          }
        } else if (control.get('fieldofinterestid')) {
          control.removeControl('fieldofinterestid');
        }
      }
    });

    if (this.control.get('operations')) {
      Object.keys(this.control.get('operations')['controls'])
        .filter(key => !(+key % 2))
        .forEach(key => {
          const control = this.control.get('operations')['controls'][key];
          if (control.get('fieldofinterestid')) {
            control.get('fieldofinterestid').setValue('');
          }
          if (control.get('aggscope')) {
            control.get('aggscope').setValue('');
          }
          if (control.get('agg')) {
            control.get('agg').setValue('');
          }
        });
    }
  }

  /**
   * Add or remove precision form control depending on rounding type
   * @param rounding roundingt type
   */
  public addOrRemovePrecisionControl(rounding: string) {
    if (rounding === 'regular' && !this.control.get('precision')) {
      this.control.addControl(
        'precision',
        new FormControl('', Validators.required)
      );
      this.validationService.touchControl(this.control, 'precision');
    }
    if (rounding !== 'regular' && this.control.get('precision')) {
      this.control.removeControl('precision');
    }
  }

  public changeToleranceErrorRateCheckbox(flag: boolean) {
    if (!flag) {
      this.control.get('toleranceerrorratevalue').setValue(0);
    }
    this.ter = flag;
  }
}




