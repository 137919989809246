import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-submit-filter-modal',
  templateUrl: './submit-filter-modal.component.html',
})
export class SubmitFilterModalComponent {

  constructor(
    public activeModal: NgbActiveModal,

  ) {}
  
}
