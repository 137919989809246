import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queryTextHighlight'
})
export class QueryTextHighlightPipe implements PipeTransform {

  public replaceAt(str, index, replacement, replacementLength) {
    return str.substring(0, index) + replacement + str.substring(index + replacementLength);
  }

  public escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  transform(value: string, args: any): any {
    const robertaModel = args.robertaModel;
    const manualfeedback = args.manualfeedback;

    // for manual feedback highlight the sementic
    if (manualfeedback) {
      let manualFeedbacktext = manualfeedback.text.replace(/([<>*()?])/g, "\\$1")
      const matchingString = new RegExp(manualFeedbacktext, 'gi');
      //const finalValue = value.replace((matchingString), match => `<span class="highlight-manualFeedback">${match}</span>`);
      let finalValue = ''
      if (value.indexOf('\n') > -1 && (!manualfeedback.boundingBox.manualAnswerStartIndex && manualfeedback.boundingBox.manualAnswerStartIndex !== 0)) {
        // don not remove /n if its excel file
        value = this.replaceAll(value, '\n', '')
      }

      // Incase of excel files, we have manualAnswerStartIndex for answer feedback selection, so if thats present we are using that
      if (manualfeedback.boundingBox.startIndex > -1 || (manualfeedback.boundingBox.manualAnswerStartIndex != null && manualfeedback.boundingBox.manualAnswerStartIndex > -1)) {
        const startIndex = (manualfeedback.boundingBox.manualAnswerStartIndex > -1) ? manualfeedback.boundingBox.manualAnswerStartIndex : (manualfeedback.boundingBox.startIndex - 1);
        finalValue = this.replaceAt(value, startIndex, `<span class="highlight-manualFeedback">${manualFeedbacktext}</span>`, manualFeedbacktext.length)
      } else {
        finalValue = value.replace((matchingString), match => `<span class="highlight-manualFeedback">${match}</span>`);
      }
      return finalValue;
    }

    // for AI feedback highlight the sementic
    else if(robertaModel && robertaModel.startIndex >= 0 && robertaModel.endIndex > 0) {
      const start = robertaModel.startIndex;
      const end = robertaModel.endIndex;
      const matchingString = value.slice(start, end);
      const finalValue = value.replace(matchingString, "<span class='highlight-aiSuggestions'>" + matchingString + "</span>");
      return finalValue;
    } else {
      return value;
    }
  }

}
