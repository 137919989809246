import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { concat, Subscription } from 'rxjs';

import { Reason } from '../../../models/close-reason.model';
import { GlobalService } from '../../../services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { PagesSelection, PagesSelectionStatus } from './pages-selection';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfirmChangeDocumentModalComponent } from '../confirm-change-document-modal/confirm-change-document-modal.component';

/**
 * Information about document initial state
 * and changes to be applied to it.
 */
class DocumentChangeInfo {
  initialDatamodelValue: string;
  initialPages: number[];

  newDatamodelValue: string;
  newPages: number[];

  splittedDocDatamodelValue: string;
  splittedDocPages: number[];
}

@Component({
  selector: 'app-update-children',
  templateUrl: './update-children.pages.html',
  styleUrls: ['./update-children.pages.scss']
})
export class UpdateChildrenPages implements OnInit, OnDestroy {
  public readonly SELECT_ALL_PAGES = '0';
  public readonly CUSTOM_PAGE_SELECTION = '1';

  public static readonly MODE_EDIT_CHILDREN = 'children';
  public static readonly MODE_EDIT_DOCUMENT = 'edit-doc';
  public static readonly MODE_SPLIT_DOCUMENT = 'split-doc';

  public uniqueId: string = String(
    Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
  );

  @Input() mode: string = UpdateChildrenPages.MODE_EDIT_CHILDREN;
  @Input() children: any;
  @Input() parent: any;
  @Input() childrenIndex: any;
  @Input() parentPages: number[];

  @Output() createdPagesSelection = new EventEmitter<PagesSelection>();
  @Output() cancel = new EventEmitter<void>();
  @Output() updateChildDocs: EventEmitter<any> = new EventEmitter();
  @Output() saveDocument: EventEmitter<any> = new EventEmitter();

  public options = true;
  public dataModelList: any = [];
  public reason = Reason;
  public loading: boolean;
  public saving: boolean;
  private subscriptionDataModels: Subscription;
  public selectpagesmode: string;
  private pagesSelection: PagesSelection;
  private pagesSelectionStringSubscription: Subscription;
  private pagesSelectionStatusSubscription: Subscription;
  public selection: string;
  private savedSelection: string;
  public selectionStatus: PagesSelectionStatus;
  public selectionErrorStateMatcher: SelectionErrorStateMatcher;
  public projectForm: FormGroup;
  public datamodelValue = '';
  public documentValue = '';
  private documentChangeInfo: DocumentChangeInfo; // only created when editing & splitting document
  public showCancelButton: boolean = false;

  public new = false;

  constructor(
    private globalService: GlobalService,
    private translate: TranslatePipe,
    private alertService: AlertService,
    public apiService: ApiService,
    private modalService: NgbModal,
    private utils: UtilsService
  ) {
    this.selectionErrorStateMatcher = new SelectionErrorStateMatcher(this);

    this.loading = true;
  }

  ngOnInit() {
    switch (this.mode) {
      case UpdateChildrenPages.MODE_EDIT_CHILDREN:
        this.selectpagesmode = this.SELECT_ALL_PAGES;

        this.new = !this.children.datamodel;
        if (!this.new) {
          this.datamodelValue = this.children.datamodel.datamodelid;
          this.documentValue = this.children.subdocumentdisplayname;
        } else {
          this.documentValue = this.parent.documentdisplayname;
        }
        break;

      case UpdateChildrenPages.MODE_EDIT_DOCUMENT:
        this.documentChangeInfo = new DocumentChangeInfo();

        this.datamodelValue = this.parent.datamodel.datamodelid;
        this.documentChangeInfo.initialDatamodelValue = this.datamodelValue;

        this.selectpagesmode = this.SELECT_ALL_PAGES;
        this.setupPagesSelection();

        this.showCancelButton = true;
        break;

      case UpdateChildrenPages.MODE_SPLIT_DOCUMENT:
        this.documentChangeInfo = new DocumentChangeInfo();

        this.documentChangeInfo.initialDatamodelValue =
          this.parent.datamodel.datamodelid;

        this.selectpagesmode = this.CUSTOM_PAGE_SELECTION;
        this.setupPagesSelection();

        this.showCancelButton = true;
        break;
    }

    this.getDropDownSources();
  }

  /**
   * fetch datamodel and languages list
   */
  public getDropDownSources() {
    this.dataModelList = Array.from(
      this.globalService.getDatamodels().values()
    );

    if (
      this.dataModelList.length === 0 &&
      !this.globalService.passedWatcherDatamodels
    ) {
      this.subscriptionDataModels = concat(
        this.globalService.watchDataModels(),
        this.globalService.watchUtils()
      ).subscribe((data: string) => {
        this.dataModelList = Array.from(
          this.globalService.getDatamodels().values()
        );

        this.loading = false;
      });
    } else if (
      this.dataModelList.length === 0 &&
      !this.globalService.passedWatcherDatamodels
    ) {
      this.subscriptionDataModels = this.globalService
        .watchDataModels()
        .subscribe((data: string) => {
          this.dataModelList = Array.from(
            this.globalService.getDatamodels().values()
          );
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  /**
   * Get numbers of selected pages from children input.
   */
  private getSelectedPagesFromChildren(): number[] {
    if (this.new) {
      return PagesSelection.getChildDocumentPages({});
    } else {
      return PagesSelection.getChildDocumentPages(this.children);
    }
  }

  /**
   * Setup document pages selection.
   */
  public setupPagesSelection(): PagesSelection {
    this.removePagesSelection();

    this.pagesSelection = new PagesSelection();

    this.pagesSelectionStatusSubscription = this.pagesSelection
      .getStatus()
      .subscribe((status: PagesSelectionStatus) => {
        this.onPagesSelectionStatusChange(status);
      });

    this.pagesSelectionStringSubscription = this.pagesSelection
      .getSelectionUpdates()
      .subscribe(() => {
        this.selection = this.pagesSelection.selectionString;

        if (this.mode == UpdateChildrenPages.MODE_SPLIT_DOCUMENT) {
          this.showSplitAllPagesErrorIfAllPagesSelected();

        } else {
          this.selectpagesmode =
            this.pagesSelection.allPagesInSelection ?
              this.SELECT_ALL_PAGES
              : this.CUSTOM_PAGE_SELECTION;
        }
      });

    switch (this.mode) {
      case UpdateChildrenPages.MODE_EDIT_CHILDREN:
        if (this.parentPages) {
          this.pagesSelection.setParentPages(this.parentPages);

          const numPages = this.parentPages.length;
          this.pagesSelection.numPages = numPages;

          this.pagesSelection.selectAll({
            updateSelectionString: true
          });
        } else {
          const numPages =
            this.parent.pages.length ?? this.parent.numpages;

          this.pagesSelection.numPages = numPages;

          const selectedPages = this.getSelectedPagesFromChildren();
          this.pagesSelection.setSelectedPages(selectedPages);
        }
        break;

      case UpdateChildrenPages.MODE_EDIT_DOCUMENT:
      case UpdateChildrenPages.MODE_SPLIT_DOCUMENT:
        const numPages =
          this.parent.pages?.length ?? this.parent.numpages;

        this.pagesSelection.numPages = numPages;

        if (this.mode == UpdateChildrenPages.MODE_EDIT_DOCUMENT) {
          this.pagesSelection.selectAll({
            updateSelectionString: true
          });

          this.documentChangeInfo.initialPages = this.getSelectedPages();
        }
        break;
    }

    this.createdPagesSelection.emit(this.pagesSelection);
    return this.pagesSelection;
  }

  /**
   * Remove document pages selection.
   */
  public removePagesSelection(): void {
    this.pagesSelection = undefined;
    this.selection = '';
    this.savedSelection = undefined;
    this.selectionStatus = undefined;
    this.pagesSelectionStringSubscription?.unsubscribe();
    this.pagesSelectionStatusSubscription?.unsubscribe();
  }

  /**
   * Get datamodel name.
   */
  private getDatamodelName(datamodelValue: string): string {
    return this.dataModelList.find(
      (datamodel: any) => datamodel.datamodelid == datamodelValue
    )?.datamodeldisplayname ?? '';
  }

  /**
   * Get pages as string.
   */
  private getPagesString(pages: number[]): string {
    return PagesSelection.convertPagesArrayToString(pages, true);
  }

  /**
   * Called when pages selection status changes.
   */
  private onPagesSelectionStatusChange(status: PagesSelectionStatus): void {
    this.selectionStatus = status;
  }

  /**
   * Called when selection mode changes.
   */
  public onSelectionModeChanged(): void {
    if (this.pagesSelection) {
      switch (this.selectpagesmode) {
        case this.SELECT_ALL_PAGES:
          if (this.savedSelection === undefined) {
            this.savedSelection = this.selection;
          }

          this.pagesSelection.selectAll({
            updateSelectionString: true
          });
          break;

        case this.CUSTOM_PAGE_SELECTION:
          if (this.savedSelection !== undefined) {
            this.selection = this.savedSelection;
            this.savedSelection = undefined;
          }

          this.pagesSelection.selectionString = this.selection;
          break;
      }
    }
  }

  /**
   * Called when page selection changes.
   */
  public onSelectionChanged(): void {
    if (this.pagesSelection) {
      switch (this.selectpagesmode) {
        case this.CUSTOM_PAGE_SELECTION:
          this.pagesSelection.selectionString = this.selection;

          if (this.mode == UpdateChildrenPages.MODE_SPLIT_DOCUMENT) {
            this.pagesSelection.checkAllPagesSelected();
            this.showSplitAllPagesErrorIfAllPagesSelected();
          }
          break;
      }
    }
  }

  /**
   * Display 'split all pages' error if all pages are selected.
   *
   * Return whether there was an error.
   */
  private showSplitAllPagesErrorIfAllPagesSelected(): boolean {
    if (this.pagesSelection.allPagesInSelection) {
      this.onPagesSelectionStatusChange({
        ok: false,
        errorMessage: 'updateDocs.errors.splitAllPages'
      });
      return true;

    } else {
      return false;
    }
  }

  /**
   * Called when the user clicks on the cancel button.
   */
  onCancel(): void {
    this.cancel.emit();
  }

  /**
   * Called when the user clicks on the submit button.
   */
  public onSubmitForm(form: NgForm) {
    switch (this.mode) {
      case UpdateChildrenPages.MODE_EDIT_CHILDREN:
        this.newChildrenDocs(form);
        break;
      case UpdateChildrenPages.MODE_EDIT_DOCUMENT:
        this.showConfirmEditDocumentModal(form);
        break;
      case UpdateChildrenPages.MODE_SPLIT_DOCUMENT:
        this.showConfirmSplitDocumentModal(form);
        break;
    }
  }

  /**
   * Show modal to confirm document edit.
   */
  private showConfirmEditDocumentModal(form: NgForm) {
    this.documentChangeInfo.newDatamodelValue = this.datamodelValue;
    this.documentChangeInfo.newPages = this.getSelectedPages();

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      ConfirmChangeDocumentModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      currentDocNewDatamodel:
        this.getDatamodelName(this.documentChangeInfo.newDatamodelValue),
      currentDocNewPages:
        this.getPagesString(this.documentChangeInfo.newPages)
    };

    modalWindowRef.result.then(
      reason => {
        if (reason === Reason.submitBtn) {
          this.editSplitDocument(form, true);
        }
      },
      _reason => { }
    );
  }

  /**
   * Show modal to confirm document split.
   */
  private showConfirmSplitDocumentModal(form: NgForm) {
    this.pagesSelection.checkAllPagesSelected();
    if (this.showSplitAllPagesErrorIfAllPagesSelected()) {
      return;
    }

    this.documentChangeInfo.splittedDocDatamodelValue = this.datamodelValue;
    this.documentChangeInfo.splittedDocPages = this.getSelectedPages();

    this.documentChangeInfo.newDatamodelValue =
      this.documentChangeInfo.initialDatamodelValue;

    this.documentChangeInfo.newPages = this.getNotSelectedPages();

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      ConfirmChangeDocumentModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      splittedDoc: true,
      currentDocNewDatamodel:
        this.getDatamodelName(this.documentChangeInfo.newDatamodelValue),
      currentDocNewPages:
        this.getPagesString(this.documentChangeInfo.newPages),
      splittedDocDatamodel:
        this.getDatamodelName(this.documentChangeInfo.splittedDocDatamodelValue),
      splittedDocPages:
        this.getPagesString(this.documentChangeInfo.splittedDocPages),
    };

    modalWindowRef.result.then(
      reason => {
        if (reason === Reason.submitBtn) {
          this.editSplitDocument(form, false);
        }
      },
      _reason => { }
    );
  }

  /**
   * upload children data
   */
  private newChildrenDocs(form: NgForm) {
    const params = {
      datamodelid: form.value.datamodel,
      documentid: this.new ? '' : this.children.subdocumentid,
      new: this.new,
      documentid_parent: this.parent.documentid,
      pages: this.getSelectedPages()
    };
    this.loading = true;
    this.saving = true;
    this.pagesSelection.isSaving = true;
    this.apiService.post('documents/changedatamodel', params, '').subscribe(
      data => {
        this.loading = false;
        this.saving = false;
        if (this.pagesSelection) {
          this.pagesSelection.isSaving = false;
        }
        if (!this.new) {
          this.alertService.success(
            this.translate.transform('documentInfo.changeChild'),
            true
          );
        } else {
          this.alertService.success(
            this.translate.transform('documentInfo.createChild'),
            true
          );
        }

        this.updateChildDocs.emit({
          newchild: this.children,
          childrenIndex: this.childrenIndex
        });
      },
      () => {
        this.loading = false;
        this.saving = false;
        if (this.pagesSelection) {
          this.pagesSelection.isSaving = false;
        }
        if (!this.new) {
          this.alertService.error(
            this.translate.transform('documentInfo.nochangeChild'),
            true
          );
        } else {
          this.alertService.error(
            this.translate.transform('documentInfo.nocreateChild'),
            true
          );
        }
      }
    );
  }

  /**
   * Send request to edit or create new document.
   */
  private editSplitDocument(form: NgForm, editDocument: boolean): void {
    const params = {
      datamodelid: form.value.datamodel,
      documentid: this.parent.documentid,
      singledocnew: !editDocument
    };
    if (editDocument) {
      const selectedPages = this.documentChangeInfo.newPages;
      if (!this.utils.areArraysEqual<number>(
        this.documentChangeInfo.initialPages,
        selectedPages
      )) {
        params['pages'] = selectedPages;
      }

    } else {
      params['pages'] = this.documentChangeInfo.splittedDocPages;
    }

    this.loading = true;
    this.saving = true;
    this.pagesSelection.isSaving = true;
    this.apiService.post('documents/changedatamodel', params, '').subscribe(
      () => {
        this.loading = false;
        this.saving = false;
        if (this.pagesSelection) {
          this.pagesSelection.isSaving = false;
        }
        this.alertService.success(
          this.translate.transform(
            editDocument ? 'documentInfo.editedDocument' : 'documentInfo.createdDocument'
          ),
          true
        );
        this.saveDocument.emit();
      },
      (_error) => {
        this.loading = false;
        this.saving = false;
        if (this.pagesSelection) {
          this.pagesSelection.isSaving = false;
        }
        this.alertService.error(
          this.translate.transform(
            editDocument ? 'documentInfo.noEditedDocument' : 'documentInfo.noCreatedDocument'
          ),
          true
        );
      }
    )
  }

  /**
   * Get array of selected pages.
   */
  private getSelectedPages(): number[] {
    return this.pagesSelection.toArray(true);
  }

  /**
   * Get array of not selected pages.
   */
  private getNotSelectedPages(): number[] {
    return this.pagesSelection.toNotSelectedArray(true);
  }

  /**
   * @returns The title of the submit button depending
   * on the modal type and if the files are included
   */
  public getButtonTitle(): string {
    switch (this.mode) {
      case UpdateChildrenPages.MODE_EDIT_CHILDREN:
        if (this.children.datamodel) {
          return this.translate.transform('documentDetails.editChildDoc');
        } else {
          return this.translate.transform('documentDetails.createChild');
        }

      case UpdateChildrenPages.MODE_EDIT_DOCUMENT:
        return this.translate.transform('documentDetails.saveDocument');

      case UpdateChildrenPages.MODE_SPLIT_DOCUMENT:
        return this.translate.transform('documentDetails.splitDocument');
    }
  }

  public isSubmitDisabled(isFormInvalid: boolean): boolean {
    if (isFormInvalid) {
      return true;
    }
    if (this.datamodelValue == '' || this.loading) {
      return true;
    }
    if (this.selectionErrorStateMatcher.isError()) {
      return true;
    }
  }

  public ngOnDestroy() {
    this.subscriptionDataModels?.unsubscribe();
    this.pagesSelectionStringSubscription?.unsubscribe();
  }
}

/**
 * Controls when selection string errors are displayed.
 */
class SelectionErrorStateMatcher implements ErrorStateMatcher {
  public constructor(private component: UpdateChildrenPages) { }

  public isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    return this.isError();
  }

  public isError(): boolean {
    const selectionStatus: PagesSelectionStatus =
      this.component.selectionStatus;
    return (
      selectionStatus &&
      !selectionStatus.ok &&
      this.component.selectpagesmode == this.component.CUSTOM_PAGE_SELECTION
    );
  }
}
