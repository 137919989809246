import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-time-tracking-filters',
  templateUrl: './time-tracking-filters.component.html',
  styleUrls: ['./time-tracking-filters.component.scss']
})
export class TimeTrackingFiltersComponent implements OnInit {
  @Output() onSearch = new EventEmitter();
  @Input() totals: number = 0;
  @Input() isLoading: boolean = false;

  public filters: any = {
    projectname: '',
    useremail: '',
    amount_max: 0,
    amount_min: 0,
    vendor: '',
    reviewdate: 'week'
  };
  public settedFilters: any = {
    projectname: '',
    useremail: '',
    amount_max: 0,
    amount_min: 0,
    vendor: '',
    reviewdate: 'week'
  };

  constructor() {}

  ngOnInit() {}

  /**
   * Clean filters
   */
  public cleanFilters() {
    this.filters = {
      projectname: '',
      useremail: '',
      amount_max: 0,
      amount_min: 0,
      vendor: '',
      reviewdate: 'week'
    };
    this.sendParamFilter();
  }

  /**
   * Send filter parameters
   */
  public sendParamFilter() {
    const filters: any = {};
    if (this.filters.projectname)
      filters.projectname = this.filters.projectname;
    if (this.filters.useremail) filters.useremail = this.filters.useremail;
    if (this.filters.vendor) filters.vendor = this.filters.vendor;
    if (this.filters.reviewdate) filters.reviewdate = this.filters.reviewdate;
    if (this.filters.amount_min > 0)
      filters.amount_min = this.filters.amount_min;
    if (this.filters.amount_max > 0)
      filters.amount_max = this.filters.amount_max;
    this.onSearch.emit({ ...filters });
    this.settedFilters = { ...filters };
  }

  /**
   * Check if form is empty
   */
  public formIsDirty() {
    return Object.keys(this.settedFilters).some(
      key => this.settedFilters[key] !== this.filters[key]
    );
  }
}
