import { Component, OnInit, Input } from '@angular/core';

import { LinkService } from '../../services/link.service';
import * as querysheetConstants from '../../constants/querysheetConstants';
import { EXPLORER_MEMBER, VIEWER } from '../../constants/userRoles';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-querysheet-card',
  templateUrl: './querysheet-card.component.html',
  styleUrls: ['./querysheet-card.component.scss']
})
export class QuerysheetCardComponent implements OnInit {
  @Input() querysheet;
  @Input() user;
  @Input() qsAllowedRoles;
  public querysheetStatus = querysheetConstants;
  querySheetTooltip : string = ""

  constructor(
    private link: LinkService,
    private translate: TranslatePipe,
   ) {}

  ngOnInit(): void {
    
    /* Display status tooltip only to viewer role */
    if (this.user.role.rolename == EXPLORER_MEMBER) {
      if (this.querysheet.querySheetStatus === this.querysheetStatus.QUERYSHEET_STATUS_PROCESSING) {
        this.querySheetTooltip = this.translate.transform('querysheetDetails.querySheetCardPublishedTooltip') 
      } else if (this.querysheet.querySheetStatus === this.querysheetStatus.QUERYSHEET_STATUS_FAILED) {
        this.querySheetTooltip = this.translate.transform('querysheetDetails.querySheetCardFailedTooltip')
      }
    }
    
  }

  public goToQuerysheet(): void {
    if (this.isAccessQuerySheet(this.querysheet)) {
      this.link.goToQuerysheet(this.querysheet);
    }
  }

  /**
   * Verify QS Access based on status and roles
   */
  public isAccessQuerySheet(querysheet) {
    return (querysheet.querySheetStatus === 'success' || this.qsAllowedRoles.includes(this.user.role.rolename))
  }
}
