import { Injectable } from '@angular/core';
import {
  FormControl,
  Validators,
  FormArray,
  FormGroup,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import { from } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DatamodelModel } from 'src/app/models/datamodel.model';
import { ValidationService } from '../common-components/validation.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class PreliminaryService {
  public preliminaryDatamodels = [];

  constructor(
    public validationService: ValidationService,
    public apiService: ApiService,
    public global: GlobalService,
    public translate: TranslatePipe
  ) {}

  /**
   * Description: New preliminary validation entry point
   * @param formTest form to add controls
   */
  newValidationPreliminary(formTest) {
    formTest.addControl('trackstatus', new FormControl(false));
    formTest.addControl('name', new FormControl('', Validators.required));
    formTest.addControl('is_edition', new FormControl(false));
    formTest.addControl(
      'datamodel_list',
      new FormArray(
        [
          new FormGroup({
            id: new FormControl('', Validators.required),
            datamodeltrigger: new FormControl(false)
          }),
          new FormGroup({
            id: new FormControl('', Validators.required),
            datamodeltrigger: new FormControl(false)
          })
        ],
        [this.triggerValidator]
      )
    );
    formTest.controls['type'].setValue(3);
    this.setPreliminaryDatamodels([]);
    this.addEmptyPreliminaryDatamodel();
    this.addEmptyPreliminaryDatamodel();
    return formTest;
  }

  triggerValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const controlValue = control.value;
    let countTrigger = 0;
    controlValue.map(cv => {
      if (cv.datamodeltrigger) {
        countTrigger++;
      }
    });
    if (countTrigger < 1) {
      return { triggerCount: true };
    }
    return null;
  }

  /**
   * Description: Edit preliminary validation entry point
   * @param validation validation json to populate
   * @param formTest form to add controls
   * @param datamodels liost of all datamodels
   */
  editPreliminary(validation, formTest, datamodels) {
    this.fillFormBasicPreliminary(validation, formTest, datamodels);
    const cases = validation.validationcontent;
    this.validationService.fillCasesGeneral(
      cases,
      formTest,
      this.fillSimpleConditionPreliminary
    );
    return formTest;
  }

  /**
   * Description: Fill basic data of the form
   * @param validation  validation to fill
   * @param nextStep    came from next step
   * @returns void
   */
  fillFormBasicPreliminary(validation, formTest, datamodels) {
    const allDatamodelsIds = datamodels.map(d => d.datamodelid);
    const datamodel_list_array = new FormArray([], this.triggerValidator);
    const datamodel_list_array_original = new FormArray([]);
    const preconditions = new FormArray([]);
    const datamodel_list_preliminary_ids = validation.validations.map(
      v => v.datamodelid
    );
    const filtered_datamodels = datamodels.filter(
      d => datamodel_list_preliminary_ids.indexOf(d.datamodelid) > -1
    );
    validation.validations.forEach(v => {
      const exists = allDatamodelsIds.indexOf(v.datamodelid) > -1;
      // Include only existing datamodels
      datamodel_list_array.push(
        new FormGroup({
          id: new FormControl(exists ? v.datamodelid : '', Validators.required),
          datamodeltrigger: new FormControl(exists ? v.datamodeltrigger : false)
        })
      );
      datamodel_list_array_original.push(
        new FormGroup({
          id: new FormControl(v.datamodelid),
          datamodeltrigger: new FormControl(v.datamodeltrigger)
        })
      );
      if (v.preconditioncontent) {
        v.preconditioncontent.forEach(precondition => {
          preconditions.push(
            new FormGroup({
              '0': new FormGroup({
                type: new FormControl(precondition[0].type),
                groupoffieldsid: new FormControl(
                  precondition[0].groupoffieldsid,
                  Validators.required
                ),
                fieldofinterestid: new FormControl(
                  precondition[0].fieldofinterestid,
                  Validators.required
                )
              }),
              '1': new FormGroup({
                type: new FormControl(precondition[1].type),
                operator: new FormControl(
                  precondition[1].operator,
                  Validators.required
                )
              }),
              '2': new FormGroup({
                type: new FormControl(precondition[2].type),
                value: new FormControl(
                  precondition[2].value,
                  Validators.required
                ),
                datatype: new FormControl(
                  precondition[2].datatype,
                  Validators.required
                )
              }),
              precondition_logic: new FormControl(
                precondition.precondition_logic
              )
            })
          );
        });
      }
    });
    formTest.addControl('is_edition', new FormControl(true));
    formTest.addControl('datamodel_list', datamodel_list_array);
    formTest.addControl(
      'datamodel_list_original',
      datamodel_list_array_original
    );
    formTest.addControl(
      'precondition',
      new FormControl(preconditions.length > 0)
    );
    formTest.addControl('preconditions', preconditions);
    formTest.addControl(
      'trackstatus',
      new FormControl(validation.validations[0].trackstatus)
    );
    formTest.get('type').setValue(3);
    formTest.addControl(
      'name',
      new FormControl(validation.validationname, Validators.required)
    );
    formTest.addControl(
      'validationid',
      new FormControl(validation.validationid)
    );
    formTest
      .get('autoexecute')
      .setValue(validation.validations[0].validationautoexecute);
    formTest.get('description').setValue(validation.validationdescription);
    this.setPreliminaryDatamodels(filtered_datamodels);
  }

  /**
   *
   * Functions for populate form when want to edit
   *
   */

  /**
   * Description: popilate simple condition
   * @param simple_condition condition to populate data
   */
  fillSimpleConditionPreliminary(simple_conditions, existOnDataModel, global) {
    const simple = new FormArray([]);
    simple_conditions.map(condition => {
      const operation_list_wrapper = new FormGroup({});
      operation_list_wrapper.addControl('operations', new FormGroup({}));
      operation_list_wrapper.addControl(
        'condition_name',
        new FormControl(condition.condition_name)
      );
      const operations = condition['operations'];
      // tslint:disable-next-line: forin
      for (const operation_index in operations) {
        const operation_wrapper = new FormGroup({});
        const operation = operations[operation_index];
        if ('datamodelid' in operation) {
          const existFieldOfInterest = existOnDataModel(
            +operation.fieldofinterestid,
            +operation.groupoffieldsid,
            +operation.datamodelid,
            global
          );
          operation_wrapper.addControl(
            'datamodelid',
            new FormControl(`${operation.datamodelid}`, Validators.required)
          );
          if (existFieldOfInterest) {
            operation_wrapper.addControl(
              'fieldofinterestid',
              new FormControl(
                `${operation.fieldofinterestid}`,
                Validators.required
              )
            );
            operation_wrapper.addControl(
              'groupoffieldsid',
              new FormControl(
                `${operation.groupoffieldsid}`,
                Validators.required
              )
            );
          } else {
            operation_wrapper.addControl(
              'fieldofinterestid',
              new FormControl('', Validators.required)
            );
            operation_wrapper.addControl(
              'groupoffieldsid',
              new FormControl('', Validators.required)
            );
          }
        } else {
          operation_wrapper.addControl(
            'type',
            new FormControl(operation.type, Validators.required)
          );
          operation_wrapper.addControl(
            'operator',
            new FormControl(operation.operator, Validators.required)
          );
        }
        const operation_control_list_wrapper = operation_list_wrapper[
          'controls'
        ]['operations'] as FormGroup;
        operation_control_list_wrapper.addControl(
          operation_index,
          operation_wrapper
        );
      }

      simple.push(operation_list_wrapper);
    });
    return simple;
  }

  /**
   * Return empty template of case condition
   */
  getCaseCondition() {
    return new FormGroup({
      condition_name: new FormControl(''),
      operations: new FormGroup({
        '0': new FormGroup({
          datamodelid: new FormControl('', Validators.required),
          fieldofinterestid: new FormControl('', Validators.required),
          groupoffieldsid: new FormControl('', Validators.required)
        }),
        '1': new FormGroup({
          type: new FormControl('operator'),
          operator: new FormControl('e')
        }),
        '2': new FormGroup({
          datamodelid: new FormControl('', Validators.required),
          fieldofinterestid: new FormControl('', Validators.required),
          groupoffieldsid: new FormControl('', Validators.required)
        })
      })
    });
  }

  /**
   * Returns datamodels selected in preliminary
   */
  public getPreliminaryDatamodels() {
    return [...this.preliminaryDatamodels];
  }

  /**
   * Modify value of array with another datamodel
   * @param datamodelObject datamodel object to set
   * @param index index of value array to change
   */
  public modifyPreliminaryDatamodel(datamodelObject, index) {
    this.preliminaryDatamodels[index] = { ...datamodelObject };
  }

  /**
   * Add empty value to datamodel list
   */
  public addEmptyPreliminaryDatamodel() {
    this.preliminaryDatamodels.push({});
  }

  /**
   * Delete specifix position of datamodel list
   * @param index array index to delete position
   */
  public deletePreliminaryDatamodelByIndex(index) {
    this.preliminaryDatamodels.splice(index, 1);
  }

  /**
   * Delete specifix datamodel of datamodel list given the ID
   * @param datamodelid datamodel ID to delete
   */
  public deletePreliminaryDatamodelById(datamodelid) {
    const index = this.preliminaryDatamodels
      .map(d => d.datamodelid)
      .indexOf(datamodelid);
    if (index > -1) {
      this.deletePreliminaryDatamodelByIndex(index);
    }
  }

  /**
   * Set a list of datamodels
   * @param datamodelList datamodel list to set
   */
  setPreliminaryDatamodels(datamodelList) {
    this.preliminaryDatamodels = [...datamodelList];
  }

  /**
   * Check if datamodel is used in a single condition
   * @param datamodelId datamodel ID to check
   * @param condition condition to check in
   */
  private isDatamodelInCondition(datamodelId, condition) {
    const operations = condition.operations;
    return (
      operations[0].datamodelid === datamodelId ||
      operations[2].datamodelid === datamodelId
    );
  }

  /**
   * Flat case to return all conditions in a
   * flatten array (without nested conditions)
   * @param caseValue case value
   */
  private flatCaseValueConditions(caseValue) {
    return caseValue.reduce((flat, toFlatten) => {
      const toConcat = toFlatten.condition_list
        ? this.flatCaseValueConditions(toFlatten.condition_list)
        : toFlatten.conditions
        ? this.flatCaseValueConditions(toFlatten.conditions)
        : toFlatten.simple_multiple
        ? this.flatCaseValueConditions(toFlatten.conditions)
        : toFlatten;
      return flat.concat(toConcat);
    }, []);
  }

  /**
   * Check if datamodel could be edited/temoved or not
   * Returns true if datamodel is not used in any condition
   * @param indexDatamodel index of datamodel list to check
   * @param generalForm form to check
   */
  canEditDatamodel(indexDatamodel, generalForm) {
    const datamodelId = generalForm
      .get('datamodel_list')
      ['controls'][indexDatamodel].get('id').value;
    if (!datamodelId) {
      return true;
    }

    const cases = generalForm.get('cases') as FormArray;
    return !this.flatCaseValueConditions(cases.value).some(condition =>
      this.isDatamodelInCondition(+datamodelId, condition)
    );
  }

  /**
   * Returns preliminary pre condition template
   */
  getPreCondition() {
    return new FormGroup({
      '0': new FormGroup({
        type: new FormControl('fieldofinterestid'),
        groupoffieldsid: new FormControl('', Validators.required),
        fieldofinterestid: new FormControl('', Validators.required)
      }),
      '1': new FormGroup({
        type: new FormControl('operator'),
        operator: new FormControl('', Validators.required)
      }),
      '2': new FormGroup({
        type: new FormControl('value'),
        value: new FormControl('', Validators.required),
        datatype: new FormControl('', Validators.required)
      }),
      precondition_logic: new FormControl('and')
    });
  }
}
