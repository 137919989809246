import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Handle layout operations
 */
export class LayoutService {
  public sidebarIsOpened: boolean = false;
  public userMenuIsOpened: boolean = false;

  @Output() sidebarChange: EventEmitter<boolean> = new EventEmitter();
  @Output() userMenuChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  /**
   * Emit layout state
   */
  updateState() {
    this.sidebarChange.emit(this.sidebarIsOpened);
    this.userMenuChange.emit(this.userMenuIsOpened);
  }

  /**
   * Toggle sidebar visibility
   */
  toggleSidebar(isOpen = null) {
    this.sidebarIsOpened = isOpen === null ? !this.sidebarIsOpened : isOpen;
    this.userMenuIsOpened = false;
    this.updateState();
  }

  /**
   * Toggle User menu visibility
   */
  toggleUserMenu(isOpen = null) {
    this.userMenuIsOpened = isOpen === null ? !this.userMenuIsOpened : isOpen;
    this.sidebarIsOpened = false;
    this.updateState();
  }

  /**
   * Close all menus
   */
  closeMenus() {
    this.sidebarIsOpened = false;
    this.userMenuIsOpened = false;
    this.updateState();
  }
}
