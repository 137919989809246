import {
  Component,
  OnChanges,
  OnDestroy,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { Subscription, Observable, concat } from 'rxjs';
import { element } from 'protractor';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { DocumentValidationService } from 'src/app/pages/document-details/document-validation/document-validation.service';
import { DocumentChecklistService } from './document-checklist.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { DocumentChecklistSelection } from './document-checklist-selection';

@Component({
  selector: 'app-document-checklist-table',
  templateUrl: './document-checklist-table.component.html',
  styleUrls: ['./document-checklist-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentChecklistTableComponent implements OnChanges, OnDestroy {
  @Input() tests: any = {};
  @Input() documentDetails: any;
  @Output() updateTimeline = new EventEmitter();
  @Output() updateShowChunkButton = new EventEmitter();
  @Output() setTestsUpdate = new EventEmitter();

  public loadingCollapsible: boolean = false;
  public lineMarked: boolean = false;
  public conditionMarked: boolean = false;
  public isCollapsed: boolean = false;
  public failures: number;
  public total: number;
  public elements: any[] = [];
  public previusElements: any[] = [];
  public selection: DocumentChecklistSelection;
  public savingChanges: boolean = false;
  public _validationStatus: any;
  public _validationTypes: any;
  public searchSub: Subscription;
  public validationsStatus: ValidationStatusModel[];
  public subscriptionUtils: Subscription;
  public approval: any = {};

  private user: any = {};

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private global: GlobalService,
    private translate: TranslatePipe,
    private documentRenderService: DocumentRenderService,
    private validationService: DocumentValidationService,
    private documentChecklist: DocumentChecklistService,
    private modalService: NgbModal
  ) {
    this.user = authService.getLoggedInUser();
    this._validationStatus = this.global.getValidationStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();
    this.searchSub = this.documentRenderService.watchSearch().subscribe(obj => {
      this.emptyAllChunks();
    });
  }

  ngOnInit() {
    this.validationsStatus = this.global.getValidationStatus();

    if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = concat(
        this.global.watchUtils(),
        this.global.watchDataModels()
      ).subscribe((data: string) => {
        this.validationsStatus = this.global.getValidationStatus();
      });
    } else if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsStatus = this.global.getValidationStatus();
        });
    }
    this.setApproval();

    this.resetSelection();
  }

  ngOnChanges() {
    this.initItemChecklistStatus(this.tests.validations);
    this.retrievePreviusState();

    if (this.previusElements) {
      if (this.previusElements.length) {
        this.elements.forEach(item => {
          this.previusElements.forEach(previus => {
            if (this.tests.conditions) {
              if (
                item.validationexecutionconditionsid ==
                previus.validationexecutionconditionsid
              ) {
                item['collapsedchecklist'] = previus['collapsedchecklist'];
              }
            } else {
              if (item.validationname == previus.validationname) {
                item['collapsedchecklist'] = previus['collapsedchecklist'];
                if (item.children) {
                  item.children.forEach(childitem => {
                    previus.children.forEach(childprevius => {
                      if (
                        childitem.validationexecutionid ==
                        childprevius.validationexecutionid
                      ) {
                        childitem.checklistsexecution.forEach(
                          childitemexecution => {
                            childprevius.checklistsexecution.forEach(
                              childpreviusexecution => {
                                childitemexecution['collapsedchecklist'] =
                                  childpreviusexecution['collapsedchecklist'];
                                childitemexecution.groups.forEach(
                                  childitemgroup => {
                                    childpreviusexecution.groups.forEach(
                                      childpreviusgroup => {
                                        if (
                                          childpreviusgroup.checklistgroupexecutionid ==
                                          childitemgroup.checklistgroupexecutionid
                                        ) {
                                          childitemgroup['collapsedchecklist'] =
                                            childpreviusgroup[
                                              'collapsedchecklist'
                                            ];
                                        }
                                      }
                                    );
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    });
                  });
                }
              }
            }
          });
        });
      }
    }
  }

  /**
   * Retrieving previus state of the dropdowns
   */
  public retrievePreviusState() {
    if (this.tests.name === this._validationTypes.CATALOG) {
      this.previusElements =
        this.documentChecklist.getCollapsibleCatalogState();
    }
  }

  ngOnDestroy() {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  /**
   * Reset selected checklist items.
   */
  public resetSelection(): void {
    this.selection = new DocumentChecklistSelection();
  }

  /**
   * Called when user clicks on 'save changes' button.
   */
  public onSaveChanges(): void {
    if (this.savingChanges) {
      return;
    }

    this.saveChanges(() => {
      this.resetSelection();
      this.setTestsUpdatedChecklist();
    });
  }

  /**
   * Save checklist changes.
   *
   * Note: updates 'saving changes' flag.
   * Do not execute twice concurrently.
   */
  private saveChanges(next: () => void): void {
    const changesData = this.selection.toArray();
    if (changesData.length) {
      this.savingChanges = true;

      this.apiService
        .put(
          `checklistsitemexecution/changestatus`,
          '',
          JSON.stringify(changesData),
          'checklist.statuschecklist'
        )
        .subscribe(
          () => {
            this.savingChanges = false;
            if (next) {
              next();
            }
          },
          _error => {
            this.savingChanges = false;
          }
        );
    }
  }

  /**
   * Output function to re render the checklist
   */
  public setTestsUpdatedChecklist() {
    this.setTestsUpdate.emit();
  }

  /**
   * Open and close the condition div
   */
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  public initItemChecklistStatus(item) {
    this.documentChecklist.setState(item);
    this.elements = this.documentChecklist.getState();
    console.log(this.elements);
    return this.documentChecklist.getState();
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  public countNumberOfItems() {
    let countItems = 0;
    this.tests.validations.forEach(validation => {
      if (validation.children) {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            validationexecution.checklistsexecution.forEach(
              checklistexecution => {
                checklistexecution.groups.forEach(group => {
                  group.items.forEach(item => {
                    countItems++;
                  });
                });
              }
            );
          }
        });
      }
    });
    return countItems;
  }

  public countNumberOfChecklists() {
    let countChecklists = 0;
    this.tests.validations.forEach(validation => {
      if (validation.children) {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            validationexecution.checklistsexecution.forEach(
              checklistexecution => {
                countChecklists++;
              }
            );
          }
        });
      }
    });
    return countChecklists;
  }

  public isLastVersion() {
    let isLastVersion = true;
    this.tests.validations.forEach(validation => {
      if (validation.children) {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            validationexecution.checklistsexecution.forEach(
              checklistexecution => {
                checklistexecution.checklistid.forEach(template => {
                  if (!template.is_latest_version) {
                    isLastVersion = false;
                  }
                });
              }
            );
          }
        });
      }
    });
    return isLastVersion;
  }

  public onMeetConditions() {
    if (this.savingChanges) {
      return;
    }

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'user',
      message: this.translate.transform('checklist.conditionsConfirm')
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.resetSelection();

          this.tests.validations.forEach((validation: any) => {
            if (validation.children) {
              validation.children.forEach((validationexecution: any) => {
                if (validationexecution.checklistsexecution.length > 0) {
                  validationexecution.checklistsexecution.forEach(
                    (checklistexecution: any) => {
                      checklistexecution.groups.forEach((group: any) => {
                        group.items.forEach((item: any) => {
                          this.selection.setItemStatus(
                            item.checklistitemexecutionId,
                            this._validationStatus.SUCCESS
                          );
                        });
                      });
                    }
                  );
                }
              });
            }
          });

          this.saveChanges(() => {
            this.alertService.success(
              this.translate.transform('checklist.statuschecklist'),
              true
            );
            this.resetSelection();
            this.setTestsUpdatedChecklist();
          });
        }
      },
      reason => {}
    );
  }

  public onRerunChecklist() {
    let countRequest = 0;
    this.tests.validations.forEach(validation => {
      if (validation.children) {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            validationexecution.checklistsexecution.forEach(
              checklistexecution => {
                const params = {
                  checklistexecutionid: checklistexecution.checklistexecutionid
                };

                this.apiService
                  .put(
                    `checklistsexecution/${checklistexecution.checklistexecutionid}/rerun`,
                    '',
                    JSON.stringify(params),
                    ''
                  )
                  .subscribe(() => {
                    countRequest++;
                    if (countRequest == this.countNumberOfChecklists()) {
                      this.alertService.success(
                        this.translate.transform('checklist.rerun'),
                        true
                      );
                    }
                  });
              }
            );
          }
        });
      }
    });
  }
  /**
   * Function to pass as an Output to the child item
   */
  public onOverride() {
    if (this.tests.conditions) {
      this.failures--;
    }
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }

  /**
   * Functions to construct the form data to pass
   * as param in the post request
   * @param form html form data
   */
  public getFormData(executionid) {
    const params = new FormData();
    params.append('commentdescription', '');
    params.append('validationexecutionid', executionid);
    params.append('documentid', this.documentDetails.documentid.toString());
    return params;
  }

  /**
   * Function called by a validation execution child
   * to rerun de execution
   * @param executionid
   */
  public onExcludePreliminary(executionid) {
    const params = this.getFormData(executionid);
    this.apiService.post('comments', params, '', false).subscribe(
      data => {
        this.alertService.success(
          this.translate.transform(
            'ValidationCollapsibleList.successfulExclude'
          ),
          true
        );
      },
      () => {}
    );
  }

  /**
   * Function called by a validation execution child
   * to rerun de execution
   * @param executionid
   */
  public onRerunPreliminary(executionid) {
    const params = { validationexecutionids: [executionid] };
    this.apiService
      .put(
        `documentvalidations/${this.documentDetails.documentid}/preliminaries`,
        '',
        params,
        'documentDetails.runValidationMsg'
      )
      .subscribe(() => {});
  }

  /**
   * Function to rerun the preliminary validation
   */
  public setRerunPreliminaries() {
    const array = [...this.elements];
    array.forEach(validation => {
      validation.children.forEach(valExe => {
        valExe.allowRerun = this.allowRerun(valExe);
      });
    });
  }

  /**
   * Returns if this validation execution
   * is allowed to rerun.
   * @param valExe
   */
  public allowRerun(valExe) {
    return (
      this.user.isadmin ||
      valExe.status === this._validationStatus.FAILURE ||
      (valExe.status === this._validationStatus.SUCCESS &&
        valExe.hasFailureCatalog)
    );
  }

  /**
   * emptyAllChunks
   */
  public emptyAllChunks() {
    this.lineMarked = false;
    this.conditionMarked = false;
    if (this.elements) {
      this.elements.forEach(element => {
        this.conditionMessageLinesToFalse(element);
        if (element.children) {
          element.children.forEach(child => {
            if (child.conditions) {
              child.conditions.forEach(condition => {
                this.conditionMessageLinesToFalse(condition);
              });
            }
          });
        }
      });
    }
    this.documentRenderService.emptyHighlightedChunks();
  }

  public cleanDocument() {
    let chunks = this.documentRenderService.getChunks();
    let documentId = this.documentDetails.documentid;
    let item = { chunks: chunks[1], documentId: documentId };
    this.emptyAllChunks();
    this.documentRenderService.goToMainDoc(item, documentId);
  }

  private conditionMessageLinesToFalse(condition) {
    condition.markedChunks = false;
    if (condition.messageLines) {
      condition.messageLines.forEach(line => {
        line.markedChunks = false;
      });
    }
  }
}
