import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

const dialogConfig = new MatDialogConfig();
dialogConfig.width = '500px';

@Component({
  selector: 'app-openai-info',
  templateUrl: './openai-info.component.html',
  styleUrls: ['./openai-info.component.scss']
})
export class OpenaiInfoComponent implements OnInit {
  constructor(public dialog: MatDialog) {}
  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnInit(): void {
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `
  <style>
    div {  display: flex; justify-content: end;}
    h3 { font-size: 1.2rem; }
    p { font-size: 0.9rem; margin-top: 10px}
    button {
      border: none;
      background-color: #424242;
      color: #ffffff;
      font-size: 1rem;
      padding: 7px;
    }
    button:hover {
      background-color: #d3d3d3;
      border-radius: 2px;
      transition: all 0.2s;
      color:  #424242;
    }
  </style>
    <h3>Tips for OpenAI search mode<h3>
      <p>Please, follow the next recommendations to obtain good answers:</p>
      <p>1. Answers will come from the text in the document. Therefore, questions outside the document-domain can't be provided</p>
      <p>2. Be specific in your questions. E.g. "what was the revenue in 2021?" is a better question than "give revenue"</p>
      <p>3. Ambiguous questions will perform poorly</p>
      <p>4. When asking for a value specify the type. E.g. "name of the director?" is a better question than "who is the director?". "address of the director?" is better than "where the director lives?"</p>
      <div>
        <button (click)="close()">Close</button>
      </div>
  `,
})
export class DialogContentExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogContentExampleDialog>) {}
  close(): void {
    this.dialogRef.close()
  }
}