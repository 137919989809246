import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TrainableService } from '../trainable.service';
import { TrainableTableComponent } from './trainable-table/trainable-table.component';
import { TrainableIndividualComponent } from './trainable-individual/trainable-individual.component';

@Component({
  selector: 'app-group-of-fields',
  templateUrl: './group-of-fields.component.html',
  styleUrls: ['./group-of-fields.component.scss']
})
export class GroupOfFieldsComponent implements OnInit {
  @Input('groupOfField') public groupOfField: FormGroup;

  constructor(private trainableService: TrainableService) {}

  ngOnInit() {}

  public changeType() {
    // TODO: Pending to uses constants inside the app.
    const value = this.groupOfField.get('groupoffieldstypeid').value.toString();
    switch (value) {
      // Individual case
      case '1':
        this.trainableService.deleteControlKey(
          this.groupOfField,
          'fieldsofinterest'
        );
        this.trainableService.addControlIndividual(this.groupOfField);
        break;
      // Table case
      case '2':
        this.trainableService.deleteControlKey(
          this.groupOfField,
          'fieldsofinterest'
        );
        this.trainableService.addControlKeyTable(this.groupOfField);
        break;
      // Paragraph case
      case '3':
        this.trainableService.deleteControlKey(
          this.groupOfField,
          'fieldsofinterest'
        );
        this.trainableService.addControlParagraph(this.groupOfField);
        break;
      // Entity case
      case '4':
        this.trainableService.deleteControlKey(
          this.groupOfField,
          'fieldsofinterest'
        );
        this.trainableService.addControlEntity(this.groupOfField);
        break;
      // Default: Individual case
      default:
        this.trainableService.deleteControlKey(
          this.groupOfField,
          'fieldsofinterest'
        );
        this.trainableService.addControlIndividual(this.groupOfField);
        break;
    }
  }
}
