import { Component, Input, OnInit } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateExplorationsComponent } from '../../../components/modals/create-explorations/create-explorations.component';

@Component({
  selector: 'app-exploration-card',
  templateUrl: './exploration-card.component.html',
  styleUrls: ['./exploration-card.component.scss']
})
export class ExplorationCardComponent implements OnInit {

  @Input() querysheetDetails;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  createExploration(): void {
    // Opening up the create exploration modal
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    this.modalService.open(
      CreateExplorationsComponent,
      modalOptions
    );
  }
}
