export const locations = {
    'Amspec invoices': [
        'USA - Other',
        'Joliet Refinery (Preferred Vendor)',
        'Offshore Galveston Lightering - Texas',
        'Offshore Southwest Pass Lightering - Louisana',
        'Beaumont P66, Enterprise',
        'Beaumont Enterprise ',
        'Houston Oiltanking Terminal',
        'Kinder Morgan',
        'Port Everglades EOT, Transmt TP',
        'KM Bostco TP',
        'APEX Terminal, Port of Baton Rouge',
        'Kinder Morgan TP',
        'Buckeye TP',
        'Nashville',
        'Kinder Morgan',
        'Odjfell',
        'Vopak DP',
        'ITC',
        'CP Chem Stolthaven',
        'Valdez, AK',
        'ACT',
        'Shell',
        'Ergon',
        'Sydney IOL - Canada',
        'Corner Brook IOL - Canada',
        'Ultramar - Canada',
        'Canada - Other',
        'Mexico',
        'Brazil',
        'Argentina',
        'Peru',
        'South America - Other',
        'Central America - Other',
        'Carribean - Other'
    ],
    'Camin Invoices': [
        'USA - Other',
        'Baytown Site Area',
        'Beaumont Sitea Area',
        'Baton Rouge Site Area',
        'Offshore Galveston Lightering - Texas',
        'Offshore Southwest Pass Lightering - Louisana',
        'Beaumont P66, Enterprise',
        'Beaumont Enterprise ',
        'Houston Oiltanking Terminal',
        'Kinder Morgan',
        'Port Everglades EOT, Transmt TP',
        'KM Bostco TP',
        'APEX Terminal, Port of Baton Rouge',
        'Kinder Morgan TP',
        'Buckeye TP',
        'Nashville',
        'Kinder Morgan',
        'Odjfell',
        'Vopak DP',
        'ITC',
        'CP Chem Stolthaven',
        'Valdez',
        'ACT',
        'Shell',
        'Ergon',
        'Sydney IOL - Canada',
        'Corner Brook IOL - Canada',
        'Canada - Other',
        'Mexico',
        'Argentina',
        'Peru',
        'South America - Other',
        'Central America - Other',
        'Carribean - Other',
    ],
    'Intertek Invoices': [
        'USA- Other',
        'Baytown Site Area',
        'Offshore Galveston Lightering - Texas',
        'Offshore Southwest Pass Lightering - Louisana',
        'Nanticoke',
        'Sarnia',
        'Beaumont P66, Enterprise',
        'Beaumont Enterprise ',
        'Houston Oiltanking Terminal',
        'Kinder Morgan',
        'East Providence EOT',
        'Port Everglades EOT, Transmt TP',
        'KM Bostco TP',
        'APEX Terminal, Port of Baton Rouge',
        'Everett EOT',
        'KinderMorgan TP',
        'Buckeye TP',
        'Nashville',
        'KinderMorgan',
        'Odjfell',
        'Vopak DP',
        'ITC',
        'CP Chem Stolthaven',
        'Valdez, AK',
        'ACT',
        'Shell',
        'Ergon',
        'Ultramar- Canada',
        'Canada- Other',
        'Mexico',
        'Guyana',
        'Brazil',
        'Argentina',
        'Peru',
        'South America - Other',
        'Central America - Other',
        'Carribean - Other',
    ],
    'Inspectorate invoices': [
        'USA - Other',
        'Beaumont Sitea Area',
        'Offshore Galveston Lightering - Texas',
        'Offshore Southwest Pass Lightering - Louisana',
        'Beaumont P66, Enterprise',
        'Beaumont Enterprise ',
        'Houston Oiltanking Terminal',
        'Kinder Morgan',
        'Port Everglades EOT, Transmt TP',
        'KM Bostco TP',
        'APEX Terminal, Port of Baton Rouge',
        'KinderMorgan TP',
        'Buckeye TP',
        'Nashville',
        'KinderMorgan',
        'Odjfell',
        'Vopak DP',
        'ITC',
        'CP Chem Stolthaven',
        'Valdez, AK',
        'ACT',
        'Shell',
        'Ergon',
        'Sydney IOL- Canada',
        'Corner Brook IOL- Canada',
        'Ultramar- Canada',
        'Canada- Other',
        'Mexico',
        'Guyana',
        'Brazil',
        'Argentina',
        'Peru',
        'South America - Other',
        'Central America - Other',
        'Carribean - Other',
    ]
}