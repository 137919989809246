import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ValidationService } from '../../common-components/validation.service';
import { BusinessService } from './../../business-components/business.service';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {
  @Input('conditionlist') public conditionlist: FormGroup;
  @Input('conditionListIndex') public conditionListIndex: number;
  @Input('casesIndex') public casesIndex: number;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  public expandedConditions: Array<number> = [];

  constructor(
    public validationService: ValidationService,
    public businessService: BusinessService
  ) {}

  ngOnInit() {
    // Open condition when is Extraction
    if (this.validationService.isType(['Extraction'])) {
      const conditions = this.conditionlist.get('conditions')['controls'];
      for (let index = 0; index < conditions.length; index++) {
        this.toggleExpandedCondition(index);
      }
    }
  }

  public getConditionName(casesCtrl, i: number) {
    return !this.validationService.hasKey(casesCtrl, 'conditions') &&
      this.conditionlist.get('conditions').get(`${i}`) &&
      this.conditionlist.get('conditions').get(`${i}`).get('condition_name') &&
      this.conditionlist.get('conditions').get(`${i}`).get('condition_name')
        .value &&
      this.conditionlist
        .get('conditions')
        .get(`${i}`)
        .get('condition_name')
        .value.trim()
      ? this.conditionlist.get('conditions').get(`${i}`).get('condition_name')
          .value
      : `Condition ${i + 1}`;
  }

  /**
   * Toggle condition expanded state
   * @param index condition index
   */
  private toggleExpandedCondition(index: number) {
    const index_element = this.expandedConditions.indexOf(index);
    index_element >= 0
      ? this.expandedConditions.splice(index_element, 1)
      : this.expandedConditions.push(index);
  }

  /**
   * Check if condition is expanded
   * @param index condition index
   */
  private isExpandedCondition(index: number) {
    return this.expandedConditions.indexOf(index) !== -1;
  }

  /**
   * Check if condition has errors and return it
   * @param caseCtrl Condition form group
   */
  public getConditionError(caseCtrl) {
    // Multiple conditions don't have child conditions
    if (
      this.conditionlist.get('simple_multiple').value &&
      !(
        caseCtrl.get('conditions') &&
        caseCtrl.get('conditions')['controls'].length
      )
    ) {
      return this.validationService.getControlError('atLeastOneCondition');
    }

    // Extraction
    if (this.validationService.isType(['Extraction'])) {
      if (
        this.validationService.controlHasError(caseCtrl, 'validation_function')
      ) {
        return this.validationService.getControlError('validation_function');
      }

      if (this.validationService.controlHasError(caseCtrl, 'parameter')) {
        return this.validationService.getControlError('parameter');
      }
    }

    // Business Rules and Catalog
    if (this.validationService.isType(['Business Rules', 'Catalog'])) {
      if (this.validationService.controlHasError(caseCtrl, 'operationScope')) {
        return this.validationService.getControlError('operationScope');
      }

      if (this.validationService.controlHasError(caseCtrl, 'rounding')) {
        return this.validationService.getControlError('rounding');
      }

      if (this.validationService.controlHasError(caseCtrl, 'precision')) {
        return this.validationService.getControlError('precision');
      }

      if (
        caseCtrl.get('operations') &&
        caseCtrl.get('operationScope').value !== 'enrichment'
      ) {
        // Single condition
        const operationsLength = Object.keys(
          caseCtrl.get('operations')['controls']
        ).length;

        if (operationsLength < 4) {
          // Operation is incomplete
          return this.validationService.getControlError('atLeastOneOperation');
        }

        const lastOperation = caseCtrl
          .get('operations')
          .get(`${operationsLength - 1}`);
        if (
          !this.validationService.hasKey(lastOperation, 'operator') ||
          lastOperation.get('operator').value !== 'end' ||
          caseCtrl.get('operations').invalid
        ) {
          // Last operation doesn't end with 'end' value
          return this.validationService.getControlError('operationIsnotEnded');
        }
      }
    }

    // Preliminary
    if (this.validationService.isType(['Preliminary'])) {
      const opCtrl = caseCtrl.get('operations');

      if (
        this.validationService.controlHasError(
          opCtrl.get('0'),
          'datamodelid'
        ) ||
        this.validationService.controlHasError(opCtrl.get('2'), 'datamodelid')
      ) {
        return this.validationService.getControlError('datamodelid');
      }

      if (
        this.validationService.controlHasError(
          opCtrl.get('0'),
          'fieldofinterestid'
        ) ||
        this.validationService.controlHasError(
          opCtrl.get('2'),
          'fieldofinterestid'
        )
      ) {
        return this.validationService.getControlError('fieldofinterestid');
      }

      if (this.validationService.controlHasError(opCtrl.get('1'), 'operator')) {
        return this.validationService.getControlError('operator');
      }
    }

    return false;
  }

  /**
   * Description: Add condition to condition list
   * @param i condition list index to add condition
   */
  public addPreCondition(i: number) {
    const conditions = this.conditionlist.get('conditions') as FormArray;
    const preconditions = conditions.controls[i] as FormGroup;
    const preconditions_list = preconditions.get('preconditions') as FormArray;
    let new_precondition: FormGroup;
    new_precondition = this.businessService.getPreCondition();
    preconditions_list.push(new_precondition);
  }

  public removePreConditions(i: number) {
    const conditions = this.conditionlist.get('conditions') as FormArray;
    const preconditions = conditions.controls[i] as FormGroup;
    const preconditions_list = preconditions.get('preconditions') as FormArray;
    preconditions_list.clear();
  }
}
