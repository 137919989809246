
export class DashboardsModel {
  dashboards: DashboardsModelDashboards;
}

export class DashboardsModelDashboards {
  lifeunderwriting?: LifeUnderWritingInformation;
  disability?: DisabilityInformation;
}

// Life Under Writing

export class LifeUnderWritingInformation {
  applicantinformation: ApplicantInformation;
  generalinformation: LifeUnderWritingGeneralInformation;
  impairments: ImpairmentsInformation;
  medication: MedicationsInformationItem[];
}

export class ApplicantInformation {
  applicationid: string;
  name: string;
  impairmentstatus: string;
  smoker: string;
  dob: string;
  age: string;
  gender: string;
}

export class LifeUnderWritingGeneralInformation {
  body?: BodyInformation;
  vitals?: VitalsInformation;
  lifestyle?: LifestyleInformation;
  family?: FamilyInformation;
  currentmedications?: MedicationsInformation;
  surgery?: SurgeryInformation;
  referrals?: ReferralsInformation;
  treatments?: TreatmentsInformation;

  bodydashboards?: BodyDashboardsInformation;
  vitalsdashboards?: VitalsDashboardsInformation;
}

export class BodyInformation {
  bodyrecordeddate: string;
  riskflag: string;
  weightrecordeddate: string;
  weightvalue: string;
  weighttrend: string;
  bmirecordeddate: string;
  bmivalue: string;
  bmitrend: string;
  bmiflag: string;
  heightrecordeddate: string;
  heightvalue: string;
}

export class VitalsInformation {
  vitalsrecordeddate: string;
  riskflag: string;
  hemoglobinrecordeddate: string;
  hemoglobinvalue: string;
  hemoglobintrend: string;
  hemoglobinflag: string;
  bloodpressurerecordeddate: string;
  bloodpressurevalue: string;
  bloodpressuretrend: string;
  bloodpressureflag: string;
  cholesterolrecordeddate: string;
  cholesterolvalue: string;
  cholesteroltrend: string;
  cholesterolflag: string;
}

export class LifestyleInformation {
  lifestylerecordeddate: string;
  alcohol: UsageInformation;
  tobacco: UsageInformation;
  drugs: UsageInformation;
}

export class UsageInformation {
  currentusage: string;
  previoususage: string;
}

export class FamilyInformation {
  familyrecordeddate: string;
  thresholdage: string;
  familymembers: FamilyMemberInformationItem[];
}

export class FamilyMemberInformationItem {
  familymembername: string;
  familymembercondition: string;
}

export class MedicationsInformation {
  medicationsrecordeddate: string;
  medications: MedicationsInformationItem[];
}

export class MedicationsInformationItem {
  medicationname: string;
  medicationdosage: string;
  medicationfrequency: string;
  primaryindication: string;
  medicationusestatus?: string;
}

export class SurgeryInformation {
  surgeryrecordeddate: string;
  surgery: SurgeryInformationItem[];
}

export class SurgeryInformationItem {
  surgeryname: string;
  surgerydate: string;
}

export class ReferralsInformation {
  referralsrecordeddate: string;
  referrals: ReferralsInformationItem[];
}

export class ReferralsInformationItem {
  referrerspeciality: string;
}

export class TreatmentsInformation {
  treatmentsrecordeddate: string;
  treatments: TreatmentsInformationItem[];
}

export class TreatmentsInformationItem {
  treatmentname: string;
  treatmentfrequency: string;
}

export class BodyDashboardsInformation {
  bodyweightdashboard: DataPoint[];
  bodyheightdashboard: DataPoint[];
  bodybmidashboard: DataPoint[];
}

export class VitalsDashboardsInformation {
  vitalshemoglobindashboard: DataPoint[];
  vitalsbloodpressuredashboard: BloodPressureDataPoint[];
  vitalscholesteroldashboard: DataPoint[];
}

export class DataPoint {
  x: string;
  y: string;
}

export class BloodPressureDataPoint {
  x: string;
  date: string;
  sys: string;
  dia: string;
}

export class ImpairmentsInformation {
  impairmentsoverview: ImpairmentsSetInformationItem[];
  impairmentshistory: ImpairmentsSetInformationItem[];
}

export class ImpairmentsSetInformationItem {
  impairmenttype: string;
  impairmentflag: string;
  impairmentstotalnumber?: string;
  impairments: ImpairmentInformationItem[];
}

export class ImpairmentInformationItem {
  icdcode: string;
  potentialimpairments: string;
  riskflag: string;

  initialdiagnosisdate?: string;
  finaldiagnosisdate?: string;
  currenttreatmentplan?: string;
}

// Disability

export class DisabilityInformation {
  claimnumber: string;
  generalinformation: DisabilityGeneralInformation;
  restrictionslimitations?: RestrictionsLimitationsInformation;
  disabilityoverview: DisabilityOverviewInformation;
  physicianinformation: DisabilityPhysicianInformation;
  diagnosisinformation: DisabilityDiagnosisInformation;
}

export class DisabilityGeneralInformation {
  personalinformation: DisabilityPersonalInformation;
  disabilityinformation: DisabilityDisabilityInformation;
}

export class DisabilityPersonalInformation {
  name: string;
  dob: string;
  employer: string;
  jobtitle: string;
}

export class DisabilityDisabilityInformation {
  disability: string;
  startdate: string;
  enddate: string;
  workrelated: string;
  expectedreturndate: string;
  typeofduty: string;
  restrictionslimitations: string;
}

export class RestrictionsLimitationsInformation {
  keys: RestrictionLimitationKey[];
  values: RestrictionLimitationValue[];
}

export class RestrictionLimitationKey {
  name: string;
  time: string;
}

export class RestrictionLimitationValue {
  type: string;
  value?: string;
  extradata?: RestrictionLimitationExtraDataItem[];
}

export class RestrictionLimitationExtraDataItem {
  key: string;
  value: string;
}

export class DisabilityOverviewInformation {
  diagnosis: DisabilityOverviewDiagnosisInformationItem[];
  hospitalsurgeryinformation: DisabilityHospitalSurgeryInformation;
  medication: DisabilityMedicationInformation;
  treatment: DisabilityTreatmentInformation;
}

export class DisabilityOverviewDiagnosisInformationItem {
  diagnosisname: string;
  flag: string;
}

export class DisabilityHospitalSurgeryInformation {
  hospitalized: string;
  hospitaldates?: HospitalDatesInformation;
  surgery: string;
  surgerydates?: SurgeryDatesInformation;
}

export class HospitalDatesInformation {
  admitdate: string;
  dischargeddate: string;
}

export class SurgeryDatesInformation {
  surgerydate: string;
}

export class DisabilityMedicationInformation {
  medications: DisabilityMedicationInformationItem[];
}

export class DisabilityMedicationInformationItem {
  name: string;
  dosage: string;
  frequency: string;
}

export class DisabilityTreatmentInformation {
  treatments: DisabilityTreatmentInformationItem[];
}

export class DisabilityTreatmentInformationItem {
  plan: string;
  frequency: string;
}

export class DisabilityPhysicianInformation {
  primaryphysicianinformation: PhysicianInformation;
  referredphysicians: PhysicianInformation[];
  mostrecentvisit: string;
  datenextvisit: string;
}

export class PhysicianInformation {
  name?: string;
  specialty?: string;
  telephone: string;
  fax: string;
  address?: string;
  signaturedate?: string;
  referredtoanotherphysician?: string;
}

export class DisabilityDiagnosisInformation {
  diagnosis_info: DisabilityDiagnosisInformationItem[];
  symptoms: string;
}

export class DisabilityDiagnosisInformationItem {
  icdcode: string;
  condition: string;
}
