import { Component, Input, OnInit } from '@angular/core';
import { DocumentReviewLogModel } from 'src/app/models/document-review-log.model';
import { PaginationModel } from 'src/app/models/pagination';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-review-tracking',
  templateUrl: './review-tracking.component.html',
  styleUrls: ['./review-tracking.component.scss']
})
export class ReviewTrackingComponent implements OnInit {

  @Input() set reviewLogs (val: DocumentReviewLogModel[]){
    if (!val) {
      return;
    }
    this._reviewLogs = [...val].reverse();
    this.setDate();
  }
  private _reviewLogs: DocumentReviewLogModel[];
  public data: any;
  private readonly paginationId = 'reviewtracking';
  private pagination: PaginationModel;
  private readonly pageInfo = {
    size: 5,
    order_by: 'documentreviewlogsid',
    desc: 1,
  }

  constructor(
    private paginationService: PaginationService
  ) { }

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination(this.paginationId);
    if (this._reviewLogs) {
      this.getLogs();
    }
  }

  /**
   * Sets the data and pagination for list
   */
  public getLogs() {
    const data = this.getData(this.pagination);
    this.paginationService.setPage(this.paginationId, data);
    this.pagination = this.paginationService.setPagination(this.paginationId, {
      total_elements: data.total_elements,
      page: data.page,
      page_size: data.page_size,
      num_pages: data.num_pages,
      order_by: data.order_by,
      desc: data.desc,
      query: this.pagination.query
    });
    this.data = data;
  }

  /**
   * Gets the data from the review logs input.
   * @param params Pagination
   * @returns An object simulating an answer from the api
   */
  public getData(params: any) {
    const initialIndex = (params.page - 1) * this.pageInfo.size;
    let lastIndex = initialIndex + this.pageInfo.size;
    if (lastIndex > this._reviewLogs.length) {
      lastIndex = undefined
    }
    return {
      data: this._reviewLogs.slice(initialIndex, lastIndex),
      total_elements: this._reviewLogs.length,
      page: params.page,
      page_size: this.pageInfo.size,
      num_pages: this._reviewLogs.length/this.pageInfo.size,
      order_by: this.pageInfo.order_by,
      desc: this.pageInfo.desc,
    };
  }

  /**
   * Handles the page change event
   * @param page
   */
  public onPageChange(page: number) {
    this.paginationService.changePagination(this.paginationId, 'page', page);
    this.getLogs();
  }

  /**
   * Adds a new property to the log objects containing
   * the correct date to display
   */

  public setDate(): void {
    for(let log of this._reviewLogs) {
      log['date'] =  log.alternativedate ?? log.createddate;
    }
  }

}
