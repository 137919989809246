import { Injectable } from '@angular/core';
import { ValidationService } from '../common-components/validation.service';
import { FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  public catalogDatamodels = [];

  constructor(
    public validationService: ValidationService,
    public global: GlobalService
  ) {}

  /**
   * Description: Get last validation to populate
   * @param validation validation data to populate
   * @returns void
   */
  nextStepValidationCatalog(validation, formTest, datamodels) {
    formTest.addControl(
      'preliminaryid',
      new FormControl(validation.validationid)
    );
    this.fillFormBasicCatalog(validation, formTest, datamodels, true);
    return formTest;
  }

  /**
   * Edit catalog validation entry point
   * @param validation validation json to populate
   * @param formTest formtest to adds controls
   * @param datamodels datamodel list
   */
  editCatalog(validation, formTest, datamodels, validationid) {
    this.fillFormBasicCatalog(validation, formTest, datamodels, false);
    formTest.addControl('validationid', new FormControl(validationid));
    const cases = validation.validationcontent;
    this.fillCasesCatalog(cases, formTest, this.fillSimpleConditionCatalog);
    return formTest;
  }

  /**
   * Genaral function for fill simple condition, used in business, catalog and preliminary
   * @param cases cases to iterate
   * @param formTest form to populate data
   * @param fillSimpleCondition function to fill simple condition
   */
  private fillCasesCatalog(cases, formTest, fillSimpleCondition) {
    const formCases = formTest.get('cases') as FormArray;
    cases.forEach(validation_case => {
      formCases.push(
        new FormGroup({
          case_name: new FormControl(
            validation_case.case_name,
            Validators.required
          ),
          case_description: new FormControl(validation_case.case_description),
          condition_type: new FormControl({
            value: validation_case.condition_type,
            disabled: false
          }),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          precondition: new FormControl(
            validation_case.precondition ? true : false
          ),
          preconditions: this.fillPreConditionsCatalog(
            validation_case.preconditions
          ),
          condition_list: this.fillConditionListGeneral(
            validation_case.condition_list,
            fillSimpleCondition
          )
        })
      );
      if ('not_editable' in validation_case) {
        const formCase: any = formCases['controls'][formCases.length - 1];
        formCase.addControl(
          'not_editable',
          new FormControl(validation_case.not_editable)
        );
      }
    });
  }

  /**
   * Populate Condition Lists and call functions to populate simple and multiple conditions
   * Recursive function
   * @param condition_lists conditions lists to populate data
   * @returns condition list filled
   */
  public fillConditionListGeneral(conditionLists, fillConditionVariable) {
    const result_condition_list = new FormArray([]);

    conditionLists
      .filter(conditionList => conditionList.conditions.length > 0)
      .forEach(conditionList => {
        if ([false, 'single'].includes(conditionList.simple_multiple)) {
          // is simple
          const conditions = fillConditionVariable(
            conditionList.conditions,
            this.validationService.existOnDataModel,
            this.global
          );
          const single_condition_structure = new FormGroup({
            condition_type: new FormControl(conditionList.condition_type),
            description: new FormControl(conditionList.description),
            precondition: new FormControl(conditionList.precondition),
            preconditions: this.fillPreConditionsCatalog(
              conditionList.preconditions
            ),
            conditions: conditions,
            error: new FormControl(conditionList.error, Validators.required),
            simple_multiple: new FormControl(false)
          });
          result_condition_list.push(single_condition_structure);
        } else {
          // is multiple
          const conditions = this.fillConditionListGeneral(
            conditionList.conditions,
            fillConditionVariable
          );
          result_condition_list.push(
            new FormGroup({
              condition_type: new FormControl(conditionList.condition_type),
              conditions: conditions,
              description: new FormControl(conditionList.description),
              precondition: new FormControl(conditionList.precondition),
              preconditions: this.fillPreConditionsCatalog(
                conditionList.preconditions
              ),
              simple_multiple: new FormControl(true)
            })
          );
        }
      });
    return result_condition_list;
  }

  public fillPreConditionsCatalog(preconditions) {
    const result = new FormArray([]);
    if (!preconditions) {
      return result;
    }
    preconditions.forEach(precondition => {
      result.push(
        new FormGroup({
          '0': new FormGroup({
            type: new FormControl('datamodelid'),
            datamodelid: new FormControl(precondition[0].datamodelid),
            groupoffieldsid: new FormControl(precondition[0].groupoffieldsid),
            fieldofinterestid: new FormControl(
              precondition[0].fieldofinterestid
            )
          }),
          '1': new FormGroup({
            type: new FormControl('operator'),
            operator: new FormControl(precondition[1].operator)
          }),
          '2': new FormGroup({
            type: new FormControl('value'),
            value: new FormControl(precondition[2].value),
            datatype: new FormControl(precondition[2].datatype)
          })
        })
      );
    });
    return result;
  }

  /**
   * Description: Fill basic data of the form
   * @param validation  validation to fill
   * @param nextStep    came from next step
   * @returns void
   */
  fillFormBasicCatalog(validation, formTest, datamodels, isNew) {
    const datamodel_list_array = new FormArray([]);
    const datamodel_list_preliminary = [...validation.validations];
    const datamodel_list_preliminary_ids = datamodel_list_preliminary.map(
      d => d.datamodelid
    );
    const catalogDatamodels = datamodels.filter(
      d => datamodel_list_preliminary_ids.indexOf(d.datamodelid) > -1
    );

    datamodel_list_preliminary.forEach(datamodel => {
      const idTriggerGroup = new FormGroup({
        id: new FormControl(datamodel.datamodelid),
        datamodeltrigger: new FormControl(datamodel.datamodeltrigger)
      });
      datamodel_list_array.push(idTriggerGroup);
    });
    formTest.addControl('datamodel_list', datamodel_list_array);

    formTest.addControl(
      'name',
      new FormControl(
        isNew ? '' : validation.validationname,
        Validators.required
      )
    );
    formTest.get('type').setValue(4);
    this.setCatalogDatamodels(catalogDatamodels);
  }

  /**
   * Set datamodel list of catalog
   * @param datamodelList
   */
  setCatalogDatamodels(datamodelList) {
    this.catalogDatamodels = datamodelList;
  }

  /**
   * Get datamodel list of catalog
   */
  getCatalogDatamodels() {
    return this.catalogDatamodels;
  }

  /**
   *
   * Functions for populate form when want to edit
   *
   */

  /**
   * Fill simple condition for Business Rule type
   * @param simple_condition condition to populate in form
   */
  fillSimpleConditionCatalog(simple_conditions, existOnDataModel, global) {
    // TODO: Refactor
    const simple = new FormArray([]);
    for (const simple_condition of simple_conditions) {
      const simple_wrapper = new FormGroup({});
      simple_wrapper.addControl('operations', new FormGroup({}));
      simple_wrapper.addControl(
        'condition_name',
        new FormControl(simple_condition.condition_name)
      );
      simple_wrapper.addControl(
        'operationScope',
        new FormControl(simple_condition.operationScope, Validators.required)
      );
      simple_wrapper.addControl(
        'rounding',
        new FormControl(simple_condition.rounding, Validators.required)
      );
      simple_wrapper.addControl(
        'toleranceerrorrate',
        new FormControl(
          simple_condition.toleranceerrorrate
            ? simple_condition.toleranceerrorrate
            : false
        )
      );
      simple_wrapper.addControl(
        'toleranceerrorratevalue',
        new FormControl(
          simple_condition.toleranceerrorratevalue
            ? simple_condition.toleranceerrorratevalue
            : 0
        )
      );
      // tslint:disable-next-line: forin
      for (const element in simple_condition) {
        if (element === 'enrichments_type') {
          simple_wrapper.addControl(
            'enrichments_type',
            new FormControl(simple_condition[element], Validators.required)
          );}
        if (element === 'operations') {
          for (let [key, value] of Object.entries(simple_condition[element])) {
            let type = value['type'];
            let new_condition = new FormGroup({
              type: new FormControl(type),
              [type]: new FormControl(value[type])
            });
            if (type === 'datamodelid') {
              const existFieldOfInterest = existOnDataModel(
                value['fieldofinterestid'],
                value['groupoffieldsid'],
                value['datamodelid'],
                global
              );
              if (!value['agg'] && value['aggregation'])
                value['agg'] = value['aggregation'];
              if (existFieldOfInterest) {
                new_condition.addControl(
                  'datamodelid',
                  new FormControl(value['datamodelid'])
                );
                new_condition.addControl(
                  'fieldofinterestid',
                  new FormControl(
                    value['fieldofinterestid'],
                    Validators.required
                  )
                );
                new_condition.addControl(
                  'groupoffieldsid',
                  new FormControl(value['groupoffieldsid'], Validators.required)
                );
                new_condition.addControl(
                  'aggscope',
                  new FormControl(value['aggscope'], Validators.required)
                );
                if (value['aggscope'] !== 'na') {
                  new_condition.addControl(
                    'agg',
                    new FormControl(value['agg'], Validators.required)
                  );
                }
              } else {
                new_condition.addControl(
                  'datamodelid',
                  new FormControl(value['datamodelid'])
                );
                new_condition.addControl(
                  'fieldofinterestid',
                  new FormControl('', Validators.required)
                );
                new_condition.addControl(
                  'groupoffieldsid',
                  new FormControl('', Validators.required)
                );
                new_condition.addControl(
                  'aggscope',
                  new FormControl('', Validators.required)
                );
                new_condition.addControl(
                  'agg',
                  new FormControl('', Validators.required)
                );
              }
            } else if (type === 'value') {
              new_condition.addControl(
                'datatype',
                new FormControl(value['datatype'], Validators.required)
              );
              new_condition.addControl(
                'value',
                new FormControl(value['value'], Validators.required)
              );
            } else if (type === 'operator') {
              new_condition.addControl(
                'operator',
                new FormControl(value['operator'], Validators.required)
              );
            }
            const simple_wrapper_control = simple_wrapper.get(
              'operations'
            ) as FormGroup;
            simple_wrapper_control.addControl(key, new_condition);
          }
        } else if (element === 'datamodelLinks') {
          let elements = simple_condition[element];
          let elements_arr = new FormArray([]);
          elements.map(el => {
            let group_to_add = new FormGroup({
              id: new FormControl(el['id'])
            });
            if (simple_condition.operationScope === 'line') {
              group_to_add.addControl(
                'fieldofinterestid',
                new FormControl(el['fieldofinterestid'], Validators.required)
              );
            }
            elements_arr.push(group_to_add);
          });
          simple_wrapper.addControl('datamodelLinks', elements_arr);
        } else if (element === 'precision') {
          simple_wrapper.addControl(
            element,
            new FormControl(simple_condition[element], Validators.required)
          );
        }
      }
      simple.push(simple_wrapper);
    }
    return simple;
  }

  /**
   * Return empty template of case condition
   */
  getCaseCondition() {
    return new FormGroup({
      toleranceerrorrate: new FormControl(false, Validators.required),
      toleranceerrorratevalue: new FormControl(0, Validators.required),
      operationScope: new FormControl('', Validators.required),
      rounding: new FormControl('', Validators.required),
      condition_name: new FormControl(''),
      operations: new FormGroup({
        '0': new FormGroup({
          type: new FormControl('', Validators.required)
        }),
        '1': new FormGroup({
          type: new FormControl('operator', Validators.required),
          operator: new FormControl('', Validators.required)
        })
      }),
      datamodelLinks: new FormArray([])
    });
  }

  /**
   * Returns catalog pre condition template
   */
  getPreCondition() {
    return new FormGroup({
      '0': new FormGroup({
        type: new FormControl('datamodelid'),
        datamodelid: new FormControl('', Validators.required),
        groupoffieldsid: new FormControl('', Validators.required),
        fieldofinterestid: new FormControl('', Validators.required)
      }),
      '1': new FormGroup({
        type: new FormControl('operator'),
        operator: new FormControl('', Validators.required)
      }),
      '2': new FormGroup({
        type: new FormControl('value'),
        value: new FormControl('', Validators.required),
        datatype: new FormControl('', Validators.required)
      })
    });
  }
}
