/**
 * An object used to send pagination information to the server
 */
export class PaginationModel {
  // The number of elements in the page
  public page_size: number;
  // The current page number
  public page: number;
  // The attribute we choose for sorting
  public order_by: string;
  // The choosen order (1 = desc true or 0 = desc false)
  public desc: number;
  // The optional query
  public query: string;
  // Total number of elements
  public total_elements: number;

  constructor(
    pageSize: number,
    page: number,
    sortAttr: string,
    desc: number,
    query: string,
    total_elements?: number, // TODO: Remove optionality
    public max_elements: number = 10000 // default value is same as limit in backend
  ) {
    this.page_size = pageSize;
    this.page = page;
    this.order_by = sortAttr;
    this.desc = desc;
    this.query = query;
    this.total_elements = total_elements;
  }
}
