import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesPermissionsGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let allow = false;
    const user = this.authService.getLoggedInUser();
    if (user) {
      const modules = route.data.modules as Array<string>;
      if (
        modules &&
        !modules.every(m => this.authService.userCanViewModule(user, m))
      ) {
        this.router.navigate(['/']);
      }

      const perm = route.data.permissions as Array<string>;
      if (
        !user.isadmin &&
        perm &&
        !perm.every(p => this.authService.hasPermission(p))
      ) {
        this.router.navigate(['/']);
      }

      allow = true;
    }
    return allow;
  }
}
