import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from './alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) {}

  public downloadFile(documentId: number, type: String, endpoint?: string) {
    this.alertService.info(
      this.translate.transform('consolidation.downloadFile.download'),
      true,
      ''
    );
    const url = endpoint ? endpoint : 'documentDownload';
    let params = !endpoint ? { type } : {};
    if (type == 'pseudonymized' || type == 'filteredexcel') {
      params = { type };
    }

    this.apiService.get(`${url}/${documentId}/`, params, '').subscribe(
      data => {
        this.openFileToDownload(data);
        this.alertService.success(
          this.translate.transform(
            'consolidation.downloadFile.successdownload'
          ),
          true,
          ''
        );
        let message = this.translate.transform(
          'consolidation.downloadFile.successdownload'
        );
        console.log(message);
      },
      error => {
        this.alertService.error(
          this.translate.transform(
            'consolidation.downloadFile.unsuccessdownload'
          ),
          true,
          ''
        );
        console.log(error);
      }
    );
  }

  public downloadExternalFile(url, file, filename) {
    this.alertService.info(
      this.translate.transform('consolidation.downloadFile.download'),
      true,
      ''
    );
    this.apiService
      .get(url, '', '', file.mediatype, { responseType: 'blob' })
      .subscribe(
        data => {
          this.openExternalFile(data, filename);
          this.alertService.success(
            this.translate.transform(
              'consolidation.downloadFile.successdownload'
            ),
            false
          );
        },
        error => {
          this.alertService.error(
            this.translate.transform(
              'consolidation.downloadFile.unsuccessdownload'
            ),
            false
          );
          console.log(error);
        }
      );
  }

  public openExternalFile(data, filename) {
    let url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private openFileToDownload(data) {
    const fileName = data.name;
    const a: HTMLAnchorElement = document.createElement(
      'a'
    ) as HTMLAnchorElement;
    a.href = `data:${data.contenttype};base64,${data.file}`;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public downloadJSONFile(documentId: number, documentName: string, endpoint?: string) {
    this.alertService.info(
      this.translate.transform('consolidation.downloadFile.download'),
      true,
      ''
    );
    const url = endpoint ? endpoint : 'documentDownload';
    let params = documentId ? {documentid:documentId} : {};

    this.apiService.get(`${url}`, params, '').subscribe(
      data => {
        const str = JSON.stringify(data);
        const bytes = new TextEncoder().encode(str);
        const blob1 = new Blob([bytes], {
            type: "application/json;charset=utf-8"
        });

        this.downloadArraytoJSON(blob1, documentName);
        this.alertService.success(
          this.translate.transform(
            'consolidation.downloadFile.successdownload'
          ),
          true,
          ''
        );
        let message = this.translate.transform(
          'consolidation.downloadFile.successdownload'
        );
        console.log(message);
      },
      error => {
        this.alertService.error(
          this.translate.transform(
            'consolidation.downloadFile.unsuccessdownload'
          ),
          true,
          ''
        );
        console.log(error);
      }
    );
  }

  public downloadArraytoJSON(blobContent: any, documentName: string){
    var url = window.URL || window.webkitURL;
        var link = url.createObjectURL(blobContent);
        var a = document.createElement("a");
        a.download = `${documentName}.json`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

  }
}
