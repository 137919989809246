import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';
import { AuthenticationService } from '../../../security/authentication.service';
import { LayoutService } from '../../../services/layout.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { ExpirationDateService } from 'src/app/security/expiration-date.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public sidebarIsOpen: boolean = false;
  public userMenuIsOpen: boolean = false;
  public user: any;
  public _userPermissions: any;


  constructor(
    public authService: AuthenticationService,
    public titleService: Title,
    private titleCase: TitleCasePipe,
    private layoutService: LayoutService,
    public globalService: GlobalService,
    public alertService: AlertService,
    public expirationService: ExpirationDateService
  ) {
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    const title = this.authService.getTenantDisplayName();

    this.titleService.setTitle(
      title ? this.titleCase.transform(title) : 'Sire 2'
    );
  }

  /**
   * Toggle sidebar on hamburguer click
   */
  toggleSidebar() {
    this.layoutService.toggleSidebar();
    this.sidebarIsOpen = !this.sidebarIsOpen;
    this.userMenuIsOpen = false;
  }

  /**
   * Toggle user menu
   */
  toggleUserMenu() {
    this.layoutService.toggleUserMenu();
    this.userMenuIsOpen = !this.userMenuIsOpen;
    this.sidebarIsOpen = false;
  }

  /**
   * Return tenant path
   */
  public getTenantPath() {
    let returnTo = this.authService.getTenantName();

    const permission = this._userPermissions.SCREEN_CLIENT_DOCUMENTS;
    if (!this.user.isadmin && this.authService.hasPermission(permission)) {
      returnTo = returnTo + '/externalViewer';
    }

    return `/${returnTo}`;
  }
}
