import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleSequential, scaleBand } from 'd3-scale';
import { extent } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import * as d3scaleChromatic from 'd3-scale-chromatic'; // Don't worry, they're basically variables
const d3 = {
  select,
  selectAll,
  scaleSequential,
  scaleBand,
  extent,
  axisLeft,
  axisBottom,
  ...d3scaleChromatic
};

/**
 * Heatmap Component
 */
@Component({
  selector: 'app-d3heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3HeatMapComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  private cfg: any = {}; // Default configuration object
  private selection: any;
  private svg;
  private xScale: any;
  private yScale: any;
  private colorScale: any;
  private xAxis: any;
  private yAxis: any;

  constructor(elementRef: ElementRef) {
    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 400, // Default height
      margin: { top: 10, right: 10, bottom: 30, left: 40 },
      xkey: 'xkey', // Field to compute x coordenate
      ykey: 'ykey', // Field to compute y coordenate
      value: 'value', // Label to display
      label: false, // Label to display
      xAxis: '',
      yAxis: '',
      colorScale: 'interpolatePiYG' // SEQUENTIAL color scale. See more on https://github.com/d3/d3-scale-chromatic
    };
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart(this.data);
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    // Calcule vertical scale (Each block height)
    this.yScale = d3
      .scaleBand()
      .domain(this.data.map(d => d[this.cfg.ykey]))
      .range([this.cfg.height, 0]); // Inverse scale

    // Calcule horizontal scale (Each block width)
    this.xScale = d3
      .scaleBand()
      .domain(this.data.map(d => d[this.cfg.xkey]))
      .range([0, this.cfg.width]); // Min and max chart width

    // Calcule color scale
    this.colorScale = d3
      .scaleSequential(d3[this.cfg.colorScale])
      .domain(d3.extent(this.data, d => d[this.cfg.value]));
  }

  /**
   * Draw the chart
   */
  private drawChart(data) {
    let self = this;

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--heatmap')
      .attr(
        'viewBox',
        '0 0 ' +
          (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
          ' ' +
          (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Axis group
    let axisg = g.append('g').attr('class', 'chart__axis chart__axis--heatmap');

    // Vertical axis
    axisg
      .append('g')
      .attr('class', 'chart__axis-y chart__axis-y--heatmap')
      .call(d3.axisLeft(this.yScale));

    // Vertical axis title
    if (this.cfg.yAxis)
      axisg
        .append('text')
        .attr('y', -this.cfg.margin.left + 10)
        .attr('x', -this.cfg.height / 2 - this.cfg.margin.top)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(this.cfg.yAxis);

    // Botom axis
    let bottomAxis = axisg
      .append('g')
      .attr('class', 'chart__axis-x chart__axis-x--heatmap')
      .attr('transform', 'translate(0,' + this.cfg.height + ')')
      .call(d3.axisBottom(this.xScale));

    // Bottom axis title
    if (this.cfg.xAxis)
      axisg
        .append('text')
        .attr('y', this.cfg.height + 30)
        .attr('x', this.cfg.width / 2)
        .style('text-anchor', 'middle')
        .text(this.cfg.xAxis);

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--heatmap');

    // Rect (all) group
    let rectg = g.append('g').attr('class', 'chart__bars chart__bars--heatmap');

    // Rect (single) group
    let rects = rectg
      .selectAll('g')
      .data(this.data)
      .enter()
      .append('g')
      .attr('class', (d, i) => `chart__bar-group chart__bar-group--${i}`)
      .attr('transform', d => {
        return `translate(${this.xScale(d[this.cfg.xkey])},${this.yScale(
          d[this.cfg.ykey]
        )})`;
      });

    // Rect element
    rects
      .append('rect')
      .attr('class', 'chart__bar chart__bar--heatmap')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.xScale.bandwidth())
      .attr('height', this.yScale.bandwidth())
      .attr('fill', d => this.colorScale(d[this.cfg.value]))
      .on('mouseover', (d, i) => {
        let label =
        this.cfg.label && i[this.cfg.label]
        ? i[this.cfg.label]
        : i[this.cfg.xkey] + '-' + i[this.cfg.ykey];
        tooltip
          .html(() => {
            return `
            <div>${label}: ${i[this.cfg.value]}</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', () => {
        tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      });
  }
}
