import { Component, OnInit, Input } from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  FormArrayName
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-consolidated',
  templateUrl: './create-consolidated.component.html',
  styleUrls: ['./create-consolidated.component.scss']
})
export class CreateConsolidatedComponent implements OnInit {
  @Input() options;
  public body: string;
  public option: string;
  public action: string;
  public consolidateForm: FormGroup;
  public isAllowed: boolean = true;
  public datamodelsName: string = '';

  // Consolidate all documents
  public allDocument: boolean = false;
  public executiveWarning: boolean;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit() {
    this.option = 'components.modal.consolidate.' + this.options.type;
    this.action = 'components.modal.consolidate.' + this.options.action;
    this.consolidateForm = this.fb.group({
      consolidatedname: ['', Validators.required]
    });

    if (this.options.data.allDocuments) {
      this.allDocument = this.options.data.allDocuments;
      this.executiveWarning =
        this.options.data.requiredinformation !== 'pams_business_ppt';
    }

    if (this.options.data.requiredinformation === 'pams_consolidated_aia') {
      this.isAllowed = this.allowToConsolidate();
      const changeOrderController = new FormArray([
        new FormGroup({
          description: new FormControl(''),
          amount: new FormControl('')
        })
      ]);
      const budgetController = new FormArray([
        new FormGroup({
          description: new FormControl(''),
          amount: new FormControl('')
        })
      ]);

      const pams_aiaGroup = new FormGroup({
        from_contractor: new FormControl(''),
        to_owner: new FormControl(''),
        project: new FormControl(''),
        via_architect: new FormControl(''),
        application_number: new FormControl(''),
        application_date: new FormControl(''),
        period_to: new FormControl(''),
        project_number: new FormControl(''),
        contract_date: new FormControl(''),
        invoice: new FormControl(''),
        previous_months_additions: new FormControl(''),
        previous_months_deductions: new FormControl(''),
        previous_certificates_payments: new FormControl(''),
        change_order: changeOrderController,
        budget_transfer: budgetController
      });
      this.consolidateForm.addControl('consolidateddata', pams_aiaGroup);
    } else if (this.options.data.requiredinformation === 'pams_dummy_po') {
      const taxes_dummy_po = new FormArray([]);
      const documentsSelected = this.options.data.documentsSelected;
      documentsSelected.forEach(doc => {
        const documentTaxes = new FormGroup({
          documentid: new FormControl(doc.documentid),
          taxid: new FormControl('', Validators.required)
        });
        taxes_dummy_po.push(documentTaxes);
      });
      this.consolidateForm.addControl('consolidateddata', taxes_dummy_po);
      const poNumberGroup = new FormGroup({
        ponumber: new FormControl('', Validators.required)
      });
      this.consolidateForm.addControl('ponumber', poNumberGroup);
    }
  }

  /**
   * Function to check if the datamodels of the selected documents
   * includes the needed ones for some consolidation type
   */
  private allowToConsolidate() {
    const documentsSelected = this.options.data.documentsSelected;
    const datamodelsNeeded = this.options.data.datamodelsNeeded;

    let l = [];
    documentsSelected.forEach(ds => {
      l.push(ds.datamodel.datamodelid);
    });

    let allow = true;
    datamodelsNeeded.forEach(dm => {
      this.datamodelsName =
        this.datamodelsName === ''
          ? dm.datamodeldisplayname
          : this.datamodelsName + ', ' + dm.datamodeldisplayname;
      if (!l.includes(dm.datamodelid)) {
        allow = false;
      }
    });

    return allow;
  }

  onAddRow(type: string) {
    const groups = this.consolidateForm.get('consolidateddata')['controls'][
      type
    ] as FormArray;
    groups.push(
      this.fb.group({
        description: [''],
        amount: ['']
      })
    );
  }

  onRemoveRow(type: string, groupindex: number) {
    const groups = this.consolidateForm.get('consolidateddata')['controls'][
      type
    ] as FormArray;
    groups.removeAt(groupindex);
  }

  onSubmit() {
    const consolidateData = this.consolidateForm.value;
    this.activeModal.close(consolidateData);
  }
}
