import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleLinear, scaleBand, scaleOrdinal } from 'd3-scale';
import { max, sum } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import * as d3scaleChromatic from 'd3-scale-chromatic';
import { StyleManagerService } from 'src/app/services/stylemanager.service';
const d3 = {
  select,
  selectAll,
  scaleLinear,
  scaleBand,
  scaleOrdinal,
  max,
  sum,
  axisLeft,
  axisBottom,
  ...d3scaleChromatic
};

/**
 * Vertical Barchar Component
 */
@Component({
  selector: 'app-d3barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3BarChartComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  @Output() onclick = new EventEmitter();
  private cfg: any = {}; // Default configuration object
  private selection: any;
  private svg;
  private rects;
  private xScale: any;
  private yScale: any;
  private colorScale: any;
  private xAxis: any;
  private yAxis: any;
  private totalValue: number;
  private selectedKey: string;

  constructor(
    elementRef: ElementRef,
    styleManager: StyleManagerService
  ) {
    const style = styleManager.styleSettings;

    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 400, // Default height
      margin: { top: 10, right: 30, bottom: 50, left: 40 },
      key: 'key', // Value to compute
      label: 'label', // Label to display
      barValue: false, // Value to place in bar
      labelRotation: 0,
      colorScheme: style.charts.defaultColorScheme,
      colorKeys: {},
      colorUnselect: 'rgba(111, 111, 111, 0.82)',
      yAxis: '', // yAxis title
      yScaleTicks: 5, // yAxis ticks
      yScaleFormat: '.0f', // yAxis format. See more on https://github.com/d3/d3-format
      xScaleSteps: null, // xAxis divisions
      barPadding: 0.1, // Separation between bars
      showAxis: true // Show or hide axis
    };
  }

  ngOnChanges() {
    if (this.data) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart(this.data);
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    this.totalValue = d3.sum(this.data, d => d[this.cfg.key]);
    const maxY = d3.max(this.data, d => d[this.cfg.key]);

    // Calcule vertical scale (Bar's height)
    this.yScale = d3
      .scaleLinear()
      .domain([0, maxY ? maxY : 100])
      .range([this.cfg.height, 0]); // Inverse scale

    // Calcule horizontal scale (Bar's width)
    this.xScale = d3
      .scaleBand()
      .domain(this.data.map(d => d[this.cfg.label]))
      .range([0, this.cfg.width]) // Min and max chart width
      .padding(this.cfg.barPadding);

    if (this.cfg.colorScheme instanceof Array) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }
  }

  /**
   * Draw the chart
   */
  private drawChart(data) {
    // Remove previus chart if exists
    this.selection.selectAll('svg.chart').remove();
    this.selectedKey = undefined;

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--barchart')
      .classed('chart--clickable', this.onclick.observers.length > 0)
      .attr(
        'viewBox',
        '0 0 ' +
        (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
        ' ' +
        (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Axis group
    let axisg = g
      .append('g')
      .attr('class', 'chart__axis chart__axis--barchart');

    // Vertical axis (horizontal grid)
    if (this.cfg.showAxis)
      axisg
        .append('g')
        .attr('class', 'chart__axis-y chart__axis-y--barchart chart__grid')
        .call(
          d3
            .axisLeft(this.yScale)
            .tickSize(-this.cfg.width)
            .ticks(this.cfg.yScaleTicks, this.cfg.yScaleFormat)
        );
    else
      axisg
        .append('g')
        .attr('class', 'chart__axis-y chart__axis-y--barchart')
        .append('line')
        .attr('x1', 0)
        .attr('x2', this.cfg.width)
        .attr('y1', this.cfg.height)
        .attr('y2', this.cfg.height);

    // Vertical axis title
    if (this.cfg.showAxis && this.cfg.yAxis)
      axisg
        .append('text')
        .attr('y', -this.cfg.margin.left + 10)
        .attr('x', -this.cfg.height / 2 - this.cfg.margin.top)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(this.cfg.yAxis);

    const xAxis = d3.axisBottom(this.xScale);

    if (this.cfg.xScaleSteps)
      xAxis.tickValues(
        this.xScale.domain().filter((d, i) => !(i % this.cfg.xScaleSteps))
      );

    // Botom axis
    let bottomAxis = axisg
      .append('g')
      .attr('class', 'chart__axis-x chart__axis-x--barchart')
      .attr('transform', 'translate(0,' + this.cfg.height + ')')
      .call(xAxis);

    // Bottom axis onclick event
    bottomAxis.selectAll('.tick').on('click', (d, i) => {
      this.elementClick(data.filter(j => j[this.cfg.label] == i)[0]);
    });

    // Bottom axis label rotation
    if (this.cfg.labelRotation != 0)
      bottomAxis
        .selectAll('text')
        .attr('y', Math.cos((this.cfg.labelRotation * Math.PI) / 180) * 9)
        .attr('x', Math.sin((this.cfg.labelRotation * Math.PI) / 180) * 9)
        .attr('dy', '.35em')
        .attr('transform', `rotate(${this.cfg.labelRotation})`)
        .style('text-anchor', 'start');

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--barchart');

    // Bars (all) group
    let barg = g.append('g').attr('class', 'chart__bars chart__bars--barchart');

    // Bar (single) group
    let bars = barg
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('class', (d, i) => {
        return `chart__bar-group chart__bar-group--${i}`;
      })
      .attr('transform', d => {
        return `translate(${this.xScale(d[this.cfg.label])},0)`;
      });

    // Rect element
    this.rects = bars
      .append('rect')
      .attr('class', 'chart__bar chart__bar--barchart')
      .attr('fill', d => this.barColor(d))
      .attr('x', 0)
      .attr('y', d => this.yScale(+d[this.cfg.key]))
      .attr('width', this.xScale.bandwidth())
      .attr('height', d => {
        return this.cfg.height - this.yScale(+d[this.cfg.key]);
      })
      .on('mouseover', (d, i) => {
        tooltip
          .html(() => {
            let percent =
              Math.round(
                (i[this.cfg.key] * Math.pow(10, 4)) / this.totalValue
              ) / Math.pow(10, 2);
            return `
            <div>${i[this.cfg.label]}: ${i[this.cfg.key]} (${percent}%)</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', () => {
        tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      })
      .on('click', (d, i) => this.elementClick(i));

    if (this.cfg.barValue)
      bars
        .append('text')
        .attr('class', 'chart__barvalue')
        .attr('x', this.xScale.bandwidth() / 2)
        .attr('y', d => this.yScale(+d[this.cfg.key]) - 10)
        .style('text-anchor', 'middle')
        .text(d => d[this.cfg.barValue]);
  }

  /**
   * onclick event handler
   */
  private elementClick(d: any) {
    // If there isn't an onclick listener on the component, don't do anything
    if (this.onclick.observers.length == 0) return;

    if (d[this.cfg.label] != this.selectedKey) {
      // Change rects color
      this.rects
        .attr('fill', this.cfg.colorUnselect)
        .filter(j => j == d)
        .attr('fill', j => this.barColor(j));

      // Store selected value
      this.selectedKey = d[this.cfg.label];
    } else {
      // Change rects color
      this.svg.selectAll('.chart__bar').attr('fill', j => this.barColor(j));

      // Remove stored value
      this.selectedKey = undefined;
    }
    // Emit event
    this.onclick.emit(d);
  }

  /**
   * Compute bar color
   */
  private barColor(d) {
    if (
      this.cfg.colorKeys &&
      this.cfg.colorKeys.hasOwnProperty(d[this.cfg.key])
    ) {
      return this.cfg.colorKeys[d[this.cfg.key]];
    } else {
      return this.colorScale(d[this.cfg.key]);
    }
  }
}
