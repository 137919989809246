import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventTrackerService } from 'src/app/services/event-tracker.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from '../../security/authentication.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public user: any;
  public currentTab: string;
  public dashboardsTabs = [];
  public _userPermissions: any;

  public token: any;
  public dashboards = [];

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private global: GlobalService
  ) {
    this.currentTab = 'generalDashboard';
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.token = authService.getToken();
  }

  ngOnInit() {
    this.dashboards = this.authService.getUserDashboards();
    if (this.user.isadmin || this.authService.hasPermission(this._userPermissions.SCREEN_VIEW_DASHBOARDS_SIRE)) {
      this.dashboardsTabs.push('generalDashboard');
    }
    if (this.user.isadmin || this.authService.hasPermission(this._userPermissions.SCREEN_VIEW_DASHBOARDS_POWERBI)) {
      this.dashboards.forEach(d => {
        this.dashboardsTabs.push(d.title);
      });
    }
  }

  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }
}
