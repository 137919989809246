import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ValidationsDocumentListComponent } from '../validations-review/validations-document-list/validations-document-list.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { EventTrackerService } from 'src/app/services/event-tracker.service';
import { Router } from '@angular/router';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-output-files',
  templateUrl: './output-files.component.html',
  styleUrls: ['./output-files.component.scss']
})
export class OutputFilesComponent implements OnInit, OnDestroy {
  @ViewChild(ValidationsDocumentListComponent)
  private assignedTable: ValidationsDocumentListComponent;

  public currentTab: string;
  public subscriptionConsolidations: Subscription;
  public loading: boolean;

  constructor(
    private authService: AuthenticationService,
    private global: GlobalService,
    private trackerService: EventTrackerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    authService.checkAccess('OutputFilesFunctionality');
  }

  ngOnInit() {
    this.loading = true
    if (!this.global.passedWatcherUtils) {
    this.global
        .watchUtils()
        .subscribe(() => {
          this.initOutputFiles()
        });
    } else {
      this.initOutputFiles()
    }
  }

  private initOutputFiles() {
    const consolidations = this.global.getConsolidations();
    if (consolidations && consolidations.length > 0) this.currentTab = consolidations[0].consolidatedtypename
    this.loading = false;
  }

  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {
    if (this.subscriptionConsolidations) {
      this.subscriptionConsolidations.unsubscribe();
    }
  }
}
