import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { QuerysheetDetailsComponent } from './pages/querysheet-details/querysheet-details.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChanges } from './components/modals/unsaved-changes/unsaved-changes.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard
  implements CanDeactivate<QuerysheetDetailsComponent>
{
  constructor(private modalService: NgbModal) {}
  canDeactivate(
    component: QuerysheetDetailsComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Check if there are unsaved changes
    if (component.hasUnsavedChanges()) {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'sm'
      };

      const modalWindowRef = this.modalService.open(
        UnsavedChanges,
        modalOptions
      );

      return modalWindowRef.result.then(result => {
        return !!result;
      });
    }
    return true;
  }
}
