import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CognitiveSearchService } from '../../cognitive-search.service';

@Component({
  selector: 'app-validated-filter',
  templateUrl: './validated-filter.component.html',
  styleUrls: ['./validated-filter.component.scss']
})
export class ValidatedFilterComponent implements OnInit {
  @Input() public validatedFilter: FormGroup;
  @Input() public fieldOfInterestList: any[];
  filteredOptions: Observable<string[]>;
  public typeSelected: string;

  constructor(private cognitiveService: CognitiveSearchService) {}

  ngOnInit(): void {
    if (this.validatedFilter.get('type').value != '') {
      this.typeSelected = this.validatedFilter.get('type').value;
    }
    this.validatedFilter.markAllAsTouched();

    this.filteredOptions = this.validatedFilter
      .get('field_of_interest_name')
      .valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return Object.keys(this.fieldOfInterestList).filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  optionSelected(event) {
    this.cognitiveService.addFieldOfInterest(
      this.validatedFilter,
      this.fieldOfInterestList[event.option.value]
    );
  }

  onChangeType() {
    if (this.validatedFilter.get('value').value != '')
      this.validatedFilter.get('value').setValue('');
    this.typeSelected = this.validatedFilter.get('type').value;
  }
}
