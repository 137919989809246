import { Injectable } from '@angular/core';

import { StyleSettings } from '../theme/theme-components/style-settings/style-settings';
import { getDefaultStyleSettings } from '../theme/theme-components/style-settings/default';
import { getLightThemeStyleSettings } from '../theme/theme-components/style-settings/light';
import { getBlueThemeStyleSettings } from '../theme/theme-components/style-settings/blue';
import { getDashboardColorPalette } from '../theme/theme-components/dashboard-palettes/dashboard-palettes';
import { Themes } from '../theme/theme-components/themes';

/**
 * Handle dynamic theming changes.
 */
@Injectable({
  providedIn: 'root'
})
export class StyleManagerService {

  public currentTheme: string;
  public styleSettings: StyleSettings;

  constructor() { }

  /**
   * Set theme.
   * @param theme theme name.
   */
  setTheme(theme: string): void {
    this.currentTheme = theme;

    let themeCssUrl: string;
    let styleSettings: StyleSettings;
    switch (theme) {
      case Themes.THEME_DEFAULT:
        //themeCssUrl = '/assets/themes/_default.css';
        themeCssUrl = '';
        styleSettings = getDefaultStyleSettings();
        break;

      case Themes.THEME_LIGHT:
        themeCssUrl = '/light.css';
        styleSettings = getLightThemeStyleSettings();
        break;

      case Themes.THEME_BLUE:
        themeCssUrl = '/blue.css';
        styleSettings = getBlueThemeStyleSettings();
        break;

      default:
        console.log('unknown theme');
        return;
    }

    this.setStyle('theme', themeCssUrl);
    this.styleSettings = styleSettings;
  }

  /**
   * Set the stylesheet with the specified key.
   */
  setStyle(key: string, href: string): void {
    getLinkElementForKey(key).setAttribute('href', href);
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  removeStyle(key: string): void {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }

  /**
   * Get dashboard color palette.
   */
  getDashboardColorPalette(dashboard: any): any {
    if (dashboard.colorPalette) {
      const colorPalette = JSON.parse(dashboard.colorPalette);

      if (this.currentTheme == Themes.THEME_DEFAULT) {
        return colorPalette;

      } else {
        const customColorPalette =
          getDashboardColorPalette(
            this.currentTheme,
            colorPalette.name
          );

        return customColorPalette ?? colorPalette;
      }

    } else {
      return {};
    }
  }

}

/**
 * Obtain theme style tag or create it if doesn't exists
 */
function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

/**
 * Return theme style tag or false if doesn't exists
 */
function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}

/**
 * Create style tag
 */
function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('type', 'text/css');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

/**
 * Generate class name for theme style tags
 */
function getClassNameForKey(key: string) {
  return `style-manager-${key}`;
}
