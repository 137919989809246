import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    } else if (!searchText) {
      return items;
    } else {
      searchText = searchText.toLowerCase();
      return items.filter(it => {
        return it.toLowerCase().includes(searchText);
      });
    }
  }
}
