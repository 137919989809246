import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectModel } from '../../../models/project-mstr.model';
import { PaginationService as PS } from 'src/app/services/pagination.service';
import { PaginationModel } from '../../../models/pagination';

@Component({
  selector: 'app-project-grid-view',
  templateUrl: './project-grid-view.component.html',
  styleUrls: ['./project-grid-view.component.scss']
})
export class ProjectGridViewComponent implements OnInit {
  public projectList: Array<ProjectModel> = [];

  @Input() data;
  @Input() loading;
  @Input() backgroundLoading: boolean;
  @Input() settings;
  @Output() loadingChange = new EventEmitter();

  constructor(private paginationService: PS) {}

  ngOnInit() {}

  /**
   * Handle navigation page change
   */
  public onPageChange(page) {
    this.loadingChange.emit(true);
    this.paginationService.changePagination('projects', 'page', page);
    this.settings['getDataFromDB']();
  }
}
