import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { ProjectModel } from 'src/app/models/project-mstr.model';
import { AnalysisModel } from 'src/app/models/analysis.model';
import { DocumentModel } from '../../models/document.model';
import { KPIModel } from 'src/app/models/kpi.model';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { LinkService } from 'src/app/services/link.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateAnalysisPages } from '../../components/modals/create-analysis/create-analysis.pages';
import { UpdateDocsComponent } from '../../components/modals/update-docs/update-docs.component';
import { RenameModalComponent } from '../../components/modals/rename-modal/rename-modal.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { EventTrackerService } from 'src/app/services/event-tracker.service';
import { AlertService } from 'src/app/services/alert.service';
import { catchError } from 'rxjs/operators';
import * as angular from 'angular';

@Component({
  selector: 'app-analysis-details',
  templateUrl: './analysis-details.pages.html',
  styleUrls: ['./analysis-details.pages.scss']
})
export class AnalysisDetailsComponent implements OnInit, OnDestroy {
  public projectInfo: ProjectModel;
  public hasEnrichmentModule: boolean;
  public user: any;
  public projectId: number;
  public analysisInfo: AnalysisModel;
  public analysisId: number;
  public kpiInfo: KPIModel;
  private dataSub: Subscription;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };
  public currentTab: string;
  private onItemAdded = new Subject<DocumentModel>();
  public editTitle: boolean = false;

  // Const
  public _userPermissions: any;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public apiService: ApiService,
    public global: GlobalService,
    public link: LinkService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private trackerService: EventTrackerService,
    private alertService: AlertService
  ) {
    this.currentTab = 'documents';
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.hasEnrichmentModule = this.authService.userCanViewModule(
      this.user,
      'EnrichmentCoreFunctionality'
    );
  }

  ngOnInit() {
    this.dataSub = this.route.params.subscribe((params: Params) => {
      // Get current project data
      this.projectId = this.link.getIdFromSlug(params.projectidname);
      this.global.getProject(this.projectId).then((project: ProjectModel) => {
        this.link.checkProjectSlug(params.projectidname, project);
        this.global.setProject(project);
        this.projectInfo = project;
      });

      // Get current analysis data
      this.analysisId = this.link.getIdFromSlug(params.analysisidname);
      this.global
        .getAnalysis(this.analysisId)
        .then((analysis: AnalysisModel) => {
          this.link.checkAnalysisSlug(params.analysisidname, analysis);
          this.global.setAnalysis(analysis);
          this.analysisInfo = analysis;
        });

      // Get or retrieve KPIs data
      // this.getKPIInfo();
    });
  }

  /**
   * Get KPI info from storage or database
   */
  private getKPIInfo() {
    this.global.getKPIs('analysis', this.analysisId).then((data: KPIModel) => {
      this.global.setKPIs('analysis', this.analysisId, data);
      this.kpiInfo = new KPIModel(data);
    });
  }

  /**
   * Document upload handler
   */
  public onProcessDocs() {
    const options = {
      level: 'analysis',
      projectid: this.projectId,
      projectname: this.projectInfo.projectname,
      analysisid: this.analysisInfo.analysisid,
      analysisname: this.analysisInfo.analysisname
    };
    const modalWindowRef = this.modalService.open(
      CreateAnalysisPages,
      this.modalOptions
    );
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValues => {
      if (
        formValues &&
        formValues.files &&
        formValues.files.length > 0 &&
        formValues.reason == undefined
      ) {
        let promises = [];

        for (const file of formValues.files) {
          let formData = new FormData();
          formData.append('projectid', this.projectId.toString());
          formData.append('analysisid', this.analysisId.toString());
          formData.append('analysisname', formValues.analysisName);
          formData.append('datamodelid', formValues.datamodelId);
          formData.append('language', formValues.language);
          formData.append('files', file, file.name);
          if (formValues.enrichment) {
            formData.append(
              'enrichment',
              formValues.enrichment,
              formValues.enrichment.name
            );
            formData.append(
              'additionaldocumenttypeid',
              formValues.enrichmentTypeid
            );
          }

          promises.push(this.apiService.post('documents', formData, '', false));
        }
        const obs = forkJoin(
          promises.map(httpRequest =>
            httpRequest.pipe(catchError(() => of(undefined)))
          )
        ).subscribe(responses => {
          const failures = responses.filter(response => response === undefined);
          if (failures.length > 0) {
            this.alertService.error(
              this.translate.transform('analysisDetails.unsuccessfulUpload'),
              false
            );
          } else {
            this.alertService.success(
              this.translate.transform('analysisDetails.successfulUpload'),
              false
            );
          }
        });
      }
      if (
        formValues &&
        formValues.reason != undefined &&
        formValues.process == true
      ) {
        this.onItemAdded.next();
        this.paginationService.changePagination('documents', 'page', 1);
      }
    });
  }

  /**
   * Enrichment files upload handler
   */
  public onUpdateDocs() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options = {
      level: 'analysis',
      projectId: this.projectId,
      projectName: this.projectInfo.projectname,
      analysisId: this.analysisInfo.analysisid,
      analysisName: this.analysisInfo.analysisname
    };
    const modalWindowRef = this.modalService.open(
      UpdateDocsComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValues => { });
  }

  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }

  /**
   * On destroy handler
   */
  ngOnDestroy() {
    // Unsubscribe to route change
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  /**
   * 
   *On rename analysis handler
  */
  public onAnalysisProject() {
    // Change the name of Analysis
    const headTag = document.getElementById('editable__analysis__title');
    const iconBtn = document.getElementById('editable__analysis__icon');
    const popModal = this.modalService.open(
      RenameModalComponent,
      this.modalOptions
    );
    const data = {
      projectid: this.projectId,
      title: "Analysis",
      endpoint: `analysis/${this.analysisId}`,
      iconBtn: iconBtn,
      headTag: headTag,
      currentName: headTag.innerText,
      previousName: headTag.innerText
    }
    popModal.componentInstance.data = data;
  }
}
