import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { format } from 'd3-format';
import { StyleManagerService } from 'src/app/services/stylemanager.service';

const d3 = { select, selectAll, scaleLinear, line, format };

/**
 * Radarchar Component
 */
@Component({
  selector: 'app-d3radarchart',
  templateUrl: './radarchart.component.html',
  styleUrls: ['./radarchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3RadarChartComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  private cfg: any = {}; // Default configuration object
  private selection;
  private svg;
  private scale;
  private line;
  private numFormat;
  private angle: number;
  private maxn: number = -999999999;
  private minn: number = 999999999;
  private innerCircleData = <any>[];
  private tdata = <any>[];
  private loaded: boolean = false;

  constructor(
    elementRef: ElementRef,
    styleManager: StyleManagerService
  ) {
    const style = styleManager.styleSettings;

    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 300, // Default height
      margin: { top: 10, right: 30, bottom: 10, left: 30 },
      keys: ['key'], // Values to compute
      label: 'column', // Column label to display
      gridSteps: 5, // Steps in grid lines (number of axis circles)
      colors: style.charts.defaultColorScheme,
      maxValue: false,
      minValue: false,
      gridPadding: 0.05, // Grid lines extra size
      gridTextPadding: 0.1, // Grid text separation
      pointRadius: 3, // Size of each area vertex
      pointHoverRadius: 8, // Size of each area vertex (area to allow tooltip)
      numFormat: '.4n' // Number format, see https://github.com/d3/d3-format
    };
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    let self = this;
    this.angle = (Math.PI * 2) / this.cfg.keys.length;

    // Calcule or set maximun for the scale
    if (this.cfg.maxValue === false) {
      this.data.forEach(d => {
        self.cfg.keys.forEach(k => {
          self.maxn = d[k] > self.maxn ? d[k] : self.maxn;
        });
      });
    } else {
      this.maxn = this.cfg.maxValue;
    }

    // Calcule or set minimun for the scale
    this.minn = this.cfg.minValue === false ? 0 : this.cfg.minValue;

    // Calcule scale
    this.scale = d3
      .scaleLinear()
      .range([0, Math.min(this.cfg.height, this.cfg.width) / 2])
      .domain([this.minn, this.maxn]);

    // Calcule grid
    this.innerCircleData = [];
    let gridStepValue =
      this.maxn >= this.cfg.gridSteps
        ? (this.maxn - (this.maxn % this.cfg.gridSteps)) / this.cfg.gridSteps
        : 1;
    for (var i = 1; i <= this.maxn; i++)
      this.innerCircleData.push(i * gridStepValue);

    // Calculate transpose data to draw paths
    this.tdata = [];
    this.data.forEach(d => {
      let q = { name: d[self.cfg.label], values: [] };
      self.cfg.keys.forEach(function (k, i) {
        q.values.push({
          value: d[k] ? d[k] : self.minn,
          column: i,
          name: k
        });
      });
      q.values.push(q.values[0]);
      self.tdata.push(q);
    });

    // draw line function
    this.line = d3
      .line()
      .x(d => {
        return (
          this.originCenter()[0] +
          this.scale(Math.sin(d['column'] * this.angle) * d['value']) -
          this.scale(Math.sin(d['column'] * this.angle) * this.minn)
        );
      })
      .y(d => {
        return (
          this.originCenter()[1] +
          this.scale(Math.cos(d['column'] * this.angle) * d['value']) -
          this.scale(Math.cos(d['column'] * this.angle) * this.minn)
        );
      });

    this.numFormat = d3.format(this.cfg.numFormat);
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    let self = this;

    // Remove previus chart if exists
    if (this.loaded) this.selection.selectAll('svg').remove();

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--radarchart')
      .attr(
        'viewBox',
        '0 0 ' +
        (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
        ' ' +
        (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Axis group
    let axisg = g
      .append('g')
      .attr('class', 'chart__axis chart__axis--radarchar');

    // Grid lines
    axisg
      .selectAll('line')
      .data(this.cfg.keys)
      .enter()
      .append('line')
      .attr(
        'class',
        'chart__axis-line chart__axis-line--radarchart chart__grid'
      )
      .attr('x1', this.originCenter()[0])
      .attr('y1', this.originCenter()[1])
      .attr('x2', (d, i) => {
        return (
          this.originCenter()[0] +
          this.scale(
            Math.sin(i * this.angle) * (this.maxn * (1 + this.cfg.gridPadding))
          ) -
          this.scale(Math.sin(i * this.angle) * this.minn)
        );
      })
      .attr('y2', (d, i) => {
        return (
          this.originCenter()[1] +
          this.scale(
            Math.cos(i * this.angle) * (this.maxn * (1 + this.cfg.gridPadding))
          ) -
          this.scale(Math.cos(i * this.angle) * this.minn)
        );
      });

    // Grid circles
    axisg
      .selectAll('circle')
      .data(this.innerCircleData)
      .enter()
      .append('circle')
      .attr(
        'class',
        'chart__axis-circle chart__axis-circle--radarchart chart__grid'
      )
      .attr('cx', this.originCenter()[0])
      .attr('cy', this.originCenter()[1])
      .attr('r', d => this.scale(d));

    // Grid texts
    axisg
      .selectAll('text')
      .data(this.cfg.keys)
      .enter()
      .append('text')
      .attr('class', 'chart__axis-text chart__axis-text--radarchart')
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => {
        return (
          this.originCenter()[0] +
          this.scale(
            Math.sin(i * this.angle) *
            (this.maxn * (1 + this.cfg.gridTextPadding))
          ) -
          this.scale(Math.sin(i * this.angle) * this.minn)
        );
      })
      .attr('y', (d, i) => {
        return (
          this.originCenter()[1] +
          this.scale(
            Math.cos(i * this.angle) *
            (this.maxn * (1 + this.cfg.gridTextPadding))
          ) -
          this.scale(Math.cos(i * this.angle) * this.minn)
        );
      })
      .text(d => {
        return d;
      });

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--radarchart');

    // Series group (all paths)
    let seriesg = g
      .append('g')
      .attr('class', 'chart__areas chart__areas--radarchart');

    // Draw entities for each series (each chart area)
    this.tdata.forEach((t, u) => {
      // Create items subgroup
      t.serie = seriesg
        .append('g')
        .attr('id', 'radarchart-area-' + u)
        .attr('class', 'chart__area-group chart__area-group--radarchart');

      // Draw areas
      t.serie
        .append('path')
        .datum(t.values)
        .attr('class', 'chart__area chart__area--radarchart')
        .attr('stroke', this.cfg.colors[u])
        .attr('fill', this.cfg.colors[u])
        .attr('d', this.line);

      // Points group
      let pointsg = t.serie
        .selectAll('.chart__points-group')
        .data(t.values)
        .enter()
        .append('g')
        .attr('fill', 'transparent')
        .attr('class', 'chart__points-group chart__points-group--radarchart')
        .attr('transform', d => {
          let cx =
            this.originCenter()[0] +
            this.scale(Math.sin(d.column * this.angle) * d.value) -
            this.scale(Math.sin(d.column * this.angle) * this.minn);
          let cy =
            this.originCenter()[1] +
            this.scale(Math.cos(d.column * this.angle) * d.value) -
            this.scale(Math.cos(d.column * this.angle) * this.minn);
          return `translate(${cx},${cy})`;
        });

      // Hover points
      pointsg
        .append('circle')
        .attr('class', 'chart__hover-point chart__hover-point--radarchart')
        .attr('fill', 'transparent')
        .attr('r', self.cfg.pointHoverRadius)
        .on('mouseover', (d, i) => {
          tooltip
            .html(() => {
              return `
              <div>${t.name}</div>
              <div>${i.name}: ${this.numFormat(i.value)}</div>
            `;
            })
            .classed('active', true);
        })
        .on('mouseout', d => {
          tooltip.classed('active', false);
        })
        .on('mousemove', d => {
          tooltip
            .style('left', window.event['pageX'] - 100 + 'px')
            .style('top', window.event['pageY'] - 76 + 'px');
        });

      // Visible points
      pointsg
        .append('circle')
        .attr('class', 'chart__point chart__point--radarchart')
        .attr('pointer-events', 'none')
        .attr('fill', () => self.cfg.colors[u])
        .attr('r', self.cfg.pointRadius);
    });

    this.loaded = true;
  }

  /**
   * Calcule canvas center to easuly translate calculus
   */
  originCenter() {
    return [this.cfg.width / 2, this.cfg.height / 2];
  }
}
