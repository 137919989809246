import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-querysheet-grid-view',
  templateUrl: './querysheet-grid-view.component.html',
  styleUrls: ['./querysheet-grid-view.component.scss']
})
export class QuerysheetGridViewComponent implements OnInit {
  @Input() data;
  @Input() settings;
  @Input() loading;
  @Input() qsAllowedRoles;
  @Input() user;
  @Output() loadingChange = new EventEmitter();

  constructor(private paginationService: PaginationService) {}

  ngOnInit(): void {}

  /**
   * Handle navigation page change
   */
  public onPageChange(page): void {
    this.loadingChange.emit(true);
    this.paginationService.changePagination('querysheets', 'page', page);
    this.settings['getDataFromDB']();
  }
}
