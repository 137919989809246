import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-type-field',
  templateUrl: './type-field.component.html',
  styleUrls: ['./type-field.component.scss']
})
export class TypeFieldComponent implements OnInit {
  @Input('parentForm') parentForm: FormGroup;

  public fieldtypes: any[] = [];
  public entities: any[] = [];

  constructor(public global: GlobalService) {}

  ngOnInit() {
    this.global.getFieldTypes().then(result => (this.fieldtypes = result));
    this.getEntities();
  }

  public showEntityList() {
    let show = false;
    const value = this.parentForm.get('fieldtypeid').value;
    if (value && this.fieldtypes.length > 0) {
      const type = this.fieldtypes.find(f => f.fieldtypeid === value);
      show = this.parentForm.get('entity') && type.fieldtypename === 'Text';
    }
    return show;
  }

  public changeEntityList() {
    if (!this.parentForm.get('entity')) return;
    if (this.showEntityList()) {
      this.parentForm.get('entity').setValidators(Validators.required);
    } else {
      this.parentForm.get('entity').clearValidators();
      this.parentForm.get('entity').setValue(null);
    }
    this.parentForm.get('entity').updateValueAndValidity();
  }

  //TODO: Temporal while we get the entities from the db
  private getEntities(): void {
    this.entities = [
      'number',
      'phone-number',
      'ordinal',
      'amount',
      'date',
      'email',
      'url',
      'org',
      'person',
      'gpe',
      'product',
      'norp',
      'language',
      'loc',
      'misc',
      'fac',
      'percent',
      'per'
    ];
  }
}
