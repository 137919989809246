import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { DashboardsModel } from "src/app/models/document-dashboard.model";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentDashboardService {

  constructor(
    private api: ApiService
  ) { }

  /**
   * Get dashboard data.
   */
  getDashboards(documentId: string): Observable<DashboardsModel> {
    return this.api.get(
      `dashboards/${documentId}`,
      {},
      ''
    );
  }

}
