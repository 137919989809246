import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { TrainableService } from '../../trainable.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-table',
  templateUrl: './trainable-table.component.html',
  styleUrls: ['./trainable-table.component.scss']
})
export class TrainableTableComponent implements OnInit {
  @Input() public table: FormArray;

  public expandedField = [];

  constructor(
    private translate: TranslatePipe,
    private trainableService: TrainableService
  ) {}

  ngOnInit() {
    console.log(this.table);
    this.table.markAllAsTouched();
    this.toggleExpandedField(0);
  }

  public addField() {
    this.trainableService.addField(this.table, 2);
  }

  public addHeader(form, validator?) {
    this.trainableService.addNewInputNameControl(form, validator);
  }

  public removeHeader(form, index) {
    this.trainableService.removeInputControl(form, index);
  }

  /**
   * Toggle field expanded
   * @param index field index number
   */
  private toggleExpandedField(index: number) {
    const index_element = this.expandedField.indexOf(index);
    if (index_element >= 0) {
      this.expandedField.splice(index_element, 1);
    } else {
      this.expandedField.push(index);
    }
  }

  /**
   * Check if field is expanded
   * @param index field index number
   */
  private isExpandedField(index: number) {
    return this.expandedField.indexOf(index) !== -1;
  }

  public getFieldName(value) {
    return value ? value : this.translate.transform('trainable.table.newField');
  }
}
