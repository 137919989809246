export function restructureQueryTreeNodes(queryTree) {
    /**
     * Addressing 2 scenarios here -
     * 1. Remove group empty group (name = 'nan') from independent queries and add display order of the group in the query
     * 2. Semantic and QnA query - merge these records into one, make QnA record in the Semantic type for same display order
     * in both these steps, store the Group details or query details so they can be used in case of edit/delete from UI
     */

    const treeNodes = [];
    queryTree.forEach(node => {
      const groupNodes = node.children.filter(item => item.hasChildren);
      const leafNodes = node.children.filter(item => !item.hasChildren);
      groupNodes.forEach(group => {
        recursiveFn(group);
      });

      if (node.isGroup && node.name == 'nan') {
        // Handling scenario 1
        if (node.children?.length) {
          const mergedNode = mergeQnASemanticQuery(node.children)[0];
          delete node.children;
          mergedNode.group = { ...node };
          // keeping the displayorder same as group's displayorder and keeping query node's displayOrder in nodeDisplayOrder
          mergedNode.nodeDisplayOrder = mergedNode.displayOrder;
          mergedNode.displayOrder = mergedNode.group.displayOrder;
          treeNodes.push(mergedNode);
        }
      } else {
        const mergedNodes = leafNodes?.length && mergeQnASemanticQuery(leafNodes);
        if (mergedNodes?.length) {
          delete node.children;
          // Sorting the records based on displayorder
          node.children = [...groupNodes, ...mergedNodes].sort((a, b) => (a.displayOrder - b.displayOrder));
        }
        treeNodes.push(node);
      }
    });
    return treeNodes.sort((a, b) => (a.displayOrder - b.displayOrder));
  }

  function recursiveFn(node) {
    if (node.hasChildren) {
      const leafNodes = node.children.filter(item => !item.hasChildren);
      const groupNodes = node.children.filter(item => item.hasChildren);
      const mergedNodes = leafNodes?.length && mergeQnASemanticQuery(leafNodes);
      if (mergedNodes?.length) {
        delete node.children;
        // Sorting the records based on displayorder
        node.children = [ ...groupNodes, ...mergedNodes].sort((a, b) => (a.displayOrder - b.displayOrder));
      }
      if (!groupNodes.length) {
        return;
      }
      groupNodes.forEach(group => {
        recursiveFn(group);
      });
    } else {
      return;
    }
  }

  function mergeQnASemanticQuery(leafNodes) {
    const combinedQnaSemanticRecords = [];
    leafNodes.forEach(node => {
      if (node.type == 'Semantic') {
        const displayOrder = node.displayOrder;
        const qnaRec = leafNodes.find(
          item => item.displayOrder == displayOrder && item.type == 'QnA'
        );

        const treeNode = { ...node };
        if (qnaRec) {
          treeNode.qna = qnaRec;
        }
        combinedQnaSemanticRecords.push(treeNode);
      }
    });
    return combinedQnaSemanticRecords;
  }