export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const getTextWidth = (text, fontStyles = '10px sans-serif') => {
    // if given, use cached canvas for better performance
    // else, create new canvas
    const canvas = /* getTextWidth.canvas || (getTextWidth.canvas = */ document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = fontStyles;
    const metrics = context.measureText(text);
    return metrics.width;
}

export const getScaleX = (spanEl: any): any => {
  let scaleXValue = 1;
  if (spanEl.style && spanEl.style.transform && spanEl.style.transform.split('(').length > 0){
    const partialData = spanEl.style.transform.split('(');
    if (partialData[1].split(')').length > 0){
      const [partialDetails] = partialData[1].split(')')
      scaleXValue = partialDetails;
    }
  }
  return scaleXValue;
}

const getExactElementWidth = (currentBox, elementOffsetLeft, elementWidth): any => {
  /**
     * this diagram represent below if else block
     * to show different scenario
     *       _______________
     *      |   1------     |
     *   2--|-----          |
     *      |         3-----|-----
     *  4---|---------------|-----
     *      |_______________|
    */
  let offsetWidth;
  if (elementOffsetLeft >= currentBox.x1 && (elementOffsetLeft + elementWidth) <= currentBox.x2) {
    offsetWidth = elementWidth;
  } else if (elementOffsetLeft < currentBox.x1 && (elementOffsetLeft + elementWidth) <= currentBox.x2) {
    offsetWidth = elementOffsetLeft + elementWidth - currentBox.x1;
  } else if (elementOffsetLeft > currentBox.x1 && (elementOffsetLeft + elementWidth) >= currentBox.x2) {
    offsetWidth = currentBox.x2 - elementOffsetLeft;
  } else if (elementOffsetLeft <= currentBox.x1 && (elementOffsetLeft + elementWidth) >= currentBox.x2) {
    offsetWidth = currentBox.x2 - currentBox.x1;
  } else {
    offsetWidth = elementWidth;
  }
  return offsetWidth;
}

export const createUnselectedArea = (spanEl: any, svgGroup: any, currentBox: any = null) => {
  let offsetLeft; let offsetTop; let offsetWidth; let offsetHeight;
  const elementWidth = spanEl.offsetWidth ? spanEl.offsetWidth * parseFloat(getScaleX(spanEl)) : undefined;
  if (spanEl.boundingBox) {
    const [boundingBox0, boundingBox1, boundingBox2, boundingBox3] = spanEl.boundingBox || [];
    //this condition will manage ocr data;
    offsetLeft = boundingBox0 >= currentBox.x1 ? boundingBox0 : currentBox.x1;
    offsetTop = boundingBox1;
    offsetWidth = getExactElementWidth(currentBox, boundingBox0, boundingBox2 - boundingBox0)
    offsetHeight = boundingBox3 - boundingBox1;
  } else {
    offsetLeft = (!currentBox || spanEl.offsetLeft >= currentBox.x1) ? spanEl.offsetLeft - (spanEl.addLeftPadding ? 5 : 0) : currentBox.x1;
    offsetTop = spanEl.offsetTop;
    offsetWidth = !currentBox ? elementWidth + (spanEl.addLeftPadding ? 5 : 0) : getExactElementWidth(currentBox, spanEl.offsetLeft, elementWidth);
    offsetHeight = spanEl.offsetHeight + (spanEl.addBottomPadding ? 5 : 0);
  }
  if (svgGroup
        && svgGroup.getElementsByClassName('unselectionArea').length > 0
        && svgGroup.querySelector(`[id='rectUnselected-${offsetWidth}-${offsetHeight}'][x='${offsetLeft}'][y='${offsetTop}'][width='${offsetWidth}']`)) {
    return;
  }
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  rect.setAttribute('class', 'unselectionArea');
  rect.setAttributeNS(null, 'x', offsetLeft);
  rect.setAttributeNS(null, 'y', offsetTop);
  rect.setAttributeNS(null, 'width', offsetWidth);
  rect.setAttributeNS(null, 'height', offsetHeight);
  rect.setAttribute('opacity', '0');
  rect.setAttribute('id', `rectUnselected-${offsetWidth}-${offsetHeight}`);
  if (svgGroup){
    const startCursor = svgGroup.querySelector(".startCursorLine");
    startCursor ? svgGroup.insertBefore(rect, startCursor) : svgGroup.appendChild(rect);
  }
}

// Renamed this function from createSVGRectsOfOCRData to createSVGRects
// TO DO: Code cleanup needed, once PDF is implemented
export const createSVGRects = (nbb: any, wordWidth: any, wordHeight: any, text: any, isAnswer = false, qnaManualFeedback = [], isQnaFeedbackMode = false, svgGroup = null, piiSearch = false) => {
  if (wordWidth < 0){
    return;
  }
  let fillColor = '';
  if (isAnswer) {
    fillColor = piiSearch ? '#87D3F2' : '#FEFE00';
  } else {
    fillColor = (isQnaFeedbackMode) ? 'darkgreen' : 'green';
  }
  if (isAnswer) {
    if (isQnaFeedbackMode && qnaManualFeedback && qnaManualFeedback.length > 0){
      if ((qnaManualFeedback[0].semanticFeedbackId == null && qnaManualFeedback[0].id == null)
                || (qnaManualFeedback[0].semanticFeedbackId && qnaManualFeedback[0].id)){
        fillColor = 'darkgreen';
      } else if (qnaManualFeedback[0].semanticFeedbackId == null && qnaManualFeedback[0].id){
        //fillColor = 'gray';
        fillColor = 'darkgreen';
      } else if (qnaManualFeedback[0].semanticFeedbackId && qnaManualFeedback[0].selectedAnswer) {
        //when semantic feedback saved first. And later trying to provide answer feedback.
        fillColor = 'darkgreen';
      }
    }
  }
  let existingSemanticHighlightElementExists;
  svgGroup && svgGroup.querySelectorAll(".selectionArea").forEach((highlightedRect) => {
    const semanticHighlightStart = parseFloat(highlightedRect.getAttribute('x'))
    const semanticHighlightEnd = parseFloat(highlightedRect.getAttribute('x')) + parseFloat(highlightedRect.getAttribute('width'))
    const answerHighlightStart = parseFloat(nbb[0])
    const answerHighlightEnd = parseFloat(nbb[2])
    if (
      highlightedRect.getAttribute('highlightType') === 'Semantic'
          && ( // Check if new answer highlight starts and ends either before or after existing semantic highlight
            !(semanticHighlightStart < answerHighlightStart && semanticHighlightEnd <= answerHighlightStart)
            && !(semanticHighlightStart >= answerHighlightEnd && semanticHighlightEnd > answerHighlightEnd)
          ) // Check if existing semantic highlight element is in the same line as new answer highlight element
          && highlightedRect.getAttribute('y') == parseFloat(nbb[1])
    ) {
      existingSemanticHighlightElementExists = true
    }
  });
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('class', `rectSelect selectionArea ${isAnswer ? 'ai-answer-highlight' : ''}`);
  rect.setAttributeNS(null, 'x', nbb[0]);
  rect.setAttributeNS(null, 'y', nbb[1]);
  rect.setAttributeNS(null, 'width', wordWidth);
  rect.setAttributeNS(null, 'height', wordHeight);
  rect.setAttribute('fill', fillColor);
  rect.setAttribute('opacity', isAnswer && !existingSemanticHighlightElementExists ? '0.5' : '0.3');
  rect.setAttribute('id', `rect-${wordWidth}-${wordHeight}`);
  rect.setAttribute('selectedTextSVGRect', 'selectedTextSVGRect');
  rect.setAttribute('highlightType', isQnaFeedbackMode ? 'QnA' : 'Semantic');
  rect.setAttribute('offsetLeft', nbb[0]);
  rect.setAttribute('offsetTop', nbb[1]);
  rect.setAttribute('offsetWidth', wordWidth);
  rect.setAttribute('textContent', text);
  rect.setAttribute('cursor', 'pointer');
  rect.style.transform = 'scaleX(1)';
  rect.style.webkitTransform = 'scaleX(1)';
  return rect;
}

export const createCursorElements = (firstElTop, lastElTop, svgGroup, sx1, sy1, sx2, sy2, ex1, ey1, ex2, ey2) => {
  const startCursorExists = document.getElementsByClassName('startCursor')
  const endCursorExists = document.getElementsByClassName('endCursor')
  if (!(startCursorExists && startCursorExists.length) && !(endCursorExists && endCursorExists.length)) {
    const startCursorLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    const startCursorBottomCircle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    const startCursorOuterRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

    const startCursorDiameter = sy2 - sy1;
    const tmpStartY1 = sy1 < sy2 ? sy1 : sy2;
    startCursorBottomCircle.setAttributeNS(null, 'x', (sx1 - startCursorDiameter + 1).toString());
    startCursorBottomCircle.setAttributeNS(null, 'y', sy2);
    startCursorBottomCircle.setAttributeNS(null, 'width', startCursorDiameter.toString());
    startCursorBottomCircle.setAttributeNS(null, 'height', startCursorDiameter.toString());
    startCursorBottomCircle.setAttributeNS(null, 'rx', (startCursorDiameter / 2).toString());
    startCursorBottomCircle.setAttribute('fill', '#188CE5');
    startCursorBottomCircle.setAttributeNS(null, 'class', `startCursorBottomCircle`);

    startCursorOuterRect.setAttributeNS(null, 'x', (sx1 - startCursorDiameter + 1).toString());
    startCursorOuterRect.setAttributeNS(null, 'y', sy1);
    startCursorOuterRect.setAttributeNS(null, 'width', (sx2 - sx1 + startCursorDiameter).toString());
    startCursorOuterRect.setAttributeNS(null, 'height', (sy2 - sy1 + startCursorDiameter).toString());
    startCursorOuterRect.setAttributeNS(null, 'stroke', 'none');
    startCursorOuterRect.setAttributeNS(null, 'fill', 'white');
    startCursorOuterRect.setAttribute('stroke-width', '0.5');
    startCursorOuterRect.setAttribute('opacity', '0');
    startCursorOuterRect.setAttribute('cursor', 'pointer');
    startCursorOuterRect.setAttributeNS(null, 'class', `startCursor draggable`);
    startCursorOuterRect.setAttribute("spanYCoordinate", Math.abs(firstElTop - tmpStartY1) <= startCursorDiameter / 2 ? firstElTop : tmpStartY1);

    startCursorLine.setAttributeNS(null, 'x1', sx1);
    startCursorLine.setAttributeNS(null, 'y1', sy1);
    startCursorLine.setAttributeNS(null, 'x2', sx2);
    startCursorLine.setAttributeNS(null, 'y2', (parseInt(sy2) + parseInt(startCursorDiameter.toString()) / 2).toString());
    startCursorLine.setAttribute('stroke', '#188CE5');
    startCursorLine.setAttribute('stroke-width', '2');
    startCursorLine.setAttributeNS(null, 'class', `startCursorLine`);

    svgGroup.appendChild(startCursorLine);
    svgGroup.appendChild(startCursorBottomCircle);
    svgGroup.appendChild(startCursorOuterRect);

    const endCursorDiameter = ey2 - ey1;
    const tmpEndY1 = ey1 < ey2 ? ey1 : ey2;
    const endCursorLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    const endCursorBottomCircle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    const endCursorOuterRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

    endCursorBottomCircle.setAttributeNS(null, 'x', (ex1 - 1).toString());
    endCursorBottomCircle.setAttributeNS(null, 'y', ey2);
    endCursorBottomCircle.setAttributeNS(null, 'width', endCursorDiameter.toString());
    endCursorBottomCircle.setAttributeNS(null, 'height', endCursorDiameter.toString());
    endCursorBottomCircle.setAttributeNS(null, 'rx', (endCursorDiameter / 2).toString());
    endCursorBottomCircle.setAttribute('fill', '#188CE5');
    endCursorBottomCircle.setAttributeNS(null, 'class', `endCursorBottomCircle`);

    endCursorOuterRect.setAttributeNS(null, 'x', (ex1 - 1).toString());
    endCursorOuterRect.setAttributeNS(null, 'y', ey1);
    endCursorOuterRect.setAttributeNS(null, 'width', (ex2 - ex1 + endCursorDiameter).toString());
    endCursorOuterRect.setAttributeNS(null, 'height', (ey2 - ey1 + endCursorDiameter).toString());
    endCursorOuterRect.setAttributeNS(null, 'stroke', 'none');
    endCursorOuterRect.setAttributeNS(null, 'fill', 'white');
    endCursorOuterRect.setAttribute('stroke-width', '0.5');
    endCursorOuterRect.setAttribute('opacity', '0');
    endCursorOuterRect.setAttribute('cursor', 'pointer');
    endCursorOuterRect.setAttributeNS(null, 'class', `endCursor draggable`);
    endCursorOuterRect.setAttribute("spanYCoordinate", Math.abs(lastElTop - tmpEndY1) <= startCursorDiameter / 2 ? lastElTop : tmpEndY1);

    endCursorLine.setAttributeNS(null, 'x1', ex1);
    endCursorLine.setAttributeNS(null, 'y1', ey1);
    endCursorLine.setAttributeNS(null, 'x2', ex2);
    endCursorLine.setAttributeNS(null, 'y2', (parseInt(ey2) + parseInt(endCursorDiameter.toString()) / 2).toString());
    endCursorLine.setAttribute('stroke', '#188CE5');
    endCursorLine.setAttribute('stroke-width', '2');
    endCursorLine.setAttributeNS(null, 'class', `endCursorLine`);

    svgGroup.appendChild(endCursorLine);
    svgGroup.appendChild(endCursorBottomCircle);
    svgGroup.appendChild(endCursorOuterRect);
  }
}

export const removeCursorElements = () => {
  const startCursor = document.getElementsByClassName('startCursor');
  let parentElement;
  if (startCursor.length > 0) {
    parentElement = startCursor[0].parentElement;
    startCursor.length > 0 ? startCursor[0].remove() : '';
  }
  if (!parentElement) {
    return;
  }
  const startCursorLine = parentElement.getElementsByClassName('startCursorLine');
  startCursorLine.length > 0 ? startCursorLine[0].remove() : '';

  const startCursorBottomCircle = parentElement.getElementsByClassName('startCursorBottomCircle');
  startCursorBottomCircle.length > 0 ? startCursorBottomCircle[0].remove() : '';

  const endCursor = parentElement.getElementsByClassName('endCursor');
  endCursor.length > 0 ? endCursor[0].remove() : '';

  const endCursorLine = parentElement.getElementsByClassName('endCursorLine');
  endCursorLine.length > 0 ? endCursorLine[0].remove() : '';

  const endCursorBottomCircle = parentElement.getElementsByClassName('endCursorBottomCircle');
  endCursorBottomCircle.length > 0 ? endCursorBottomCircle[0].remove() : '';
}


//itemCoordinates can be word coordinates or line coodinates
// & cursorCoordinates contains bots start[0,1] and end[2,3] cursor coordinates
export const isWithinCursors = (cursorCoordinates, itemCoordinates) => {
  const wordHeight = itemCoordinates[3] - itemCoordinates[1];
  if (itemCoordinates[1] < cursorCoordinates[1]
        && Math.abs(itemCoordinates[1] - cursorCoordinates[1]) > wordHeight / 2) {
    //if before start cursor on y axis
    return false;
  } else if (Math.abs(itemCoordinates[1] - cursorCoordinates[1]) <= wordHeight / 2
        && itemCoordinates[0] < cursorCoordinates[0]
        && itemCoordinates[2] < cursorCoordinates[0]) {
    //if item is on x axis plane of start cursor but entire word is before start cursor
    return false;
  } else if (itemCoordinates[1] > cursorCoordinates[3]
    && Math.abs(itemCoordinates[1] - cursorCoordinates[3]) > wordHeight / 2) {
    //if after end cursor on y axis
    return false;
  } else if (Math.abs(itemCoordinates[1] - cursorCoordinates[3]) <= wordHeight / 2
        && itemCoordinates[0] > cursorCoordinates[2]
        && itemCoordinates[2] > cursorCoordinates[2]) {
    //if item is on x axis plane of end cursor but entire word is after end cursor
    return false;
  } else {
    return true;
  }
}

// Getting partial word selection, wordBoundingBox is rect needed for the specified word, start is x1 of start cursor and end is x1 of end cursor
export const getPartialSelectedTextForExcel = (word: any, start: number, end: number, startY: number, endY: number) => {
  const leftIgnoredPercentage = (start > word.boundingBox[0] && Math.abs(startY - word.boundingBox[1]) < 1) ? ((start - word.boundingBox[0]) / word.wordWidth) * 100 : 0;
  const rightIgnoredPercentage = ((word.boundingBox[2] > end) && Math.abs(endY - word.boundingBox[1]) < 1) ? ((word.boundingBox[2] - end) / word.wordWidth) * 100 : 0;

  const startOfPartialText = word.text.substr(0, Math.round(word.text.length * leftIgnoredPercentage / 100)).length;
  const partialText = word.text.substr(0, word.text.length - Math.round(word.text.length * rightIgnoredPercentage / 100)).substr(Math.round(word.text.length * leftIgnoredPercentage / 100));
  return { partialText, startOfPartialText };
}

// Takes an element and creates/destroys error highlight rect if element is answer highlight rect
export const createDestroyAnswerErrorHighlight = (el, isCreate = true) => {
  if (isCreate) {
    el.setAttribute('fill', 'transparent');
    el.setAttribute('stroke', '#FF0000');
    el.setAttribute('stroke-width', '2');
    el.setAttribute('answerOutsideSemanticErrorHighlight', 'answerOutsideSemanticErrorHighlight');
  } else {
    const isErrorHighlight = el.hasAttribute('answerOutsideSemanticErrorHighlight')
    if (isErrorHighlight) {
      el.setAttribute('fill', 'darkgreen');
      el.setAttribute('opacity', '0.3');
      el.removeAttribute('stroke');
      el.removeAttribute('stroke-width');
      el.removeAttribute('answerOutsideSemanticErrorHighlight', 'answerOutsideSemanticErrorHighlight');
    }
  }
}