import { Injectable } from '@angular/core';
import { ValidationService } from '../common-components/validation.service';
import { FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  constructor(
    public validationService: ValidationService,
    public global: GlobalService,
    public translate: TranslatePipe
  ) {}

  /**
   * Description: Get last validation to populate
   * @param validation validation data to populate
   * @returns void
   */
  nextStepValidationBusiness(validation, formTest) {
    this.fillFormBasicExtractionBusiness(
      validation,
      { ...validation.validations.extraction, validationdescription: '' },
      true,
      formTest
    );
    return formTest;
  }

  editValidationBusiness(validation, formTest) {
    const cases = validation.validations.business_rules.validationcontent;
    this.fillFormBasicExtractionBusiness(
      validation,
      validation.validations.business_rules,
      false,
      formTest
    );
    this.fillCasesBusiness(cases, formTest, validation.datamodelid);

    return formTest;
  }

  /**
   * Description: Fill basic data of the form
   * @param validation  validation to fill
   * @param nextStep    came from next step
   * @returns void
   */
  fillFormBasicExtractionBusiness(
    validationTotal,
    validation,
    nextStep,
    formTest
  ) {
    formTest.get('description').setValue(validation.validationdescription);
    formTest.addControl(
      'datamodelid',
      new FormControl(validationTotal.datamodelid)
    );
    formTest.addControl('latestdoc', new FormControl(''));
    formTest.addControl('uniquedoc', new FormControl(''));
    formTest.get('datamodelid').disable();
    formTest.get('type').setValue(2);
    formTest
      .get('autoexecute')
      .setValue(nextStep ? true : validation.validationautoexecute);
    if (nextStep) {
      formTest.get('type').setValue(2);
      formTest.get('cases').setValue([]);
      const cases = formTest.get('cases') as FormArray;
      cases.push(
        new FormGroup({
          case_name: new FormControl('', Validators.required),
          case_description: new FormControl(''),
          condition_type: new FormControl('', Validators.required),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          precondition: new FormControl(false),
          preconditions: new FormArray([]),
          condition_list: new FormArray([
            new FormGroup({
              condition_type: new FormControl('', Validators.required),
              description: new FormControl(''),
              precondition: new FormControl(false),
              preconditions: new FormArray([]),
              simple_multiple: new FormControl(false),
              conditions: new FormArray([], Validators.required)
            })
          ])
        })
      );
    } else {
      ['uniquedoc', 'latestdoc'].forEach(controlName => {
        if (validation[controlName]) {
          let values = JSON.parse(validation[controlName].replace(/'/g, '"'));
          values = values.filter(value =>
            this.validationService.fieldOfInterestExistsOnDatamodel(
              value,
              validationTotal.datamodelid
            )
          );
          if (values) {
            formTest.get(controlName).setValue(values);
            formTest.get(controlName).setValidators([Validators.required]);
            formTest.get(controlName).markAsTouched();
          }
        }
      });
    }
  }

  /**
   * Description: Fill simple condition for Business Rule type
   * @param simple_condition condition to populate in form
   */
  fillSimpleConditionBusiness(simple_conditions, datamodelid) {
    // TODO: Refactor
    const simple = new FormArray([]);
    for (const simple_condition of simple_conditions) {
      const simple_wrapper = new FormGroup({});
      simple_wrapper.addControl('operations', new FormGroup({}));
      simple_wrapper.addControl(
        'toleranceerrorrate',
        new FormControl(
          simple_condition.toleranceerrorrate
            ? simple_condition.toleranceerrorrate
            : false
        )
      );
      simple_wrapper.addControl(
        'toleranceerrorratevalue',
        new FormControl(
          simple_condition.toleranceerrorratevalue
            ? simple_condition.toleranceerrorratevalue
            : 0
        )
      );
      simple_wrapper.addControl(
        'condition_name',
        new FormControl(simple_condition.condition_name)
      );
      // tslint:disable-next-line: forin
      for (const element in simple_condition) {
        if (element === 'operationScope') {
          simple_wrapper.addControl(
            'operationScope',
            new FormControl(simple_condition[element], Validators.required)
          );
        } else if (element === 'enrichments_type') {
          simple_wrapper.addControl(
            'enrichments_type',
            new FormControl(simple_condition[element], Validators.required)
          );
        } else if (element === 'operations') {
          for (const [key, value] of Object.entries(
            simple_condition[element]
          )) {
            const type = value['type'];
            const new_condition = new FormGroup({
              type: new FormControl(type),
              [type]: new FormControl(value[type])
            });
            if (type === 'fieldofinterestid') {
              if (
                this.validationService.fieldOfInterestExistsOnDatamodel(
                  value['fieldofinterestid'],
                  datamodelid
                )
              ) {
                new_condition
                  .get('fieldofinterestid')
                  .setValue(value['fieldofinterestid']);
              } else {
                new_condition.get('fieldofinterestid').setValue('');
              }
              new_condition
                .get('fieldofinterestid')
                .setValidators(Validators.required);
              new_condition.addControl(
                'groupoffieldsid',
                new FormControl(value['groupoffieldsid'])
              );
              new_condition.addControl(
                'agg',
                new FormControl(value['agg'], Validators.required)
              );
            } else if (type === 'value') {
              new_condition.addControl(
                'datatype',
                new FormControl(value['datatype'], Validators.required)
              );
              new_condition.addControl(
                'value',
                new FormControl(value['value'], Validators.required)
              );
            } else if (type === 'operator') {
              new_condition.addControl(
                'operator',
                new FormControl(value['operator'], Validators.required)
              );
            } else if (type === '') {
              new_condition.removeControl('');
            }
            const simple_wrapper_control = simple_wrapper.get(
              'operations'
            ) as FormGroup;
            simple_wrapper_control.addControl(key, new_condition);
          }
        } else if (['rounding', 'precision'].includes(element)) {
          simple_wrapper.addControl(
            element,
            new FormControl(simple_condition[element], Validators.required)
          );
        }
      }
      simple.push(simple_wrapper);
    }
    return simple;
  }

  /**
   * Description: Populate Condition Lists and call functions to populate simple and multiple conditions
   * Recursive function
   * @param conditionLists conditions lists to populate data
   * @returns condition list filled
   */
  fillConditionListBusiness(conditionLists, datamodelid) {
    const result = new FormArray([]);
    conditionLists
      .filter(conditionList => conditionList.conditions.length > 0)
      .forEach(conditionList => {
        if ([false, 'single'].includes(conditionList.simple_multiple)) {
          // is simple
          result.push(
            new FormGroup({
              condition_type: new FormControl(conditionList.condition_type),
              description: new FormControl(conditionList.description),
              precondition: new FormControl(
                conditionList.precondition ? true : false
              ),
              preconditions: this.fillPreConditionsBusiness(
                conditionList.preconditions
              ),
              conditions: this.fillSimpleConditionBusiness(
                conditionList.conditions,
                datamodelid
              ),
              simple_multiple: new FormControl(false),
              error: new FormControl(conditionList.error, Validators.required)
            })
          );
        } else {
          // is multiple
          result.push(
            new FormGroup({
              condition_type: new FormControl(conditionList.condition_type),
              description: new FormControl(conditionList.description),
              precondition: new FormControl(
                conditionList.precondition ? true : false
              ),
              preconditions: this.fillPreConditionsBusiness(
                conditionList.preconditions
              ),
              conditions: this.fillConditionListBusiness(
                conditionList.conditions,
                datamodelid
              ),
              simple_multiple: new FormControl(true)
            })
          );
        }
      });
    return result;
  }

  fillPreConditionsBusiness(preconditions) {
    const result = new FormArray([]);
    if (!preconditions) {
      return result;
    }
    preconditions.forEach(precondition => {
      result.push(
        new FormGroup({
          '0': new FormGroup({
            type: new FormControl('fieldofinterestid'),
            groupoffieldsid: new FormControl(precondition[0].groupoffieldsid),
            fieldofinterestid: new FormControl(
              precondition[0].fieldofinterestid
            )
          }),
          '1': new FormGroup({
            type: new FormControl('operator'),
            operator: new FormControl(precondition[1].operator)
          }),
          '2': new FormGroup({
            type: new FormControl('value'),
            value: new FormControl(precondition[2].value),
            datatype: new FormControl(precondition[2].datatype)
          })
        })
      );
    });
    return result;
  }

  /**
   * Genaral function for fill simple condition, used in business,
   * catalog and preliminary
   * @param cases cases to iterate
   * @param formTest form to populate data
   * @param fillSimpleCondition function to fill simple condition
   */
  fillCasesBusiness(cases, formTest, datamodelid) {
    const formCases = formTest.get('cases') as FormArray;
    cases.forEach(validation_case => {
      formCases.push(
        new FormGroup({
          case_name: new FormControl(
            validation_case.case_name,
            Validators.required
          ),
          case_description: new FormControl(validation_case.case_description),
          condition_type: new FormControl(validation_case.condition_type),
          precondition: new FormControl(
            validation_case.precondition ? true : false
          ),
          preconditions: this.fillPreConditionsBusiness(
            validation_case.preconditions
          ),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          condition_list: this.fillConditionListBusiness(
            validation_case.condition_list,
            datamodelid
          )
        })
      );
      if ('not_editable' in validation_case) {
        const formCase: any = formCases['controls'][formCases.length - 1];
        formCase.addControl(
          'not_editable',
          new FormControl(validation_case.not_editable)
        );
      }
    });
  }

  /**
   * Returns business case condition template
   */
  getCaseCondition() {
    return new FormGroup({
      toleranceerrorrate: new FormControl(false, Validators.required),
      toleranceerrorratevalue: new FormControl(0, Validators.required),
      operationScope: new FormControl('', Validators.required),
      rounding: new FormControl('', Validators.required),
      condition_name: new FormControl(''),
      operations: new FormGroup({
        '0': new FormGroup({
          type: new FormControl('', Validators.required)
        }),
        '1': new FormGroup({
          type: new FormControl('operator'),
          operator: new FormControl('', Validators.required)
        })
      })
    });
  }

  /**
   * Returns business pre condition template
   */
  getPreCondition() {
    return new FormGroup({
      '0': new FormGroup({
        type: new FormControl('fieldofinterestid'),
        groupoffieldsid: new FormControl('', Validators.required),
        fieldofinterestid: new FormControl('', Validators.required)
      }),
      '1': new FormGroup({
        type: new FormControl('operator'),
        operator: new FormControl('', Validators.required)
      }),
      '2': new FormGroup({
        type: new FormControl('value'),
        value: new FormControl('', Validators.required),
        datatype: new FormControl('', Validators.required)
      })
    });
  }
}
