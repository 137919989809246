import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date | moment.Moment, ...args: any[]): any {
    if (value && !args.includes('fromNow')) {
      let [format] = args;
      return moment(value).format(format);
    } else if (value && args.includes('fromNow')) {
      return moment(value).fromNow();
    }
    return '';
  }
}
