import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';

@Injectable({
  providedIn: 'root'
})
export class CognitiveSearchService {
  formCognitiveSearch: FormGroup;

  constructor(private modalService: NgbModal) {}

  public getFormCognitiveSearch(): FormGroup {
    const formCognitiveSearch = new FormGroup({}, this.customValidator);
    return formCognitiveSearch;
  }

  public getPreviousSearch() {
    return this.formCognitiveSearch;
  }

  public setPreviousSearch(newSearchFilter) {
    return (this.formCognitiveSearch = newSearchFilter);
  }

  public serializeForRequest(form: FormGroup) {
    const serializedForm = this.getFormCognitiveSearch();
    this.serializeBasicFilter(form, serializedForm);
    this.serializeExtractedFilter(form, serializedForm);
    this.serializeValidatedFilter(form, serializedForm);
    return serializedForm;
  }

  public serializeBasicFilter(form, serializedForm) {
    const basicArray = form.get('basicFilters')?.controls;
    if (basicArray) {
      basicArray.forEach(element => {
        if (element.get('date_filters')) {
          const form_date = element.get('date_filters');
          const new_date_elem: FormGroup = new FormGroup(
            this.getNewDateGroup()
          );
          const date_span: string =
            form_date.get('date_span').value +
            form_date.get('date_span_unit').value;
          new_date_elem.patchValue(form_date.value);
          new_date_elem.removeControl('date_span_unit');
          new_date_elem.get('date_span').setValue(date_span);
          this.serializeArrays(
            'date_filters',
            new_date_elem,
            serializedForm,
            true
          );
        }
        this.serializeArrays('text_filters', element, serializedForm);
        this.serializeArrays('numeric_filters', element, serializedForm);
        this.serializeArrays('modify_filter', element, serializedForm);
        this.serializeArrays('user_filters', element, serializedForm);
        this.serializeArrays('category_filters', element, serializedForm);
      });
    }
  }

  public serializeExtractedFilter(form, serializedForm) {
    const extractedArray = form.get('extractedFilters')?.controls;
    if (extractedArray) {
      extractedArray.forEach((element: FormGroup) => {
        const extracted = new FormGroup({});
        extracted.addControl(
          'field_of_interest',
          element.get('field_of_interest')
        );
        extracted.addControl('level', element.get('level'));
        extracted.addControl('value', element.get('term'));
        extracted.addControl('value_logic', element.get('term_logic'));
        extracted.addControl('use_synonyms', element.get('use_synonyms'));
        extracted.addControl('logic', element.get('logic'));
        this.serializeArrays(
          'extracted_data_filters',
          extracted,
          serializedForm,
          true
        );
      });
    }
  }

  public serializeValidatedFilter(form, serializedForm) {
    const validatedArray = form.get('validatedFilters')?.controls;
    if (validatedArray) {
      validatedArray.forEach(element => {
        const validated = new FormGroup(this.getNewValidatedGroup());
        const date_span: string =
          element.get('date_span').value + element.get('date_span_unit').value;
        validated.patchValue(element.value);
        element.get('field_of_interest').controls.forEach(foi => {
          const arr = validated.get('field_of_interest') as FormArray;
          arr.push(foi);
        });
        validated.removeControl('field_of_interest_name');
        validated.removeControl('date_span_unit');
        validated.get('date_span').setValue(date_span);
        this.serializeArrays(
          'extracted_data_filters',
          validated,
          serializedForm,
          true
        );
      });
    }
  }

  /**
   * Function to serialize the json
   * like the elastic expects. If elem has the control type
   * add it into an array control of form
   * @param type string type of form control
   * @param elem form control to add
   * @param form parent form to push the elem
   */
  public serializeArrays(type, element, form, secondType?) {
    if (element.get(type) || secondType) {
      if (!form.get(type)) {
        form.addControl(type, new FormArray([]));
      }
      form.get(type).controls.push(element.get(type) || element);
    }
  }

  // ------ Filters -------------
  public addBasicFilter(form) {
    let basicFilters = form.get('basicFilters') as FormArray;
    if (!basicFilters) {
      basicFilters = new FormArray([], Validators.required);
      form.addControl('basicFilters', basicFilters);
    }
    this.addNewBasicFilter(basicFilters);
  }

  public addExtractedFilter(form) {
    let extractedFilters = form.get('extractedFilters') as FormArray;
    if (!extractedFilters) {
      extractedFilters = new FormArray([], Validators.required);
      form.addControl('extractedFilters', extractedFilters);
    }
    this.addNewExtractedFilter(extractedFilters);
  }

  public addValidatedFilter(form) {
    let validatedFilters = form.get('validatedFilters') as FormArray;
    if (!validatedFilters) {
      validatedFilters = new FormArray([], Validators.required);
      form.addControl('validatedFilters', validatedFilters);
    }
    this.addNewValidatedFilter(validatedFilters);
  }

  public addFieldOfInterest(form, values) {
    const arr = form.get('field_of_interest') as FormArray;
    arr.clear();
    values.forEach(element => {
      arr.push(new FormControl(element));
    });
  }

  public addNewBasicFilter(arr) {
    arr.push(
      new FormGroup({
        basicType: new FormControl('', Validators.required)
      }),
      this.customBasicValidator
    );
  }

  public getNewExtractedGroup() {
    return {
      field_of_interest_name: new FormControl('', Validators.required),
      field_of_interest: new FormArray([], Validators.required),
      level: new FormControl('extraction'),
      term: new FormControl('', Validators.required),
      term_logic: new FormControl(''),
      use_synonyms: new FormControl(false),
      logic: new FormControl('')
    };
  }

  public getNewValidatedGroup() {
    return {
      field_of_interest_name: new FormControl('', Validators.required),
      field_of_interest: new FormArray([], Validators.required),
      level: new FormControl('validation'),
      type: new FormControl('', Validators.required),
      value: new FormControl(''),
      value_logic: new FormControl('', Validators.required),
      date_span: new FormControl(''),
      date_span_unit: new FormControl(''),
      low_value: new FormControl(''),
      high_value: new FormControl(''),
      logic: new FormControl('')
    };
  }

  public addNewExtractedFilter(arr) {
    arr.push(new FormGroup(this.getNewExtractedGroup(), Validators.required));
  }

  public addNewValidatedFilter(arr) {
    arr.push(
      new FormGroup(this.getNewValidatedGroup(), {
        validators: [this.customValidatedValidator, Validators.required]
      })
    );
  }

  public getNewDateGroup() {
    return {
      field: new FormControl('', Validators.required),
      date_logic: new FormControl('', Validators.required),
      date_span: new FormControl(''),
      date_span_unit: new FormControl(''),
      date_value: new FormControl(''),
      from_date: new FormControl(''),
      until_date: new FormControl(''),
      include_boundaries: new FormControl(''),
      logic: new FormControl('')
    };
  }

  public addNewDateFilter() {
    return new FormGroup(this.getNewDateGroup(), {
      validators: [this.customDateValidator, Validators.required]
    });
  }

  public addNewTextFilter() {
    return new FormGroup(
      {
        term: new FormControl('', Validators.required),
        field: new FormControl(''),
        term_logic: new FormControl(''),
        use_synonyms: new FormControl(false),
        logic: new FormControl('')
      },
      Validators.required
    );
  }

  public addNewNumericFilter() {
    return new FormGroup(
      {
        field: new FormControl('', Validators.required),
        value: new FormControl(''),
        numeric_logic: new FormControl('', Validators.required),
        low_value: new FormControl(''),
        high_value: new FormControl(''),
        include_boundaries: new FormControl(''),
        logic: new FormControl('')
      },
      Validators.required
    );
  }

  public addTextFilter(form, field) {
    const newTextFilter = this.addNewTextFilter();
    form.addControl('text_filters', newTextFilter);
    form.get('text_filters').get('field').setValue(field);
  }

  public addNumericFilter(form, field) {
    const newNumericFilter = this.addNewNumericFilter();
    form.addControl('numeric_filters', newNumericFilter);
    form.get('numeric_filters').get('field').setValue(field);
  }

  public addDateFilter(form, field) {
    const newDateFilter = this.addNewDateFilter();
    form.addControl('date_filters', newDateFilter);
    form.get('date_filters').get('field').setValue(field);
  }

  public addModifiedFilter(form) {
    const newModified = new FormGroup({
      has_been_modified: new FormControl('', Validators.required)
    });
    form.addControl('modify_filter', newModified);
  }

  public addUserFilter(form) {
    const newUserFilter = new FormGroup({
      user: new FormControl('', Validators.required)
    });
    form.addControl('user_filters', newUserFilter);
  }

  public addCategoryFilter(form, field) {
    const newCategoryFilter = new FormGroup({
      field: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required)
    });
    form.addControl('category_filters', newCategoryFilter);
    form.get('category_filters').get('field').setValue(field);
  }
  // ------ End Filters ---------

  // ------ Custom validators -------------
  public customValidator(control: AbstractControl) {
    if (
      !(
        control.get('basicFilters') ||
        control.get('originalFilters') ||
        control.get('extractedFilters') ||
        control.get('validatedFilters')
      )
    ) {
      return { customValid: true };
    }
    return null;
  }

  public customBasicValidator(control: AbstractControl) {
    if (control['controls'].length === 1) {
      return { customValid: true };
    }
    return null;
  }

  public customDateValidator(control: AbstractControl) {
    const date_value = control.get('date_value');
    const date_logic = control.get('date_logic');
    const date_span = control.get('date_span');
    const date_span_unit = control.get('date_span_unit');
    const from_date = control.get('from_date');
    const until_date = control.get('until_date');

    if (date_logic.valid) {
      if (date_logic.value === 'recent') {
        if (date_span_unit.value === '' || date_span.value === '') {
          if (date_span_unit.value === '') {
            date_span_unit.setErrors({ invalid: true });
          }
          if (date_span.value === '') {
            date_span.setErrors({ invalid: true });
          }
          return { invalid: true };
        }
      } else if (date_logic.value === 'range') {
        if (
          from_date.value === '' ||
          until_date.value === '' ||
          from_date.value > until_date.value
        ) {
          if (from_date.value === '') {
            from_date.setErrors({ invalid: true });
          }
          if (until_date.value === '') {
            until_date.setErrors({ invalid: true });
          }
          if (from_date.value > until_date.value) {
            from_date.setErrors({ invalid: true });
            until_date.setErrors({ invalid: true });
          }
          return { invalid: true };
        }
      } else if (date_value.value === '') {
        date_value.setErrors({ invalid: true });
        return { invalid: true };
      }
      if (date_span_unit) {
        date_span_unit.setErrors(null);
      }
      if (date_span) {
        date_span.setErrors(null);
      }
      if (from_date) {
        from_date.setErrors(null);
      }
      if (until_date) {
        until_date.setErrors(null);
      }
    }
    return null;
  }

  public customValidatedValidator(control: AbstractControl) {
    const type = control.get('type');
    const value = control.get('value');
    const logic = control.get('value_logic');
    const date_span = control.get('date_span');
    const date_span_unit = control.get('date_span_unit');
    const low_value = control.get('low_value');
    const high_value = control.get('high_value');
    if (!type) {
      return null;
    }
    if (type.value !== '') {
      if (type.value === 'numeric') {
        if (logic.value === 'range') {
          if (low_value.value === '' && high_value.value === '') {
            high_value.setErrors({ invalid: true });
            low_value.setErrors({ invalid: true });
            return { customValid: true };
          }
        } else {
          if (value.value === '') {
            value.setErrors({ invalid: true });
            return { customValid: true };
          }
        }
      } else if (type.value === 'date') {
        if (logic.value === 'recent') {
          if (date_span_unit.value === '' || date_span.value === '') {
            if (date_span_unit.value === '') {
              date_span_unit.setErrors({ invalid: true });
            }
            if (date_span.value === '') {
              date_span.setErrors({ invalid: true });
            }
            return { invalid: true };
          }
        } else if (logic.value === 'range') {
          if (
            low_value.value === '' ||
            high_value.value === '' ||
            low_value.value > high_value.value
          ) {
            if (low_value.value === '') {
              low_value.setErrors({ invalid: true });
            }
            if (high_value.value === '') {
              high_value.setErrors({ invalid: true });
            }
            if (low_value.value > high_value.value) {
              low_value.setErrors({ invalid: true });
              high_value.setErrors({ invalid: true });
            }
            return { invalid: true };
          }
        }
      }
      if (
        logic.value !== 'range' &&
        logic.value !== 'recent' &&
        value.value === ''
      ) {
        value.setErrors({ invalid: true });
        return { invalid: true };
      }
      if (date_span_unit) {
        date_span_unit.setErrors(null);
      }
      if (date_span) {
        date_span.setErrors(null);
      }
      if (value) {
        value.setErrors(null);
      }
      if (low_value) {
        low_value.setErrors(null);
      }
      if (high_value) {
        high_value.setErrors(null);
      }
    }
    return null;
  }
  // ------ End Custom validators ---------

  /**
   * Add a new FormControl to an array
   * with a validator iif it comes in params
   * @param arr
   * @param validator
   */
  public addNewInputControl(arr, validator?) {
    if (validator) {
      const control = new FormControl('', Validators.required);
      control.markAsTouched();
      arr.push(control);
    } else {
      arr.push(new FormControl(''));
    }
  }

  public deleteControl(form, value, modal?) {
    if (form.get(value)) {
      if (modal) {
        this.deleteModal(form, value, value);
      } else {
        form.removeControl(value);
      }
    }
  }

  /**
   * Remove element from a list
   * @param form the list of elem
   * @param i the index of the elem in the list
   */
  public removeInputControl(form, i) {
    if (form.length > 1) {
      form.removeAt(i);
    }
  }

  /**
   * Delete Modal for delete case, condition list or conditions
   * @param parent  where we gonna delete a case or a condition list
   * @param index index to delete
   * @param message variable that what we are gonna delete to show it
   * @returns Form without case, condition list or condition
   */
  private deleteModal(parent, index, message) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: message,
      notAllowed: false
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          parent.removeControl(index);
        }
      },
      reason => {}
    );
  }
}
