// Activity codes
export const ID_LOGIN_TIMESTAMP = 1;
export const ID_ASSIGN_VALIDATION_TO_OTHER_USER = 2;
export const ID_NAVIGATION_ACTIONS = 3;
export const ID_NAVIGATION_EVENTS = 4;
export const ID_UPDATE_REVIEW_GROUP = 5;
export const ID_RECOMMEND = 6;
export const ID_RERUN_VALIDATION = 7;
export const ID_UPDATE_DOCUMENT_STATUS = 8;
export const ID_EDIT_EXTRACTED_VALUE = 9;
export const ID_EDIT_CHUNKS_SAVE_CHANGES = 10;
export const ID_OVERRIDE = 11;
export const ID_NOT_RECOMMEND = 12;
export const ID_MASSIVE_EDITION = 13;
export const ID_EXCLUDE_PRELIMINATY = 14;
export const ID_TIME_TO_CONSOLIDATE = 15;
export const ID_BATCH_EDITION = 16;
