import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app//services/global.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { merge, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.scss']
})
export class QueueListComponent implements OnInit {
  @Output() sentColumnsQueue = new EventEmitter<any[]>();
  @Output() sentTenants = new EventEmitter<any[]>();
  public user: any;
  public loading: boolean;
  public loading_data_queue: boolean;
  private pagination: PaginationModel;
  public settingTable: any;
  public columns: any[];
  public rows: {};
  public nameTenants = [];
  public queues = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private globalService: GlobalService,
    private paginationService: PaginationService,
    private router: Router,
    private link: LinkService,  
    private alertService: AlertService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();
    this.getColumns();
    this.getSettingTable();
    this.getQueues({}, false);
  }

  ngOnInit() {
    this.pagination = this.paginationService.getDefaultPagination('queues');
    this.sendColunmsToParent(this.columns);
    for (let user of this.user.tenants)
    { 
      this.nameTenants.push({nameOfTenant: user.tenantname.toUpperCase(), idOfTenant: user.tenantid})
    }
    this.sendTenantsToParent(this.nameTenants);

  }

  sendColunmsToParent(columns) {
    this.sentColumnsQueue.emit(columns);
  }

  public refreshQueues() {
    this.getQueues({}, false);
   
  }
  /**
   * Get documents from database
   */
  public getQueues(filter, ignoreLoading) {
    this.loading = true;
    let params = {
      env: environment.env
    };

    if (Object.keys(filter).length != 0) {
      params = {...filter, ...params};
    }

    this.apiService.get('admin/environment/queue/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: [];
      }) => {
        this.rows = data;
        this.paginationService.setPage('queues', data);
        this.paginationService.setPagination('queues', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
       
        let stopcondition = false;
        this.queues = data.data;
        
        for (let i = 0; i < data.data.length; i++) {
          stopcondition = false;
          for (let j = 0; j < this.nameTenants.length; j++ && !stopcondition) {
            if (
              this.rows["data"][i].client.toUpperCase() ==
                this.nameTenants[j].nameOfTenant.toUpperCase() ||
                this.rows["data"][i].client.toUpperCase() + ' (INACTIVE)' ==
                this.nameTenants[j].nameOfTenant.toUpperCase()
            ) {
              stopcondition = true;
            }
          }
          if (!stopcondition) {
            this.nameTenants.push({
              nameOfTenant: this.rows["data"][i].client.toUpperCase() + ' (inactive)',
              idOfTenant: 0
            });
          }
        }
        if(!ignoreLoading)
        this.loading = false;
      },
      () => {
        if(!ignoreLoading)
        this.loading = false;
      }
    );
  }

 

  /**
   * Go to the document with the document information pass by params
   */
  public goToQueue(row) {
    //this.link.goToEnvironment(env['env'])
    console.log('go to queue ==> ' + row['name']);
  }

  queuesRequest$ () {
    const tmp$ =  this.apiService.get('admin/environment/queue/', {env: environment.env}, '')
    return tmp$ as Observable<{total_elements: number,
      page: number,
      page_size: number,
      num_pages: number,
      order_by: string,
      desc: number,
      data: []}>
  } 

  deleteQueuesRequest$ (selectedQueues) {
    const params = {
      env: environment.env,
      key: selectedQueues,
    }
    
    return this.apiService.post('admin/environment/queue', params, '')
  }
  /**
   * Go to the document with the document information pass by params
   */
  public cleanQueue(key) {
    //this.link.goToEnvironment(env['env'])

    this.loading = true;
    this.getQueues('', true);
    
    const selectedQueues$ = this.queuesRequest$().pipe(
      map(data=> data.data),
      map(queues=> queues.filter(q=> (q as any).client == key)),
    )
    /*
    let selected_queues = [];
    const myQueues = this.queues.filter(q => q.client == key)
    for (let i = 0; i < this.queues.length; i++){
      if(key == this.queues[i].client)
      {
        selected_queues[i] = this.queues[i];
      }
    }
    console.log('clean queue ==> ' + key);
    */

    const deleteQueueRequest$ = selectedQueues$.pipe(
      mergeMap(queues => this.deleteQueuesRequest$(queues)),
      tap( _ => this.refreshQueues()),
    )
/* 
    const params = {
      env: environment.env,
      key: selected_queues
    };
    this.apiService
    .post('admin/environment/queue', params, '') */
    deleteQueueRequest$.subscribe(
      data => {
        this.alertService.success(
          this.translate.transform(
            'queues.deletedQueue'
          ),
          false
        );
        
      },
      error => {
        this.loading_data_queue = false;
        this.alertService.error(
          this.translate.transform(
            'queues.undeletedCache'
          ),
          false
        );
      }
    );



  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'Name',
        name: 'original_key',
        type: 'link',
        clickLink: this.goToQueue.bind(this)
      },
      {
        header: 'Client',
        name: 'client',
        class: 'small-size-column'
      },
      {
        header: 'Elements',
        name: 'count',
        class: 'small-size-column'
      }
    ];

  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      getDataFromDB: this.getQueues.bind(this),
      hasEditPermissions: true,
      responsiveTitle: {
        label: 'env'
      },
      clickLink: this.goToQueue.bind(this),
      actionsOnSelected: [
        {
          click: this.deleteQueueMultiple.bind(this),
          class: 'fa fa-trash',
          title: 'projectAnalysis.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteQueueIndividual.bind(this),
          class: 'fa fa-trash'
        }
      ]
    };
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  sendTenantsToParent(nameTenants) {
    this.sentTenants.emit(nameTenants);
  }

  /**
   * Delete single document
   */
  public deleteQueueIndividual(item) {
    console.log(item);
  }

  /**
   * Delete multiple documents
   */
  public deleteQueueMultiple(selectedItems) {
    console.log(selectedItems);
  }
}
