import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { select, selectAll } from 'd3-selection';
import { scaleLinear, scaleBand, scaleTime, scaleOrdinal } from 'd3-scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { max, extent } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { brushX, brushSelection } from 'd3-brush';
import { transition } from 'd3-transition';
import * as d3scaleChromatic from 'd3-scale-chromatic';
import { StyleManagerService } from 'src/app/services/stylemanager.service';
const d3 = {
  select,
  selectAll,
  scaleLinear,
  scaleBand,
  scaleTime,
  scaleOrdinal,
  timeParse,
  timeFormat,
  max,
  extent,
  axisLeft,
  axisBottom,
  brushX,
  brushSelection,
  transition,
  ...d3scaleChromatic
};

export const DATE_INPUT_FORMAT = {
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

/**
 * Vertical Barchar with Time Brush Component
 */
@Component({
  selector: 'app-d3barchart-brushed',
  templateUrl: './barchart-brushed.component.html',
  styleUrls: ['./barchart-brushed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMAT }
  ]
})
export class D3BarChartBrushedComponent implements OnChanges {
  @Input() public data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  @Input() public hideDateInputs: boolean = false;
  @Output() onbrush = new EventEmitter();
  private cfg: any = {}; // Default configuration object
  private selection: any;
  private svg;
  private g;
  private axisg;
  private axisv;
  private axish;
  private tooltip;
  private barg;
  private bars;
  private rects;
  private brusharea;
  private xScale: any; // Chart horizontal scale
  private yScale: any; // Chart vertical scale
  private xScaleBrush: any; // Brush horizontal scaleº
  private yScaleBrush: any; // Brush vertical scale
  private colorScale: any; // Bar's color scale
  private xAxis: any; // Horizontal axis
  private yAxis: any; // Vertical axis
  private parseTime: any; // Time parse function
  private transition: any; // Transition obj

  public min_date: Date = new Date();
  public max_date: Date = new Date();
  public start_date: Date = new Date();
  public end_date: Date = new Date();

  constructor(
    elementRef: ElementRef,
    styleManager: StyleManagerService
  ) {
    const style = styleManager.styleSettings;

    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 400, // Default height
      margin: { top: 10, right: 30, bottom: 10, left: 40 },
      brushHeight: 30,
      brushGap: 26,
      key: 'key', // Value to compute
      dateKey: 'date',
      dateFormat: '%Y-%m-%d', // https://github.com/d3/d3-time-format/blob/master/README.md#locale_format
      colorScheme: style.charts.defaultColorScheme,
      colorKeys: {},
      yAxis: '',
      yScaleTicks: 5, // yAxis ticks
      yScaleFormat: '.0f' // yAxis format. See more on https://github.com/d3/d3-format
    };
  }

  ngOnChanges() {
    if (this.data) {
      const data = [...this.data];
      this.setConfiguration(this.config);
      this.computeData();
      this.updateScales(data);
      this.drawChart(data);
      this.bindData(data);
      this.updateChart(data);
      this.drawBrush(data);
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height -
      this.cfg.margin.top -
      this.cfg.margin.bottom -
      this.cfg.brushHeight;
  }
  /**
   * Creates a new d3 transition
   * @returns a new transition with the specified time
   */
   private getTransition(duration?: number): any{
    if(duration){
      return d3.transition('t').duration(duration);
    }
    return d3.transition('t');
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    // Format date functions
    this.parseTime = d3.timeParse(this.cfg.dateFormat);

    this.data.forEach(d => {
      d['jsdate'] = this.parseTime(d[this.cfg.dateKey]);
    });
    this.data.sort((a, b) => a.jsdate - b.jsdate);

    // Define vertical scale (Bar's height)
    this.yScale = d3.scaleLinear();

    // Define horizontal scale
    this.xScale = d3.scaleTime();

    // Define vertical scale (for brush area)
    this.yScaleBrush = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, d => d[this.cfg.key])])
      .range([this.cfg.brushHeight, 0]); // Inverse scale

    // Define horizontal scale (for brush area)
    this.xScaleBrush = d3
      .scaleTime()
      .domain(d3.extent(this.data, d => d['jsdate']))
      .range([0, this.cfg.width - this.cfg.width / this.data.length]);

    // Assign max and min dates to inputs
    this.min_date = this.xScaleBrush.domain()[0];
    this.max_date = this.xScaleBrush.domain()[1];

    if (this.cfg.colorScheme instanceof Array) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }
  }

  /**
   * Update chart scales based on filtered data
   */
  private updateScales(data) {
    const maxY = d3.max(data, d => d[this.cfg.key]);

    // Calcule vertical scale (Bar's height)
    this.yScale.domain([0, maxY ? maxY : 100]).range([this.cfg.height, 0]); // Inverse scale

    // If there aren't data, don't continue with changes
    if (!data.length) return;

    // Calcule horizontal scale
    this.xScale
      .domain(d3.extent(data, d => d['jsdate']))
      .range([0, this.cfg.width - this.cfg.width / data.length]);

    // Assign dates to inputs
    this.start_date = this.xScale.domain()[0];
    this.end_date = this.xScale.domain()[1];
  }

  /**
   * Draw the context
   */
  private drawChart(data) {
    this.selection.selectAll('svg.chart').remove();

    const totalHeight =
      this.cfg.height +
      this.cfg.margin.top +
      this.cfg.brushGap +
      this.cfg.brushHeight +
      this.cfg.margin.bottom;
    const totalWidth =
      this.cfg.width + this.cfg.margin.left + this.cfg.margin.right;

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--barchart chart--barchart-brushed')
      .attr('viewBox', `0 0 ${totalWidth} ${totalHeight}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('width', totalWidth)
      .attr('height', totalHeight)
      .style('width', '100%');

    // General group wrapper with margins convention
    this.g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    this.tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr(
        'class',
        'chart__tooltip chart__tooltip--barchart chart__tooltip--barchart-brushed'
      );

    // Axis group
    this.axisg = this.g
      .append('g')
      .attr(
        'class',
        'chart__axis chart__axis--barchart chart__axis--barchart-brushed'
      );

    // Vertical axis (horizontal grid)
    this.axisv = this.axisg
      .append('g')
      .attr(
        'class',
        'chart__axis-y chart__axis-y--barchart chart__axis-y--barchart-brushed chart__grid'
      );

    // Vertical axis title
    if (this.cfg.yAxis)
      this.axisg
        .append('text')
        .attr('y', -this.cfg.margin.left + 10)
        .attr('x', -this.cfg.height / 2 - this.cfg.margin.top)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(this.cfg.yAxis);

    // Botom axis
    this.axish = this.axisg
      .append('g')
      .attr(
        'class',
        'chart__axis-x chart__axis-x--barchart chart__axis-x--barchart-brushed'
      )
      .attr('transform', 'translate(0,' + this.cfg.height + ')');

    // Bars (all) group
    this.barg = this.g
      .append('g')
      .attr(
        'class',
        'chart__bars chart__bars--barchart chart__bars--barchart-brushed'
      );
  }

  /**
   * Bind data to elements
   */
  private bindData(data) {
    // Bind data to bars (svg rects)
    this.bars = this.barg
      .selectAll('rect')
      .data(data, d => d[this.cfg.dateKey]);
  }

  /**
   * Draw the chart
   */
  private updateChart(data) {
    // Vertical axis (horizontal grid)
    this.axisv
      .transition(this.getTransition(1000))
      .call(
        d3
          .axisLeft(this.yScale)
          .tickSize(-this.cfg.width)
          .ticks(this.cfg.yScaleTicks, this.cfg.yScaleFormat)
      );

    // Bottom axis
    this.axish.transition(this.getTransition(1000)).call(d3.axisBottom(this.xScale));

    // Enter elements
    this.bars
      .enter()
      .append('rect')
      .attr(
        'class',
        'chart__bar chart__bar--barchart chart__bar--barchart-brushed'
      )
      .attr('fill', d => this.barColor(d))
      .attr('height', 0)
      .attr('y', this.cfg.height)
      .on('mouseover', (d, i) => {
        this.tooltip
          .html(() => {
            return `
            <div>${i[this.cfg.dateKey]}: ${i[this.cfg.key]}</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', () => {
        this.tooltip.classed('active', false);
      })
      .on('mousemove', () => {
        this.tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      });

    // Update elements
    this.barg
      .selectAll('rect')
      .transition(this.getTransition(1000))
      .attr('x', d => this.xScale(d.jsdate))
      .attr('y', d => this.yScale(+d[this.cfg.key]))
      .attr('width', data.length ? this.cfg.width / data.length : 0)
      .attr('height', d => this.cfg.height - this.yScale(+d[this.cfg.key]));

    // Exit elements
    this.bars.exit().transition(this.getTransition(1000)).style('opacity', 0).remove();
  }

  /**
   * Draw the brush
   */
  private drawBrush(data) {
    // Brush area
    this.brusharea = this.svg
      .append('g')
      .attr(
        'class',
        'chart__brush chart__brush--barchart chart__brush--barchart-brushed'
      )
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top + this.cfg.height + this.cfg.brushGap
        })`
      );

    // Brush bars
    let brushbars = this.brusharea
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr(
        'class',
        'chart__bar chart__bar--barchart chart__bar--barchart-brushed'
      )
      .attr('fill', d => this.barColor(d))
      .attr('x', d => this.xScaleBrush(d.jsdate))
      .attr('y', d => this.yScaleBrush(+d[this.cfg.key]))
      .attr('width', this.cfg.width / data.length)
      .attr(
        'height',
        d => this.cfg.brushHeight - this.yScaleBrush(+d[this.cfg.key])
      );

    // Brush event
    this.brusharea.call(
      d3
        .brushX()
        .extent([
          [0, 0],
          [this.cfg.width, this.cfg.brushHeight]
        ])
        .on('end', _ => {
          this.brushEnded();
        })
    );
  }

  /**
   * onbrush (end) event handler
   */
  private brushEnded() {
    const brushselection = d3.brushSelection(this.brusharea.node());

    if (brushselection) {
      // Brush has changed -> filter data
      this.filterData(
        this.xScaleBrush.invert(brushselection[0]),
        this.xScaleBrush.invert(brushselection[1])
      );
    } else {
      // Brush is removed -> show all data
      this.resetFilters();
    }
  }

  /**
   * External date update
   */
  public updateDates(start_date, end_date) {
    this.start_date = start_date;
    this.end_date = end_date;
    this.updateInputDates();
  }

  /**
   * Date inputs event handler
   */
  private updateInputDates() {
    // update brush
    d3.brushX().move(this.brusharea, [
      this.xScaleBrush(this.start_date),
      this.xScaleBrush(this.end_date)
    ]);

    // filter data
    this.filterData(this.start_date, this.end_date);
  }

  /**
   * Filter data from within date range
   */
  private filterData(startDate, endDate) {
    let data = this.data.filter(d => {
      return d.jsdate >= startDate && d.jsdate <= endDate;
    });
    this.updateDataChain(data);

    // Emit date changes
    this.onbrush.emit([new Date(startDate), new Date(endDate)]);
  }

  /**
   * Show all data (remove filters)
   */
  private resetFilters() {
    this.updateDataChain(this.data);
    // Emit filter reset
    this.onbrush.emit(null);
  }

  /**
   * Methods to call on data filter change
   */
  private updateDataChain(data) {
    this.updateScales(data);
    this.bindData(data);
    this.updateChart(data);
  }

  /**
   * Compute bar color
   */
  private barColor(d) {
    if (
      this.cfg.colorKeys &&
      this.cfg.colorKeys.hasOwnProperty(d[this.cfg.key])
    ) {
      return this.cfg.colorKeys[d[this.cfg.key]];
    } else {
      return this.colorScale(d[this.cfg.key]);
    }
  }
}
