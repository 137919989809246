import { Injectable } from '@angular/core';
import { ValidationService } from '../common-components/validation.service';
import { FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class ExtractionService {
  constructor(
    public validationService: ValidationService,
    public globalService: GlobalService
  ) {}

  /**
   * Description: new validation functionality
   * get list of datamodels, list of functions and set validation type to Extraction
   * @returns void
   */
  public newValidationExtraction(formTest, validation) {
    formTest.addControl(
      'general_confidence',
      new FormControl('', [Validators.min(0), Validators.max(100)])
    );
    formTest.addControl('autoexecute', new FormControl(true));
    formTest.addControl('description', new FormControl(''));
    formTest.addControl('datamodelid', new FormControl(validation.datamodelid));
    formTest.addControl('docnameoverride', new FormControl(''));
    formTest.get('datamodelid').disable();
    formTest.get('type').setValue(1);
    formTest.get('general_confidence').setValue('');
    const cases = formTest.get('cases') as FormArray;
    cases.push(
      new FormGroup({
        case_name: new FormControl('', Validators.required),
        case_description: new FormControl(''),
        condition_type: new FormControl({ value: 'must', disabled: true }),
        success_message: new FormControl(''),
        fail_message: new FormControl(''),
        condition_list: this.makeConditions(validation.groupoffields),
        not_editable: new FormControl(true)
      })
    );
    return formTest;
  }

  /**
   * Populate all simple conditions for extracction validation
   * @returns condition list filled with field of interest
   */
  private makeConditions(groupoffields) {
    const condition_list = new FormArray([]);
    groupoffields.forEach(groupOfField => {
      groupOfField.fieldsofinterest.forEach(fieldofinterest => {
        const conditions = new FormArray([
          new FormGroup({
            confidence: new FormControl('', [
              Validators.min(0),
              Validators.max(100)
            ]),
            validationfunctionid: new FormControl('', Validators.required),
            condition_name: new FormControl(''),
            not_editable: new FormControl(true)
          })
        ]);
        const condition = new FormGroup({
          fieldofinterestid: new FormControl({
            value: `${fieldofinterest.fieldofinterestid}`,
            disabled: true
          }),
          description: new FormControl(''),
          groupoffieldsid: new FormControl({
            value: groupOfField.groupoffieldsid,
            disabled: false
          }),
          condition_type: new FormControl({ value: 'should', disabled: true }),
          simple_multiple: new FormControl({ value: false, disabled: true }),
          is_mandatory: new FormControl(true),
          error: new FormControl('', Validators.required),
          conditions: conditions,
          not_editable: new FormControl(true)
        });
        condition_list.push(condition);
      });
    });
    return condition_list;
  }

  /**
   * Edit extraction validation entry point
   * @param formTest form to populate controls
   * @param validation validation json to populate
   */
  public editValidationExtraction(validation, formTest) {
    formTest.addControl(
      'general_confidence',
      new FormControl('', [Validators.min(0), Validators.max(100)])
    );
    this.validationService.getFunctions();
    this.fillFormBasicExtraction(validation, formTest);
    const cases = validation.validations.extraction.validationcontent;
    this.fillCases(cases, formTest, validation.datamodelid);
    return formTest;
  }

  /**
   * Description: Fill basic data of the form
   * @param validation  validation to fill
   * @param nextStep    came from next step
   * @returns void
   */
  public fillFormBasicExtraction(validation, formTest) {
    formTest.addControl('datamodelid', new FormControl(validation.datamodelid));
    formTest.addControl('description', new FormControl(''));
    formTest.addControl('autoexecute', new FormControl(true));
    formTest.get('datamodelid').disable();
    formTest.get('type').setValue(1);
    formTest
      .get('description')
      .setValue(validation.validations.extraction.validationdescription);
    formTest
      .get('autoexecute')
      .setValue(validation.validations.extraction.validationautoexecute);
    if (validation.validations.extraction.docnameoverride) {
      const values = JSON.parse(
        validation.validations.extraction.docnameoverride
      )
        .map(fieldid => +fieldid)
        .filter(fieldid =>
          this.validationService.fieldOfInterestExistsOnDatamodel(
            fieldid,
            validation.datamodelid
          )
        );
      formTest.addControl(
        'docnameoverride',
        new FormControl(values, [Validators.required])
      );
    } else {
      formTest.addControl('docnameoverride', new FormControl(''));
    }
  }

  /**
   * Descipion: Populate cases and call population of Condition List
   * @param cases cases to populate data
   * @returns void
   */
  public fillCases(cases, formTest, datamodelid) {
    const formCases = formTest.get('cases') as FormArray;
    cases.forEach(validation_case => {
      formCases.push(
        new FormGroup({
          case_name: new FormControl(
            validation_case.case_name,
            Validators.required
          ),
          case_description: new FormControl(validation_case.case_description),
          condition_type: new FormControl({
            value: validation_case.condition_type,
            disabled: true
          }),
          success_message: new FormControl(''),
          fail_message: new FormControl(''),
          condition_list: this.fillConditionList(
            validation_case.condition_list,
            datamodelid
          )
        })
      );
      if ('not_editable' in validation_case) {
        const formCase: any = formCases['controls'][formCases.length - 1];
        formCase.addControl(
          'not_editable',
          new FormControl(validation_case.not_editable)
        );
      }
    });
  }

  /**
   * Populate Condition Lists and call functions to populate simple and multiple conditions
   * Recursive function
   * @param condition_lists conditions lists to populate data
   * @returns condition list filled
   */
  public fillConditionList(condition_lists, datamodelid) {
    const result_condition_list = new FormArray([]);
    const fieldsofinterestInValidation = [];
    condition_lists
      .filter(
        cnlt =>
          cnlt.conditions.length > 0 &&
          this.validationService.existOnDataModel(
            +cnlt['fieldofinterestid'],
            +cnlt['groupoffieldsid'],
            +datamodelid,
            this.globalService
          )
      )
      .forEach(condition_list => {
        // is simple
        const conditions = this.fillSimpleConditionExtraction(
          condition_list.conditions
        );
        let existFieldOfInterest = this.validationService.existOnDataModel(
          +condition_list.fieldofinterestid,
          condition_list.groupoffieldsid,
          datamodelid,
          this.globalService
        );
        if (existFieldOfInterest) {
          fieldsofinterestInValidation.push(+condition_list.fieldofinterestid);
          result_condition_list.push(
            new FormGroup({
              condition_type: new FormControl({
                value: condition_list.condition_type,
                disabled: true
              }),
              conditions: conditions,
              description: new FormControl(condition_list.description),
              simple_multiple: new FormControl(false),
              is_mandatory: new FormControl(condition_list.is_mandatory),
              error: new FormControl(
                `${condition_list.error}`,
                Validators.required
              ),
              fieldofinterestid: new FormControl({
                value: `${condition_list.fieldofinterestid}`,
                disabled: true
              }),
              groupoffieldsid: new FormControl({
                value: `${condition_list.groupoffieldsid}`,
                disabled: false
              })
            })
          );
          if ('not_editable' in condition_list) {
            const formCase: any =
              result_condition_list['controls'][
                result_condition_list.length - 1
              ];
            formCase.addControl(
              'not_editable',
              new FormControl(condition_list.not_editable)
            );
          }
        }
      });

    // search for new fileds interest to be populated
    let newFieldsOfInterest = this.getNewFieldsOfInterest(
      fieldsofinterestInValidation,
      datamodelid
    );

    // populate new fields of intesrest
    newFieldsOfInterest.map(nfof => {
      let new_conditions = new FormArray([
        new FormGroup({
          confidence: new FormControl('', [
            Validators.min(0),
            Validators.max(100)
          ]),
          validationfunctionid: new FormControl('', Validators.required),
          not_editable: new FormControl(true),
          condition_name: new FormControl('')
        })
      ]);
      result_condition_list.push(
        new FormGroup({
          fieldofinterestid: new FormControl({
            value: `${nfof.fieldofinterestid}`,
            disabled: true
          }),
          description: new FormControl(''),
          groupoffieldsid: new FormControl({
            value: `${nfof.groupoffieldsid}`,
            disabled: false
          }),
          condition_type: new FormControl({ value: 'should', disabled: true }),
          simple_multiple: new FormControl({ value: false, disabled: true }),
          error: new FormControl('', Validators.required),
          is_mandatory: new FormControl(true),
          conditions: new_conditions,
          not_editable: new FormControl(true)
        })
      );
    });

    return result_condition_list;
  }

  /**
   * Description:Populate each simple condition for Extraction
   * Operation is dynamic so its populated in other functions
   * @param conditionsList simple condition to populate data
   * @returns simple condition filled
   */
  public fillSimpleConditionExtraction(conditionsList) {
    const conditionsForm = new FormArray([]);
    conditionsList.forEach(jsonCondition => {
      const condition = new FormGroup({
        confidence: new FormControl(jsonCondition.confidence, [
          Validators.min(0),
          Validators.max(100)
        ]),
        validationfunctionid: new FormControl(
          jsonCondition.validationfunctionid,
          Validators.required
        ),
        condition_name: new FormControl(jsonCondition.condition_name)
      });
      if ('not_editable' in jsonCondition) {
        condition.addControl(
          'not_editable',
          new FormControl(jsonCondition.not_editable)
        );
      }
      if (jsonCondition.selected_function) {
        condition.addControl(
          'selected_function',
          new FormControl(jsonCondition.selected_function, Validators.required)
        );
      }
      if (
        jsonCondition.validationfunctionid ||
        jsonCondition.validation_function
      ) {
        const val =
          jsonCondition.validationfunctionid ||
          jsonCondition.validation_function;
        condition.addControl(
          'validationfunctionid',
          new FormControl(val, Validators.required)
        );
      }
      if (jsonCondition.parameter) {
        condition.addControl(
          'parameter',
          new FormControl(jsonCondition.parameter, Validators.required)
        );
      }
      if (jsonCondition.input) {
        condition.addControl(
          'input',
          new FormControl(jsonCondition.input, Validators.required)
        );
      }
      conditionsForm.push(condition);
    });

    return conditionsForm;
  }

  /**
   * Returns template Case condition for extraction
   */
  public getCaseCondition() {
    return new FormGroup({
      confidence: new FormControl('', [Validators.min(0), Validators.max(100)]),
      validationfunctionid: new FormControl('', Validators.required),
      condition_name: new FormControl('')
    });
  }

  public getNewFieldsOfInterest(fieldsofinterestInValidation, datamodelid) {
    let datamodelObj = this.globalService.getDataModelById(+datamodelid);
    let newFieldsOfInterest = [];

    datamodelObj.groupoffields.map(gof => {
      gof.fieldsofinterest.map(fof => {
        if (!fieldsofinterestInValidation.includes(fof.fieldofinterestid)) {
          newFieldsOfInterest.push({
            groupoffieldsid: gof.groupoffieldsid,
            fieldofinterestid: fof.fieldofinterestid
          });
        }
      });
    });

    return newFieldsOfInterest;
  }
}
