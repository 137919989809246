import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RoleModel } from 'src/app/models/role.model';
import { GlobalService } from '../../services/global.service';
import { SubmitFilterModalComponent } from '../modals/submit-filter/submit-filter-modal.component';
import {
  DocumentsCount,
  ValidationSearchService
} from './validation-search.service';
import { DatamodelSearchService } from 'src/app/pages/datamodels/datamodel-search.service';
import { ApiService } from 'src/app/services/api.service';
import { EnrichmentModel } from 'src/app/models/enrichment.model';
import { Subscription, Observable } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthenticationService } from 'src/app/security/authentication.service';

interface TenantInterface {
  nameOfTenant: string;
  idOfTenant: string;
}

@Component({
  selector: 'app-validations-filter',
  templateUrl: './validations-filter.component.html',
  styleUrls: ['./validations-filter.component.scss']
})
export class ValidationsFilterComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Output() onSearch = new EventEmitter();
  @Output() paginationTypeChange = new EventEmitter<string>();

  @Input() groups: any[];
  @Input() statusList: any[];
  @Input() analysisList: any[];
  @Input() rolesList: RoleModel[];
  @Input() nameTenants: TenantInterface[];
  @Input() EYdecisions: any[];
  @Input() datamodels: any[];
  @Input() assignedToFilter: boolean = false;
  @Input() projectFilter: boolean = false;
  @Input() columnsFilter: any[];
  @Input() paginationType: string;
  @Input() hasSelectedItems: boolean = false;
  @Input() settings: any;
  @Input() enableMedicFeatures: boolean = false;
  @Input() stateList: any[];

  public selectGroups: any[];

  //Variables to check changes on filter to clean value
  public evtnSubscription: Subscription;
  @Input() filterObservable: Observable<any>;

  /**Enrichment files list and filter related variables */
  public enrichmentTypesList: EnrichmentModel[] = [];
  public subscriptionEnrichmentTypes: Subscription;
  public rows: {};
  public showWarning: boolean = false;
  public type: string | number = '';
  public loadingTable: boolean;

  /* Filter variables */
  public projectName: string;
  public documentdisplayname_logic: string;
  public analysisdisplayname_logic: string;
  public projectname_logic: string;
  public documentName: string;
  public createdByName: string;
  public tagName: string;
  public assignedTo: string;
  public filters: [];
  private analysisName: string;
  private datamodelId: any = {};
  private documentid: number;
  private datamodelDisplayName: string;
  private datamodeldisplayname: string;
  private checklistname: string;
  private decisionDisplayName: string;
  private groupDisplayName: string;
  private statusDisplayName: string;
  public username: string;
  public email: string;
  public roleid: string;
  public client: string;
  public assigned: string;
  public qsassigned: string;
  public hasextraction: string;
  public hasbusinessrules: string;
  public preliminaryname: string;
  public catalogname: string;
  public start_date: Date = new Date();
  public end_date: Date = new Date();
  public first_date: Date = new Date();
  public last_date: Date = new Date();
  public datechange: boolean = true;
  public category: string = '';
  public enrichmentfiletype: string;
  public level: string;
  public user: any = {};
  public explorationName: string;
  public explorationdisplayname_logic: string;
  public analysisids = [];
  public failedAnalysisName : string ;
  public querySheetName: string;
  public querysheetdisplayname_logic: string;
  public stateDisplayName: string;
  public stateId: string;

  private validationPaginations: string[] = [
    'documentvalidationsassigned',
    'documentvalidations'
  ];
  private datamodelPaginations: string[] = [
    'checklist',
    'validations',
    'validationsMulti'
  ];
  private projectUsersAssignations: string[] = [
    'assignedprojectusers',
    'unassignedprojectusers'
  ];
  private qsUsersAssignations: string[] = [
    'explorers'
  ];
  private statusId: string;
  private reviewStage: string | string[];
  private recommended: any = '';
  private groupDisable: boolean;

  public hasBeenSearched: boolean = false;
  public hasBeenSearchedDatamodel: boolean = false;
  public selectFilter: FormControl = new FormControl('1');

  public isreviewpaused: string;
  public reviewduedate: string;

  public areMedicFeaturesEnabled: boolean = false;
  public filterMode: string;
  public documentsCount: DocumentsCount;

  public _userPermissions: any;

  constructor(
    private global: GlobalService,
    private validationSearchService: ValidationSearchService,
    private datamodelSearchService: DatamodelSearchService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private translate: TranslatePipe,
    private authService: AuthenticationService
  ) {
    this.user = authService.getLoggedInUser();
    this.start_date.setFullYear(this.start_date.getFullYear() - 10);
    this.loadingTable = true;
  }

  ngOnInit() {
    if (this.paginationType == 'explorations') {
      this.first_date = this.start_date;
    }
    if (this.paginationType == 'querysheets') {
      this.first_date = this.start_date;
    }
    this.evtnSubscription = this.filterObservable.subscribe(
      (filter: string) => {
        this.cleanFilter(filter);
      }
    );
    if (
      this.enableMedicFeatures &&
      this.authService.userCanViewModule(
        this.user,
        'DocumentsReviewKpiFiltersFunctionality'
      )
    ) {
      this.areMedicFeaturesEnabled = true;

      this.validationSearchService
        .getDocumentsCount()
        .subscribe((documentsCount: DocumentsCount) => {
          this.documentsCount = documentsCount;
        });
    }

    if (this.paginationType === 'documentstoconsolidate') {
      this.getDocumentsByDate();
    }

    this.getDropDownSources();
    if (this.validationPaginations.includes(this.paginationType)) {
      this.hasBeenSearched = this.validationSearchService.getHasBeenSearched(
        this.paginationType
      );
      this.filters = this.validationSearchService.getPreviousSearch(
        this.paginationType
      );
      if (this.hasBeenSearched) {
        this.fillParams();
        this.emitSearch();
      }
    }
    if (this.datamodelPaginations.includes(this.paginationType)) {
      this.hasBeenSearchedDatamodel =
        this.datamodelSearchService.getHasBeenSearched(this.paginationType);
      this.filters = this.datamodelSearchService.getPreviousSearch(
        this.paginationType
      );
      if (this.hasBeenSearchedDatamodel) {
        this.fillParams();
        this.emitSearch();
      }
    }
    if (
      this.paginationType == 'assignedprojectusers' ||
      this.paginationType == 'unassignedprojectusers'
    ) {
      this.assigned = '1';
      this.emitSearch();
    }
    if (this.paginationType == 'explorers') {
      this.qsassigned = '';
    }
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  ngOnDestroy(): void {
    if (this.evtnSubscription) {
      this.evtnSubscription.unsubscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groups) {
      const selectGroups = [...this.groups];
      selectGroups.push({
        groupid: 'all',
        groupname: 'All Levels'
      });

      this.selectGroups = selectGroups;
    }
  }

  /**
   * Request documents grouped by date
   */
  private getDocumentsByDate() {
    this.apiService.get('clientoverview/dates/', {}, '').subscribe(data => {
      if (data.length > 0) {
        this.first_date = new Date(data[0].date);
        this.last_date = new Date(data[data.length - 1].date);
        this.start_date = this.first_date;
        this.end_date = this.last_date;
      }
    });
  }
  public fillParams() {
    this.projectName = this.filters['projectname'];
    this.projectname_logic = this.filters['projectname_logic'];
    this.analysisName = this.filters['analysisname'];
    this.analysisdisplayname_logic = this.filters['analysisdisplayname_logic'];
    this.documentid = this.filters['documentid'];
    this.documentName = this.filters['documentdisplayname'];
    this.documentdisplayname_logic = this.filters['documentdisplayname_logic'];
    this.datamodelId = this.filters['datamodelId'];
    this.tagName = this.filters['tagName'];
    this.createdByName = this.filters['createdByName'];
    this.assignedTo = this.filters['assignedTo'];
    this.reviewStage = this.filters['reviewgroup'];
    this.statusId = this.filters['statusId'];
    this.recommended = this.filters['recommended'];
    this.groupDisplayName = this.filters['review_group_name'];
    this.datamodelDisplayName = this.filters['datamodelname'];
    this.datamodeldisplayname = this.filters['datamodeldisplayname'];
    this.decisionDisplayName = this.filters['reviewrecommended_name'];
    this.statusDisplayName = this.filters['status_name'];
    this.username = this.filters['username'];
    this.email = this.filters['email'];
    this.roleid = this.filters['roleid'];
    this.assigned = this.filters['assigned'];
    this.qsassigned = this.filters['qsassigned'];
    this.hasextraction = this.filters['hasextraction'];
    this.hasbusinessrules = this.filters['hasbusinessrules'];
    this.preliminaryname = this.filters['preliminaryname'];
    this.catalogname = this.filters['catalogname'];
    this.isreviewpaused = this.filters['isreviewpaused'];
    this.reviewduedate = this.filters['reviewduedate'];
    this.checklistname = this.filters['checklistname'];
    this.start_date = this.filters['start_date'];
    this.end_date = this.filters['end_date'];
    this.category = this.filters['category'];
    this.enrichmentfiletype = this.filters['enrichmentfiletype'];
    this.level = this.filters['level'];
    this.client = this.client['client'];
    this.stateId = this.filters['stateId'];
    this.stateDisplayName = this.filters['state_name'];
  }

  private fillFilter() {
    this.filters['projectname'] = this.projectName;
    this.filters['analysisname'] = this.analysisName;
    this.filters['datamodelId'] = this.datamodelId;
    this.filters['datamodelname'] = this.datamodelDisplayName;
    this.filters['datamodeldisplayname'] = this.datamodeldisplayname;
    this.filters['review_group_name'] = this.groupDisplayName;
    this.filters['reviewrecommended_name'] = this.decisionDisplayName;
    this.filters['documentid'] = this.documentid;
    this.filters['documentdisplayname'] = this.documentName;
    this.filters['status_name'] = this.statusDisplayName;
    this.filters['createdByName'] = this.createdByName;
    this.filters['tagName'] = this.tagName;
    this.filters['assignedTo'] = this.assignedTo;
    this.filters['reviewgroup'] = this.reviewStage;
    this.filters['statusId'] = this.statusId;
    this.filters['recommended'] = this.recommended;
    this.filters['username'] = this.username;
    this.filters['email'] = this.email;
    this.filters['roleid'] = this.roleid;
    this.filters['assigned'] = this.assigned;
    this.filters['qsassigned'] = this.qsassigned;
    this.filters['hasextraction'] = this.hasextraction;
    this.filters['hasbusinessrules'] = this.hasbusinessrules;
    this.filters['preliminaryname'] = this.preliminaryname;
    this.filters['catalogname'] = this.catalogname;
    this.filters['isreviewpaused'] = this.isreviewpaused;
    this.filters['reviewduedate'] = this.reviewduedate;
    this.filters['checklistname'] = this.checklistname;
    this.filters['start_date'] = this.start_date;
    this.filters['end_date'] = this.end_date;
    this.filters['category'] = this.category;
    this.filters['enrichmentfiletype'] = this.enrichmentfiletype;
    this.filters['level'] = this.level;
    this.filters['client'] = this.client;
    this.filters['stateId'] = this.stateId;
    this.filters['state_name'] = this.stateDisplayName;

    return this.filters;
  }

  /**
   * Send filter parameters
   */
  public sendParamFilter() {
    if (this.hasSelectedItems) {
      this.modalService
        .open(SubmitFilterModalComponent, {})
        .result.then(result => {
          if (result === 'Agree') {
            this.sendParams();
          }
        });
    } else {
      this.sendParams();
    }
  }

  private sendParams() {
    if (this.paginationType == 'documentstoconsolidate') {
      if (this.datamodels && this.datamodelId) {
        let datamodelnames = '';
        for (const datamodelid of this.datamodelId) {
          let datamodelfind = this.datamodels.find(
            dm => dm.datamodelid === datamodelid
          );
          datamodelnames =
            datamodelnames + datamodelfind.datamodeldisplayname + ', ';
        }
        this.datamodelDisplayName = datamodelnames.substring(
          0,
          datamodelnames.length - 2
        );
      } else {
        this.datamodelDisplayName = '';
      }
    } else {
      this.datamodelDisplayName =
        this.datamodels && this.datamodelId
          ? this.datamodels.find(dm => dm.datamodelid === this.datamodelId)
              .datamodeldisplayname
          : '';
    }

    this.updateReviewGroupDisplayName();
    this.updateDecisionDisplayName();

    this.statusDisplayName =
      this.statusList && this.statusId
        ? this.statusList.find(st => st.statusid === this.statusId)
            .statusdisplayname
        : '';

     this.stateDisplayName =
      this.stateList && this.stateId
        ? this.stateList.find(st => st.stateid === this.stateId)
            .statedisplayname
        : '';   
    if (this.validationPaginations.includes(this.paginationType)) {
      this.validationSearchService.setHasBeenSearched(
        true,
        this.paginationType
      );

      this.validationSearchService.setPreviousSearch(
        this.fillFilter(),
        this.paginationType
      );
    }

    if (this.datamodelPaginations.includes(this.paginationType)) {
      this.datamodelSearchService.setHasBeenSearched(true, this.paginationType);

      this.datamodelSearchService.setPreviousSearch(
        this.fillFilter(),
        this.paginationType
      );
    }

    this.emitSearch();
  }

  /**
   * Clean filters and search results
   */
  public cleanFilters(search: boolean = true) {
    this.projectName = '';
    this.projectname_logic = '';
    this.analysisName = '';
    this.documentid = undefined;
    this.documentName = '';
    this.documentdisplayname_logic = '';
    this.analysisdisplayname_logic = '';
    this.datamodelId = undefined;
    this.tagName = '';
    this.createdByName = '';
    this.assignedTo = '';
    this.reviewStage = undefined;
    this.statusId = undefined;
    this.recommended = '';
    this.groupDisable = false;
    this.datamodelDisplayName = '';
    this.datamodeldisplayname = '';
    this.decisionDisplayName = '';
    this.groupDisplayName = '';
    this.statusDisplayName = '';
    this.username = '';
    this.email = '';
    this.roleid = '';
    this.assigned = '';
    this.qsassigned = '';
    this.hasextraction = '';
    this.hasbusinessrules = '';
    this.preliminaryname = '';
    this.catalogname = '';
    this.isreviewpaused = undefined;
    this.reviewduedate = '';
    this.checklistname = '';
    this.start_date = this.first_date;
    this.end_date = this.last_date;
    this.category = '';
    this.enrichmentfiletype = '';
    this.level = '';
    this.client = '';
    this.analysisids = [];
    this.explorationName = '';
    this.explorationdisplayname_logic = '';
    this.querySheetName = '';
    this.querysheetdisplayname_logic = '';
    this.stateId = undefined;
    this.stateDisplayName = '';
    this.filterMode = undefined;
    this.failedAnalysisName = '';

    if (this.projectUsersAssignations.includes(this.paginationType)) {
      this.assigned = '1';
      this.paginationType = 'assignedprojectusers';
      this.paginationTypeChange.emit(this.paginationType);
    }

    if (this.qsUsersAssignations.includes(this.paginationType)) {
      this.qsassigned = '';
      this.paginationType = 'explorers';
      this.paginationTypeChange.emit(this.paginationType);
    }

    if (this.validationPaginations.includes(this.paginationType)) {
      this.filters = this.validationSearchService.setPreviousSearch(
        [],
        this.paginationType
      );
      if (this.hasBeenSearched) {
        this.validationSearchService.setHasBeenSearched(
          false,
          this.paginationType
        );
      }
    }

    if (this.datamodelPaginations.includes(this.paginationType)) {
      this.filters = this.datamodelSearchService.setPreviousSearch(
        [],
        this.paginationType
      );
      if (this.hasBeenSearchedDatamodel) {
        this.datamodelSearchService.setHasBeenSearched(
          false,
          this.paginationType
        );
      }
    }
    if (this.paginationType == 'explorations') {
      this.filters = this.validationSearchService.setPreviousSearch(
        [],
        this.paginationType
      );
    }
    if (this.paginationType == 'querysheets') {
      this.filters = this.validationSearchService.setPreviousSearch(
        [],
        this.paginationType
      );
    }
    if (this.paginationType == 'documentstatus') {
      this.filters = this.validationSearchService.setPreviousSearch(
        [],
        this.paginationType
      );
    }

    if (search) {
      this.emitSearch();
    }
    this.category = '';
    this.getDropDownSources();
  }

  /**
   * Clean specific filter input field
   */

  public cleanFilter(filter: string) {
    switch (filter) {
      case 'projectname':
        this.projectName = '';
        this.projectname_logic = '';
        break;
      case 'projectname_logic':
        this.projectname_logic = '';
        break;
      case 'analysisname':
        this.analysisName = '';
        this.analysisdisplayname_logic = '';
        break;
      case 'analysisdisplayname_logic':
        this.analysisdisplayname_logic = '';
        break;
      case 'datamodelid':
        this.datamodelId = undefined;
        break;
      case 'datamodeldisplayname':
        this.datamodeldisplayname = '';
        break;
      case 'datamodelname':
        this.datamodelId = undefined;
        break;
      case 'review_group':
        this.reviewStage = '';
        break;
      case 'reviewrecommended':
        this.recommended = '';
        break;
      case 'documentid':
        this.documentid = undefined;
        break;
      case 'documentdisplayname':
        this.documentName = '';
        this.documentdisplayname_logic = '';
        break;
      case 'documentdisplayname_logic':
        this.documentdisplayname_logic = '';
        break;
      case 'statusid':
        this.statusDisplayName = '';
        this.statusId = undefined;
        break;
      case 'status_name':
        this.statusDisplayName = '';
        this.statusId = undefined;
        break;
      case 'stateId':
          this.stateDisplayName = '';
          this.stateId = undefined;
        break;
      case 'state_name':
          this.stateDisplayName = '';
          this.stateId = undefined;
        break;  
      case 'tagname':
        this.tagName = '';
        break;
      case 'assigned_to_name':
        this.assignedTo = '';
        break;
      case 'username':
        this.username = '';
        break;
      case 'email':
        this.email = '';
        break;
      case 'roleid':
        this.roleid = '';
        break;
      case 'hasextraction':
        this.hasextraction = '';
        break;
      case 'hasbusinessrules':
        this.hasbusinessrules = '';
        break;
      case 'documentname':
        this.documentName = '';
        break;
      case 'createdbyname':
        this.createdByName = '';
        break;
      case 'createdByName':
        this.createdByName = '';
        break;
      case 'enrichmentfiletype':
        this.enrichmentfiletype = '';
        break;
      case 'level':
        this.level = '';
        break;
      case 'category':
        this.category = '';
        this.enrichmentfiletype = '';
        this.type = '';
        this.getDropDownSources();
        break;
      case 'unassignedprojectusers':
        this.assigned = '1';
        break;
      case 'explorers':
        this.qsassigned = '';
        break;
      case 'isreviewpaused':
        this.isreviewpaused = undefined;
        break;
      case 'client':
        this.client = '';
        break;
      case 'explorationName':
        this.explorationName = '';
        this.explorationdisplayname_logic = '';
        break;
      case 'explorationdisplayname_logic':
        this.explorationdisplayname_logic = '';
      case 'querySheetName':
        this.querySheetName = '';
        this.querysheetdisplayname_logic = '';
      case 'querysheetdisplayname_logic':
        this.querysheetdisplayname_logic = '';
          break;  
      case 'failedAnalysisName':
        this.failedAnalysisName = '';
        break;
      case 'analysisids':
        this.analysisids = [];
        break;
      case 'start_date':
        if (this.paginationType == 'explorations') {
          // as to not affect other components, put the if condition for exploration
          this.start_date = this.first_date;
        }
        if (this.paginationType == 'querysheets') {
          // as to not affect other components, put the if condition for exploration
          this.end_date = this.last_date;
        }
        break;
      case 'end_date': 
        if (this.paginationType == 'explorations') {
          // as to not affect other components, put the if condition for exploration
          this.end_date = this.last_date;
        }
        if (this.paginationType == 'querysheets') {
          // as to not affect other components, put the if condition for exploration
          this.end_date = this.last_date;
        }
        break;
      default:
        break;
    }
  }

  /**
   * Emit search event to parent component
   */
  private emitSearch() {
    const searchObject = {
      projectname: this.projectName,
      projectname_logic: this.projectname_logic,
      analysisname: this.analysisName,
      analysisdisplayname_logic: this.analysisdisplayname_logic,
      datamodelId: this.datamodelId,
      datamodelname: this.datamodelDisplayName,
      datamodeldisplayname: this.datamodeldisplayname,
      review_group_name: this.groupDisplayName,
      reviewrecommended_name: this.decisionDisplayName,
      documentid: this.documentid,
      documentdisplayname: this.documentName,
      documentdisplayname_logic: this.documentdisplayname_logic,
      status_name: this.statusDisplayName,
      createdByName: this.createdByName,
      tagName: this.tagName,
      assignedTo: this.assignedTo,
      reviewgroup: this.reviewStage,
      statusId: this.statusId,
      recommended: this.recommended,
      username: this.username,
      email: this.email,
      roleid: this.roleid,
      assigned: this.assigned,
      qsassigned: this.qsassigned,
      hasextraction: this.hasextraction,
      hasbusinessrules: this.hasbusinessrules,
      preliminaryname: this.preliminaryname,
      catalogname: this.catalogname,
      isreviewpaused: this.isreviewpaused,
      reviewduedate: this.reviewduedate,
      checklistname: this.checklistname,
      start_date: this.start_date,
      end_date: this.end_date,
      category: this.category,
      enrichmentfiletype: this.enrichmentfiletype,
      level: this.level,
      client : this.client,
      analysisids : this.analysisids,
      explorationName: this.explorationName,
      explorationdisplayname_logic: this.explorationdisplayname_logic,
      querySheetName: this.querySheetName,
      querysheetdisplayname_logic: this.querysheetdisplayname_logic,
      failedAnalysisName : this.failedAnalysisName,
      stateId: this.stateId,
      state_name: this.stateDisplayName,
    };
    // Remove the keys from the object if they are not present
    Object.keys(searchObject).forEach(key => {
      if ([undefined, ''].includes(searchObject[key])) {
        delete searchObject[key];
      } else {
        if (
          ['start_date', 'end_date'].includes(key) &&
          this.start_date == this.first_date &&
          this.end_date == this.last_date
        ) {
          delete searchObject[key];
        }
      }
    });
    if (this.projectUsersAssignations.includes(this.paginationType)) {
      this.paginationType =
        this.assigned === '1'
          ? 'assignedprojectusers'
          : 'unassignedprojectusers';
    }
    if (this.qsUsersAssignations.includes(this.paginationType)) {
      this.paginationType = 'explorers';
    }

    this.onSearch.emit(searchObject);
    this.paginationTypeChange.emit(this.paginationType);
  }

  /**
   * Handle EY Decision select change.
   */
  public onSelectEYDecision(): void {
    if (this.filterMode == 'completed') {
      this.filterMode = undefined;
    }

    if ([0, 1].includes(this.recommended)) {
      this.reviewStage = undefined;
    }
  }

  /**
   * Handle review group select change.
   */
  public onSelectReviewGroup(): void {
    if (this.filterMode == 'valiPending') {
      this.filterMode = undefined;
    }

    this.filterMode = undefined;
  }

  /**
   * Input date filter handler
   * Called when general datepicker changed
   */
  public onInputDatesSelect() {
    // Transform Moment.js date to regular date object
    this.start_date = new Date(this.start_date);
    this.end_date = new Date(this.end_date);
    this.datechange = true;
  }

  /**
   * Called when user clicks on 'Validation Pending' button.
   */
  public onSelectValidationPendingFilter(): void {
    this.cleanFilters(false);

    this.reviewStage = 'all';
    this.updateReviewGroupDisplayName();

    this.filterMode = 'valiPending';

    this.emitSearch();
  }

  /**
   * Called when user clicks on 'Completed' button.
   */
  public onSelectCompletedFilter(): void {
    this.cleanFilters(false);

    this.recommended = 2;
    this.updateDecisionDisplayName();

    this.filterMode = 'completed';

    this.emitSearch();
  }

  /**
   * Update review group display name.
   */
  private updateReviewGroupDisplayName(): void {
    if (this.selectGroups && this.reviewStage) {
      if (typeof this.reviewStage == 'object') {
        this.groupDisplayName = this.reviewStage
          .map(
            (groupId: string) =>
              this.selectGroups.find((group: any) => group.groupid == groupId)
                ?.groupname ?? ''
          )
          .join(' or ');
      } else {
        this.groupDisplayName =
          this.selectGroups.find(g => g.groupid === this.reviewStage)
            ?.groupname ?? '';
      }
    } else {
      this.decisionDisplayName = '';
    }
  }

  /**
   * Update decision (recommend/not recommend) display name.
   */
  private updateDecisionDisplayName(): void {
    if (this.EYdecisions && this.recommended !== '') {
      if (typeof this.recommended == 'object') {
        this.decisionDisplayName = this.recommended
          .map(
            (decisionId: number) =>
              this.EYdecisions.find(
                (decision: any) => decision.eydecision == decisionId
              )?.eydecisionname ?? ''
          )
          .join(' or ');
      } else {
        this.decisionDisplayName =
          this.EYdecisions.find(dec => dec.eydecision === this.recommended)
            ?.eydecisionname ?? '';
      }
    } else {
      this.decisionDisplayName = '';
    }
  }

  /**
   *
   * Default logic name 'exact phrase'
   */
  public controlNameLogic() {
    this.documentdisplayname_logic = 'phrase';
  }

  /**
   *
   * Default logic name 'exact phrase'
   */
  public controlNameLogicAnalysis() {
    this.analysisdisplayname_logic = 'phrase';
  }

  public controlNameLogicProject() {
    this.projectname_logic = 'phrase';
  }


  public controlNameLogicExploration() {
    // Set this logic value to phrase if nothing else already selected
    if (this.explorationName && !this.explorationdisplayname_logic) {
      this.explorationdisplayname_logic = 'phrase';
    } else if (!this.explorationName ) {
      this.explorationdisplayname_logic = '';
    }
  }
  public controlNameLogicQuerysheet() {
    // Set this logic value to phrase if nothing else already selected
    if (this.querySheetName && !this.querysheetdisplayname_logic) {
      this.querysheetdisplayname_logic = 'phrase';
    } else if (!this.querySheetName ) {
      this.querysheetdisplayname_logic = '';
    }
  }
  public showFilter(filter) {
    if (this.columnsFilter === undefined) {
      return false;
    }
    let exist = this.columnsFilter.find(cf => cf.name === filter);
    if (
      filter === 'documentid' &&
      this.paginationType !== 'validations' &&
      this.paginationType !== 'validationsMulti' &&
      this.paginationType !== 'users' &&
      this.paginationType !== 'dashboard' &&
      this.paginationType !== 'assignedprojectusers' &&
      this.paginationType !== 'unassignedprojectusers' &&
      this.paginationType != 'checklist' &&
      this.paginationType != 'analysis' &&
      this.paginationType != 'enrichment' &&
      this.paginationType != 'reviewers' &&
      this.paginationType != 'explorers' &&
      this.paginationType != 'childDocuments' &&
      this.paginationType != 'queues-cache' &&
      this.paginationType != 'explorations' &&
      this.paginationType != 'querysheets' &&
      this.paginationType != 'documentstatus'
    ) {
      return true;
    }

    if (
      (filter === 'analysisname' || filter === 'analysisdisplayname_logic') &&
      this.paginationType !== 'analysis'
    ) {
      return false;
    }

    if (
      (filter === 'analysisname' || filter === 'analysisdisplayname_logic') &&
      this.paginationType == 'analysis'
    ) {
      return true;
    }

    if (
      filter === 'documentdisplayname_logic' &&
      (this.paginationType == 'dashboard' ||
        this.paginationType == 'childDocuments')
    ) {
      return true;
    }

    if (
      filter === 'enrichmentfiletype' &&
      this.paginationType == 'enrichment'
    ) {
      return true;
    }

    if (
      (filter === 'documentid' || filter === 'assigned') &&
      this.paginationType == 'childDocuments'
    ) {
      return false;
    }

    if (filter === 'level' && this.paginationType == 'enrichment') {
      return true;
    }

    if (
      filter === 'range_date' &&
      (this.paginationType === 'documentstoconsolidate' || this.paginationType === 'explorations'|| this.paginationType === 'querysheets')
    ) {
      return true;
    }

    if (
      filter === 'assigned' &&
      this.projectUsersAssignations.includes(this.paginationType)
    ) {
      return true;
    }

    if (
      filter === 'qsassigned' &&
      this.qsUsersAssignations.includes(this.paginationType)
    ) {
      return true;
    }

    if (filter == 'checklistname' && this.paginationType == 'checklist') {
      return true;
    }

    if (
      filter == 'assigned' &&
      (this.paginationType == 'assignedprojectusers' ||
        this.paginationType == 'unassignedprojectusers')
    ) {
      return true;
    }

    if (
      filter == 'qsassigned' &&
      this.paginationType == 'explorers'
    ) {
      return true;
    }

    if (
      this.user.isadmin &&
      filter === 'assignedToName' &&
      this.paginationType === 'childDocuments'
    ) {
      return true;
    }

    if (
      (filter == 'hasextraction' || filter == 'hasbusinessrules') &&
      this.paginationType == 'validations'
    ) {
      return true;
    }

    if (
      ['hasextraction', 'hasbusinessrules'].includes(filter) &&
      this.paginationType === 'validations'
    ) {
      return true;
    }

    if (
      [
        'documentdisplayname_logic',
        'documentdisplayname',
        'datamodel',
        'createdbyuser'
      ].includes(filter) &&
      [
        'documentvalidations',
        'documentvalidationsassigned',
        'documents',
        'documentstoconsolidate',
        'external'
      ].includes(this.paginationType)
    ) {
      return true;
    }

    if (
      [
        'documentdisplayname_logic',
        'documentdisplayname',
        'projectname',
        'projectname_logic',
        'datamodel'
      ].includes(filter) &&
      ['timetracking'].includes(this.paginationType)
    ) {
      return true;
    }

    if (
      filter === 'datamodeldisplayname' &&
      this.paginationType === 'timetracking'
    ) {
      return false;
    }

    if (
      filter === 'projectname_logic' &&
      this.validationPaginations.includes(this.paginationType)
    ) {
      return true;
    }

    if (filter === 'explorationdisplayname_logic' && this.paginationType === 'explorations') {
      return true;
    }
    if (filter === 'querysheetdisplayname_logic' && this.paginationType === 'querysheets') {
      return true;
    }
    if (filter === 'createdbyuser' && this.paginationType === 'querysheets') {
      return false;
    }
    if (filter === 'isPublishedStatus' && this.paginationType === 'querysheets') {
      return true;
    }
    if (this.paginationType === 'validationsMulti') {
      if (['datamodeldisplayname', 'createdbyuser'].includes(filter)) {
        return false;
      }
      if (['catalogname', 'preliminaryname'].includes(filter)) {
        return true;
      }
    }
    if(filter == 'analysisList' && this.paginationType === 'explorations'){
      return true;
    }
    if(filter == 'failedAnalysisName' && this.paginationType === 'documentstatus'){
      return true;
    }
    return this.columnsFilter.find(cf => cf.name === filter) === undefined
      ? false
      : true;
  }

  public onSelectType(type) {
    const enrichmentTypeId = type.value;
    if (this.rows['total_elements'] > 0) {
      const sameTypeDoc = this.rows['data'].filter(
        doc => doc.additionaldocumenttypeid === +enrichmentTypeId
      );
      const idsInTable = this.rows['data'].map(
        data => data.additionaldocumenttypeid
      );
      this.showWarning = idsInTable.includes(+this.type);
    }
  }

  /**Enrichment dropdown */
  public getDropDownSources() {
    this.enrichmentTypesList = Array.from(
      this.global.getEnrichmentTypes().values()
    ).filter(type => type.enrichment_category == parseInt(this.category));
    if (
      this.enrichmentTypesList.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionEnrichmentTypes = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.enrichmentTypesList = Array.from(
            this.global.getEnrichmentTypes().values()
          ).filter(type => type.enrichment_category === +this.category);
          this.loadingTable = false;
        });
    } else {
      this.loadingTable = false;
    }
  }

  public filterListOfTypes(newValue) {
    this.enrichmentTypesList = Array.from(
      this.global.getEnrichmentTypes().values()
    ).filter(type => type.enrichment_category === +newValue);
    this.type = '';
  }
}
