import { Component, OnInit, Input } from '@angular/core';
import { Reason } from 'src/app/models/close-reason.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { PaginationModel } from 'src/app/models/pagination';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-assign-checklist',
  templateUrl: './assign-checklist.component.html',
  styleUrls: ['./assign-checklist.component.scss']
})
export class AssignChecklistComponent implements OnInit {
  @Input() options;
  public reason = Reason;
  public user: any;
  public assignedChecklistFromTable: any;
  public allowAssign: boolean = false;
  public params: {};
  public loading: boolean;
  public backgroundLoading: boolean;
  public columns: any[];
  public settingTable: any;
  public data: any;
  private pagination: PaginationModel;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private globalService: GlobalService
  ) {
    this.user = authService.getLoggedInUser();
    this.backgroundLoading = false;
    this.allowAssign = this.allowAssignChecklist();
  }

  ngOnInit() {
    this.params = {
      validationid: this.options.validationid,
      validationname: this.options.validationname,
      checklists: this.options.checklists
    };
    this.pagination =
      this.paginationService.getDefaultPagination('assignchecklist');
    this.getChecklistData();
    this.getSettingTable();
    this.getColumns();
  }

  public onAssignChecklist() {
    const params = {
      assignedchecklist: this.assignedChecklistFromTable
    };
    this.activeModal.close(params);
  }

  public onUnassignChecklist() {
    const params = {
      unassignedchecklist: this.options.assigned
    };
    this.activeModal.close(params);
  }

  public assignFromTable(checklist) {
    this.assignedChecklistFromTable = checklist;
  }

  public allowAssignChecklist() {
    return this.user && this.user.isadmin;
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'checklist.name',
        title: 'checklist.name',
        name: 'name',
        clickLink: this.assignFromTable.bind(this),
        type: 'link'
      }
    ];
  }

  public getChecklistData() {
    this.data = {
      total_elements: this.options.checklists.length,
      page: 1,
      page_size: 10,
      num_pages: 1,
      order_by: 'validationid',
      desc: 0,
      data: this.options.checklists
    };

    let data = JSON.parse(JSON.stringify(this.options.checklists));
    let originalData = JSON.parse(JSON.stringify(data));
    const dataToShowIni =
      this.pagination.page * this.pagination.page_size -
      this.pagination.page_size;
    const dataToShowFin = this.pagination.page * this.pagination.page_size;
    data = data.slice(dataToShowIni, dataToShowFin);
    this.data['total_elements'] = originalData.length;
    this.data['page'] = this.pagination.page;
    this.data['page_size'] = this.pagination.page_size;
    if (originalData.length <= data.length) {
      this.data['num_pages'] = Math.floor(
        originalData.length / this.pagination.page_size
      );
      if (originalData.length % this.pagination.page_size > 0) {
        this.data['num_pages'] = this.data['num_pages'] + 1;
      }
    } else {
      this.data['num_pages'] = 1;
    }
    this.data['order_by'] = this.pagination.order_by;
    this.data['desc'] = this.pagination.desc;
    this.data['data'] = data;
    this.paginationService.setPage('assignchecklist', data);
    this.pagination = this.paginationService.setPagination('assignchecklist', {
      total_elements: data.length,
      page: this.data['page'],
      page_size: this.data['page_size'],
      num_pages: this.data['num_pages'],
      order_by: this.data['order_by'],
      desc: this.data['desc'],
      query: this.pagination.query
    });
    this.pagination = this.paginationService.getPagination('assignchecklist');
  }
  /**
   * Return loaded SettingTable with the general settings for the
   * component responsive table
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'validationid',
      getDataFromDB: this.getChecklistData.bind(this),

      responsiveTitle: {},
      paginationClass: 'relative-pag'
    };
  }

  public onLoadingChange(bool) {
    this.loading = bool;
  }
}
