import { Component, OnInit, Input } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ITreeOptions,
  IActionMapping,
  TREE_ACTIONS,
  TreeNode
} from '@circlon/angular-tree-component';
import { ADMINISTRATOR, PROJECT_MANAGER, QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER, 
  TEAM_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN, EXPLORER_MEMBER} from '../../constants/userRoles';
import { usecases } from './utils/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { sortResultsBasedOnScore, getUsecaseLevelsMetaData } from './utils/utils';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { LinkService } from '../../services/link.service';
import { restructureQueryTreeNodes } from '../querysheet-details/queryTreeUtils';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Subscription } from 'rxjs';
import { DeleteModalComponent } from '../../components/modals/delete/delete.component';
import { NoOperationAllowedModalComponent } from '../../components/modals/no-operation-allowed/no-operation-allowed.component';

@Component({
  selector: 'app-exploration-details',
  templateUrl: './exploration-details.component.html',
  styleUrls: ['./exploration-details.component.scss']
})

export class ExplorationDetailsComponent implements OnInit {

  public explorationQueryData: any = {};
  public results = [];
  private dataSub: Subscription;
  public querysheetDetails: any = {};
  public isLoading: Boolean = false;
  public isExplorationDeleting: Boolean = false;
  public isReportDownloding: Boolean = false;
  public resultsLoading: Boolean = false;
  public selectedQuery = {};
  public nodes = [];
  public user: any;
  public showFilePreview: boolean = false;
  public selectedResult: any = null;
  public querySheetId = null;
  public explorationId = null;
  public explorationDetails: any = {};
  public usecaseLevels: any = {};
  public outdatedExplorationMsgs: any = { header: null, message: null, generateRsultsbutton: null, deleteButton: null };
  public disableGenerateBtn: boolean = false;
  actionMapping: IActionMapping = {
    mouse: {
      expanderClick: (tree, node: TreeNode, $event) => {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      },
      click: (tree, node, $event) => {
        // For leaf nodes i.e. queries, show the details
        if (!node.hasChildren && !this.outdatedExplorationMsgs?.header) {
          this.selectedQuery = node.data;
          //TO DO: change to actual usecase in future, kept semantci with qna for now
          // Hierarchy of the usecase models, cause it changes based on query as well
          this.usecaseLevels = getUsecaseLevelsMetaData(usecases.SEMANTIC_WITH_QNA, this.selectedQuery);
          this.fetchResultsForSelectedQuery();
        }
      }
    }
  };

  options: ITreeOptions = {
    allowDrag: false,
    actionMapping: this.actionMapping
  };

  constructor(
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe,
    private linkService: LinkService,
    private modalService: NgbModal,
    public router: Router,
    public route: ActivatedRoute,
	public fileDownloadService: FileDownloadService
  ) { 
    this.user = this.authService.getLoggedInUser();
  }

  ngOnInit(): void {
    // Hierarchy of the usecase models
    this.usecaseLevels = getUsecaseLevelsMetaData(usecases.SEMANTIC_WITH_QNA);
    this.explorationDetails = this.globalService.getExploration();
    this.dataSub = this.route.queryParamMap.subscribe((params: Params) => {
      /* Scenario: exploration Page refresh,
                  taking id from route and fetching all the details */
      this.querySheetId = params.get('querysheetId');
      this.explorationId = params.get('explorationId');
    });
    this.getExplorationDetail();
  }

  public fetchQueryTreeDetails() {
    this.isLoading = true;
    this.globalService
      .fetchQuerySheetDetails(this.querySheetId)
      .then(response => {
        this.nodes = restructureQueryTreeNodes(response.querytree);
        this.querysheetDetails = response
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
      });
  }

  public fetchResultsForSelectedQuery() {
    this.resultsLoading = true;
    const explorationId = this.explorationDetails?.explorationId || this.explorationId;
    const queryIds = [this.selectedQuery['id']];
    this.selectedQuery['qna'] && queryIds.push(this.selectedQuery['qna']['id'])

    this.apiService.get(`explorationResults/${explorationId}/`, { queryIds }, '', true, {}, "", false)
      .subscribe(
        event => {
          this.results = event.results.map((res: any) => {
            // Replacing the name of chunk to level 1 model
            res[this.usecaseLevels.level1_1.modelKey] = { ...res.chunk };
            delete res.chunk;
            let score = res[this.usecaseLevels.level1_1.modelKey].semanticScore;
            if ( score > 1) {
              res[this.usecaseLevels.level1_1.modelKey].semanticScore = 100;
            } else {
              res[this.usecaseLevels.level1_1.modelKey].semanticScore = Math.round(score*100)
            }
            return res;
          });
          delete event.results;
          this.explorationQueryData = event;
          this.fetchFeedbackDataForSelectedQuery();
      },
      error => {
        console.log('error', error);
        this.results = new Array();
        if(error?.error?.error_code=="E1000"){
          this.alertService.info(this.translate.transform(
            'querysheetManagement.noResultsForQuery')
            );
        } else if (error.status === 405 && error?.error === 'exploration is modified') {
          const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'sm'
          };
          const options = {
            message: this.translate.transform('components.modal.noOperationAllowed.message'),
            title: this.translate.transform('components.modal.noOperationAllowed.title'),
            primaryButtonText: this.translate.transform('components.modal.noOperationAllowed.goBack')
          };
          const modalWindowRef = this.modalService.open(
            NoOperationAllowedModalComponent,
            modalOptions
          );
          modalWindowRef.componentInstance.options = options;
        } else{
          this.alertService.error(this.translate.transform(
            'querysheetManagement.errorWhileFetchingResults')
            );
        }
        this.resultsLoading = false;
      });
  }

  public fetchFeedbackDataForSelectedQuery() {
    const explorationId = this.explorationQueryData.explorationId;
    const params = { queryIds: this.explorationQueryData.queries.map(query => query.queryId) };
    this.resultsLoading = true;
    this.apiService.get(`feedback/${explorationId}/`, params, '')
    .subscribe(
      event => {
        this.results.forEach(result => {
          // In case of Semantic with QnA, level1_1 will be Semantic
          const level1_1 = this.usecaseLevels.level1_1.modelKey;
          if (event[level1_1]) {
            const presentFeedback = event[level1_1].find((fb: any) => fb.chunkId == result.chunkId && fb.semanticResultId == result.semanticResultId);
            if (presentFeedback && presentFeedback.selections.length >= 0) {
              result.feedbackId = event._id;
              result[level1_1].feedback = presentFeedback;
            }
          }

          // Checking if there is any level 2, level2_1 here is QnA in case of Semantic with QnA
          const level2_1 = this.usecaseLevels.level1_1?.level2_1?.modelKey;
          if (event[level2_1]) {
            const presentFeedback = event[level2_1].find((fb: any) => fb.chunkId == result.chunkId);
            if (presentFeedback && presentFeedback.selections.length >= 0) {
              !result.feedbackId && (result.feedbackId = event._id);
              result[level2_1].feedback = presentFeedback;
            }
          }
        });
        // Handling results sorting, from the given feedbacks which are coming sorted from BE
        sortResultsBasedOnScore(this.results, this.usecaseLevels.level1_1.modelKey, this.usecaseLevels.level1_1.resultScoreKey);
        // Stopping the loader once feedback data retrieves
        this.resultsLoading = false;
      },
      error => {
        console.log('error', error);
        this.resultsLoading = false;
      }
    )
  }
  getModalOption(projectList) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      message: `${this.translate.transform('components.modal.projectAccessRequired.message')} ${projectList.join(', ')}`,
      title: this.translate.transform('components.modal.projectAccessRequired.title'),
      primaryButtonText: this.translate.transform('components.modal.noOperationAllowed.goBack')
    };
    return {
      modalOptions,
      options
    }
  }

  public getExplorationDetail(): void {
    this.isLoading = true;
    this.globalService
      .fetchExplorationDetails(this.explorationId)
      .then(response => {
        this.isLoading = false;
        this.explorationDetails = this.globalService.getExploration();

        // In case of outdated exploration or failed/in-progress documents, set display header, message and buttons
        this.getMessagingDetails();
        if (!this.explorationDetails.querySheet.isActive) {
          this.querysheetDetails = this.explorationDetails.querySheet;
        } else {
          // Fetch query tree details only if exploration is not outdated and querysheet not deleted
          this.fetchQueryTreeDetails();
        }
      })
      .catch(error => {
        this.isLoading = false;
        if (error.status === 405 && error.error?.message == "User doesn't have access to the following projects: ") {
          const projects = []
          error.error?.project_list.forEach(project => {
            projects.push(project.projectname)
          })
          const { modalOptions, options } = this.getModalOption(projects)
          const modalWindowRef = this.modalService.open(
            NoOperationAllowedModalComponent,
            modalOptions
          );
          modalWindowRef.componentInstance.options = options;
        }
      });
  }

  public setShowFilePreviewFlag(flagVal: boolean = null) {
    this.showFilePreview = flagVal != null ? flagVal : !this.showFilePreview;
    if (!this.showFilePreview) {
      this.setSelectedResult(null)
    }
  }

  public setSelectedResult(result: any = null) {
    this.selectedResult = result;
  }

  public handleRetriggerClick() {
    const explorationId = this.explorationDetails?.explorationId || this.explorationId;
    const url = `explorationResults/${explorationId}/retrigger`;
    this.disableGenerateBtn = true;
    this.isLoading = true;
    this.apiService.put(url, '', {}, '').subscribe(
      (data) => {
        // Exploration has been retriggered successfully
        if (data === 'No changes found in exploration/results' 
            || data === 'Exploration not found'
            || data === 'Can not generate results because Query Sheet is deleted.'
            || data === 'Can not generate results because Query Sheet is not published.'
            || data === 'Can not generate results because Query Sheet is not processed successfully.'
            || data === 'Your documents are still in process.'
            || data === 'No documents are mapped to the exploration') {
              this.alertService.error(data);
              this.isLoading = false;
              this.disableGenerateBtn = false;
        } else if(data === 'Exploration results are already up to date') {
          this.isLoading = false;
          this.alertService.success(
            this.translate.transform(
              'querysheetManagement.explorationUptoDateViewResults'
            )
          );
          this.getExplorationDetail();
        } else {
          this.linkService.goToQuerySheetsManagement('explorationList');
          this.isLoading = false;
          setTimeout(() => {
            this.alertService.success(
              this.translate.transform(
                'querysheetManagement.explorationRetriggeredSuccessfully'
              )
            );
          }, 500)
        }
      }
    );
  }

  public handleDeleteClick() {
    const url = 'explorations/delete';
    const params = { 'ids': [this.explorationDetails?.explorationId || this.explorationId] };

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'exploration',
      message: this.translate.transform('querysheetManagement.explorationDeleteConfirmation')
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.isLoading = true;
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              // Exploration has been deleted successfully
              this.isLoading = false;
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.explorationDeletedSuccessfully'
                )
              );
              this.linkService.goToQuerySheetsManagement('explorationList');
            }
          );
        }
      }
    );
  }

  public getMessagingDetails() {
    let failedDocsCount = 0;
    let inProgressPendingDocsCount = 0;
    // Reset the object before every check
    this.outdatedExplorationMsgs = { header: null, message: null, generateRsultsbutton: null, deleteButton: null };
    this.explorationDetails.document_list.forEach((doc: any) => {
      // Status id 4 is for failure status
      if (["failure"].includes(doc.documentStatusName)) {
        failedDocsCount++;
      } else if (["in-progress", "pending"].includes(doc.documentStatusName)) {
        // Updated to include messaging for pending documents as well
        inProgressPendingDocsCount++;
      }
    });

    if (!this.explorationDetails.querySheet?.isActive) {
      // If the mapped querysheet is deleted
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.mappedQSDeletedHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.mappedQSDeletedMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = true;
    } else if (failedDocsCount) {
      // If there are failed documents in the exploration
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.errorInMappedDocsHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.errorInMappedDocsMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (inProgressPendingDocsCount) {
      // If there are in-progress documents in the exploration
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.inProgressMappedDocsHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.inProgressMappedDocsMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (this.explorationDetails.document_list?.length == 0) {
      // If the all the mapped documents in the exploration are deleted
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.allMappedDocumentsGetDeletedHeader');
      this.outdatedExplorationMsgs.message = `<span>${this.translate.transform('explorationDetails.allMappedDocumentsGetDeletedMessage1')}
                                              <span class="editWord" style="color: white;">${this.translate.transform('explorationDetails.allMappedDocumentsGetDeletedMessage2')}</span>
                                              ${this.translate.transform('explorationDetails.allMappedDocumentsGetDeletedMessage3')}</span>`;
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (["in-progress"].includes(this.explorationDetails?.statusdetails?.statusname)) {
      // If the generation of the results are not yet ready, its in-progress
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.resultGenerationInProgressHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.resultGenerationInProgressMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (['processing', 'pending'].includes(this.explorationDetails.querySheet?.querySheetStatus)) {
      // If the querysheet embedding are not yet ready, its in-progress/pending
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.querySheetEmbeddingNotReadyHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.querySheetEmbeddingNotReadyMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (['failed'].includes(this.explorationDetails.querySheet?.querySheetStatus)) {
      // If the querysheet embedding are is failed
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.mappedQSFailedHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.mappedQSFailedMessage');
      this.outdatedExplorationMsgs.generateRsultsbutton = null;
      this.outdatedExplorationMsgs.deleteButton = null;
    } else if (this.explorationDetails.isOutdated) {
      // If the exploration is outdated, due docs getting deleted, added, unmapped or querysheet updated
      this.outdatedExplorationMsgs.header = this.translate.transform('explorationDetails.outdatedExplorationHeader');
      this.outdatedExplorationMsgs.message = this.translate.transform('explorationDetails.outdatedExplorationMsg');
      this.outdatedExplorationMsgs.generateRsultsbutton = true;
      this.outdatedExplorationMsgs.deleteButton = null;
    }
  }

  public deleteQuerySheet() {
    const url = 'querysheets/delete';
    const params = { 'ids': [this.querySheetId]};

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'querySheet',
      customMessage: "querysheetManagement.querySheetDeleteConfirmation",
      notAllowed: !this.querySheetId
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.isLoading = true;
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              this.isLoading = false;
              // Redirecting to query sheets management/list page
              this.linkService.goToQuerySheetsManagement();

              // Querysheet/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.querysheetDeletedSuccessfully'
                )
              );
            }
          );
        }
      }
    );
  }
  
  // synchronous call to show loader when document takes more time.
  public downloadAssessorReport() {
    this.isReportDownloding = true;
    this.downloadAssessorCallback();
    this.isReportDownloding = false;
  }

  public downloadAssessorCallback() {
    const explorationId = this.explorationDetails?.explorationId || this.explorationId;
    const assessorReportName = `${this.explorationDetails?.querySheet?.querySheetName}_assessor_report`
    const file = {
      mediatype:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    this.fileDownloadService.downloadExternalFile(`assessorreport/${explorationId}/`, file, assessorReportName);
  }
  
}
