import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { Subscription, concat } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { LinkService } from 'src/app/services/link.service';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { DocumentValidationService } from 'src/app/pages/document-details/document-validation/document-validation.service';
import { DocumentChecklistTableComponent } from '../document-checklist-table.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DocumentChecklistService } from '../document-checklist.service';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentChecklistSelection } from '../document-checklist-selection';

@Component({
  selector: 'app-document-checklist-table-item',
  templateUrl: './document-checklist-table-item.component.html',
  styleUrls: ['./document-checklist-table-item.component.scss']
})
export class DocumentChecklistTableItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() item: any = {};
  @Input() elements: any = {};
  @Input() validationtypename: any;

  @Input() index: number;
  @Input() documentDetails: any;
  @Input() selection: DocumentChecklistSelection;
  @Input() lineMarked: boolean;
  @Input() conditionMarked: boolean;

  @Input() isFirstRow: boolean;
  @Input() disableInput: boolean = false;

  @Output() discountFailure = new EventEmitter();
  @Output() updateTimeline = new EventEmitter();
  @Output() setTestsUpdate = new EventEmitter();

  @Output() excludePreliminary = new EventEmitter();
  @Output() rerunPreliminary = new EventEmitter();
  @Output() lineMarkedChange = new EventEmitter();
  @Output() conditionMarkedChange = new EventEmitter();

  public selectedStatus: string;
  public newCommentForm: FormGroup;
  public _documentStatus: any;
  public isCollapsed: boolean = true;
  public isOpen: boolean = false;
  public loadingCollapsible: boolean = false;
  public validationsStatus: ValidationStatusModel[];
  public isRerunDisabled: boolean = false;
  public isRerunDisabledTrigger: boolean;
  public isExcludeDisabled: boolean = false;
  public _validationTypes: any;
  public _validationStatus: any;
  public _userPermissions: any;
  public subscriptionUtils: Subscription;
  public markedChunks: Array<any> = [];
  public markedChunksSub: Subscription;
  public datamodels: any = [];
  public approval: any = {};
  public user: any = {};
  public isExternal: boolean = false;
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  public loadingComment: boolean = true;
  public editComment: boolean = false;

  constructor(
    private global: GlobalService,
    private apiService: ApiService,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private documentRenderService: DocumentRenderService,
    private listComponent: DocumentChecklistTableComponent,
    private router: Router,
    private link: LinkService,
    private validationService: DocumentValidationService,
    private documentValidation: DocumentValidationService,
    private documentChecklist: DocumentChecklistService
  ) {
    this._documentStatus = this.global.getDocumentStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();
    this._validationStatus = this.global.getValidationStatusConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    this.newCommentForm = new FormGroup({
      textComment: new FormControl(
        this.item.comment ? this.item.comment : this.item.errorcomment,
        [Validators.required]
      ),
      amount: new FormControl(this.item.amount ? this.item.amount : 0.0, [
        Validators.required
      ])
    });
    this.loadingComment = false;
    this.validationsStatus = this.global.getValidationStatus();
    this.datamodels = Array.from(this.global.getDatamodels().values());

    /**
     * Initializing dropdowns
     */
    if (this.validationtypename === this._validationTypes.CATALOG) {
      if (!this.item.checklistexecutionid) {
        this.item['collapsedchecklist'] = true;
      }
    }
    if (this.item['collapsedchecklist'] == undefined) {
      this.item['collapsedchecklist'] = true;
    }

    if (
      this.validationsStatus.length === 0 &&
      this.datamodels.length === 0 &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = concat(
        this.global.watchUtils(),
        this.global.watchDataModels()
      ).subscribe((data: string) => {
        this.validationsStatus = this.global.getValidationStatus();
        this.datamodels = Array.from(this.global.getDatamodels().values());
      });
    } else if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsStatus = this.global.getValidationStatus();
        });
    } else if (
      this.datamodels.length === 0 &&
      !this.global.passedWatcherDatamodels
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodels = Array.from(this.global.getDatamodels().values());
        });
    }
    if (
      this.isFirstRow &&
      (this.item.validationtypename === this._validationTypes.PRELIMINARY ||
        this.item.validationtypename === this._validationTypes.CATALOG)
    ) {
      this.toggleCollapse();
    }
    this.getReviewersTypes();
    this.markedChunksSub = this.documentRenderService
      .getMarkedChunks()
      .subscribe(({ chunks }) => {
        this.markedChunks = chunks;
      });

    this.updateSelectedStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection) {
      this.updateSelectedStatus();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
    if (this.markedChunksSub) {
      this.markedChunksSub.unsubscribe();
    }
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  /**
   * Store locally selected item status from selection.
   */
  private updateSelectedStatus(): void {
    this.selectedStatus = this.selection?.getItemStatus(
      this.item.checklistitemexecutionId
    );
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.global.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
        this.reviewersGroupsTypes = this.global.getReviewers();
        this.setApproval();
      });
    } else {
      this.setApproval();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * TODO add documentation
   */
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.item['collapsedchecklist'] = !this.item['collapsedchecklist'];
    if (this.item.children && this.item['collapsedchecklist'] == true) {
      this.item.children.forEach(element => {
        element['collapsedchecklist'] = true;
        element.checklistsexecution.forEach(checklist => {
          checklist['collapsedchecklist'] = true;
        });
      });
    }

    if (this.validationtypename === this._validationTypes.CATALOG) {
      this.documentChecklist.setPreviousSearchCatalog(this.elements);
    }
  }

  /**
   * TODO add documentation
   */
  public toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public setTestsUpdatedChecklist() {
    this.setTestsUpdate.emit();
  }

  /**
   * Item status change
   */
  public onChangeStatus(item: any, status: string): void {
    this.selectedStatus = this.selection.toggleItemStatus(
      item.checklistitemexecutionId,
      status
    );
  }

  /**
   * onClick event function: when clicks the top
   * of a level in collapsible list
   */
  public itemClick(_item: any) {
    this.toggleCollapse();
    this.toggleOpen();
  }

  /**
   * Send a signal to the father to
   * discount the number of failures
   */
  public onOverride() {
    this.item.message
      ? this.discountFailure.emit()
      : this.item.failureConditions--;
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }

  /**
   * Returns the name to show on the top of a level
   * If it has message item is the top of a condition's details list
   * If it has children, item is the top of executions list
   * Otherwise item is the top of conditions list
   * @param item
   */
  public getItemName(item: any): string {
    let name: string;

    if (item.children) {
      name =
        (item.validationtypename || item.label) + ' ' + item.validationname;
    } else if (item.checklistname) {
      name = item.checklistname;
    } else if (item.groupname) {
      name = item.groupname;
    } else if (item.itemname) {
      name = item.itemname;
    }
    return name;
  }

  /**
   * Returns de Status Name
   * @param item
   */
  public getItemStatus(item: any): string {
    if (item.validationstatusid) {
      return item.validationstatusid.validationstatusdisplayname;
    } else {
      return item.statusChecklistName;
    }
  }

  /**
   * Returns de status icon
   */
  public getIcon(status: string): string {
    if (status === undefined) {
      return '';
    }
    const replacedStatus = status.replace(/\s+/g, '-');
    if (
      [
        this._validationStatus.MANUAL_SUCCESS,
        this._validationStatus.SUCCESS
      ].includes(replacedStatus)
    ) {
      return 'check';
    } else if (
      [
        this._validationStatus.FAILURE_NA,
        this._validationStatus.FAILURE
      ].includes(replacedStatus)
    ) {
      return 'clear';
    } else if (replacedStatus === this._documentStatus.WARNING) {
      return 'warning';
    } else if (replacedStatus === this._validationStatus.SKIPPED) {
      return 'fiber_manual_record';
    } else if (replacedStatus === this._validationStatus.IN_PROGRESS) {
      return 'sync';
    }
  }

  /**
   * Returns the status color.
   */
  public getColorStatus(item: any): string {
    if (item.validationstatusid) {
      return item.validationstatusid.validationstatusname;
    } else {
      return item.statusChecklist;
    }
  }

  /**
   * Fuction for create a comment of each checklist item execution.
   */
  public onCreateComment(form) {
    this.editComment = false;
    this.loadingComment = true;
    const params = {
      amount: form.value.amount,
      comment: form.value.textComment,
      checklistitemexecutionid: this.item.checklistitemexecutionId
    };
    this.apiService
      .put(
        `checklistsitemexecution/${this.item.checklistitemexecutionId}/comment`,
        '',
        JSON.stringify(params),
        'checklist.commentItem'
      )
      .subscribe(
        data => {
          this.setTestsUpdatedChecklist();
          this.editComment = false;
          this.loadingComment = false;
        },
        () => {
          this.editComment = false;
          this.loadingComment = false;
        }
      );
  }

  public onBlur(evt, form) {
    if (evt.target.valueAsNumber) {
      form.amount = evt.target.valueAsNumber.toFixed(2);
    }
  }

  public onEditComment() {
    this.editComment = !this.editComment;
  }
}
