import { StyleSettings } from "./style-settings";

export function getLightThemeStyleSettings(): StyleSettings {
  return {
    charts: {
      defaultColor: '#75a6ff',
      defaultColorScheme: ['#75a6ff'],

      status: {
        colorScheme: ['#e0effc', '#d0e0ff', '#a3c4e1', '#8dcaff', '#75acdb'],
        colorKeys: {
          pending: '#f1f09e',
          failure: '#fb9c9c',
          success: '#90ebaa',
          inProgress: '#84dee5',
          warning: '#f3bc93',
          partialSuccess: '#d4d590'
        }
      },

      time: {
        colorScheme: ['#34d3e8']
      },

      projectColorScheme: ['#6ca1b1'],
      datamodelColorScheme: ['#8d92be'],
      weekColorScheme: ['#75a6ff'],
      hourColorScheme: ['#75a6ff'],
      processColorScheme: ['#75a6ff'],
      flowColorScheme: ['#75a6ff'],

      medicDashboard: {
        weightColorScheme: ['#34d3e8'],
        heightColorScheme: ['#34d3e8'],
        bmiColorScheme: ['#34d3e8'],
        hemoglobinColorScheme: ['#34d3e8'],
        bloodPressureColorScheme: ['#34d3e8', '#ff9966'],
        cholesterolColorScheme: ['#34d3e8']
      }
    }
  };
}
