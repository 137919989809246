import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-document-items',
  templateUrl: './document-items.component.html',
  styleUrls: ['./document-items.component.scss']
})
export class DocumentItemsComponent implements OnInit, AfterViewInit {
  @Input('currentArrayList') currentArrayList;
  @Input('currentAnalysisObj') currentAnalysisObj;
  @Input('allDocItems') allDocItems;
  @Output() childEvent = new EventEmitter();
  public countNumber: number = 0;

  @ViewChild('main') refMain: ElementRef<HTMLInputElement>;
  @ViewChild('paras') refPara: ElementRef<HTMLInputElement>;
  @ViewChild('buttonCont') refButtonCont: ElementRef<HTMLInputElement>;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getDocumentItem(this.currentArrayList);
  }

  /* getting the all document item from parent and mapping in child component with 
  dynamic width based on content of each element of arry and showing the count.
  */
  getDocumentItem(list) {
    var ix, ixLen;
    var span = this.renderer.createElement('span');
    var btn = this.renderer.createElement('button');
    span.textContent = ' + ';

    for (ix = 0, ixLen = list.length;ix < ixLen;ix++) {
      if (ix !== ixLen - 1) {
        this.refPara.nativeElement.textContent += list[ix] + ', ';
      } else {
        this.refPara.nativeElement.textContent += list[ix];
      }

      if (this.refPara.nativeElement.scrollWidth > this.refMain.nativeElement.scrollWidth - 100) {
        this.refPara.nativeElement.setAttribute('style', 'overflow:hidden; text-overflow: ellipsis');
        this.refPara.nativeElement.style.width = (this.refMain.nativeElement.clientWidth - 64) + 'px';
        this.countNumber = list.length - (ix + 1);

        if (this.countNumber !== 0) {
          span.textContent += this.countNumber
          this.refMain.nativeElement.append(span);
        }

        // set more button with properties on each item of document
        btn.textContent = 'More';
        btn.type = 'button';
        btn.setAttribute('class', 'plus-count-text')
        btn.addEventListener('click', (e) => {
          this.showMoreAndLess(this.currentAnalysisObj.analysisObj.analysisid);
        });
        this.refButtonCont.nativeElement.append(btn)
        return;
      }
    }
  }

  //toggle the read more/less document test on click of button
  showMoreAndLess(docIndex) {
    this.childEvent.emit(docIndex);
  }
  
}

