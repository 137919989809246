import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PrivacyAcceptedGuard implements CanActivateChild {

constructor(
  private authService: AuthenticationService,
  private router: Router
){}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
    const user = this.authService.getLoggedInUser();

    if (user && !user.privacyNoteAccepted){
      // Check URL
      const path = childRoute.data.path;
      if (path == 'privacy-note' || path == 'clientManagement') {
        // Allow acces to accept privacy note or change client
        return true;
      } else {
        let tenantName = childRoute.params.tenantname;
        if (tenantName) {
          this.router.navigate([`${tenantName}/privacy-note`]);
        } else {
          this.router.navigate(['/']);
        }
        return false;
      }
    }
    return true;
  }
}
