import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class CategoryFilterComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup;

  // Datamodels variables
  public subscriptionDataModels: Subscription;
  public loadingDatamodels: boolean;
  public fieldOfInterestIds: any[];

  // Global variables
  public subscriptionGlobal: Subscription;
  public loadingGlobal: boolean;
  public statusList: any[];
  public languages: any[];
  public datamodels: any[];

  constructor(private global: GlobalService) {}

  ngOnInit(): void {
    this.getDatamodels();
    this.getCategories();
    this.form.markAllAsTouched();
  }

  private getDatamodels() {
    const datamodelMap = this.global.getDataModelMap();
    if (datamodelMap.size === 0 && !this.global.passedWatcherDatamodels) {
      this.loadingDatamodels = true;
      this.subscriptionDataModels = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodels = Array.from(datamodelMap.values());
          this.loadingDatamodels = false;
        });
    } else {
      this.datamodels = Array.from(datamodelMap.values());
    }
  }

  private getCategories() {
    this.languages = Array.from(this.global.getLanguages().values());
    this.statusList = Array.from(this.global.getGlobalStatus().values());

    if (
      this.languages.length === 0 &&
      this.statusList.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.loadingGlobal = true;
      this.subscriptionGlobal = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.statusList = Array.from(this.global.getGlobalStatus().values());
          this.languages = Array.from(this.global.getLanguages().values());
          this.loadingGlobal = false;
        });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionDataModels) {
      this.subscriptionDataModels.unsubscribe();
    }

    if (this.subscriptionGlobal) {
      this.subscriptionGlobal.unsubscribe();
    }
  }
}
