import { Observable, Subject } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { Thumbnail } from "./document-thumbnails.component";

/**
 * Object that loads document thumbnails.
 */
export class DocumentThumbnailsProvider {

  /**
   * Id of document for which this provider is loading thumbnails.
   */
  private _documentId: number;
  public get documentId(): number {
    return this._documentId;
  }

  private numThumbnails: number;

  private thumbnails: Thumbnail[];
  private thumbnailsSub = new Subject<any>();

  private cancelledLoading: boolean;

  public constructor(
    documentId: number,
    numPages: number,
    private apiService: ApiService,
    private pageNumbers?: number[]) {

    this._documentId = documentId;
    this.numThumbnails = numPages;
    // this.numThumbnails = 15;
  }

  /**
   * Get observable of document thumbnails.
   */
  public getThumbnails(): Observable<any> {
    return this.thumbnailsSub.asObservable();
  }

  /**
   * Trigger thumbnails subscription.
   */
  public triggerThumbnails() {
    this.thumbnailsSub.next({ thumbnails: this.thumbnails })
  }

  /**
   * Load thumbnails from database.
   */
  public async loadThumbnails(): Promise<void> {
    this.thumbnails = [];
    for (let i = 0; i < this.numThumbnails; ++i) {
      const thumbnail = new Thumbnail();
      thumbnail.isLoading = false;
      thumbnail.triedToLoad = false;
      this.thumbnails.push(thumbnail);
    }
    this.triggerThumbnails();

    for (let i = 0; i < this.numThumbnails; ++i) {
      if (this.cancelledLoading) {
        this.stopLoading();
        return;
      }

      const thumbnail = this.thumbnails[i];

      thumbnail.isLoading = true;
      this.triggerThumbnails();
      try {
        const pageNumber =
          this.pageNumbers ?
            this.pageNumbers[i]
            : i + 1;

        const blobData = await this.apiService
          .get(
            `docpictures/thumbnails/`,
            { documentid: this.documentId, page: pageNumber },
            '',
            false,
            {
              responseType: 'blob'
            },
            '',
            false
          ).toPromise();
        this.setThumbnailImageFromBlob(thumbnail, blobData);

      } catch (error) {
        this.setThumbnailSrc(thumbnail, null, error);
      }
    }
  }

  /**
   * Set thumbnail image from blob.
   * @param thumbnail thumbnail object.
   * @param blobData image blob data.
   */
  private setThumbnailImageFromBlob(thumbnail: Thumbnail, blobData: any) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.setThumbnailSrc(thumbnail, reader.result);
      },
      false
    );
    reader.readAsDataURL(blobData);
  }

  /**
   * Set src property of thumbnail object.
   */
  private setThumbnailSrc(thumbnail: Thumbnail, src: any, error: boolean = null) {
    thumbnail.src = src;
    thumbnail.error = error;
    thumbnail.isLoading = false;
    thumbnail.triedToLoad = true;
    this.triggerThumbnails();
  }

  /**
   * Cancel loading thumbnails.
   */
  public cancelLoading(): void {
    this.cancelledLoading = true;
  }

  /**
   * Stop loading thumbnails.
   */
  private stopLoading(): void {
    for (let i = 0; i < this.numThumbnails; ++i) {
      const thumbnail = this.thumbnails[i];

      thumbnail.isLoading = false;
      thumbnail.triedToLoad = true;
    }

    this.triggerThumbnails();
  }

}
