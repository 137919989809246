import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import * as pbi from 'powerbi-client';
import { ViewChild, ElementRef } from '@angular/core';
import { PBIService } from '../../../services/pbi.service';
import { Subscription, Observable } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { StyleManagerService } from 'src/app/services/stylemanager.service';

@Component({
  selector: 'app-powerbi-charts',
  templateUrl: './powerbi-charts.component.html',
  styleUrls: ['./powerbi-charts.component.scss']
})
export class PowerbiChartsComponent implements OnInit, OnDestroy {
  public subscriptionPBI: Subscription;
  public user: any;
  public dateLoading: boolean;

  @Input() dashboard: any;
  tokenPBI: any;
  env: any;
  loadPbi: boolean;

  @ViewChild('reportContainer') reportContainer: ElementRef;

  constructor(
    authService: AuthenticationService,
    private pbiService: PBIService,
    private styleManager: StyleManagerService
  ) {
    this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    if(window['activate_pbi'] === true){
      this.loadPbi = true;
      this.pbiService.clearPBIToken();
      this._generatePBILoginConnection();
      this.dateLoading = true;
    }else{
      this.loadPbi = false;
    }
  }

  /**
   * Handle the Microsoft and PBI connections to generate a token to render the PBI dashboard
   */
  private _generatePBILoginConnection() {
    this.tokenPBI = this.pbiService.getPBIToken();
    if (!this.tokenPBI) {
      this.pbiService.getPBIConnection(
        this.dashboard.groupid,
        this.dashboard.reportid,
        this.dashboard.datasetid,
        this.user
      );
      this.subscriptionPBI = this.pbiService
        .watchPBIAccess()
        .subscribe((data: string) => {
          this.tokenPBI = this.pbiService.getPBIToken();
          this.showReport(this.tokenPBI.token);
        });
    } else {
      this.showReport(this.tokenPBI);
    }
  }

  /**
   * Get dashboard color palette.
   */
  private getColorPalette() {
    const p = this.styleManager.getDashboardColorPalette(this.dashboard);
    console.log('zibo', p);
    return p;
  }

  /**
   * Build the configuration to render the PBI dashboard after that
   */
  showReport(accessToken) {
    // Embed URL
    const embedUrl =
      'https://app.powerbi.com/reportEmbed?reportId=' +
      this.dashboard.reportid +
      '&groupId=' +
      this.dashboard.groupid +
      '&filterPaneEnabled=false&navContentPaneEnabled=false';
    const models = pbi.models;
    let config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: this.dashboard.reportid,
      theme: {
        themeJson: this.getColorPalette()
      },
      settings: {
        filterPaneEnabled: false
      }
    };

    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    this.dateLoading = false;
    const reportContainer = <HTMLElement>(
      document.getElementById('reportContainer')
    );
    // @ts-ignore
    let report = <pbi.Report>powerbi.embed(reportContainer, config);
    report.applyTheme({ themeJson: this.getColorPalette() });
    report.on('error', function () {
      this.alertService.error(
        this.translate.transform('powerbi.reportEmbed'),
        true,
        ''
      );
      report.off('error');
    });
  }

  ngOnDestroy() {
    if (this.subscriptionPBI) {
      this.subscriptionPBI.unsubscribe();
    }
  }
}
