import { Component, OnInit } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateQuerysheetComponent } from '../../components/modals/create-querysheet/create-querysheet.component';
import { PaginationModel } from '../../models/pagination';
import { PaginationService } from '../../services/pagination.service';
import { ApiService } from '../../services/api.service';
import { QuerySheetModel } from '../../models/querysheet-mstr.model';
import { ExplorationModel } from '../../models/exploration-mstr.model'
import { TranslatePipe } from '../../pipes/translate.pipe';
import { GlobalService } from '../../services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ADMINISTRATOR, PROJECT_MANAGER, QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER, 
  TEAM_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN, EXPLORER_MEMBER} from '../../constants/userRoles';
import * as querysheetConstants from '../../constants/querysheetConstants';
import { ValidationSearchService } from '../../components/validations-filter/validation-search.service';

export enum ViewType {
  list = 0,
  grid
}

@Component({
  selector: 'app-querysheet-management',
  templateUrl: './querysheet-management.component.html',
  styleUrls: ['./querysheet-management.component.scss']
})
export class QuerysheetManagementComponent implements OnInit {
  public viewType = ViewType;
  public currentViewType = ViewType.list;
  public loading: boolean;
  public explorationLoading: boolean;
  public tableSettings: object;
  public explorationTableSettings: object;
  public rows: object = {};
  public explorationsRow: object = {};
  private pagination: PaginationModel;
  private explorationPagination: PaginationModel;
  public currentTab: string = "explorationList";
  public querysheetStatus = querysheetConstants;
  public analysisList = [];
  public user: any;
  public qsAllowedRoles:  Array<String> = [ADMINISTRATOR, PROJECT_MANAGER, TEAM_ADMIN, EXPLORER_MEMBER, QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN_PLUS_EXPLORER_MEMBER];
  public _userPermissions: any;
  
  constructor(
    private globalService: GlobalService,
    private modalService: NgbModal,
    private paginationService: PaginationService,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private translate: TranslatePipe,
    public validationSearchService: ValidationSearchService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();    
    if(this.authService.hasPermission([this._userPermissions.VIEW_EXPLORATION]) || this.user.isadmin){
      this.currentTab = this.globalService.getCurrentTab()  || "explorationList";
    } else {
      this.currentTab = "querysheetList";
    }
  }

  ngOnInit(): void {
    // Initialize pagination to default
    this.explorationPagination = this.paginationService.getDefaultPagination('explorations');
    this.pagination = this.paginationService.getDefaultPagination('querysheets');
    // fetch querysheet data from db
    this.getQuerySheetsFromDB();    
    this.getTableSettings();

    if (this.authService.hasPermission([this._userPermissions.VIEW_EXPLORATION]) || this.user.isadmin) {
      this.getExplorationsFromDB();
      this.getAllAnalysisForUser();
    }
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getTableSettings() {
    this.tableSettings = {
      getDataFromDB: this.getQuerySheetsFromDB.bind(this)
    };
    if(this.authService.hasPermission([this._userPermissions.VIEW_EXPLORATION]) || this.user.isadmin){
      this.explorationTableSettings = {
        getDataFromDB: this.getExplorationsFromDB.bind(this)
      }
    }    
  }

  public toggleTab(tab: string) {
    const querysheetFilters = this.validationSearchService.getPreviousSearch('querysheets');
    const explorationFilters = this.validationSearchService.getPreviousSearch('explorations');
    //Clearing Querysheet Filter when tab changed to explorations
    if(tab=="explorationList" && querysheetFilters && Object.keys(querysheetFilters).length > 0){
      this.validationSearchService.setPreviousSearch({},'querysheets');
      this.rows = {}
      this.getQuerySheetsFromDB();
    }    
    //Clearing exploration Filter when tab changed to querysheets
    if(tab=="querysheetList" && explorationFilters && Object.keys(explorationFilters).length > 0){
      this.validationSearchService.setPreviousSearch({},'explorations');
      this.explorationsRow = {}
      this.getExplorationsFromDB();
    }
    this.currentTab = tab;
    this.globalService.setCurrentTab(tab)
  }

  public getQuerySheetsFromDB(filters={}, cbFunc = undefined):void {
    if (!cbFunc) {
      this.loading = true;
    }
    if(Object.keys(filters).length > 0){
      this.validationSearchService.setPreviousSearch(
        filters,
        'querysheets'
      );
      this.pagination = this.paginationService.getDefaultPagination('querysheets');
    }else{
      filters = this.validationSearchService.getPreviousSearch('querysheets');
    }
    let params = { ...this.pagination, ...filters };
    // this.loading = true;

    // Integrating with api
    this.apiService.get('querysheets/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: QuerySheetModel[];
      }) => {
        // Customise Processing Status and State columns for QS Listing
        data.data.forEach(d => {
          d['project'] = 'TPRM-Test';
          //Processing Status column modifications
          switch(d['querySheetStatus']) {
            case this.querysheetStatus.QUERYSHEET_STATUS_PROCESSING:
              d['status'] = this.translate.transform('querysheetDetails.inProgress');
              d['status_data'] = { class: 'in-progress', value: this.translate.transform('querysheetDetails.inProgress')};
              d['status_data_tooltip'] = (this.user.role.rolename == EXPLORER_MEMBER)? this.translate.transform('querysheetDetails.querySheetCardPublishedTooltip') : ""
              d['status_data_tooltip_position'] = "below" 
              break;
            case this.querysheetStatus.QUERYSHEET_STATUS_SUCCESS:
              d['status'] = this.translate.transform('querysheetDetails.success');
              d['status_data'] = { class: 'success', value: this.translate.transform('querysheetDetails.success')};
              break;
            case this.querysheetStatus.QUERYSHEET_STATUS_FAILED:
              d['status'] = this.translate.transform('querysheetDetails.failed');
              d['status_data'] = { class: 'failure', value: this.translate.transform('querysheetDetails.failed')};
              d['status_data_tooltip'] = (this.user.role.rolename == EXPLORER_MEMBER) ? this.translate.transform('querysheetDetails.querySheetCardFailedTooltip') : ""
              d['status_data_tooltip_position'] = "below" 
              break;
            default:
              d['status'] = this.translate.transform('querysheetDetails.pending');
              d['status_data'] = { class: 'pending', value: this.translate.transform('querysheetDetails.pending')};
              break;
          }

          //State column modifications
          d['isPublishedStatus'] = d['isPublished']
          ? this.translate.transform('querysheetDetails.published')
          : this.translate.transform('querysheetDetails.unpublished')
        });
        this.rows = data;
        this.paginationService.setPage('querysheets', data);
        this.pagination = this.paginationService.setPagination('querysheets', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loading = false;
        cbFunc && cbFunc()
      },
      () => {
        this.loading = false;
        cbFunc && cbFunc()
      }
    );
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  // Opening a modal for query sheet creation
  onCreateQuerysheet(): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      CreateQuerysheetComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      title: this.translate.transform('createQuerysheet.title'),
      action: 'create'
    };

    modalWindowRef.result.then(result => {
      if (result) {
        this.paginationService.changePagination('querysheets', 'page', 1);
        this.getQuerySheetsFromDB();
      }
    });
  }

  public getExplorationsFromDB(filters={}, cbFunc = undefined): void {
    if (!cbFunc) {
      this.explorationLoading = true;
    }
    if(Object.keys(filters).length > 0){
      this.validationSearchService.setPreviousSearch(
        filters,
        'explorations'
      );
      this.explorationPagination = this.paginationService.getDefaultPagination('explorations');
    }else{
      filters = this.validationSearchService.getPreviousSearch('explorations');
    }
    let params = { ...this.explorationPagination, ...filters };
    this.apiService.get('explorations/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: ExplorationModel[];
      }) => {
        data.data.forEach(d => {
          if(d.status == null || d.status == 'progress'){
              d['status_data'] = { class: 'in-progress', value: d.documentcount };
          }else if (d.status == 'ready'){
              d['status_data'] = { class: 'success', value: d.documentcount };
          }else{
              d['status_data'] = { class: 'failure', value: d.documentcount };
          }
        })
        //Displaying modified date always if exists
        data.data.forEach(obj => {
          if (obj.modifiedDate) {
            obj.createdDate = obj.modifiedDate
          }
        })
        this.explorationsRow = data;
        this.paginationService.setPage('explorations', data);
        this.explorationPagination = this.paginationService.setPagination('explorations', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.explorationPagination.query
        });
        this.explorationLoading = false;
        cbFunc && cbFunc()
      });
  }

  public getAllAnalysisForUser(): void {
    this.apiService.get('analysis/useranalysis/', '', '').subscribe(data => {
      this.analysisList = data.data;
    });
  }
}
