import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-query-details',
  templateUrl: './query-details.component.html',
  styleUrls: ['./query-details.component.scss']
})
export class QueryDetailsComponent implements OnInit {
  @Input() selectedQuery: {
    id: any;
    name: string;
    text: string;
    question: string;
    children: [];
    highlight: boolean;
  } = null;

  constructor() {}

  ngOnInit(): void {}
}
