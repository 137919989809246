import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, concat, Subject } from 'rxjs';

import { PaginationModel } from 'src/app/models/pagination';
import { PaginationService } from 'src/app/services/pagination.service';
import { ApiService } from '../../../services/api.service';
import { ProjectModel } from '../../../models/project-mstr.model';
import { Reason } from 'src/app/models/close-reason.model';
import { GlobalService } from '../../../services/global.service';
import { EnrichmentModel } from '../../../models/enrichment.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DeleteModalComponent } from '../delete/delete.component';
import { LinkService } from 'src/app/services/link.service';
import * as _rolesConst from 'src/app/constants/userRoles';
import { ValidationSearchService } from '../../validations-filter/validation-search.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { AuthenticationService } from 'src/app/security/authentication.service';

/**
 * Enrichment upload modal
 */
@Component({
  selector: 'app-update-docs',
  templateUrl: './update-docs.component.html',
  styleUrls: ['./update-docs.component.scss']
})
export class UpdateDocsComponent implements OnInit, OnDestroy {
  @Input() settings: any;
  @Input() paginationType: string;
  @Input() params: {} = [];
  @Output() savedEvent = new EventEmitter<ProjectModel>();

  public options = true;
  public reason = Reason;
  public file: File;
  public enrichmentTypesList: EnrichmentModel[] = [];
  public subscriptionEnrichmentTypes: Subscription;
  public loadingTable: boolean;
  public backgroundLoading: boolean;
  public columns: any[];
  public pagination: PaginationModel;
  public settingTable: any;
  public rows: {};
  public showWarning: boolean = false;
  public enrichmentValidationCategory = '0';
  public type: number = 0;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  // General variables
  public showValidations: boolean = false;
  public showReviewFlows: boolean = false;

  //Document to consolidate variables
  public responseTableData = {};
  public countItemsSelected: number;
  public itemsSelected = [];

  // Preview current selection on pills
  public previewSelectionOnAttribute: String;

  //cognitive
  public _documentStatus: any;
  public _userPermissions: any;

  //Filter statitics
  public totalFiltered: number = 0;

  //Filter related Variables
  public filters: any = [];
  public labelFilters: any[];
  public showFilters: boolean = false;
  public statusList = [];
  public datamodelList;

  //validation variable
  public subscriptionReviewers: Subscription;

  //public addItemEvent: Observable<any>;

  public user: any = {};

  FILE_FORMATS =
    '.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.binary.macroEnabled.12,plain/text,.txt,.json';
  constructor(
    private linkService: LinkService,
    private authService: AuthenticationService,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private globalService: GlobalService,
    private paginationService: PaginationService,
    private translate: TranslatePipe,
    public validationSearchService: ValidationSearchService,
    private fileDownloadService: FileDownloadService,
    private modalService: NgbModal
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();

    this.loadingTable = true;
    this.columns = this.getColumns();
    this._documentStatus = this.globalService.getDocumentStatusConst();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.showReviewFlows = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
  }

  ngOnInit() {
    this.pagination = this.paginationService.getDefaultPagination('enrichment');
    this.getAdditionalDocumentsList();
    this.settingTable = this.getSettingTable();
    this.statusList = this.globalService.getGlobalStatus();
    this.datamodelList = Array.from(
      this.globalService.getDatamodels().values()
    );

    if (!this.statusList && this.datamodelList.length === 0) {
      this.subscriptionReviewers = concat(
        this.globalService.watchDataModels(),
        this.globalService.watchUtils()
      ).subscribe(() => {
        this.datamodelList = Array.from(
          this.globalService.getDatamodels().values()
        );
        this.statusList = this.globalService.getGlobalStatus();
      });
    } else if (this.datamodelList.length === 0) {
      this.subscriptionReviewers = this.globalService
        .watchDataModels()
        .subscribe(() => {
          this.datamodelList = Array.from(
            this.globalService.getDatamodels().values()
          );
        });
    } else if (!this.statusList) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.statusList = this.globalService.getGlobalStatus();
        });
    }
  }

  public getDropDownSources() {
    this.enrichmentTypesList = Array.from(
      this.globalService.getEnrichmentTypes().values()
    ).filter(
      type =>
        type.enrichment_category == parseInt(this.enrichmentValidationCategory)
    );
    if (
      this.enrichmentTypesList.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionEnrichmentTypes = this.globalService
        .watchUtils()
        .subscribe((data: string) => {
          this.enrichmentTypesList = Array.from(
            this.globalService.getEnrichmentTypes().values()
          ).filter(
            type =>
              type.enrichment_category === +this.enrichmentValidationCategory
          );
          this.loadingTable = false;
        });
    } else {
      this.loadingTable = false;
    }
  }

  public getAdditionalDocumentsList() {
    const storedProjects = this.paginationService.getPage('enrichment');
    if (
      storedProjects &&
      storedProjects.data.length &&
      storedProjects.page === 1 &&
      storedProjects.order_by === 'additionaldocumentid' &&
      storedProjects.desc === 0
    ) {
      this.backgroundLoading = true;
      this.rows = storedProjects;
      this.loadingTable = false;
    } else {
      this.loadingTable = true;
    }
    // Always request projects from db
    this.getAdditionalDocumentsFromDB();
  }

  /**
   * Function that returns the inner logic text value
   */

  public enrichmentFilterTextValue(filterName: string, logic: string) {
    if (filterName === 'category') {
      switch (logic) {
        case '0':
          return this.translate.transform('validation.extraction');
        case '1':
          return this.translate.transform('validation.validation');
        default:
          return logic;
      }
    } else if (filterName === 'enrichmentfiletype') {
      this.enrichmentTypesList = Array.from(
        this.globalService.getEnrichmentTypes().values()
      ).filter(type => type.additionaldocumenttypeid === parseInt(logic));
      return this.enrichmentTypesList[0].displayname;
    } else if (filterName === 'level') {
      switch (logic) {
        case 'global':
          return this.translate.transform('updateDocs.level.global');
        case 'project':
          return this.translate.transform('updateDocs.level.project');
        case 'analysis':
          return this.translate.transform('updateDocs.level.analysis');
        case 'document':
          return this.translate.transform('updateDocs.level.document');
        default:
          return logic;
      }
    } else {
      return logic;
    }
  }
  /** */

  /**
   * Get Additional Documents from database
   */
  private getAdditionalDocumentsFromDB() {
    const params = this.setParams();
    this.apiService.get('enrichment/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: EnrichmentModel[];
      }) => {
        this.rows = this.serializeData(data);
        this.totalFiltered = data.total_elements;
        this.paginationService.setPage('enrichment', data);
        this.pagination = this.paginationService.setPagination('enrichment', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loadingTable = false;
        this.backgroundLoading = false;
      },
      () => {
        this.loadingTable = false;
        this.backgroundLoading = false;
      }
    );
  }

  public setParams() {
    const params = { ...this.pagination, ...this.filters };
    switch (this.settings.level) {
      case 'project':
        params['projectid'] = this.settings.projectId;
        break;
      case 'analysis':
        params['analysisid'] = this.settings.analysisId;
        params['projectid'] = this.settings.projectId;
        break;
      case 'document':
        params['documentid'] = this.settings.documentId;
        params['analysisid'] = this.settings.analysisId;
        params['projectid'] = this.settings.projectId;
        break;
      default:
        break;
    }
    return params;
  }

  /**
   * Return loaded SettingTable with the general settings for the component responsive table
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'documentid',
      hasEditPermissions: this.hasEditPermissions(),
      renderFunction: this.shouldRender.bind(this),
      getDataFromDB: this.getAdditionalDocumentsFromDB.bind(this),
      responsiveTitle: {
        label: 'documentdisplayname'
      },
      paginationClass: 'relative-pag',
      hasDownloadOptions: this.hasFileDownloadPermissions(),
      actionsOnDownload: () => [
        {
          click: this.downloadFile.bind(this),
          class: 'fa fa-download',
          title: 'download.file.generic',
        }
      ]
    };
    this.setAdditionalOptions();

    return this.settingTable;
  }

  /**
   * Check if user has file download permissions.
   */
  private hasFileDownloadPermissions() {
    return (
      (this.user && this.user.isadmin) ||
      (this.user &&
        this.authService.hasPermission(
          this._userPermissions.DOWNLOAD_DOCUMENTS
        ))
    );
  }

  /**
   * Download file.
   * @param row table row.
   */
  private downloadFile(row: any) {
    const url = `download/additionaldocument/${row.additionaldocumentid}/`;
    const file = {
      mediatype: row.mediatype
    };
    const filename = row.documentdisplayname;
    this.fileDownloadService.downloadExternalFile(url, file, filename);
  }

  /**
   * Check if user has edit permissions
   */
  public hasEditPermissions() {
    return this.user && this.getAdditionalPerm();
  }

  private getAdditionalPerm() {
    switch (this.settings.level) {
      case 'root':
        return this.user.isadmin;
      case 'project':
        return (
          this.user.isadmin ||
          this.authService.hasPermission([
            this._userPermissions.DELETE_ENRICHMENT_FILES
          ])
        );
      case 'analysis':
        return (
          this.user.isadmin ||
          this.authService.hasPermission([
            this._userPermissions.DELETE_ENRICHMENT_FILES
          ])
        );
      case 'document':
        return (
          this.user.isadmin ||
          this.authService.hasPermission([
            this._userPermissions.DELETE_ENRICHMENT_FILES
          ])
        );
      default:
        return false;
    }
  }

  public onRemoveItemEvent(event) {
    if (event.length > 0) {
      this.itemsSelected = event;
    } else {
      this.itemsSelected = event.items;
      this.changeSelectedItems(event.index, event.data);
    }
  }

  /**
   * Function to add differents custom options
   * to the setting table depending on wich
   * component is the parent
   */
  private setAdditionalOptions() {
    this.settingTable['hasSelect'] = 'true';
    this.settingTable['actionsOnSelected'] = [
      {
        click: this.deleteSelectedDocuments.bind(this),
        class: 'fa fa-trash',
        title: 'projectDocument.deleteSelected'
      }
    ];
    this.settingTable['actionsOnResponsive'] = [
      {
        click: this.deleteDocument.bind(this),
        class: 'fa fa-trash'
      }
    ];
    this.settingTable['isSelected'] = this.isSelected.bind(this);
    this.settingTable['hasSelectedFunctions'] = 'true';
    this.settingTable['changeSelectedItems'] =
      this.changeSelectedItems.bind(this);
    this.settingTable['paginationClass'] = 'relative-pag';
    this.settingTable['checkerItems'] = this.getItemsToConsolidate.bind(this);
    this.previewSelectionOnAttribute = 'documentdisplayname';
  }

  /**
   * Delete single document
   */
  public deleteDocument(item) {
    this.deleteDocumentModal([item.additionaldocumentid]);
  }

  /**
   * Delete multiple documents
   */
  public deleteSelectedDocuments(selectedItems) {
    let numericIds = [];

    selectedItems.forEach(item => numericIds.push(item.additionaldocumentid));

    this.deleteDocumentModal(numericIds);
  }

  /**
   * Load modal to confirm document(s) removal
   */
  private deleteDocumentModal(additionaldocuments) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm',
      backdropClass: 'enrichment-delete'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'document(s)',
      notAllowed: additionaldocuments.length < 1
    };

    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loadingTable = true;
          const requestbody = { ids: additionaldocuments };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.cleanAllSelectedItems();
    this.apiService
      .post('enrichment/delete', requestbody, 'updateDocs.delete')
      .subscribe(
        () => {
          // Remove documents from view while request from db
          this.rows = this.rows['data'].filter(
            el => requestbody.ids.indexOf(el.additionaldocumentid) < 0
          );
          this.loadingTable = false;
          this.pagination = this.paginationService.changePagination(
            'enrichment',
            'page',
            1
          );
          this.getAdditionalDocumentsFromDB();
        },
        error => {
          this.loadingTable = false;
        }
      );
  }

  /**
   * Check if is selected when paginate to tick checkbox or not
   * @param index index of element
   * @param data all data array of current page
   */
  public isSelected(index, data) {
    const index_page = data.data.indexOf(index);
    let isSelected = false;
    if (this.responseTableData[data.page]) {
      if (this.responseTableData[data.page][index_page]) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldRender(row) {
    return true;
  }

  public getItemsToConsolidate() {
    const items = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        items.push(this.responseTableData[number_page][number_item]);
      }
    }
    return items;
  }

  public showDocumentsSelected() {
    return (
      !this.loadingTable &&
      this.countItemsSelected &&
      this.countItemsSelected > 0
    );
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    return [
      {
        header: 'documentFilter.documentdisplayname',
        name: 'documentdisplayname',
        orderBy: 'documentdisplayname'
      },
      {
        header: 'updateDocs.enrichmentFileTypes',
        name: 'additionaldocumentname'
      },
      {
        header: 'updateDocs.category',
        name: 'category'
      },
      {
        header: 'projectList.date',
        name: 'createddate',
        type: 'date',
        format: 'll'
      },
      {
        header: 'projectList.createdBy',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'updateDocs.datamodels',
        type: 'datamodels',
        class: 'td-center'
      },
      {
        header: 'updateDocs.level.header',
        name: 'level',
        class: 'ellipsis-text medium-size-column'
      }
    ];
  }

  /**
   * Function to show properly the data in the table
   * In this case to allow the navigation to the additional documents.
   * @param data
   */
  public serializeData(data) {
    const enrichedTypesGlobal = Array.from(
      this.globalService.getEnrichmentTypes().values()
    );
    this.enrichmentTypesList = enrichedTypesGlobal.filter(
      type => type.enrichment_category === +this.enrichmentValidationCategory
    );
    const elements = { ...data };
    elements.data.map(doc => {
      const enrType = enrichedTypesGlobal.filter(
        type =>
          type.additionaldocumenttypeid === doc['additionaldocumenttypeid']
      )[0];
      if (enrType) {
        doc['additionaldocumentname'] = enrType.displayname;
        doc['category'] =
          enrType.enrichment_category === 0 ? 'Extraction' : 'Validation';
        doc['datamodels'] = enrType['associated_datamodels'].map(
          datamodel => datamodel.datamodelid
        );
      }
      if (doc['documentid'] !== null) {
        doc['level'] = this.translate.transform('updateDocs.level.document');
      } else if (doc['analysisid'] !== null) {
        doc['level'] = this.translate.transform('updateDocs.level.analysis');
      } else if (doc['projectid'] !== null) {
        doc['level'] = this.translate.transform('updateDocs.level.project');
      } else {
        doc['level'] = this.translate.transform('updateDocs.level.global');
      }
    });
    return elements;
  }

  public onUpdateDocs(form: NgForm) {
    const formValues = {
      enrichmentTypeid: form.value.enrichmentType,
      file: this.file
    };
    switch (this.settings.level) {
      case 'project':
        formValues['projectid'] = this.settings.projectId;
        break;
      case 'analysis':
        formValues['analysisid'] = this.settings.analysisId;
        break;
      case 'document':
        formValues['documentid'] = this.settings.documentId;
        break;
      default:
        break;
    }
    if (formValues && formValues.file) {
      let formData = new FormData();
      if (this.settings.projectId) {
        formData.append('projectid', this.settings.projectId);
      }

      if (this.settings.documentId) {
        formData.append('documentid', this.settings.documentId);
      }

      if (this.settings.analysisId) {
        formData.append('analysisid', this.settings.analysisId);
      }
      formData.append('additionaldocumenttypeid', formValues.enrichmentTypeid);
      formData.append('files', formValues.file);

      //start table loading before api Post loading
      this.backgroundLoading = true;

      this.apiService
        .post('enrichment', formData, 'updateDocs.fileUpdated', false)
        .subscribe(() => {
          //reinitialize modal for reusage
          this.getAdditionalDocumentsFromDB();
          this.deleteFile();
          form.value.enrichmentType = null;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionEnrichmentTypes) {
      this.subscriptionEnrichmentTypes.unsubscribe();
    }
  }

  /**
   * Handle loading data of the table from the child
   */
  public onLoadingChange(bool) {
    this.loadingTable = bool;
  }

  /**
   * Handle input file change
   * @param event uploadevent
   */
  public onChange(event: any) {
    this.file = <File>event.target.files[0];
  }

  public deleteFile() {
    this.file = null;
  }

  public onSelectType() {
    if (this.rows['total_elements'] > 0) {
      this.showWarning = true;
    }
  }

  public filterListOfTypes(newValue) {
    this.enrichmentTypesList = Array.from(
      this.globalService.getEnrichmentTypes().values()
    ).filter(type => type.enrichment_category === +newValue);
    this.type = 0;
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  public cleanAllSelectedItems() {
    this.itemsSelected.forEach(item => {
      this.changeSelectedItems(item, this.rows);
    });
    this.itemsSelected = [];
  }

  public changeSelectedItems(index, data) {
    let newAddition = false;
    if (data) {
      const index_page = data.data.indexOf(index);
      if (!this.responseTableData[data.page]) {
        this.responseTableData[data.page] = {};
      }
      if (this.responseTableData[data.page][index_page]) {
        delete this.responseTableData[data.page][index_page];
      } else {
        if (index_page >= 0) {
          newAddition = true;
          this.responseTableData[data.page][index_page] = data.data[index_page];
        }
      }
    }
    const items = [];

    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        if (
          JSON.stringify(this.responseTableData[number_page][number_item]) ==
          JSON.stringify(index) &&
          !newAddition
        ) {
          delete this.responseTableData[number_page][number_item];
        } else {
          items.push(this.responseTableData[number_page][number_item]);
        }
      }
    }
    this.countItemsSelected = items.length;
    this.itemsSelected = items;
    return items;
  }

  /**
   * Assign the filters and get the documents from the Database
   * @param filters
   */
  public onSearch(filters) {
    this.onBackgroundLoadingChange(true);
    this.cleanAllSelectedItems();

    this.pagination = this.paginationService.getDefaultPagination('enrichment');
    if (this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }
    if (!this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }

    this.filters = {
      documentname:
        filters['documentdisplayname'] !== undefined ? filters['documentdisplayname'] : '',
      category:
        filters['category'] !== undefined ? filters['category'] : '',
      enrichmentfiletype:
        filters['enrichmentfiletype'] !== undefined
          ? filters['enrichmentfiletype']
          : '',
      createdbyname:
        filters['createdByName'] !== undefined ? filters['createdByName'] : '',
      level: filters['level'] !== undefined ? filters['level'] : ''
    };

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'documentname':
            return {
              name: 'documentname',
              value: filters['documentdisplayname']
            };
          case 'category':
            return {
              name: 'category',
              value: filters['category']
            };
          case 'enrichmentfiletype':
            return {
              name: 'enrichmentfiletype',
              value: filters['enrichmentfiletype']
            };
          case 'createdbyname':
            return {
              name: 'createdbyname',
              value: filters['createdByName']
            };
          case 'level':
            return {
              name: 'level',
              value: filters['level']
            };
          default:
            return { name: f, value: this.filters[f] };
        }
      });

    this.getAdditionalDocumentsFromDB();
  }

  public cleanIndividualFilter(filter: string){
    this.filterEvent.next(filter)
    this.filters[filter] = ''; 

    if(filter === 'category'){
      this.filters['enrichmentfiletype'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'enrichmentfiletype');
    }

    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    this.onBackgroundLoadingChange(true);
    this.getAdditionalDocumentsFromDB();
  }
}
