import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { ApiService } from '../../services/api.service';
import { DocumentFieldDataModel } from '../../models/document-field-data.model';
import { AuthenticationService } from '../../security/authentication.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from '../../services/global.service';
import * as _rolesConst from 'src/app/constants/userRoles';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { NewCommentComponent } from '../modals/new-comment/new-comment.component';
import { AddCommentValidationComponent } from '../modals/add-comment-validation/add-comment-validation.component';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { LinkService } from 'src/app/services/link.service';
import { UpdateReviewCountdownComponent } from '../modals/update-review-countdown/update-review-countdown.component';
import { Reason } from 'src/app/models/close-reason.model';
import { DocumentReviewLogModel } from 'src/app/models/document-review-log.model';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { UpdateDocsComponent } from '../modals/update-docs/update-docs.component';

@Component({
  selector: 'app-document-buttons',
  templateUrl: './document-buttons.component.html',
  styleUrls: ['./document-buttons.component.scss']
})
export class DocumentButtonsComponent implements OnInit, OnDestroy {
  @Input() documentDetails: DocumentFieldDataModel;
  @Input() buttons: Array<string>;

  backgroundLoading: boolean = false;

  public documentId: number;
  public user: any = {};
  public showValidations: boolean = false;
  public showChangeStatus: boolean = false;
  public isRelaunchable: boolean = false;
  public documentStatusConst;
  public approval: any = {};
  public showReviewFlow: any = false;

  private isLoading: boolean = false;
  private hasBeenRerunned: boolean = false;
  private hasChunksToShow: boolean = false;
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  private userReviewGroups: Array<number> = [];
  public hasEnrichmentModule: boolean;

  // Const
  public _userPermissions: any;
  public _rolesConst = _rolesConst;

  //To make sure recommend and not-recommend buttons cannot be clicked twice
  public recommendClicked: boolean = false;
  public showWordMock: boolean = false;

  public showdrpdwn: boolean = false;

  public hasChunkData: boolean = false;
  public chunkData: any


  constructor(
    public fileDownloadService: FileDownloadService,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private globalService: GlobalService,
    private translate: TranslatePipe,
    private global: GlobalService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private router: Router,
    public link: LinkService,
    public documentRenderService: DocumentRenderService
  ) {
    this.user = authService.getLoggedInUser();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.showChangeStatus = this.authService.userCanViewModule(
      this.user,
      'ChangeStatusFunctionality'
    );
    this.showReviewFlow = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
    this.hasEnrichmentModule = this.authService.userCanViewModule(
      this.user,
      'EnrichmentCoreFunctionality'
    );
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.userReviewGroups = this.user.userreviewer.map(g => g.groupid);
    this.showWordMock = this.authService.userCanViewModule(this.user,'DemoModule');
  }

  ngOnInit() {
    this.documentId = this.documentDetails.documentid;
    this.chunkData = this.documentRenderService.getChunks()
    if (!this.chunkData || Object.keys(this.chunkData).length == 0) {
      this.documentRenderService.chunksWatcher()
        .subscribe(obj => {
          this.chunkData = this.documentRenderService.getChunks()
          this.hasChunkData = this.chunkData ? true : false
       });
    }
    this.hasChunkData = this.chunkData ? true : false
    this.hasChunksToShow = this.hasExtractedValues(
      this.documentDetails.extracteddatalist
    );
    // Check if document is relaunchable
    this.checkRelaunchable();
    this.documentStatusConst = this.globalService.getDocumentStatusConst();
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
        });
    } else {
      this.setApproval();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer or admin
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if document is relaunchable
   */
  private checkRelaunchable() {
    if (
      this.authService.hasPermission([this._userPermissions.EDIT_PROJECTS]) ||
      this.authService.hasPermission([this._userPermissions.EDIT_ANALYSIS]) ||
      this.authService.hasPermission([this._userPermissions.EDIT_DOCUMENTS]) ||
      this.user.isadmin
    ) {
      this.apiService
        .get(`documents/${this.documentId}/relaunchable/`, {}, '')
        .subscribe(data => {
          this.isRelaunchable = data.relaunchable === 1;
        });
    }
  }

  /**
   * Resend document
   */
  public onResendExtraction() {
    this.apiService
      .put('documents/', this.documentId, {}, 'documentDetails.resendMsg')
      .subscribe(() => {});
  }

  /**
   * Return rerun button title
   */
  public getRerunButtonTitle() {
    if (!this.hasChunksToShow) return '';
    if (!this.userCanRerun())
      return this.translate.transform('documentDetails.onlyAssigned');
    if (this.hasBeenRerunned)
      return this.translate.transform('documentDetails.mustReloadPageToRerun');
    return '';
  }

  /**
   * Run validation
   */
  public onRunValidation() {
    this.hasBeenRerunned = true;
    if (!this.hasChunksToShow || !this.userCanRerun()) {
      return;
    }
    this.apiService
      .put(
        'documentvalidations/',
        this.documentId,
        {},
        'documentDetails.runValidationMsg'
      )
      .subscribe(() => {});
  }

  /**
   * Check if user is the assigned reviewer
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Check if user can self-assign approval
   */
  public userCanSelfAssignApproval() {
    return (
      this.approval &&
      (!this.approval.assigned ||
        ((this.user.isadmin ||
          this.user.role.rolename === _rolesConst.GROUP_BENEFIT_VIEWER) &&
          this.approval.assigned.userid != this.user.userid)) &&
      !this.approval.recommended &&
      (this.userReviewGroups.indexOf(this.approval.groupid) !== -1 ||
        this.user.isadmin)
    );
  }
  public disable_self_assign(): boolean {
    if (!this.user.usergroupid.includes(this.approval.groupid)) {
      return true;
    }
    return false;
  }

  /**
   * Check if user can rerun document (only if
   * has document assigned).
   */
  public userCanRerun() {
    return this.approvalIsAssignedToUser() && !this.approval.recommended;
  }

  /**
   * Self-assign approval
   */
  public selfAssignApproval() {
    if (!this.approval || !this.approval.approvalid) {
      return;
    }
    this.isLoading = true;
    this.apiService
      .post(
        'documentreviewapproval/assignto',
        {
          approvalids: [this.approval.approvalid],
          userid: this.user.userid
        },
        'validation.validationToReview.assigned'
      )
      .subscribe(() => {
        this.approval.assigned = {
          userid: this.user.userid,
          username: this.user.username
        };
        this.globalService.emptyDocumentDetails();
        this.isLoading = false;
      });
  }

  /**
   * This method is used for recommending a document
   */
  public confirmDocumentRecommendation(confirm: boolean) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'recommend',
      message: confirm
        ? this.translate.transform('components.modal.recommend.textConfirm')
        : this.translate.transform('components.modal.recommend.textNotConfirm')
    };
    modalWindowRef.result.then(
      result => {
        if (result) {
          this.backgroundLoading = true;
          this.setRecommendation(confirm);
          this.backgroundLoading = false;
        } else {
          this.recommendClicked = false;
        }
      },
      reason => {}
    );
  }

  /**
   * Send recommendation
   * @param recommended recommend validation (true) or no recommend (false)
   */
  public setRecommendation(recommended: boolean) {
    if (!this.approval || !this.approval.approvalid) {
      return;
    }
    this.isLoading = true;
    const message = recommended
      ? this.translate.transform('documentDetails.recommend')
      : this.translate.transform('documentDetails.unrecommend');
    this.apiService
      .put(
        `documentreviewapproval/${this.approval.approvalid}/recommend`,
        '',
        { recommended },
        message
      )
      .subscribe(
        () => {
          this.globalService.emptyDocumentDetails();
          this.isLoading = false;
          this.approval.recommended = true;
          this.recommendClicked = false;
        },
        () => {
          this.isLoading = false;
          this.recommendClicked = false;
        }
      );
  }

  public setPartialUpdate(status_id: number) {
    this.isLoading = true;
    this.apiService
      .patch(
        'documents/',
        this.documentId,
        { status_id },
        'documentDetails.changedStatus'
      )
      .subscribe();
  }

  /**
   * Filter extracted values with null or empty
   * extracted values list
   * @param d extractedvalue
   */
  public filterExtractedValues(d: any) {
    return d.extractedvalueslist && d.extractedvalueslist.length > 0;
  }

  /**
   * Returns true if the extracteddatalist
   * has a extractedfield with extracted value
   * otherwise returns false.
   * @param extractedlist documentDetails.extracteddatalist
   */
  public hasExtractedValues(extractedlist) {
    if ([undefined, null].includes(extractedlist)) {
      return false;
    } else {
      return (
        extractedlist
          .filter(d => {
            const datalist = { ...d };
            // Remove empty fields list
            datalist.fieldslist = d.fieldslist.filter(
              this.filterExtractedValues
            );
            return datalist.fieldslist.some(this.filterExtractedValues);
          })
          .filter(e => e).length > 0
      );
    }
  }

  /**
   * Updates the review state (paused or resumed) of the document
   * @returns
   */
  public updateReviewCountdownState(): void {
    if (this.documentDetails.isreviewpaused == null) {
      return;
    }
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options: any = {
      isPaused: this.documentDetails.isreviewpaused,
      reviewLogs: this.documentDetails.reviewlogs
    };

    const modalWindowRef = this.modalService.open(
      UpdateReviewCountdownComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(result => {
      if (result.reason === Reason.submitBtn) {
        this.setReviewLog(result.result);
      }
    });
  }

  /**
   * Creates a review log in the database
   * @param value An object containing the message and the alternative date.
   */
  private setReviewLog(value: any): void {
    this.isLoading = true;
    this.apiService
      .post(
        `documentreviewlogs`,
        {
          userid: this.user.userid,
          documentid: this.documentId,
          ispaused: !this.documentDetails.isreviewpaused,
          message: value.message,
          alternativedate: value.alternativeDate
        },
        this.documentDetails.isreviewpaused
          ? 'documentDetails.reviewResumed'
          : 'documentDetails.reviewPaused'
      )
      .subscribe(
        (reviewLog: DocumentReviewLogModel) => {
          this.isLoading = false;
          this.documentDetails.isreviewpaused = reviewLog.ispaused;

          if (!this.documentDetails.reviewlogs) {
            this.documentDetails.reviewlogs = [];
          }
          this.documentDetails.reviewlogs.push(reviewLog);
        },
        error => {
          this.isLoading = false;
        }
      );
  }

  /**
   * Chgecks if the user can use the review button
   * @returns
   */
  public userCanEditReviewFlow(): boolean {
    return (
      this.showReviewFlow &&
      this.documentDetails.maxreviewdays &&
      this.documentDetails.isreviewpaused != null
    );
  }

  /**
   * Checks if the review due date is greater than the current date
   * @returns True if the review due date is greater than the current date.
   */
  public checkReviewDueDate(): boolean {
    if (!this.documentDetails.reviewduedate) {
      return false;
    }
    if (!(this.documentDetails.reviewduedate instanceof Date)) {
      this.documentDetails.reviewduedate = new Date(
        this.documentDetails.reviewduedate
      );
    }
    return this.documentDetails.reviewduedate > new Date();
  }
  /**
   *
   * @returns The title of the pause/resume button
   */
  public getPauseButtonTitle(): string {
    if (!this.approvalIsAssignedToUser()) {
      return this.translate.transform('documentDetails.onlyAssigned');
    } else if (!this.checkReviewDueDate()) {
      return this.translate.transform('documentDetails.dueDateExpired');
    } else {
      return '';
    }
  }

  // public downloadMock(): void {
  //   const downloadInstance = document.createElement('a');
  //   let url;
  //   if (this.documentDetails.language.langcode === 'deu') {
  //     const blob = new Blob(['../../../assets/fixtures/mockDocs/ECLI_BE_GHCC_2007_ARR.135_DE.docx'], {type: 'application/msword'});
  //     url = window.URL.createObjectURL(blob);
  //     downloadInstance.href = url;
  //     downloadInstance.download = 'ECLI_BE_GHCC_2007_ARR.135_DE.docx';
  //   } else if (this.documentDetails.language.langcode === 'nld') {
  //     const blob = new Blob(['../../../assets/fixtures/mockDocs/ECLI_BE_GHCC_2007_ARR.135_DE.docx'], {type: 'application/msword'});
  //     url = window.URL.createObjectURL(blob);
  //     downloadInstance.href = url;
  //     downloadInstance.download = 'ECLI_BE_GHCC_2007_ARR.135_NL.docx';
  //   }
  //   downloadInstance.target = '_blank';
  //   document.body.appendChild(downloadInstance);
  //   downloadInstance.click();
  //   document.body.removeChild(downloadInstance);
  //   window.URL.revokeObjectURL(url)

  // }
  public downloadMock(): void {
    const downloadInstance: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    if (this.documentDetails.language.langcode === 'deu') {
      // downloadInstance.href = 'src/assets/fixtures/mockDocs/ECLI_BE_GHCC_2007_ARR.135_DE.docx';
      downloadInstance.href = 'src/assets/fixtures/mockDocs/pablotest.docx';

      downloadInstance.download = 'ECLI_BE_GHCC_2007_ARR.135_DE.docx';
    } else if (this.documentDetails.language.langcode === 'nld') {
      // downloadInstance.href = 'src/assets/fixtures/mockDocs/ECLI_BE_GHCC_2007_ARR.135_NL.docx';
      downloadInstance.href = 'src/assets/fixtures/mockDocs/pablotest.docx';
      downloadInstance.download = 'ECLI_BE_GHCC_2007_ARR.135_NL.docx';
    }
    downloadInstance.target = '_blank';
    document.body.appendChild(downloadInstance);
    downloadInstance.click();
    document.body.removeChild(downloadInstance);

  }

  /**
   * This method will download Microsoft OCR response if available for the documents
   */
  public downloadMSOCRJson() {
    console.log("downloadMSOCRJson")
    const endpoint = `documents/${this.documentId}/ocr/`
    this.fileDownloadService.downloadJSONFile(
      null,
      this.documentDetails.documentdisplayname,
      endpoint)

  }

  /**
   * This method will download SIRE Chunk Text response if available for the documents
   */
  public downloadChunkJson() {
    console.log("downloadChunkJson")
    const endpoint = 'docchunks/chunk/'
    this.fileDownloadService.downloadJSONFile(
      this.documentId,
      this.documentDetails.documentdisplayname,
      endpoint)
  }

  /**
   * This method will enable download drop options
   */
  public showDownloadBtn() {
    this.showdrpdwn = !this.showdrpdwn
    console.log("showDownloadBtn")
    if (this.showdrpdwn) {
      document.getElementById("myDropdown").classList.toggle("show");
    } else {
        var myDropdown = document.getElementById("myDropdown");
          if (myDropdown.classList.contains('show')) {
            myDropdown.classList.remove('show');
          }
    }
  }
}
