import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageModel } from '../models/page';
import { PaginationModel } from '../models/pagination';
import { ProjectModel } from '../models/project-mstr.model';
import { AnalysisModel } from '../models/analysis.model';
import { KPIModel } from '../models/kpi.model';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  private pagination: PaginationModel;
  private pages = {};
  private paginations = {};

  constructor() {}

  /**
   * Set current page
   */
  public setPage(model: string, page: PageModel) {
    this.pages[model] = page;
    return this.pages[model];
  }

  /**
   * Return current page
   */
  public getPage(model: string) {
    return this.pages[model];
  }

  /**
   * Return default pagination model
   */
  public getDefaultPagination(model: string) {
    switch (model) {
      case 'projects':
        return new PaginationModel(10, 1, 'projectid', 1, '');
      case 'analysis':
        return new PaginationModel(10, 1, 'analysisid', 1, '');
      case 'users':
      case 'unassignedprojectusers':
      case 'assignedprojectusers':
      case 'reviewers':
        return new PaginationModel(50, 1, 'username', 0, '');
      case 'explorers':
        return new PaginationModel(50, 1, 'username', 0, '');
      case 'projectusers':
        return new PaginationModel(50, 1, 'username', 0, '');
      case 'documents':
      case 'external':
      case 'cognitivesearch':
        return new PaginationModel(50, 1, 'documentid', 1, '');
      case 'datamodels':
        return new PaginationModel(50, 1, 'datamodelid', 1, '');
      case 'validations':
        return new PaginationModel(50, 1, 'datamodelid', 1, '');
      case 'checklist':
        return new PaginationModel(10, 1, 'id', 1, '');
      case 'validationsMultipleDocs':
        return new PaginationModel(10, 1, 'validationid', 1, '');
      case 'documentvalidations':
        return new PaginationModel(50, 1, 'documentid', 0, '');
      case 'documentvalidationsassigned':
        return new PaginationModel(50, 1, 'documentid', 0, '');
      case 'relateddocuments':
        return new PaginationModel(10, 1, 'documentid', 1, '');
      case 'consolidateddocuments':
        return new PaginationModel(10, 1, 'consolidateddocid', 1, '');
      case 'documentstoconsolidate':
        return new PaginationModel(10, 1, 'documentid', 1, '');
      case 'evidences':
        return new PaginationModel(50, 1, 'commentattachmentid', 1, '');
      case 'clientmanagement':
        return new PaginationModel(50, 1, 'tenantid', 1, '');
      case 'modal':
        return new PaginationModel(5, 1, '', 1, '');
      case 'logtracking':
        return new PaginationModel(20, 1, 'logtrackingid', 1, '');
      case 'reviewtracking':
        return new PaginationModel(5, 1, 'reviewtrackingid', 1, '');
      case 'timetracking':
        return new PaginationModel(50, 1, 'documentid', 1, '');
      case 'queues':
        return new PaginationModel(1000, 1, 'name', 1, '');
      case 'queues_cache':
        return new PaginationModel(1000, 1, 'key', 1, '');
      case 'comments':
        return new PaginationModel(50, 1, 'documentid', 1, '');
      case 'enrichment':
        return new PaginationModel(5, 1, 'additionaldocumentid', 1, '');
      case 'editrole':
        return new PaginationModel(10, 1, 'permissionid', 1, '');
      case 'assignchecklist':
        return new PaginationModel(10, 1, 'validationid', 1, '');
      case 'querysheets':
        return new PaginationModel(10, 1, 'querySheetId', 1, '');
      case 'createExploration':
        return new PaginationModel(10, 1, 'projectid', 1, '');
      case 'explorations':
        return new PaginationModel(10, 1, 'modifiedDate', 1, '');
      case 'documentstatus':
        return new PaginationModel(5, 1, 'analysisid', 0, '');
    }
  }

  /**
   * Change pagination property
   */
  public changePagination(model: string, property: string, value: any) {
    this.paginations[model][property] = value;
    return this.paginations[model];
  }

  /**
   * Set current pagination model
   */
  public setPagination(
    model: string,
    obj: {
      total_elements: number;
      page: number;
      page_size: number;
      num_pages: number;
      order_by: string;
      desc: number;
      query: string;
    }
  ) {
    this.paginations[model] = new PaginationModel(
      obj.page_size,
      obj.page,
      obj.order_by,
      obj.desc,
      obj.query,
      obj.total_elements
    );
    return this.paginations[model];
  }

  /**
   * Return pagination model
   */
  public getPagination(model: string) {
    return this.paginations[model]
      ? this.paginations[model]
      : this.getDefaultPagination(model);
  }
}
