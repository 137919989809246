import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrls: ['./new-comment.component.scss']
})
export class NewCommentComponent implements OnInit {
  @Input() options;
  public reason = Reason;
  public filesList: Array<File> = [];

  FILE_FORMATS =
    '.csv,image/gif,image/jpeg,image/jpeg,image/jpeg,image/jpeg,image/jpeg,image/png,image/tiff,image/x-tiff,image/tiff,image/x-tiff,image/png,.pdf,.msg,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  public onCreateComment(form) {
    const params = {
      newComment: form.textComment,
      files: this.filesList
    };
    this.activeModal.close(params);
  }

  public onChange(event: any) {
    this.filesList.push(...(<Array<File>>event.target.files));
  }

  public deleteFile(index: number) {
    const files = Array.from(this.filesList);
    files.splice(index, 1);
    this.filesList = <Array<File>>files;
  }
}
