import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-projects',
  templateUrl: './select-projects.component.html',
  styleUrls: ['./select-projects.component.scss']
})
export class SelectProjectsComponent implements OnInit {

  @Output() setSelectedProjects = new EventEmitter();
  @Input() setting;
  @Input() selectedProjects = [];
  @Input() data: { data: []} = null;
  public columns: Array<any> = [];
  public tableSettings: any;
  
  constructor() { }

  ngOnInit(): void {
    this.getTableSettings();
    this.getColumns();
    const selectedProjectIds = []
    if(this.data && this.data.data && Array.isArray(this.data.data) && this.data.data.length == Number(1)){
      const projectList = this.data.data;
      selectedProjectIds.push(projectList[projectList.length-1]['projectid']);
      this.setSelectedProjects.emit(selectedProjectIds);
      this.data.data.forEach((obj: any) => {
        obj['isRowSelected'] = true
      })
    } else if( this.selectedProjects.length > 0){
      this.data.data.forEach((project: any) => {
        if (this.selectedProjects.indexOf(project.projectid) > -1) {
          project.isRowSelected = true;
        }
      })
      this.setSelectedProjects.emit(this.selectedProjects);
    }
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'createExploration.tableColumns.project',
        name: 'projectname',
        orderBy: 'projectname'
      },
      {
        header: 'createExploration.tableColumns.createdBy',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'createExploration.tableColumns.date',
        name: 'createddate',
        orderBy: 'createddate',
        type: 'date',
        format: 'll'
      },
      {
        header: 'createExploration.tableColumns.analysis',
        name: 'analyses'
      },
      {
        header: 'createExploration.tableColumns.documents',
        name: 'documents'
      }
    ];
  }

  public getTableSettings(): void {
    const projectList = this.data?.data;
    const allRowSelectedFlag = Array.isArray(projectList) && projectList.length === Number(1) ? true : false;
    const dataLen = this.data?.data?.length;
    const selectedProjectLen = this.selectedProjects?.length;

    this.tableSettings = {
      dataId: 'projectid',
      hasSelect: 'false',
      hasEditPermissions: false,
      hasDownloadOptions: false,
      getDataFromDB: this.setting['getProjectsFromDB'],
      hasRowSelectOptions: true,
      onRowSelection: this.selectProject.bind(this),
      hidePagination: true,
      allRowsSelected: ((dataLen == selectedProjectLen && dataLen > 0 ) || allRowSelectedFlag),
      responsiveTitle: {
        label: 'projectname'
      }
    };
  }

  public selectProject(project, areAllRowsSelected): void {
    if (areAllRowsSelected) {
      this.tableSettings.allRowsSelected = !this.tableSettings.allRowsSelected;
      const selectedProjectIds = project.data.map(item => {
        item.isRowSelected = this.tableSettings.allRowsSelected;
        return item.projectid;
      });
      this.setSelectedProjects.emit(this.tableSettings.allRowsSelected ? selectedProjectIds : []);
    } else {
      const selectedProjectIds = this.selectedProjects;
      project.isRowSelected = !project.isRowSelected;
      if (!project.isRowSelected) {
        // If any row gets unselected, make allRowsSelected flag false
        this.tableSettings.allRowsSelected = false;
        const removedprojectIndex = selectedProjectIds.indexOf(project.projectid);
        selectedProjectIds.splice(removedprojectIndex, 1);
      } else {
        selectedProjectIds.push(project.projectid);
        this.tableSettings.allRowsSelected = (this.data?.data?.length == this.selectedProjects.length);
      }
      this.setSelectedProjects.emit(selectedProjectIds);
    }
  }
}
