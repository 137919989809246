import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges
} from '@angular/core';
import { PaginationService } from 'src/app/services/pagination.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
@Component({
  selector: 'app-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss']
})
export class ResponsiveTableComponent implements OnInit {
  public selectedItems = [];

  @Input() dataType: string;
  @Input() columns: any[];
  @Input() settings: any[];
  @Input() loading;
  @Input() backgroundLoading;
  @Input() data;
  @Input() useEllipsis?: boolean;
  @Output() loadingChange = new EventEmitter();
  @Output() newItemEvent = new EventEmitter();

  public isOpen: boolean = false;
  public _documentStatus: any;
  public _userPermissions: any;
  public user: any;

  constructor(
    private paginationService: PaginationService,
    private global: GlobalService,
    private authService: AuthenticationService
  ) {
    this._documentStatus = this.global.getDocumentStatusConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    this.resetSelect();
  }

  /**
   * Return CSS Class for Status Colors
   */
  public getStatusClass(classname) {
    if (classname.includes(this._documentStatus.SUCCESS)) {
      return 'text-status--success';
    } else if (classname.includes(this._documentStatus.FAILURE)) {
      return 'text-status--failure';
    } else if (classname.includes(this._documentStatus.WARNING)) {
      return 'text-status--warning';
    } else if (classname.includes(this._documentStatus.IN_PROGRESS)) {
      return 'text-status--in-progress';
    } else if (classname.includes(this._documentStatus.PENDING)) {
      return 'text-status--pending';
    }
  }

  /**
   * Clean the selectedItems (array and items from data)
   */
  public resetSelect() {
    this.selectedItems = [];
    if (this.data && this.data.data && this.data.data.length > 0) {
      this.data.data.map(item => (item.respontableIsSelected = false));
    }
  }

  /**
   * Order displayed elements and update pagination
   */
  public onSort(orderBy: string, reset = true) {
    this.loadingChange.emit(true);
    const desc = this.data.order_by === orderBy && this.data.desc === 0 ? 1 : 0;
    this.paginationService.changePagination(this.dataType, 'desc', desc);
    this.paginationService.changePagination(this.dataType, 'order_by', orderBy);
    this.paginationService.changePagination(this.dataType, 'page', 1);
    if (reset) {
      this.resetSelect();
    }
    this.settings['getDataFromDB']();
  }

  /**
   * Handle navigation page change
   */
  public onPageChange(page, reset = true) {
    this.loadingChange.emit(true);
    this.paginationService.changePagination(this.dataType, 'page', page);
    if (reset) {
      this.resetSelect();
    }
    this.settings['getDataFromDB']();
  }

  /**
   * Toggle expanded row in table (only showed on mobile sizes)
   */
  private showTableElement(el) {
    el.respontableIsShowed = !el.respontableIsShowed;
  }

  /**
   * Event handler to detect selected inputs
   */
  private changeSelectedItems() {
    this.selectedItems = this.data['data']
      .filter(d => d.respontableIsSelected === true)
      .map(d => d[this.settings['dataId']]);
  }

  /**
   * Manages selected items from parent component. If 'func' is missing it sends an event
   * to update selected items.
   *
   * @param func function that came from settings in parent component
   * @param row row data, one item
   * @param data all table data, need to know number of page and manage stored data
   */
  private changeItems(func, row, data) {
    if (func instanceof Function) {
      this.selectedItems = func(row, data);
    } else {
      this.newItemEvent.emit({ row, data }); // <-- Should be the default way to "talk" to a parent
    }
  }

  /**
   * Check if need to be checked an item when paginate from parent component
   * @param func function that came from settings in parent component
   * @param row row data, one item
   * @param data all table data, need to know number of page and manage stored data
   */
  private isSelected(func, row, data) {
    return func(row, data);
  }

  /**
   * Return CSS Classes for the columns of the table
   */
  public getDynamicClass(column: any) {
    let dynamicClass = '';
    dynamicClass = column.class ? column.class : '';
    dynamicClass = column.orderBy ? 'sort' : dynamicClass;
    dynamicClass =
      column.orderBy &&
      this.data.order_by === column.orderBy &&
      this.data.desc === 1
        ? dynamicClass + ' sort--desc'
        : dynamicClass;
    dynamicClass =
      column.orderBy &&
      this.data.order_by === column.orderBy &&
      this.data.desc === 0
        ? dynamicClass + ' sort--asc'
        : dynamicClass;
    return dynamicClass;
  }

  /**
   * Check if the selectedItems has rows
   * @param action the action icon clicked
   */
  public limitMinimumItems(action) {
    if (this.settings['checkerItems']) {
      this.selectedItems = this.settings['checkerItems'](); // This is to take care of selected items that are not on the current page.
    } else {
      this.changeSelectedItems();
    }

    if (action.limitItems) {
      return (
        this.selectedItems && this.selectedItems.length == action.limitItems
      );
    } else if (action.minimumItems) {
      return (
        this.selectedItems && this.selectedItems.length >= action.minimumItems
      );
    } else {
      return this.selectedItems && this.selectedItems.length > 0;
    }
  }

  /**
   * Toggle user menu
   */
  onChangeDropdown() {
    this.isOpen = !this.isOpen;
  }

  /*
   * Calcule table min height
   */
  public getMinHeight() {
    if (this.data && this.data.data) {
      return this.data.data.length < this.data.total_elements
        ? this.data.data.length * 41 + 'px'
        : '';
    } else {
      return '0px';
    }
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldRender(row) {
    return this.settings['renderFunction']
      ? this.settings['renderFunction'](row)
      : true;
  }

  /**
   * Returns wether it should be disable checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldDisable(row) {
    return this.settings['disableFunction']
      ? this.settings['disableFunction'](row)
      : false;
  }

  /**
   * sortExtraColumns
   */
  public sortExtraColumns(colname) {
    let sortingColumns = ['vendor_name', 'document_amount', 'document_number'];
    return sortingColumns.includes(colname) ? colname : false;
  }

  /**
   * Return CSS Classes for the columns of the table
   */
  public getDynamicClassExtraColumn(colname) {
    let dynamicClass = '';
    dynamicClass = this.sortExtraColumns(colname) ? 'sort' : '';
    dynamicClass =
      this.data.order_by === colname && this.data.desc === 1
        ? dynamicClass + ' sort--desc'
        : dynamicClass;
    dynamicClass =
      this.data.order_by === colname && this.data.desc === 0
        ? dynamicClass + ' sort--asc'
        : dynamicClass;
    return dynamicClass;
  }

  // TODO this method could be refactored with something like column.name for
  // all titles, to do that, the getColumns() method for each table should be
  // edited
  /**
   * This function returns the correct text for the [title] attr
   */
  public rowTitle(title, row?: any) {
    if (
      [undefined, null].includes(title) ||
      (title.projectid && title.projectname === undefined) ||
      title instanceof Array ||
      row?.statusmessage
    ) {
      return '';
    } else if (typeof title === 'object') {
      if (title.rolename) {
        return title.rolename;
      } else if (title.username) {
        return title.username;
      } else if (title.projectname) {
        return title.projectname;
      } else if (title.value) {
        return title.value;
      } else if (title.datamodeldisplayname) {
        return title.datamodeldisplayname;
      } else if (title.querySheetName) {
        return title.querySheetName
      }
    }
    return title;
  }

  /**
   * Gets the class of the column depending on the useEllipsis
   * @param col Column
   * @returns Class name
   */
  public getColumnClass(col: any): string {
    return `${this.useEllipsis ? 'ellipsis-text ' : ''}${col.class}`;
  }
}
