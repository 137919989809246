import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormGroupName
} from '@angular/forms';
import { ValidationService } from '../common-components/validation.service';
import { ExtractionService } from '../extraction-components/extraction.service';
import { BusinessService } from '../business-components/business.service';
import { PreliminaryService } from '../preliminary-components/preliminary.service';
import { CatalogService } from '../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { TrainableService } from '../../../trainable-datamodels/trainable/trainable.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ChangeDetectorRef } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class CheckListComponent implements OnInit {
  public expandedField = [];
  public errorscheckingSelect: any = [];
  formTest: FormGroup = new FormGroup({
    checklist_name: new FormControl('', Validators.required),
    checklist_groups: new FormArray([
      new FormGroup({
        validation: new FormControl('', Validators.required),
        checklist: new FormArray([
          new FormGroup({
            checkmessage: new FormControl('', Validators.required),
            errorCategory: new FormControl('', Validators.required),
            errorComment: new FormControl('', Validators.required)
          })
        ])
      })
    ])
  });

  public isNew: boolean;
  public routeSub: Subscription;
  public loadingErrors: boolean = true;
  public subscriptionErrors: Subscription;
  private isSaveButtonActive: boolean = false;
  public loadingSave: boolean = false;
  public tenantName: any;
  public checklistEdit: any;
  public checklists: any = [];
  public loadingChecklist: boolean = true;
  public subscriptionChecklist: Subscription;

  constructor(
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    public globalService: GlobalService,
    private translate: TranslatePipe,
    private modalService: NgbModal,
    private trainableService: TrainableService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.tenantName = this.authService.getTenantName();
  }

  ngOnInit() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      const checklistid = params.get('id');
      if (!checklistid) {
        this.isNew = true;
      } else {
        this.isNew = false;
      }
    });
    this.getChecklistsData();
    this.getErrors();

    setTimeout(() => {
      this.formTest.valueChanges.subscribe((changedObj: any) => {
        this.isSaveButtonActive = this.formTest.valid;
      });
    }, 10);
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  /**
   * Retrive all checklist errors
   */
  public getErrors() {
    this.errorscheckingSelect = this.globalService.getErrorsCheckList();
    if (
      this.errorscheckingSelect.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.loadingErrors = true;
      this.subscriptionErrors = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.errorscheckingSelect = this.globalService.getErrorsCheckList();
          this.fillCheckListData();
          this.loadingErrors = false;
        });
    } else {
      this.fillCheckListData();
      this.loadingErrors = false;
    }
  }

  /**
   * Toggle specific validation expanded
   * @param index field index number
   */
  private toggleExpandedField(index: number) {
    const index_element = this.expandedField.indexOf(index);
    if (index_element >= 0) {
      this.expandedField.splice(index_element, 1);
    } else {
      this.expandedField.push(index);
    }
  }

  /**
   * Check if specific validation is expanded
   * @param index field index number
   */
  private isExpandedField(index: number) {
    return this.expandedField.indexOf(index) !== -1;
  }

  /**
   * Add checklist of the specific validation
   *
   */
  public addCheckList(index) {
    const checklists = this.formTest
      .get('checklist_groups')
      ['controls'][index].get('checklist') as FormArray;

    const control = new FormGroup({
      checkmessage: new FormControl('', Validators.required),
      errorCategory: new FormControl('', Validators.required),
      errorComment: new FormControl('', Validators.required)
    });
    control.get('checkmessage').markAsTouched();
    control.get('errorCategory').markAsTouched();
    control.get('errorComment').markAsTouched();

    checklists.push(control);
  }

  /**
   * Delete checklist of the specific validation
   *
   */
  public removeCheckList(groupindex, checklistindex) {
    const form = this.formTest
      .get('checklist_groups')
      ['controls'][groupindex].get('checklist') as FormArray;

    if (form.length > 1) {
      form.removeAt(checklistindex);
    }
  }

  /**
   * Retrive all checklists
   */
  public getChecklistsData() {
    this.checklists = this.globalService.getChecklists();
    if (
      this.checklists.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.loadingChecklist = true;
      this.subscriptionChecklist = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.checklists = this.globalService.getChecklists();
          this.loadingChecklist = false;
        });
    }
  }

  /**
   * Fill CheckListData
   *
   */
  fillCheckListData() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      const checklistid = params.get('id');
      if (checklistid) {
        this.checklistEdit = this.checklists.find(
          checklist => checklist.id === +checklistid
        );
      }

      if (this.checklistEdit) {
        this.formTest.get('checklist_groups')['controls'].splice(0, 1);
        this.formTest.controls['checklist_name'].setValue(
          this.checklistEdit.name
        );

        this.checklistEdit.groups.forEach((group, index) => {
          const checklists = new FormArray([]);
          group.items.forEach(item => {
            const control = new FormGroup({
              id: new FormControl(item.id),
              checkmessage: new FormControl(item.name, Validators.required),
              errorCategory: new FormControl(
                item.error_type,
                Validators.required
              ),
              errorComment: new FormControl(
                item.error_comment,
                Validators.required
              )
            });
            control.get('checkmessage').markAsTouched();
            control.get('errorCategory').markAsTouched();
            control.get('errorComment').markAsTouched();

            checklists.push(control);
          });
          const groups = this.formTest.get('checklist_groups') as FormArray;
          groups.push(
            new FormGroup({
              id: new FormControl(group.id),
              validation: new FormControl(group.name, Validators.required),
              checklist: checklists
            })
          );
        });
        this.formTest.get('checklist_groups')['controls'].forEach(group => {
          group.get('validation').valueChanges.subscribe((groupChange: any) => {
            group.removeControl('id');
          });
        });
        this.formTest.get('checklist_groups')['controls'].forEach(group => {
          group.get('checklist')['controls'].forEach(item => {
            item.valueChanges.subscribe((itemChange: any) => {
              if (item.get('id')) {
                item.removeControl('id');
              }
            });
          });
        });
      }
    });
    //mark as touched
    this.formTest.get('checklist_name').markAllAsTouched();
    this.formTest.get('checklist_groups').markAllAsTouched();

    //Expand all the fields
    let iterate = 0;
    this.formTest.get('checklist_groups')['controls'].forEach(group => {
      this.toggleExpandedField(iterate);
      iterate++;
    });
    this.isSaveButtonActive = false;
  }

  /**
   * Add Specific validation (CheckList)
   *
   */
  public addField() {
    const groups = this.formTest.get('checklist_groups') as FormArray;
    const group = new FormGroup({
      validation: new FormControl('', Validators.required),
      checklist: new FormArray([
        new FormGroup({
          checkmessage: new FormControl('', Validators.required),
          errorCategory: new FormControl('', Validators.required),
          errorComment: new FormControl('', Validators.required)
        })
      ])
    });
    groups.push(group);
    group.get('validation').markAsTouched();
    group.get('checklist')['controls'].forEach(checklistgroup => {
      checklistgroup.controls.checkmessage.markAsTouched();
      checklistgroup.controls.errorCategory.markAsTouched();
      checklistgroup.controls.errorComment.markAsTouched();
    });
  }

  /**
   * Save current checklist
   */
  public saveChecklist() {
    const postMessage = this.isNew
      ? this.translate.transform('checklist.create')
      : this.translate.transform('checklist.update');
    this.loadingSave = true;

    if (!this.isNew) {
      this.formTest.addControl('id', new FormControl(this.checklistEdit.id));
    }
    this.router.navigate([`/${this.tenantName}`, 'datamodels-validations']);
    this.apiService
      .post(
        'checklists',
        this.formTest.getRawValue(),
        postMessage,
        true,
        {},
        true
      )
      .subscribe(
        newData => {
          this.loadingSave = false;
          this.router.navigate([
            `/${this.tenantName}`,
            'datamodels-validations'
          ]);
        },
        () => {
          this.loadingSave = false;
        }
      );
  }
}
