import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../modals/delete/delete.component';
import { Subscription, concat, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/models/pagination';
import * as moment from 'moment';
import { AssignReviewerComponent } from '../modals/assign-reviewer/assign-reviewer.component';
import { CreateConsolidatedComponent } from '../modals/create-consolidated/create-consolidated.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { CognitiveSearchService } from 'src/app/pages/query/cognitive-search.service';
import { ValidationSearchService } from '../validations-filter/validation-search.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit, OnDestroy {
  @Input() paginationType: string;
  @Input() params: {} = [];
  @Input() invalidStatusNames: string[];
  @Input() showChildren: boolean;
  @Input() enableMedicFeatures: boolean = false;
  @Input() showFilterOnInit: boolean = false;

  @Output() newItemEvent = new EventEmitter<string>();

  // General variables
  public user: any = {};
  public showValidations: boolean = false;
  public showReviewFlows: boolean = false;
  public paginationId: number;

  // Filter variables
  public filters: any = [];
  public labelFilters: any[];
  public datamodelList: any[];
  public statusList: any[];
  public showFilters: boolean = false;
  public assignedToFilter: boolean = false;
  public projectFilter: boolean = false;
  public totalDocuments: number = 0;

 //Property to emit filter to delete in the validations component
 public filterEvent: Subject<string> = new Subject<string>();

  // Responsive table variables
  public loading: boolean = true;
  public backgroundLoading: boolean;
  private pagination: PaginationModel;
  private validationPaginations: string[] = [
    'documentvalidationsassigned',
    'documentvalidations'
  ];
  public columns: any[];
  public settingTable: any;
  public rows: {};
  public extraColumns: string[] = [];

  public analysisId: number;

  // Preview current selection on pills
  public previewSelectionOnAttribute: String;

  // Validation variables
  public EYDecisionTypes: any[];
  public validationTypes: any[];
  public usersGroup: any[];
  public reviewersGroupsTypes: any[];
  public subscriptionReviewers: Subscription;

  // Document to consolidate variables
  @Output() refreshTables = new EventEmitter();
  public responseTableData = {};
  public consolidatedtypeid: number;
  public consolidatetypename: string;
  public projectid: number;
  public requiredinformation = '';
  public countItemsSelected: number;
  public itemsSelected = [];
  public consolidations: any[];
  public hasDocumentToSelect: boolean = false;
  public allowedDocumentsIds = [];

  // Cognitive search on documents variables
  public formSerialized: any;
  public _documentStatus: any;
  public _userPermissions: any;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private linkService: LinkService,
    private global: GlobalService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    public cognitiveService: CognitiveSearchService,
    public validationSearchService: ValidationSearchService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.user = authService.getLoggedInUser();
    this._documentStatus = this.global.getDocumentStatusConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.showReviewFlows = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
  }

  ngOnInit() {
    this.statusList = this.global.getGlobalStatus();
    this.datamodelList = Array.from(this.global.getDatamodels().values());

    if (this.showValidations) {
      this.EYDecisionTypes = this.global.getDecisionTypes();
      this.reviewersGroupsTypes = this.global.getReviewers();
      this.validationTypes = this.global.getValidationTypes();
      this.consolidations = this.global.getConsolidations();
      if (
        (this.reviewersGroupsTypes.length === 0 ||
          this.validationTypes.length === 0) &&
        this.datamodelList.length === 0 &&
        !this.global.passedWatcherDatamodels &&
        !this.global.passedWatcherUtils
      ) {
        this.subscriptionReviewers = concat(
          this.global.watchDataModels(),
          this.global.watchUtils()
        ).subscribe(() => {
          this.reviewersGroupsTypes = this.global.getReviewers();
          this.validationTypes = this.global.getValidationTypes();
          this.statusList = this.global.getGlobalStatus();
          this.consolidations = this.global.getConsolidations();
          this.datamodelList = Array.from(this.global.getDatamodels().values());
          this.initLoadingPage();
        });
      } else if (
        (this.reviewersGroupsTypes.length === 0 ||
          this.validationTypes.length === 0) &&
        !this.global.passedWatcherUtils
      ) {
        this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
          this.reviewersGroupsTypes = this.global.getReviewers();
          this.validationTypes = this.global.getValidationTypes();
          this.statusList = this.global.getGlobalStatus();
          this.consolidations = this.global.getConsolidations();
          this.initLoadingPage();
        });
      } else if (
        this.datamodelList.length === 0 &&
        !this.global.passedWatcherDatamodels
      ) {
        this.subscriptionReviewers = this.global
          .watchDataModels()
          .subscribe(() => {
            this.datamodelList = Array.from(
              this.global.getDatamodels().values()
            );
            this.initLoadingPage();
          });
      } else {
        this.initLoadingPage();
      }
    } else {
      if (
        this.statusList.length === 0 &&
        this.datamodelList.length === 0 &&
        !this.global.passedWatcherDatamodels &&
        !this.global.passedWatcherUtils
      ) {
        this.subscriptionReviewers = concat(
          this.global.watchDataModels(),
          this.global.watchUtils()
        ).subscribe(() => {
          this.datamodelList = Array.from(this.global.getDatamodels().values());
          this.statusList = this.global.getGlobalStatus();
          this.initLoadingPage();
        });
      } else if (
        this.datamodelList.length === 0 &&
        !this.global.passedWatcherDatamodels
      ) {
        this.subscriptionReviewers = this.global
          .watchDataModels()
          .subscribe(() => {
            this.datamodelList = Array.from(
              this.global.getDatamodels().values()
            );
            this.initLoadingPage();
          });
      } else if (
        this.statusList.length === 0 &&
        !this.global.passedWatcherUtils
      ) {
        this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
          this.statusList = this.global.getGlobalStatus();
          this.initLoadingPage();
        });
      } else {
        this.initLoadingPage();
      }
    }

    if (this.showFilterOnInit) {
      this.showFilters = true;
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  public initLoadingPage() {
    this.loading = true;
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    this.setTableType();
    if (this.paginationType === 'documentstoconsolidate') {
      this.global
        .getDocumentsToConsolidate(this.projectid, this.consolidatedtypeid)
        .then(res => {
          this.allowedDocumentsIds = res;
          this.hasDocumentToSelect = this.allowedDocumentsIds.length > 0;
          this.getTableContent();
        });
    } else {
      this.getTableContent();
    }
  }

  public getTableContent() {
    this.getColumns();
    this.getSettingTable();
    this.getDocuments();
  }

  public onRemoveItemEvent(event) {
    if (event.length > 0) {
      this.itemsSelected = event;
    } else {
      this.itemsSelected = event.items;
      this.changeSelectedItems(event.index, event.data);
    }
  }

  public onChangeSelectionEvent(event) {
    this.changeSelectedItems(event.row, event.data);
  }

  /**
   * Get documents from storage or database
   */
  public getDocuments() {
    const documentsPage = this.paginationService.getPage(this.paginationType);
    const defaultPage = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    const dataId = this.hasDataId(documentsPage);
    if (
      documentsPage &&
      documentsPage.data.length &&
      documentsPage.page === 1 &&
      documentsPage.order_by === defaultPage.order_by &&
      documentsPage.desc === defaultPage.desc &&
      dataId
    ) {
      // If documents are loaded, show them and hide spinner
      this.rows = documentsPage;
      this.loading = false;
      this.onBackgroundLoadingChange(true);
    } else {
      // If documents aren't loaded, show spinner
      this.loading = true;
    }
    // Always request documents from db
    if (this.validationPaginations.includes(this.paginationType)) {
      this.filters = this.validationSearchService.getPreviousSearch(
        this.paginationType
      );
      if (this.filters === undefined || this.filters.length === 0) {
        this.getDocumentsFromDB();
      }
    }
    if (!this.validationPaginations.includes(this.paginationType)) {
      this.getDocumentsFromDB();
    }
  }

  private hasDataId(documentsPage) {
    let result = false;
    if (
      this.paginationType === 'documents' &&
      documentsPage &&
      documentsPage.data.length > 0
    ) {
      result = documentsPage.data[0].analysis.analysisid === this.analysisId;
    }
    return result;
  }

  /**
   * Function that returns the inner logic text value
   */
  public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  /**
   * Serialize the data between the database and the view table
   */
  private serializeData(data) {
    data.data.map(document => {
      if (this.showValidations) {
        switch (document.reviewrecommended) {
          case true:
            document['displayRecommended'] = 'Recommended';
            break;
          case false:
            document['displayRecommended'] = 'Not recommended';
            break;
          case null:
            document['displayRecommended'] = 'N/A';
            break;
          default:
            break;
        }
        if (document.approvals) {
          const currentApproval = document.approvals.find(
            a => a.currentapproval === true
          );
          if (currentApproval) {
            if (
              this.reviewersGroupsTypes.find(
                g => g.groupid === currentApproval.groupid
              )
            ) {
              document['groupName'] = this.reviewersGroupsTypes.find(
                g => g.groupid === currentApproval.groupid
              ).groupname;
            }
            if (currentApproval.assigned) {
              document['assignedToName'] = currentApproval.assigned.username;
            }
          }
        }
      }
      // Serializer to extra_columns data
      if (document.extra_columns) {
        // Array of the extra columns objects from api
        const array = Object.keys(document.extra_columns).map(k => {
          return { name: k, value: document.extra_columns[k] };
        });
        array.forEach(col => {
          // If the element has value on the column and that column is not
          // in the extra columns array, we add it the column name.
          if (
            col.value &&
            col.value.trim() !== '' &&
            !this.extraColumns.includes(col.name)
          ) {
            this.extraColumns.push(col.name);
          }
        });
      }
    });
  }

  /**
   * Get documents from database
   */
  public getDocumentsFromDB() {
    const params = this.setParams();
    const endpoint =
      this.paginationType === 'cognitivesearch'
        ? 'cognitivesearch/'
        : 'documents/';
    this.apiService.get(endpoint, params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        newTab: {};
        order_by: string;
        desc: number;
        data: any[];
      }) => {
        this.totalDocuments = data.total_elements;
        this.serializeData(data);
        if (this.paginationType === 'external') {
          data.data.forEach(d => {
            if (d.reviewrecommended == null) {
              d['status'] = 'In Progress';
              d['status_data'] = { class: 'in-progress', value: 'In Progress' };
            } else {
              if (d['status'].toLowerCase().includes('failure')) {
                d['status'] = 'Failure';
                d['status_data'] = { class: 'failure', value: 'Failure' };
              } else {
                d['status'] = 'Success';
                d['status_data'] = { class: 'success', value: 'Success' };
              }
            }
          });
        }
        data.newTab = {
          type: 'link-fa-icon',
          header: 'validation.documentValidation.action',
          link: 'external',
          title: 'responsiveTable.newTab',
          class: 'td-icon align-right',
          clickLink: this.goToDocumentNewTab.bind(this),
          namefunc: rowData => {
            return '<i class="fa fa-external-link"></i>';
          }
        };
        this.rows = data;
        this.paginationService.setPage(this.paginationType, data);
        this.pagination = this.paginationService.setPagination(
          this.paginationType,
          {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          }
        );
        this.loading = false;
        this.onBackgroundLoadingChange(false);
      },
      () => {
        this.loading = false;
        this.onBackgroundLoadingChange(false);
      }
    );
  }

  /**
   * Go to the project with the project information pass by params
   */
  public goToDocument(document, event = null) {
    if (event) {
      event.stopPropagation();
    }
    this.paginationType === 'external'
      ? this.linkService.goToExternalDocument(document)
      : this.linkService.goToDocument(document);
  }

  /**
   * Go to the document with the document information pass by params
   */
  public goToDocumentNewTab(document) {
    this.linkService.goToDocument(document, true);
  }

  /**
   * Return loaded columns with the information of each column of
   * the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectAnalysis.documentName',
        name: 'documentdisplayname',
        type: 'link-document',
        clickLink: this.goToDocument.bind(this),
        orderBy: 'documentdisplayname',
        class: 'ellipsis-text big-size-column',
        title: 'documentdisplayname',
        secname: 'documentid',
        thirdname: 'datamodel',
        thirdnamesec: 'datamodeldisplayname',
        fourthname: 'createdbyuser',
        fourthnamesec: 'username'
      },

      {
        header: 'projectDocument.status',
        name: 'status_data',
        type: 'one-span-status',
        orderBy: 'statusid',
        title: 'status_data',
        second: 'value'
      },
      {
        type: 'aging-date',
        header: 'validation.validationToReview.aging',
        orderBy: 'createddate',
        name: 'createddate',
        format: 'lll',
        class: 'medium-size-column'
      },
      {
        header: 'Tags',
        name: 'tags',
        tags: this.getTagsIcon.bind(this),
        orderBy: 'tagname',
        type: 'tags',
        class: ''
      }
    ];
    this.setAdditionalColumns();
  }

  /**
   * Return loaded SettingTable with the general settings for
   * the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'documentid',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getDocumentsFromDB.bind(this),
      renderFunction: this.shouldRender.bind(this),
      extraColumns: this.extraColumns,
      clickLink: this.goToDocument.bind(this),
      responsiveTitle: {
        label: 'documentdisplayname',
        value: 'createddate',
        formatDate: 'fromNow',
        status: true
      }
    };
    this.setAdditionalOptions();
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldRender(row) {
    let shouldRender = true;
    this.invalidStatusNames.forEach(statusName => {
      if (this._documentStatus[statusName] === row.status_data.class) {
        shouldRender = false;
      }
    });
    return shouldRender;
  }

  /**
   * Set all the global variables
   * for show the proper list of docs
   * depending on the parent component
   */
  public setTableType() {
    switch (this.paginationType) {
      case 'documents':
        this.analysisId = this.params['analysisId'];
        break;
      case 'documentstoconsolidate':
        this.countItemsSelected = 0;
        this.projectid = this.params['projectid'];
        this.consolidatedtypeid = this.params['consolidatedtypeid'];
        this.requiredinformation = this.params['requiredinformation'];
        this.consolidatetypename = this.requiredinformation;
        break;
      case 'cognitivesearch':
        this.formSerialized = this.params['formSerialized'];
        break;
      default:
        break;
    }
  }

  /**
   * Function to set the params to
   * pass to the API call depending
   * on the parent component
   */
  private setParams() {
    switch (this.paginationType) {
      case 'documents':
        return {
          ...this.pagination,
          ...this.filters,
          analysisid: this.analysisId
        };
      case 'documentvalidationsassigned':
        return {
          ...this.pagination,
          ...this.filters,
          assigned: 1,
          page_size: 5,
          validations: 1
        };
      case 'documentvalidations':
        return {
          ...this.pagination,
          ...this.filters,
          validations: 1,
          unassigned: 1
        };
      case 'documentstoconsolidate':
        return {
          ...this.pagination,
          ...this.filters,
          consolidatedtypeid: this.consolidatedtypeid,
          projectid: this.projectid
        };
      case 'external':
        return { ...this.pagination, ...this.filters };
      case 'cognitivesearch':
        const param = { ...this.formSerialized, ...this.pagination };
        return { search_data: JSON.stringify(param) };
      default:
        return { ...this.pagination };
    }
  }

  /**
   * Function to add differents custom options
   * to the setting table depending on wich
   * component is the parent
   */
  private setAdditionalOptions() {
    switch (this.paginationType) {
      case 'documents':
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.deleteSelectedDocuments.bind(this),
            class: 'fa fa-trash',
            title: 'projectDocument.deleteSelected'
          }
        ];
        this.settingTable['actionsOnResponsive'] = [
          {
            click: this.deleteDocument.bind(this),
            class: 'fa fa-trash'
          }
        ];
        this.settingTable['isSelected'] = this.isSelected.bind(this);
        this.settingTable['hasSelectedFunctions'] = 'true';
        this.settingTable['paginationClass'] = 'relative-pag';
        this.settingTable['checkerItems'] =
          this.getItemsToConsolidate.bind(this);
        this.previewSelectionOnAttribute = 'documentdisplayname';
        break;
      case 'documentvalidations':
        this.settingTable['paginationClass'] = 'relative-pag';
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['hasSelectedFunctions'] = 'true';
        this.settingTable['isSelected'] = this.isSelected.bind(this);
        /*this.settingTable[
          'changeSelectedItems'
        ] = this.changeSelectedItems.bind(this);*/
        this.settingTable['checkerItems'] =
          this.getItemsToConsolidate.bind(this);
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.assignToReviewers.bind(this),
            class: 'fa fa-user-plus',
            title: 'validation.validationToReview.assignToOtherUser'
          }
        ];
        this.settingTable['actionsOnResponsive'] = [
          {
            click: this.assignToReviewersSimpleDoc.bind(this),
            class: 'fa fa-user-plus'
          }
        ];
        this.previewSelectionOnAttribute = 'documentdisplayname';
        break;
      case 'documentvalidationsassigned':
        this.settingTable['paginationClass'] = 'relative-pag';
        break;
      case 'documentstoconsolidate':
        this.settingTable['paginationClass'] = 'relative-pag';
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['hasSelectedFunctions'] = 'true';
        this.settingTable['changeSelectedItems'] =
          this.changeSelectedItems.bind(this);
        this.settingTable['checkerItems'] =
          this.getItemsToConsolidate.bind(this);
        this.settingTable['isSelected'] = this.isSelected.bind(this);
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.goToConsolidateDocuments.bind(this),
            text:
              'consolidation.actionToConsolidate.' + this.consolidatetypename,
            title:
              'consolidation.actionToConsolidate.' + this.consolidatetypename,
            minimumItems: 1
          }
        ];
        this.previewSelectionOnAttribute = 'documentdisplayname';
        break;
      default:
        break;
    }
  }

  /**
   * Function to switch the permissions
   * between the differents parent components
   */
  private getAdditionalPerm() {
    switch (this.paginationType) {
      case 'documents':
        return (
          this.user.isadmin ||
          this.authService.hasPermission(this._userPermissions.DELETE_DOCUMENTS)
        );
      case 'documentvalidations':
        return (
          this.user.isadmin ||
          this.authService.hasPermission(
            this._userPermissions.ASSIGN_USERS_TO_DOCUMENTS
          )
        );
      case 'documentstoconsolidate':
        return (
          this.user.isadmin ||
          this.authService.hasPermission(
            this._userPermissions.CREATE_OUTPUT_FILES
          )
        );
      default:
        return true;
    }
  }

  /**
   * Function to add differents columns
   * to the table depending on which
   * component is the parent
   */
  private setAdditionalColumns() {
    if (this.showValidations) {
      let validationsModule: any[] = [
        ...(
          this.user.isadmin ?
            [
              {
                header: 'validation.validationToReview.EYDecision',
                name: 'displayRecommended',
                orderBy: 'reviewrecommended',
                class: 'small-size-column'
              }
            ]
            : []
        ),
        {
          header: 'validation.validationToReview.reviewGroup',
          name: 'groupName',
          optional: 'true',
          class: 'small-size-column',
          orderBy: 'groupid'
        }
      ];

      if (this.user.isadmin && this.paginationType === 'documents') {
        this.assignedToFilter = true;
        const assignedToColumn = {
          header: 'validation.validationToReview.assignedTo',
          name: 'assignedToName',
          optional: 'true',
          class: 'small-size-column',
          title: 'assignedToName'
        };
        validationsModule.push(assignedToColumn);
      }

      if (this.user.isadmin && this.paginationType === 'documentvalidations') {
        this.assignedToFilter = true;
        const assignedToColumn = {
          header: 'validation.validationToReview.assignedTo',
          name: 'assignedToName',
          optional: 'true',
          class: 'ellipsis-text medium-size-column',
          title: 'assignedToName'
        };
        validationsModule.push(assignedToColumn);
      }
      // If it is in the validations lists view
      if (
        ['documentvalidations', 'documentvalidationsassigned'].includes(
          this.paginationType
        )
      ) {
        this.projectFilter = true;
        const validationListColumn = {
          header: 'projectList.projectName',
          name: 'project',
          second: 'projectname',
          orderBy: 'projectname',
          class: 'ellipsis-text medium-size-column',
          title: 'project'
        };
        validationsModule.push(validationListColumn);
      }
      if (this.paginationType === 'external') {
        validationsModule = validationsModule.filter(
          vm => vm['name'] !== 'groupName'
        );
        this.columns = this.columns.filter(
          column => column.name !== 'createdbyuser' && column.name !== 'tags'
        );
      }
      this.columns.splice(3, 0, ...validationsModule);
    }

    if (this.showChildren === true) {
      // orderBy is not configured.
      const childrenColumn = {
        header: 'projectList.children',
        name: 'splitteddocumentlist',
        second: 'length',
        class: 'align-center small-size-column',
        orderBy: 'splitteddocumentlist',
        optional: 'true'
      };
      this.columns.splice(this.columns.length - 1, 0, childrenColumn);
    }

    if (this.showReviewFlows) {
      const reviewFlowColumn = [
        {
          type: 'review-paused',
          header: 'validation.validationToReview.isReviewPaused',
          orderBy: 'isreviewpaused',
          name: 'isreviewpaused',
          class: 'medium-size-column',
          maxDays: 'maxreviewdays',
          format: 'lll',
          date: 'reviewduedate'
        }
      ];
      this.columns.splice(4, 0, ...reviewFlowColumn);
    }
  }

  /**
   * Handle loading data status
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  /**
   * Check if user has edit permissions
   */
  public hasEditPermissions() {
    return this.user && this.getAdditionalPerm();
  }

  /**
   * Return CSS Class for One tag icon or some tags icon
   */
  public getTagsIcon(tags: any) {
    return tags.length > 1
      ? 'fa fa-tags'
      : tags.length === 1
        ? 'fa fa-tag'
        : '';
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters: any) {
    this.onBackgroundLoadingChange(true);
    this.cleanAllSelectedItems();
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    if (this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }
    if (!this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }
    this.filters = {
      documentid:
        filters['documentid'] !== undefined ? filters['documentid'] : '',
      documentdisplayname:
        filters['documentdisplayname'] !== undefined
          ? filters['documentdisplayname']
          : '',
      documentdisplayname_logic:
        filters['documentdisplayname_logic'] !== undefined
          ? filters['documentdisplayname_logic']
          : '',
      projectname:
        filters['projectname'] !== undefined
          ? filters['projectname']
          : '',
      projectname_logic:
        filters['projectname_logic'] !== undefined
          ? filters['projectname_logic']
          : '',
      datamodeldisplayname:
        filters['datamodeldisplayname'] !== undefined
          ? filters['datamodeldisplayname']
          : '',
      datamodelid:
        filters['datamodelId'] !== undefined
          ? filters['datamodelId']
          : '',
      createdbyname:
        filters['createdByName'] !== undefined
          ? filters['createdByName']
          : '',
      tagname: filters['tagName'] !== undefined
        ? filters['tagName']
        : '',
      assigned_to_name:
        filters['assignedTo'] !== undefined
          ? filters['assignedTo']
          : '',
      review_group:
        filters['reviewgroup'] !== undefined
          ? DocumentsTableComponent.getFilterValue(filters['reviewgroup'])
          : '',
      reviewrecommended:
        filters['recommended'] !== undefined
          ? DocumentsTableComponent.getFilterValue(filters['recommended'])
          : '',
      statusid:
        filters['statusId'] !== undefined
          ? filters['statusId']
          : '',
      isreviewpaused:
        filters['isreviewpaused'] !== undefined
          ? filters['isreviewpaused']
          : '',
      reviewduedate:
        filters['reviewduedate'] !== undefined
          ? filters['reviewduedate']
          : '',
      start_date:
        filters['start_date'] !== undefined
          ? this.formatDate(filters['start_date'])
          : '',
      end_date:
        filters['end_date'] !== undefined
          ? this.formatDate(filters['end_date'])
          : ''
    };

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodeldisplayname':
            return {
              name: 'datamodeldisplayname',
              value: filters['datamodeldisplayname']
            };
          case 'datamodelid':
            return {
              name: 'datamodelname',
              value: filters['datamodelname']
            };
          case 'createdbyname':
            return {
              name: 'createdByName',
              value: filters['createdByName']
            };
          case 'review_group':
            return {
              name: 'review_group_name',
              value: filters['review_group_name']
            };
          case 'reviewrecommended':
            return {
              name: 'reviewrecommended_name',
              value: filters['reviewrecommended_name']
            };
          case 'statusid':
            return {
              name: 'status_name',
              value: filters['status_name']
            };
          case 'isreviewpaused':
            let value: string;
            filters['isreviewpaused'] === 'true' ? value = 'Yes' : value = 'No';
            return {
              name: 'isreviewpaused',
              value: value
            };
          default:
            return {
              name: f,
              value: this.filters[f]
            };
        }
      });

    this.getDocumentsFromDB();
  }

  /**
   * Get filter value suitable to be sent as param to backend.
   */
  private static getFilterValue(
    value: string | number | string[] | number[]
  ): string {
    if (typeof value === 'object') {
      return (value as any[]).join(',');
    } else {
      return String(value);
    }
  }

  /**
   * Format Date object to yyyy-mm-dd
   */
  public formatDate(date) {
    return moment(new Date(date)).format('YYYY-MM-DD');
  }

  // ------- Start function block of regular documents list --------
  /**
   * Load modal to confirm document(s) removal
   */
  private deleteDocumentModal(documents) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'document',
      notAllowed: documents.length < 1
    };
    const documentsids = documents.map(doc => {
      return doc.documentid;
    });
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: documentsids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.cleanAllSelectedItems();
    this.apiService
      .post('documents/delete', requestbody, 'projectDocument.delete')
      .subscribe(
        () => {
          // Remove documents from view while request from db
          this.rows = this.rows['data'].filter(
            el => requestbody.ids.indexOf(el.documentid) < 0
          );
          this.loading = false;
          this.pagination = this.paginationService.changePagination(
            'documents',
            'page',
            1
          );
          this.getDocumentsFromDB();
        },
        error => {
          this.loading = false;
        }
      );
  }

  /**
   * Delete single document
   */
  public deleteDocument(item) {
    this.deleteDocumentModal([item.documentid]);
  }

  /**
   * Delete multiple documents
   */
  public deleteSelectedDocuments(selectedItems) {
    this.deleteDocumentModal(selectedItems);
  }
  // ------- End function block of regular documents list --------

  // ------- Start function block of validation documents lists --------
  /**
   * Load modal to assign a user for review the selected validations
   */
  public assignToReviewersSimpleDoc(document) {
    this.assignToReviewers(document);
  }
  /**
   * Load modal to assign a user for review the selected validations
   */
  public assignToReviewers(documentsToAssign) {
    // Get approvals ids
    let approvalids = [];
    documentsToAssign.forEach(d => {
      const currentApproval = d.approvals.find(a => a.currentapproval === true);
      if (currentApproval) {
        approvalids = approvalids.concat(currentApproval.approvalid);
      }
    });
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
      windowClass: 'modal--large'
    };
    const options = {
      type: 'new',
      action: 'create',
      projectsids: documentsToAssign.map(d => d.project.projectid).join(',')
    };
    const modalWindowRef = this.modalService.open(
      AssignReviewerComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(assigneduser => {
      if (assigneduser) {
        this.onBackgroundLoadingChange(true);
        this.apiService
          .post(
            'documentreviewapproval/assignto',
            { approvalids, userid: assigneduser.assigneduser },
            'validation.validationToReview.assigned'
          )
          .subscribe(() => {
            this.global.emptyDocumentDetails();
            this.params['selfassigned'].emit();
            this.cleanAllSelectedItems();
            this.getDocumentsFromDB();
          });
      }
    });
  }

  public assignValidationToReviewers([validationid]) {
    this.assignToReviewers(validationid);
  }
  // ------- End function block of validation documents lists --------

  public cleanAllSelectedItems() {
    this.itemsSelected.forEach(item => {
      this.changeSelectedItems(item, this.rows);
    });
    this.itemsSelected = [];
  }

  // ------- Start function block of documents to consolidate lists --------
  /**
   * stored items selected in table to not lose it when paginate
   * @param index index of element
   * @param data all data array of current page
   */
  public changeSelectedItems(index, data) {
    let newAddition = false;
    if (data) {
      const index_page = data.data.indexOf(index);
      if (!this.responseTableData[data.page]) {
        this.responseTableData[data.page] = {};
      }
      if (this.responseTableData[data.page][index_page]) {
        delete this.responseTableData[data.page][index_page];
      } else {
        if (index_page >= 0) {
          newAddition = true;
          this.responseTableData[data.page][index_page] = data.data[index_page];
        }
      }
    }
    const items = [];

    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        if (
          JSON.stringify(this.responseTableData[number_page][number_item]) ==
          JSON.stringify(index) &&
          !newAddition
        ) {
          delete this.responseTableData[number_page][number_item];
        } else {
          items.push(this.responseTableData[number_page][number_item]);
        }
      }
    }
    this.countItemsSelected = items.length;
    this.itemsSelected = items;
    return items;
  }

  public getItemsToConsolidate() {
    const items = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        items.push(this.responseTableData[number_page][number_item]);
      }
    }
    return items;
  }

  /**
   * Check if is selected when paginate to tick checkbox or not
   * @param index index of element
   * @param data all data array of current page
   */
  public isSelected(index, data) {
    const index_page = data.data.indexOf(index);
    let isSelected = false;
    if (this.responseTableData[data.page]) {
      if (this.responseTableData[data.page][index_page]) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  public goToConsolidateDocuments() {
    this.consolidateDocuments(false);
  }

  private getDatamodelsNeeded() {
    if (this.consolidatetypename === 'pams_consolidated_aia') {
      return this.datamodelList.filter(dm =>
        ['AIA Contracts', 'Sub-AIA'].includes(dm.datamodeldisplayname)
      );
    }
    return;
  }

  public previousSearchWarning() {
    return (
      (!this.loading &&
        this.cognitiveService.getPreviousSearch() &&
        this.paginationType === 'cognitivesearch') ||
      (this.validationPaginations.includes(this.paginationType) &&
        Object.keys(
          this.validationSearchService.getPreviousSearch(this.paginationType)
        ).length > 0 &&
        this.validationSearchService.getHasBeenSearched(this.paginationType))
    );
  }

  public showDocumentsSelected() {
    return (
      !this.loading && this.countItemsSelected && this.countItemsSelected > 0
    );
  }

  /**
   * Action of consolidate 2 to n  documents
   */
  public consolidateDocuments(all: boolean) {
    const items = [];
    let document_ids = '';
    let documentsSelected = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        items.push(this.responseTableData[number_page][number_item]);
        document_ids +=
          this.responseTableData[number_page][number_item]['documentid'] + ',';
        documentsSelected.push(
          this.responseTableData[number_page][number_item]
        );
      }
    }

    if (all) {
      documentsSelected = this.allowedDocumentsIds;
      document_ids = this.allowedDocumentsIds.map(d => d.documentid).join(',');
    } else {
      document_ids = document_ids.substring(0, document_ids.length - 1);
    }
    const paramsModal = {
      consolidatedtypeid: this.consolidatedtypeid,
      projectid: this.projectid,
      document_ids: document_ids
    };
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: any = {};
    options.type = 'new';
    options.action = 'create';
    options.data = {
      requiredinformation: this.params['requiredinformation'],
      projectid: this.projectid,
      documentsSelected: documentsSelected,
      allDocuments: all,
      datamodelsNeeded: this.getDatamodelsNeeded()
    };

    const modalWindowRef = this.modalService.open(
      CreateConsolidatedComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      res => {
        paramsModal['consolidatedname'] = res['consolidatedname'];
        paramsModal['consolidateddata'] = res['consolidateddata'];
        if (res['ponumber']) {
          paramsModal['ponumber'] = res['ponumber'];
        }
        this.loading = true;
        this.apiService
          .post('consolidateddocuments', paramsModal, '')
          .subscribe(
            data => {
              this.countItemsSelected = 0;
              this.refreshTables.emit(true);
              this.cleanAllSelectedItems();
            },
            error => {
              this.loading = false;
            }
          );
      },
      () => { } // Handles promise rejection
    );
  }
  // ------- End function block of documents to consolidate lists --------

  public cleanIndividualFilter(filter: string){
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    
    if(filter === 'createdByName'){
      filter = filter.toLowerCase()
    }

    if(filter === 'datamodelname'){
      filter = 'datamodelid'
    }

    if(filter === 'reviewrecommended_name'){
      filter = 'reviewrecommended'
    }
    
    if(filter === 'review_group_name'){
      filter = 'review_group'
    }

    if(filter === 'status_name'){
      filter = 'statusid'
    }
    
    this.filterEvent.next(filter)
    this.filters[filter] = ''; 
    
    if(filter === 'documentdisplayname'){
      this.filters['documentdisplayname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'documentdisplayname_logic');
    }
    if(filter === 'projectname'){
      this.filters['projectname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'projectname_logic');
    }
    
    this.onBackgroundLoadingChange(true);
    this.getDocumentsFromDB();
  }
}
