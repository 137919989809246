import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { DocumentModel } from '../../../models/document.model';
import { ApiService } from 'src/app/services/api.service';
import { LinkService } from 'src/app/services/link.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ActivatedRoute } from '@angular/router';
import { PaginationModel } from '../../../models/pagination';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { AuthenticationService } from '../../../security/authentication.service';
import { Observable } from 'rxjs';
import { DocumentsTableComponent } from 'src/app/components/documents-table/documents-table.component';

@Component({
  selector: 'app-analysis-documents-view',
  templateUrl: './analysis-documents-view.component.html',
  styleUrls: ['./analysis-documents-view.component.scss']
})
export class AnalysisDocumentsViewComponent implements OnInit, OnDestroy {
  @Input() analysisId: number | string;
  @Input() addItemEvent: Observable<any>;
  @ViewChild(DocumentsTableComponent)
  private documentTable: DocumentsTableComponent;

  private newDocumentSub: any;
  public params: {};

  constructor() {}

  ngOnInit() {
    this.params = { analysisId: this.analysisId };
    this.newDocumentSub = this.addItemEvent.subscribe((document: any) => {
      this.documentTable.getDocuments();
    });
  }

  ngOnDestroy() {
    this.newDocumentSub.unsubscribe();
  }
}
