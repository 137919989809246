let lablesAndMessage = {};
export const setLablesAndMessage = (obj) => {
    lablesAndMessage = { ...obj }
}

export const isEmpty = (obj) => {
    if (obj && Array.isArray(obj)) {
        return obj.length === 0
    } else if (obj && typeof (obj) == 'object') {
        return Object.keys(obj).length === 0
    }
    return true;
}
export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const toFixed2Decimal = (floatValue) => Number.parseFloat(floatValue.toString().match(/^-?\d+(?:\.\d{0,0})?/)[0]);

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}
export const disableScrollOnViewerContainer = (flag) => {
    const thumbnailOverlay = document.getElementsByClassName('thumbnailOverlay');
    if (flag) {
        document.getElementById('viewerContainer').style.overflow='hidden'
        document.getElementById('zoom-in').classList.add('disabled')
        document.getElementById('zoom-out').classList.add('disabled')
        document.getElementById('zoomlevel').classList.add('disabled')
        document.getElementById('full-screen')?.classList.add('disabled')
        document.getElementById('full-screen-exit')?.classList.add('disabled')
        if (thumbnailOverlay && thumbnailOverlay.length > 0) {
            thumbnailOverlay[0]['style'].display = 'flex';
        }
    } else {
        document.getElementById('viewerContainer').style.overflow='auto'
        document.getElementById('zoom-in').classList.remove('disabled')
        document.getElementById('zoom-out').classList.remove('disabled')
        document.getElementById('zoomlevel').classList.remove('disabled')
        document.getElementById('full-screen')?.classList.remove('disabled')
        document.getElementById('full-screen-exit')?.classList.remove('disabled')
        if (thumbnailOverlay && thumbnailOverlay.length > 0) {
            thumbnailOverlay[0]['style'].display = 'none';
        }
    }
}
export const getCurrentBoxObject = (boxPositionData, feedbackData, qnaFeedback={}, manualFeedbackConstantFactor=1) => {
    const startCursor = getScaledCursor(boxPositionData.startCursor, manualFeedbackConstantFactor)
    const endCursor = getScaledCursor(boxPositionData.endCursor, manualFeedbackConstantFactor)
    const size = boxPositionData['size'].map(size => +(size * manualFeedbackConstantFactor).toFixed(3))
    const position = boxPositionData['position'].map(position => +(position * manualFeedbackConstantFactor).toFixed(3))
    const isAiBox = document.getElementById(`resizeID-${size[1]}-${size[0]}`) ? false : true;
    let isDeleted = false;
    if(feedbackData.isDeleted){
        isDeleted = true;
    }
    if (!isEmpty(qnaFeedback)) {
        qnaFeedback['startCursor'] = getScaledCursor(qnaFeedback['startCursor'], manualFeedbackConstantFactor)
        qnaFeedback['endCursor'] = getScaledCursor(qnaFeedback['endCursor'], manualFeedbackConstantFactor)
    }
    return {
        'w': size[1],
        'h': size[0],
        'x1': position[0],
        'y1': position[1],
        'x2': position[0] + size[1],
        'y2': position[1] + size[0],
        'pageNo': feedbackData['pageNum'],
        'saveButtonId': `saveButton-${position[0]}-${position[1]}`,
        'discardButtonId': `discardButton-${position[0]}-${position[1]}`,
        'copyButtonId': `copyButton-${position[0]}-${position[1]}`,
        'feedbackText': feedbackData['text'],
        isAiBox,
        'startCursor': startCursor,
        'endCursor': endCursor,
        'id': feedbackData.selectionId || feedbackData.id, // feedbackData.selectionId is the id of the feedback object and when user modifies the feedback multiple time and then deletes itx`,
        isDeleted,
        qnaFeedback
    }
}
export const getSelectionObject = (pageNo, answerId, operationType = 'insert') => {
    return {
        "operation": operationType,
        "USEModel": {
            "text": null,
            "boundingBox": {
                "startCursor": [0, 0, 0, 0],
                "endCursor": [0, 0, 0, 0],
                "position": [0, 0],
                "size": [0, 0]
            },
            "pageNum": pageNo
        },
        "robertaModel": {
            "answerId": answerId,
            "text": null,
            "boundingBox": {
                "startCursor": [0, 0, 0, 0],
                "endCursor": [0, 0, 0, 0],
                "position": [0, 0],
                "size": [0, 0]
            },
            "pageNum": pageNo
        }
    }
}

export const getConstantFactor = (scale, isManualFeedback) => {
    //https://stackoverflow.com/questions/48209623/pixels-to-pdf-coordinates
    //Please refer above link to understand why we multiply 72/300
    //dimension[0] is height & dimension[1] is width
    let constantFactor = 72 / 300;
    if (isManualFeedback) {
        constantFactor = 1;
    }
    const constantValue = 4 / 3;
    constantFactor = scale != 0.75 ? constantFactor * scale * constantValue : constantFactor
    return constantFactor
}
export const getBoundingBox = (position, dimention, isManualFeedback=false, scale=0.75, pageViewInfo=[]) => {
    //if block is for AI box and else block is for manual feedback
    if(pageViewInfo.length > 0){
        const [x, y, w, h] = pageViewInfo;
        const width = w - x;
        const height = h - y;
        const left = position[0] * width;
        const top = position[1] * height;
        const right = dimention[1] * width + left;
        const bottom = dimention[0] * height + top;  
        const factor = 4 / 3
        const bb = {
            x1: +(left * scale * factor).toFixed(3),
            y1: +(top * scale * factor).toFixed(3),
            x2: +(right * scale * factor).toFixed(3),
            y2: +(bottom * scale * factor).toFixed(3)
        }
        return bb;  
    }else{
        let constantFactor = isManualFeedback ? 1 : getConstantFactor(scale, isManualFeedback)
        const bb = {
            x1: +(position[0] * constantFactor).toFixed(3),
            y1: +(position[1] * constantFactor).toFixed(3),
            x2: +(position[0] * constantFactor + dimention[1] * constantFactor).toFixed(3),
            y2: +(position[1] * constantFactor + dimention[0] * constantFactor).toFixed(3)
        }
        return bb;
    }
}
const getTextWidth = (text, pageNumber, fontStyles = '10px sans-serif') => {
    const canvas = document.querySelector(`[data-page-number="${pageNumber}"]`).getElementsByTagName('canvas')[0]
    const context = canvas.getContext("2d");
    context.font = fontStyles;
    const metrics = context.measureText(text);
    return metrics.width;
}

export const getScaledCursor = (cursor, manualFeedbackConstantFactor, toDefaultScale=false) => {
    let copyObj = window['structuredClone'](cursor);
    Object.keys(copyObj).forEach(key => {
        copyObj[key] = toDefaultScale ? +(copyObj[key] / manualFeedbackConstantFactor).toFixed(3) : +(copyObj[key] * manualFeedbackConstantFactor).toFixed(3)
    })
    return { ...copyObj }
}

export const getModifiedManualFeedback = (manualFeedbacks, feedback, scale) => {
    const manualFeedbackConstantFactor = getConstantFactor(scale, true)
    let qnaFeedback = {}
    let semanticFeedback = {}
    const isAiBox = feedback.implicit == 'True' ? true : false;
    manualFeedbacks.find(manualFeedback => {
        if(manualFeedback['USEModel']
            && manualFeedback['USEModel'].implicit == 'True'
            && feedback.implicit == 'True') {
                qnaFeedback = { ...manualFeedback['robertaModel'] } 
                return true;
        }
        else if (manualFeedback['USEModel'] 
            && feedback.boundingBox
            && manualFeedback['USEModel'].boundingBox
            && manualFeedback['USEModel'].boundingBox.position[0] == feedback.boundingBox.position[0]
            && manualFeedback['USEModel'].boundingBox.size[0] == feedback.boundingBox.size[0]) {
                //This block is when semantic feedback is modified
                semanticFeedback = { ...manualFeedback['USEModel'] }
            if (manualFeedback['robertaModel']) {
                qnaFeedback = { ...manualFeedback['robertaModel'] }
            }
                return true;
        } else if (!manualFeedback['USEModel']
            && feedback.boundingBox
            && manualFeedback['robertaModel']
            && manualFeedback['robertaModel'].boundingBox
            && manualFeedback['robertaModel'].pageNum == feedback.pageNum
            && manualFeedback['robertaModel'].boundingBox.position[0] == feedback.boundingBox.position[0]
            && manualFeedback['robertaModel'].boundingBox.size[0] == feedback.boundingBox.size[0]) {
                //This block is when only answer feedback is modified
                semanticFeedback = { ...feedback }
                qnaFeedback = { ...manualFeedback['robertaModel'] }
                return true;
        } else if (!manualFeedback['USEModel']
        && manualFeedback.robertaModel
        && feedback.implicit == 'True'
        && feedback['answerFeedbackId']
        && feedback['answerFeedbackId'] == manualFeedback.robertaModel.selectionId) {
            //this block is for modification on already saved answer feedback
            qnaFeedback = { ...manualFeedback['robertaModel'] }
            return true;
        }
    })
    let feedbackObj = {}
    if (isAiBox) {
        const startCursor = getScaledCursor(qnaFeedback['boundingBox'].startCursor, manualFeedbackConstantFactor);
        const endCursor = getScaledCursor(qnaFeedback['boundingBox'].endCursor, manualFeedbackConstantFactor);
        feedbackObj = {
            pageNum: qnaFeedback['pageNum'],
            text: qnaFeedback['text'],
            boundingBox: {
                startCursor: { ...startCursor },
                endCursor: { ...endCursor },
                position: [...qnaFeedback['boundingBox'].position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                size: [...qnaFeedback['boundingBox'].size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3)),
                startIndex: qnaFeedback['boundingBox'].startIndex,
                endIndex: qnaFeedback['boundingBox'].endIndex
            }
        }
    } else {
        const startCursor = getScaledCursor(semanticFeedback['boundingBox'].startCursor, manualFeedbackConstantFactor);
        const endCursor = getScaledCursor(semanticFeedback['boundingBox'].endCursor, manualFeedbackConstantFactor);
        feedbackObj = {
            pageNum: semanticFeedback['pageNum'],
            text: semanticFeedback['text'],
            id: semanticFeedback['selectionId'],
            boundingBox: {
                startCursor: { ...startCursor },
                endCursor: { ...endCursor },
                position: [...semanticFeedback['boundingBox'].position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                size: [...semanticFeedback['boundingBox'].size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3))
            }
        }
    }
    if (!isAiBox && !isEmpty(qnaFeedback)) {
        const qnaStartCursor = getScaledCursor(qnaFeedback['boundingBox'].startCursor, manualFeedbackConstantFactor);
        const qnaEndCursor = getScaledCursor(qnaFeedback['boundingBox'].endCursor, manualFeedbackConstantFactor);
        feedbackObj['qnaFeedback'] = {
            pageNum: qnaFeedback['pageNum'],
            text: qnaFeedback['text'],
            id: qnaFeedback['selectionId'],
            boundingBox: {
                startCursor: { ...qnaStartCursor },
                endCursor: { ...qnaEndCursor },
                position: [...qnaFeedback['boundingBox'].position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                size: [...qnaFeedback['boundingBox'].size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3)),
                startIndex: qnaFeedback['boundingBox'].startIndex,
                endIndex: qnaFeedback['boundingBox'].endIndex
            }
        }
    }
    return feedbackObj
}

export const getManualFeedbackFromSelectedResults = (selectedResult, feedback, scale) => {
    const manualFeedbackConstantFactor = getConstantFactor(scale, true)
    const qnaFeedback = selectedResult.robertaModel?.feedback?.selections?.find(ansFeedback => {
        return feedback.id && ansFeedback.parentId && feedback.id === ansFeedback.parentId
    })
    if (feedback.implicit == 'True' && qnaFeedback) {
        const qnaStartCursor = getScaledCursor(qnaFeedback.boundingBox.startCursor, manualFeedbackConstantFactor);
        const qnaEndCursor = getScaledCursor(qnaFeedback.boundingBox.endCursor, manualFeedbackConstantFactor);
        return {
            ...qnaFeedback,
            boundingBox: {
                startCursor: { ...qnaStartCursor },
                endCursor: { ...qnaEndCursor },
                position: [...qnaFeedback.boundingBox.position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                size: [...qnaFeedback.boundingBox.size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3)),
                startIndex: qnaFeedback.boundingBox.startIndex,
                endIndex: qnaFeedback.boundingBox.endIndex
            }
        }
    } else if(feedback.boundingBox) {
        const startCursor = getScaledCursor(feedback.boundingBox.startCursor, manualFeedbackConstantFactor);
        const endCursor = getScaledCursor(feedback.boundingBox.endCursor, manualFeedbackConstantFactor);
        const feedbackObj = {
            id: feedback.id,
            pageNum: feedback.pageNum,
            text: feedback.text,
            boundingBox: {
                startCursor: { ...startCursor },
                endCursor: { ...endCursor },
                position: [...feedback.boundingBox.position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                size: [...feedback.boundingBox.size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3))
            }
        }
        if (qnaFeedback) {
            const qnaStartCursor = getScaledCursor(qnaFeedback.boundingBox.startCursor, manualFeedbackConstantFactor);
            const qnaEndCursor = getScaledCursor(qnaFeedback.boundingBox.endCursor, manualFeedbackConstantFactor);
            feedbackObj['qnaFeedback'] = {
                id: qnaFeedback.id,
                pageNum: qnaFeedback.pageNum,
                text: qnaFeedback.text,
                boundingBox: {
                    startCursor: { ...qnaStartCursor },
                    endCursor: { ...qnaEndCursor },
                    position: [...qnaFeedback.boundingBox.position].map(position => +(position * manualFeedbackConstantFactor).toFixed(3)),
                    size: [...qnaFeedback.boundingBox.size].map(size => +(size * manualFeedbackConstantFactor).toFixed(3)),
                    startIndex: qnaFeedback.boundingBox.startIndex,
                    endIndex: qnaFeedback.boundingBox.endIndex
                }
            }
        }
        return feedbackObj
    }
}

export const findManualFeedback = (selections, currentBox, feedbackType = 'semantic', manualFeedbackConstantFactor) => {
    //We always save manual feedback on default scale(0.75) even though feedback is given on different scale.
    //Therefore when we need to find the manualfeedback on different scale, we convert the current coordinates to defauld scale and then find it.
    let feedbackIndex = -1;
    let { x1, y1, pageNo } = currentBox;
    x1 = +(x1 / manualFeedbackConstantFactor).toFixed(3);
    y1 = +(y1 / manualFeedbackConstantFactor).toFixed(3);
    let modalName = feedbackType == 'semantic' || (feedbackType == 'answer' && !currentBox.isAiBox) ? 'USEModel' : 'robertaModel';
    /**if User is trying to add answer feedback on saved semantic manual feedback, 
    and user never modified the saved semantic feedback for that moment but modifies answer feedback multiple times,
    then in selection object we will only have robertaModel**/
    if(feedbackType == 'answer' && !currentBox.isAiBox && currentBox.id && currentBox.qnaFeedback){
        modalName = 'robertaModel'
    }
    selections.find((selection, index) => {
        const position = selection[modalName]?.boundingBox?.position || [0, 0]
        if (feedbackType == 'answer' && currentBox.isAiBox && (selection.operation == 'delete' || selection.operation == 'update')) {
            if (position[0] == x1 && position[1] == y1) {
                feedbackIndex = index;
                return true;
            }
        } else {
            if (modalName == 'USEModel' && !selection[modalName]) {
                if (selection.robertaModel.boundingBox.position[0] == x1
                    && selection.robertaModel.boundingBox.position[1] == y1
                    && selection.robertaModel.pageNum == pageNo) {
                    feedbackIndex = index;
                    return true;
                }
            } else {
                const pageNum = selection[modalName]?.pageNum || -1
                if (position[0] == x1 && position[1] == y1 && pageNo === pageNum) {
                    feedbackIndex = index;
                    return true;
                }
            }
        }
    })
    return feedbackIndex;
}
export const isValidatedCursorPosition = (svgGroup) => {
    const startCursor = svgGroup.querySelector(".startCursorLine");
    const endCursor = svgGroup.querySelector(".endCursorLine");
    const startCursorX = parseInt(startCursor.getAttribute('x1'));
    const startCursorY1 = parseInt(startCursor.getAttribute('y1'));
    const startCursorY2 = parseInt(startCursor.getAttribute('y2'));
    const endCursorX = parseInt(endCursor.getAttribute('x1'));
    const endCursorY1 = parseInt(endCursor.getAttribute('y1'));
    const endCursorY2 = parseInt(endCursor.getAttribute('y2'));
    if (startCursorY2 < endCursorY1
        || (
            Math.abs(endCursorY1 - startCursorY2) < (endCursorY2 - endCursorY1) / 2
            && Math.abs(endCursorY1 - startCursorY2) < (startCursorY2 - startCursorY1) / 2
        )
    ) {
        return true;
    } else if (startCursorY1 == endCursorY1
        || startCursorY2 == endCursorY2
        || (startCursorY1 >= endCursorY1 && startCursorY2 <= endCursorY2)
        || (startCursorY1 <= endCursorY1 && startCursorY2 >= endCursorY2)
        || (
            Math.abs(startCursorY1 - endCursorY1) < Math.abs(startCursorY1 - startCursorY2) / 2
            && Math.abs(startCursorY2 - endCursorY2) < Math.abs(startCursorY1 - startCursorY2) / 2
        )) {
        // if cursors are in same line
        if (startCursorX < endCursorX) {
            return true;
        } else {
            return false
        }
    }
    return false
}
export const enableDisableSaveCopyDiscardButtons = (enable = true, currentBox, enableDisabelOnlySaveAndCopy = false) => {
    const saveButton:any = document.getElementById(`${currentBox.saveButtonId}`)
    const copyButton:any = document.getElementById(`${currentBox.copyButtonId}`)
    const discardButton:any = document.getElementById(`${currentBox.discardButtonId}`)
    if (!enable && saveButton && copyButton && discardButton) {
        saveButton.disabled = true;
        saveButton.style.cursor = 'not-allowed';
        copyButton.disabled = true;
        copyButton.style.cursor = 'not-allowed';
        if (!enableDisabelOnlySaveAndCopy) {
            discardButton.disabled = true
            discardButton.style.cursor = 'not-allowed';
        }
    } else if (saveButton && saveButton && copyButton && discardButton) {
        saveButton.disabled = false
        copyButton.disabled = false
        discardButton.disabled = false
        saveButton.style.cursor = 'pointer';
        copyButton.style.cursor = 'pointer';
        discardButton.style.cursor = 'pointer';
    }
}
//itemCoordinates can be word coordinates or line coodinates
// & cursorCoordinates contains bots start[0,1] and end[2,3] cursor coordinates
export const isWithinCursors = (cursorCoordinates, itemCoordinates) => {
    const wordHeight = itemCoordinates[3] - itemCoordinates[1];
    if (itemCoordinates[1] < cursorCoordinates[1]
        && Math.abs(itemCoordinates[1] - cursorCoordinates[1]) > wordHeight / 2) {
        //if before start cursor on y axis
        return false;
    } else if (Math.abs(itemCoordinates[1] - cursorCoordinates[1]) <= wordHeight / 2
        && itemCoordinates[0] < cursorCoordinates[0]
        && itemCoordinates[2] < cursorCoordinates[0]) {
        //if item is on x axis plane of start cursor but entire word is before start cursor
        return false;
    } else if (itemCoordinates[1] > cursorCoordinates[3]
        && Math.abs(itemCoordinates[1] - cursorCoordinates[3]) > wordHeight / 2) {
        //if after end cursor on y axis
        return false;
    } else if (Math.abs(itemCoordinates[1] - cursorCoordinates[3]) <= wordHeight / 2
        && itemCoordinates[0] > cursorCoordinates[2]
        && itemCoordinates[2] > cursorCoordinates[2]) {
        //if item is on x axis plane of end cursor but entire word is after end cursor
        return false;
    } else {
        return true;
    }
}
const findSpansBetweenCursors = (elements, start, end) => {
    if (Object.keys(start).length == 4 && Object.keys(end).length == 4) {
        start = { x: start.x1, y: start.y1 }
        end = { x: end.x1, y: end.y1 }
    }
    const selectedElements = []
    const startX = parseFloat(start.x)
    const startY = parseFloat(start.y)
    const endX = parseFloat(end.x);
    const endY = parseFloat(end.y);
    (elements || []).forEach(element => {
        const elHeight = element.offsetHeight || element.getAttribute('offsetHeight')
        const elWidth = (element.offsetWidth || element.getAttribute('offsetWidth')) * getScaleX(element)
        const x1 = element && (element.offsetLeft || element.getAttribute('offsetLeft'))
        const y1 = element && (element.offsetTop || element.getAttribute('offsetTop'))
        const x2 = x1 + elWidth // element bottom-right coordinates
        const y2 = y1 + elHeight // element bottom-right coordinates

        if (element && element.tagName.toLowerCase() === 'br' || isWithinCursors([startX, startY, endX, endY], [x1, y1, x2, y2])) {
            selectedElements.push(element);
        } else if (element && element.tagName.toLowerCase() !== 'br' && element.tagName.toLowerCase() !== 'span') {
            console.log('Unable to parse element - ', element)
        }
    });
    const validSpans = [];
    for (let i = 0; i < selectedElements.length; i++) {
        const el = selectedElements[i];
        const scaleXValue = getScaleX(el);
        const x1 = el.offsetLeft;
        const y1 = el.offsetTop;
        const x2 = x1 + el.offsetWidth * scaleXValue;
        if (Math.abs(start.y - y1) <= el.offsetHeight / 2) {
            // to manage first line where we have start cursor
            if (start.x < x2) {
                validSpans.push(el);
            }
        } else if (Math.abs(end.y - y1) <= el.offsetHeight / 2) {
            // to manage last line where we have end cursor
            if (x1 < end.x) {
                validSpans.push(el);
            }
        } else {
            validSpans.push(el);
        }
    }
    return removeTrailingBRElements(validSpans)
}
export function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const checkAnswerOutsideSemanticSelection = (currentBox) => {
    if (
        currentBox?.qnaFeedback?.feedbackText
        && currentBox?.qnaFeedback?.startCursor
        && !isEmpty(currentBox.qnaFeedback.startCursor)
        && currentBox?.qnaFeedback?.endCursor
        && !isEmpty(currentBox.qnaFeedback.endCursor)
        && currentBox?.feedbackText
        && currentBox?.startCursor
        && !isEmpty(currentBox.startCursor)
        && currentBox?.endCursor
        && !isEmpty(currentBox.endCursor)
    ) {
        let isFallingOutside = false;
        const qnaCursorStartPosition = {
            x: Math.round(parseFloat(currentBox.qnaFeedback.startCursor.x1)),
            y: Math.round(parseFloat(currentBox.qnaFeedback.startCursor.y1)) <= Math.round(parseFloat(currentBox.qnaFeedback.startCursor.y2))
                ? Math.round(parseFloat(currentBox.qnaFeedback.startCursor.y1))
                : Math.round(parseFloat(currentBox.qnaFeedback.startCursor.y2))
        };
        const qnaCursorEndPosition = {
            x: Math.round(parseFloat(currentBox.qnaFeedback.endCursor.x1)),
            y: Math.round(parseFloat(currentBox.qnaFeedback.endCursor.y1)) <= Math.round(parseFloat(currentBox.qnaFeedback.endCursor.y2))
                ? (Math.round(parseFloat(currentBox.qnaFeedback.endCursor.y1)))
                : (Math.round(parseFloat(currentBox.qnaFeedback.endCursor.y2)))
        };
        const semanticCursorStartPosition = {
            x: Math.round(parseFloat(currentBox.startCursor.x1)),
            y: Math.round(parseFloat(currentBox.startCursor.y1)) <= Math.round(parseFloat(currentBox.startCursor.y2))
                ? Math.round(parseFloat(currentBox.startCursor.y1))
                : Math.round(parseFloat(currentBox.startCursor.y2))
        };
        const semanticCursorEndPosition = {
            x: Math.round(parseFloat(currentBox.endCursor.x1)),
            y: Math.round(parseFloat(currentBox.endCursor.y1)) <= Math.round(parseFloat(currentBox.endCursor.y2))
                ? Math.round(parseFloat(currentBox.endCursor.y1))
                : Math.round(parseFloat(currentBox.endCursor.y2))
        };
        const startCursorHeight = Math.abs(currentBox.startCursor.y1 - currentBox.startCursor.y2);
        const endCursorHeight = Math.abs(currentBox.endCursor.y1 - currentBox.endCursor.y2);

        //---handle start cursor------
        if (
            semanticCursorStartPosition.y == qnaCursorStartPosition.y
            || Math.abs(semanticCursorStartPosition.y - qnaCursorStartPosition.y) <= startCursorHeight / 2
        ) { // if semantic and qna start cursors are in same y coordinates
            if (semanticCursorStartPosition.x > qnaCursorStartPosition.x) { //semantic start x is greater than qna start x
                isFallingOutside = true;
            }
        } else if (semanticCursorStartPosition.y - qnaCursorStartPosition.y > startCursorHeight / 2) { //semantic start cursor y is greater than qna start y cursor. Means semantic start is in next line
            isFallingOutside = true;
        }

        //-----handle end cursor--------
        if (
            semanticCursorEndPosition.y == qnaCursorEndPosition.y
            || Math.abs(semanticCursorEndPosition.y - qnaCursorEndPosition.y) <= endCursorHeight / 2
        ) { //if semantic and qna end cursors are in same y coordinates
            if (semanticCursorEndPosition.x < qnaCursorEndPosition.x) { //semantic end x is less than qna end x
                isFallingOutside = true;
            }
        } else if (qnaCursorEndPosition.y - semanticCursorEndPosition.y > endCursorHeight / 2) { //semantic end cursor y is less than qna end y cursor
            isFallingOutside = true;
        }
        return isFallingOutside;
    } else {
        return false
    }
}

const disableSaveCopyClearButton = (isDisabled = false) => {
    const save = document.getElementsByClassName('saveSelectionButton')[0]
    const copy = document.getElementsByClassName('copySelectionButton')[0]
    const clear = document.getElementsByClassName('discardSelectionButton')[0]
    if (save && copy && clear) {
        const buttons = [save, copy, clear]
        if (isDisabled) {
            buttons.forEach(button => {
                button['disabled'] = true;
                button['style'].opacity = 0.6
                button['style'].cursor = 'not-allowed'
            })
        } else {
            buttons.forEach(button => {
                button['disabled'] = false;
                button['style'].opacity = 1
                button['style'].cursor = 'pointer'
            })
        }
    }
}

const restoreSelection = (currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) => {
    const svgGroup = document.getElementById(`resizeID-${currentBox.w}-${currentBox.h}`);
    const feedbackType = currentBox.isAiBox ? 'answer' : redrawBoxData.feedbackType == 'answer' ? 'answer' : 'semantic'
    const highlightType = feedbackType == 'semantic' ? lablesAndMessage['semanticBtn'] : lablesAndMessage['answerBtn']
    svgGroup && (svgGroup.querySelectorAll(`[highlightType="${highlightType}"]`) || []).forEach((highlightedRect) => {
        highlightedRect.remove();
    })
    removeCursorsAfterSave()
    setTimeout(() => {
        document.getElementById('answerOutsideSemanticSelectionErrorPopup').remove()
    }, 10)
    const boxObj = findSpansUnderSVGBox(
        0.75,
        currentBox.pageNo,
        [currentBox.x1, currentBox.y1, currentBox.x2, currentBox.y2]
    );
    const spans = boxObj && boxObj.spans || [];
    let firstSpanEl;
    let lastSpanEl;
    //oldCurrentBox is the current box that was before moving cursors
    const oldCurrentBox = getCurrentBox(currentBox.pageNo, currentBox.x1, currentBox.x1, feedbackType, currentBox.isAiBox)
    let startCursor = feedbackType == 'semantic' ? { ...oldCurrentBox.startCursor } : { ...oldCurrentBox.qnaFeedback.startCursor }
    let endCursor = feedbackType == 'semantic' ? { ...oldCurrentBox.endCursor } : { ...oldCurrentBox.qnaFeedback.endCursor }
    const feedback = {
        id: currentBox.qnaFeedback.id,
        pageNum: currentBox.pageNo,
        text: feedbackType == 'semantic' ? oldCurrentBox.feedbackText : oldCurrentBox.qnaFeedback.feedbackText,
        boundingBox: {
            startCursor: { ...startCursor },
            endCursor: { ...endCursor },
            'position': [currentBox.x1, currentBox.y1],
            'size': [currentBox.h, currentBox.w]
        }
    }
    let manualFeedbackSpans = findSpansBetweenCursors(spans, { x: startCursor['x1'], y: startCursor['y1'] }, { x: endCursor['x1'], y: endCursor['y1'] })
    if (manualFeedbackSpans.length > 0) {
        for (let i = 0; i < manualFeedbackSpans.length; i++) {
            if (manualFeedbackSpans[i].tagName === 'SPAN' && manualFeedbackSpans[i].textContent.trim() !== '') {
                firstSpanEl = manualFeedbackSpans[i];
                break;
            }
        }
        for (let i = manualFeedbackSpans.length - 1; i > -1; i--) {
            if (manualFeedbackSpans[i].tagName === 'SPAN' && manualFeedbackSpans[i].textContent.trim() !== '') {
                lastSpanEl = manualFeedbackSpans[i];
                break;
            }
        }
    }
    if (currentBox.feedbackType == 'semantic') {
        highlightFeedbackText(0.75, [currentBox.x1, currentBox.y1, currentBox.x2, currentBox.y2], currentBox.pageNo, feedback, false, false)
    } else {
        highlightFeedbackText(0.75, [currentBox.x1, currentBox.y1, currentBox.x2, currentBox.y2], currentBox.pageNo, feedback, false, true, oldCurrentBox.feedbackText)
    }
    const startPos = { x: startCursor['x1'], y: startCursor['y1'] };
    const endPos = { x: endCursor['x1'], y: endCursor['y1'] }
    createCursor(currentBox, svgGroup, firstSpanEl, lastSpanEl, startPos, endPos);
    cursorMouseDownEventAttached['startCursor'] = false;
    cursorMouseDownEventAttached['endCursor'] = false;
    addDragableEventOnCursor(spans, svgGroup, cursorMouseDownEventAttached, currentBox, 0.75, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox);
    disableSaveCopyClearButton(false)
    setCurrentBox(window['structuredClone'](oldCurrentBox))
    redrawBoxData.enableDisableDoneButton(false)
}

const clearAnswer = (currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) => {
    discardOperation(currentBox.x1, currentBox.y1, cursorMouseDownEventAttached, currentBox, 'answer', false, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, setCurrentBox, redrawBoxData, getCurrentBox)
    setTimeout(() => {
        document.getElementById('answerOutsideSemanticSelectionErrorPopup').remove()
    },10)
    redrawBoxData.enableDisableDoneButton(false)
}

const showHideAnswerOutsideSemanticSelectionPopup = (show = true, isQnaFeedback, currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) => {
    if (show && !document.getElementById('answerOutsideSemanticSelectionErrorPopup')) {
        const popupContainer = document.createElement('div')
        popupContainer.classList.add('popupContainer')
        popupContainer.id = 'answerOutsideSemanticSelectionErrorPopup'
        popupContainer.style.left = `${currentBox.x1}px`
        popupContainer.style.top = (currentBox.y1 - 79) < 0 ? `${currentBox.y1 + currentBox.h}px` : `${currentBox.y1 - 79}px`
        
        const popupContent = document.createElement('div')
        popupContent.classList.add('popupContent')

        const messageContainer = document.createElement('div')
        messageContainer.classList.add('messageContainer')

        const popupMessage = document.createElement('p')
        popupMessage.classList.add('popupMessage')
        popupMessage.innerText = lablesAndMessage['semanticInsideAnswerValidation']

        const warning_icon = document.createElement('img')
        warning_icon.src = '/assets/img/icons/warning-red.svg'
        warning_icon.style.height = '14px'
        warning_icon.style.width = '14px'
        warning_icon.style.marginTop = '3px'

        const popupButtonRow = document.createElement('div')
        popupButtonRow.classList.add('popupButtonRow')

        const restore_selection = document.createElement('button')
        const restore_icon = document.createElement('img')
        restore_icon.src = '/assets/img/icons/clock-rotate-left-solid.svg'
        restore_icon.style.height = '10px'
        restore_icon.style.width = '14px'
        restore_icon.style.marginBottom = '2px'
        restore_selection.appendChild(restore_icon)
        const restore_labelEl = document.createElement('span')
        restore_labelEl.innerText = lablesAndMessage['restoreSelection']
        restore_selection.appendChild(restore_labelEl)
        restore_selection.addEventListener('click', () => { restoreSelection(currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) })
        if (isQnaFeedback) {
            const clear_answer = document.createElement('button')
            const clear_icon = document.createElement('img')
            clear_icon.src = '/assets/img/clear.svg'
            clear_icon.style.height = '14px'
            clear_icon.style.width = '14px'
            clear_icon.style.marginBottom = '2px'

            clear_answer.appendChild(clear_icon)
            const clear_labelEl = document.createElement('span')
            clear_labelEl.innerText = lablesAndMessage['clearAnswer']
            clear_answer.appendChild(clear_labelEl)
            clear_answer.addEventListener('click', () => { clearAnswer(currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) })
            popupButtonRow.appendChild(clear_answer)
        }
        popupButtonRow.appendChild(restore_selection)

        messageContainer.appendChild(warning_icon)
        messageContainer.appendChild(popupMessage)

        popupContent.appendChild(messageContainer)
        popupContent.appendChild(popupButtonRow)

        popupContainer.appendChild(popupContent)
        const pageDiv = document.querySelector(`[data-page-number="${currentBox.pageNo}"]`);
        pageDiv.appendChild(popupContainer)
        disableSaveCopyClearButton(true)
    } else if (!show) {
        const errorPopup = document.getElementById('answerOutsideSemanticSelectionErrorPopup')
        errorPopup && errorPopup.remove()
        disableSaveCopyClearButton(false)
    }
}

const findStartAndEndIndex = (semanticSpans, answerSpans, semanticText, answerText) => {
    const beforeSpans = []
    let firstAnswerSpanWithtext = answerSpans[0];
    if (!firstAnswerSpanWithtext.textContent.trim()) {
        for (let i = 0; i < answerSpans.length; i++) {
            if (answerSpans[i].tagName == 'SPAN' && answerSpans[i].textContent.trim()) {
                firstAnswerSpanWithtext = answerSpans[i]
                break;
            }
        }
    }
    for (let i = 0; i < semanticSpans.length; i++) {
        if (semanticSpans[i].offsetLeft == firstAnswerSpanWithtext.offsetLeft && semanticSpans[i].offsetTop == firstAnswerSpanWithtext.offsetTop && semanticSpans[i].textContent == firstAnswerSpanWithtext.textContent) {
            break
        } else {
            beforeSpans.push(semanticSpans[i])
        }
    }
    let firstThree = '';
    const semanticTextArr = semanticText.split(' ');
    for (let i = 0; i < semanticTextArr.length; i++) {
        if (i > 2) {
            break
        } else {
            firstThree = (firstThree + ' ' + semanticTextArr[i]).trim();
        }
    }
    const stichedSpansTextBeforeFeedback = stitchSpanTextWithPredictiveSpaces(beforeSpans).trim()
    let diff = stichedSpansTextBeforeFeedback.indexOf(firstThree)
    if (!stichedSpansTextBeforeFeedback) {
        diff = firstAnswerSpanWithtext.textContent.trim().indexOf(firstThree)
    }
    const additionalSpace = beforeSpans[beforeSpans.length - 1].tagName == 'BR' || (beforeSpans[beforeSpans.length - 1].tagName == 'SPAN' && beforeSpans[beforeSpans.length - 1].textContent == ' ') ? ' ' : '';
    // Scenario, answer text is from multiple spans, so we are not able to find correct start index
    const answerWords = answerText.split(' ');
    const partialAnswerText = answerWords[0] + (answerWords[1] ? (' ' + answerWords[1]) : '') + (answerWords[2] ? (' ' + answerWords[2]) : '');
    const stichedText = (stichedSpansTextBeforeFeedback + additionalSpace + firstAnswerSpanWithtext.textContent).trim();
    let startIndex = stichedText.lastIndexOf(partialAnswerText)
    if (startIndex == -1) {
        // if 3 words are not found, check with 2
        const twoWordAnswerText = answerWords[0] + (answerWords[1] ? (' ' + answerWords[1]) : '')
        startIndex = stichedText.lastIndexOf(twoWordAnswerText)
        if (startIndex == -1) {
            // if 2 words are not found, check with only one
            startIndex = stichedText.lastIndexOf(answerWords[0])
        }
    }
    let endIndex = startIndex + answerText.length
    if (stichedSpansTextBeforeFeedback && diff === -1) {
        diff = (stichedSpansTextBeforeFeedback + additionalSpace + firstAnswerSpanWithtext.textContent).trim().indexOf(firstThree)
    }
    return {
        startIndex: diff > -1 ? startIndex - diff + 1 : startIndex,
        endIndex: diff > -1 ? endIndex - diff + 1 : endIndex
    }
}

const changeHighlight = (start, end, spans, currentBox, cursorMouseDownEventAttached, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) => {
    const { w } = currentBox
    const { h } = currentBox
    const isQnaFeedback = currentBox.feedbackType == 'answer' ? true : false;

    const isAnswerOutsideSemanticSelection = isQnaFeedback && currentBox.isAiBox ? false : checkAnswerOutsideSemanticSelection(currentBox)
    showHideAnswerOutsideSemanticSelectionPopup(isAnswerOutsideSemanticSelection, isQnaFeedback, currentBox, cursorMouseDownEventAttached, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox)
    if (isAnswerOutsideSemanticSelection) {
        redrawBoxData.enableDisableDoneButton(true)
        return
    }
    redrawBoxData.enableDisableDoneButton(false)

    const svgGroup:any = currentBox.isAiBox ? document.getElementById(`rect-${w}-${h}`).parentNode : document.getElementById(`resizeID-${w}-${h}`);
    const feedbackType = currentBox.feedbackType;
    if(isQnaFeedback){
        svgGroup && (svgGroup.querySelectorAll(`[highlightType="Answer"]`) || []).forEach((highlightedRect) => {
            highlightedRect.remove();
        })
    }else{
        svgGroup && (svgGroup.querySelectorAll(`[highlightType="Semantic"]`) || []).forEach((highlightedRect) => {
            highlightedRect.remove();
        })
    }
    createSelectionArea(currentBox, spans, svgGroup, { x: currentBox.x1, y: currentBox.y1 }, { x: currentBox.x2, y: currentBox.y2 }, true, { start, end }, currentBox.isAiBox, feedbackType=='answer' ? true : false)
    addDragableEventOnCursor(spans, svgGroup, cursorMouseDownEventAttached, currentBox, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox);
    const isValid = isValidatedCursorPosition(svgGroup);
    enableDisableSaveCopyDiscardButtons(isValid, currentBox)
    if (!isValid) {
        enableDisableSaveCopyDiscardButtons(isValid, currentBox, true)
        return;
    }
    /*------------- Cursor-based text filtering code --------------*/
    const selectedElements = findSpansBetweenCursors(spans, start, end)
    const startX = parseFloat(start.x)
    const startY = parseFloat(start.y)
    const endX = parseFloat(end.x)
    const endY = parseFloat(end.y)
    const startCursor = {
        x: startX,
        y: startY + 2
    }
    const endCursor = {
        x: endX,
        y: endY
    }
    let stitchedText = '';
    stitchedText = getSelectedTextFromBoxElements(
        scale,
        selectedElements,
        [currentBox['x1'], currentBox['y1'], currentBox['x2'], currentBox['y2']],
        currentBox.pageNo,
        startCursor,
        endCursor
    )
    //lets handle answer start and end index for manual feedbacks first
    let answerIndexes = {}
    if (isQnaFeedback) {
        let semanticText = currentBox.isAiBox ? redrawBoxData.selectedResult.USEModel.chunk_text.eng.text : currentBox.feedbackText
        if(semanticText.indexOf('\n') > -1){
            semanticText = replaceAll(semanticText, '\n', '')
        }
        answerIndexes = findStartAndEndIndex(spans, selectedElements, semanticText, stitchedText);
    } else if (!isEmpty(currentBox.qnaFeedback)) {
        //if Answer feedback is already provided, then on changing of semantic text, start and end index of answer within semantic text also gets changed.
        const elements = findSpansBetweenCursors(spans, {x: currentBox.qnaFeedback.startCursor.x1, y: currentBox.qnaFeedback.startCursor.y1}, {x: currentBox.qnaFeedback.endCursor.x1, y: currentBox.qnaFeedback.endCursor.y1})
        answerIndexes = findStartAndEndIndex(spans, elements, stitchedText, currentBox.qnaFeedback.text || currentBox.qnaFeedback.feedbackText);
    }
    if (isQnaFeedback && !currentBox.isAiBox) {
        currentBox.qnaFeedback.feedbackText = stitchedText.trim();
        currentBox.qnaFeedback.startIndex = answerIndexes['startIndex']
        currentBox.qnaFeedback.endIndex = answerIndexes['endIndex']
    } else if (currentBox.isAiBox) {
        currentBox.feedbackText = stitchedText.trim();
        currentBox.startIndex = answerIndexes['startIndex']
        currentBox.endIndex = answerIndexes['endIndex']
    } else {
        if (!currentBox.isAiBox && !isQnaFeedback && !isEmpty(currentBox.qnaFeedback) && !isEmpty(answerIndexes)) {
            //if Answer feedback is already provided, then on changing of semantic text, start and end index of answer within semantic text also gets changed.
            currentBox.qnaFeedback.startIndex = answerIndexes['startIndex']
            currentBox.qnaFeedback.endIndex = answerIndexes['endIndex']
        }
        currentBox.feedbackText = stitchedText.trim();
    }
    setCurrentBox({...currentBox})
    const saveButton:any = document.getElementById(`${currentBox.saveButtonId}`);
    if (saveButton && !stitchedText.trim()) {
        saveButton.disabled = true
        saveButton.style.cursor = 'not-allowed';

    } else if (saveButton) {
        saveButton.disabled = false
        saveButton.style.cursor = 'pointer';
    }
}
export const closestElement = (cursor, arr, anotherCursor, cursorType) => {
    const x = Math.abs(cursor.getAttribute("x1"));
    let y = Math.abs(cursor.getAttribute("y1"));
    if (cursorType != 'start') {
        // Below logic is to handle case when user tries to move the end cursor above start cursor
        if (y - Math.abs(anotherCursor.getAttribute("y1")) < 0) {
            y = Math.abs(anotherCursor.getAttribute("y1"));
        }
    }
    if (arr.length == 0) {
        return;
    }
    let curr = arr[0];
    const closestYAxiselements = [];
    let diffY = Math.abs(y - curr?.getAttribute('y'));
    //Finding closest element (curr) in y axix
    for (let val = 1; val < arr.length; val++) {
        const newdiffY = Math.abs(y - arr[val].getAttribute('y'));
        if (newdiffY < diffY) {
            diffY = newdiffY;
            curr = arr[val];
        }
    }
    //Finding all the elements that exists in x axix of curr element
    for (let val = 0; val < arr.length; val++) {
        if (Math.abs(curr.getAttribute('y') - arr[val].getAttribute('y')) <= curr.getAttribute('height') / 2) {
            closestYAxiselements.push(arr[val]);
        }
    }
    //Finding nearest x axis element that exists in x axix of curr element
    if (closestYAxiselements.length > 0) {
        const closestYAxisEle = closestYAxiselements[0];
        curr = closestYAxisEle;
        let diffX = Math.abs(x - curr.getAttribute('x'));
        for (let val = 1; val < closestYAxiselements.length; val++) {
            const newdiffX = Math.abs(x - closestYAxiselements[val].getAttribute('x'));
            if (newdiffX < diffX) {
                diffX = newdiffX;
                curr = closestYAxiselements[val];
            }
        }
    }
    return curr;
}
const debounceFn = debounce(changeHighlight);
function Draggable(elem, spans, svgGroup, cursorMouseDownEventAttached, currentBox, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) {
    this.svgGroup = svgGroup;
    this.spans = spans;
    this.target = elem;
    if (!elem || !elem.parentElement || !elem.ownerSVGElement) {
        return
    }
    const actualParent = elem.parentElement;
    const getRect = actualParent.querySelector(".rectSelect");
    if (getRect) {
        this.rectx1Position = getRect.getAttribute("x");
        this.recty1Position = getRect.getAttribute("y");
        this.rectx2Position = parseInt(getRect.getAttribute("x")) + parseInt(getRect.getAttribute("width"));
        this.recty2Position = parseInt(getRect.getAttribute("y")) + parseInt(getRect.getAttribute("height"));
    }

    this.clickPoint = this.target?.ownerSVGElement?.createSVGPoint()
    this.lastMove = this.target?.ownerSVGElement?.createSVGPoint()
    this.currentMove = this.target?.ownerSVGElement?.createSVGPoint()
    const cursorType = this.target?.classList?.contains('endCursor') ? 'endCursor' : 'startCursor';
    if (!cursorMouseDownEventAttached[cursorType]) {
        this.target.addEventListener("mousedown", this)
        cursorMouseDownEventAttached[cursorType] = true;
    }
    this.handleEvent = function (evt) {
        evt.preventDefault()
        if (!elem.ownerSVGElement) {
            return false;
        }
        this.clickPoint = globalToLocalCoords(evt.clientX, evt.clientY)
        this.target?.classList.add("dragged")
        this.target?.setAttribute("pointer-events", "none")
        this.target?.ownerSVGElement.addEventListener("mousemove", this.move)
        this.target?.ownerSVGElement.addEventListener("mouseup", this.endMove)
    }

    this.move = function (evt) {
        const svgGroup = evt.target.classList.contains('drawingPlane');
        if (svgGroup || !elem.ownerSVGElement) {
            evt.target.classList.remove("dragged")
            return false;
        }
        const p = globalToLocalCoords(evt.clientX, evt.clientY);
        const selectedCursorType = this.target.getAttribute("class").includes("startCursor") ? "start" : "end";
        const startCursor = actualParent.querySelector(".startCursorLine");
        const endCursor = actualParent.querySelector(".endCursorLine");

        const isCircle = evt.target.classList.contains(selectedCursorType == 'start' ? 'startCursorBottomCircle' : 'endCursorBottomCircle');
        const isLine = evt.target.classList.contains(selectedCursorType == 'start' ? 'startCursorLine' : 'endCursorLine')
        let unSelRect;
        if (isCircle || isLine) {
            const { parentElement } = evt.target;
            const lineEl = selectedCursorType == 'start' ? parentElement.getElementsByClassName('startCursorLine')[0] : parentElement.getElementsByClassName('endCursorLine')[0]
            p.y = lineEl.getAttribute('y1');
            if (selectedCursorType == 'start') {
                unSelRect = closestElement(startCursor, [...(parentElement.getElementsByClassName('unselectionArea') || [])], endCursor, selectedCursorType)
            } else {
                unSelRect = closestElement(endCursor, [...(parentElement.getElementsByClassName('unselectionArea') || [])], startCursor, selectedCursorType);
            }
        }
        const firstSpan = this.spans.find(spanEl => spanEl.tagName == 'SPAN' || spanEl.tagName == 'rect');
        const hasTextClass = evt.target.classList.contains('selectionArea');
        const hasTextClassDeselected = evt.target.classList.contains('unselectionArea') || unSelRect;
        const rectYposition = hasTextClass || hasTextClassDeselected || unSelRect ? unSelRect?.getAttribute("y") || parseFloat(evt.target.getAttribute("y")) : p.y;
        const heightRect = hasTextClass || hasTextClassDeselected || unSelRect ? unSelRect?.getAttribute("height") || evt.target.getAttribute("height") : firstSpan?.offsetHeight || firstSpan?.getAttribute('height');
        const rectActualPostion = parseInt(rectYposition) + parseInt(heightRect);
        const startCursorBottomCircle = actualParent.querySelector(".startCursorBottomCircle")
        const endCursorBottomCircle = actualParent.querySelector(".endCursorBottomCircle")
        
        const startCursorPositionForTextSelection = {
            x: parseFloat(startCursor.getAttribute("x1")) < parseFloat(startCursor.getAttribute("x2"))
                ? parseFloat(startCursor.getAttribute("x1"))
                : parseFloat(startCursor.getAttribute("x2")),
            y: parseFloat(startCursor.getAttribute("y1")) < parseFloat(startCursor.getAttribute("y2"))
                ? parseFloat(startCursor.getAttribute("y1")) : parseFloat(startCursor.getAttribute("y2"))
        };
        const startCursorPositon = { x: startCursor.getAttribute("x1"), y: startCursor.getAttribute("y1") < startCursor.getAttribute("y2") ? startCursor.getAttribute("y1") : startCursor.getAttribute("y2") };
        const endCursorPositon = { x: endCursor.getAttribute("x1"), y: endCursor.getAttribute("y1") < endCursor.getAttribute("y2") ? endCursor.getAttribute("y1") : endCursor.getAttribute("y2") };
        const endCursorPositionForTextSelection = {
            x: parseFloat(endCursor.getAttribute("x1")) < parseFloat(endCursor.getAttribute("x2"))
                ? parseFloat(endCursor.getAttribute("x1"))
                : parseFloat(endCursor.getAttribute("x2")),
            y: parseFloat(endCursor.getAttribute("y1")) < parseFloat(endCursor.getAttribute("y2"))
                ? parseFloat(endCursor.getAttribute("y1")) : parseFloat(endCursor.getAttribute("y2"))
        };
        let conditionForCursorCross = true;
        let isQnACursorOutOfBounds = false;
        const evtCursorYposition = p.y || evt.target.getAttribute("y");

        const startCursorY1 = parseFloat(startCursor.getAttribute("y1"));
        const startCursorY2 = parseFloat(startCursor.getAttribute("y2")) - heightRect / 2;
        const endCursorY1 = parseFloat(endCursor.getAttribute("y1"));
        const endCursorY2 = parseFloat(endCursor.getAttribute("y2")) - heightRect / 2;
        if (selectedCursorType === "start") {
            const cursorHeight = endCursorY2 - endCursorY1;
            if (currentBox.feedbackType == 'answer' && !isEmpty(currentBox.qnaFeedback)) {
                const ch = p.y + cursorHeight / 2
                if (ch < endCursorY1
                    && ch < endCursorY2
                    && p.y < endCursorPositon.y
                    && currentBox.startCursor.x1 > currentBox.qnaFeedback.startCursor.x1) {
                    isQnACursorOutOfBounds = true;
                }
            }
            if (((p.y + cursorHeight / 2) < endCursorY1 && (p.y + cursorHeight / 2) < endCursorY2)
                && p.y < endCursorPositon.y) {
                conditionForCursorCross = false;
            } else if (evtCursorYposition < endCursorPositionForTextSelection.y) {
                conditionForCursorCross = false;
            } else if (p.y < endCursorY2 && p.x < endCursorPositon.x) {
                // for text on single line
                conditionForCursorCross = false;
            }
        } else if (selectedCursorType === "end") {
            const cursorHeight = startCursorY2 - startCursorY1;
            if (currentBox.feedbackType == 'answer' && !isEmpty(currentBox.qnaFeedback)) {
                const ch = p.y + cursorHeight / 2
                if (ch < startCursorY1
                    && ch < startCursorY2
                    && p.y < startCursorPositon.y
                    && currentBox.endCursor.x1 < currentBox.qnaFeedback.endCursor.x1) {
                    isQnACursorOutOfBounds = true;
                }
            }
            if (p.y > startCursorY1 && p.y > startCursorY2) {
                conditionForCursorCross = false;
            } else if (p.y == startCursorY1 || p.y == startCursorY2 || (p.y >= startCursorY1 && p.y <= startCursorY2)) {
                if (parseFloat(startCursor.getAttribute("x1")) + 5 <= parseFloat(endCursor.getAttribute("x1"))) {
                    conditionForCursorCross = false;
                }
            }
        }
        const validX = selectedCursorType == 'start' ? p.x + heightRect / 2 : p.x - heightRect / 2;
        if (!isQnACursorOutOfBounds && !conditionForCursorCross && (hasTextClass || hasTextClassDeselected || unSelRect) && validX > this.rectx1Position && validX < this.rectx2Position && p.y > this.recty1Position && p.y < this.recty2Position) {
            let y1 = rectActualPostion;
            let y2 = rectActualPostion - heightRect;
            //y1 should always be smaller then y2. If not, replace the values.
            if (y1 > y2) {
                const tmp = y1;
                y1 = y2;
                y2 = tmp;
            }
            if (selectedCursorType === "start") {
                startCursor.setAttribute("x1", p.x + heightRect / 2)// adding (heightRect / 2) to x axis to fix issue where cursor is moving few pixels backwards when user holding bubble and moving it
                startCursor.setAttribute("x2", p.x + heightRect / 2)
                startCursor.setAttribute("y1", y1)
                startCursor.setAttribute("y2", y2 + heightRect / 2)

                // subtracting (heightRect / 2) to x axis to fix issue where cursor is moving few pixels backwards when user holding bubble and moving it
                startCursorBottomCircle.setAttributeNS(null, 'x', p.x - heightRect / 2 + 1);
                startCursorBottomCircle.setAttributeNS(null, 'y', y2);
                startCursorBottomCircle.setAttributeNS(null, 'width', heightRect);
                startCursorBottomCircle.setAttributeNS(null, 'height', heightRect);
                startCursorBottomCircle.setAttributeNS(null, 'rx', heightRect / 2);
            } else {
                endCursor.setAttribute("x1", p.x - heightRect / 2)// subtracting (heightRect / 2) to x axis to fix issue where cursor is moving few pixels backwards when user holding bubble and moving it
                endCursor.setAttribute("x2", p.x - heightRect / 2)
                endCursor.setAttribute("y1", y1)
                endCursor.setAttribute("y2", y2 + heightRect / 2)

                // subtracting (heightRect / 2) to x axis to fix issue where cursor is moving few pixels backwards when user holding bubble and moving it
                endCursorBottomCircle.setAttributeNS(null, 'x', p.x - 1 - heightRect / 2);
                endCursorBottomCircle.setAttributeNS(null, 'y', y2);
                endCursorBottomCircle.setAttributeNS(null, 'width', heightRect);
                endCursorBottomCircle.setAttributeNS(null, 'height', heightRect);
                endCursorBottomCircle.setAttributeNS(null, 'rx', heightRect / 2);
            }
            // adding/subtracting (heightRect / 2) to x axis to fix issue where cursor is moving few pixels backwards when user holding bubble and moving it
            this.target.setAttributeNS(null, "x", p.x - (selectedCursorType === "start" ? heightRect / 2 - 1 : 1 + heightRect / 2))
            this.target.setAttributeNS(null, "y", rectActualPostion - heightRect)
            this.target.setAttributeNS(null, "width", heightRect)
            this.target.setAttributeNS(null, "height", heightRect * 2)
            this.target.setAttribute("spanYCoordinate", y1 || p.y || evt.target.getAttribute("y"))

            if (!currentBox.isAiBox && currentBox.feedbackType == 'answer') {
                currentBox.qnaFeedback.startCursor = {
                    'x1': startCursor.getAttribute("x1"),
                    'y1': startCursor.getAttribute("y1"),
                    'x2': startCursor.getAttribute("x2"),
                    'y2': startCursor.getAttribute("y2") - (selectedCursorType === "start" ? heightRect / 2 : startCursorBottomCircle.getAttribute('rx'))
                }
                currentBox.qnaFeedback.endCursor = {
                    'x1': endCursor.getAttribute("x1"),
                    'y1': endCursor.getAttribute("y1"),
                    'x2': endCursor.getAttribute("x2"),
                    'y2': endCursor.getAttribute("y2") - (selectedCursorType === "end" ? heightRect / 2 : endCursorBottomCircle.getAttribute('rx'))
                }
            } else {
                currentBox.startCursor = {
                    'x1': startCursor.getAttribute("x1"),
                    'y1': startCursor.getAttribute("y1"),
                    'x2': startCursor.getAttribute("x2"),
                    'y2': startCursor.getAttribute("y2") - (selectedCursorType === "start" ? heightRect / 2 : startCursorBottomCircle.getAttribute('rx'))
                }
                currentBox.endCursor = {
                    'x1': endCursor.getAttribute("x1"),
                    'y1': endCursor.getAttribute("y1"),
                    'x2': endCursor.getAttribute("x2"),
                    'y2': endCursor.getAttribute("y2") - (selectedCursorType === "end" ? heightRect / 2 : endCursorBottomCircle.getAttribute('rx'))
                }
            }
            
            debounceFn(startCursorPositionForTextSelection, endCursorPositionForTextSelection, this.spans, currentBox, cursorMouseDownEventAttached, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox)
        }
    }.bind(this)

    this.endMove = function (evt) {
        this.lastMove.x = this.currentMove.x
        this.lastMove.y = this.currentMove.y
        this.target.classList.remove("dragged")
        this.target.setAttribute("pointer-events", "all")

        this.target.removeEventListener("mousemove", this.move)
        this.target.removeEventListener("mouseup", this.endMove)

        this.target.ownerSVGElement.removeEventListener("mousemove", this.move)
        this.target.ownerSVGElement.removeEventListener("mouseup", this.endMove)
    }.bind(this)

    function globalToLocalCoords(x, y) {
        const p = elem.ownerSVGElement.createSVGPoint()
        const m = elem && elem.parentElement ? elem.parentElement.getScreenCTM() : null;
        p.x = x
        p.y = y
        return p.matrixTransform(m.inverse())
    }
}

export const enableDisableEditButtons = (disable = false, editFeedback) => {
    const editButtons = document.querySelectorAll(`[id*="editButton-"]`);
    if (disable) {
      editFeedback = true;
    } else {
      editFeedback = false;
    }
    if (editButtons.length > 0) {
      for (let i = editButtons.length; i > 0; i--) {
        if (disable) {
          editButtons[i - 1]['disabled'] = true;
          editButtons[i - 1]['style'].cursor = 'not-allowed';
        } else {
          editButtons[i - 1]['disabled'] = false;
          editButtons[i - 1]['style'].cursor = 'pointer';
        }
      }
    }
}
export const getAIAnswerTextPosition = (prefix, spanText, search, start, isRecursive = false) => {
    const currIndex = spanText.indexOf(search);
    const totalStrlength = isRecursive ? prefix.length + currIndex : prefix.length > 0 ? prefix.length + currIndex + 1 : prefix.length + currIndex
    if (currIndex != -1 && (totalStrlength === start || (start >= (totalStrlength - 2) && start <= (totalStrlength + 2)))) {
        return true;
    } else if (spanText.length > 0) {
        let subStrIndex = 0
        if (currIndex > -1) {
            prefix += spanText.slice(0, currIndex + search.length)
            subStrIndex = currIndex + search.length
        } else {
            prefix += spanText
            subStrIndex = spanText.length
        }
        return getAIAnswerTextPosition(prefix, spanText.slice(subStrIndex), search, start, true)
    } else {
        return false;
    }
}

export const doesContainChildMarkedContent = (node) => {
    const markedContentNode = ([...(node?.childNodes || [])] || []).filter(spanEl => {
        if (spanEl.tagName === 'SPAN' && spanEl.className.includes('markedContent')) {
            return true;
        }
    });
    return markedContentNode.length > 0;
}

export const getPresentationNodes = (node, presentationNodes = []) => {
    let finalNodes = [...presentationNodes];
    [...(node?.childNodes || [])].forEach(childNode => {
        if (childNode.className.includes('markedContent')
            && childNode.childNodes
            && childNode.childNodes.length) {
            finalNodes = getPresentationNodes(childNode, finalNodes)
        } else {
            finalNodes.push(childNode)
        }
    });
    return finalNodes;
}

export const stripAndConvertPixelStringToFloat = (text) => {
    const value = text.replace('px', '');
    if (value === '' || Number(value).toString() === 'NaN') {
        return 0;
    }
    return parseFloat(value);
}

export const getScaleX = (spanEl) => {
    let scaleXValue;
    if (spanEl.style && spanEl.style.transform && spanEl.style.transform.split('scaleX(').length > 0) {
        const partialData = spanEl.style.transform.split('scaleX(');
        if (partialData.length > 1 && partialData[1].split(')').length > 0) {
            scaleXValue = partialData[1].split(')')[0]
            scaleXValue = parseFloat(scaleXValue)
        }
    }
    if(!scaleXValue){
        scaleXValue = 1;
    }
    return scaleXValue;
}

export const removeTrailingBRElements = boxTextElements => {
    let filterStartElement; // First element of selection without excess <br>
    let filterEndElement; // Last element of selection without excess <br>
    let startIndex = 0; // Start index of selection without excess <br>
    let endIndex = boxTextElements && boxTextElements.length - 1 // End index of selection without excess <br>
    while (
        boxTextElements
        && startIndex < boxTextElements.length
        && endIndex >= 0
        && (!filterStartElement || !filterEndElement)
    ) {
        // Check to stop iterating from start of array once iterator startIndex reaches the first span
        if (!filterStartElement) {
            if (boxTextElements[startIndex].tagName.toLowerCase() === 'span' || boxTextElements[startIndex].tagName.toLowerCase() === 'rect') {
                filterStartElement = boxTextElements[startIndex]
            } else {
                //Check to log any unexpected type of element in DOM tree
                if (boxTextElements[startIndex].tagName.toLowerCase() !== 'br') {
                    console.log('Failed to filter non span element - ')
                    console.log(boxTextElements[startIndex])
                }
                startIndex++
            }
        }
        // Check to stop iterating from end of array once iterator endIndex reaches the last span
        if (!filterEndElement) {
            if (boxTextElements[endIndex].tagName.toLowerCase() === 'span' || boxTextElements[endIndex].tagName.toLowerCase() === 'rect') {
                filterEndElement = boxTextElements[endIndex]
            } else {
                //Check to log any unexpected type of element in DOM tree
                if (boxTextElements[endIndex].tagName.toLowerCase() !== 'br') {
                    console.log('Failed to filter non span element - ')
                    console.log(boxTextElements[endIndex])
                }
                endIndex--
            }
        }
    }
    try {
        return boxTextElements.slice(startIndex, endIndex + 1) // Delete out-of-bounds <br> elements
    } catch (error) {
        console.log('Unable to parse text from selection: ')
        console.log(error)
        return boxTextElements
    }
}

export const stitchSpanTextWithPredictiveSpaces = (middleSpans, initialText = null) => { // To stitch text together from spans, while adding space to ends of each line
    const spansToStitch = initialText ? middleSpans.slice(1, middleSpans.length - 1) : middleSpans.slice(0, middleSpans.length - 1)
    let finalText = '';
    (spansToStitch || []).forEach(span => {
        if (span.tagName === 'BR') {
            if (
                span.previousSibling
                && span.previousSibling.textContent[span.previousSibling.textContent.length - 1] !== ' '
                && span.nextSibling.textContent[0] !== ' '
            ) {
                finalText += ' '
            } else if (
                span.parentElement
                && (
                    span.parentElement.previousSibling
                    && span.parentElement.previousSibling.className === 'markedContent'
                    && span.parentElement.previousSibling.lastChild
                    && span.parentElement.previousSibling.lastChild.tagName === 'SPAN'
                    && span.parentElement.previousSibling.lastChild.textContent[span.parentElement.previousSibling.lastChild.textContent.length - 1] !== ' '
                )
                && span.nextSibling
                && span.nextSibling.textContent[0] !== ' ') {
                finalText += ' '
            } else {
                finalText += ''
            }
        } else {
            finalText += span.textContent
        }
    })
    return initialText ? initialText + finalText : finalText
}

export const getTextInsideSelection = (el, boundingCoordinates, pageNumber) => {
    // boundingCoordinates can be either bounding box coordinates
    // or an array of the top-left and bottom-right coords of the span
    try {
        const elementComputedStyle = !el.isOCR && window.getComputedStyle(el);
        const webkitTransform = elementComputedStyle?.webkitTransform;
        const fontFamily = elementComputedStyle?.fontFamily;
        let fontSize = elementComputedStyle?.fontSize;
        const left = el.offsetLeft;
        const width = el.offsetWidth;
        const elText = el.textContent;
        const boxEndX = boundingCoordinates[2];
        const boxStartX = boundingCoordinates[0];
        // eslint-disable-next-line no-undef
        const eleMatrix = new WebKitCSSMatrix(webkitTransform);
        // width of the span falling outside the right edge of box
        const rightEndBleed = ((left + width * eleMatrix.m11) > boxEndX) ? (left + (width * eleMatrix.m11) - boxEndX) : 0;
        // width of the span falling outside the left edge of box
        const leftEndBleed = boxStartX > left ? boxStartX - left : 0;
        // element width, adjusted for transform scale
        const elementWidth = width * eleMatrix.m11;
        // % of span width outside right edge
        const percentageOmittedRight = (rightEndBleed * 100) / elementWidth;
        // % of span width outside left edge
        const percentageOmittedLeft = (leftEndBleed * 100) / elementWidth;
        let selectedStartIndex = elText.length;
        let selectedEndIndex;
        fontSize = !fontSize ? el.offsetHeight : fontSize;
        if (left > boxEndX) {
            return '';
        }
        if (percentageOmittedLeft > 99 || percentageOmittedRight > 99) {
            return ''
        } else {
            if (parseInt(percentageOmittedLeft + '') > 0 && elText.length > 2) {

                for (let counter = 0; counter < elText.length; counter++) {
                    // Calculate width of substring from start of text incrementally
                    const substrWidth = getTextWidth(elText.substr(0, counter), pageNumber, `${fontSize} ${fontFamily}`)
                    if (substrWidth >= percentageOmittedLeft * width / 100) {
                        // If width of substr is equal to or exceeds leftOmittedWidth, mark that index as the start
                        selectedStartIndex = counter
                        break;
                    }
                }
            } else {
                selectedStartIndex = 0
            }
            if (parseInt(percentageOmittedRight + '') > 0) {
                for (let index = elText.length; index >= 0; index--) {
                    // Calculate width of substring from start of text decrementally
                    const substrWidth = getTextWidth(elText.substr(0, index), pageNumber, `${fontSize} ${fontFamily}`);
                    const percentageIncludedLeft = 100 - percentageOmittedRight
                    if (substrWidth <= percentageIncludedLeft * width / 100) {
                        // If width of text is equal to or lesser than total left side text width, mark index as the end
                        selectedEndIndex = index
                        break;
                    }
                }
            } else {
                selectedEndIndex = 0
            }
            return selectedEndIndex === 0 ? elText.substr(selectedStartIndex) : elText.substr(selectedStartIndex, selectedEndIndex - selectedStartIndex + 1)
        }
    } catch (e) {
        return ''
    }
}

export const getSelectedTextFromBoxElements = (
    scale,
    elementList,
    boxCoordinates,
    pageNumber,
    startCursorCoordinates = null,
    endCursorCoordinates = null
) => {
    if(startCursorCoordinates 
        && endCursorCoordinates
        && Object.keys(startCursorCoordinates).length == 4
        && Object.keys(endCursorCoordinates).length == 4){
            startCursorCoordinates = {x: startCursorCoordinates.x1, y: startCursorCoordinates.y1}
            endCursorCoordinates = {x: endCursorCoordinates.x1, y: endCursorCoordinates.y1}
    }
    let stitchedText = ''
    for (let counter = 0; counter < elementList.length; counter++) {
        if ((counter > 0 && elementList[counter].parentNode && elementList[counter].parentNode.isSameNode(elementList[counter - 1].parentNode)) || counter === 0) {
            if (elementList[counter].tagName.toLowerCase() === 'span') {
                let updateBoxCoordinates = []
                updateBoxCoordinates = [
                    startCursorCoordinates && counter === 0 ? startCursorCoordinates.x : boxCoordinates[0],
                    boxCoordinates[1],
                    endCursorCoordinates && counter === elementList.length - 1 && (Math.abs(elementList[counter].offsetTop - endCursorCoordinates.y) <= 2 * scale) ? endCursorCoordinates.x : boxCoordinates[2],
                    boxCoordinates[3]
                ]
                const textInsideBox = getTextInsideSelection(elementList[counter], updateBoxCoordinates, pageNumber)
                stitchedText += textInsideBox
            } else if (elementList[counter].tagName.toLowerCase() !== 'br') { // In case a non-span + non-br element shows up
                stitchedText += ''
                console.log('Unable to parse non-span element: ', elementList[counter])
            } else if (elementList[counter].tagName.toLowerCase() === 'br') { //condition : if br is there then add a space
                stitchedText += ' '
            }
        } else {
            stitchedText += (counter > 0 && elementList[counter].textContent != ' ' ? ' ' : '') + elementList[counter].textContent
        }
    }
    return stitchedText.trim()
}
//answerLiesInMultipleSpans is now called feedbackLiesInMultipleSpans
const feedbackLiesInMultipleSpans = (pageNumber, spanIndex, answer, answerSpans, spanEl, prevSpansText, textList) => {
    const {
        fontFamily,
        fontSize
    } = window.getComputedStyle(spanEl);
    const scaleXValue = getScaleX(spanEl);
    let xPosition = spanEl.offsetLeft;
    let highlightElWidth = spanEl.offsetWidth * scaleXValue;
    const spanText = spanEl.textContent;
    const width = spanEl.offsetWidth * scaleXValue;
    let leftTrimPercentage = 0;
    let rightTrimPercentage = 0;
    if (spanIndex === 0) {
        let startFound = false;
        let index = 0
        while (!startFound && index < spanText.length) {
            if ( // Calculating highlight start position of first span by calculating width of omitted text
                spanText.substr(index)[0] !== ' '
                && answer.indexOf(spanText.substr(index).replaceAll('  ', ' ')) === 0
            ) {
                startFound = true
                const omittedWidth = getTextWidth(
                    spanText.substr(0, index),
                    pageNumber,
                    `${fontSize} ${fontFamily}`
                ) * scaleXValue // Calculate width of excluded text of first span
                leftTrimPercentage = (omittedWidth * 100) / width
                xPosition = spanEl.offsetLeft + (leftTrimPercentage * width) / 100 - 3
                prevSpansText += spanText.substr(index)
                textList.push(spanText.substr(index))
            } else {
                index++
            }
        }
    }
    if (spanIndex !== answerSpans.length - 1 && spanIndex !== 0) {
        prevSpansText += spanText
        textList.push(spanText)
    }
    if (spanIndex === answerSpans.length - 1) {
        let endFound = false;
        let spanTextLength = spanText.length
        while (!endFound && spanTextLength >= 0) {
            if ( // Calculating highlight width of last span by calculating width of omitted text
                spanText.substr(0, spanTextLength)[spanTextLength - 1] !== ' '
                && (
                    answer === prevSpansText.replaceAll('  ', ' ') + spanText.substr(0, spanTextLength).replaceAll('  ', ' ')
                    || answer === replaceAll(stitchSpanTextWithPredictiveSpaces(answerSpans, textList[0]), '  ', ' ') + spanText.substr(0, spanTextLength).replaceAll('  ', ' ')
                )
            ) {
                endFound = true
                const omittedWidth = getTextWidth(
                    spanText.substr(spanTextLength),
                    pageNumber,
                    `${fontSize} ${fontFamily}`
                ) * scaleXValue // Calculate width of excluded text of first span
                rightTrimPercentage = (omittedWidth * 100) / width
            } else {
                spanTextLength--
            }
        }
    }
    const totalTrimPercentage = (leftTrimPercentage + rightTrimPercentage)
    highlightElWidth = totalTrimPercentage > 0 ? (highlightElWidth * (100 - totalTrimPercentage) / 100) + 3 : highlightElWidth;
    return {
        highlightElWidth,
        xPosition,
        textList,
        prevSpansText
    }
}
//answerLiesInSingleSpan is now called feedbackLiesInSingleSpan
const feedbackLiesInSingleSpan = (pageNumber, answer, start, spanEl, predictiveCompleteText) => {
    const {
        fontFamily,
        fontSize
    } = window.getComputedStyle(spanEl);
    const scaleXValue = getScaleX(spanEl);
    let xPosition = spanEl.offsetLeft;
    let highlightElWidth = spanEl.offsetWidth * scaleXValue;
    const spanText = spanEl.textContent;
    const width = spanEl.offsetWidth * scaleXValue;
    let startFound = false;
    let endFound = false;
    let index = 0;
    let finalStart = 0;
    let finalEnd = spanText.length - 1;
    let elementWidthPercentage
    const indexOfAnswerSpan = predictiveCompleteText.indexOf(spanText)
    const startMinusOne = start - 1;
    const startMinusTwo = start - 2;
    while (!startFound && index < spanText.length) {
        if ( // Calculating highlight start position of span by calculating width of omitted text
            start 
            && start > -1
            && spanText.substr(index)[0] !== ' '
            && spanText.substr(index).replaceAll('  ', ' ').indexOf(answer) === 0
            && (
                (
                    start === (indexOfAnswerSpan + index)
                    || startMinusOne === (indexOfAnswerSpan + index)
                    || startMinusTwo === (indexOfAnswerSpan + index)
                    || startMinusOne === index
                    || startMinusTwo === index
                    || start === index 
                    //|| predictiveCompleteText.indexOf(answer) === (indexOfAnswerSpan + index)
                )
            )) {
            startFound = true
            finalStart = index
            const omittedWidth = getTextWidth(
                spanText.substr(0, index),
                pageNumber,
                `${fontSize} ${fontFamily}`
            ) * scaleXValue // Calculating width of excluded text
            xPosition = spanEl.offsetLeft + omittedWidth
        } else if (spanText.substr(index)[0] !== ' '
            && spanText.substr(index).replaceAll('  ', ' ').indexOf(answer) === 0) {
            //this blocks handles a scenario when only semantic feedback is given and that semantic
            // feedback falls inside 1 single span
            startFound = true
            finalStart = index
            const omittedWidth = getTextWidth(
                spanText.substr(0, index),
                pageNumber,
                `${fontSize} ${fontFamily}`
            ) * scaleXValue // Calculating width of excluded text
            xPosition = spanEl.offsetLeft + omittedWidth

        } else {
            index++
        }
    }
    if (startFound) {
        while (!endFound) {
            if (spanText.substr(finalStart, index - finalStart + 1).replaceAll('  ', ' ') === answer) {
                endFound = true
                finalEnd = index
                elementWidthPercentage = (
                    getTextWidth(
                        spanText.substr(finalStart, index - finalStart + 1),
                        pageNumber,
                        `${fontSize} ${fontFamily}`
                    ) * 100 * scaleXValue
                ) / width // Calculating element Width percentage instead of rightTrimPercentage
            } else {
                index++
            }
        }
    }
    highlightElWidth = (highlightElWidth * elementWidthPercentage) / 100 + 3;
    return {
        highlightElWidth,
        xPosition
    }
}

//getSpansForSelectedText is used to be called highlightSavedFeedbackText in eyde
const getSpansForSelectedText = (scale, boundingBox, pageNumber, feedbackObj = { feedbackText: '', startIndex: '' }, aiBoxSpanDetails) => {
    try {
        const { feedbackText, startIndex } = feedbackObj;
        const boxSpans = aiBoxSpanDetails.spans || [];
        let completeText = aiBoxSpanDetails.feedbackText;
        if (!completeText) {
            return
        }
        let startMatchFound = 0;
        let endMatchFound = 0;
        let leftTrimmedSpans = [];
        let finalSpans = [];
        let finalText = '';
        let fetchedText = '';
        let prevFetchedText = '';
        let trialStartIndex = 0;
        const spanBox = boxSpans;
        let trialEndIndex = spanBox.length;
        const spanList = (spanBox || []).filter((ele) => ele.tagName === 'SPAN');
        const lastSpan = spanList[spanList.length - 1];
        let primaryStringComparisonCheck = false
        const predictiveCompleteText = replaceAll(stitchSpanTextWithPredictiveSpaces(spanBox, finalText), '  ', ' ') + (lastSpan && lastSpan.textContent ? lastSpan.textContent : '');
        if (
            completeText.includes(feedbackText)
            || (
                !completeText.includes(feedbackText)
                && replaceAll(completeText, '  ', ' ').includes(feedbackText) // To eliminate double spaces in spans
            )
        ) {
            primaryStringComparisonCheck = true
        } else if (
            predictiveCompleteText.includes(feedbackText)
            || (
                !predictiveCompleteText.includes(feedbackText)
                && replaceAll(predictiveCompleteText, '  ', ' ').includes(feedbackText) // To eliminate double spaces in spans
            )
        ) {
            completeText = predictiveCompleteText
            primaryStringComparisonCheck = true
        } else {
            completeText = predictiveCompleteText
        }

        if (primaryStringComparisonCheck) {
            let prefixText = '';
            while (startMatchFound === 0 && trialStartIndex <= trialEndIndex) {
                fetchedText = getSelectedTextFromBoxElements(
                    scale,
                    spanBox.slice(trialStartIndex, trialEndIndex),
                    boundingBox,
                    pageNumber
                )
                if ((fetchedText === feedbackText) || (completeText === feedbackText)) {
                    leftTrimmedSpans = spanBox.slice(trialStartIndex, trialEndIndex)
                    finalText = fetchedText
                    startMatchFound = 1
                } else if (
                    fetchedText.includes(feedbackText)
                    || replaceAll(fetchedText, '  ', ' ').includes(feedbackText)
                ) {
                    prevFetchedText = fetchedText
                    // Fix for the scenario where same string presents multiple times in the aiBox it was taking last occurence, please refer bug 3149489
                    let isAIAnswerPresentAtPos = '';
                    if (feedbackText) {
                        isAIAnswerPresentAtPos = getAIAnswerTextPosition(
                            prefixText.trim(),
                            spanBox[trialStartIndex].textContent,
                            feedbackText,
                            startIndex
                        )
                    }
                    if (spanBox[trialStartIndex]?.tagName.toLowerCase() === 'span') {
                        prefixText += spanBox[trialStartIndex].textContent;
                    } else if (spanBox[trialStartIndex]?.tagName.toLowerCase() === 'br') { //condition : if br is there then add a space
                        prefixText += ' '
                    }
                    if (spanBox[trialStartIndex]?.tagName === 'SPAN' && isAIAnswerPresentAtPos) {
                        // Checking if its a AiBox without any selected manual feedback, checking for start index and
                        // if first word is present in span text
                        leftTrimmedSpans = spanBox.slice(trialStartIndex, trialEndIndex)
                        startMatchFound = 1
                    }
                    trialStartIndex++
                } else {
                    leftTrimmedSpans = spanBox.slice(trialStartIndex - 1, trialEndIndex)
                    startMatchFound = 1
                }
            }
            trialEndIndex = leftTrimmedSpans.length
            while (endMatchFound === 0 && trialEndIndex >= 0) {
                fetchedText = replaceAll(getSelectedTextFromBoxElements(
                    scale,
                    leftTrimmedSpans.slice(0, trialEndIndex),
                    boundingBox,
                    pageNumber
                ), '  ', ' ')
                if (fetchedText === feedbackText) {
                    finalSpans = leftTrimmedSpans.slice(0, trialEndIndex)
                    finalText = fetchedText
                    endMatchFound = 1
                } else if (fetchedText.includes(feedbackText)) {
                    trialEndIndex--
                    prevFetchedText = fetchedText
                } else {
                    finalSpans = leftTrimmedSpans.slice(0, trialEndIndex + 1)
                    finalText = prevFetchedText
                    endMatchFound = 1
                }
            }
            return finalSpans;
        } else {
            // below logic is a retry mechanism on paragraphs where unpredictable spaces exists in pdf file.
            const aiArray = (feedbackText.split(' ') || []).filter(t2 => !!t2);
            let aiSpanArray = [];
            let verifiedTxt = '';
            let allSpansFound = false;
            boxSpans.forEach(sp => {
                if (sp.tagName === 'SPAN' && !allSpansFound) {
                    const formedPartialText = ((verifiedTxt + sp.textContent).split(' ') || []).filter(t2 => !!t2).map(t => t.trim()).join(' ');
                    let found = false;
                    let maxIt = 50;
                    let len = aiArray.length;

                    if (formedPartialText.includes(aiArray.join(' '))) {
                        allSpansFound = true;
                        aiSpanArray.push(sp);
                        verifiedTxt = verifiedTxt + sp.textContent;
                    } else {
                        while (maxIt > 0 && !found) {
                            const tmpArr = [...(aiArray || [])];
                            tmpArr.length = len;
                            if (formedPartialText.includes(tmpArr.join(' ')) && len > 1) {
                                found = true;
                            } else {
                                len = parseInt((len / 2) + '');
                            }
                            maxIt--;
                        }
                        if (found) {
                            aiSpanArray.push(sp);
                            verifiedTxt = verifiedTxt + sp.textContent;
                        } else {
                            aiSpanArray = [];
                            verifiedTxt = '';
                        }
                    }

                }
            })
            if (aiSpanArray.length == 0) {
                //below logic is a retry mechanism where it looks from start of boxSpans and
                //identify all the spans and later filter the spans that does not contain feedback.
                const newBoxSpan = (boxSpans || []).filter(sp => sp.tagName == 'SPAN') || [];
                let index = -1;
                let paragraphText = '';
                const spanList = [];
                const ansFeedbackStr = aiArray.join(' ');
                newBoxSpan.forEach((sp, spIndex) => {
                    if (index == -1) {
                        spanList.push(sp)
                        paragraphText = paragraphText + sp.textContent;
                        if (paragraphText.includes(ansFeedbackStr)) {
                            index = spIndex;
                        }
                    }
                })
                //filtering the spans that does not contain feedback.
                const startIgnoredText = paragraphText.substring(0, paragraphText.indexOf(ansFeedbackStr))
                if (startIgnoredText == '') {
                    aiSpanArray = [...(spanList || [])];
                } else if (startIgnoredText != '' && spanList.length > 1) {
                    let { textContent } = spanList[0];
                    for (let counter = 1; counter < spanList.length; counter++) {
                        textContent = textContent + spanList[counter].textContent;
                        if (startIgnoredText.indexOf(textContent) === -1) {
                            aiSpanArray.push(spanList[counter])
                        }
                    }
                }
            }
            const flag = boxSpans.find(spanEl => spanEl.tagName === 'SPAN');
            return aiSpanArray.length > 0 ? aiSpanArray : flag ? boxSpans : [];
        }
    } catch (error) {
        console.log('Unable to parse/highlight saved text -> ')
        console.log(error)
    }
}

export const findSpansUnderSVGBox = (scale, pageNo, coordinates) => {
    const container = document.getElementById("viewerContainer");
    let selectedNode = null
    selectedNode = container.querySelector(`div[data-page-number="${pageNo}"]`);
    if (selectedNode) {
        const boxTextElements = []; // Span list based on coordinates.
        const textLayer = selectedNode.getElementsByClassName('textLayer')[0]; //Picks up all children spans (paragraphs) of textLayer
        const textElements = [];
        // Takes the children of each paragraph span, and gathers the actual text spans
        if (textLayer && textLayer.childNodes && textLayer.childNodes.length) {
            (textLayer.childNodes || []).forEach(el => {
                if (
                    el.tagName.toLowerCase() === 'span'
                    && el.className.includes('markedContent')
                    && el.childNodes && el.childNodes.length
                ) {
                    if (doesContainChildMarkedContent(el)) {
                        [...(el?.childNodes || [])].forEach(element => {
                            if (doesContainChildMarkedContent(element)) {
                                const presentationNodes = getPresentationNodes(element) || [];
                                textElements.push(...presentationNodes);
                            } else {
                                textElements.push(element);
                            }
                        });
                    } else {
                        textElements.push(...(el?.childNodes || []))
                    }
                } else if (el.tagName.toLowerCase() === 'span') {
                    textElements.push(el)
                } else if (el.tagName.toLowerCase() === 'br' && el.nextSibling && el.nextSibling.tagName.toLowerCase() === 'span') {
                    textElements.push(el)
                }
            });
        }
        if (textLayer && textElements && textElements.length) {
            // If text spans exist, filter them based on x and y coordinates of svg box selection
            for (let counter = 0; counter < textElements.length; counter++) {
                const el = textElements[counter]
                const scaleXValue = getScaleX(textElements[counter]);
                const elHeight = textElements[counter].offsetHeight
                const elWidth = textElements[counter].offsetWidth * scaleXValue;
                const computedStyle = window.getComputedStyle(textElements[counter]);
                //let cssScaleFactor = stripAndConvertPixelStringToFloat(computedStyle.getPropertyValue('--scale-factor')) || 1
                // cssScaleFactor is failing on zoom in zoom out logic. Need to revisit it later if we see any abnormalities.
                let cssScaleFactor = 1;
                let x1 = stripAndConvertPixelStringToFloat(computedStyle.getPropertyValue('left')) * cssScaleFactor
                let y1 = stripAndConvertPixelStringToFloat(computedStyle.getPropertyValue('top')) * cssScaleFactor
                const x2 = x1 + elWidth // element bottom-right coordinates
                const y2 = y1 + elHeight // element bottom-right coordinates
                if (
                    (
                        x1 && y1 && x2 && y2
                        && (
                            x1 > coordinates[0] && x1 < coordinates[2] // Check if span's top-left lies inside svg box
                            || x2 > coordinates[0] && x2 < coordinates[2] // Check if span's bottom-right lies inside svg box
                            || x1 < coordinates[0] && x2 > coordinates[2] // Check if span's body lies inside svg box, but coordinates lie outside
                        )
                        && (
                            y1 > coordinates[1] && y1 < coordinates[3] // Check if span's top-left lies inside svg box
                            || y2 > coordinates[1] && y2 < coordinates[3] // Check if span's bottom-right lies inside svg box
                            || y1 < coordinates[1] && y2 > coordinates[3] // Check if span's body lies inside svg box, but coordinates lie outside
                        )
                        && (
                            textElements[counter].offsetHeight * 0.75 < coordinates[3] - y1 // Final Line: Check if final y coordinate line covers more than 75% of the text span
                            && textElements[counter].offsetHeight * 0.25 > coordinates[1] - y1 // Initial Line: Check if initial y coordinate line excludes less than 25% of the text span
                        )
                    )
                    || ((!x1 || !y1) && textElements[counter].tagName === 'BR') // To allow all <br> in the page (ones not in the selection to be filtered in next block of code)
                ) {
                    boxTextElements.push(textElements[counter]);
                }
            }

            // Filter out unnecessary br tags from start and end of list
            const removeUnUnsed = removeTrailingBRElements(boxTextElements)
            const validSpans = [];
            for (let counter = 0; counter < removeUnUnsed.length; counter++) {
                const ele = removeUnUnsed[counter];
                const scaleXValue = getScaleX(ele);
                const left = ele.offsetLeft;
                const top = ele.offsetTop;
                const right = left + ele.offsetWidth * scaleXValue;
                if ((coordinates[1] < top < coordinates[3])) {
                    if ((left <= coordinates[0] && right >= coordinates[0] && right <= coordinates[2]) //start of span is outside of box but end of span is inside of box
                        || (left >= coordinates[0] && right <= coordinates[2]) //start of span is inside of box and end of span is inside of box
                        || (left >= coordinates[0] && right >= coordinates[2]) //start of span is inside of box and end of span is outside of box
                        || (left <= coordinates[0] && right >= coordinates[2]) //start of a span is outside of the box and end of the span is also outside of the box.
                        || ele.tagName.toLowerCase() === 'br') {
                        validSpans.push(ele);
                    }
                }
            }
            const stitchedText = getSelectedTextFromBoxElements(scale, validSpans, coordinates, pageNo)
            return {
                spans: boxTextElements,
                feedbackText: stitchedText
            }
        } else {
            return {
                spans: [],
                feedbackText: null
            }
        }
    } else {
        return {
            spans: [],
            feedbackText: null
        }
    }
}
//semanticFeedbackText is needed to evaluate exact answer position using start and end index
export const highlightFeedbackText = async (scale, boundingBox, pageNumber, feedbackObj = {}, isAiBox=false, isAnswer=false, semanticFeedbackText='') => {

    const w = +(boundingBox[2] - boundingBox[0]).toFixed(3);
    const h = +(boundingBox[3] - boundingBox[1]).toFixed(3);
    let feedbackText = feedbackObj['text']
    let startIndex = feedbackObj['startIndex'] || feedbackObj['boundingBox']?.startIndex
    const pdfViewer = document.querySelector(`[data-page-number="${pageNumber}"]`);
    const svg = pdfViewer && pdfViewer.querySelector('.drawingPlane');
    const svgGroup = svg && isAiBox ? svg.querySelectorAll('[id="aiFeedback"]')[0] : svg.querySelector(`[id="resizeID-${w}-${h}"]`);
    const aiBoxSpanDetails = findSpansUnderSVGBox(scale, pageNumber, boundingBox);
    const boxSpans = aiBoxSpanDetails.spans;
    const spanBox = removeTrailingBRElements(boxSpans);
    const spanList = (spanBox || []).filter((ele) => ele.tagName === 'SPAN');
    const lastSpan = spanList[spanList.length - 1];
    const predictiveCompleteText = replaceAll(stitchSpanTextWithPredictiveSpaces(spanBox), '  ', ' ') + (lastSpan && lastSpan.textContent ? lastSpan.textContent : '');

    //if feedbackText contains \n, it is causing highlight issue. Refer below bug
    //https://eysbp.visualstudio.com/EY%20Fabric%20Intelligence%20Layer/_workitems/edit/5272544
    feedbackText = feedbackText.trim();
    if(feedbackText.indexOf('\n') > -1){
        feedbackText = replaceAll(feedbackText, '\n', '')
    }
    if(startIndex && semanticFeedbackText && predictiveCompleteText.indexOf(semanticFeedbackText) > -1){
        startIndex = predictiveCompleteText.indexOf(semanticFeedbackText) + startIndex - 1;
    }
    const spanElements = feedbackText && getSpansForSelectedText(scale, boundingBox, pageNumber, { feedbackText: feedbackText, startIndex }, aiBoxSpanDetails);
    let containsSpans;
    let hasFeedbackBeenHighlighted;
    if (spanElements && svgGroup) {
        containsSpans = spanElements.some((spanEl) => spanEl.tagName == 'SPAN');
        if (isAiBox) {
            if ((feedbackObj['parentId'] && feedbackObj['id']) || (feedbackObj['text'] != '' && !isEmpty(feedbackObj['boundingBox']))) {
                //removing the ai answer highlights
                svgGroup.querySelectorAll(`[aiAnswerTextSVGRect="aiAnswerTextSVGRect"]`).forEach(highlight => {
                    highlight.remove()
                })
                hasFeedbackBeenHighlighted = svgGroup.querySelector(`[selectedTextSVGRect="selectedTextSVGRect"][highlightType="Answer"]`)
            } else {
                hasFeedbackBeenHighlighted = svgGroup.querySelector(`[aiAnswerTextSVGRect="aiAnswerTextSVGRect"]`);
            }
        } else {
            if (isAnswer) {
                hasFeedbackBeenHighlighted = svgGroup.querySelector(`[selectedTextSVGRect="selectedTextSVGRect"][highlightType="Answer"]`)
            } else {
                hasFeedbackBeenHighlighted = svgGroup.querySelector(`[selectedTextSVGRect="selectedTextSVGRect"][highlightType="Semantic"]`);
            }
        }
    }
    if (feedbackText && spanElements && containsSpans && svg && !hasFeedbackBeenHighlighted) {
        let prevSpansText = '';
        let textList = [];

        (spanElements || []).forEach((spanEl, spanIndex) => {
            if (spanEl.tagName === 'SPAN') {
                const scaleXValue = getScaleX(spanEl);
                let xPosition = spanEl.offsetLeft;
                let highlightElWidth = spanEl.offsetWidth * scaleXValue;
                if (spanElements.length > 1) {
                    const dataObj = feedbackLiesInMultipleSpans(pageNumber, spanIndex, feedbackText, spanElements, spanEl, prevSpansText, textList);
                    xPosition = dataObj.xPosition;
                    highlightElWidth = dataObj.highlightElWidth;
                    prevSpansText = dataObj.prevSpansText;
                    textList = [...(dataObj.textList || [])];
                } else {
                    const dataObj = feedbackLiesInSingleSpan(pageNumber, feedbackText, startIndex, spanEl, predictiveCompleteText);
                    xPosition = dataObj.xPosition;
                    highlightElWidth = dataObj.highlightElWidth;
                }
                const highlightColor = isAiBox ? ((feedbackObj['parentId'] && feedbackObj['id']) || (feedbackObj['text'] && !isEmpty(feedbackObj['boundingBox']))) ? 'darkgreen' : '#B57BBA' : isAnswer ? 'darkgreen' : 'green';
                const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                rect.setAttribute('class', 'rectSelect selectionArea');
                rect.setAttributeNS(null, 'x', xPosition);
                rect.setAttributeNS(null, 'y', spanEl.offsetTop);
                rect.setAttributeNS(null, 'width', highlightElWidth > 0 ? highlightElWidth.toString() : 0 + '');
                rect.setAttributeNS(null, 'height', spanEl.offsetHeight);
                rect.setAttribute('fill', highlightColor);
                rect.setAttribute('fill-opacity', isAiBox ? feedbackObj['parentId'] && feedbackObj['id'] ? '0.4' : '0.5' : '0.2');
                rect.setAttribute('id', `rect-${spanEl.offsetWidth}-${spanEl.offsetHeight}`);
                //feedbackObj['boundingBox'] is present when user provide answer feedback inside ai box and changes the scale before saving it
                if (isAiBox && !feedbackObj['parentId'] && !feedbackObj['id'] && !feedbackObj['boundingBox']) {
                    rect.setAttribute('aiAnswerTextSVGRect', 'aiAnswerTextSVGRect');
                    rect.setAttribute('highlightType', lablesAndMessage['answerBtn']);
                } else if (isAnswer) {
                    rect.setAttribute('selectedTextSVGRect', 'selectedTextSVGRect');
                    rect.setAttribute('highlightType', lablesAndMessage['answerBtn']);
                } else {
                    rect.setAttribute('selectedTextSVGRect', 'selectedTextSVGRect');
                    rect.setAttribute('highlightType', lablesAndMessage['semanticBtn']);
                }
                rect.setAttribute('cursor', 'pointer');
                svgGroup.appendChild(rect);
            } else if (spanEl.tagName === 'BR') {
                prevSpansText = prevSpansText + ' ';
                textList.push(' ')
            }
        });
    }
}

export const setEventListeners = (pageNo, mouseDownEventCB) => {
    const pdfViewer = document.querySelector(`[data-page-number="${pageNo}"]`);
    let svg = pdfViewer && pdfViewer.querySelector('.drawingPlane');
    svg && svg.parentNode && svg.parentNode.replaceChild(svg.cloneNode(true), svg);
    svg = pdfViewer && pdfViewer.querySelector('.drawingPlane');
    const svgPoint = (elem, x, y) => {
        const p = svg['createSVGPoint']();
        p.x = x;
        p.y = y;
        return p.matrixTransform(elem.getScreenCTM().inverse());
    }

    if (svg) {
        // below event is for hiding edit buttons over manual feedback box
        //when user move cursor away from feedback box.
        svg.addEventListener('mouseover', (event) => {
            if (event.target['tagName'].toLowerCase() != 'rect') {
                const pageObj = document.querySelector(`[data-page-number="${pageNo}"]`);
                const buttons = pageObj.getElementsByTagName('BUTTON');
                for (let i = 0; i < buttons.length; i++) {
                    if (buttons[i].id.indexOf('saveButton') === -1 && buttons[i].id.indexOf('copyButton') === -1 && buttons[i].id.indexOf('discardButton') === -1) {
                        buttons[i]['style'].visibility = 'hidden';
                    }
                }
            }
        })
        const mouseDownEventCBPrimary = event => {
            mouseDownEventCB(event, svg, svgPoint, pdfViewer, pageNo);
        };

        svg.addEventListener('mousedown', mouseDownEventCBPrimary);
    }
}

export const removeInvalidFeedbackBox = (
    mouseDownEventCB,
    elementsObj,
    pageNo,
    editFeedback,
    eyToast,
    group: Element = null,
    showMessage = false
) => {
    const {rect, circle, svg, saveButton, discardButton, copyButton} = elementsObj;
    rect && rect.remove();
    circle && circle.remove();
    //removing all events on svg
    svg && svg.parentNode && svg.parentNode.replaceChild(svg.cloneNode(true), svg);
    setEventListeners(pageNo, mouseDownEventCB);
    saveButton && saveButton[0] && saveButton[0].remove();
    discardButton && discardButton[0] && discardButton[0].remove();
    copyButton && copyButton[0] && copyButton[0].remove();
    group && group.remove()
    if (showMessage) {
        const groupId = group && group.getAttribute("id");
        document.getElementById(`${groupId}`).remove();
        eyToast("Something went wrong while selecting the text/paragraph. Please try again.", true)
    }
}

export const showHideButtonEventOnSelectionArea = (group, x, y) => {
    const semanticEditButton = document.getElementById(`editButton-${x}-${y}-Semantic`);
    const answerEditButton = document.getElementById(`editButton-${x}-${y}-Answer`);
    group?.addEventListener('mouseover', () => {
        if (semanticEditButton) {
            semanticEditButton.style.visibility = 'unset';
        }
        if (answerEditButton) {
            answerEditButton.style.visibility = 'unset';
        }
    })
    group?.addEventListener('mouseout', () => {
        if (semanticEditButton) {
            semanticEditButton.style.visibility = 'hidden';
        }
        if (answerEditButton) {
            answerEditButton.style.visibility = 'hidden';
        }
    })
}

export const showHideEventOnButtonsAtPage = (pageNo) => {
    let pageObj;
    let svg;
    if (pageNo) {
        pageObj = document.querySelector(`[data-page-number="${pageNo}"]`);
        [svg] = pageObj.getElementsByClassName('drawingPlane');
    } else {
        pageObj = document.getElementById('componentContainer');
        if (pageObj) {
            [svg] = pageObj.getElementsByClassName('drawingPlane');
        }
    }
    const svgGroups = svg?.getElementsByTagName('g') || [];
    for (let i = 0; i < svgGroups.length; i++) {
        if (svgGroups[i].getAttribute('id').indexOf('resizeID') > -1) {
            const selectedRect = svgGroups[i].getElementsByClassName('rectSelect')[0];
            if (selectedRect) {
                const x = selectedRect.getAttribute('x');
                const y = selectedRect.getAttribute('y');
                showHideButtonEventOnSelectionArea(svgGroups[i], x, y)
            }
        }
    }
    const svgElement = document.getElementById('aiFeedback');
    if (svgElement) {
        const selectedRect = svgElement.getElementsByClassName('rectSelect')[0];
        const x = selectedRect.getAttribute('x');
        const y = selectedRect.getAttribute('y');
        setTimeout(() => showHideButtonEventOnSelectionArea(svgElement, x, y), 700);
    }
}

const getExactElementWidth = (currentBox, elementOffsetLeft, elementWidth) => {
    /**
       * this diagram represent below if else block
       * to show different scenario
       *       _______________
       *      |   1------     |
       *   2--|-----          |
       *      |         3-----|-----
       *  4---|---------------|-----
       *      |_______________|
      */
    let offsetWidth;
    if (elementOffsetLeft >= currentBox.x1 && (elementOffsetLeft + elementWidth) <= currentBox.x2) {
        offsetWidth = elementWidth;
    } else if (elementOffsetLeft < currentBox.x1 && (elementOffsetLeft + elementWidth) <= currentBox.x2) {
        offsetWidth = elementOffsetLeft + elementWidth - currentBox.x1;
    } else if (elementOffsetLeft > currentBox.x1 && (elementOffsetLeft + elementWidth) >= currentBox.x2) {
        offsetWidth = currentBox.x2 - elementOffsetLeft;
    } else if (elementOffsetLeft <= currentBox.x1 && (elementOffsetLeft + elementWidth) >= currentBox.x2) {
        offsetWidth = currentBox.x2 - currentBox.x1;
    } else {
        offsetWidth = elementWidth;
    }
    return offsetWidth;
}

export const createUnselectedArea = (spanEl, svgGroup, currentBox) => {
    let offsetLeft; let offsetTop; let offsetWidth; let
        offsetHeight;
    const elementWidth = spanEl.offsetWidth ? spanEl.offsetWidth * parseFloat(getScaleX(spanEl)+'') : undefined;
    if (spanEl.boundingBox) {
        const [boundingBox0, boundingBox1, boundingBox2, boundingBox3] = spanEl.boundingBox || [];
        //this condition will manage ocr data;
        offsetLeft = boundingBox0 >= currentBox.x1 ? boundingBox0 : currentBox.x1;
        offsetTop = boundingBox1;
        offsetWidth = getExactElementWidth(currentBox, boundingBox0, boundingBox2 - boundingBox0)
        offsetHeight = boundingBox3 - boundingBox1;
    } else {
        offsetLeft = (!currentBox || spanEl.offsetLeft >= currentBox.x1) ? spanEl.offsetLeft - (spanEl.addLeftPadding ? 5 : 0) : currentBox.x1;
        offsetTop = spanEl.offsetTop;
        offsetWidth = !currentBox ? elementWidth + (spanEl.addLeftPadding ? 5 : 0) : getExactElementWidth(currentBox, spanEl.offsetLeft, elementWidth);
        offsetHeight = spanEl.offsetHeight + (spanEl.addBottomPadding ? 5 : 0);
    }
    if (svgGroup
        && svgGroup.getElementsByClassName('unselectionArea').length > 0
        && svgGroup.querySelector(`[id='rectUnselected-${offsetWidth}-${offsetHeight}'][x='${offsetLeft}'][y='${offsetTop}'][width='${offsetWidth}']`)) {
        return;
    }
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

    rect.setAttribute('class', 'unselectionArea');
    rect.setAttributeNS(null, 'x', offsetLeft);
    rect.setAttributeNS(null, 'y', offsetTop);
    rect.setAttributeNS(null, 'width', offsetWidth);
    rect.setAttributeNS(null, 'height', offsetHeight);
    rect.setAttribute('fill', 'yello');
    rect.setAttribute('opacity', '0');
    rect.setAttribute('id', `rectUnselected-${offsetWidth}-${offsetHeight}`);
    if (svgGroup) {
        const startCursor = svgGroup.querySelector(".startCursorLine");
        startCursor ? svgGroup.insertBefore(rect, startCursor) : svgGroup.appendChild(rect);
    }
}

const getHighlightRectProps = (spanEl, start, end, scaleXValue, spanEndX, cursorMove=false, cursorPosition={}) => {
    let highlightElWidth;
    let xPosition;
    let newX;
    //when span starts from inside of bounding box
    const isCursorPositionEmpty = isEmpty(cursorPosition);
    const cursorStartX = isCursorPositionEmpty ? undefined : cursorPosition['start'].x;
    const cursorEndX = isCursorPositionEmpty ? undefined : cursorPosition['end'].x;
    if (spanEl.textContent.trim() && spanEl.offsetLeft >= start.x) {

        if (cursorMove && cursorStartX > spanEl.offsetLeft && cursorStartX < spanEndX) {
            const startCursor = document.getElementsByClassName('startCursor')[0];
            //yAxix is the y plane coordinates where cursor is moving currently
            const yAxix = +startCursor.getAttribute('spanYCoordinate');
            const endCursor = document.getElementsByClassName('endCursor')[0];
            const endYAxis = +endCursor.getAttribute('spanYCoordinate');
            if (yAxix == endYAxis || Math.abs(yAxix - endYAxis) < spanEl.offsetHeight / 2) {
                if (cursorStartX < spanEndX && cursorEndX > spanEl.offsetLeft && cursorStartX > spanEl.offsetLeft) {
                    if (cursorEndX < spanEndX) {
                        newX = cursorStartX;
                        xPosition = newX;
                        highlightElWidth = cursorEndX - cursorStartX;
                    } else {
                        newX = cursorStartX;
                        xPosition = newX;
                        highlightElWidth = spanEndX - cursorStartX;
                    }
                } else if (spanEl.offsetLeft > cursorEndX || spanEndX < cursorStartX) {
                    newX = cursorEndX; //doent matter what we set newX here
                    xPosition = newX;
                    highlightElWidth = 0;
                }

            } else if (yAxix == spanEl.offsetTop || Math.abs(yAxix - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                if (spanEl.offsetLeft < cursorStartX) {
                    if (spanEndX > end.x) {
                        newX = cursorStartX;
                        xPosition = newX;
                        highlightElWidth = end.x - cursorStartX;
                    } else {
                        newX = cursorStartX;
                        xPosition = newX;
                        highlightElWidth = spanEndX - cursorStartX;
                    }
                } else {
                    newX = cursorStartX;
                    xPosition = newX;
                    highlightElWidth = spanEndX - cursorStartX;
                }
            } else if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                if (spanEl.offsetLeft < cursorEndX && spanEndX < cursorEndX) {
                    newX = spanEl.offsetLeft;
                    xPosition = newX;
                    highlightElWidth = spanEndX;
                } else if (spanEl.offsetLeft < cursorEndX && spanEndX > cursorEndX) {
                    newX = spanEl.offsetLeft;
                    xPosition = newX;
                    highlightElWidth = cursorEndX - spanEl.offsetLeft;
                }
            } else {
                newX = end.x
            }
        } else if (cursorMove && cursorEndX < spanEl.offsetLeft) {
            const endCursor = document.getElementsByClassName('endCursor')[0];
            const endYAxis = +endCursor.getAttribute('spanYCoordinate');
            if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                newX = cursorStartX; //doent matter what we set newX here
                highlightElWidth = 0;
            } else {
                newX = end.x
            }
        } else if (cursorMove && cursorEndX > spanEl.offsetLeft && cursorEndX < spanEndX) {
            const endCursor = document.getElementsByClassName('endCursor')[0];
            const endYAxis = +endCursor.getAttribute('spanYCoordinate');
            if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                newX = spanEl.offsetLeft;
                xPosition = newX;
                highlightElWidth = cursorEndX - spanEl.offsetLeft;
            } else {
                newX = end.x
            }
        } else {
            newX = end.x
        }
        if (!highlightElWidth && spanEndX < newX) { //starts from inside of the box and ends inside of the box.
            highlightElWidth = spanEl.offsetWidth * scaleXValue;
        } else { //starts inside of the box and ends outside of the box.
            highlightElWidth = highlightElWidth || newX - spanEl.offsetLeft;
        }
        xPosition = xPosition || spanEl.offsetLeft
    } else if (spanEl.textContent.trim() && spanEl.offsetLeft < start.x && spanEndX > end.x) { //span starts outside of the box but ends outside of the box
        if ((spanEl.offsetTop > start.y || Math.abs(spanEl.offsetTop - start.y) <= spanEl.offsetHeight / 2) && (spanEl.offsetTop < end.y || Math.abs(spanEl.offsetTop - end.y) <= spanEl.offsetHeight / 2)) {
            if (cursorMove) {
                const startCursor = document.getElementsByClassName('startCursor')[0];
                const yAxix = +startCursor.getAttribute('spanYCoordinate');
                const endCursor = document.getElementsByClassName('endCursor')[0];
                const endYAxis = +endCursor.getAttribute('spanYCoordinate');
                if (yAxix == endYAxis) {
                    if (cursorStartX < spanEndX && cursorEndX > spanEl.offsetLeft && cursorStartX >= spanEl.offsetLeft) {
                        if (cursorEndX < spanEndX) {
                            newX = cursorStartX;
                            xPosition = newX;
                            highlightElWidth = cursorEndX - cursorStartX;
                        } else {
                            newX = cursorStartX;
                            xPosition = newX;
                            highlightElWidth = spanEndX - cursorStartX;
                        }
                    } else if (spanEl.offsetLeft > cursorEndX || spanEndX < cursorStartX) {
                        newX = cursorEndX; //doent matter what we set newX here
                        xPosition = newX;
                        highlightElWidth = 0;
                    }
                } else if (yAxix == spanEl.offsetTop) {
                    if (start.x > spanEl.offsetLeft) { //starting from outside of the box
                        if (spanEndX > cursorStartX) { //if ending after cursor
                            newX = cursorStartX;
                            xPosition = newX;
                            if (spanEndX > end.x) {
                                highlightElWidth = end.x - cursorStartX;
                            } else {
                                highlightElWidth = spanEndX - cursorStartX;
                            }
                        } else { ////if ending before cursor
                            newX = cursorStartX;
                            xPosition = newX;
                            highlightElWidth = 0;
                        }
                    } else {
                        newX = cursorStartX;
                        xPosition = newX;
                        highlightElWidth = spanEndX - cursorStartX;
                    }
                } else if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                    if (start.x > spanEl.offsetLeft) { //starting from outside of the box
                        if (spanEndX > cursorEndX) { //if ending after cursor
                            newX = start.x;
                            xPosition = newX;
                            highlightElWidth = cursorEndX - start.x;
                        } else { ////if ending before cursor
                            newX = start.x;
                            xPosition = newX;
                            highlightElWidth = spanEndX - start.x;
                        }
                    } else {
                        newX = spanEl.offsetLeft;
                        xPosition = newX;
                        highlightElWidth = cursorEndX - spanEl.offsetLeft;
                    }
                }
            } else {
                highlightElWidth = end.x - start.x;
                xPosition = start.x;
            }
        }
    } else if (spanEl.textContent.trim() && spanEl.offsetLeft < start.x && spanEndX < end.x) { //start outside ofthe box but ends inside of the box
        if ((spanEl.offsetTop + 4 > start.y) && spanEl.offsetTop + 4 < end.y) {
            if (cursorMove) {
                const startCursor = document.getElementsByClassName('startCursor')[0];
                const endCursor = document.getElementsByClassName('endCursor')[0];
                const yAxix = startCursor.getAttribute('spanYCoordinate');
                const endYAxis = +endCursor.getAttribute('spanYCoordinate');
                if (yAxix == spanEl.offsetTop) {
                    newX = cursorStartX;
                    highlightElWidth = spanEndX - newX;
                    if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                        highlightElWidth = cursorEndX - cursorStartX;
                    }
                } else if (endYAxis == spanEl.offsetTop || Math.abs(endYAxis - spanEl.offsetTop) <= spanEl.offsetHeight / 2) {
                    newX = start.x;
                    highlightElWidth = cursorEndX - start.x;
                } else {
                    newX = start.x
                    highlightElWidth = spanEndX - newX;
                }

                xPosition = newX;
            } else {
                highlightElWidth = spanEndX - start.x;
                xPosition = start.x;
            }
        }
    }
    return {
        highlightElWidth,
        xPosition
    };
}

const validateSpanForHighlight = (spanEl, start, end, scaleXValue, cursorMove=false, cursorPosition = {}) => {
    let highlightElWidth;
    let xPosition;
    const spanEndX = spanEl.offsetLeft + spanEl.offsetWidth * scaleXValue;
    if (cursorMove && spanEl.textContent.trim()) {
        const startCursor = document.getElementsByClassName('startCursorLine')[0];
        const endCursor = document.getElementsByClassName('endCursorLine')[0];
        //yAxix is the y plane coordinates where cursor is moving currently
        const startYAxix = startCursor && parseFloat(startCursor.getAttribute('y1'));
        const endYAxis = endCursor && parseFloat(endCursor.getAttribute('y1'));

        // Adding/subtracting 3 px in all cases to remove extra 3 px of highlight that user cant see while selecting due to stroke-width of cursor (6px)
        if (spanEl.offsetTop == startYAxix
            || Math.abs(spanEl.offsetTop - startYAxix) <= spanEl.offsetHeight / 2
            || spanEl.offsetTop == endYAxis
            || Math.abs(spanEl.offsetTop - endYAxis) <= spanEl.offsetHeight / 2) {
            if (startYAxix == endYAxis || Math.abs(startYAxix - endYAxis) < spanEl.offsetHeight / 2) {
                if (cursorPosition['start'].x < spanEndX && cursorPosition['end'].x > spanEl.offsetLeft) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX, cursorMove, cursorPosition)
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                }
            } else if (spanEl.offsetTop == startYAxix || Math.abs(spanEl.offsetTop - startYAxix) <= spanEl.offsetHeight / 2) {
                if (cursorPosition['start'].x > spanEl.offsetLeft && cursorPosition['start'].x < spanEndX) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX, cursorMove, cursorPosition)
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                } else if (cursorPosition['start'].x < spanEl.offsetLeft) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX) //since this span needs to be highlighted entirely
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                } else if (cursorPosition['start'].x < spanEndX) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX) //since this span needs to be highlighted entirely
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                }
            } else if (spanEl.offsetTop == endYAxis || Math.abs(spanEl.offsetTop - endYAxis) <= spanEl.offsetHeight / 2) {
                if (cursorPosition['end'].x > spanEl.offsetLeft && cursorPosition['end'].x < spanEndX) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX, cursorMove, cursorPosition)
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                } else if (cursorPosition['end'].x >= spanEl.offsetLeft && cursorPosition['end'].x >= spanEndX) {
                    const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX) //since this span needs to be highlighted entirely
                    highlightElWidth = obj.highlightElWidth - 3;
                    xPosition = obj.xPosition + 3;
                }
            }
        } else if (spanEl.offsetTop > startYAxix && (!endYAxis || spanEl.offsetTop < endYAxis)) {
            const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX)
            highlightElWidth = obj.highlightElWidth - 3;
            xPosition = obj.xPosition + 3;
        }
    } else {
        const obj = getHighlightRectProps(spanEl, start, end, scaleXValue, spanEndX)
        highlightElWidth = obj.highlightElWidth - 3;
        xPosition = obj.xPosition + 3;
    }

    return {
        highlightElWidth,
        xPosition
    };
}
const createSelectionArea = (currentBox, spans = [], svgGroup, start, end, cursorMove = false, cursorPosition={}, isAiBox=false, isQnaFeedback=false) => {
    if (start && end && Object.keys(start).length == 4 && Object.keys(end).length == 4) {
        start = { x: start.x1, y: start.y1 }
        end = { x: end.x1, y: end.y1 }
    }
    spans.forEach(spanEl => {
        if (spanEl.tagName === 'SPAN' && spanEl.textContent != '') {
            const scaleXValue = getScaleX(spanEl);

            createUnselectedArea(spanEl, svgGroup, currentBox);
            const selectedProps = validateSpanForHighlight(spanEl, start, end, scaleXValue, cursorMove, cursorPosition);
            if (selectedProps.highlightElWidth && selectedProps.xPosition) {
                const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                rect.setAttribute('class', 'selectionArea');
                rect.setAttributeNS(null, 'x', selectedProps.xPosition);
                rect.setAttributeNS(null, 'y', spanEl.offsetTop);
                rect.setAttributeNS(null, 'width', selectedProps.highlightElWidth);
                rect.setAttributeNS(null, 'height', spanEl.offsetHeight);
                rect.setAttribute('fill', isQnaFeedback && !isAiBox ? 'darkgreen' : 'green');
                //rect.setAttribute('fill', 'green');
                rect.setAttribute('opacity', '0.2');
                rect.setAttribute('id', `rect-${spanEl.offsetWidth}-${spanEl.offsetHeight}`);
                rect.setAttribute('selectedTextSVGRect', 'selectedTextSVGRect');
                rect.setAttribute('highlightType', isQnaFeedback ? lablesAndMessage['answerBtn'] : lablesAndMessage['semanticBtn']);
                //rect.setAttribute('highlightType', lablesAndMessage['semanticBtn']);
                rect.setAttribute('cursor', 'pointer');
                const startCursor = svgGroup.querySelector(".startCursorLine");
                startCursor ? svgGroup.insertBefore(rect, startCursor) : svgGroup.appendChild(rect);
            }
        }
    });
}

const addDragableEventOnCursor = (spans, svgGroup, cursorMouseDownEventAttached, currentBox, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox) => {
    setTimeout(() => {
        const dre = document.querySelectorAll(".draggable") || [];
        dre && dre.forEach((item) => {
            const cursorType = item.classList.contains('endCursor') ? 'endCursor' : 'startCursor';
            if (!cursorMouseDownEventAttached[cursorType]) {
                item.addEventListener("mousemove", (e) => {
                    const o = new Draggable(item, spans, svgGroup, cursorMouseDownEventAttached, currentBox, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox)
                })
            }
        });
    }, 300)
};

export const createCursorElements = (firstElTop, lastElTop, svgGroup, sx1, sy1, sx2, sy2, ex1, ey1, ex2, ey2) => {
    const startCursorExists = document.getElementsByClassName('startCursor')
    const endCursorExists = document.getElementsByClassName('endCursor')
    if (!(startCursorExists && startCursorExists.length) && !(endCursorExists && endCursorExists.length)) {
        const startCursorLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        const startCursorBottomCircle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        const startCursorOuterRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

        const startCursorDiameter = (sy2 - sy1);
        const tmpStartY1 = sy1 < sy2 ? sy1 : sy2;
        startCursorBottomCircle.setAttributeNS(null, 'x', (sx1 - startCursorDiameter + 1)+'');
        startCursorBottomCircle.setAttributeNS(null, 'y', sy2);
        startCursorBottomCircle.setAttributeNS(null, 'width', startCursorDiameter+'');
        startCursorBottomCircle.setAttributeNS(null, 'height', startCursorDiameter+'');
        startCursorBottomCircle.setAttributeNS(null, 'rx', (startCursorDiameter / 2)+'');
        startCursorBottomCircle.setAttribute('fill', '#0B4B66');
        startCursorBottomCircle.setAttributeNS(null, 'class', `startCursorBottomCircle`);

        startCursorOuterRect.setAttributeNS(null, 'x', (sx1 - startCursorDiameter + 1)+'');
        startCursorOuterRect.setAttributeNS(null, 'y', sy1);
        startCursorOuterRect.setAttributeNS(null, 'width', (sx2 - sx1 + startCursorDiameter)+'');
        startCursorOuterRect.setAttributeNS(null, 'height', (sy2 - sy1 + startCursorDiameter)+'');
        startCursorOuterRect.setAttributeNS(null, 'stroke', 'none');
        startCursorOuterRect.setAttributeNS(null, 'fill', 'white');
        startCursorOuterRect.setAttribute('stroke-width', '0.5');
        startCursorOuterRect.setAttribute('opacity', '0');
        startCursorOuterRect.setAttribute('cursor', 'pointer');
        startCursorOuterRect.setAttributeNS(null, 'class', `startCursor draggable`);
        startCursorOuterRect.setAttribute("spanYCoordinate", Math.abs(firstElTop - tmpStartY1) <= startCursorDiameter / 2 ? firstElTop : tmpStartY1);

        startCursorLine.setAttributeNS(null, 'x1', sx1);
        startCursorLine.setAttributeNS(null, 'y1', sy1);
        startCursorLine.setAttributeNS(null, 'x2', sx2);
        startCursorLine.setAttributeNS(null, 'y2', (parseInt(sy2) + parseInt(startCursorDiameter+'') / 2)+'');
        startCursorLine.setAttribute('stroke', '#0B4B66');
        startCursorLine.setAttribute('stroke-width', '2');
        startCursorLine.setAttributeNS(null, 'class', `startCursorLine`);

        svgGroup.appendChild(startCursorLine);
        svgGroup.appendChild(startCursorBottomCircle);
        svgGroup.appendChild(startCursorOuterRect);

        const endCursorDiameter = ey2 - ey1;
        const tmpEndY1 = ey1 < ey2 ? ey1 : ey2;
        const endCursorLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        const endCursorBottomCircle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        const endCursorOuterRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

        endCursorBottomCircle.setAttributeNS(null, 'x', (ex1 - 1)+'');
        endCursorBottomCircle.setAttributeNS(null, 'y', ey2);
        endCursorBottomCircle.setAttributeNS(null, 'width', endCursorDiameter+'');
        endCursorBottomCircle.setAttributeNS(null, 'height', endCursorDiameter+'');
        endCursorBottomCircle.setAttributeNS(null, 'rx', (endCursorDiameter / 2)+'');
        endCursorBottomCircle.setAttribute('fill', '#0B4B66');
        endCursorBottomCircle.setAttributeNS(null, 'class', `endCursorBottomCircle`);

        endCursorOuterRect.setAttributeNS(null, 'x', (ex1 - 1)+'');
        endCursorOuterRect.setAttributeNS(null, 'y', ey1);
        endCursorOuterRect.setAttributeNS(null, 'width', (ex2 - ex1 + endCursorDiameter)+'');
        endCursorOuterRect.setAttributeNS(null, 'height', (ey2 - ey1 + endCursorDiameter)+'');
        endCursorOuterRect.setAttributeNS(null, 'stroke', 'none');
        endCursorOuterRect.setAttributeNS(null, 'fill', 'white');
        endCursorOuterRect.setAttribute('stroke-width', '0.5');
        endCursorOuterRect.setAttribute('opacity', '0');
        endCursorOuterRect.setAttribute('cursor', 'pointer');
        endCursorOuterRect.setAttributeNS(null, 'class', `endCursor draggable`);
        endCursorOuterRect.setAttribute("spanYCoordinate", Math.abs(lastElTop - tmpEndY1) <= startCursorDiameter / 2 ? lastElTop : tmpEndY1);

        endCursorLine.setAttributeNS(null, 'x1', ex1);
        endCursorLine.setAttributeNS(null, 'y1', ey1);
        endCursorLine.setAttributeNS(null, 'x2', ex2);
        endCursorLine.setAttributeNS(null, 'y2', (parseInt(ey2) + parseInt(endCursorDiameter+'') / 2)+'');
        endCursorLine.setAttribute('stroke', '#0B4B66');
        endCursorLine.setAttribute('stroke-width', '2');
        endCursorLine.setAttributeNS(null, 'class', `endCursorLine`);

        svgGroup.appendChild(endCursorLine);
        svgGroup.appendChild(endCursorBottomCircle);
        svgGroup.appendChild(endCursorOuterRect);
    }
}

const createCursor = (currentBox, svgGroup, firstSpanEl, lastSpanEle, start, end) => {
    if (start && end && Object.keys(start).length == 4 && Object.keys(end).length == 4) {
        start = { x: start.x1, y: start.y1 }
        end = { x: end.x1, y: end.y1 }
    }
    let scaleXValue = 1;
    const partialData = lastSpanEle.style.transform.split('(');
    if (partialData && partialData.length == 2 && partialData[1].split(')').length > 0) {
        const [scaleXval] = partialData[1].split(')');
        scaleXValue = scaleXval;
    }

    let startX = firstSpanEl.offsetLeft;
    if (start.x) {
        startX = start.x;
    }
    let endX = lastSpanEle.offsetLeft + lastSpanEle.offsetWidth * scaleXValue;
    if (end.x) {
        endX = end.x;
    }
    if (!currentBox.startCursor && !currentBox.endCursor || isEmpty(currentBox.startCursor) && isEmpty(currentBox.endCursor)) {
        currentBox.startCursor = {
            'x1': startX,
            'y1': firstSpanEl.offsetTop,
            'x2': startX,
            'y2': firstSpanEl.offsetTop + firstSpanEl.offsetHeight
        }
        currentBox.endCursor = {
            'x1': endX,
            'y1': lastSpanEle.offsetTop,
            'x2': endX,
            'y2': lastSpanEle.offsetTop + lastSpanEle.offsetHeight
        }
    }
    let sx1; let sy1; let sx2; let sy2; let ex1; let ey1; let ex2; let
        ey2;
    
    sx1 = startX;
    sy1 = firstSpanEl.offsetTop;
    sx2 = startX;
    sy2 = firstSpanEl.offsetTop + firstSpanEl.offsetHeight;

    ex1 = endX;
    ey1 = lastSpanEle.offsetTop;
    ex2 = endX;
    ey2 = lastSpanEle.offsetTop + lastSpanEle.offsetHeight;

    createCursorElements(
        firstSpanEl.offsetTop,
        lastSpanEle.offsetTop,
        svgGroup,
        sx1,
        sy1,
        sx2,
        sy2,
        ex1,
        ey1,
        ex2,
        ey2
    );
}

export const findAndHighlightText = (addUpdateDeleteManualFeedback, getCurrentBox, redrawBoxData, setCurrentBox, mouseDownEventCB, eyToast, editFeedback, feedbackBoxProps, plottingCondition, failedSelectionProps:any = {}, isQnaFeedback = false) => {
    const { isEditing, isCreating, isPlotting } = plottingCondition;
    const { pageNo, start, pe: end, w, h, svgGroup, currentBox, scale, cursorMouseDownEventAttached } = feedbackBoxProps
    const boxObj = findSpansUnderSVGBox(
        scale,
        pageNo,
        [start.x, start.y, start.x + w, start.y + h]
    );
    const spans = boxObj && boxObj.spans || [];
    const feedbackText = boxObj && boxObj.feedbackText || '';
    let textbetweenCursor = feedbackText;
    //startCursor & endCursor is semantic feedback cursor positions
    let startCursor = currentBox.startCursor;
    let endCursor = currentBox.endCursor;
    if(currentBox.feedbackType == 'answer' && !currentBox.isAiBox && !isEmpty(currentBox.qnaFeedback) && !currentBox.qnaFeedback.isDeleted){
        startCursor = currentBox.qnaFeedback.startCursor
        endCursor = currentBox.qnaFeedback.endCursor
    }
    if(currentBox.isAiBox && currentBox.isDeleted){
        startCursor = undefined;
        endCursor = undefined;
    }
    let manualFeedbackSpans = [];
    let cursorMove = false;
    let isCreatinSelectionArea = currentBox.isAiBox && isEmpty(startCursor) && isEmpty(endCursor) //answer Feedback in SemanticBox for firstTime
    if(!currentBox.isAiBox && !isEmpty(startCursor) && !isEmpty(endCursor) && isQnaFeedback && isEmpty(currentBox.qnaFeedback)){
        //creating selection area for answer manual feedback inside semantic manual feedback for the first time
        isCreatinSelectionArea = true;
        currentBox.qnaFeedback = {
            startCursor,
            endCursor,
            'feedbackText': currentBox.feedbackText
        }
        cursorMove = true;
    } else if(currentBox.feedbackType == 'answer' && !currentBox.isAiBox && !isEmpty(currentBox.qnaFeedback) && currentBox.qnaFeedback.isDeleted){
        //saved answer manual feedback is deleted then again adding answer manual feedback inside semantic manual feedback
        isCreatinSelectionArea = true;
        currentBox.qnaFeedback['startCursor'] = { ...startCursor }
        currentBox.qnaFeedback['endCursor'] = { ...endCursor }
        currentBox.qnaFeedback['feedbackText'] = currentBox.feedbackText
        if (currentBox.qnaFeedback['isDeleted']) {
            delete currentBox.qnaFeedback['isDeleted']
        }
        cursorMove = true;
    }
    

    let firstSpanEl; 
    let lastSpanEl;
    const cursorCheck = Boolean(startCursor && endCursor)
    if (cursorCheck) {
        manualFeedbackSpans = findSpansBetweenCursors(spans, { x: startCursor.x1, y: startCursor.y1 }, { x: endCursor.x1, y: endCursor.y1 })
        textbetweenCursor = getSelectedTextFromBoxElements(
            scale,
            manualFeedbackSpans,
            [start.x, start.y, start.x + w, start.y + h],
            currentBox.pageNo,
            { x: startCursor.x1, y: startCursor.y1 },
            { x: endCursor.x1, y: endCursor.y1 }
        )
    }
    if (manualFeedbackSpans.length > 0 && cursorCheck) {
        for (let i = 0; i < manualFeedbackSpans.length; i++) {
            if (manualFeedbackSpans[i].tagName === 'SPAN' && manualFeedbackSpans[i].textContent.trim() !== '') {
                firstSpanEl = manualFeedbackSpans[i];
                break;
            }
        }
        for (let i = manualFeedbackSpans.length - 1; i > -1; i--) {
            if (manualFeedbackSpans[i].tagName === 'SPAN' && manualFeedbackSpans[i].textContent.trim() !== '') {
                lastSpanEl = manualFeedbackSpans[i];
                break;
            }
        }
    } else {
        for (let i = 0; i < spans.length; i++) {
            if (spans[i].tagName === 'SPAN' && spans[i].textContent.trim() !== '') {
                firstSpanEl = spans[i];
                break;
            }
        }
        for (let i = spans.length - 1; i > -1; i--) {
            if (spans[i].tagName === 'SPAN' && spans[i].textContent.trim() !== '') {
                lastSpanEl = spans[i];
                break;
            }
        }
    }
    if (
        (
            spans.length > 0
            || manualFeedbackSpans.length > 0
        )
        && firstSpanEl
        && lastSpanEl
        && svgGroup
    ) {
        const firstSpanElYCoordinates = [firstSpanEl.offsetTop, firstSpanEl.offsetTop + firstSpanEl.offsetHeight];
        const lastSpanElYCoordinates = [lastSpanEl.offsetTop, lastSpanEl.offsetTop + lastSpanEl.offsetHeight];
        if (
            (
                firstSpanEl.offsetTop <= lastSpanEl.offsetTop
                || (
                    /*Below cases are handeling when text in one sngle line
                    but first span is little smaller than the next one*/
                    firstSpanElYCoordinates[0] >= lastSpanElYCoordinates[0]
                    && firstSpanElYCoordinates[0] <= lastSpanElYCoordinates[1]
                    && firstSpanElYCoordinates[1] >= lastSpanElYCoordinates[0]
                    && firstSpanElYCoordinates[1] <= lastSpanElYCoordinates[1]
                )
            )
            && (
                firstSpanEl.offsetTop - lastSpanEl.offsetTop < firstSpanEl.offsetHeight
                && firstSpanEl.offsetTop - lastSpanEl.offsetTop < lastSpanEl.offsetHeight
            )
        ) {
            let cursorPosition = {}
            if (!isEmpty(startCursor) && !isEmpty(endCursor)) {
                if(Object.keys(startCursor).length == 4 && Object.keys(endCursor).length == 4){
                    cursorPosition = { 'start': { x: startCursor.x1, y: startCursor.y1 }, 'end': { x: endCursor.x1, y: endCursor.y1 } }
                }else{
                    cursorPosition = { 'start': { x: startCursor.x1, y: startCursor.y1 }, 'end': { x: endCursor.x1, y: endCursor.y1 } }
                }
            }
            if ((isCreating || isEditing) && !isPlotting) {
                let startPos = firstSpanEl && firstSpanEl.offsetLeft >= start.x ? { x: firstSpanEl.offsetLeft, y: firstSpanEl.offsetTop } : start;
                startPos = isEmpty(startCursor) ? startPos : cursorPosition['start'];
                
                const lastElementX2 = lastSpanEl.offsetLeft + lastSpanEl.offsetWidth * getScaleX(lastSpanEl)
                let endPos = lastSpanEl && lastElementX2 <= end.x ? { x: lastElementX2, y: lastSpanEl.offsetTop } : end;
                endPos = isEmpty(endCursor) ? endPos : cursorPosition['end'];
                if (isQnaFeedback) {
                    createCursor(currentBox, svgGroup, firstSpanEl, lastSpanEl, startPos, endPos);
                } else {
                    createCursor(currentBox, svgGroup, firstSpanEl, lastSpanEl, startPos, endPos);
                }
            }
            if (isCreating || isPlotting || isCreatinSelectionArea) {
                createSelectionArea(currentBox, spans, svgGroup, start, end, cursorMove, cursorPosition, currentBox.isAiBox, isQnaFeedback)
            } else if (isEditing) {
                //Below block adds unselection area for saved manual feedback when edit button is clicked
                spans.forEach(spanEl => {
                    if(spanEl.tagName == 'SPAN' && spanEl.textContent != ''){
                        createUnselectedArea(spanEl, svgGroup, currentBox);
                    }
                })
            }
            addDragableEventOnCursor(spans, svgGroup, cursorMouseDownEventAttached, currentBox, scale, setCurrentBox, redrawBoxData, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, getCurrentBox);
        } else if (failedSelectionProps) {
            const {
                rect,
                circle,
                svg,
                saveButton,
                discardButton,
                copyButton,
                pageNo,
                group,
                showMessage
            } = failedSelectionProps
            const elementsObj = {rect, circle, svg, saveButton, discardButton, copyButton}
            removeInvalidFeedbackBox(mouseDownEventCB, elementsObj, pageNo, editFeedback, eyToast, group, showMessage)
            showHideEventOnButtonsAtPage(pageNo)
        }
    }
    return textbetweenCursor;
}

//In EYDE this function called removeHighlightedText
export const removeCursorsAfterSave = () => {
    const startCursor = document.getElementsByClassName('startCursor');
    let parentElement;
    if (startCursor.length > 0) {
        parentElement = startCursor[0].parentElement;
        startCursor.length > 0 ? startCursor[0].remove() : '';
    }
    if (!parentElement) {
        return;
    }
    const startCursorLine = parentElement.getElementsByClassName('startCursorLine');
    startCursorLine.length > 0 ? startCursorLine[0].remove() : '';

    const startCursorBottomCircle = parentElement.getElementsByClassName('startCursorBottomCircle');
    startCursorBottomCircle.length > 0 ? startCursorBottomCircle[0].remove() : '';

    const endCursor = parentElement.getElementsByClassName('endCursor');
    endCursor.length > 0 ? endCursor[0].remove() : '';

    const endCursorLine = parentElement.getElementsByClassName('endCursorLine');
    endCursorLine.length > 0 ? endCursorLine[0].remove() : '';

    const endCursorBottomCircle = parentElement.getElementsByClassName('endCursorBottomCircle');
    endCursorBottomCircle.length > 0 ? endCursorBottomCircle[0].remove() : '';
}

const createButtonElement = (w, h, x, y, label, answerFeedbacktext='', isAnswerNextToSemanticButton=false, currentBox:any={}) => {
    // if AI Semantic box doesnot contains AI Answer, then it will show +Answer button on the AI box, else Edit Answer button will show.
    // if user has added answer manual feedback inside semantic ai box, then button will show edit answer button. Else it will be Add answer button.
    const editButtonIconSrc = '/assets/img/edit.svg';
    const addButtonIconSrc = '/assets/img/add.svg';
    let answerButton = addButtonIconSrc;
    const isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
    if ((currentBox.isAiBox || isAiBox) && (answerFeedbacktext || currentBox.feedbackText)) {
        answerButton = editButtonIconSrc;
    } else if (!currentBox.isAiBox 
        && (
            (!isEmpty(currentBox.qnaFeedback) && !currentBox.qnaFeedback.id) 
            || (currentBox.qnaFeedback?.id && !currentBox.qnaFeedback?.isDeleted)
            ) ) {
            //box is not ans AI box
            //if its a newly created feedback, then answer feedback is provided
            //or if its a saved feedback and contains answer feedback as well and not deleted
        answerButton = editButtonIconSrc;
    }
    const buttonProperties = {
        'Copy': {
            'css': 'copySelectionButton',
            'src': '/assets/img/copy.svg',
            'id': `copyButton-${x}-${y}`,
            'alt': 'copyIcon',
            'left': x + w - 120
        },
        'Save': {
            'css': 'saveSelectionButton',
            'src': '/assets/img/save.svg',
            'id': `saveButton-${x}-${y}`,
            'alt': 'saveIcon',
            'left': x + w - 180
        },
        'Clear': {
            'css': 'discardSelectionButton',
            'src': '/assets/img/clear.svg',
            'id': `discardButton-${x}-${y}`,
            'alt': 'discardIcon',
            'left': x + w - 60
        },
        'Semantic': {
            'css': 'editSelectionButton',
            'src': editButtonIconSrc,
            'id': `editButton-${x}-${y}-${label}`,
            'alt': `editIcon`,
            'left': (x + w - 75)
        },
        'Answer': {
            'css': 'editSelectionButton',
            'src': answerButton,
            'id': `editButton-${x}-${y}-${label}`,
            'alt': `editIcon`,
            'left': isAnswerNextToSemanticButton ? ((x + w - 135) < 0 ? (x + w - 75) : (x + w - 150)) : (x + w - 75)
        }
    }
    if(isAnswerNextToSemanticButton && (x + w - 135) < 0){
        document.getElementById(`editButton-${x}-${y}-Semantic`).style.left = `${x + w}px`
    }
    const button = document.createElement('button')
    button.id = buttonProperties[label].id;
    button.classList.add(buttonProperties[label].css)
    const icon = document.createElement('img')
    icon.src = buttonProperties[label].src
    icon.style.height = (label == lablesAndMessage['semanticBtn'] || label == lablesAndMessage['answerBtn']) ? '12px' : '14px'
    icon.style.width = '14px'
    //icon.style.marginBottom = (label == lablesAndMessage['semanticBtn'] || label == lablesAndMessage['answerBtn']) ? '4px' : '2px'
    icon.style.marginBottom = '2px';
    icon.alt = buttonProperties[label].alt
    button.appendChild(icon)
    const labelEl = document.createElement('span')
    labelEl.innerText = label
    labelEl.style.marginTop = '1px'
    button.appendChild(labelEl)
    button.style.top = (y - 20) < 0 ? `${y + h}px` : `${y - 20}px`
    let left = buttonProperties[label].left;
    if(label == lablesAndMessage['copyBtn'] && left < 120){
        left = 120;
    } else if(label == lablesAndMessage['saveBtn'] && left < 60) {
        left = 60;
    } else if(label == lablesAndMessage['clearBtn'] && left < 180) {
        left = 180;
    }
    button.style.left = `${left}px`;
    return button;
}

const createCopyButton = (w, h, x, y, pdfViewer, getCurrentBox, pageNumber, redrawBoxData) => {
    const copyButton = createButtonElement(w, h, x, y, lablesAndMessage['copyBtn'])
    copyButton.onclick = () => {
        const feedbackType = redrawBoxData.feedbackType;
        const tempCurrentBox = getCurrentBox()
        const isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
        const savedCurrentBox = getCurrentBox(pageNumber, x, y, feedbackType, isAiBox)
        let feedbacktext = savedCurrentBox['feedbackText'] || tempCurrentBox['feedbackText'] || '';
        if (feedbackType == 'answer' 
            && !isAiBox
            && (tempCurrentBox.qnaFeedback?.feedbackText || savedCurrentBox.qnaFeedback?.['feedbackText'])) {
                feedbacktext = savedCurrentBox.qnaFeedback?.['feedbackText'] || tempCurrentBox.qnaFeedback?.feedbackText
        } else if(tempCurrentBox['feedbackText'] && savedCurrentBox['feedbackText'] && tempCurrentBox['feedbackText'] != savedCurrentBox['feedbackText']){
            feedbacktext = tempCurrentBox['feedbackText']
        }
        navigator.clipboard.writeText(feedbacktext);
    }
    pdfViewer.appendChild(copyButton);
}
const createSaveButton = (w, h, x, y, pdfViewer, cursorMouseDownEventAttached, getCurrentBox, setCurrentBox, redrawBoxData, editFeedback, mouseDownEventCB, addUpdateDeleteManualFeedback) => {
    const isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
    const feedbackType = redrawBoxData.feedbackType;
    const saveButton = createButtonElement(w, h, x, y, lablesAndMessage['saveBtn'])
    saveButton.onclick = () => {
        //get this.currentBox by not passing any arguments
        const currentBox = getCurrentBox();
        cursorMouseDownEventAttached['startCursor'] = false;
        cursorMouseDownEventAttached['endCursor'] = false;
        
        //adding events again to svg
        setEventListeners(currentBox.pageNo, mouseDownEventCB);
        enableDisableEditButtons(false, editFeedback);
        showHideEventOnButtonsAtPage(currentBox.pageNo);
        const saveButton = document.getElementById(`${currentBox.saveButtonId}`);
        const discardButton = document.getElementById(`${currentBox.discardButtonId}`);
        const copyButton = document.getElementById(`${currentBox.copyButtonId}`);
        setTimeout(() => {
            saveButton && saveButton.remove();
            discardButton && discardButton.remove();
            copyButton && copyButton.remove();
        }, 10)
        let operationType = 'add';
        if(currentBox.isAiBox && currentBox.id){
            operationType = 'update';
        } else if (!currentBox.isAiBox && feedbackType == 'answer' && currentBox.qnaFeedback.id) {
            operationType = 'update';
        } else if (!currentBox.isAiBox && feedbackType == 'semantic' && currentBox.id) {
            operationType = 'update';
        }
        addUpdateDeleteManualFeedback(operationType, feedbackType, currentBox)
        redrawBoxData.boundingBox = { 'x1': x, 'y1': y, 'x2': x + w, 'y2': y + h }
        createEditButton(redrawBoxData, currentBox.id || '', isAiBox ? lablesAndMessage['answerBtn'] : lablesAndMessage['semanticBtn'], false, editFeedback, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback)
        if(redrawBoxData.selectedQuery.qna){
            createEditButton(redrawBoxData, currentBox.id || '', lablesAndMessage['answerBtn'], true, editFeedback, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback)
        }
        const group = currentBox.isAiBox ? document.getElementById("aiFeedback") : document.getElementById(`resizeID-${w}-${h}`);
        showHideButtonEventOnSelectionArea(group, x, y)
        removeCursorsAfterSave()
        setCurrentBox({})
        disableScrollOnViewerContainer(false)
        redrawBoxData.enableDisableControlButtons(false)
    }
    pdfViewer.appendChild(saveButton);
}

const discardOperation = (x, y, cursorMouseDownEventAttached, currentBox, feedbackType, isAiBox, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, setCurrentBox, redrawBoxData, getCurrentBox) => {
    cursorMouseDownEventAttached['startCursor'] = false;
    cursorMouseDownEventAttached['endCursor'] = false;
    const sc = document.getElementById(`startCircle-${currentBox.x1}-${currentBox.y1}`);
    const ec = document.getElementById(`endCircle-${currentBox.x2}-${currentBox.y2}`);
    const sb = document.getElementById(`${currentBox.saveButtonId}`)
    const db = document.getElementById(`${currentBox.discardButtonId}`);
    const copyButton = document.getElementById(`${currentBox.copyButtonId}`);

    const { w } = currentBox;
    const { h } = currentBox;
    if (feedbackType == 'semantic' || isAiBox) {
        const rectElem = document.getElementById(`rect-${w}-${h}`);
        rectElem && rectElem.parentElement.remove();
    } else {
        //here we are removing cursors & answer highlights inside manual semantic box
        const svgGroup = currentBox.isAiBox ? document.getElementById(`rect-${w}-${h}`).parentNode : document.getElementById(`resizeID-${w}-${h}`);
        svgGroup && (svgGroup.querySelectorAll(`[highlightType="Answer"]`) || []).forEach((highlightedRect) => {
            highlightedRect.remove();
        })
        removeCursorsAfterSave()
    }

    //adding events again to svg
    setEventListeners(currentBox.pageNo, mouseDownEventCB);
    enableDisableEditButtons(false, editFeedback);
    showHideEventOnButtonsAtPage(currentBox.pageNo);
    setTimeout(() => {
        sc && sc.remove();
        ec && ec.remove();
        sb && sb.remove();
        copyButton && copyButton.remove();
        db && db.remove();
    }, 10)
    addUpdateDeleteManualFeedback('delete', feedbackType, currentBox)
    if ((feedbackType == 'semantic' || (feedbackType == 'answer' && currentBox.isAiBox)) && currentBox.id) {
        currentBox.isDeleted = true
        setCurrentBox(currentBox)
    } else if (feedbackType == 'answer' && currentBox.qnaFeedback?.id) {
        currentBox.qnaFeedback.isDeleted = true
        setCurrentBox(currentBox)
    }
    if (isAiBox) {
        redrawBoxData.drawSemanticBox(redrawBoxData.boundingBox, redrawBoxData.pageNumber, redrawBoxData.selectedResult.robertaModel, false)
        if (redrawBoxData.selectedQuery.qna) {
            createEditButton(redrawBoxData, '', lablesAndMessage['answerBtn'], false, editFeedback, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback)
        }
    } else if (feedbackType == 'answer') {
        createEditButton(redrawBoxData, currentBox.id || '', isAiBox ? lablesAndMessage['answerBtn'] : lablesAndMessage['semanticBtn'], false, editFeedback, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback)
        if (redrawBoxData.selectedQuery.qna) {
            createEditButton(redrawBoxData, currentBox.qnaFeedback?.id || '', lablesAndMessage['answerBtn'], true, editFeedback, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback)
        }
    }
    setCurrentBox({})
    const group = isAiBox ? document.getElementById("aiFeedback") : document.getElementById(`resizeID-${w}-${h}`);
    if (group) {
        showHideButtonEventOnSelectionArea(group, x, y)
    }
    disableScrollOnViewerContainer(false)
    redrawBoxData.enableDisableControlButtons(false)
}

const createDiscardButton = (w, h, x, y, pdfViewer, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, editFeedback, redrawBoxData, addUpdateDeleteManualFeedback) => {
    const isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
    const feedbackType = redrawBoxData.feedbackType;
    const currentBox = getCurrentBox(redrawBoxData.pageNumber, x, y, feedbackType, isAiBox)
    const discardButton = createButtonElement(w, h, x, y, lablesAndMessage['clearBtn'])
    discardButton.onclick = () => {
        discardOperation(x, y, cursorMouseDownEventAttached, currentBox, feedbackType, isAiBox, mouseDownEventCB, editFeedback, addUpdateDeleteManualFeedback, setCurrentBox, redrawBoxData, getCurrentBox)
    }
    pdfViewer.appendChild(discardButton);
}
export const addButtonsOnRect = (w, h, getCurrentBox, setCurrentBox, pdfViewer, cursorMouseDownEventAttached, mouseDownEventCB, editFeedback, redrawBoxData, addUpdateDeleteManualFeedback) => {
    //This function gets called when user creates a new box so no needs to pass anything to getCurrentBox
    const currentBox = getCurrentBox()
    const x = currentBox['x1'];
    const y = currentBox['y1'];
    createSaveButton(w, h, x, y, pdfViewer, cursorMouseDownEventAttached, getCurrentBox, setCurrentBox, redrawBoxData, editFeedback, mouseDownEventCB, addUpdateDeleteManualFeedback)
    createCopyButton(w, h, x, y, pdfViewer, getCurrentBox, currentBox.pageNo, redrawBoxData)
    createDiscardButton(w, h, x, y, pdfViewer, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, editFeedback, redrawBoxData, addUpdateDeleteManualFeedback)
}

export const createEditButton = (redrawBoxData, id, buttonLabel = lablesAndMessage['semanticBtn'], isAnswerNextToSemanticButton = false, editFeedback,  getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, addUpdateDeleteManualFeedback) => {
    const { selectedResult, boundingBox, pageNumber, scale, alertService } = redrawBoxData;
    const x = boundingBox['x1'];
    const y = boundingBox['y1'];
    const w = +(boundingBox['x2'] - boundingBox['x1']).toFixed(3);
    const h = +(boundingBox['y2'] - boundingBox['y1']).toFixed(3);
    const pdfViewer:any = document.querySelector(`[data-page-number="${pageNumber}"]`);
    if(pdfViewer.querySelector(`[id='editButton-${x}-${y}-${buttonLabel}']`)){
        return
    }
    const isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
    const feedbackType = isAiBox || buttonLabel === lablesAndMessage['answerBtn'] ? 'answer' : 'semantic'
    let currentBox = id ? getCurrentBox(): getCurrentBox(redrawBoxData.pageNumber, x, y, feedbackType, isAiBox);
    //in case of manual feedback, selected result will have qnaFeedback property other wise will have robertaModel property for answer feedback
    const aiFeedbacktext = selectedResult?.qnaFeedback?.text || selectedResult?.robertaModel?.text || currentBox.qnaFeedback?.feedbackText;
    const editButton = createButtonElement(w, h, x, y, buttonLabel, aiFeedbacktext, isAnswerNextToSemanticButton, currentBox)
    editButton.style.visibility = 'hidden';
    editButton.style.width = '75px'
    editButton.style.visibility = 'hidden';
    //below mouseover and mouseout event is to handle
    //show/hide edit buttons of feedback box
    editButton.addEventListener('mouseover', (e) => {
        editButton.style.visibility = 'unset';
        let nextButton;
        if (buttonLabel === lablesAndMessage['semanticBtn']) {
            nextButton = e.target['nextElementSibling'];
        } else {
            nextButton = e.target['previousElementSibling'];
        }
        if (nextButton && nextButton.style) {
            nextButton.style.visibility = 'unset';
        }
    })
    editButton.addEventListener('mouseout', (e) => {
        const elementType = e.target['tagName'];
        let nextButton;
        if (buttonLabel === lablesAndMessage['semanticBtn']) {
            nextButton = e.target['nextElementSibling'];
        } else {
            nextButton = e.target['previousElementSibling'];
        }
        if (elementType != "BUTTON" && elementType != "SPAN" && elementType != "IMG" && nextButton && nextButton.style) {
            nextButton.style.visibility = 'hidden';
        }
    })
    editButton.onclick = async (e:any) => {
        const editElement = e.target.tagName === 'IMG' || e.target.tagName === 'SPAN' ? e.target.parentElement : e.target;

        const elId = editElement.id;
        const [x1, y1, buttonType] = elId.split('editButton-')[1].split('-');
        let isQnAFeedbackMode = buttonType === lablesAndMessage['answerBtn'];
        let isAiBox = document.getElementById(`resizeID-${w}-${h}`) ? false : true;
        if(!id){
            //this scenario is for only newly created box
            currentBox = getCurrentBox(pageNumber, boundingBox['x1'], boundingBox['y1'], isAiBox ? 'answer' : isQnAFeedbackMode ? 'answer' : 'semantic', isAiBox) || {}
        }
        currentBox['w'] = w;
        currentBox['h'] = h;
        currentBox['x1'] = boundingBox['x1'];
        currentBox['y1'] = boundingBox['y1'];
        currentBox['x2'] = boundingBox['x2'];
        currentBox['y2'] = boundingBox['y2'];
        currentBox['pageNo'] = pageNumber;
        currentBox['saveButtonId'] = `saveButton-${currentBox['x1']}-${currentBox['y1']}`;
        currentBox['discardButtonId'] = `discardButton-${currentBox['x1']}-${currentBox['y1']}`;
        currentBox['copyButtonId'] = `copyButton-${currentBox['x1']}-${currentBox['y1']}`;
        currentBox['feedbackType'] = isQnAFeedbackMode ? 'answer' : 'semantic'
        currentBox['isAiBox'] = isAiBox;
        const pdfViewer1 = document.querySelector(`[data-page-number="${pageNumber}"]`);
        redrawBoxData.feedbackType = feedbackType;
        createSaveButton(w, h, x, y, pdfViewer1, cursorMouseDownEventAttached, getCurrentBox, setCurrentBox, redrawBoxData, editFeedback, mouseDownEventCB, addUpdateDeleteManualFeedback)
        createCopyButton(w, h, x, y, pdfViewer1, getCurrentBox, pageNumber, redrawBoxData)
        createDiscardButton(w, h, x, y, pdfViewer1, getCurrentBox, setCurrentBox, cursorMouseDownEventAttached, mouseDownEventCB, editFeedback, redrawBoxData, addUpdateDeleteManualFeedback)
        const editButtons = document.querySelectorAll(
            `[id*='editButton-${x}-${y}']`
        );
        setTimeout(() => {
            editButtons && editButtons.length && (editButtons || []).forEach(button => button.remove());
        }, 10)

        const pdfViewer = document.querySelector(`[data-page-number="${pageNumber}"]`);
        let svg = pdfViewer && pdfViewer.querySelector('.drawingPlane');
        let group = currentBox['isAiBox'] ? svg.querySelector(`[id="aiFeedback"]`) : svg.querySelector(`[id="resizeID-${w}-${h}"]`)
        const rect = svg.getElementsByClassName('rectSelect')[0];
        const saveButton = document.querySelectorAll(`[id*="saveButton"]`);
        const discardButton = document.querySelectorAll(`[id*="discardButton"]`);
        const copyButton = document.querySelectorAll(`[id*="copyButton"]`);
        const failedSelectionProps = {
            rect,
            circle: null,
            svg,
            saveButton,
            discardButton,
            copyButton,
            pageNo: pageNumber,
            group,
            showMessage: true
        }
        const feedbackBoxProps = {
            pageNo: pageNumber,
            start: { x: boundingBox['x1'], y: boundingBox['y1'] },
            pe: { x: boundingBox['x2'], y: boundingBox['y2'] },
            w,
            h,
            svgGroup: group,
            currentBox,
            scale,
            cursorMouseDownEventAttached
        }
        if (isQnAFeedbackMode) {
            group.querySelectorAll(`[aiAnswerTextSVGRect="aiAnswerTextSVGRect"]`).forEach(node => node.remove())
        }
        
        const plottingCondition = { isEditing: true, isCreating: false, isPlotting: false }

        const feedbackText = findAndHighlightText(addUpdateDeleteManualFeedback, getCurrentBox, redrawBoxData, setCurrentBox, mouseDownEventCB, alertService.error, editFeedback, feedbackBoxProps, plottingCondition, failedSelectionProps, isQnAFeedbackMode);
        
        if (isQnAFeedbackMode && !currentBox.isAiBox){
            currentBox.qnaFeedback.feedbackText = feedbackText;
        }else{
            currentBox['feedbackText'] = feedbackText;
        }
        
        setCurrentBox({ ...currentBox })
        svg && svg.parentNode && svg.parentNode.replaceChild(svg.cloneNode(true), svg);
        enableDisableEditButtons(true, editFeedback);
        disableScrollOnViewerContainer(true)
        redrawBoxData.enableDisableControlButtons(true)
    }
    pdfViewer && pdfViewer.appendChild && pdfViewer.appendChild(editButton);
    enableDisableEditButtons(false, editFeedback);
}

export const getSelectionObjToUpdateManualFeedback = (selectedResult, manualFeedbackSelections, currentBox, feedbackType, operationType, manualFeedbackConstantFactor=1) => {
    let selectionObj = { "operation": operationType }
    const useFeedbackId = selectedResult.USEModel.feedback._id;
    const robertaFeedbackId = selectedResult.robertaModel?.feedback?._id;
    if (operationType == 'delete') {
        const selectionId = currentBox.isAiBox ? currentBox.id : currentBox.qnaFeedback.id;
        if (feedbackType == 'answer' && robertaFeedbackId && selectionId) {
            //if answer manual feedback is provided inside AI box, then on delete case, we have to send USEModel information as well
            const selObj = selectedResult.robertaModel?.feedback.selections.find(selection => currentBox.id == (currentBox.isAiBox ? selection.id : selection.parentId))
            if (currentBox.isAiBox) {
                selectionObj['USEModel'] = {
                    'id': useFeedbackId,
                    'selectionId': selObj.parentId
                }
            }
            selectionObj['robertaModel'] = {
                'id': robertaFeedbackId,
                'selectionId': selectionId,
            }
            if (selObj && selObj.boundingBox) {
                selectionObj['robertaModel']['boundingBox'] = window['structuredClone'](selObj.boundingBox)
            }
        } else if(feedbackType == 'semantic' && useFeedbackId) {
            selectionObj['USEModel'] = {
                'id': useFeedbackId,
                'selectionId': currentBox.id
            }
            if (robertaFeedbackId && currentBox.qnaFeedback.id) {
                selectionObj['robertaModel'] = {
                    'id': robertaFeedbackId,
                    'selectionId': currentBox.qnaFeedback.id
                }
            }
        } else {
            selectionObj = undefined;
        }
        return { selectionObj }
    } else {
        //checking semantic and answer feedbacktext is modified or not
        let isSemanticTextModified = false;
        let isAnswerTextModified = false;
        const savedSemanticFeedback = selectedResult.USEModel.feedback.selections.find(selection => {
            return selection.id == currentBox.id
        })
        const savedAnswerFeedback = selectedResult.robertaModel?.feedback?.selections.find(selection => {
            const qnaFeedbackId = currentBox.isAiBox ? currentBox.id : (currentBox.qnaFeedback.selectionId || currentBox.qnaFeedback?.id)
            return selection.id == qnaFeedbackId
        })
        let savedSemanticFeedbackModified = false;
        let savedAnswerFeedbackModified = false;
        const manualFeedback = manualFeedbackSelections.find(selection => {
            if(selection['USEModel']
                && savedSemanticFeedback
                && selection.USEModel.boundingBox
                && savedSemanticFeedback.boundingBox
                && selection.USEModel.boundingBox.position[0] == savedSemanticFeedback.boundingBox.position[0] 
                && selection.USEModel.boundingBox.size[0] == savedSemanticFeedback.boundingBox.size[0] 
                && selection.USEModel.pageNum == savedSemanticFeedback.pageNum
                && selection.USEModel.text != savedSemanticFeedback.text){
                    savedSemanticFeedbackModified = true;
            }
            if (selection['robertaModel']
                && savedAnswerFeedback
                && selection.robertaModel.boundingBox
                && savedAnswerFeedback.boundingBox
                && selection.robertaModel.boundingBox.position[0] == savedAnswerFeedback.boundingBox.position[0]
                && selection.robertaModel.boundingBox.size[0] == savedAnswerFeedback.boundingBox.size[0]
                && selection.robertaModel.pageNum == savedAnswerFeedback.pageNum
                && selection.robertaModel.text != savedAnswerFeedback.text) {
                    savedAnswerFeedbackModified = true;
            } else if (currentBox.qnaFeedback
                && savedAnswerFeedback
                && savedAnswerFeedback.boundingBox
                //&& currentBox.qnaFeedback.id == savedAnswerFeedback.id
                && +(currentBox.x1 * manualFeedbackConstantFactor).toFixed(3) == savedAnswerFeedback.boundingBox.position[0]
                && +(currentBox.h * manualFeedbackConstantFactor).toFixed(3) == savedAnswerFeedback.boundingBox.size[0]
                && currentBox.pageNo == savedAnswerFeedback.pageNum
                && currentBox.qnaFeedback.feedbackText != savedAnswerFeedback.text) {
                    savedAnswerFeedbackModified = true;
            }
            if(savedSemanticFeedbackModified || savedAnswerFeedbackModified){
                return true;
            }
        })
        if (savedSemanticFeedbackModified || savedAnswerFeedbackModified) {
            isSemanticTextModified = savedSemanticFeedbackModified;
            isAnswerTextModified = savedAnswerFeedbackModified
            // if (manualFeedback['USEModel']?.text
            //     && currentBox.feedbackText
            //     && manualFeedback['USEModel']?.text != currentBox.feedbackText) {
            //     isSemanticTextModified = true;
            // }
            if(!manualFeedback['USEModel']
                && savedSemanticFeedback
                && currentBox.feedbackText
                && savedSemanticFeedback.text != currentBox.feedbackText){
                    isSemanticTextModified = true;
            }
            if (currentBox.isAiBox
                && manualFeedback['robertaModel']?.text
                && currentBox.feedbackText
                && manualFeedback['robertaModel']?.text != currentBox.feedbackText) {
                isAnswerTextModified = true;
            }
            // if (currentBox.qnaFeedback?.feedbackText
            //     && manualFeedback['robertaModel']?.text
            //     && (
            //         manualFeedback['robertaModel']?.text != currentBox.qnaFeedback?.feedbackText
            //         || (manualFeedback['robertaModel'].boundingBox.startIndex != currentBox.qnaFeedback?.startIndex && manualFeedback['robertaModel'].boundingBox.endIndex != currentBox.qnaFeedback?.endIndex)
            //     )) {
            //     isAnswerTextModified = true;
            // }
        } else {
            if (savedSemanticFeedback?.text
                && currentBox.feedbackText
                && savedSemanticFeedback?.text != currentBox.feedbackText) {
                isSemanticTextModified = true;
            }
            if (currentBox.isAiBox
                && savedAnswerFeedback?.text
                && currentBox.feedbackText
                && savedAnswerFeedback?.text != currentBox.feedbackText) {
                isAnswerTextModified = true;
            }
            if (currentBox.qnaFeedback?.feedbackText
                && savedAnswerFeedback?.text
                && (
                    savedAnswerFeedback?.text != currentBox.qnaFeedback?.feedbackText
                    || (savedAnswerFeedback.boundingBox.startIndex != currentBox.qnaFeedback?.startIndex && savedAnswerFeedback.boundingBox.endIndex != currentBox.qnaFeedback?.endIndex)
                )) {
                isAnswerTextModified = true;
            }
        }
        
        if (isAnswerTextModified) {
            selectionObj['robertaModel'] = {
                'id': robertaFeedbackId,
                'selectionId': currentBox.isAiBox ? currentBox.id : currentBox.qnaFeedback.id,
                'text': currentBox.isAiBox ? currentBox.feedbackText : currentBox.qnaFeedback.feedbackText,
                'pageNum': currentBox.pageNo,
                'boundingBox': {
                    'startCursor': currentBox.isAiBox ? getScaledCursor(currentBox.startCursor, manualFeedbackConstantFactor, true) : getScaledCursor(currentBox.qnaFeedback.startCursor, manualFeedbackConstantFactor, true),
                    'endCursor': currentBox.isAiBox ? getScaledCursor(currentBox.endCursor, manualFeedbackConstantFactor, true) : getScaledCursor(currentBox.qnaFeedback.endCursor, manualFeedbackConstantFactor, true),
                    'startIndex': currentBox.isAiBox ? currentBox.startIndex : currentBox.qnaFeedback.startIndex,
                    'endIndex': currentBox.isAiBox ? currentBox.endIndex : currentBox.qnaFeedback.endIndex,
                    'position': [+(currentBox.x1 / manualFeedbackConstantFactor).toFixed(3), +(currentBox.y1 / manualFeedbackConstantFactor).toFixed(3)],
                    'size': [+(currentBox.h / manualFeedbackConstantFactor).toFixed(3), +(currentBox.w / manualFeedbackConstantFactor).toFixed(3)]
                }
            }
        }
        if (isSemanticTextModified) {
            selectionObj['USEModel'] = {
                'id': useFeedbackId,
                'selectionId': currentBox.id,
                'text': currentBox.feedbackText,
                'pageNum': currentBox.pageNo,
                'boundingBox': {
                    'startCursor': getScaledCursor(currentBox.startCursor, manualFeedbackConstantFactor, true),
                    'endCursor': getScaledCursor(currentBox.endCursor, manualFeedbackConstantFactor, true),
                    'position': [+(currentBox.x1 / manualFeedbackConstantFactor).toFixed(3), +(currentBox.y1 / manualFeedbackConstantFactor).toFixed(3)],
                    'size': [+(currentBox.h / manualFeedbackConstantFactor).toFixed(3), +(currentBox.w / manualFeedbackConstantFactor).toFixed(3)]
                }
            }
        }
        return {
            selectionObj,
            isSemanticTextModified,
            isAnswerTextModified
        };
    }
}

export const navigateToSearchResult = (pageNumber, pdfViewer, isFullPreview) => {
    let timeVal = 250;
    if (pdfViewer.currentPageNumber != pageNumber) {
        pdfViewer.currentPageNumber = pageNumber;
    }
    setTimeout(() => {
        const selEl = document.querySelector(`[data-page-number="${pageNumber}"]`).getElementsByClassName("selected")[0]
        if (selEl) {
            const selElPos = selEl.getBoundingClientRect();
            const vc = document.getElementById('viewerContainer');
            const header = document.getElementsByClassName('header')[0];
            if (selElPos && (selElPos.y > (vc.clientHeight) || selElPos.y < header['offsetHeight'])) {
                selEl.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
            if (selElPos && (selElPos.x > vc.clientWidth || selElPos.x < 0 || (isFullPreview && selElPos.x < 140))) {
                // Condition added if we are in full view mode and the element falls under the thumnbails bar scroll to the right
                vc.scrollLeft = isFullPreview ? selElPos.x - 140 : selElPos.x;
            }
        }
    }, timeVal)
}