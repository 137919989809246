// Permissions codes
export const ID_CREATE_PROJECTS = 1;
export const ID_EDIT_PROJECTS = 2;
export const ID_DELETE_PROJECTS = 3;
export const ID_CREATE_ANALYSIS = 4;
export const ID_EDIT_ANALYSIS = 5;
export const ID_DELETE_ANALYSIS = 6;
export const ID_CREATE_DOCUMENTS = 7;
export const ID_EDIT_DOCUMENTS = 8;
export const ID_DELETE_DOCUMENTS = 9;
export const ID_TRAIN_MODELS = 10;
export const ID_VIEW_DATAMODELS = 11;
export const ID_CREATE_DATAMODELS = 12;
export const ID_EDIT_DATAMODELS = 13;
export const ID_UPLOAD_ENRICHMENT_FILES = 14;
export const ID_CREATE_USERS = 15;
export const ID_EDIT_USERS = 16;
export const ID_DELETE_USERS = 17;
export const ID_ASSIGN_USERS_TO_PROJECTS = 18;
export const ID_CREATE_VALIDATIONS = 19;
export const ID_EDIT_VALIDATIONS = 20;
export const ID_RERUN_VALIDATIONS = 21;
export const ID_UPLOAD_BACKUP_DOCUMENT = 22;
export const ID_SCREEN_VIEW_PROJECT = 23;
export const ID_SCREEN_VIEW_OUTPUT_FILES = 24;
export const ID_SCREEN_VIEW_DASHBOARDS = 25;
export const ID_SCREEN_VIEW_DASHBOARDS_SIRE = 26;
export const ID_SCREEN_VIEW_DASHBOARDS_POWERBI = 27;
export const ID_SCREEN_VIEW_SCAN = 28;
export const ID_SCREEN_VALIDATIONS = 29;
export const ID_SCREEN_CLIENT_DOCUMENTS = 30;
export const ID_SCREEN_USERS_MANAGMENT = 31;
export const ID_SCREEN_CLIENTS_MANAGMENT = 32;
export const ID_SCREEN_TIME_TRACKING = 33;
export const ID_ASSIGN_USERS_TO_DOCUMENTS = 34;
export const ID_CREATE_OUTPUT_FILES = 35;
export const ID_DELETE_OUTPUT_FILES = 36;
export const ID_DOWNLOAD_OUTPUT_FILES = 37;
export const ID_RERUN_PRELIMINARY_VALIDATIONS = 38;
export const ID_EXCLUDE_PRELIMINARY_VALIDATIONS = 39;
export const ID_DOWNLOAD_DOCUMENTS = 40;
export const ID_RECOMMEND_DOCUMENTS = 41;
export const ID_CHANGE_DATAMODEL_DOCUMENTS = 42;
export const ID_CREATE_MANUAL_COMMENTS = 43;
export const ID_ADD_REMOVE_EXTRACTED_LINES_DOCUMENTS = 44;
export const ID_SELECT_CHUNKS = 45;
export const ID_DOWNLOAD_BACKUP_DOCUMENTS = 46;
export const ID_OVERRIDE_VALIDATION = 47;
export const ID_EDIT_MANUAL_COMMENTS = 48;
export const ID_DELETE_MANUAL_COMMENTS = 49;
export const ID_EDIT_REVIEW_FLOW = 50;
export const ID_EDIT_DATAMODELS_REVIEWDAYS = 51;
/* Note:   This permission ID of SCREEN_CLIENT_ONLY_DOCUMENTDETAIL
has to be changes,because 53 is not assigned to View_QueryLIST*/
​export const ID_SCREEN_CLIENT_ONLY_DOCUMENTDETAIL = 99;

export const ID_VIEW_QUERYSHEET = 53;
export const ID_CREATE_QUERYSHEET = 54;
export const ID_EDIT_QUERYSHEET = 55;
export const ID_DELETE_QUERYSHEET = 56;
export const ID_SCREEN_QUERYSHEET_MANAGEMENT = 61;
export const ID_VIEW_EXPLORATION = 57;
export const ID_CREATE_EXPLORATION = 58;
export const ID_EDIT_EXPLORATION = 59;
export const ID_DELETE_EXPLORATION = 60;

// Permissions names
export const CREATE_PROJECTS = 'create_projects';
export const EDIT_PROJECTS = 'edit_projects';
export const DELETE_PROJECTS = 'delete_projects';

export const CREATE_ANALYSIS = 'create_analysis';
export const EDIT_ANALYSIS = 'edit_analysis';
export const DELETE_ANALYSIS = 'delete_analysis';

export const CREATE_DOCUMENTS = 'create_documents';
export const EDIT_DOCUMENTS = 'edit_documents';
export const DELETE_DOCUMENTS = 'delete_documents';
export const ASSIGN_USERS_TO_DOCUMENTS = 'assign_users_to_documents';
export const UPLOAD_BACKUP_DOCUMENT = 'upload_backup_document';
export const DOWNLOAD_BACKUP_DOCUMENTS = 'download_backup_document';
export const DOWNLOAD_DOCUMENTS = 'download_documents';
export const RECOMMEND_DOCUMENTS = 'recommend_document';
export const CHANGE_DATAMODEL_DOCUMENTS = 'change_datamodel_documents';
export const CREATE_MANUAL_COMMENTS = 'create_manual_comments';
export const EDIT_MANUAL_COMMENTS = 'edit_manual_comments';
export const DELETE_MANUAL_COMMENTS = 'delete_manual_comments';

export const ADD_REMOVE_EXTRACTED_LINES_DOCUMENTS =
  'add_remove_extracted_lines_documents';
export const SELECT_CHUNKS = 'select_chunks';

export const TRAIN_MODELS = 'train_models';
export const VIEW_DATAMODELS = 'view_datamodels';
export const CREATE_DATAMODELS = 'create_datamodels';
export const EDIT_DATAMODELS = 'edit_datamodels';
export const EDIT_DATAMODELS_REVIEWDAYS = 'edit_datamodels_reviewdays';

export const UPLOAD_ENRICHMENT_FILES = 'upload_enrichment_files';
export const DELETE_ENRICHMENT_FILES = 'delete_enrichment_files';
export const CREATE_USERS = 'create_users';
export const EDIT_USERS = 'edit_users';
export const DELETE_USERS = 'delete_users';
export const ASSIGN_USERS_TO_PROJECTS = 'assign_users_to_projects';

export const CREATE_VALIDATIONS = 'create_validations';
export const EDIT_VALIDATIONS = 'edit_validations';
export const RERUN_VALIDATIONS = 'rerun_validations';
export const RERUN_PRELIMINARY_VALIDATIONS = 'rerun_preliminary_validations';
export const EXCLUDE_PRELIMINARY_VALIDATIONS =
  'exclude_preliminary_validations';
export const OVERRIDE_VALIDATIONS = 'override_validation';

export const CREATE_OUTPUT_FILES = 'create_output_files';
export const DELETE_OUTPUT_FILES = 'delete_output_files';
export const DOWNLOAD_OUTPUT_FILES = 'download_output_files';

export const SCREEN_VIEW_PROJECT = 'screen_view_project';
export const SCREEN_VIEW_OUTPUT_FILES = 'screen_view_output_files';
export const SCREEN_VIEW_DASHBOARDS = 'screen_view_dashboards';
export const SCREEN_VIEW_DASHBOARDS_SIRE = 'screen_view_dashboards_sire';
export const SCREEN_VIEW_DASHBOARDS_POWERBI = 'screen_view_dashboards_powerbi';
export const SCREEN_VIEW_SCAN = 'screen_view_scan';
export const SCREEN_VALIDATIONS = 'screen_validations';
export const SCREEN_CLIENT_DOCUMENTS = 'screen_client_documents';
export const SCREEN_USERS_MANAGMENT = 'screen_users_management';
export const SCREEN_CLIENTS_MANAGMENT = 'screen_clients_management';
export const SCREEN_TIME_TRACKING = 'screen_time_tracking';
​export const SCREEN_CLIENT_ONLY_DOCUMENTDETAIL = 'screen_client_only_documentdetail';

export const EDIT_REVIEW_FLOW = 'edit_review_flow';

​export const SCREEN_QUERYSHEET_MANAGEMENT = 'screen_querysheet_management';
​export const VIEW_QUERYSHEET = 'view_querysheet';
​export const CREATE_QUERYSHEET = 'create_querysheet';
​export const EDIT_QUERYSHEET = 'edit_querysheet';
​export const DELETE_QUERYSHEET = 'delete_querysheet';
​export const VIEW_EXPLORATION = 'view_exploration';
​export const CREATE_EXPLORATION = 'create_exploration';
​export const EDIT_EXPLORATION = 'edit_exploration';
​export const DELETE_EXPLORATION = 'delete_exploration';

export const UNALLOWED_USERNAME_KEYWORDS = ['admin', 'admn', 'administrator', 'manager', 'mngr', 'viewer' , 'explorer', 'explr' ,'querysheet', 'member'];
