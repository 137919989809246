import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datamodelid',
  templateUrl: './datamodelid.component.html',
  styleUrls: ['./datamodelid.component.scss']
})
export class DatamodelidComponent implements OnInit {
  @Input('formTest') formTest;
  @Input('datamodels') datamodels;

  constructor() {}

  ngOnInit() {}
}
