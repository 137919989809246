import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import * as userPermissionsConst from '../../constants/userPermissions';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent implements OnInit {
  public message: string;
  public loading: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
    if (this.router.url === '/access-denied') {
      this.message =
        'You are not allowed to access. Please contact your administrator.';
    } else {
      this.message =
        'Server temporaly unavailable. Try again in a few minutes...';
    }
  }

  ngOnInit() {}

  onRetryLogin() {
    this.loading = true;
    const returnTo = '/';
    this.router.navigate([returnTo]).then(
      _ => {
        this.loading = false;
      },
      _ => {
        this.loading = false;
      }
    );
  }
}
