import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleOrdinal, scaleLinear, scaleTime } from 'd3-scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { max, extent } from 'd3-array';
import { line } from 'd3-shape';
import { axisLeft, axisBottom } from 'd3-axis';
import {
  curveBasis,
  curveBundle,
  curveCardinal,
  curveCatmullRom,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
  curveStepAfter,
  curveStepBefore
} from 'd3-shape'; // Curve variables
const d3 = {
  select,
  selectAll,
  scaleOrdinal,
  scaleLinear,
  scaleTime,
  timeParse,
  timeFormat,
  max,
  extent,
  line,
  axisLeft,
  axisBottom,
  curveBasis,
  curveBundle,
  curveCardinal,
  curveCatmullRom,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
  curveStepAfter,
  curveStepBefore
};

/**
 * Multiple Linechart Component
 */
@Component({
  selector: 'app-d3linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3LineChartComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  private cfg: any = {}; // Default configuration object
  private selection: any;
  private svg;
  private line: any;
  private tdata: Array<any>;
  private xScale: any;
  private yScale: any;
  private colorScale: any;
  private parseTime: any;
  private formatTime: any;
  private loaded: boolean = false;

  constructor(private elementRef: ElementRef) {
    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 400, // Default height
      margin: { top: 10, right: 30, bottom: 50, left: 40 },
      keys: ['key'], // Values to compute
      labels: false, // Labels to display
      dateKey: 'date',
      dateFormat: '%Y-%m-%d', // https://github.com/d3/d3-time-format/blob/master/README.md#locale_format
      colorScheme: 'schemeCategory10', // More schemes in https://github.com/d3/d3-scale-chromatic
      colorKeys: {},
      curve: 'curveLinear', // More examples in https://bl.ocks.org/d3noob/ced1b9b18bd8192d2c898884033b5529
      pointRadius: 3,
      pointHoverRadius: 6,
      yAxis: '', // yAxis title
      xScaleTicks: 3, // xAxis divisions
      xScaleFormat: '%Y-%m-%d', // xAxis format
      yScaleTicks: 5, // yAxis divisions
      yScaleFormat: '.0f' // yAxis format. More on https://github.com/d3/d3-format
    };
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    // Generate color scheme
    if (this.cfg.colorScheme instanceof Array === true) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }

    // Format date functions
    this.parseTime = d3.timeParse(this.cfg.dateFormat);
    this.formatTime = d3.timeFormat('%d-%m-%Y');

    // Calcule transpose data
    this.tdata = [];
    this.cfg.keys.forEach((j, i) => {
      this.tdata[i] = {};
      this.tdata[i]['key'] = j;
      this.tdata[i]['values'] = [];
    });

    this.data.forEach(d => {
      d.jsdate = this.parseTime(d[this.cfg.dateKey]);
    });
    this.data.sort((a, b) => b.jsdate - a.jsdate);

    this.data.forEach(d => {
      d.min = 9999999999;
      d.max = -9999999999;
      this.cfg.keys.forEach((j, i) => {
        this.tdata[i]['values'].push({ x: d.jsdate, y: +d[j], k: i });
        if (d[j] < d.min) d.min = +d[j];
        if (d[j] > d.max) d.max = +d[j];
      });
    });

    // Calcule vertical scale
    this.yScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, d => d.max)])
      .rangeRound([this.cfg.height, 0]);

    // Calcule horizontal scale
    this.xScale = d3
      .scaleTime()
      .domain(d3.extent(this.data, d => d.jsdate))
      .rangeRound([0, this.cfg.width]);

    this.line = d3
      .line()
      .curve(d3[this.cfg.curve])
      .x((d: any) => this.xScale(d.x))
      .y((d: any) => this.yScale(d.y));
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    let self = this;

    // Remove previus chart if exists
    if (this.loaded) this.selection.selectAll('svg').remove();

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--linechart')
      .attr(
        'viewBox',
        '0 0 ' +
          (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
          ' ' +
          (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Axis group
    let axisg = g
      .append('g')
      .attr('class', 'chart__axis chart__axis--linechart');

    // Horizontal axis
    axisg
      .append('g')
      .attr('class', 'chart__axis-x chart__axis-x--linechart')
      .attr('transform', `translate(0,${this.cfg.height})`)
      .call(
        d3
          .axisBottom(this.xScale)
          .ticks(this.cfg.xScaleTicks, this.cfg.xScaleFormat)
      );

    // Vertical axis (horizontal grid)
    axisg
      .append('g')
      .attr('class', 'chart__axis-y chart__axis-y--barchart chart__grid')
      .call(
        d3
          .axisLeft(this.yScale)
          .tickSize(-this.cfg.width)
          .ticks(this.cfg.yScaleTicks, this.cfg.yScaleFormat)
      );

    // Vertical axis title
    if (this.cfg.yAxis)
      axisg
        .append('text')
        .attr('y', -this.cfg.margin.left + 10)
        .attr('x', -this.cfg.height / 2 - this.cfg.margin.top)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(this.cfg.yAxis);

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--linechart');

    // Lines group
    let linesg = g
      .selectAll('.chart__lines-group')
      .data(this.tdata)
      .enter()
      .append('g')
      .attr('class', d => {
        return (
          'chart__lines-group chart__lines-group--linechart chart__lines-group--' +
          d.key
        );
      });

    // Lines
    linesg
      .append('path')
      .attr('class', 'chart__line chart__line--linechart')
      .attr('fill', 'transparent')
      .attr('stroke', d => this.lineColor(d))
      .attr('d', (d: any) => this.line(d.values));

    // Points general group
    let pointsgg = g.append('g');

    // Points
    this.cfg.keys.forEach((k, i) => {
      // Points group
      let pointsg = pointsgg
        .selectAll('.chart__points-group')
        .data(self.data)
        .enter()
        .append('g')
        .attr(
          'class',
          'chart__points-group chart__points-group--linechart chart__points-group--' +
            k
        )
        .attr(
          'transform',
          d => `translate(${self.xScale(d.jsdate)},${self.yScale(d[k])})`
        );

      // Hover points
      pointsg
        .append('circle')
        .attr(
          'class',
          'chart__hover-point chart__hover-point--linechart chart__hover-point--' +
            k
        )
        .attr('fill', 'transparent')
        .attr('r', self.cfg.pointHoverRadius)
        .on('mouseover', (d, data) => {
          tooltip
            .html(() => {
              let label =
                self.cfg.labels && self.cfg.labels[i] ? self.cfg.labels[i] : k;
              return `
              <div>${label}: ${data[k]}</div>
            `;
            })
            .classed('active', true);
        })
        .on('mouseout', () => {
          tooltip.classed('active', false);
        })
        .on('mousemove', () => {
          tooltip
            .style('left', window.event['pageX'] - 100 + 'px')
            .style('top', window.event['pageY'] - 76 + 'px');
        });

      // Visible points
      pointsg
        .append('circle')
        .attr(
          'class',
          'chart__point chart__point--linechart chart__point--' + k
        )
        .attr('pointer-events', 'none')
        .attr('fill', d => self.lineColor(k))
        .attr('r', self.cfg.pointRadius);
    });

    this.loaded = true;
  }

  /**
   * Compute line color
   */
  private lineColor(d) {
    if (this.cfg.colorKeys && this.cfg.colorKeys.hasOwnProperty(d.key)) {
      return this.cfg.colorKeys[d.data[this.cfg.key]];
    } else {
      return this.colorScale(d.key);
    }
  }
}
