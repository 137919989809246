import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FAILURE_DISPLAYNAME } from 'src/app/constants/validationStatus';

import { PagesSelection } from '../modals/update-children/pages-selection';

export class Thumbnail {
  src: any;
  isLoading: boolean;
  triedToLoad: boolean;
  error: boolean;
  isSelected?: boolean;
}

@Component({
  selector: 'app-document-thumbnails',
  templateUrl: './document-thumbnails.component.html',
  styleUrls: ['./document-thumbnails.component.scss']
})
export class DocumentThumbnailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() disabled: boolean;
  @Input() thumbnails: Thumbnail[];
  @Input() selectedPage: number;
  @Input() pagesSelection: PagesSelection;
  @Input() pageNumbers: number[];
  @Input() displayExternalPageNumbers: boolean = false;

  @Output() clickThumbnail = new EventEmitter<number>();

  private selectedIndex: number;
  private pagesSelectionChangeSubscription: Subscription;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPage) {
      this.selectedIndex = this.getThumbnailIndex(this.selectedPage);
    }

    if (changes.pagesSelection) {
      this.pagesSelectionChangeSubscription?.unsubscribe();

      if (this.pagesSelection) {
        this.pagesSelectionChangeSubscription = this.pagesSelection
          .getSelectionChanges()
          .subscribe(() => {
            this.updateSelection();
          });

        this.updateSelection();
      }
    }
  }

  ngOnDestroy(): void {
    this.pagesSelectionChangeSubscription?.unsubscribe();
  }

  private getThumbnailIndex(pageNumber: number) {
    return this.pageNumbers
      ? this.pageNumbers.findIndex(
          (includedPageNumber: number) => includedPageNumber == pageNumber
        )
      : pageNumber - 1;
  }

  public getPageNumber(index: number) {
    return this.pageNumbers ? this.pageNumbers[index] : index + 1;
  }

  onClickThumbnail(index: number): void {
    if (this.isEnabled(index)) {
      const pageNumber = this.getPageNumber(index);
      this.clickThumbnail.emit(pageNumber);
    }
  }

  private isEnabled(index: number): boolean {
    return !this.disabled && index != this.selectedIndex;
  }

  private updateSelection() {
    this.thumbnails.forEach((thumbnail: Thumbnail, i: number) => {
      const pageNumber = i + 1;
      thumbnail.isSelected = this.pagesSelection.isSelected(pageNumber);
    });
  }

  togglePageSelection(index: number): void {
    if (this.pagesSelection) {
      const thumbnail = this.thumbnails[index];
      const pageNumber = index + 1;

      if (this.pagesSelection.canSelect(pageNumber)) {
        const wasPageSelected = this.pagesSelection.isSelected(pageNumber);
        thumbnail.isSelected = !wasPageSelected;
        this.pagesSelection.setSelected(pageNumber, thumbnail.isSelected);
      }
    }
  }
}
