import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import {
  ApplicantInformation,
  BodyInformation,
  FamilyInformation,
  ImpairmentsInformation,
  ImpairmentsSetInformationItem,
  LifestyleInformation,
  LifeUnderWritingGeneralInformation,
  LifeUnderWritingInformation,
  MedicationsInformation,
  ReferralsInformation,
  SurgeryInformation,
  TreatmentsInformation,
  VitalsInformation
} from 'src/app/models/document-dashboard.model';
import { LifeUnderWritingPanelComponent } from './lifeunderwriting-panel/lifeunderwriting-panel.component';


@Component({
  selector: 'app-lifeunderwriting-dashboard',
  templateUrl: './lifeunderwriting-dashboard.component.html',
  styleUrls: ['./lifeunderwriting-dashboard.component.scss'],
})
export class LifeUnderWritingDashboardComponent
  implements OnInit {

  @Input() dashboardData: LifeUnderWritingInformation;

  itemsPerPage: number = 5;
  currentPage = {};

  applicantInformation: ApplicantInformation;

  currentGeneralInformation: LifeUnderWritingGeneralInformation;
  currentBodyInformation: BodyInformation;
  currentVitalsInformation: VitalsInformation;
  currentLifestyleInformation: LifestyleInformation;
  currentFamilyInformation: FamilyInformation;
  currentMedicationsInformation: MedicationsInformation;
  currentSurgeryInformation: SurgeryInformation;
  currentReferralsInformation: ReferralsInformation;
  currentTreatmentsInformation: TreatmentsInformation;

  currentImpairmentsInformation: ImpairmentsInformation;
  currentImpairmentsOverviewInformation: ImpairmentsSetInformationItem[];

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.setDashboardData();
  }

  /**
   * Extract parts of dashboards data.
   */
  private setDashboardData() {
    this.applicantInformation =
      this.dashboardData?.applicantinformation;

    this.currentGeneralInformation =
      this.dashboardData?.generalinformation;

    this.currentBodyInformation =
      this.currentGeneralInformation?.body;

    this.currentVitalsInformation =
      this.currentGeneralInformation?.vitals;

    this.currentLifestyleInformation =
      this.currentGeneralInformation?.lifestyle;

    this.currentFamilyInformation =
      this.currentGeneralInformation?.family;

    this.currentMedicationsInformation =
      this.currentGeneralInformation?.currentmedications;

    this.currentSurgeryInformation =
      this.currentGeneralInformation?.surgery;

    this.currentReferralsInformation =
      this.currentGeneralInformation?.referrals;

    this.currentTreatmentsInformation =
      this.currentGeneralInformation?.treatments;

    this.currentImpairmentsInformation =
      this.dashboardData?.impairments;

    this.currentImpairmentsOverviewInformation =
      this.currentImpairmentsInformation?.impairmentsoverview;
  }

  /**
   * Called when a page of a paginated item list is selected.
   */
  public onPageChange(id: string, page: number): void {
    this.currentPage[id] = page;
  }

  /**
   * Show panel.
   */
  public showPanel(contentType: string, contentTab?: string): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'xl'
    };
    const modalWindowRef = this.modalService.open(
      LifeUnderWritingPanelComponent,
      modalOptions
    );
    const panelComponent: LifeUnderWritingPanelComponent =
      modalWindowRef.componentInstance;

    panelComponent.dashboardData = this.dashboardData;
    panelComponent.contentType = contentType;
    panelComponent.contentTab = contentTab;
  }

}
