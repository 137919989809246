import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-exploration-step',
  templateUrl: './create-exploration-step.component.html',
  styleUrls: ['./create-exploration-step.component.scss']
})
export class CreateExplorationStepComponent implements OnInit {

  @Input() data: { data: [] } = null;
  @Input() apiErrors = null;

  public addExpNameForm: FormGroup;
  public selDocSummaryData = null;
  public finalArray = [];
  public selectedDocumentIds = [];
  public loading: boolean = false;
  public ellipseText: boolean = true;
  @Input() explorationName: string = '';
  @Input() checkIfExplorationChanged;
  constructor() { }

  ngOnInit(): void {
    this.selDocSummaryData = this.data.data;
    this.getSummaryDisplay();

    this.addExpNameForm = new FormGroup({
      addexplorationname: new FormControl('', [Validators.required])
    });
    if (this.explorationName) {
      this.addExpNameForm.get('addexplorationname').setValue(this.explorationName)
    }
  }

  /*
    Grouping of the Documents as Project and analysis on exploration summaryDisplay
  */
  public getSummaryDisplay() {

    this.loading = true;
    const refObj = {};
    this.finalArray = [];
    this.selectedDocumentIds = [];

    for (let row of this.selDocSummaryData) {
      if (row.isRowSelected) {
        this.selectedDocumentIds.push(row.documentid)
        if (refObj[row.project.projectid]) {
          const mainIndex = refObj[row.project.projectid].index;
          const analysisIndex = refObj[row.project.projectid][row.analysis.analysisid]

          if (analysisIndex) {
            this.finalArray[mainIndex].analysis[analysisIndex].documentObj.push(row.documentdisplayname);
          } else {
            this.finalArray[mainIndex].analysis.push(
              {
                analysisObj: row.analysis,
                documentObj: [row.documentdisplayname]
              });

            refObj[row.project.projectid][row.analysis.analysisid] = this.finalArray[mainIndex].analysis.length - 1
          }

        } else {
          refObj[row.project.projectid] = { index: this.finalArray.length };
          refObj[row.project.projectid][row.analysis.analysisid] = '0';

          this.finalArray.push({
            projectname: row.project.projectname,
            analysis: [
              {
                analysisObj: row.analysis,
                documentObj: [row.documentdisplayname]
              }
            ],
          })
        }

      }
    }

    this.loading = false;
  }

  // Show more or less content based in click button
  public showMoreBtn(docIndex) {
    for (let row of this.finalArray) {
      for (let rowInner of row.analysis) {
        if (rowInner.analysisObj.analysisid == docIndex) {
          rowInner.readmore = true;
        } else {
          rowInner.readmore = false;
        }
      }
    }
  }

  //toggle the read more/less document test on click of button
  public hideLessBtn(docIndex) {
    for (let row of this.finalArray) {
      for (let rowInner of row.analysis) {
        if (rowInner.analysisObj.analysisid == docIndex) {
          rowInner.readmore = false;
        } else {
          rowInner.readmore = false;
        }
      }
    }
  }

  // Reset the Api error response on addexplorationname input field
  public resetApiError() {
    const currentErrorValue = this.addExpNameForm.get('addexplorationname').errors;
    this.checkIfExplorationChanged("explorationName", this.addExpNameForm.get('addexplorationname').value)
    if (currentErrorValue && currentErrorValue.apiError) {
      delete this.addExpNameForm.get('addexplorationname').errors.apiError;
    }
  }

}

