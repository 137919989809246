import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';

@Component({
  selector: 'app-document-generic-ocr',
  templateUrl: './document-generic-ocr.component.html',
  styleUrls: ['./document-generic-ocr.component.scss']
})
export class DocumentGenericOcrComponent implements OnInit, OnDestroy {
  @Input() documentDetails: any;
  public pagesSelection: PagesSelection;
  public isChildrenEditDisabled: boolean = false;
  @ViewChild('qnaWrapper', { static: false }) refQnaWrapper: ElementRef;
  @ViewChild('deletePopup', { static: false }) refdeletePopup: ElementRef;
  public searchString: string = '';
  public searchStringDisbale: Boolean = false;
  public QnAData: any = [];
  public isLoading: Boolean = false;
  public QnAisLoading: Boolean = false;
  public flagQnAisLoadingAndSearchDisbale : Boolean = false;
  public isDeleteActive: Boolean = false;
  public isCopied: Boolean = false;
  public isEditable: Boolean = false;
  public editedPageChunks: any = []
  public domSelectedText: string = ''
  public getIsLoadingChunksSub: Subscription;
  public getPageChunk: Subscription;
  public isRefreshingChunks: boolean = false;
  public chunkData: any
  public pageChunk: any = []
  public pageChunkTextContent: any = []
  public currentPage: number = 1
  public prevPage: number = 1


  constructor(
    private changeDetector: ChangeDetectorRef,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe,
    public documentRenderService: DocumentRenderService
  ) {

    this.documentRenderService.getCurrentPage()
        .subscribe(page => {
          if (page && page.pagenum) {
            this.prevPage = this.currentPage
            this.currentPage = page.pagenum

            if (this.chunkData && this.chunkData[this.currentPage]) {
              this.bindPageChunk()
            }
          }


    })

    this.getIsLoadingChunksSub = this.documentRenderService
      .getIsLoadingChunks()
      .subscribe(obj => {
        this.isRefreshingChunks = obj.state;
        this.pageChunk = []
        this.pageChunkTextContent = []
        if (!this.isRefreshingChunks) {
          this.chunkData = this.documentRenderService.getChunks()

          if (this.chunkData && this.chunkData[this.currentPage]) {
            this.bindPageChunk()
          }
        }

      });
  }

  ngOnInit(): void {
    // this.fetchListOfQnA();
    console.log("this.pagesSelection", this.pagesSelection)
  }

  ngOnDestroy() {
    if (this.getIsLoadingChunksSub) {
      this.getIsLoadingChunksSub.unsubscribe();
    }
  }

  public bindPageChunk() {
    this.pageChunk = []
    this.pageChunkTextContent = []
    if (this.prevPage != this.currentPage) {
      this.isEditable = false
      this.editedPageChunks = []
    }
    this.chunkData[this.currentPage].forEach(eachItem => {
      if (eachItem && eachItem.chunktext && eachItem.chunktext.length > 0) {
        eachItem.chunktext.forEach(chunkItem => {
          if (chunkItem) {
            this.pageChunk.push({"chunkTextId":chunkItem.chunktextid,"textContent":chunkItem.textcontent})
            this.pageChunkTextContent.push(chunkItem.textcontent)
          }
        })
      }
    })
  }

  /**
  * Set pages selection.
  */
  public setPagesSelection(pagesSelection: PagesSelection) {
    this.pagesSelection = pagesSelection;

    // Pages selection usually comes from a child component
    // so trigger change detection.
    this.changeDetector.detectChanges();
  }

  /**
 * Disable children edition.
 */
  public disableChildrenEdit(): void {
    this.isChildrenEditDisabled = true;
  }

  /**
   * Copy pageChunk Text to clipboard
   */
  public copySummaryTextToClipboard() {
    this.isCopied = true;
    if (this.domSelectedText) {
      navigator.clipboard.writeText(this.domSelectedText);
    } else {
      navigator.clipboard.writeText(this.pageChunkTextContent.join(""));
    }
    this.domSelectedText = ''
  }

  /**
   * Enable Edit mode for extracted page chunk
   */
  public enableEditforPageChunk() {
    this.isEditable = true;
    this.alertService.success(
      this.translate.transform('documentDetails.enableEditMsg'),
      true
    );
  }
  /**
   * save method to update the text chunk values in db
   */
  public savePagechunk() {
    this.isEditable = false;
    const params = {
      chunkTextlist: this.editedPageChunks
    }
    if (params && params.chunkTextlist.length > 0) {
      this.apiService
          .put(`docchunks/${this.documentDetails.documentid}`,'', params, '')
          .subscribe(data => {
            this.documentRenderService.getDocumentChunksFromDB(this.currentPage, true)
            this.documentRenderService.chunksWatcher()
            .subscribe(obj => {
              this.chunkData = this.documentRenderService.getChunks()

              if (this.chunkData && this.chunkData[this.currentPage]) {
                this.bindPageChunk()
              }
            });

            this.alertService.success(
              this.translate.transform('documentDetails.successfullysavedMsg'),
              true
            );
            this.editedPageChunks = []
          });
    } else {
      this.alertService.info(
        this.translate.transform('documentDetails.saveInfoMsg'),
        true
      );
    }
  }

  /**
   * Handle edit functionality using onblur
   */
  public onBlur(event: any, chunktext: any) {
    const finddiff = (diffMe, diffBy) => diffMe.split(diffBy).join('')
    const difference = finddiff(event.target.outerText, chunktext["textContent"].replace(/\n/g, ''))

    if(difference) {
      if(this.editedPageChunks.length > 0) {
        const matched_index = this.editedPageChunks.findIndex(item => item.chunkTextId ===chunktext.chunkTextId)
        if (matched_index >= 0) {
          this.editedPageChunks[matched_index].textContent = event.target.outerText
        } else {
              this.editedPageChunks.push({
                chunkTextId: chunktext["chunkTextId"],
                textContent: event.target.outerText
              })
        }
      }  else {
        this.editedPageChunks.push({
          chunkTextId: chunktext["chunkTextId"],
          textContent: event.target.outerText
        })
      }

    } else if (difference == '') {
      const matched_index = this.editedPageChunks && this.editedPageChunks.length > 0 && this.editedPageChunks.findIndex(item => item.chunkTextId ===chunktext.chunkTextId)
      if (matched_index > -1) {
        this.editedPageChunks.splice(matched_index, 1)
      }
    }
  }

  /**
   * Get User selected text from windows object
   */
  getSelectionText() {
    if (document.getSelection) {
        var sel = window.getSelection();
        var range = sel.getRangeAt(0);
        var selectedText = range.toString();
        this.domSelectedText = selectedText;
    }
  }

}
