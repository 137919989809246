import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CognitiveSearchService } from '../../cognitive-search.service';

@Component({
  selector: 'app-extracted-filter',
  templateUrl: './extracted-filter.component.html',
  styleUrls: ['./extracted-filter.component.scss']
})
export class ExtractedFilterComponent implements OnInit {
  @Input() public extractedFilter: FormGroup;
  @Input() public fieldOfInterestList: any[];
  filteredOptions: Observable<string[]>;

  constructor(private cognitiveService: CognitiveSearchService) {}

  ngOnInit() {
    this.extractedFilter.markAllAsTouched();

    this.filteredOptions = this.extractedFilter
      .get('field_of_interest_name')
      .valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return Object.keys(this.fieldOfInterestList).filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  optionSelected(event) {
    this.cognitiveService.addFieldOfInterest(
      this.extractedFilter,
      this.fieldOfInterestList[event.option.value]
    );
  }
}
