import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-external-viewer',
  templateUrl: './external-viewer.component.html',
  styleUrls: ['./external-viewer.component.scss']
})
export class ExternalViewerComponent implements OnInit {
  public _userPermissions: any;

  constructor(
    private authService: AuthenticationService,
    private global: GlobalService
  ) {
    authService.checkAccess('ClientDocumentsFunctionality');
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  ngOnInit() {}
}
