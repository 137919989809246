export const environment = {
  env: window['env'] || 'develop',
  apiUrl: window['apiUrl'] || 'http://##Missing_URL##',
  azure_tenant_id: window['a_t_id'] || '##A_T_ID##',
  azure_application_id: window['a_a_id'] || '##A_A_ID##',
  tokenHeader: window['tokenHeader'] || '##MISSING_TOKEN##',
  mock: window['mock'] || false,
  pbi_t_id: window['pbi_t_id'] || '##MISSING_PBI_T_ID##',
  pbi_a_id: window['pbi_a_id'] || '##MISSING_PBI_A_ID##',
  pbi_cs_id: window['pbi_cs_id'] || '##MISSING_PBI_CS_ID##',
  pbi_scope: window['pbi_scope'] || '##MISSING_PBI_SCOPE##',
  authType: window['authType'] || 'msal',
  theme: window['theme'] || 'default'
}
