import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ApiService } from 'src/app/services/api.service';
import { PaginationModel } from 'src/app/models/pagination';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { Subject, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss']
})
export class TimeTrackingComponent implements OnInit, OnChanges, OnDestroy {
  public user: any;
  public isLoading: boolean = true;
  public isLoadingTable: boolean = true;

  public kpis: any = {};
  public kpisLevels: number = 0;
  public kpisPages: number = 0;
  public filters: any = {
    reviewdate: 'week'
  };
  public kpiTotalDocs: number = 0;
  public tableFilters: any = {};
  public activeTooltip: string = '';
  public documents: Array<any>;
  private pagination: PaginationModel;

  // validation variables 
  public subscriptionReviewers: Subscription;

  public labelFilters: any[];
  public datamodelList: any[];
  public showFilters: boolean = false;
  public totalDocuments: number = 0;

  public columns: any[];
  public extraColumns: string[] = [];
  public rows: {};
  public settingTable: any;

   //Property to emit filter to delete in the tracking filters component
   public filterEvent: Subject<string> = new Subject<string>();

  public levelChartConfig: any = {
    key: 'totaltime',
    label: 'level',
    barValue: 'strtime',
    colorScheme: ['#2fc8d4'],
    showAxis: false,
    height: 260,
    margin: { top: 30, bottom: 20 }
  };

  public pageChartConfig: any = {
    key: 'totaltime',
    label: 'page',
    barValue: 'strtime',
    colorScheme: ['#a8d959'],
    showAxis: false,
    height: 260,
    margin: { top: 30, bottom: 20 }
  };

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private apiService: ApiService,
    private paginationService: PaginationService,
    private link: LinkService,
    private global: GlobalService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();
    this.pagination = this.paginationService.getDefaultPagination(
      'timetracking'
    );
  }

  ngOnInit() {
    this.datamodelList = Array.from(this.global.getDatamodels().values());

    if(this.datamodelList.length === 0){
      this.subscriptionReviewers = this.global.watchDataModels().subscribe(() => {
        this.datamodelList = Array.from(
          this.global.getDatamodels().values()
        );
      })
    }

    this.getKPIsData();
    this.getDocumentsTable();
    this.getColumns();
    this.getSettingTable();
  }

  ngOnChanges() {
      this.getColumns();
      this.getSettingTable();
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  /**
   * Request KPIs
   */
  private getKPIsData() {
    this.isLoading = true;
    const params = {
      ...this.filters,
      tzoffset: -1 * new Date().getTimezoneOffset()
    };
    this.apiService.get('logs/kpis/', params, '').subscribe(data => {
      this.kpiTotalDocs = data.total;
      this.parseKPIresponse(data);
      this.isLoading = false;
    });
  }

  public getSettingTable() {
    this.settingTable = {
      getDataFromDB: this.getDocumentsTable.bind(this),
      hasDownloadOptions: false,
      hasSelect: false,
      extraColumns: this.extraColumns, 
      responsiveTitle: {
        label: 'document'
      }
    };
  }

  /**
   * Get documents (table)
   */
  private getDocumentsTable() {
    this.isLoadingTable = true;
    this.apiService
      .get(
        'documents/',
        {
          ...this.filters,
          ...this.tableFilters,
          kpi: 'timetracking',
          page: this.pagination.page,
          tzoffset: -1 * new Date().getTimezoneOffset()
        },
        ''
      )
      .subscribe((data: any) => {
        this.totalDocuments = data.total_elements;
        this.rows = data; 
        this.transformTimeKpis(this.rows);
        this.formatLastThreeColumns(this.rows);
        this.pagination = this.paginationService.setPagination('timetracking', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.isLoadingTable = false;
      });
  }

  public getColumns(){
    this.columns = [
      {
        header: 'timetracking.document',
        name: 'documentdisplayname',
        type: 'link-timetracking',
        title: 'documentdisplayname',
        secname: 'documentid',
        thirdname: 'datamodeldisplayname',
      },
      {
        header: 'timetracking.project',
        name: 'project',
        second: 'projectname'
      },
      {
        header: 'timetracking.amount',
        name: 'amount',
      },
      {
        header: 'timetracking.vendor',
        name: 'vendor',
      },
      {
        header: 'timetracking.kpi1_title',
        name: 'kpis',
        second: 'kpi1',
      },
      {
        header: 'timetracking.kpi2_title',
        name: 'kpis',
        second: 'kpi2'
      },
      {
        header: 'timetracking.kpi3_title',
        name: 'kpis',
        second: 'kpi3'
      },
      {
        header: 'timetracking.kpi4_title',
        name: 'kpis',
        second: 'kpi4'
      },
      {
        header: 'timetracking.kpi7_title',
        name: 'kpis',
        second: 'kpi7'
      },
      {
        header: 'timetracking.kpi8_title',
        name: 'kpis',
        second: 'kpi8'      
      },
      {
        header: 'timetracking.kpi9_title',
        name: 'kpis',
        second: 'format'
      },
      {
        header: 'timetracking.kpi10_title',
        name: 'levels',
        second: 'format'
      },
      {
        header: 'timetracking.kpi11_title',
        name: 'pages',
        second: 'format'
      }
    ];
  }

  public onLoadingChange(bool) {
    this.isLoading = bool;
  }

  /**
   * This function gets the kpis to transform in seconds
   * from our rows object. The kpis of interest to do so
   * are so specific that the had to be written one by one
   * @param rows 
   */
  public transformTimeKpis(rows: Object){
    rows['data'].forEach(row => {
      row.kpis['kpi1'] = this.getDurationFromSeconds(row.kpis['kpi1']);
      row.kpis['kpi2'] = this.getDurationFromSeconds(row.kpis['kpi2']);
      row.kpis['kpi3'] = this.getDurationFromSeconds(row.kpis['kpi3']);
      row.kpis['kpi4'] = this.getDurationFromSeconds(row.kpis['kpi4']);
      row.kpis['kpi7'] = this.getDurationFromSeconds(row.kpis['kpi7']);
      row.kpis['kpi8'] = this.getDurationFromSeconds(row.kpis['kpi8']);
    })
  }

  /**
   * This function formats the last three columns to show the desired data
   * @param rows 
   */
  public formatLastThreeColumns(rows: Object){
    rows['data'].forEach(row => {
      //the use of the new 'format' key avoid overriding existing keys
      row.kpis['format'] = `CR ${row.kpis['kpi5']}\n RR ${row.kpis['kpi6']} \n RA ${row.kpis['kpi9']}`;

      row.levels['format'] = `1 ${this.getDurationFromSeconds(row.levels['1'])}\n 2 ${this.getDurationFromSeconds(row.levels['2'])}\n 3 ${this.getDurationFromSeconds(row.levels['3'])}\n 4 ${this.getDurationFromSeconds(row.levels['4'])}`;

      row.pages['format'] = `E ${this.getDurationFromSeconds(row.pages['Extraction'])}\n B ${this.getDurationFromSeconds(row.pages['Business Rules'])}\n P ${this.getDurationFromSeconds(row.pages['Preliminary'])}\n C ${this.getDurationFromSeconds(row.pages['Catalog'])}`;
    })
  }

  /**
   * Parse response data to get final KPIs to show
   */
  private parseKPIresponse(data) {
    this.kpis = {
      kpi1: this.parseTimeKPI(data.kpi1),
      kpi2: this.parseTimeKPI(data.kpi2),
      kpi3: this.parseTimeKPI(data.kpi3),
      kpi4: this.parseTimeKPI(data.kpi4),
      kpi5: data.kpi5,
      kpi6: data.kpi6,
      kpi7: this.parseTimeKPI(data.kpi7),
      kpi8: this.parseTimeKPI(data.kpi8),
      kpi9: this.parseKPI9(data.kpi9),
      kpi10: this.parseBarKPI(data.kpi10),
      kpi11: this.parseBarKPI(data.kpi11)
    };

    this.kpisLevels = this.kpis.kpi10.reduce((acc, o) => acc + o.totaltime, 0);
    this.kpisPages = this.kpis.kpi11.reduce((acc, o) => acc + o.totaltime, 0);
  }

  /**
   * Parse KPIs 1, 2, 3, 4, 5, 7, 8
   * -> Calcule totals and avgs and
   *    convert seconds to 00:00:00 format
   */
  private parseTimeKPI(items, field = 'totaltime') {
    const sum = items.reduce((acc, o) => acc + o[field], 0);
    const avg = items.length ? sum / items.length : 0;

    return {
      sum: this.getDurationFromSeconds(sum),
      avg: this.getDurationFromSeconds(avg)
    };
  }

  /**
   * Parse KPIs 10 and 11
   */
  private parseBarKPI(items) {
    return items.map(item => ({
      ...item,
      strtime: this.getDurationFromSeconds(item.totaltime)
    }));
  }

  /**
   * Parse KPI 9
   * Number of re-assignments to lower revision levels
   */
  private parseKPI9(items) {
    return items
      .filter(item => item.version && item.version > 0)
      .reduce((acc, o) => +o.count + acc, 0);
  }

  /**
   * Get duration string from total seconds
   * 4173 -> 01:09:33
   */
  public getDurationFromSeconds(seconds: number) {
    const days = Math.floor(seconds / (60 * 60 * 24));
    seconds -= +days * 60 * 60 * 24;

    const hours = Math.floor(seconds / (60 * 60)) % 24;
    seconds -= +hours * 60 * 60;

    const minutes = Math.floor(seconds / 60) % 60;
    seconds = Math.floor(seconds - minutes * 60);

    return days > 0
      ? `${days} days, ${this.leftPad(hours)}:${this.leftPad(
          minutes
        )}:${this.leftPad(seconds)}`
      : `${this.leftPad(hours)}:${this.leftPad(minutes)}:${this.leftPad(
          seconds
        )}`;
  }

  /**
   * Get left padded numbers
   * 7 -> 07
   */
  private leftPad(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  /**
   * Mark current tooltip as visible
   * @param name: kpi name
   */
  private showTooltip(name) {
    this.activeTooltip = name;
  }

  /**
   * Hide all tooltips
   */
  private hideTooltips() {
    this.activeTooltip = '';
  }

  /**
   * Variable to show the filter panel or not
   */
   public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Get kpis with filters
   */
  public onSearch(filters) {
    this.filters = { ...filters };
    this.tableFilters = {};
    this.getKPIsData();
    this.getDocumentsTable();
  }

  public onTableSearch(filters) {
    this.pagination = this.paginationService.getDefaultPagination(
      'timetracking'
    );

    this.tableFilters = {
      documentid:
        filters['documentid'] !== undefined ? filters['documentid'] : '',
      documentdisplayname:
        filters['documentdisplayname'] !== undefined
          ? filters['documentdisplayname']
          : '',
      documentdisplayname_logic:
        filters['documentdisplayname_logic'] !== undefined
          ? filters['documentdisplayname_logic']
          : '',
      projectname:
        filters['projectname'] !== undefined
          ? filters['projectname']
          : '',
      projectname_logic:
        filters['projectname_logic'] !== undefined
          ? filters['projectname_logic']
          : '',
      datamodelid:
        filters['datamodelId'] !== undefined
          ? filters['datamodelId']
          : '',
    }

    this.labelFilters = Object.keys(this.tableFilters)
      .filter(k => this.tableFilters[k] !== '')
      .map(f => {
        switch (f) {
          case 'documentid':
            return {
              name: 'documentid',
              value: filters['documentid']
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname',
              value: filters['documentdisplayname']
            };
          case 'documentdisplayname_logic':
            return {
              name: 'documentdisplayname_logic',
              value: filters['documentdisplayname_logic']
            };
          case 'projectname':
            return {
              name: 'projectname',
              value: filters['projectname']
            };
          case 'projectname_logic':
            return {
              name: 'projectname_logic',
              value: filters['projectname_logic']
            };
          case 'datamodelid':
            return {
              name: 'datamodelname',
              value: filters['datamodelname']
            };
          default:
            return {
              name: f,
              value: this.filters[f]
            };
        }
      });

    this.getDocumentsTable();
  }

  /**
   * Function that returns the inner logic text value
   */
   public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  cleanIndividualFilter(filter: string){
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);

    if(filter === 'datamodelname'){
      filter = 'datamodelid'
    }

    this.filterEvent.next(filter);
    this.tableFilters[filter] = ''; 
    
    if(filter === 'documentdisplayname'){
      this.tableFilters['documentdisplayname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'documentdisplayname_logic');
    }
    if(filter === 'projectname'){
      this.tableFilters['projectname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'projectname_logic');
    }

    this.getDocumentsTable();
  }

  /**
   * Change page in documents table
   */
  public onPageChange(page) {
    this.isLoadingTable = true;
    this.pagination = this.paginationService.changePagination(
      'timetracking',
      'page',
      page
    );
    this.getDocumentsTable();
  }

  public showTableElement(document) {
    this.link.goToDocument(document);
  }

  public getUserLevelsTitle(document) {
    return document.user_levels
      .map(
        ul =>
          `L${ul.level} - ${ul.user} - ${this.getDurationFromSeconds(ul.time)}`
      )
      .join('\n');
  }
}
