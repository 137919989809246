import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { PaginationService } from 'src/app/services/pagination.service';
import { PaginationModel } from 'src/app/models/pagination';

@Component({
  selector: 'app-responsive-table-modal',
  templateUrl: './responsive-table-modal.component.html',
  styleUrls: ['./responsive-table-modal.component.scss']
})
export class ResponsiveTableModalComponent implements OnInit {
  @Input() options;
  private paginationModal: PaginationModel;
  public modalData;
  public rowsModal;
  
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private paginationService: PaginationService
  ) {}

  ngOnInit() {
    this.paginationModal = this.paginationService.getDefaultPagination('modal');
    this.modalData = this.getDataModal(this.options.tableSettings.data);
    this.paginationService.setPage('modal', this.modalData);
    this.paginationService.setPagination('modal', {
      total_elements: this.modalData['total_elements'],
      page: 1,
      page_size: this.modalData['page_size'],
      num_pages: this.modalData['num_pages'],
      order_by: this.modalData['order_by'],
      desc: this.modalData['desc'],
      query: this.modalData['query']
    });
    this.options.tableSettings.settings['getDataFromDB'] =
      this.getDocumentsOfConsolidated.bind(this);
    this.rowsModal = this.modalData;
  }

  getDataModal(data) {
    let num_pages = 1;
    if (this.paginationModal.page_size <= data.length) {
      num_pages = Math.floor(data.length / this.paginationModal.page_size);
      if (data.length % this.paginationModal.page_size > 0) {
        num_pages = num_pages + 1;
      }
    }

    return {
      total_elements: data.length,
      page: this.paginationModal.page,
      page_size: this.paginationModal.page_size,
      num_pages: num_pages,
      order_by: this.paginationModal.order_by,
      desc: this.paginationModal.desc,
      data: data
    };
  }

  public getDocumentsOfConsolidated() {
    this.paginationModal = this.paginationService.getPagination('modal');
    this.paginationModal.order_by = 'documentid';
    // TODO: deep copy object quick and dirty solution
    // const data = JSON.parse(JSON.stringify(this.global.getDataModelDataPagination()))
    // All data {....} {}
    const data = JSON.parse(JSON.stringify(this.modalData));

    let originalData = JSON.parse(JSON.stringify(data));

    let dataToShowIni =
      this.paginationModal.page * data.page_size - data.page_size;
    let dataToShowFin = this.paginationModal.page * data.page_size;

    data.data = data.data.slice(dataToShowIni, dataToShowFin);

    this.rowsModal = originalData;
    this.rowsModal['page'] = this.paginationModal.page;
    this.rowsModal['page_size'] = originalData.page_size;
    this.rowsModal['num_pages'] = originalData.num_pages;

    this.rowsModal['order_by'] = this.paginationModal.order_by;
    this.rowsModal['desc'] = this.paginationModal.desc;

    this.paginationService.setPage('modal', data);
    this.paginationModal = this.paginationService.setPagination('modal', {
      total_elements: data.data.length,
      page: this.rowsModal['page'],
      page_size: this.rowsModal['page_size'],
      num_pages: this.rowsModal['num_pages'],
      order_by: this.rowsModal['order_by'],
      desc: this.rowsModal['desc'],
      query: this.paginationModal.query
    });
    this.paginationModal = this.paginationService.getPagination('modal');
  }
}
