/**
 * Get light theme PAMS theme.
 */
export function getLightThemePamsTheme(): any {
  return {
    "name": "PAMS theme",
    "dataColors": [
      "#01B8AA", "#2DB757", "#D9B300", "#5F6B6D", "#374649",
      "#8AD4EB", "#E6E6E6", "#FD625E", "#F2C80F", "#BF714D",
      "#FF4136", "#CCCCCC", "#922B73", "#FF810A", "#3498DB",
      "#FFE600", "#5DADE2"
    ],
    "firstLevelElements": "#7F8C8D",
    "secondLevelElements": "#7F8C8D",
    "thirdLevelElements": "#7F8C8D",
    "fourthLevelElements": "#7F8C8D",
    "foreground": "#3A6108",
    "tableAccent": "#568410",
    "textClasses": {
      "callout": {
        "fontSize": 42, "fontFace": "DIN", "color": "#7F8C8D"
      },
      "title": {
        "fontSize": 12, "fontFace": "DIN", "color": "#7F8C8D"
      },
      "header": {
        "fontSize": 12, "fontFace": "DIN", "color": "#7F8C8D"
      },
      "label": {
        "fontSize": 10, "fontFace": "DIN", "color": "#7F8C8D"
      }
    },
    "visualStyles": {
      "page": {
        "*":
        {
          "background": [{
            "color": {
              "solid": {
                "color": "#FFFFFF"
              }
            }, "transparency": 100
          }]
        }
      }
    }
  }
}
