import { Observable, Subject } from 'rxjs';

/**
 * Function options.
 */
export interface Options {
  /**
   * When getting pages of child document,
   * get pages from this property.
   */
  getPagesFromProperty?: string;

  /**
   * If true, selection string is updated.
   */
  updateSelectionString?: boolean;
}

/**
 * Selection status.
 */
export interface PagesSelectionStatus {
  ok: boolean;
  errorMessage?: string;
}

const getPagesProperty = (options: Options) =>
  options?.getPagesFromProperty ?? 'subpagesid';

interface PageRange {
  first: number;
  last: number;
}

/**
 * Class that encapsulates a selection of pages.
 */
export class PagesSelection {
  // error messages
  private static readonly ERROR_NO_SELECTION = 'common.requiredField';
  private static readonly ERROR_INVALID_PAGE_NUMBER =
    'updateDocs.errors.invalidPageNumber';
  private static readonly ERROR_PAGE_NUMBER_OUT_OF_RANGE =
    'updateDocs.errors.pageNumberOutOfRange';
  private static readonly ERROR_INVALID_FORMAT =
    'updateDocs.errors.invalidFormat';

  /**
   * Number of pages.
   */
  private _numPages: number;
  public isSaving: boolean;

  public get numPages(): number {
    return this._numPages;
  }

  public set numPages(value: number) {
    this._numPages = value;
    if (this.selectAllPages) {
      this.selectAll();
    } else {
      this.parseSelectionString();
    }
  }

  /**
   * Pages that can be selected.
   */
  private set_allowedPage: Set<number>;

  /**
   * Selection string.
   * Example: '3, 5-7, 10'
   */
  private _selectionString: string;

  public get selectionString(): string {
    return this._selectionString;
  }

  public set selectionString(value: string) {
    this.selectAllPages = false;
    this._selectionString = value;
    this.parseSelectionString();
  }

  /**
   * True if all pages have been selected.
   */
  private selectAllPages: boolean;

  /**
   * If true, all pages have been selected via individual selections.
   */
  private _allPagesInSelection: boolean;

  public get allPagesInSelection(): boolean {
    return this._allPagesInSelection;
  }

  /**
   * Emits when the selection string changes or all pages are selected (at once).
   */
  private selectionChangeSub = new Subject<void>();

  /**
   * Emits when the selection changes as a result of an individual selection.
   */
  private selectionUpdateSub = new Subject<void>();

  /**
   * Emits whether there was an error when selection changes.
   */
  private statusSub = new Subject<PagesSelectionStatus>();

  /**
   * If selectedPageNumbers[42] is set, page 42 is selected.
   * (the index of an element in the array
   * indicates the selected page number,
   * the values stored in the array are irrelevant)
   */
  private selectedPageNumbers: boolean[] = [];

  /**
   * Mapping between pages in selection
   * and pages in parent document.
   */
  private parentPageNumbers: number[];

  /**
   * Get observable of status.
   */
  public getStatus(): Observable<PagesSelectionStatus> {
    return this.statusSub.asObservable();
  }

  /**
   * Emit a status change.
   */
  private emitStatusChange(ok: boolean, errorMessage?: string): void {
    const status = ok
      ? { ok: true }
      : { ok: false, errorMessage: errorMessage };

    this.statusSub.next(status);
  }

  /**
   * Set which pages can be selected.
   */
  public setAllowedPages(pagesSelectionLimit: number[]): void {
    this.set_allowedPage = new Set<number>();
    pagesSelectionLimit.forEach((allowedPage: number) => {
      this.set_allowedPage.add(allowedPage);
    });
  }

  /**
   * Set mapping between pages in selection
   * and pages in parent document.
   */
  public setParentPages(parentPages: number[]): void {
    this.parentPageNumbers = [];
    parentPages?.forEach((parentPageNumber: number, i: number) => {
      const internalPageNumber = i + 1;
      this.parentPageNumbers[internalPageNumber] = parentPageNumber;
    });
  }

  /**
   * Select all pages.
   */
  public selectAll(options?: Options): void {
    this.selectAllPages = true;

    this.selectedPageNumbers = [];

    if (this.set_allowedPage) {
      this.set_allowedPage.forEach((allowedPage: number) => {
        this.selectedPageNumbers[allowedPage] = true;
      });
    } else {
      for (let i = 1; i <= this._numPages; ++i) {
        this.selectedPageNumbers[i] = true;
      }
    }

    this._allPagesInSelection = true;

    this.selectionChangeSub.next();

    if (options?.updateSelectionString) {
      this.updateSelectionString();
    }
  }

  /**
   * Parse selection string.
   *
   * Set array of selected pages.
   */
  private parseSelectionString(): void {
    this.selectedPageNumbers = [];

    try {
      if (!this._selectionString) {
        throw PagesSelection.ERROR_NO_SELECTION;
      } else {
        const parts = this._selectionString.split(',');

        parts.forEach((part: string) => {
          const segments = part.split('-');

          if (segments.length == 1) {
            const pageNumber = this.parseIntToken(segments[0]);
            this.checkValidPageNumber(pageNumber);

            this.selectedPageNumbers[pageNumber] = true;
          } else if (segments.length == 2) {
            const range: PageRange = {
              first: this.parseIntToken(segments[0]),
              last: this.parseIntToken(segments[1])
            };
            this.checkValidPageRange(range);

            for (let i = range.first; i <= range.last; ++i) {
              this.selectedPageNumbers[i] = true;
            }
          } else {
            throw PagesSelection.ERROR_INVALID_FORMAT;
          }
        });

        this.emitStatusChange(true);
      }
    } catch (errorMessage) {
      this.selectedPageNumbers = [];
      this.emitStatusChange(false, errorMessage);
    }

    this.selectionChangeSub.next();
  }

  /**
   * Parse a numeric token of the selection string.
   */
  private parseIntToken(segment: string): number {
    const numValue = parseInt(segment);
    return numValue ? numValue : undefined;
  }

  /**
   * Check if the page number can be part of the selection.
   *
   * Throws error message if not valid.
   */
  private checkValidPageNumber(pageNumber: number): void {
    if (!pageNumber) {
      throw PagesSelection.ERROR_INVALID_PAGE_NUMBER;
    }

    if (this.set_allowedPage) {
      if (!this.set_allowedPage.has(pageNumber)) {
        throw PagesSelection.ERROR_PAGE_NUMBER_OUT_OF_RANGE;
      }
    } else {
      if (pageNumber < 1 || pageNumber > this.numPages) {
        throw PagesSelection.ERROR_PAGE_NUMBER_OUT_OF_RANGE;
      }
    }
  }

  /**
   * Check if the range of page numbers can be part of the selection.
   *
   * Throws error message if not valid.
   */
  private checkValidPageRange(range: PageRange): void {
    const rangeFirst = range.first;
    const rangeLast = range.last;

    if (!rangeFirst || !rangeLast) {
      throw PagesSelection.ERROR_INVALID_PAGE_NUMBER;
    }

    if (rangeLast < rangeFirst) {
      throw PagesSelection.ERROR_INVALID_FORMAT;
    }

    if (this.set_allowedPage) {
      for (let i = rangeFirst; i < rangeLast; ++i) {
        if (!this.set_allowedPage.has(i)) {
          throw PagesSelection.ERROR_PAGE_NUMBER_OUT_OF_RANGE;
        }
      }
    } else {
      if (
        rangeFirst < 1 ||
        rangeFirst > this.numPages ||
        rangeLast > this.numPages
      ) {
        throw PagesSelection.ERROR_PAGE_NUMBER_OUT_OF_RANGE;
      }
    }
  }

  /**
   * Update selection string.
   */
  private updateSelectionString(): void {
    this._selectionString = PagesSelection.getSelectionString(
      this.selectedPageNumbers
    );

    if (this._selectionString) {
      this.emitStatusChange(true);
    } else {
      this.emitStatusChange(false, PagesSelection.ERROR_NO_SELECTION);
    }
    this.selectionUpdateSub.next();
  }

  /**
   * True if page number is included in selection string.
   */
  public isSelected(pageNumber: number): boolean {
    return Boolean(this.selectedPageNumbers[pageNumber]);
  }

  /**
   * Returns true if the specified page can be selected.
   */
  public canSelect(pageNumber: number): boolean {
    try {
      this.checkValidPageNumber(pageNumber);
      return true;
    } catch (_errorMessage) {
      return false;
    }
  }

  /**
   * Set whether a page is selected.
   */
  public setSelected(pageNumber: number, isPageSelected: boolean): void {
    if (isPageSelected) {
      try {
        this.checkValidPageNumber(pageNumber);
      } catch (_errorMessage) {
        return;
      }

      this.selectedPageNumbers[pageNumber] = true;
      this.checkAllPagesSelected();
    } else {
      if (!this.selectedPageNumbers[pageNumber]) {
        return;
      }
      delete this.selectedPageNumbers[pageNumber];
      this._allPagesInSelection = false;
      this.selectAllPages = false;
    }

    this.updateSelectionString();
  }

  /**
   * Check if all pages have been selected.
   */
  public checkAllPagesSelected(): void {
    if (this.set_allowedPage) {
      const allowedPagesIterator = this.set_allowedPage.values();
      checkAllowedPages: while (true) {
        const nextItem = allowedPagesIterator.next();
        if (nextItem.done) {
          break checkAllowedPages;
        }
        const allowedPage = nextItem.value;

        if (!this.selectedPageNumbers[allowedPage]) {
          this._allPagesInSelection = false;
          return;
        }
      }
    } else {
      for (let i = 1; i <= this._numPages; ++i) {
        if (!this.selectedPageNumbers[i]) {
          this._allPagesInSelection = false;
          return;
        }
      }
    }

    this._allPagesInSelection = true;
  }

  /**
   * Set selected pages.
   * @param selectedPages array of page numbers.
   */
  public setSelectedPages(selectedPages: number[]): void {
    this.selectedPageNumbers =
      PagesSelection.convertPagesArrayToPageNumbersArray(selectedPages);

    this.checkAllPagesSelected();

    this.updateSelectionString();
    this.selectionChangeSub.next();
  }

  /**
   * Get observable of selection changes.
   */
  public getSelectionChanges(): Observable<void> {
    return this.selectionChangeSub.asObservable();
  }

  /**
   * Get observable of selection updates.
   */
  public getSelectionUpdates(): Observable<void> {
    return this.selectionUpdateSub.asObservable();
  }

  /**
   * Return page selection as array of page numbers.
   *
   * If parent pages were set, return selected parent page numbers.
   */
  public toArray(zeroBasedIndex: boolean = false): number[] {
    const array = [];
    this.selectedPageNumbers.forEach((_e, internalPageNumber) => {
      const pageNumber = this.parentPageNumbers ?
        this.parentPageNumbers[internalPageNumber]
        : internalPageNumber;

      array.push(zeroBasedIndex ? pageNumber - 1 : pageNumber);
    });
    return array;
  }

  /**
   * Return complement of page selection as array of page numbers.
   *
   * If parent pages were set, return parent page numbers not selected.
   */
  public toNotSelectedArray(zeroBasedIndex: boolean = false): number[] {
    const array = [];
    for (let internalPageNumber = 1;
      internalPageNumber <= this._numPages;
      ++internalPageNumber
    ) {
      if (!this.selectedPageNumbers[internalPageNumber]) {
        const pageNumber = this.parentPageNumbers ?
          this.parentPageNumbers[internalPageNumber]
          : internalPageNumber;

        array.push(zeroBasedIndex ? pageNumber - 1 : pageNumber);
      }
    }
    return array;
  }

  /**
   * Convert array with page indices to selection string.
   */
  private static getSelectionString(pageNumbers: boolean[]): string {
    const parts = [];

    let previousSelectedPage: number;
    let firstIntervalPage: number;
    pageNumbers.forEach((_e, pageNumber: number) => {
      if (previousSelectedPage != pageNumber - 1) {
        this.pushSelectionStringIntervalParts(
          parts,
          firstIntervalPage,
          previousSelectedPage
        );

        firstIntervalPage = pageNumber;
      }
      previousSelectedPage = pageNumber;
    });
    this.pushSelectionStringIntervalParts(
      parts,
      firstIntervalPage,
      previousSelectedPage
    );

    return parts.join(', ');
  }

  /**
   * Add to array of selection string parts
   * an interval of pages.
   */
  private static pushSelectionStringIntervalParts(
    selectionStringParts: string[],
    firstIntervalPage: number,
    lastIntervalPage: number
  ): void {
    if (firstIntervalPage) {
      if (firstIntervalPage == lastIntervalPage) {
        selectionStringParts.push(String(firstIntervalPage));
      } else {
        selectionStringParts.push(`${firstIntervalPage}-${lastIntervalPage}`);
      }
    }
  }

  /**
   * Get selection string of parent document pages in child document.
   * @param children the child document.
   */
  public static getChildDocumentSelectionString(
    children: any,
    options?: Options
  ): string {
    const pages = PagesSelection.getChildDocumentPages(children, options);
    const pageNumbers =
      PagesSelection.convertPagesArrayToPageNumbersArray(pages);
    return PagesSelection.getSelectionString(pageNumbers);
  }

  /**
   * Get pages in child document.
   * @param children the child document.
   */
  public static getChildDocumentPages(
    children: any,
    options?: Options
  ): number[] {
    const pagesProperty = getPagesProperty(options);
    return PagesSelection.isChildDocumentMissingPagesData(children, options)
      ? []
      : children[pagesProperty].map(
        (subpage: any) => subpage.pageparentid.pagenumber + 1
      );
  }

  /**
   * If document is child document, get page numbers.
   * If not child document, return undefined.
   */
  public static getDocumentPageNumbers(
    document: any,
    options?: Options
  ): number[] {
    if (document.parentdocumentid) {
      return PagesSelection.getChildDocumentPages(document, options);
    } else {
      return undefined;
    }
  }

  /**
   * Returns true if child document is missing parent pages data.
   * @param children the child document.
   */
  public static isChildDocumentMissingPagesData(
    children: any,
    options?: Options
  ): boolean {
    const pagesProperty = getPagesProperty(options);
    return (
      !children[pagesProperty] ||
      children[pagesProperty].some((subpage: any) => !subpage.pageparentid)
    );
  }

  /**
   * Convert array with pages to array where indices are pages.
   */
  private static convertPagesArrayToPageNumbersArray(
    pages: number[],
    zeroBasedIndex: boolean = false
  ): boolean[] {
    const pageNumbers = [];

    pages.forEach((page: number) => {
      pageNumbers[zeroBasedIndex ? page + 1 : page] = true;
    });

    return pageNumbers;
  }

  /**
   * Convert array with pages to selection string.
   */
  public static convertPagesArrayToString(
    pages: number[],
    zeroBasedIndex: boolean = false
  ): string {
    const pageNumbers =
      PagesSelection.convertPagesArrayToPageNumbersArray(
        pages,
        zeroBasedIndex
      );

    return PagesSelection.getSelectionString(pageNumbers);
  }
}
