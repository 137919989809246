import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../security/authentication.service';
import { LayoutService } from '../../../services/layout.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public user: any;
  public showValidationsLink: boolean = false;
  public showDashboardLink: boolean = false;
  public showTimeTrackingLink: boolean = false;
  public showCognitiveLink: boolean = false;
  public showOutputFilesLink: boolean = false;
  public showClientDocumentsLink: boolean = false;
  public showPrivacyNote: boolean = false;
  public showCheckList: boolean = false;
  public showQuerysheetManagement: boolean = false;
  

  public _userPermissions: any;

  constructor(
    private authService: AuthenticationService,
    private layoutService: LayoutService,
    private global: GlobalService
  ) {}

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.showValidationsLink = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.showPrivacyNote = this.authService.userCanViewModule(
      this.authService.getLoggedInUser(),
      'PrivacyNoteFunctionality'
    );
    this.showDashboardLink = this.authService.userCanViewModule(
      this.user,
      'ChartsDashboard'
    );
    this.showTimeTrackingLink = this.authService.userCanViewModule(
      this.user,
      'TimeTrackingCoreFunctionality'
    );
    this.showCognitiveLink = this.authService.userCanViewModule(
      this.user,
      'CognitiveSearchCoreFunctionality'
    );
    this.showOutputFilesLink = this.authService.userCanViewModule(
      this.user,
      'OutputFilesFunctionality'
    );
    this.showClientDocumentsLink = this.authService.userCanViewModule(
      this.user,
      'ClientDocumentsFunctionality'
    );
    this.showQuerysheetManagement = this.authService.userCanViewModule(
      this.user,
      'QuerysheetManagement'
    );
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  closeMenus() {
    this.layoutService.closeMenus();
  }

  /**
   * Check permissions depends on the privacy note
   */
  getPrivacyNotePermission() {
    if (!this.showPrivacyNote) {
      return true;
    } else if (this.showPrivacyNote && this.user.privacyNoteAccepted == false) {
      return false;
    } else if (this.showPrivacyNote && this.user.privacyNoteAccepted == true) {
      return true;
    }
  }

  /**
   * Returns sidebar tenant path
   */
  getPath(item: string) {
    const tenantName = this.authService.getTenantName();
    return `/${tenantName}/${item}`;
  }
}
