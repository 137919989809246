import { getScaleX, isWithinCursors, debounce, createDestroyAnswerErrorHighlight } from '../utils/util';

export const addDragableEventOnCursor = (params) => {
    setTimeout(() => {
        const dre = document.querySelectorAll(".draggable")
        dre && dre.forEach((item) => {
            item.addEventListener("mousemove", (e) => {
                const o = new Draggable(item, params);
            })
        })
    }, 100)
};

const changeHighlight = (params) => {
    let { currentFeedback, updateFeedbackBeforeValidatinErr, feedbackInAction, createSelectionArea, 
        setCurrentFeedback, highlightAiAnswer, metaDataOfPopup, selectedCellPosition, disableSaveBtn, 
        updateIsLevel1InsideLevel2SelectionFlag, level1_1, level2_1 } = params;
    const svg = document.querySelector('.drawingPlane');
    const group = document.getElementsByClassName("svgGroup")[0];
    // Remove only the rects belonging to the currently edited feedback semantic/answer
    const activeLevel = feedbackInAction == 'level2_1' ? level2_1 : level1_1;
    removeHighlightRects(activeLevel);

    const { startCursor, endCursor } = currentFeedback[activeLevel.modelKey].boundingBox;
    const spansBetweenCursors = findSpansBetweenCursors(metaDataOfPopup.allWordSpans, startCursor, endCursor);
    const { text = '', startIndex, endIndex, lineNo } = createSelectionArea(group, svg, spansBetweenCursors);
    disableSaveBtn = !text?.trim()?.length;

    // Need to save the answer start index on unformatted data as well in case of ai box feedback where we save context as well
    // (startIndex and endIndex we save in bounding box are on the formatted text), saving it in manualStartIndex attribute
    let manualAnswerStartIndex = -1;
    if (feedbackInAction == 'level1_1') {
        const stringBeforeStart = metaDataOfPopup.text.substr(0, startIndex);
        const lineTextArr = stringBeforeStart.split('\n');
        let spacesAtStartAndEndOfLines = 0;
        lineTextArr.forEach((line, index) => {
            const lineText = line.replace('  ', ' ');
            if (index == lineNo) {
                spacesAtStartAndEndOfLines += lineText.length - lineText.trimStart().length;
            } else {
                spacesAtStartAndEndOfLines += lineText.length - lineText.trim().length;
            }
        });
        spacesAtStartAndEndOfLines += stringBeforeStart.length - stringBeforeStart.replace('  ', ' ').length;

        // If level 1 feedback changes, then startIndex for level 2 feedback also gets changed, updating that here
        // Checking if there is level 2 present and if yes check if feedback alredy selected
        if (level2_1 && currentFeedback?.[level2_1?.modelKey]?.text?.length) {
            const spacesAtStartAndEndOfLines = text.length - text.replace('  ', ' ').length;
            const level2ManualAnswerStartIndex = currentFeedback[level2_1.modelKey]?.boundingBox.startIndex - startIndex - spacesAtStartAndEndOfLines;
            setCurrentFeedback({ boundingBox: { ...currentFeedback[level2_1.modelKey].boundingBox, manualAnswerStartIndex: level2ManualAnswerStartIndex }}, false, true);
        }
    } else {
        const spacesAtStartAndEndOfLines = currentFeedback?.[level1_1.modelKey]?.text.length - currentFeedback?.[level1_1.modelKey]?.text.replace('  ', ' ').length;
        // In case of AI box feedback, we won't have level1 text, so by default subtracting 0n from them
        manualAnswerStartIndex = startIndex - (currentFeedback?.[level1_1.modelKey]?.boundingBox.startIndex || 0) - (spacesAtStartAndEndOfLines || 0);
    }

    addDragableEventOnCursor(params);
    const updateCurrentBox: any = {
        boundingBox: {
          startIndex,
          endIndex,
          startCursor,
          endCursor,
          position: selectedCellPosition
        },
        text
    }

    // Adding manualAnswerStartIndex only for level2_1 feedback
    feedbackInAction == 'level2_1' && (updateCurrentBox.boundingBox.manualAnswerStartIndex = manualAnswerStartIndex);

    setCurrentFeedback(updateCurrentBox);

    // If ai-answer(purple) highlight present and user moves the cursors remove ai-answer highlight
    if (metaDataOfPopup?.aiAnswerSpans?.length) {
        // Remove ai answer (yellow) highlight if present
        highlightAiAnswer(group, svg, true);
    }

    const validateLevel1NotInsidelevel2Selection = level1_1.validateLevel1NotIntoLevel2 && checkIfLevel1InsideLevel2Selection(currentFeedback, level1_1, level2_1);
    const feedbackBeforeValidationErr = JSON.parse(JSON.stringify(currentFeedback));
    updateIsLevel1InsideLevel2SelectionFlag(validateLevel1NotInsidelevel2Selection, feedbackBeforeValidationErr);
    /* if (validateLevel1NotInsidelevel2Selection) {
        // Creating red border around the answer selection areas
        group && group.querySelectorAll(`[highlightType="QnA"`).forEach((highlightedRect) => {
            createDestroyAnswerErrorHighlight(highlightedRect, true);
        });
    } else {
        // Removing red border around the answer selection areas
        group && group.querySelectorAll(`[highlightType="QnA"`).forEach((highlightedRect) => {
            createDestroyAnswerErrorHighlight(highlightedRect, false);
        });
    } */
}

const debounceFn = debounce(changeHighlight);
export function Draggable(elem, params) {
    this.target = elem;
    const fontSize = 12;
    const actualParent = elem.parentElement;

    if (!elem || !elem.parentElement || !elem.ownerSVGElement){
        return
    }

    this.clickPoint = this.target?.ownerSVGElement?.createSVGPoint()
    this.lastMove = this.target?.ownerSVGElement?.createSVGPoint()
    this.currentMove = this.target?.ownerSVGElement?.createSVGPoint()

    this.target.addEventListener("mousedown", this)
    this.handleEvent = function (evt) {
        evt.preventDefault()
        if (!elem.ownerSVGElement){
            return false;
        }
        this.clickPoint = { x: evt.clientX, y: evt.clientY };
        this.target.classList.add("dragged")
        this.target.setAttribute("pointer-events", "none")
        this.target.ownerSVGElement.addEventListener("mousemove", this.move)
        this.target.ownerSVGElement.addEventListener("mouseup", this.endMove)
    }

    this.move = function (evt) {
        const p = { x: evt.clientX, y: evt.clientY };
        const hasTextClass = evt.target.classList.contains('selectionArea');
        const hasTextClassDeselected = evt.target.classList.contains('unselectionArea');
        const selectedCursorType = this.target.getAttribute("class").includes("startCursor") ? "start" : this.target.getAttribute("class").includes("endCursor") ? "end" : null;
        const startCursor = actualParent.querySelector(".startCursorLine");
        const endCursor = actualParent.querySelector(".endCursorLine");
        const startCursorBottomCircle = actualParent.querySelector(".startCursorBottomCircle")
        const endCursorBottomCircle = actualParent.querySelector(".endCursorBottomCircle")
        const startCursorPositon = { x: parseInt(startCursor.getAttribute("x1")), y: parseInt(startCursor.getAttribute("y1")) };
        const endCursorPositon = { x: parseInt(endCursor.getAttribute("x1")), y: parseInt(endCursor.getAttribute("y1")) };
        let evtCursorYposition = parseInt(evt.target.getAttribute("y")) || p.y;

        // Validate if answer feedback is within semantic feedback (for answer feedback within semantic checking if its not a aibox and feedbackinAction is qna)
        const validateLevel2NotInsideLevel1 = params.feedbackInAction == 'level2_1' && params.level2_1 && params.currentFeedback?.[params.level1_1.modelKey];
        const level1StartCursorPosition = validateLevel2NotInsideLevel1 ? params.currentFeedback?.[params.level1_1.modelKey].boundingBox.startCursor : null;
        const level1EndCursorPosition = validateLevel2NotInsideLevel1 ? params.currentFeedback?.[params.level1_1.modelKey].boundingBox.endCursor : null;

        let conditionForCursorCross = true;
        //let isLevel2CursorsOutOfLevel1 = validateLevel2NotInsideLevel1;

        if (selectedCursorType === "start") {
            if (Math.abs(evtCursorYposition - endCursorPositon.y) <= 1) {
                evtCursorYposition = endCursorPositon.y;
            }
            conditionForCursorCross = !((evtCursorYposition < endCursorPositon.y) || (evtCursorYposition == endCursorPositon.y && p.x <= endCursorPositon.x));

            if (validateLevel2NotInsideLevel1) {
                if (Math.abs(evtCursorYposition - level1StartCursorPosition.y1) <= 1) {
                    evtCursorYposition = level1StartCursorPosition.y1;
                }
                //isLevel2CursorsOutOfLevel1 = !((evtCursorYposition > level1StartCursorPosition.y1) || (evtCursorYposition == level1StartCursorPosition.y1 && p.x >= level1StartCursorPosition.x1));
            }
        } else if (selectedCursorType == "end") {
            if (Math.abs(evtCursorYposition - startCursorPositon.y) <= 1) {
                evtCursorYposition = startCursorPositon.y;
            }
            conditionForCursorCross = !(((evtCursorYposition > startCursorPositon.y)
                                            || (evtCursorYposition == startCursorPositon.y && p.x >= startCursorPositon.x))
                                            // Added condition to restrict cursor movement beyond last text span
                                            && (p.x - 2 <= parseInt(evt.target.getAttribute("x")) + parseInt(evt.target.getAttribute("width"))));

            if (validateLevel2NotInsideLevel1) {
                if (Math.abs(evtCursorYposition - level1EndCursorPosition.y1) <= 1) {
                    evtCursorYposition = level1EndCursorPosition.y1;
                }
                //isLevel2CursorsOutOfLevel1 = !((evtCursorYposition < level1EndCursorPosition.y1) || (evtCursorYposition == level1EndCursorPosition.y1 && p.x <= level1EndCursorPosition.x1));
            }
        }

        if (!conditionForCursorCross && /* !isLevel2CursorsOutOfLevel1 && */ (hasTextClass || hasTextClassDeselected)) {
            const y1 = evtCursorYposition;
            const y2 = parseInt(evtCursorYposition) + fontSize;

            if (selectedCursorType === "start") {
                startCursor.setAttribute("x1", p.x)
                startCursor.setAttribute("x2", p.x)
                startCursor.setAttribute("y1", y1)
                startCursor.setAttribute("y2", parseInt(y2.toString()) + fontSize / 2)

                startCursorBottomCircle.setAttributeNS(null, 'x', p.x - fontSize + 1);
                startCursorBottomCircle.setAttributeNS(null, 'y', y2);
                startCursorBottomCircle.setAttributeNS(null, 'width', fontSize);
                startCursorBottomCircle.setAttributeNS(null, 'height', fontSize);
                startCursorBottomCircle.setAttributeNS(null, 'rx', fontSize / 2);
            } else if (selectedCursorType === "end") {
                endCursor.setAttribute("x1", p.x)
                endCursor.setAttribute("x2", p.x)
                endCursor.setAttribute("y1", y1)
                endCursor.setAttribute("y2", parseInt(y2.toString()) + fontSize / 2)

                endCursorBottomCircle.setAttributeNS(null, 'x', p.x - 1);
                endCursorBottomCircle.setAttributeNS(null, 'y', y2);
                endCursorBottomCircle.setAttributeNS(null, 'width', fontSize);
                endCursorBottomCircle.setAttributeNS(null, 'height', fontSize);
                endCursorBottomCircle.setAttributeNS(null, 'rx', fontSize / 2);
            }

            this.target.setAttributeNS(null, "x", p.x - (selectedCursorType === "start" ? fontSize - 1 : 1))
            this.target.setAttributeNS(null, "y", evtCursorYposition)
            this.target.setAttributeNS(null, "width", fontSize)
            this.target.setAttributeNS(null, "height", fontSize * 2)
            this.target.setAttribute("spanYCoordinate", y1 || p.y || evt.target.getAttribute("y"))

            const activeLevel = params.feedbackInAction == 'level2_1' ? params.level2_1 : params.level1_1;
            const updateCurrentBox = {
                boundingBox: {
                    ...params.currentFeedback[activeLevel.modelKey].boundingBox,
                    startCursor: {
                        'x1': startCursor.getAttribute("x1"),
                        'y1': startCursor.getAttribute("y1"),
                        'x2': startCursor.getAttribute("x2"),
                        'y2': startCursor.getAttribute("y2") - fontSize / 2
                    },
                    endCursor: {
                        'x1': endCursor.getAttribute("x1"),
                        'y1': endCursor.getAttribute("y1"),
                        'x2': endCursor.getAttribute("x2"),
                        'y2': endCursor.getAttribute("y2") - fontSize / 2
                    }
                }
            }
            params.setCurrentFeedback(updateCurrentBox);
            debounceFn(params);
        }
    }.bind(this)

    this.endMove = function () {
        this.lastMove.x = this.currentMove.x
        this.lastMove.y = this.currentMove.y
        this.target.classList.remove("dragged")
        this.target.setAttribute("pointer-events", "all")
        this.target.removeEventListener("mousemove", this.move)
        this.target.removeEventListener("mouseup", this.endMove)
        this.target.ownerSVGElement?.removeEventListener("mousemove", this.move)
        this.target.ownerSVGElement?.removeEventListener("mouseup", this.endMove)
    }.bind(this)
}

const findSpansBetweenCursors = (elements, start, end) => {
    const selectedElements = []
    const startX = parseFloat(start.x1)
    const startY = parseFloat(start.y1)
    const endX = parseFloat(end.x1)
    const endY = parseFloat(end.y1)
    elements.forEach(element => {
      const elHeight = element.offsetHeight || element.getAttribute('offsetHeight')
      const elWidth = (element.offsetWidth || element.getAttribute('offsetWidth')) * getScaleX(element)
      const x1 = element && (element.offsetLeft || element.getAttribute('offsetLeft'))
      const y1 = element && (element.offsetTop || element.getAttribute('offsetTop'))
      const x2 = x1 + elWidth // element bottom-right coordinates
      const y2 = y1 + elHeight // element bottom-right coordinates

      if (element && isWithinCursors([startX, startY, endX, endY], [x1, y1, x2, y2])) {
        selectedElements.push(element);
      } else if (element && element.tagName.toLowerCase() !== 'span') {
        console.log('Unable to parse element - ', element)
      }
    })

    // TO DO Check if we need  this, else remove
    const validSpans = [];
    for (let i = 0; i < selectedElements.length; i++) {
      const el = selectedElements[i];
      const scaleXValue = getScaleX(el);
      const x1 = el.offsetLeft;
      const y1 = el.offsetTop;
      const x2 = x1 + el.offsetWidth * scaleXValue;
      if (Math.abs(start.y - y1) <= 3 * 1/* scale */){
        // to manage first line where we have start cursor
        if (start.x < x2){
          validSpans.push(el);
        }
      } else if (Math.abs(end.y - y1) <= 3 * 1/* scale */){
        // to manage last line where we have end cursor
        if (x1 < end.x){
          validSpans.push(el);
        }
      } else {
        validSpans.push(el);
      }
    }
    return validSpans;
}

export const removeHighlightRects = (feedbackInAction, removeHighlightType = '') => {
    // Function to remove highlighted rects
    const group = document.getElementsByClassName("svgGroup")[0];
    const feedbackTypeInAction = feedbackInAction?.highlightType;
    group && (removeHighlightType || feedbackTypeInAction) && group.querySelectorAll(`[highlightType="${removeHighlightType || feedbackTypeInAction}"]`).forEach((highlightedRect) => highlightedRect.remove());
}

// Checking if the level1 feedback going inside level2 feedback
const checkIfLevel1InsideLevel2Selection = (currentFeedback, level1_1, level2_1) => {
    const { startIndex = null, endIndex = null } = currentFeedback[level1_1.modelKey]?.boundingBox || {};
    const { startIndex: level2StartIndex = null, endIndex: level2EndIndex = null } = currentFeedback[level2_1.modelKey]?.boundingBox || {};

    if ((startIndex || endIndex) && (level2StartIndex || level2EndIndex)) {
        return !(level2StartIndex >= startIndex && level2EndIndex <= endIndex);
    }
    return false;
}
