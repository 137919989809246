import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DisabilityInformation } from 'src/app/models/document-dashboard.model';
import { Reason } from 'src/app/models/close-reason.model';
import {
  DiagnosisData,
  PanelData,
  RestrictionsLimitationsData,
} from './disability-panel-data';

@Component({
  selector: 'app-disability-panel',
  templateUrl: './disability-panel.component.html',
  styleUrls: ['./disability-panel.component.scss'],
})
export class DisabilityPanelComponent
  implements OnInit, AfterViewInit {

  @Input() dashboardData: DisabilityInformation;
  @Input() contentType: string;
  @Input() contentTab: string | number;

  closeReason = Reason;
  modalTitle: string;
  data: PanelData;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.initContent();
  }

  ngAfterViewInit(): void {
  }

  /**
   * Initialize content.
   */
  private initContent(): void {
    switch (this.contentType) {
      case 'restrictionsLimitations':
        this.modalTitle = 'Restrictions and Limitations';
        this.data = new RestrictionsLimitationsData(this.dashboardData);
        break;

      case 'diagnosis':
        this.modalTitle = 'Diagnosis Information';
        this.data = new DiagnosisData(this.dashboardData);
        break;
    }
  }

}
