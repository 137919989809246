import { UserModel } from './user-mstr.model';

export class ValidationModel {
  public validationid: number;
  public validationname: string;
  public isactive: boolean;
  public respontableIsShowed: boolean;
  public respontableIsSelected: boolean | null;
  public validationdescription: string | null;
  public validationtypeid: number | null;
  public validationautoexecute: boolean | null;
  public validationcontent: string | null;
  public createddate: string | null;
  public createdby: number | null;
  public createdbyuser: UserModel | null;
  public modifieddate: string | null;
  public modifiedbyuser: UserModel | null;
  public datamodelid: number | null;
  public version: number | null;
  public lastversion: boolean | null;
  public validationfields: Number[];
  public unique: Boolean;
  public unique_fields: String[];

  constructor(
    validationid: number,
    validationname: string,
    respontableIsShowed: boolean,
    respontableIsSelected: boolean,
    isactive?: boolean,
    validationdescription?: string | null,
    validationtypeid?: number | null,
    validationautoexecute?: boolean | null,
    validationcontent?: string | null,
    createddate?: string | null,
    createdby?: number | null,
    datamodelid?: number | null,
    version?: number | null,
    lastversion?: boolean | null,
    createdbyuser?: UserModel | null,
    modifieddate?: string | null,
    modifiedby?: UserModel | null,
    validationfields?: Number[],
    unique?: Boolean,
    unique_fields?: String[]
  ) {
    this.validationid = validationid;
    this.validationname = validationname;
    this.validationdescription = validationdescription;
    this.validationtypeid = validationtypeid;
    this.validationautoexecute = validationautoexecute;
    this.validationcontent = validationcontent;
    this.datamodelid = datamodelid;
    this.version = version;
    this.lastversion = lastversion;
    this.createddate = createddate;
    this.createdby = createdby;
    this.createdbyuser = createdbyuser;
    this.modifieddate = modifieddate;
    this.modifiedbyuser = modifiedby;
    this.isactive = isactive;
    this.respontableIsShowed = false;
    this.respontableIsSelected = false;
    this.validationfields = validationfields;
    this.unique = unique;
    this.unique_fields = unique_fields;
  }
}
