import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateQuerysheetComponent } from '../../../components/modals/create-querysheet/create-querysheet.component';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import * as querysheetConstants from '../../../constants/querysheetConstants';
import { ApiService } from '../../../services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { AlertService } from 'src/app/services/alert.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-querysheet-info-card',
  templateUrl: './querysheet-info-card.component.html',
  styleUrls: ['./querysheet-info-card.component.scss']
})
export class QuerysheetInfoCardComponent implements OnInit {
  @Output() fetchQueryTreeDetails = new EventEmitter();
  @Output() deleteQuerySheet = new EventEmitter();
  @Output() setIsLoadingFlag = new EventEmitter();
  @Input() querysheetDetails;
  @Input() user;
  @Input() enablePublishAfterEdit: boolean = true;
  public querysheetStatus = querysheetConstants;
  public readMoreEnabled: boolean = false;
  public publishClicked: boolean = false;
  public tenantName: any;
  public _userPermissions: any;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private translate: TranslatePipe,
    private fileDownloadService: FileDownloadService,
    private authService: AuthenticationService,
    public alertService: AlertService,
    public globalService: GlobalService,
    private router: Router,
  ) {
    this.tenantName = this.authService.getTenantName();
  }

  ngOnInit(): void {
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  editQuerySheet() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      CreateQuerysheetComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      querysheet: this.querysheetDetails,
      title: this.translate.transform('editQuerysheet.title'),
      action: 'edit',
    };

    modalWindowRef.result.then(result => {
      if (result) {
        // Fetch updated query sheet details
        this.fetchQueryTreeDetails.emit();
      }
    });
  }

  importQueries() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      CreateQuerysheetComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      querysheet: this.querysheetDetails,
      title: this.translate.transform('queryDetails.importQueries'),
      action: 'import'
    };

    modalWindowRef.result.then(result => {
      if (result) {
        if (this.querysheetDetails?.isPublished) {
          // And also publish the updated querysheet automatically
          this.publishQuerySheet();
        } else {
          // Fetch updated query sheet details
          this.fetchQueryTreeDetails.emit();
        }
      }
    });
  }

  exportQueries() {
    // TO DO: Change the api once ready
    const url = `querysheets/${this.querysheetDetails.querySheetId}/export/`;
    const file = {
      mediatype:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };

    this.fileDownloadService.downloadExternalFile(
      url,
      file,
      this.querysheetDetails?.querySheetName
    );
  }

   /**
   * Publish multiple querysheet
   */
   public publishQuerySheet(): void {
    this.setIsLoadingFlag.emit(true);
    const url = `querysheets/bulk_edit_publish_status`;
    const data = {
      querySheetIds: [this.querysheetDetails.querySheetId],
      is_published: true
    }
    this.publishClicked = true
    this.apiService.post(url, data, '')
          .subscribe(
            (response: any) => {
              this.fetchQueryTreeDetails.emit();
              this.alertService.success(
                this.translate.transform(
                  'querysheetDetails.progressToast'
                ),
                true
              );
              this.publishClicked = false;
              this.setIsLoadingFlag.emit(false);
              //set currentTab in global to queySheetList
              this.globalService.setCurrentTab('querysheetList');
              //Navigate to Query Sheet List screen
              this.router.navigate([
                `${this.tenantName}/querysheet-management`
              ]);
            },
            (error: any) => {
              this.publishClicked = false;
              this.setIsLoadingFlag.emit(false);
            }
          );
  }

  handleDeleteQuerySheet() {
    this.deleteQuerySheet.emit();
  }
}
