import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KPIModel } from 'src/app/models/kpi.model';

/**
 * KPIs Component
 */
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KPIComponent implements OnChanges {
  @Input('kpiInfo') kpiInfo: KPIModel;
  @Input() isDocumentKPI: boolean;
  public spinnerStyles = 'la-ball-circus';
  public statusData;
  public statusDataConfig;

  constructor() {
    this.statusDataConfig = {
      key: 'name',
      value: 'value',
      height: 48,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      innerRadius: 0.5,
      colorKeys: {
        Pending: '#d2d040',
        Failure: '#d65b5b',
        Success: '#44b865',
        'In Progress': '#0eacb8',
        Warning: '#ee9958',
        'Partial Success': '#b6b844'
      },
      hasLabels: false
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.kpiInfo.currentValue) return;
    this.statusData = changes.kpiInfo.currentValue.charts.status;
  }
}
