import { UserModel } from './user-mstr.model';

export class DocumentModel {
  public documentid: number;
  public documentname: string;
  public documentdisplayname: string;
  public rundate: string;
  public mediatype: string | null;
  public tenantid: number;
  public createdby: number;
  public createddate: string;
  public createdbyuser: UserModel;
  public modifiedbyuser: UserModel;
  public clientdocumentid: string | null;
  public modifiedby: number;
  public modifieddate: string;
  public isactive: boolean;
  public isparentdocument: boolean | null;
  public parentdocumentid: number | null;
  public parentdocumentname: string | null;
  public url: string;
  public datamodel: {
    datamodelid: number;
    datamodeldisplayname: string;
  } | null;
  public status: string;
  public statusmessage: string | null;
  public splitteddocumentlist: any[];
  public project: {
    projectid: number;
    projectname: string;
    url: string;
  };
  public analysis: {
    analysisid: number;
    analysisname: string;
    clienttransactionid: string;
    url: string;
  };
  public classificationconfidencelevel: number | null;
  public confidencelevelaverage: number | null;
  public language: {
    langid: number;
    langname: string;
    langcode: string;
  };
  public numpages: number | null;
  public emailcontent: string | null;

  constructor(
    documentid: number,
    documentname: string,
    documentdisplayname: string,
    rundate: string,
    tenantid: number,
    createdby: number,
    createddate: string,
    createdbyuser: UserModel,
    modifiedbyuser: UserModel,
    clientdocumentid: string | null,
    modifiedby: number,
    modifieddate: string,
    isparentdocument: boolean | null,
    parentdocumentid: number | null,
    parentdocumentname: string | null,
    statusmessage: string | null,
    datamodel: any,
    status: string,
    splitteddocumentlist: any[],
    project: any,
    analysis: any,
    language: any,
    classificationconfidencelevel: number | null,
    confidencelevelaverage: number | null,
    mediatype: string | null,
    numpages: number | null,
    emailcontent: string | null,
    isactive: boolean,
    url: string
  ) {
    this.documentid = documentid;
    this.documentname = documentname;
    this.documentdisplayname = documentdisplayname;
    this.rundate = rundate;
    this.tenantid = tenantid;
    this.createdby = createdby;
    this.createddate = createddate;
    this.createdbyuser = createdbyuser;
    this.modifiedbyuser = modifiedbyuser;
    this.clientdocumentid = clientdocumentid;
    this.modifiedby = modifiedby;
    this.modifieddate = modifieddate;
    this.isparentdocument = isparentdocument;
    this.parentdocumentid = parentdocumentid;
    this.parentdocumentname = parentdocumentname;
    this.statusmessage = statusmessage;
    this.datamodel = datamodel;
    this.status = status;
    this.splitteddocumentlist = splitteddocumentlist;
    this.project = project;
    this.analysis = analysis;
    this.language = language;
    this.classificationconfidencelevel = classificationconfidencelevel;
    this.confidencelevelaverage = confidencelevelaverage;
    this.mediatype = mediatype;
    this.numpages = numpages;
    this.emailcontent = emailcontent;
    this.isactive = isactive;
    this.url = url;
  }
}
