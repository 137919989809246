import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { arc, pie } from 'd3-shape';
import { sum } from 'd3-array';
import * as d3scaleChromatic from 'd3-scale-chromatic';
const d3 = {
  select,
  selectAll,
  scaleOrdinal,
  arc,
  pie,
  sum,
  ...d3scaleChromatic
};

/**
 * Piechar Component
 */
@Component({
  selector: 'app-d3piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3PieChartComponent implements OnChanges {
  @Input() private data: Array<any>; // Must be an objects array
  @Input() private config: any = {}; // Custom configuration object
  @Input() public title: string; // Header title
  @Output() onclick = new EventEmitter();
  private cfg: any = {}; // Default configuration object
  private selection;
  private svg;
  private colorScale;
  private arc;
  private outerArc;
  private pie;
  private slices;
  private loaded: boolean = false;
  private totalValue: number;
  private selectedKey: string;

  constructor(private elementRef: ElementRef) {
    this.selection = d3.select(elementRef.nativeElement);
    this.cfg = {
      width: 700, // Default width
      height: 300, // Default height
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      key: 'name',
      value: 'value',
      colorScheme: 'schemeCategory10', // More shemes in https://github.com/d3/d3-scale-chromatic
      colorKeys: {},
      colorUnselect: 'rgba(111, 111, 111, 0.82)',
      radius: false,
      innerRadius: 0,
      hasLabels: true,
      labelPadding: 0.1
    };
  }

  ngOnChanges() {
    if (this.data) {
      this.setConfiguration(this.config);
      this.computeData();
      this.drawChart();
    }
  }

  /**
   * Overwrite default configuration with custom configuration
   */
  private setConfiguration(config) {
    Object.keys(config).forEach(key => {
      if (
        config[key] instanceof Object &&
        config[key] instanceof Array === false
      ) {
        // Nested value
        Object.keys(config[key]).forEach(sk => {
          this.cfg[key][sk] = config[key][sk];
        });
      } else this.cfg[key] = config[key];
    });

    // Get target DOM element size
    // Using D3's margins convention, see https://bl.ocks.org/mbostock/3019563 for more info
    let bounds = this.selection
      .select('.chart__wrap--piechart')
      .node()
      .getBoundingClientRect();
    this.cfg.width =
      parseInt(bounds.width) - this.cfg.margin.left - this.cfg.margin.right;
    this.cfg.height =
      this.cfg.height - this.cfg.margin.top - this.cfg.margin.bottom;
  }

  /**
   * Calcule scales, maximum values and other stuff to draw the chart
   */
  private computeData() {
    if (this.cfg.colorScheme instanceof Array === true) {
      this.colorScale = d3.scaleOrdinal().range(this.cfg.colorScheme);
    } else {
      this.colorScale = d3.scaleOrdinal(d3[this.cfg.colorScheme]);
    }

    this.cfg.radius = this.cfg.radius
      ? this.cfg.radius
      : Math.min(this.cfg.width, this.cfg.height) / 2;

    this.cfg.innerRadius =
      this.cfg.innerRadius > 0 && this.cfg.innerRadius < 1
        ? this.cfg.radius * this.cfg.innerRadius
        : this.cfg.innerRadius;

    this.arc = d3
      .arc()
      .outerRadius(this.cfg.radius)
      .innerRadius(this.cfg.innerRadius);

    this.pie = d3
      .pie()
      .sort(null)
      .value(d => d[this.cfg.value]);

    if (this.cfg.hasLabels) {
      this.outerArc = d3
        .arc()
        .outerRadius(this.cfg.radius * (1 + this.cfg.labelPadding))
        .innerRadius(this.cfg.radius * (1 + this.cfg.labelPadding));
    }

    this.totalValue = d3.sum(this.data, d => d[this.cfg.value]);
  }

  /**
   * Draw the chart
   */
  private drawChart() {
    // Remove previus chart if exists
    if (this.loaded) this.selection.selectAll('svg').remove();
    this.selectedKey = undefined;
    if (!this.totalValue) return;

    // SVG container
    this.svg = this.selection
      .select('.chart__wrap')
      .append('svg')
      .attr('class', 'chart chart--piechart')
      .classed('chart--clickable', this.onclick.observers.length > 0)
      .attr(
        'viewBox',
        '0 0 ' +
          (this.cfg.width + this.cfg.margin.left + this.cfg.margin.right) +
          ' ' +
          (this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr(
        'width',
        this.cfg.width + this.cfg.margin.left + this.cfg.margin.right
      )
      .attr(
        'height',
        this.cfg.height + this.cfg.margin.top + this.cfg.margin.bottom
      )
      .style('width', '100%');

    // General group wrapper with margins convention
    let g = this.svg
      .append('g')
      .attr('class', 'char__margin-wrap')
      .attr(
        'transform',
        `translate(${this.cfg.margin.left},${this.cfg.margin.top})`
      );

    // Helper group to center chart
    let centerg = g
      .selectAll('.chart__slice-group')
      .data(this.pie(this.data))
      .enter()
      .append('g')
      .attr('class', 'chart__slice-group')
      .attr(
        'transform',
        `translate(${this.cfg.width / 2},${this.cfg.height / 2})`
      );

    // Tooltip
    this.selection.selectAll('.chart__tooltip').remove();
    let tooltip = this.selection
      .select('.chart__wrap')
      .append('div')
      .attr('class', 'chart__tooltip chart__tooltip--piechart');

    // Slices
    this.slices = centerg
      .append('path')
      .attr('class', 'chart__slice chart__slice--piechart')
      .attr('fill', d => this.sliceColor(d))
      .attr('d', this.arc)
      .on('mouseover', (d, i) => {
        tooltip
          .html(() => {
            let percent = Math.round(
              (i.data[this.cfg.value] * 100) / this.totalValue
            );
            return `
            <div>${i.data[this.cfg.key]}: ${
              i.data[this.cfg.value]
            } (${percent}%)</div>
          `;
          })
          .classed('active', true);
      })
      .on('mouseout', d => {
        tooltip.classed('active', false);
      })
      .on('mousemove', d => {
        tooltip
          .style('left', window.event['pageX'] - 100 + 'px')
          .style('top', window.event['pageY'] - 76 + 'px');
      })
      .on('click', (d, i) => this.elementClick(i.data));

    // Labels
    if (this.cfg.hasLabels) {
      let axisg = centerg
        .append('g')
        .attr('class', 'chart__axis chart__axis--piechart');

      axisg
        .append('polyline')
        .attr('class', 'chart__axis-line chart__axis-line--piechart')
        .attr('points', d => {
          var pos = this.outerArc.centroid(d);
          pos[0] =
            this.cfg.radius *
            (1 - this.cfg.labelPadding) *
            (this.midAngle(d) < Math.PI
              ? 1 + this.cfg.labelPadding
              : -1 * (1 + this.cfg.labelPadding));
          return [this.arc.centroid(d), this.outerArc.centroid(d), pos];
        });

      axisg
        .append('text')
        .attr('class', 'chart__axis-text chart__axis-text--piechart')
        .attr('transform', d => {
          var pos = this.outerArc.centroid(d);
          pos[0] =
            this.cfg.radius *
            (this.midAngle(d) < Math.PI
              ? 1 + this.cfg.labelPadding
              : -1 * (1 + this.cfg.labelPadding));
          return 'translate(' + pos + ')';
        })
        .attr('text-anchor', d =>
          this.midAngle(d) < Math.PI ? 'start' : 'end'
        )
        .text(d => d.data[this.cfg.key])
        .on('click', (d, i) => this.elementClick(i.data));
    }
    this.loaded = true;
  }

  /**
   * Compute slice color
   */
  private sliceColor(d) {
    if (
      this.cfg.colorKeys &&
      this.cfg.colorKeys.hasOwnProperty(d.data[this.cfg.key])
    ) {
      return this.cfg.colorKeys[d.data[this.cfg.key]];
    } else {
      return this.colorScale(d.data[this.cfg.key]);
    }
  }

  /**
   * Get angle bisection
   */
  private midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  /**
   * onclick event handler
   */
  private elementClick(d: any) {
    // If there isn't an onclick listener on the component, don't do anything
    if (this.onclick.observers.length == 0) return;

    if (d[this.cfg.key] != this.selectedKey) {
      // Change slices color
      this.slices
        .attr('fill', this.cfg.colorUnselect)
        .filter(j => j.data[this.cfg.key] == d[this.cfg.key])
        .attr('fill', d => this.sliceColor(d));

      // Store selected value
      this.selectedKey = d[this.cfg.key];
    } else {
      // Change slices color
      this.slices.attr('fill', d => this.sliceColor(d));

      // Remove stored value
      this.selectedKey = undefined;
    }

    // Emit event
    this.onclick.emit(d);
  }
}
