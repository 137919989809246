import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app//services/global.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-cache-list',
  templateUrl: './cache-list.component.html',
  styleUrls: ['./cache-list.component.scss']
})
export class CacheListComponent implements OnInit {
  public user: any;
  public loading: boolean;
  public loading_data: boolean;
  public loading_data_cache: boolean;
  private pagination: PaginationModel;
  public settingTable: any;
  public columns: any[];
  @Output() sentColumnsCache = new EventEmitter<any[]>();
  @Output() sentTenants = new EventEmitter<any []>();
  public rows: {};
  public json_data: any;
  public active_key = false;
  public nameTenants = [];
  selectedValue: any;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private globalService: GlobalService,
    private paginationService: PaginationService,
    private router: Router,
    private link: LinkService,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) {
    this.user = authService.getLoggedInUser();
    this.getColumns();
    this.getSettingTable();
  }


  ngOnInit() {
    this.pagination = this.paginationService.getDefaultPagination(
      'queues_cache'
    );
    this.getCacheKeys({});
    this.json_data = null;
    this.loading_data = true;
    this.sendColunmsToParent(this.columns);
    for (let user of this.user.tenants)
    { 
      this.nameTenants.push({nameOfTenant: user.tenantname.toUpperCase(), idOfTenant: user.tenantid})
    }
    this.sendTenantsToParent(this.nameTenants);
  }

  sendColunmsToParent(columns) {
    this.sentColumnsCache.emit(columns);
  }

  sendTenantsToParent(nameTenants) {
    this.sentTenants.emit(nameTenants)
  }

  public clearTenantCache(){

    this.loading = true;
    const params = {
      tenantid: this.selectedValue
    };

   
    this.apiService
    .get('admin/cleancache/', params, '')
    .subscribe(
      data => {
        this.loading = false;
        this.alertService.success(
          this.translate.transform(
            'queues.deletedCache'
          ),
          false
        );
      },
      error => {
        this.loading = false;
        this.alertService.error(
          this.translate.transform(
            'queues.undeletedCache'
          ),
          false
        );
      }
    );
  }

  /**
   * Get documents from database
   */
  public getCacheKeys(filter) {
    this.loading = true;
    let params = {
      env: environment.env
    };

    if (Object.keys(filter).length != 0)
    {
      params = {...filter, ...params};
    }
    this.apiService.get('admin/environment/cache/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: [];
      }) => {
       
        this.rows = data;
        this.paginationService.setPage('queues_cache', data);
        this.paginationService.setPagination('queues_cache', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.loading = false;
        let stopcondition = false;

        for ( let i = 0; i < data.data.length; i++ )
        {
          stopcondition = false;
          for (let j = 0; j < this.nameTenants.length; j++ && !stopcondition)
          {
            if (this.rows["data"][i].client.toUpperCase() ==
            this.nameTenants[j].nameOfTenant.toUpperCase() ||
            this.rows["data"][i].client.toUpperCase() + ' (INACTIVE)' ==
            this.nameTenants[j].nameOfTenant.toUpperCase())
            {
              stopcondition = true;
            }
          }
          if (!stopcondition)
          {
            this.nameTenants.push({
              nameOfTenant: this.rows["data"][i].client.toUpperCase() + ' (inactive)',
              idOfTenant: 0
            });
          }
        }
      },
      () => {
        this.loading = false;
      }
    );
    
  }

  private getKey(data) {
    this.active_key = data['key'];
    this.loading_data = true;
    const params = {
      env: environment.env,
      key: this.active_key
    };
    this.apiService.get('admin/environment/cache/1/', params, '').subscribe(
      (data: any) => {
        this.json_data = data;
        this.loading_data = false;
      },
      () => {
        this.loading_data = false;
      }
    );
   
  }
 
  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'Cropped Key',
        name: 'original_key',
        type: 'link',
        clickLink: this.getKey.bind(this),
        orderBy: 'env'
      },
      {
        header: 'Client',
        name: 'client',
        class: 'small-size-column'
      }
    ];

    this.sendColunmsToParent(this.columns);
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      getDataFromDB: this.getCacheKeys.bind(this),
      responsiveTitle: {
        label: 'env'
      }
    };
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }  
}
