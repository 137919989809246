import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from 'src/app/models/close-reason.model';
import { ValidationTypeModel } from 'src/app/models/validation-type-mstr.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-add-comment-validation',
  templateUrl: './add-comment-validation.component.html',
  styleUrls: ['./add-comment-validation.component.scss']
})
export class AddCommentValidationComponent implements OnInit {
  @Input() options;
  public reason = Reason;
  public errorsmappingSelect: any;
  newCommentForm: FormGroup;
  public documentId: number;

  constructor(
    public activeModal: NgbActiveModal,
    public global: GlobalService,
    private alertService: AlertService,
    private apiService: ApiService,
    private translate: TranslatePipe
  ) {}

  ngOnInit() {
    this.newCommentForm = new FormGroup({
      textComment: new FormControl(this.options.data.textComment, [
        Validators.required
      ]),
      select: new FormControl(this.options.data.select, [Validators.required]),
      amount: new FormControl(this.options.data.amount, [Validators.required])
    });
    this.documentId = this.options.data.documentId;

    this.errorsmappingSelect = this.global.getErrorsMapping();
    const errorsmappinglist = this.global.getErrorsMapping;
  }

  public onCreateComment(form) {
    const params = {
      amount: form.value.amount,
      errorid: form.value.select,
      message: form.value.textComment,
      documentid: this.documentId
    };
    this.activeModal.close(params);
  }

  public onBlur(evt, form) {
    if (evt.target.valueAsNumber) {
      form.amount = evt.target.valueAsNumber.toFixed(2);
    }
  }
}
