import { Component, OnInit, Input } from '@angular/core';
import { Reason } from 'src/app/models/close-reason.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public body: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.body = this.options.body;
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }
}
