import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { ValidationsDocumentListComponent } from './validations-document-list/validations-document-list.component';

@Component({
  selector: 'app-validations-review',
  templateUrl: './validations-review.component.html',
  styleUrls: ['./validations-review.component.scss']
})
export class ValidationsReviewComponent implements OnInit {
  @ViewChild(ValidationsDocumentListComponent)
  private assignedTable: ValidationsDocumentListComponent;

  public currentTab: string;

  constructor(
    private authService: AuthenticationService,
    private global: GlobalService
  ) {
    authService.checkAccess('ValidationsCoreFunctionality');
  }

  ngOnInit() {}

  public onSelfAssigned(doc) {
    this.assignedTable.selfAssign();
  }
}
