import { Component, OnInit, Input } from '@angular/core';
import { Reason } from 'src/app/models/close-reason.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  selector: 'app-assign-reviewer',
  templateUrl: './assign-reviewer.component.html',
  styleUrls: ['./assign-reviewer.component.scss']
})
export class AssignReviewerComponent implements OnInit {
  @Input() options;
  public reason = Reason;
  public user: any;
  public assignedUserFromTable: any;
  public allowAssign: boolean = false;
  public params: {};

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService
  ) {
    this.user = authService.getLoggedInUser();
    this.allowAssign = this.allowAssignUsers();
  }

  ngOnInit() {
    this.params = { projectsids: this.options.projectsids };
  }

  public onAssignUser() {
    const params = {
      assigneduser: this.allowAssign
        ? this.assignedUserFromTable.userid
        : this.user.userid
    };
    this.activeModal.close(params);
  }

  public assignFromTable(user) {
    this.assignedUserFromTable = user;
  }

  public allowAssignUsers() {
    return this.user && this.user.isadmin;
  }
}
