import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'src/app/services/api.service';

export class DocumentsCount {
  valiPending: number;
  completed: number;
}

@Injectable({
  providedIn: 'root'
})
export class ValidationSearchService {
  public filtersAssigned: [];
  public filtersUnassigned: [];
  private hasBeenSearchedAssigned: boolean = false;
  private hasBeenSearchedUnassigned: boolean = false;

  constructor(private api: ApiService) { }

  public getPreviousSearch(paginationType: string) {
    return paginationType === 'documentvalidations'
      ? this.filtersUnassigned
      : this.filtersAssigned;
  }

  public setPreviousSearch(
    newSearchFilter: any,
    paginationType: string
  ) {
    return paginationType === 'documentvalidations'
      ? (this.filtersUnassigned = newSearchFilter)
      : (this.filtersAssigned = newSearchFilter);
  }

  public getHasBeenSearched(paginationType: string) {
    return paginationType === 'documentvalidations'
      ? this.hasBeenSearchedUnassigned
      : this.hasBeenSearchedAssigned;
  }

  public setHasBeenSearched(
    value: any,
    paginationType: string
  ) {
    return paginationType === 'documentvalidations'
      ? (this.hasBeenSearchedUnassigned = value)
      : (this.hasBeenSearchedAssigned = value);
  }

  public cleanFilters() {
    this.filtersAssigned = [];
    this.filtersUnassigned = [];
    this.hasBeenSearchedAssigned = false;
    this.hasBeenSearchedUnassigned = false;
  }

  /**
   * Get documents count: validation pending, completed, ...
   */
  public getDocumentsCount(): Observable<DocumentsCount> {
    return this.api.get(
      'documents/',
      {
        count: true
      },
      ''
    );
  }
}
