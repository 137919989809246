import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-numeric-filter',
  templateUrl: './numeric-filter.component.html',
  styleUrls: ['./numeric-filter.component.scss']
})
export class NumericFilterComponent implements OnInit {
  @Input() public form: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.form.markAllAsTouched();
  }
}
