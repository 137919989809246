import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AssignReviewerComponent } from 'src/app/components/modals/assign-reviewer/assign-reviewer.component';
import { ApiService } from 'src/app/services/api.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  selector: 'app-document-reviewer',
  templateUrl: './document-reviewer.component.html',
  styleUrls: ['./document-reviewer.component.scss']
})
export class DocumentReviewerComponent implements OnInit {
  @Input() documentDetails: any;

  public showValidations: boolean = false;
  public assignedTo: string;
  public reviewStage: string;
  public approvalid: number;
  public approvals: any[];
  public isLoading: boolean = false;
  public currentApproval: any = {};
  private user: any = {};

  // Reviewer group
  public reviewersGroupsTypes: any[];
  public subscriptionReviewers: Subscription;
  public _userPermissions: any;

  constructor(
    private global: GlobalService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private globalService: GlobalService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
  }

  ngOnInit(): void {
    this.reviewersGroupsTypes = this.global.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
        this.reviewersGroupsTypes = this.global.getReviewers();
        this.initReviewersInfo();
      });
    } else {
      this.initReviewersInfo();
    }
  }

  initReviewersInfo() {
    if (this.documentDetails.approvals) {
      this.approvals = this.documentDetails.approvals;
      this.approvals.forEach(ap => {
        if (ap.currentapproval === true) {
          this.currentApproval = ap;
          this.approvalid = this.currentApproval.approvalid;
        }
        if (this.reviewersGroupsTypes.find(g => g.groupid === ap.groupid)) {
          ap['reviewStage'] = this.reviewersGroupsTypes.find(
            g => g.groupid === ap.groupid
          ).groupname;
        }
        ap['assignedTo'] = ap.assigned
          ? ap.assigned.username
          : this.translate.transform(
              'validation.validationToReview.pendingToAssign'
            );
      });
    }
  }

  public launchAssignToModal() {
    this.assignToReviewers(this.documentDetails.documentid);
  }

  /**
   * Load modal to assign a user for review the selected validations
   */
  public assignToReviewers(documentsid: number) {
    // Get approvals ids
    let approvalids = [this.approvalid];
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
      windowClass: 'modal--large'
    };
    const options = {
      type: 'new',
      action: 'create'
    };
    const modalWindowRef = this.modalService.open(
      AssignReviewerComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(assigneduser => {
      if (assigneduser) {
        this.isLoading = true;
        this.apiService
          .post(
            'documentreviewapproval/assignto',
            { approvalids, userid: assigneduser.assigneduser },
            'validation.validationToReview.assigned'
          )
          .subscribe(
            data => {
              this.global.emptyDocumentDetails();
              const current = this.approvals.find(
                a => a.currentapproval === true
              );
              current['assignedTo'] = data;
              this.isLoading = false;
            },
            () => {}
          );
      }
    });
  }

  /**
   * Check if user can assign approval
   */
  public userCanAssignApproval() {
    return (
      (this.user.isadmin && this.currentApproval.approvalid) ||
      (this.currentApproval.approvalid &&
        !this.currentApproval.assigned &&
        !this.currentApproval.recommended &&
        (this.reviewersGroupsTypes.findIndex(
          r => r.groupid === this.currentApproval.groupid
        ) !== -1 ||
          this.user.isadmin))
    );
  }

  /**
   * Check if assign to button needs to be disable
   */
  public checkAssignState() {
    const docstatus = this.documentDetails.status_data.class;
    const docStatusList = this.global.getDocumentStatusConst();
    if (
      docstatus == docStatusList.SUCCESS ||
      docstatus == docStatusList.EXTRACTION_FAILURE ||
      docstatus == docStatusList.BR_FAILURE ||
      docstatus == docStatusList.PRELIMINARY_FAILURE ||
      docstatus == docStatusList.CATALOG_FAILURE
    ) {
      return true;
    } else {
      return false;
    }
  }
}
