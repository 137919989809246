import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Reason } from '../../../models/close-reason.model';
import { ApiService } from '../../../services/api.service';
import { PaginationService } from '../../../services/pagination.service';
import { PaginationModel } from '../../../models/pagination';
import { QuerySheetModel } from 'src/app/models/querysheet-mstr.model';
import { LinkService } from 'src/app/services/link.service';
import { Subscription, concat, Subject } from 'rxjs';

@Component({
  selector: 'app-analysis-failed-documents-modal',
  templateUrl: './analysis-failed-documents-modal.component.html',
  styleUrls: ['./analysis-failed-documents-modal.component.scss']
})
export class AnalysisFailedDocumentsModalComponent implements OnInit {
  @Input() options;
  @Input() setting;
  @Input() selectedProjects = [];

  public querysheetDetails: QuerySheetModel = null;
  public tenantName: any;
  public reason = Reason;
  public loading: boolean = false;
  public pagination: PaginationModel;
  public getDataFromDBSettings: Object;
  public columns: Array<any> = [];
  public tableSettings: any;
  public data: any;
  public analysisFilterFlag: boolean = false;
  public backgroundLoading: boolean = false;
  public setAnalysisName: string = "" || undefined;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private paginationService: PaginationService,
    private linkService: LinkService) { }

  ngOnInit(): void {
    this.pagination = this.paginationService.getDefaultPagination('documentstatus');
    this.getAnalysisFailedDocuments(null);
    this.getTableSettings();
    this.getColumns();
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'explorationAnalysisStatus.analysisName',
        title: 'explorationAnalysisStatus.analysisName',
        orderBy: 'analysisname',
        type: 'custom',
        namefunc: (rowData: any) => {
          return `<span class="ellipsis-text" title="${rowData.analysisname}">${rowData.analysisname}  </span>`;
        }

      },
      {
        header: 'explorationAnalysisStatus.projectName',
        title: 'explorationAnalysisStatus.projectName',
        type: 'custom',
        namefunc: (rowData: any) => {
          return `${rowData.project.projectname}`;
        }
      },
      {
        header: 'explorationAnalysisStatus.FailedAndDocuments',
        title: 'explorationAnalysisStatus.FailedAndDocuments',
        type: 'custom',
        namefunc: (rowData: any) => {
          return `<span class="error-text">${rowData.faileddocumentcount}  </span> / <span> ${rowData.numberofdocuments}</span>`;
        }
      },
      {
        header: ' ',
        name: 'numberofdocuments',
        type: 'link-fa-icon',
        link: 'external',
        title: 'responsiveTable.newTab',
        class: 'td-icon',
        clickLink: this.goToAnalysisNewTab.bind(this),
        namefunc: rowData => {
          return '<i class="fa fa-external-link"></i>';
        }
      }
    ];
  }


  /**
   * Go to the document with the document information pass by params
   * and open link in new tab
   */
  public goToAnalysisNewTab(document) {
    this.linkService.goToAnalysis(document, true);
  }


  public getTableSettings(): void {
    this.tableSettings = {
      dataId: 'documentstatus',
      hasSelect: 'false',
      hasEditPermissions: false,
      hasDownloadOptions: false,
      getDataFromDB: this.getAnalysisFailedDocuments.bind(this),
      hasRowSelectOptions: false,
      hidePagination: false,
      responsiveTitle: {
        label: 'documentstatus'
      }
    };
  }

  closeModal(): void {
    this.activeModal.close(this.reason.closeBtn);
  }

  /**
   * Fetching data from DB for Analysis list Failed Documents
   */
  public getAnalysisFailedDocuments(analysisName) {
    this.loading = true;
    let params = {};
    const url = 'analysis/documentstatus/';
    const setAnalysisNameData = analysisName || this.setAnalysisName || '';

    /* based on analysis name and pagination and user is on last page (ex -3rd page) and clicks on search button with analsys name it will trigger default page 1 */
    if ((setAnalysisNameData || this.pagination.page >= 1) && this.pagination.page === this.data?.page) {
      this.pagination = this.paginationService.getDefaultPagination('documentstatus');
    }

    /* this will trigger default on load and page event only */
    params = {
      ...this.pagination,
      explorationId: this.options.data.explorationId,
      documentStatus: 'failure',
      analysisname: setAnalysisNameData
    };

    this.apiService.get(url, params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
      }) => {
        this.pagination = this.paginationService.setPagination('documentstatus', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: ''
        });

        this.data = data;
        this.backgroundLoading = false;
        this.analysisFilterFlag = true;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  // Modal Popup Analysis search and fetch  data from the API for failed documents 
  public onSearch(filters) {
    this.backgroundLoading = true;
    this.setAnalysisName = filters['failedAnalysisName'] || '';
    this.getAnalysisFailedDocuments(filters['failedAnalysisName']);
  }

}
