import { environment } from "src/environments/environment";
import { MsalCustomGuard } from "./msal-custom.guard";
import { SiteminderCustomGuard } from "./siteminder-custom.guard";
import { IbmCustomGuard } from "./ibm-custom.guard.ts.guard";

export function getGuard(): any {
  switch(environment.authType) {
    case 'msal':
      return MsalCustomGuard;
    case 'siteminder':
      return SiteminderCustomGuard;
    case 'ibm':
      return IbmCustomGuard;
  }
}
