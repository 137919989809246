import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { ExtractionService } from '../../../extraction-components/extraction.service';
import { ValidationService } from '../../../common-components/validation.service';
import { BusinessService } from '../../../business-components/business.service';
import { PreliminaryService } from '../../../preliminary-components/preliminary.service';
import { CatalogService } from '../../../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-pre-condition',
  templateUrl: './pre-condition.component.html',
  styleUrls: ['./pre-condition.component.scss']
})
export class PreConditionComponent implements OnInit {
  @Input('precondition') preCondition: FormGroup;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  @Input('index') index: number;
  @Input('datamodels') datamodels: Array<any>;
  public groupsoffields: Array<any> = [];
  public datamodelsList: Array<any> = [];
  public datamodelValue;
  public fieldsOfInterest: Array<any> = [];

  constructor(
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    if (this.validationService.isType(['Catalog'])) {
      let datamodelid = this.preCondition.get('0');
      this.datamodelValue = datamodelid.get('datamodelid').value
        ? datamodelid.get('datamodelid').value
        : undefined;
    }
    if (this.validationService.isType(['Business Rules'])) {
      this.groupsoffields = this.validationService.getGroupOfFields();
    }
    if (this.validationService.isType(['Preliminary'])) {
      this.groupsoffields = this.getFieldsOfInterestOrderDocument();
    }

    const fieldOfInterest = this.preCondition.get('0');
    fieldOfInterest.get('fieldofinterestid').valueChanges.subscribe(value => {
      const gof = this.validationService.searchGroupOfFieldFromFieldOfInterest(
        +value,
        this.groupsoffields
      );
      fieldOfInterest.get('groupoffieldsid').setValue(gof);
    });
    this.datamodelsList = this.datamodel_list.map(d => ({
      id: d.id
    }));
  }

  /**
   * Description: gets fields of interest of a datamodel
   */
  public getFieldsOfInterestOrderDocument() {
    const datamodelid = this.datamodels.filter(
      datamodel => datamodel.datamodeltrigger === true
    )[0].id;

    if (datamodelid) {
      const dataDataModel = this.globalService.getDataModelById(+datamodelid);
      return dataDataModel ? dataDataModel.groupoffields : [];
    } else {
      return [];
    }
  }

  public changeDatamodelValue(value) {
    if (this.datamodelValue) {
      this.groupsoffields =
        this.globalService.getDataModelById(value).groupoffields;
    }
  }

  /**
   * Check if form group has a control by its name
   * @param controlName FormControl name
   */
  public hasControl(controlName: string) {
    return this.validationService.hasKey(
      this.preCondition.get('2'),
      controlName
    );
  }

  /**
   * Check if form control has a exact value
   * @param controlName FormControl name
   * @param value value to compare
   */
  public controlHasValue(controlName: string, value: any) {
    return (
      this.hasControl(controlName) &&
      this.preCondition.get('2').get(controlName).value === value
    );
  }

  public getFormBlockClass() {
    return this.validationService.isType(['Business Rules'])
      ? 'dataval__fourow dataval__fourow--nogrow'
      : this.validationService.isType(['Preliminary'])
      ? this.index > 0
        ? 'dataval__fifrow dataval__fifrow--nogrow'
        : 'dataval__fourow dataval__fourow--nogrow'
      : 'dataval__fifrow dataval__fifrow--nogrow';
  }

  public getDatamodelName(id: number) {
    return this.datamodels.filter(datamodel => datamodel.datamodelid === id)[0]
      .datamodeldisplayname;
  }
}
