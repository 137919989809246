import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from '../../../models/close-reason.model';

@Component({
  selector: '',
  templateUrl: './unsaved-changes.component.html'
})
export class UnsavedChanges implements OnInit {
  @Input() options;
  public reason: Reason;
  public body: string;
  public option: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.option = '';
    this.body = '';
  }

  onConfirm(reason: Reason) {
    this.activeModal.close(reason);
  }
}
