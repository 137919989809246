import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../validation.service';
import { CatalogService } from '../../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-operation-field',
  templateUrl: './operation-field.component.html',
  styleUrls: ['./operation-field.component.scss']
})
export class OperationFieldComponent implements OnInit {
  @Input('control') control: FormGroup;
  @Input('scope') scope: FormGroup;
  @Input('datamodelLinks') datamodelLinks?: FormGroup;
  @Input('group') group: string;
  @Input('ter') ter: boolean;
  public operatorGroup: string;
  public fieldTypes: Array<any> = [];
  private datamodels: Array<any> = [];
  private groupsoffields: Array<any> = [];

  constructor(
    public validationService: ValidationService,
    private catalogService: CatalogService,
    private translate: TranslatePipe,
    private global: GlobalService
  ) {}

  ngOnInit() {
    this.operatorGroup = `${+this.group + 1}`;
    this.fieldTypes = this.getFieldTypes();
    this.groupsoffields = this.getGroupsOfFields();
    this.datamodels = this.getDatamodels();

    this.control
      .get(this.group)
      .get('type')
      .valueChanges.subscribe(value => {
        this.onSelectType(
          this.control.get(this.group),
          this.control.get(this.operatorGroup),
          value
        );
      });

    this.scope.valueChanges.subscribe(value => {
      this.groupsoffields = this.getGroupsOfFields();
    });

    if (this.control.get(this.operatorGroup).get('operator')) {
      this.control
        .get(this.operatorGroup)
        .get('operator')
        .valueChanges.subscribe(value => {
          this.onSelectOperator(value);
        });
    }

    if (this.control['controls'][this.group].get('fieldofinterestid')) {
      this.control['controls'][this.group]
        .get('fieldofinterestid')
        .valueChanges.subscribe(value => {
          const gofId =
            this.validationService.searchGroupOfFieldFromFieldOfInterest(
              +value,
              this.getGroupsOfFields()
            );
          this.control['controls'][this.group]
            .get('groupoffieldsid')
            .setValue(gofId ? gofId : '');
        });
    }
    if (this.control['controls'][this.group].get('aggscope')) {
      this.control['controls'][this.group]
        .get('aggscope')
        .valueChanges.subscribe(value => {
          this.onSelectAggScope(this.control['controls'][this.group], value);
        });
    }
  }

  public getFormBlockClass() {
    const control = this.control.get(this.group).get('type');
    return control && control.value !== 'datamodelid'
      ? 'dataval__thirow dataval__thirow--nogrow'
      : this.validationService.isType(['Business Rules'])
      ? 'dataval__fourow dataval__fourow--nogrow'
      : 'dataval__fifrow dataval__fifrow--nogrow';
  }

  /**
   * Handle operator change to add or delete next form groups
   * @param value operator value
   */
  public onSelectOperator(value) {
    if (value === 'end') {
      for (const group in this.control['controls']) {
        if (+group > +this.operatorGroup) {
          this.control.removeControl(group);
        }
      }
    } else {
      const nextGroup = `${+this.operatorGroup + 1}`;
      if (!this.control.get(nextGroup)) {
        this.control.addControl(
          nextGroup,
          new FormGroup({
            type: new FormControl('', Validators.required)
          })
        );
        this.control.addControl(
          `${+nextGroup + 1}`,
          new FormGroup({
            type: new FormControl('operator', Validators.required),
            operator: new FormControl('', Validators.required)
          })
        );
      }
    }
  }

  /**
   * Handle data type change to add or delete form groups
   * @param control current FormGroup
   * @param operatorControl operator FormGroup
   * @param value data type selected value
   */
  public onSelectType(control, operatorControl, value: string) {
    if (['datamodelid', 'fieldofinterestid'].includes(value)) {
      control.addControl(
        'groupoffieldsid',
        new FormControl('', Validators.required)
      );
      control.addControl(
        'fieldofinterestid',
        new FormControl('', Validators.required)
      );
      control.get('fieldofinterestid').valueChanges.subscribe(value => {
        const gofId =
          this.validationService.searchGroupOfFieldFromFieldOfInterest(
            +value,
            this.getGroupsOfFields()
          );
        control.get('groupoffieldsid').setValue(gofId ? gofId : '');
      });
      if (this.validationService.isType(['Catalog'])) {
        if (!control.get('aggscope')) {
          control.addControl(
            'aggscope',
            new FormControl('', Validators.required)
          );
          control.get('aggscope').valueChanges.subscribe(value => {
            this.onSelectAggScope(control, value);
          });
        }
        if (control.get('aggscope').value === 'na') {
          if (control.get('agg')) control.removeControl('agg');
        } else if (!control.get('agg')) {
          control.addControl('agg', new FormControl('', Validators.required));
        }
      }
      if (this.validationService.isType(['Business Rules'])) {
        control.addControl('agg', new FormControl('', Validators.required));
      }
      if (value === 'datamodelid') {
        control.addControl(
          'datamodelid',
          new FormControl('', Validators.required)
        );
        control.get('datamodelid').valueChanges.subscribe(value => {
          this.groupsoffields = this.getGroupsOfFieldsByDatamodel(+value);
        });
      }
      if (control.get('value')) {
        control.removeControl('value');
        control.removeControl('datatype');
      }
    }
    if (value === 'value') {
      control.addControl('value', new FormControl('', Validators.required));
      control.addControl('datatype', new FormControl('', Validators.required));
      if (control.get('fieldofinterestid')) {
        control.removeControl('fieldofinterestid');
        if (this.hasControl('groupoffieldsid'))
          control.removeControl('groupoffieldsid');
        if (this.hasControl('aggscope')) control.removeControl('aggscope');
        if (this.hasControl('agg')) control.removeControl('agg');
      }
      if (control.get('datamodelid')) {
        control.removeControl('datamodelid');
      }
    }
    if (!operatorControl.get('operator')) {
      operatorControl.addControl(
        'operator',
        new FormControl('', Validators.required)
      );
      operatorControl.get('operator').valueChanges.subscribe(value => {
        this.onSelectOperator(value);
      });
    }
  }

  /**
   * Handle aggregation scope change to add or delete controls
   * @param control current FormGroup
   * @param value aggregation scope selected value
   */
  public onSelectAggScope(control, value) {
    if (value === 'na' && control.get('agg')) {
      control.removeControl('agg');
    }
    if (value !== 'na' && !control.get('agg')) {
      control.addControl('agg', new FormControl('', Validators.required));
    }
  }

  /**
   * Return field types depending on validation type
   */
  private getFieldTypes() {
    if (this.validationService.isType(['Business Rules'])) {
      return [
        {
          value: 'fieldofinterestid',
          name: this.translate.transform('validation.fieldOfInterest')
        },
        { value: 'value', name: this.translate.transform('validation.value') }
      ];
    }
    if (this.validationService.isType(['Catalog'])) {
      return [
        {
          value: 'datamodelid',
          name: this.translate.transform('validation.datamodel')
        },
        { value: 'value', name: this.translate.transform('validation.value') }
      ];
    }
  }

  /**
   * Return datamodels list
   */
  private getDatamodels() {
    if (!this.validationService.isType(['Catalog'])) {
      return [];
    }
    return this.catalogService.getCatalogDatamodels();
  }

  /**
   * Return group of fields
   */
  private getGroupsOfFields() {
    if (this.validationService.isType(['Business Rules'])) {
      return this.validationService.getGroupOfFields();
    } else if (
      this.validationService.isType(['Catalog']) &&
      this.controlIsSet('datamodelid')
    ) {
      return this.getGroupsOfFieldsByDatamodel(
        +this.control.get(this.group).get('datamodelid').value
      );
    }
    return [];
  }

  /**
   * Return group of fields filtered by datamodel
   * @param datamodelid datamodel ID
   */
  public getGroupsOfFieldsByDatamodel(datamodelid: number) {
    const datamodel = this.global.getDataModelById(+datamodelid);
    return datamodel ? datamodel.groupoffields : [];
  }

  /**
   * Check if the field of interest from the current group is table type
   */
  public fieldOfInterestIsTableType() {
    const gofId = this.control.get(this.group).get('groupoffieldsid').value;
    const gof = this.groupsoffields.find(gof => gof.groupoffieldsid === +gofId);
    return gofId && gof ? gof['groupoffieldstypeid'].toString() == '2' : null;
  }

  /**
   * Check if form group has a control by its name
   * @param controlName FormControl name
   */
  public hasControl(controlName: string) {
    return this.validationService.hasKey(
      this.control.get(this.group),
      controlName
    );
  }

  /**
   * Check if form control has a exact value
   * @param controlName FormControl name
   * @param value value to compare
   */
  public controlHasValue(controlName: string, value: any) {
    return (
      this.hasControl(controlName) &&
      this.control.get(this.group).get(controlName).value === value
    );
  }

  /**
   * Check if form control has any value
   * @param controlName FormControl name
   */
  public controlIsSet(controlName: string) {
    return (
      this.hasControl(controlName) &&
      this.control.get(this.group).get(controlName).value
    );
  }

  /**
   * Check if agg form control has to be hidden
   */
  public aggregationIsActive() {
    return (
      this.controlIsSet('fieldofinterestid') &&
      this.control.get(this.group).get('agg') &&
      ((this.validationService.isType(['Catalog']) &&
        this.controlIsSet('aggscope')) ||
        this.validationService.isType(['Business Rules']))
    );
  }

  /**
   * Check if operation form control has to be hidden
   */
  private operationIsHidden() {
    if (this.hasControl('value')) {
      return !this.controlIsSet('value');
    }
    if (
      this.validationService.isType(['Business Rules']) &&
      this.hasControl('agg')
    ) {
      return !this.controlIsSet('agg');
    }
    if (this.validationService.isType(['Catalog']) && this.hasControl('agg')) {
      return !(this.controlIsSet('aggscope') && this.controlIsSet('agg'));
    }
    return false;
  }
}
