// Roles codes
export const ID_ADMINISTRATOR = 1;
export const ID_PROJECT_MANAGER = 2;
export const ID_ANALYSIS_MANAGER = 3;
export const ID_DOCUMENT_MANAGER = 4;
export const ID_VIEWER = 5;
export const ID_EXTERNAL_VIEWER = 6;
/*ID_GROUP_BENEFIT_VIEWER Id was 7,  now its as changed to 12 
because in DB order 7 to 11 start for new added roles*/
export const ID_GROUP_BENEFIT_VIEWER = 12;
export const ID_TEAM_ADMIN = 7;
export const ID_EXPLORER_MEMBER = 8;
export const ID_QUERYSHEET_ADMIN = 9;
export const ID_EXPLORER_QUERYSHEET_ADMIN = 10
export const ID_EXPLORER_TEAM_ADMIN = 11;

// Roles names
export const ADMINISTRATOR = 'administrator';
export const PROJECT_MANAGER = 'project_manager';
export const ANALYSIS_MANAGER = 'analysis_manager';
export const DOCUMENT_MANAGER = 'document_manager';
export const VIEWER = 'viewer';
export const EXTERNAL_VIEWER = 'external_viewer';
export const GROUP_BENEFIT_VIEWER = 'group_benefit_viewer';

export const TEAM_ADMIN = 'team_admin';
export const EXPLORER_MEMBER= 'explorer_member';
export const QUERYSHEET_ADMIN = 'querysheet_admin';
export const QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER= 'explorer_querysheet_admin';
export const TEAM_ADMIN_PLUS_EXPLORER_MEMBER = 'explorer_team_admin';
